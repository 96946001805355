import React from "react";
import { Col, Form, Row, Stack } from "react-bootstrap";
import EditEmailSchedule from "../../components/EditEmailSchedule";
import EmailPreview from "../../components/EmailPreview";
import EditEmailTargetGroup from "../../components/EditEmailTargetGroup";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const EmailStep3 = React.forwardRef(
  ({ validated, segment, currentActivity }, ref) => {
    return (
      <Row className="justify-content-between">
        <Col className="col-12 col-xl-5">
          <Form ref={ref} validated={validated}>
            <Stack className="pt-2 gap-5">
              <Row>
                <Col>
                  <h4 className="display-sm semi-bold">
                    Inställningar för emailkampanj
                  </h4>
                </Col>
                <Col className="col-2 d-flex align-items-center justify-content-end">
                  {statusBadgeSetter(currentActivity)}
                </Col>
                <EditEmailSchedule ad={currentActivity?.ad} />
              </Row>
              <Row>
                <Form.Label
                  htmlFor="segmentvalue"
                  className="text-muted semi-bold"
                >
                  Målgrupp
                </Form.Label>
                {!!segment && (
                  <EditEmailTargetGroup segment={segment} activity={currentActivity} />
                )}
              </Row>
            </Stack>
          </Form>
        </Col>
        <Col className="col-12 col-xl-6">
          <EmailPreview ad={currentActivity?.ad} />
        </Col>
      </Row>
    );
  }
);

export default EmailStep3;
