import { Col, Row } from "react-bootstrap";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import EditLinkedinAdSettings from "../components/EditLinkedinAdSettings";
import MobilePreview from "features/campaign/campaign-details/components/MobilePreview";
import { forwardRef } from "react";

const LinkedinStep2 = forwardRef(
  (
    {
      activity,
      campaign,
      linkedinAdObject,
      setLinkedinAdObject,
      mutate,
      segment,
      adBannerPreview,
    },
    ref
  ) => {
    return (
      <Row className="justify-content-between">
        <Col className="col-12 col-lg-5">
          <Row>
            <Col className="col-12">
              <Row className="justify-content-between">
                <Col>
                  <h4 className="display-sm semi-bold">
                    Inställningar för Linkedin-kampanj
                  </h4>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  {statusBadgeSetter(activity)}
                </Col>
              </Row>
            </Col>
          </Row>
          <EditLinkedinAdSettings
            campaign={campaign}
            mutate={mutate}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            segment={segment}
            ref={ref}
          />
        </Col>

        <Col className="d-flex align-items-center justify-content-center">
          <MobilePreview
            channel="LINKEDIN"
            activity={activity}
            adBannerPreview={adBannerPreview ? adBannerPreview : null}
          />
        </Col>
      </Row>
    );
  }
);

export default LinkedinStep2;
