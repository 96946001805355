import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leadType: 0,
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    setLeadType: (state, action) => {
      state.leadType = action.payload;
    },
  },
});

export const { setLeadType } = leadSlice.actions;
