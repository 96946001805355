import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getAccounts } from "features/accounts/api";
import { Table, Col, Spinner, Row, Image } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { TextInput } from "components/forms";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";
import ClientPersonDetailsOffCanvas from "features/accounts/account-details/ClientPersonDetailsOffCanvas";
import Agent from "api/Agent";

const Persons = (props) => {
  const columns = [
    { label: "", property: "merInfo" },
    { label: "Kund", property: "name" },
    { label: "Email", property: "email" },
    { label: "Skapad", property: "createdOn" },
  ];

  //Table search and pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  //GET PERSONSACCOUNTS FUNCTIONALITY
  const {
    isLoading: isLoadingPersons,
    data: personsAccounts,
  } = Agent.Accounts.GET.ALL(currentPage, props.accountType, 10, search)


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredData = personsAccounts?.Results;
  //HANDLE OFFCANVAS------
  const [personId, setPersonId] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setPersonId(null);
  };

  const handleShow = (id) => {
    setPersonId(id);
    setShow(true);
  };
  return (
    <>
      {personId && props.accountType === 5 && (
        <ClientPersonDetailsOffCanvas
          show={show}
          handleClose={handleClose}
          personId={personId}
          accountType={props.accountType}
        />
      )}

      <Col className="col-12">
        <Col className="col-12">
          <Row className="justify-content-end">
            <Col className="col-5">
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>
        <CardContainer
          style={{ padding: "0" }}
          children={
            <>
              <Table
                id="clientsTable"
                className="table-container"
                responsive
                hover
                size="lg"
              >
                <thead className="text-sm semi-bold">
                  <tr>
                    {columns.map((col, index) => (
                      <th
                        style={{
                          width: index === 0 ? "30px" : "",
                          userSelect: "none",
                        }}
                        key={index}
                      >
                        <p>{col.label}</p>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="text-md regular">
                  {isLoadingPersons ? (
                    <Spinner />
                  ) : (
                    filteredData?.map((item) => (
                      <tr key={item.id} onClick={() => handleShow(item.id)}>
                        <td>
                          {item.merinfoMatched && (
                            <Image
                              src="https://www.merinfo.se/favicon-32x32.png"
                              width={25}
                            />
                          )}
                        </td>
                        {item.title ? (
                          <td style={{ whiteSpace: "nowrap" }}>{item.title}</td>
                        ) : (
                          <td
                            style={{
                              whiteSpace: "nowrap",
                              color: "grey",
                              fontWeight: 300,
                              fontStyle: "italic",
                            }}
                          >
                            Namn saknas
                          </td>
                        )}
                        <td>{item.email}</td>

                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.createdOn.substring(0, 10)}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>

              <PaginationBar
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                countPerPage={personsAccounts?.Paging.per_page}
                totalCount={personsAccounts?.Paging.total}
              />

              {filteredData && filteredData.length === 0 && (
                <Col className="d-flex align-items-center justify-content-center p-3">
                  <h5 style={{ color: "#ddd" }}>
                    Inga kunder hittade. Ladda upp din kundlista!
                  </h5>
                </Col>
              )}
            </>
          }
        />
      </Col>
    </>
  );
};

export default Persons;
