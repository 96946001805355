import { Button, Form, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { TextInput } from "components/forms";
import { Table, Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { MdSearch, MdCheckCircleOutline, MdCancel } from "react-icons/md";
import moment from "moment";
import "moment/locale/sv";
import styles from "./assets/style.module.css";
import PaginationBar from "components/pagination/PaginationBar";
import { campaignObject } from "features/campaign/api";
import { useNavigate } from "react-router-dom";
import {
  BsArrowDownShort,
  BsArrowUpShort,
  BsFillMegaphoneFill,
} from "react-icons/bs";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";
import { CreateCampaignButton } from "features/campaign";
import UpdateOfferOffCanvas from "features/offers/create-offer/UpdateOfferOffCanvas";
import { FiTrash2 } from "react-icons/fi";

moment.locale("sv");

const OffersTable = ({ settings, setComparisons, comparisons }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [showExpired, setShowExpired] = useState(false);
  const rowsPerPage = 7;
  const {
    data: offers,
    isError,
    isLoading,
  } = Agent.Offers.GET.ALL(currentPage, rowsPerPage, search, showExpired);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleComparisons = (offer) => {
    const isComparison =
      comparisons?.findIndex((comparison) => comparison?.id === offer?.id) >= 0;
    if (isComparison)
      return setComparisons(
        comparisons.filter((comparison) => comparison?.id !== offer?.id)
      );
    return setComparisons([...comparisons, offer]);
  };

  const columns = [
    {
      label: settings?.offerName
        ? `${settings.offerName} ${isSmallScreen ? "" : "& startdatum"}`
        : `"Erbjudande ${isSmallScreen ? "" : "& startdatum"}"`,
      property: "title",
    },
    { label: "Plats" },
    { label: "Jämför" },
    { label: "Konverteringar", property: "purchaseCount" },
    { label: "Kampanj", property: "campaignsCount" },
    { label: "", property: "" },
    { label: "", property: "removeOffer" },
  ];

  const handleTableHeaderClick = (property) => {
    if (sort?.name === property)
      return setSort({ name: property, desc: !sort.desc });
    return setSort({ name: property, desc: false });
  };

  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [offerId, setOfferId] = useState("");

  const handleShowEditOffer = (id) => {
    setShowEditOffcanvas(true);
    setOfferId(id);
  };

  const handleCloseEditOffer = () => {
    setShowEditOffcanvas(false);
    setOfferId("");
  };

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({ id: null });
  const [confirmDelete, setConfirmDelete] = useState([]);

  const { mutate } = Agent.Offers.DEL.ID();
  const deleteOffer = (id) =>
    mutate(id, {
      onMutate: (id) => {
        setLoadingDelete(true);
        setSelectedObject({ id });
      },
      onSuccess: () => {
        setSelectedObject({ id: null });
        setShowDeleteModal(false);
      },
      onError: () => {
        setLoadingDelete(false);
        setSelectedObject({ id: null });
      },
      onSettled: () => {
        setLoadingDelete(false);
      },
    });

  const onDeleteClick = (id) => {
    setConfirmDelete(confirmDelete.filter((x) => x !== id));
    deleteOffer(id);
  };

  return (
    <>
      {showEditOffcanvas && (
        <UpdateOfferOffCanvas
          settings={settings}
          id={offerId}
          show={showEditOffcanvas}
          handleClose={handleCloseEditOffer}
        />
      )}
      <Row>
        <Col className="col-12">
          <Row
            className={`justify-content-between ${
              isSmallScreen ? "flex-column" : ""
            }`}
          >
            <Col
              className={`d-flex align-items-center ${
                isSmallScreen ? "order-2" : "order-1"
              }`}
            >
              <Form.Check
                className={`${styles.checkbox} m-2`}
                checked={showExpired}
                onChange={() => setShowExpired((prev) => !prev)}
              />
              <Form.Label className="text-sm semi-bold text-nowrap mb-0">
                Visa historiska{" "}
                {settings?.offerPluralName
                  ? settings.offerPluralName
                  : "erbjudanden"}
              </Form.Label>
            </Col>
            <Col
              className={
                isSmallScreen ? "col-12 mt-2 order-1" : "col-3 order-2"
              }
            >
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col className="col-12">
          <CardContainer
            style={{ padding: "0" }}
            children={
              <div className="table-responsive">
                <Table
                  id="segmentTable"
                  className="table "
                  responsive
                  hover
                  size="lg"
                >
                  <thead className="text-sm semi-bold">
                    <tr>
                      {columns.map((col, index) => (
                        <th
                          style={{ userSelect: "none" }}
                          className={`col-sm-${12 / columns.length} ${
                            col.noWrapOnLarge ? "d-none d-lg-table-cell" : ""
                          }`}
                          key={index}
                          onClick={() =>
                            col.property && handleTableHeaderClick(col.property)
                          }
                        >
                          {sort?.name === col.property && col.property ? (
                            <p>
                              {col.label}
                              {sort?.desc ? (
                                <BsArrowDownShort size={20} />
                              ) : (
                                <BsArrowUpShort size={20} />
                              )}
                            </p>
                          ) : (
                            <p>{col.label}</p>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="text-md regular">
                    {offers?.Results?.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() => handleShowEditOffer(item.id)}
                      >
                        <td
                          style={{
                            whiteSpace: item.noWrapOnLarge
                              ? "nowrap"
                              : "normal",
                          }}
                        >
                          {item.title}
                          {!isSmallScreen && item.startTime && (
                            <p
                              style={{
                                color: "#7B8FA1",
                                margin: "-10px 0",
                                textTransform: "capitalize",
                              }}
                            >
                              {moment(item.startTime).format(
                                "dddd D MMMM YYYY, HH:mm"
                              )}
                            </p>
                          )}
                        </td>
                        <td>
                          {item.location
                            ? item.location
                            : item.productName
                            ? item.productName
                            : ""}
                        </td>
                        <td>
                          <Form.Check
                            onClick={() => handleComparisons(item)}
                            className={`${styles.checkbox} ms-2`}
                            style={{ minHeight: "100%", width: "100%" }}
                          />
                        </td>
                        <td>{item?.purchaseCount} st</td>
                        <td style={{ color: "#2D6450" }}>
                          {`${item?.campaignsCount} ${
                            item?.campaignsCount === 1 ? "kampanj" : "kampanjer"
                          }`}
                        </td>
                        <td
                          style={{ color: "#2D6450" }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CreateCampaignButton offer={item} />
                        </td>
                        <td onClick={(e) => e.stopPropagation()}>
                          <div align="center ">
                            {!confirmDelete?.find(
                              (_delete) => _delete === item?.id
                            ) ? (
                              <button
                                className="delete-button"
                                onClick={() =>
                                  setConfirmDelete([...confirmDelete, item.id])
                                }
                              >
                                <FiTrash2 size={18} />
                              </button>
                            ) : loadingDelete &&
                              item.id === selectedObject.id ? (
                              <Spinner size="sm" />
                            ) : (
                              <>
                                <MdCancel
                                  size={20}
                                  className="edit-button me-2"
                                  onClick={(e) => {
                                    setConfirmDelete(
                                      confirmDelete.filter((x) => x !== item.id)
                                    );
                                  }}
                                />
                                <MdCheckCircleOutline
                                  size={20}
                                  className="delete-button"
                                  onClick={() => onDeleteClick(item.id)}
                                />
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  {isError && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3 bg-danger"
                    >
                      <h5 className=" text-white semi-bold text-center w-100">
                        Ett fel uppstod med tabell data
                      </h5>
                    </caption>
                  )}

                  {isLoading && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3"
                    >
                      <h5 className=" text-black text-center w-100">
                        <Spinner />
                      </h5>
                    </caption>
                  )}
                </Table>

                {offers?.Results.length > 0 && (
                  <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    totalCount={offers?.Paging?.total}
                    countPerPage={offers?.Paging.per_page}
                  />
                )}
                {offers?.Results && offers.Results.length === 0 && (
                  <Col className="d-flex align-items-center justify-content-center p-3">
                    <h5 style={{ color: "#ddd" }}>
                      {`Inga ${
                        settings?.pluralName
                          ? settings.pluralName
                          : "erbjudanden"
                      } hittade.`}
                    </h5>
                  </Col>
                )}
              </div>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default OffersTable;
