import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const EmailDomains = {
  GET: {
    ALL: () => {
      return useQuery({
        queryFn: () => axios.get(`/emaildomains/v1/`).then(({ data }) => data),
        queryKey: ["emaildomains"],
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all emaildomains \n Error: `, err),
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(id).then(() =>
              axios.get(`/emaildomains/v1/${id}`).then(({ data }) => data)
            )
          ).catch((e) => Promise.reject(e)),
        queryKey: ["emaildomain", id],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get emaildomain '${id}' \n Error: `, err),
      });
    },
  },

  PUT: {
    Emaildomain: () => {
      const queryClient = useQueryClient();
      return useMutation(
        (emaildomain) =>
          axios
            .put(`/emaildomains/v1/${emaildomain?.id}`, emaildomain)
            .then(() => emaildomain?.id),
        {
          onSuccess: (id) => {
            queryClient.invalidateQueries("emaildomains");
            queryClient.invalidateQueries(["emaildomain", id]);
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to PUT emaildomain ${id} \n Error: `, error);
          },
        }
      );
    },
  },
  POST: {
    Emaildomain: () => {
      const queryClient = useQueryClient();
      return useMutation(
        (emaildomain) => axios.post(`/emaildomains/v1/`, emaildomain),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("emaildomains");
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to POST new emaildomain \n Error: `, error);
            toast("[Fel] Det gick inte att skapa en ny email domän ", {
              type: "error",
            });
          },
        }
      );
    },
  },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/emaildomains/v1/${id}`), {
        onSuccess: (x) => {
          queryClient.invalidateQueries(["emaildomains"]);
          queryClient.removeQueries([id]);
          toast("email domänen borttagen ", { type: "info" });
        },
        onError: (error) => {
          console.warn(
            `Failed to delete emaildomain '${id}' \n Error: `,
            error
          );
          toast("[Fel] email domänen gick inte att ta bort ", {
            type: "error",
          });
        },
      });
    },
  },
};
