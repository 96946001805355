import { Line } from "react-chartjs-2";
import { getCampaignStats } from "features/overview/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { Spinner, Container, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";


const CampaignStats = ({ifXsScreen}) => {

  // const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  // const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const {
    data: campaigns,
    isError,
    isLoading,
    error,
  } = useQuery("campaignStats", getCampaignStats);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>{error.message}</p>
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable the default legend display
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        ticks: {
          display: true,
          beginAtZero: false,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };

  let chartData = {
    labels: [...campaigns?.data.labels.map((label) => label)],
    datasets: [
      {
        label: "Klick",
        // data: [12, 19, 3, 5, 2, 3],
        data: [...campaigns?.data.datasets[1].data.map((data) => data)],
        borderColor: "#3EBEE3",
        backgroundColor: "transparent",
        borderWidth: 1.5,
        borderRadius: 10,
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        pointStyle: "circle",
        pointRadius: 5,
        // For smooth linechart
        lineTension: 0.3,
        pointRadius: 0,
      },
      {
        label: "Interaktioner",
        // data: [5, 10, 12, 8, 6, 7],
        data: [...campaigns?.data.datasets[0].data.map((data) => data)],
        borderColor: "#E7AD67",
        backgroundColor: "transparent",
        borderWidth: 1.5,
        borderRadius: 10,
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        pointStyle: "circle",
        pointRadius: 5,
        // For smooth linechart
        lineTension: 0.3,
        pointRadius: 0,
      },
      {
        label: "CTR",
        // data: [3, 6, 4, 9, 8, 10],
        data: [...campaigns?.data.datasets[2].data.map((data) => data)],
        borderColor: "#B73324",
        backgroundColor: "transparent",
        borderWidth: 1.5,
        borderRadius: 10,
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        pointStyle: "circle",
        pointRadius: 5,
        // For smooth linechart
        lineTension: 0.3,
        pointRadius: 0,
      },
    ],
  };

  //dumy data 
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const dummyData = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: [3, 6, 4, 9, 8, 10],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
        data: [4, 7, 34, 45, 55, 70],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }



  return (
    <Container fluid >
      <Row className="mb-4">
        <Col>
          <div style={{ width: "100%", height: "100%", paddingTop: "40px" }}>
            {chartData.datasets[0].data.length > 0 && (
              <Line options={options} data={chartData} 
              // data={chartData} 
              />
            )}
          </div>
        </Col>
      </Row>
    <Row>
      <Col
          className={!ifXsScreen ? "flex-row" : "flex-column"}
          style={{
            display: "flex",
            flexDirection: ifXsScreen ? "column" : "row",
            justifyContent: "center",
            // gap: "2px",
            color: "#667085",
            fontSize: "14px",
            marginRight: ifXsScreen ? "" : "15px",
          }}
      >
        {chartData.datasets.map((dataset) => (
          <div
            key={dataset.label}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
              paddingRight: ifXsScreen ? "": "15px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: dataset.borderColor,
                marginRight: "5px",
              }}
            />
            <span>{dataset.label}</span>
          </div>
        ))}
      
      </Col>
      </Row>
      </Container>
  );
};

export default CampaignStats;
