import React, { useContext, useEffect, useRef, } from "react";
import html2canvas from "html2canvas";
import DirectCampaignPreview from "../DirectCampaignWizard/DirectCampaignPreview";
import { debounce } from "lodash";
import { DRContext } from "../../wizard/DRWizard";

const RenderFullSizeOffScreen = ({ propertyName, activity,}) => {
  const {render, setRender} = useContext(DRContext);
  const imageRef = useRef(null);
  const debouncedExport = debounce(() => handleExportImage(), 300);
  const handleExportImage = (e) => {
    if (imageRef.current) {
      html2canvas(imageRef.current, {
        logging: false,
        letterRendering: 1,
        allowTaint: false,
        scale: 1,
        useCORS: true,
      }).then((canvas) => {
        const imageDataURL = canvas.toDataURL("image/png");
        if (!render?.some((x) => x[propertyName] === imageDataURL)) {
          setRender((prevRender) => [
            ...prevRender?.filter(x => !Object.keys(x).includes(propertyName)),
            { [propertyName]: imageDataURL },
          ]);
        }

      });
    }
  };


  return (
    <div
      style={{ position: "absolute", top: 9999, left: -9999, zIndex: -1000, width:"800px", height:"800px" }}
    >
      <div ref={imageRef} style={{ position: "relative" }}>
        <DirectCampaignPreview
          propertyName={propertyName}
          activity={activity}
          onLoad={debouncedExport}
        />
      </div>
    </div>
  );
};

export default RenderFullSizeOffScreen;
