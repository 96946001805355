import { Row, Col, Nav } from "react-bootstrap";
import { useState} from "react";
import CampaignListItem from "./CampaignListItem";
import { campaignSteps } from "./steps/data/stepsData";
import { useSelector } from "react-redux";

const CampaignStepsContainer = ({ifSmallScreen}) => {
  const visited = useSelector((state) => state.campaign.visited);
  const prevLocation = useSelector((state) => state.campaign.prevLocation);

  const [completed, setCompleted] = useState([]);

  const onClickHandler = (step) => {
    setCompleted((prev) => [...prev, step.id]);
  };

  return (
    <>
    {
      ifSmallScreen ? (
      <>
          <Row className="p-3">
      <Col className="col-12" style={{paddingLeft:"30px"}}>
        <h2 className="display-xs bold">Skapa Kampanj</h2>
      </Col>
      <Col className="col-12">
        <Nav 
        // className="flex-row row g-4 d-flex"
        className="d-flex align-items-center justify-content-center gap-3"
        >
          {campaignSteps.map((step) => (
            <CampaignListItem
              key={step.id}
              // onClick={() => onClickHandler(step)}
              completed={completed}
              visited={visited}
              prevLocation={prevLocation}
              url={step.url}
              subText={step.subText}
              link={step.link}
              id={step.id}
              title={step.name}
              icon={step.icon}
              iconBg={step.iconBg}
              status={step.status}
              ifSmallScreen={ifSmallScreen}
            />
          ))}
        </Nav>
      </Col>
    </Row>
      </>
    ) : (
      <Row className="p-3">
      <Col className="col-12">
        <h2 className="display-xs bold">Skapa Kampanj</h2>
      </Col>
      <Col className="col-12">
        <Nav className="flex-column row g-4">
          {campaignSteps.map((step) => (
            <CampaignListItem
              key={step.id}
              // onClick={() => onClickHandler(step)}
              completed={completed}
              visited={visited}
              prevLocation={prevLocation}
              url={step.url}
              subText={step.subText}
              link={step.link}
              id={step.id}
              title={step.name}
              icon={step.icon}
              iconBg={step.iconBg}
              status={step.status}
              ifSmallScreen={ifSmallScreen}
            />
          ))}
        </Nav>
      </Col>
    </Row>
    )
  }
</>
  );
};

export default CampaignStepsContainer;
