import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//global.scss includes bootstrap import and bootstrap-class overrides
import "assets/styles/global.scss";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { store } from "app/store";
import { AuthProvider } from "context/AuthProvider";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css'


const queryClient = new QueryClient();



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <AuthProvider>
            <App />
            <ReactQueryDevtools initialIsOpen position="bottom-right" />
          </AuthProvider>
        </Router>
      </Provider>
      <ToastContainer newestOnTop />
    </QueryClientProvider>
  </React.StrictMode>
);
