import Agent from "api/Agent";
import React, { useState } from "react";
import {
  Button,
  Row,
  Spinner,
  Form,
  Modal
} from "react-bootstrap";
import { segmentTypes_sv } from "features/segment/segment-details/assets/config";
import * as icons from "react-icons/md";
import Select from 'react-select';
import { toast } from "react-toastify";

const iconOptions = Object.keys(icons).map(key => ({
  label: <div>{React.createElement(icons[key], {
    style: {
      width: "30px",
      height: "30px",
      marginRight: "10px"
    },
  }
  )}{key}</div>, value: key
}));


const EditFilterCategoryModal = ({ show, setShow, categoryId, setCategoryId }) => {
  const { data, isLoading, isError, isSuccess } = Agent.FilterCategories.GET.ID(categoryId)
  const [iconBg, setIconBg] = useState(undefined)


  const postMutation = Agent.FilterCategories.POST.FILTER_CATEGORY();
  const putMutation = Agent.FilterCategories.PUT.FILTER_CATEGORY();

  const handleClose = () => {
    if (typeof setCategoryId == "function") setCategoryId(undefined)
    setShow(false);
  };

  const resetForm = () => {
    const form = document.getElementById('form')
    form?.reset();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('form')
    const formData = new FormData(form);
    const formDataArray = Array.from(formData?.entries());

    if (!formDataArray) {
      console.warn("Failed to get formData")
      return false
    }

    const request = formDataArray.reduce((result, [key, value]) => {
      if (form.elements[key]?.type?.includes("select")) result[key] = +value;
      else result[key] = value;

      return result;
    }, { ...data })

    // if (request?.filterTypes) delete request.filterTypes
    if (!request?.filterTypes) request.filterTypes = [];



    // return false;
    if (request?.id) {
      toast.promise(
        putMutation.mutateAsync(request, {
          onSuccess: () => {
            // resetForm();
          }
        }), { success: "Kategori Uppdaterad" })
    }
    else {

      toast.promise(
        postMutation.mutateAsync(request, {
          onSuccess: () => {
            resetForm();
          }
        }), { success: "Kategori skapad" })
    }





  }
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{`${categoryId ? "Uppdatera" : "Skapa ny"}  filter kategori`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-1">
        <Row className="justify-content-center align-items-center">
          {isError ? <span>Ett fel uppstod</span> :
            isLoading && <Spinner />}
        </Row>
        {(!categoryId || isSuccess) &&
          <Form onSubmit={(e) => handleSubmit(e)} id="form" className="d-flex flex-column gap-2">
            <Form.Group >
              <Form.Label>Namn *</Form.Label>
              <Form.Control
                name="name"
                defaultValue={data?.name ?? ""}
                placeholder="..."
                type="text"
                required
              />
            </Form.Group>
            <Form.Group >
              <Form.Label>Rubrik (i applikation) *</Form.Label>
              <Form.Control
                name="title"
                defaultValue={data?.title ?? ""}
                placeholder="..."
                type="text"
                required
              />
            </Form.Group>
            <Form.Group >
              <Form.Label>Beskrivning </Form.Label>
              <Form.Control
                name="subtitle"
                defaultValue={data?.subtitle ?? ""}
                type="text"
              />
            </Form.Group>
            <Form.Group >
              <Form.Label>Typ av målgrupp *</Form.Label>
              <Form.Select
                className="text-md"
                name="segmentType"
                defaultValue={data?.segmentType ?? ""}>
                <option value="">Alla</option>
                {Object.keys(segmentTypes_sv)?.map(key => <option key={segmentTypes_sv[key]?.text} value={key}>{segmentTypes_sv[key]?.text}</option>)}

              </Form.Select>

            </Form.Group>

            <Form.Group >
              <Form.Label>Icon *</Form.Label>
              <Select
                required
                isSearchable
                isClearable
                name="icon"
                defaultValue={iconOptions?.find(x => x?.value === data?.icon) ?? ""}
                placeholder="Välj en icon"
                options={iconOptions}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    color: iconBg ?? data?.iconBg,
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: iconBg ?? data?.iconBg,
                  })
                }}
              />
            </Form.Group>


            <Form.Group >
              <Form.Label>Ikon färg *</Form.Label>
              <Form.Control
                name="iconBg"
                defaultValue={data?.iconBg ?? ""}
                type="color"
                value={iconBg}
                onChange={e => setIconBg(e.target.value)}
              />
            </Form.Group>

            <Row className="row-cols-auto  p-2">
              <span className="text-muted ">* nödvändiga fält</span>
            </Row>

            <Row className="row-cols-auto justify-content-end p-2">
              {categoryId ?
                <Button variant="success" type="submit" disabled={putMutation?.isLoading}  >
                  {putMutation.isLoading ? <Spinner /> : "Uppdatera kategori"}
                </Button>
                :
                <Button variant="success" type="submit" disabled={postMutation?.isLoading}  >
                  {postMutation.isLoading ? <Spinner /> : "Skapa kategori"}
                </Button>
              }
            </Row>
          </Form>}
      </Modal.Body>
    </Modal>

  )
}

export default EditFilterCategoryModal
