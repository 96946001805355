import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import style from "./draggable.module.css" 
import {
  AiFillCaretDown,
  AiFillCaretLeft,
  AiFillCaretRight,
  AiFillCaretUp,
} from "react-icons/ai";

export function Draggable({ id, content, styles, size = ["15", "15"], disabled }) {
  const ARROW_SIZE = 12
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    disabled: disabled && disabled,
  });

  const move = !!transform && {
    transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
  };
  const pointerEvent = disabled && {
    pointerEvents:"none",
  };

  return (
    <div
      ref={setNodeRef}
      style={{ ...styles, ...move, ...pointerEvent, width: `${size[0]}%`, height: `${size[1]}%`, } }
      {...listeners}
      {...attributes}

      className={style.draggable}
    >

      {content}
    <div

       style={{ position: "absolute", width:"100%", height:"100%"}}
    >
      <div
      className={style.arrow}
       style={{ position: "absolute", left: "50%", bottom: 0 }}>
        <AiFillCaretDown
          size={ARROW_SIZE}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            left: "-50%",
            opacity: 0.8,
          }}
        />
      </div>

      <div className={style.arrow} style={{ position: "absolute", left: "50%", top: 0 }}>
        <AiFillCaretUp
          size={ARROW_SIZE}
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            left: "-50%",
            opacity: 0.8,
          }}
        />
      </div>

      <div
      className={style.arrow}
        style={{
          position: "absolute",
          height: "100%",
          right: 0,
          alignItems: "center",
        }}
      >
        <AiFillCaretRight
          size={ARROW_SIZE}
          style={{ position: "relative", width: "100%", opacity: 0.8 }}
        />
      </div>

      <div
      className={style.arrow}
        style={{
          position: "absolute",
          height: "100%",
          left: 0,
          alignItems: "center",
        }}
      >
        <AiFillCaretLeft
          size={ARROW_SIZE}
          style={{ position: "relative", width: "100%", opacity: 0.8 }}
        />
      </div>
      </div>
    </div>
  );
}
