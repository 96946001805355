import SideBar from "layouts/side-bar/SideBar";
import { Container, Row } from "react-bootstrap";
import MainContent from "layouts/main-content/MainContent";
import "react-toastify/dist/ReactToastify.min.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "features/errorFallback/ErrorFallback";

const Layout = ({ siteUrl, setSiteUrl }) => {
  return (
    <Container className="App" fluid>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Row style={{ flexWrap: "nowrap" }}>
          <SideBar setSiteUrl={setSiteUrl} siteUrl={siteUrl} />
          <MainContent />
        </Row>
      </ErrorBoundary>
    </Container>
  );
};

export default Layout;
