import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Leads = {
  GET: {
    ALL: (page, type, search, isInCrm, hasActivity, score) => {
      const queryParams = [
        page && `page=${page}`,
        search && `search=${search}`,
        type !== undefined && `type=${type}`,
        isInCrm !== "" && isInCrm !== undefined && `isInCrm=${isInCrm}`,
        hasActivity !== "" &&
          hasActivity !== undefined &&
          `hasActivity=${hasActivity}`,
        score !== "" && score !== undefined && `score=${score}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios.get(`leads/v1${queryString}`).then(({ data }) => data),
        queryKey: ["leads", queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET leads with query: ${queryString} \n Error: `,
            error
          );
          toast.error("[Fel] Det gick inte att hämta leads");
        },
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: () => axios.get(`leads/v1/${id}`).then(({ data }) => data),
        queryKey: ["lead", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET leads with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta lead");
        },
      });
    },
  },
};
