import { Col, Row, Spinner, Badge, Button, Alert } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getPreview, getExternalStatuses } from "../../../api";
import MobilePreview from "../../../components/MobilePreview";
import {
  BsFillCheckCircleFill,
  BsEyeFill,
  BsFillPlayCircleFill,
  BsPauseCircleFill,
} from "react-icons/bs";
import { FaCheckCircle, FaUserCheck } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { updateCampaignActivity } from "features/campaign/api";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import Agent from "api/Agent";
import EditSettingsContainer from "../components/EditSettingsContainer";

const LinkedinStep3 = ({ activity, campaign }) => {
  const { error, isError, isLoading, data } = useQuery({
    queryKey: ["MobilePreview", "MobilePreview/" + activity?.id],
    queryFn: () => getPreview(activity.id),
    // refetchOnWindowFocus: false,
    // retry: 1,
    retry: false,
    staleTime: 60000 * 10,
  });

  //Get segment by id-----
  const { data: segment } = Agent.Segments.GET.ID(campaign.segmentID);

  const { data: externalStatuses } = useQuery({
    queryKey: ["externalStatuses", activity?.id],
    queryFn: () => getExternalStatuses(activity.id),
  });

  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: publishLoading,
    isError: publishIsError,
  } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );
  const publishAd = (e) => {
    e.preventDefault();
    const publishedAd = {
      ...activity,
      publishAd: true,
      isActive: true,
    };

    mutate(publishedAd);
  };

  const pausAd = (e) => {
    e.preventDefault();
    const pausedAd = {
      ...activity,
      isActive: false,
    };

    mutate(pausedAd);
  };

  const audienceStatus = externalStatuses?.find(
    (status) => status.name?.includes("Segment") && status.statusreason
  );
  const otherStatuses = externalStatuses?.find(
    (status) => !status.name?.includes("Audience") && status.statusreason
  );

  return (
    <Row className="justify-content-between">
      <Col className="col-12 col-lg-6">
        <Row>
          <Col className="col-12">
            <Row className="justify-content-between">
              <Col>
                <h4 className="display-sm semi-bold">Linkedin-kampanj</h4>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                {statusBadgeSetter(activity)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {audienceStatus && (
            <Col className="col-12 status-alert-container">
              <Alert
                size="small"
                variant={
                  audienceStatus.status === "BUILDING"
                    ? "info"
                    : audienceStatus.status === "READY"
                    ? "success"
                    : "warning"
                }
              >
                <Row>
                  <Col className="col-1">
                    {audienceStatus.status === "200" ? (
                      <FaUserCheck size={20} />
                    ) : (
                      <IoWarning size={20} color="#3ebee3" />
                    )}
                  </Col>
                  <Col>
                    <p>{audienceStatus.statusreason}</p>
                  </Col>
                </Row>
              </Alert>
            </Col>
          )}
          {otherStatuses && (
            <Col className="col-12 status-alert-container">
              <Alert
                size="small"
                variant={
                  audienceStatus.status === "200" ? "success" : "warning"
                }
              >
                <Row>
                  <Col className="col-1">
                    {audienceStatus.status === "200" ? (
                      <FaCheckCircle size={20} />
                    ) : (
                      <IoWarning size={20} />
                    )}
                  </Col>
                  <Col>
                    <p>{audienceStatus.statusreason}</p>
                  </Col>
                </Row>
              </Alert>
            </Col>
          )}
        </Row>

        {/* //?AD-STATE INDICATORS--------------------- */}
        {/* //*PREPARING/UNDER REVIEW */}
        {activity?.state === 5 && (
          <Row className="mt-5 g-4" style={{ color: "#DDCD9E" }}>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <BsEyeFill size={94} />
            </Col>
            <Col className="col-12 text-center">
              <h5 className="display-sm bold">
                Din annons granskas av Linkedin. <br /> Detta kan ta upp till 48
                timmar!
              </h5>
            </Col>
          </Row>
        )}
        {/* //*READY (TO BE PUBLISHED) */}
        {activity?.state === 1 && (
          <Row className="mt-5 g-4" style={{ color: "#42A146" }}>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <BsFillCheckCircleFill size={94} />
            </Col>
            <Col className="col-12 text-center">
              <h5 className="text-lg bold">
                Din annons är godkänd och redo att publiceras!
              </h5>
            </Col>
            <Col className="col-12 d-flex justify-content-center">
              <Button
                onClick={(e) => publishAd(e)}
                className="d-flex align-items-center semi-bold"
                variant="success"
              >
                Publicera
                {publishLoading ? (
                  <Spinner />
                ) : (
                  <BsFillPlayCircleFill className="mx-2" />
                )}
              </Button>
            </Col>
          </Row>
        )}
        {/* //*RUNNING/SCHEDULED (IS PUBLISHED) */}
        {activity?.state === 2 && (
          <Row className="mt-5 g-4" style={{ color: "#3EBEE3" }}>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <BsFillPlayCircleFill size={94} />
            </Col>
            <Col className="col-12 text-center">
              <h5 className="display-sm bold">
                Din annons körs eller är schemalagd!
              </h5>
            </Col>
            <Col className="col-12 d-flex justify-content-center">
              <Button
                onClick={(e) => pausAd(e)}
                className="d-flex align-items-center semi-bold"
                variant="warning"
              >
                Pausa{" "}
                {publishLoading ? (
                  <Spinner />
                ) : (
                  <BsPauseCircleFill className="mx-2" />
                )}
              </Button>
            </Col>
          </Row>
        )}
        {/* //*ONHOLD/PAUSED */}
        {activity?.state === 4 && (
          <Row className="mt-5 g-4" style={{ color: "#C8C58B" }}>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <BsPauseCircleFill size={94} />
            </Col>
            <Col className="col-12 text-center">
              <h5 className="display-sm bold">
                Din annons är för tillfället pausad
              </h5>
            </Col>
            <Col className="col-12 d-flex justify-content-center">
              <Button
                onClick={(e) => publishAd(e)}
                className="d-flex align-items-center semi-bold"
                variant="success"
              >
                Starta{" "}
                {publishLoading ? (
                  <Spinner />
                ) : (
                  <BsFillPlayCircleFill className="mx-2" />
                )}
              </Button>
            </Col>
          </Row>
        )}
        {/* //?-------------------------------------- */}
        <EditSettingsContainer
          campaign={campaign}
          activity={activity}
          segment={segment}
        />
      </Col>

      <Col>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <p>{error.message}</p>
        ) : data ? (
          <MobilePreview
            channel="LINKEDIN"
            adBannerPreview={data ? data : null}
            activity={activity}
          />
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};

export default LinkedinStep3;
