import React from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import SMSEditor from "./SMSEditor";

export default function EditSMS({ text, setText, setSenderName, activity }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      const data = new FormData(form);
      const responseBody = {};
      for (let [key, value] of data.entries()) {
        responseBody[key] = value;
      }
      console.log(JSON.stringify(responseBody));
    } else {
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mt-1 row-cols-1 g-4">
        <Col>
          <Form.Label
            htmlFor="email_header"
            className="p-0 text-muted semi-bold"
          >
            Avsändare
          </Form.Label>
          <Row>
            <Col className="col-6">
              <Form.Control
                type="text"
                placeholder="Namn"
                onChange={(e) => setSenderName(e.target.value)}
              />
            </Col>
            <Col className="col-6">
              <Form.Control type="text" placeholder="+46701234567" />
            </Col>
          </Row>

          <Form.Control.Feedback type="invalid">
            Ange en Avsändare.
          </Form.Control.Feedback>
        </Col>

        <Col>
          <SMSEditor text={text} setText={setText} activity={activity} />
        </Col>
      </Row>
    </Form>
  );
}
