import { Row, Col, Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";

const EditDynamicUpdating = ({
  activity,
  updateActivity,
  updateActivityLoading,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(
    activity?.segmentRefreshIntervalDays
      ? activity.segmentRefreshIntervalDays
      : 0
  );

  const updateDynamicInterval = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/segmentRefreshIntervalDays",
        value: newValue,
      },
    ];
    updateActivity(patchObj);
    setShowInput(false);
  };
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{minWidth:"170px"}}>
        <p>Uppdateringsfrekvens</p>
      </Col>

      {showInput ? (
        <Col className="d-flex flex-grow-1 align-items-center">
          <p
            className="text-sm text-center"
            style={{
              whiteSpace: "nowrap",
              marginRight: "5px",
              color: "lightgrey",
            }}
          >
            Antal dagar:{" "}
          </p>
          <Form.Control
            type="number"
            defaultValue={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
        </Col>
      ) : (
        <Col className="flex-grow-1"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            whiteSpace: "nowrap",
          }}
        >
          {updateActivityLoading ? (
            <Spinner />
          ) : (
            <p style={{ color: "#545F61" }} className="text-center">
              {activity?.segmentRefreshIntervalDays &&
              activity?.segmentRefreshIntervalDays === 0
                ? "Ej dynamisk"
                : activity?.segmentRefreshIntervalDays &&
                  activity?.segmentRefreshIntervalDays !== 0
                ? activity.segmentRefreshIntervalDays + " dagar"
                : "Ej dynamiskt"}
            </p>
          )}
        </Col>
      )}

      {showInput ? (
        <Col className="flex-shrink-1 edit-col-icon" onClick={updateDynamicInterval}>
          <FaSave color="green" />
        </Col>
      ) : (
        <Col className="flex-shrink-1 edit-col-icon" onClick={() => setShowInput(true)}>
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditDynamicUpdating;
