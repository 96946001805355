import { Card, Row, Stack } from "react-bootstrap";
import AddDataExportButton from "./components/AddDataExportButton";
import DataExportTable from "./components/DataExportTable";


const DataExportCard = () => {
  return (
    <Card className={"card-container "} bg="light">
      <Card.Body >
        <Stack gap={3}>
          <Row className="row-cols-auto justify-content-start p-2">
            <span className="semi-bold ">Utskick</span>
          </Row>
          <Row>
            <DataExportTable sortByProp={"scheduledDate"} sortDesc={true} />
            <Row className="row-cols-auto justify-content-end px-2 py-0">
              <AddDataExportButton />
            </Row>

          </Row>
        </Stack>
      </Card.Body>
    </Card>
  )
}

export default DataExportCard
