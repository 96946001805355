
import { useEffect } from "react";
import { EmailEditor as ReactEmailEditor } from "react-email-editor";
import sv from "../sv.json";
import { Row } from "react-bootstrap";
import axios from "api/axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import { DraftContext } from "../states/draft/Draft";

// Helper function to load email template into the editor
const loadEmailTemplate = (template, emailEditorRef) => {
  try {
    const design = JSON.parse(template);
    emailEditorRef?.current?.editor?.loadDesign(design);
  } catch (error) {
    emailEditorRef?.current?.editor?.loadBlank();
    emailEditorRef?.current?.editor?.setBodyValues({
      backgroundColor: "#fff",
      fontFamily: {
        label: "Helvetica",
        value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
      },
    });
  }
};

// Main component
export default function EmailEditor({ template, SetEditorReady }) {
  const { emailEditorRef } = useContext(DraftContext) ?? { emailEditorRef: undefined };
  const { editorReady } = useContext(DraftContext) ?? { editorReady: undefined };
  // Get Unlayer project ID from environment variables
  const UnlayerProjectID = process.env.REACT_APP_UNLAYER_PROJECTID;

  useEffect(() => { SetEditorReady(false) }, [])
  // Callback function when the email editor is ready
  const onReady = async () => {
    SetEditorReady(true);

    // Load the email template into the editor
    loadEmailTemplate(template, emailEditorRef);
    try {
      // Register callback for the "image" event in the email editor
      emailEditorRef?.current?.editor?.registerCallback(
        "image",
        async function(file, done) {
          var dataa = new FormData();
          dataa.append("file", file.attachments[0]);
          done({ progress: 0 });

          // Upload the image file using Axios
          axios
            .post(`/files/v1`, dataa, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (ev) =>
                done({
                  progress:
                    Math.round((ev?.loaded * 100) / ev?.total) < 100
                      ? Math.round((ev?.loaded * 100) / ev?.total)
                      : 99,
                }),
            })
            .then(({ data }) => done({ progress: 100, url: data?.url }))
            .catch((err) => {
              console.warn("Failed to upload file in email editor \n", err);
              toast.error("Filen gick inte att ladda upp");
            });
        }
      );
    } catch (err) {
      console.error("Unable to set eventListener at emailEditor for imageEvent. Error:\n", err);
    }
  };

  useEffect(() => {
    if (editorReady) loadEmailTemplate(template, emailEditorRef);
  }, [template])


  return (
    <div className="h-100">
      <Row
        className="d-flex justify-content-between py-2 mx-auto"
        style={{ maxWidth: "2000px" }}
      >
        <Row style={{ height: "72vh" }}>
          <ReactEmailEditor

            id="editor"
            projectId={UnlayerProjectID}
            appearance={{ panels: { tools: { dock: "left" } } }}
            ref={emailEditorRef}
            onReady={() => onReady()}
            options={{ locale: "sv", translations: { sv } }}
            style={{
              maxHeight: "max-content",
              height: "100%",
              border: "1px solid #eaecf0",
              boxShadow:
                "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
            }}
          />
        </Row>
      </Row>
    </div>
  );
}

