import { useJsApiLoader } from "@react-google-maps/api";
import { libraries } from "components/maps/google-map-main/assets/options";
import { isArray } from "lodash";
import React from "react";
import { Col } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";

export default function FilterPropMap({ filter, filterType }) {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: API_KEY,
    libraries,
  });
  const [locations, setLocations] = useState(null);
  const selections = filter?.find((x) => isArray(x));

  const fetchAreaDescriptions = async () => {
    if (isLoaded) {
      const fetchedLocations = await Promise.all(
        selections?.map((x) => fetchArea(x.center, x.radius))
      );
      setLocations(fetchedLocations);
    }
  };

  useEffect(() => {
    fetchAreaDescriptions();
  }, [isLoaded, selections]);

  useEffect(() => {
    setLocations(null);
  }, []);

  return locations?.map((x) => (
    <Col key={x?.location} className="bold" style={{ color: "#00789e" }}>
      <span>{x?.km} km runtom </span>
      <span>{x?.location}</span>
    </Col>
  ));
}

async function fetchArea(newCenter, newRadius) {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: newCenter }, (results, status) => {
      if (status === "OK" && results[0]) {
        const locationName = results[0].formatted_address;
        resolve({ location: locationName, km: (newRadius / 1000).toFixed(1) });
      } else {
        reject("Location not found");
      }
    });
  });
}
