import "./style.scss";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate, Outlet, useParams, useLocation } from "react-router";
import { MdArrowBack } from "react-icons/md";
import { SetCampaignName, CampaignStepsContainer } from "features/campaign";
import { useState, useEffect } from "react";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";

const CreateCampaign = () => {
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { data: campaign, isLoading: segmentLoading } =
    Agent.Campaigns.GET.ID(id);
  const { mutate } = Agent.Campaigns.DEL.ID(id);

  const [leavingPage, setLeavingPage] = useState(false);

  const onClickGoBack = () => {
    if (campaign.completedSteps.length < 6) {
      setLeavingPage(true);
    } else {
      navigate("/campaigns");
    }
  };

  const handleClose = () => setLeavingPage(false);
  // console.log(location.pathname)
  // const navigateHook = (currentLocation, campaign, navigate) => {
  //   if (currentLocation.pathname !== `/campaigns/create-campaign/${id}/budget-schedule`) {
  //     // Check if the user is leaving the current route

  //       setLeavingPage(true);

  //   }
  // };
  // useEffect(() => {
  //   // Check if the user is navigating to a different route
  //   return navigateHook(location, campaign, navigate);
  // }, [location, campaign, navigate]);

  const goBack = async () => {
    try {
      await mutate(id);
      navigate("/campaigns");
      setLeavingPage(false);
    } catch (error) { }
  };
  return (
    <>
      {leavingPage && (
        <Modal show={leavingPage} onHide={handleClose}>
          <Modal.Body>
            <Row className="g-3">
              <Col className="col-12">
                <p>
                  Din kampanj är inte färdigskapad och kommer tas bort om du
                  lämnar sidan. Vill du fortsätta?
                </p>
              </Col>
              <Col>
                <Row className="justify-content-between">
                  <Col>
                    <Button onClick={goBack} variant="danger">
                      Ja, ta bort min kampanj.
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button onClick={handleClose} variant="secondary">
                      Avbryt och fortsätt.
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
      <Row className={`campaign-top-container-smallScreen d-flex align-items-center ${ifXsScreen ? "justify-content-start" : "justify-content-between"} `}>
        <Col xs="auto" >
          <Button onClick={onClickGoBack} className="btn-go-back">
            <MdArrowBack />
          </Button>
        </Col>
        <Col xs="auto" className="pr-2 col-7">
          <SetCampaignName ifXsScreen={ifXsScreen} />
        </Col>
        {!ifXsScreen && (
          <Col xs="auto" className="pr-2">
            {/* <p>Hjälp</p> */}
          </Col>
        )}
      </Row>
      {
        ifSmallScreen ? (
          <>
            <Col className="m-0 campaign-content-container">
              <Row className="h-100 p-0">
                <Col
                  className=" col-12 "
                  style={{ zIndex: 2, backgroundColor: "#f0f0f0",borderBottom: "1px solid #dddddd" }}
                >
                  <CampaignStepsContainer ifSmallScreen={ifSmallScreen} />
                </Col>

                <Col className=" col-12 m-0 pb-5 px-0 pt-0 campaign-step-container">
                  <Outlet />
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Col className="m-0 campaign-content-container">
            <Row className="h-100 p-0">
              <Col
                className="col-md-4 left"
                style={{ zIndex: 2, backgroundColor: "#f9f9f9" }}
              >
                <CampaignStepsContainer ifSmallScreen={ifSmallScreen} />
              </Col>

              <Col className="col-md-8 right m-0 pb-5 px-0 pt-0 campaign-step-container">
                <Outlet />
              </Col>
            </Row>
          </Col>
        )
      }

    </>
  );
};

export default CreateCampaign;
