import { CardContainer } from "components/cards";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import "../style.scss";
import { useNavigate } from "react-router-dom";
import { GoogleMapMain } from "components/maps";
import { useQuery } from "react-query";
import { getAccounts } from "features/accounts/api";
import Agent from "api/Agent";
//import GoogleHeatMap from "components/maps/google-map-main/GoogleHeatMap";

const CustomersOverviewContainer = ({ifXsScreen, ifMdAddCircleOutlineIsSmall,xsValue }) => {
  // const {
  //   data: segments,
  //   isError,
  //   isLoading,
  //   error,
  // } = Agent.Segments.GET.ALL()

  //GET COMPANYACCOUNTS FUNCTIONALITY
  const { isLoading, data: companyAccounts } = useQuery(
    ["accounts", 6, 1],
    () =>
      getAccounts(6, 1).then((res) => {
        return res.data.data.filter((item) => item.type === "companies");
      }),
    { keepPreviousData: true }
  );

  console.log({companyAccounts})

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/clients");
  };

  const mapData = [
    { lat: 59.3293, lng: 18.0686 },
    { lat: 59.3326, lng: 18.0649 },
    { lat: 59.3297, lng: 18.0719 },
    { lat: 59.3293, lng: 18.0686 },
    { lat: 59.3326, lng: 18.0649 },
    { lat: 59.3297, lng: 18.0719 },
    { lat: 59.3291, lng: 18.0685 },
    { lat: 59.3278, lng: 18.0625 },
    { lat: 59.3273, lng: 18.0721 },
  ];
  // const heatmapData = [
  //   ...mapData.map((data) => {
  //     return { location: new window.google.maps.LatLng(data.lat, data.lng) };
  //   }),

  //   { location: new window.google.maps.LatLng(59.3293, 18.0686) },
  //   { location: new window.google.maps.LatLng(59.3326, 18.0649) },
  //   { location: new window.google.maps.LatLng(59.3297, 18.0719) },
  //   { location: new window.google.maps.LatLng(59.3293, 18.0686) },

  //   { location: new window.google.maps.LatLng(59.3326, 18.0649) },
  //   { location: new window.google.maps.LatLng(59.3297, 18.0719) },

  //   { location: new window.google.maps.LatLng(59.3291, 18.0685) },
  //   { location: new window.google.maps.LatLng(59.3278, 18.0625) },
  //   { location: new window.google.maps.LatLng(59.3273, 18.0721) },
  // ];

  const mapCenter = { lat: 59.3293, lng: 18.0686 };
  const mapZoom = 13;

  return (
    <CardContainer
      h100
      padding
      children={
        <Container className="m-0 g-0" fluid>
          <Row className="mb-4 justify-content-between">
            <Col>
              <h4 className="text-lg semi-bold">Mina Kunder</h4>
            </Col>
            <Col className="col-2 d-flex justify-content-end align-items-center">
              <FaUsers size={30} color="#ddd" />
            </Col>
          </Row>
          <Row 
          // style={{ height: "25vh" }}
          >
            {isLoading ? (
              <Col className="justify-content-center text-center">
              <Spinner />
              </Col>
            ) : !isLoading && companyAccounts && companyAccounts.length > 0 ? (
              <Col className="col-12">
                <GoogleMapMain />
                {/* <GoogleHeatMap
                  data={heatmapData}
                  center={mapCenter}
                  zoom={mapZoom}
                /> */}
              </Col>
            ) : (
                  <Container fluid >
                    <Row className="h-100">
                      <Col className="col-12">
                        <Row className={`justify-content-evenly  mx-auto ${ifXsScreen ? 'p-0' : 'h-100 p-3'}`}>
                          <Col md={6} className="overviewInfoBox h-100 ">
                            <Alert variant="warning" className={ifXsScreen ? 'd-flex' : 'h-100 d-flex'}>
                              <Row className="justify-content-center text-center">
                                <Col
                                  className={` semi-bold d-flex align-items-center ${ifXsScreen ? 'text-sm ' : 'text-lg'}`}
                                  style={{ color: "rgb(174 165 138)" }}
                                >
                                  Ladda upp en kundlista för att se var i landet majoriteten av era kunder finns!
                                </Col>
                              </Row>
                            </Alert>
                          </Col>
                          <Col xs={12} md={6} className="overviewCreateButton" onClick={handleButtonClick}>
                            <Row className="justify-content-evenly mx-auto">
                              {!ifMdAddCircleOutlineIsSmall &&
                                <Col xs={3} md={3} lg={3} className="d-flex align-items-center">
                                  <MdAddCircleOutline size={65} />
                                </Col>
                              }
                              <Col xs={xsValue} md={9}>
                                <Row className="g-0 flex-column">
                                  <Col>
                                    <h4 className={`semi-bold m-0 ${ifXsScreen ? 'text-sm ' : ''}`}>
                                    Ladda upp kundlista
                                    </h4>
                                  </Col>
                                  <Col>
                                    <p className="text-sm ">
                                    Företag eller Personer
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
            )}
          </Row>
        </Container>
      }
    />
  );
};

export default CustomersOverviewContainer;
