import { Row, Col, Form, Spinner } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useState } from "react";

const EditBudget = ({ activity, updateAd, updateAdLoading }) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(
    activity?.ad?.budget ? activity.ad.budget : 0
  );

  const updateBudget = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/budget",
        value: newValue,
      },
    ];
    updateAd(patchObj);
    setShowInput(false);
  };
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{minWidth:"170px"}}>
        <p>Budget</p>
      </Col>
      {showInput ? (
        <Col className="flex-grow-1">
          <Form>
            <Form.Control
              type="number"
              defaultValue={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
          </Form>
        </Col>
      ) : (
        <Col className="flex-grow-1">
          {updateAdLoading ? (
            <Spinner />
          ) : (
            <p style={{ color: "#545F61" }} className="text-center">
              {activity?.ad?.budget && `${activity?.ad?.budget} kr/dag`}
            </p>
          )}
        </Col>
      )}

      {/* <Col className="col-2 edit-col-icon">
          <AiFillLock color="rgb(212 212 212)" />
        </Col> */}
      {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {showInput ? (
        <Col className="flex-shrink-1 edit-col-icon" onClick={updateBudget}>
          <FaSave color="green" />
        </Col>
      ) : (
        <Col
          className="flex-shrink-1 edit-col-icon"
          onClick={() => setShowInput(true)}
        >
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditBudget;
