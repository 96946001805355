import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import EditEmail from "../../components/EditEmail";
import EmailPreview from "../../components/EmailPreview";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const placeholder = {
  sender: "exempel@autotarget.se",
  title: "Rubrik",
  description: "Beskrivning till mailetutskicket",
};

const EmailStep2 = React.forwardRef(({ validated, currentActivity }, ref) => {
  const [formData, setFormData] = useState(null);

  const handleChange = () => {
    if (ref?.current) {
      const form = ref.current;

      const data = new FormData(form);
      const responseBody = {};
      for (let [key, value] of data.entries()) {
        responseBody[key] = value;
      }
      setFormData(responseBody);
    }
  };

  return (
    <Row className="justify-content-between p-0 m-0">
      <Col className="col-xl-5">

        <Row>
          <Col className="col-12">
            <Row className="justify-content-between">
              <Col>
                <h4 className="display-sm semi-bold">
                  Inställningar för emailkampanj
                </h4>
              </Col>
              <Col className="col-2 d-flex align-items-center justify-content-end">
                {statusBadgeSetter(currentActivity)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <EditEmail
            placeholder={placeholder}
            ref={ref}
            validated={validated}
            onChange={handleChange}
            ad={currentActivity?.ad}
          />
        </Row>
      </Col>
      <Col className="col-xl-6 col-12">
        <EmailPreview
          ad={currentActivity?.ad}
          formData={formData}
          placeholder={placeholder}
        />
      </Col>
    </Row>
  );
});

export default EmailStep2;
