import { useEffect, useState, forwardRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import CampaignInput from "features/campaign/create-campaign/components/steps/components/CampaignInput";
import TargetGroupSize from "./form/TargetGroupSize";
import TargetTypeSelect from "./form/TargetTypeSelect";

const EditMetaAdSettings = forwardRef(
  (
    { activity, metaAdObject, setMetaAdObject, segment, segmentIsLoading },
    ref
  ) => {
    //Starttime maxdate
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 6);
    const formattedMaxDate = maxDate.toISOString().slice(0, 16);

    useEffect(() => {
      const newStartDate = new Date(metaAdObject.startDate);
      const newEndDate = new Date(metaAdObject.endDate);
      if (newStartDate > newEndDate) {
        setMetaAdObject({
          ...metaAdObject,
          endDate: metaAdObject.startDate,
        });
      }
    }, [metaAdObject.startDate, metaAdObject.endDate]);

    const [validated, setValidated] = useState(false);
    const onClickSubmit = (e) => {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
      setValidated(true);
    };

    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

    return (
      <Row className="mt-1 row-cols-1 g-3">
        <Form
          ref={ref}
          validated={validated}
          noValidate
          onSubmit={onClickSubmit}
        >
          <Row className="gy-4">
            <Col>
              <CampaignInput
                required
                value={metaAdObject.startDate}
                label="* Startdatum"
                type="datetime-local"
                max={formattedMaxDate}
                onChange={(e) =>
                  setMetaAdObject({
                    ...metaAdObject,
                    startDate: e.target.value,
                  })
                }
              />
            </Col>
            <Col>
              <CampaignInput
                required
                value={metaAdObject.endDate}
                label="* Slutdatum"
                max={formattedMaxDate}
                type="datetime-local"
                onChange={(e) =>
                  setMetaAdObject({
                    ...metaAdObject,
                    endDate: e.target.value,
                  })
                }
              />
            </Col>
            {segmentIsLoading ? (
              <Spinner />
            ) : (
              <TargetGroupSize
                activity={activity}
                metaAdObject={metaAdObject}
                setMetaAdObject={setMetaAdObject}
                segment={segment}
              />
            )}

            {segmentIsLoading ? (
              <Spinner />
            ) : (
              <TargetTypeSelect
                metaAdObject={metaAdObject}
                setMetaAdObject={setMetaAdObject}
                segment={segment}
              />
            )}

            <Col className="col-12">
              <Form.Label
                htmlFor="targetadress"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Daglig Budget
              </Form.Label>
              <InputGroup>
                <Form.Control
                  className="py-3 text-xl semi-bold"
                  type="number"
                  defaultValue={metaAdObject.budget}
                  onChange={(e) =>
                    setMetaAdObject({
                      ...metaAdObject,
                      budget: e.target.value,
                    })
                  }
                  placeholder={metaAdObject.budget}
                />
                <InputGroup.Text>kr</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col className="col-12">
              <Form.Label
                htmlFor="campaignTemplate"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Visa avancerade inställningar
              </Form.Label>

              <Form.Check
                className="py-1 big-switch"
                type="switch"
                defaultChecked={showAdvancedSettings}
                onChange={() => setShowAdvancedSettings(!showAdvancedSettings)}
              />
            </Col>

            {showAdvancedSettings && (
              <Row className="mx-3 gy-3">
                <Col className="col-12">
                  <Form.Label
                    htmlFor="optimization-goal"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    Optimeringsmål
                  </Form.Label>

                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    id="optimization-goal"
                    aria-label="Default select example"
                    defaultValue={metaAdObject.optimizationGoal}
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        optimizationGoal: Number(e.target.value),
                      })
                    }
                  >
                    <option value={5}>Antal unika visningar</option>
                    <option value={8}>Antal klick</option>
                    <option value={13}>Antal visade annonser</option>
                  </Form.Select>
                </Col>
                <Col className="col-12">
                  <Form.Label
                    htmlFor="bidstrategy"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    Budstrategi
                  </Form.Label>

                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    id="bidstrategy"
                    aria-label="Default select example"
                    defaultValue={metaAdObject.bidStrategy}
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        bidStrategy: Number(e.target.value),
                      })
                    }
                  >
                    <option value={0}>Lägsta kostnad utan budtak</option>
                    <option value={1}>Lägsta kostnad med budtak</option>
                  </Form.Select>
                </Col>
                <Col className="col-12">
                  <Form.Label
                    htmlFor="billingevent"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    Betalningstyp
                  </Form.Label>

                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    id="billingevent"
                    aria-label="Default select example"
                    defaultValue={metaAdObject.billingEvent}
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        billingEvent: Number(e.target.value),
                      })
                    }
                  >
                    <option value={2}>Antal visningar</option>
                    <option value={3}>Antal Klick</option>
                  </Form.Select>
                </Col>
                <Col className="col-12">
                  <Form.Label
                    htmlFor="objective"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    Annonsmål
                  </Form.Label>

                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    id="objective"
                    aria-label="Default select example"
                    defaultValue={metaAdObject.objective}
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        objective: Number(e.target.value),
                      })
                    }
                  >
                    <option value={0}>Trafik</option>
                    <option value={1}>Medvetenhet</option>
                    <option value={2}>App-Promotion</option>
                    <option value={3}>Engagemang</option>
                    <option value={4}>Leads</option>
                    <option value={5}>Försäljning</option>
                  </Form.Select>
                </Col>
              </Row>
            )}
          </Row>
        </Form>
      </Row>
    );
  }
);

export default EditMetaAdSettings;
