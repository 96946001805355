import React from "react";
import { Row } from "react-bootstrap";
import EditDRSettings from "../components/EditDRSettings";
import RenderFullSizeOffScreen from "../components/RenderFullSizeOffScreen/RenderFullSizeOffScreen";

const DRStep2 = React.forwardRef(({ campaign, activity }, ref) => {

  return (
    <div style={{maxWidth:"1400px"}} className="mx-auto">
        <EditDRSettings campaign={campaign} activity={activity} ref={ref} />
      <RenderFullSizeOffScreen propertyName={"selectedImageFront"} activity={activity} />
      <RenderFullSizeOffScreen propertyName={"selectedImageBack"} activity={activity} />
    </div>
  );
});

DRStep2.displayName = 'DRStep2'

export default DRStep2;
