import Agent from 'api/Agent';
import React, { useContext } from 'react';
import { Alert, Button, Col, Form, Row, Spinner, Stack } from 'react-bootstrap';
import TemplateCard from './TemplateCard';
import { TemplateModalContext } from './TemplateModal';
import TemplateSlideShow from './TemplateSlideShow/TemplateSlideShow';



const TemplateContainer = ({ setTemplate, handleCloseModal }) => {
  const { search, generic } = useContext(TemplateModalContext)
  const { data: templates, isLoading } = Agent.EmailTemplate.GET.ALL({
    search: search,
    isGeneric: generic ? true : false,
  });
  const handleSelectTemplate = (template) => {
    try {
      setTemplate(template);
      handleCloseModal();
    } catch (error) {
      console.warn("unable to set template, error:", error)
    }
  }

  return (
    <Row className="d-flex flex-row flex-nowrap justify-content-center align-items-center" style={{ minHeight: "420px" }}>
      {
        isLoading ?
          <Spinner />
          :
          !templates?.length ?
            search ?

              <Alert variant='warning' style={{ maxWidth: "max-content" }} className="p-3 shadow">Fann inga mallar som innehåller: <strong>{search}</strong></Alert>
              :
              <Alert variant="warning" style={{ maxWidth: "max-content" }} className="p-3 shadow">Det finns inga mallar ännu. Skapa en </Alert>
            :
            <TemplateSlideShow templates={
              templates && templates?.map((mail) => (<TemplateCard template={mail} onClick={handleSelectTemplate} key={mail?.id} />)
              )}
            />
      }
    </Row>
  );
};

export default TemplateContainer;

