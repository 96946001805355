import { useState } from "react";
import { Offcanvas, Spinner, Row, Col, Button } from "react-bootstrap";
import LeadMenu from "./components/LeadMenu";
import LeadCompanyOverview from "./pages/LeadCompanyOverview";
import LeadCompanyEconomy from "./pages/LeadCompanyEconomy";
import styles from "./style.module.scss";
import LeadCompanyBoard from "./pages/LeadCompanyBoard";
import LeadCompanyPhoneNumbers from "./pages/LeadCompanyPhoneNumbers";
import LeadCompanyVehicles from "./pages/LeadCompanyVehicles";
import { BiSend } from "react-icons/bi";
import { qualifyLead } from "../api";
import { BsFillBookmarkCheckFill, BsCloudCheckFill } from "react-icons/bs";
import { useMutation, useQueryClient } from "react-query";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";

const options = [
  { id: 0, name: "Overview", label: "Översikt" },
  { id: 1, name: "Economy", label: "Ekonomi" },
  { id: 3, name: "Board", label: "Board" },
  { id: 4, name: "Vehicle", label: "Vehicle" },
  { id: 5, name: "Phone", label: "Phone" },
  { id: 6, name: "SimilarCompanies", label: "Liknande Företag" },
];

const LeadCompanyDetailsOffCanvas = (props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const [selectedNav, setSelectedNav] = useState(options[0].name);
  const [bgBlur, setBgBlur] = useState(false);

  const {
    data: lead,
    isLoading,
    isError,
    error,
  } = Agent.Leads.GET.ID(props.leadId);

  const handleNavSelection = (selection) => {
    setSelectedNav(selection);
  };

  const [qualifiedSuccess, setQualifiedSuccess] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: qualifyLoading,
    isError: isQualifyError,
  } = useMutation(() => qualifyLead({ id: props.leadId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["lead", props.leadId]);
      setQualifiedSuccess(true);
    },
  });

  return (
    <Offcanvas
      className={styles.leadDetailsOffCanvas}
      show={props.show}
      onHide={props.handleClose}
      placement="end"
      style={bgBlur ? { filter: "brightness(50%)" } : {}}
    >
      {isLoading && (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner style={{ width: "5rem", height: "5rem" }} />
        </div>
      )}
      {isError && (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <span className="semi-bold text-danger">
            Det gick inte att läsa in detaljerad information
          </span>
        </div>
      )}
      {lead && !isLoading && !isError && (
        <>
          <div className={styles.leadDetailsOffCanvas_Header}>
            <Offcanvas.Header
              className="flex-row-reverse justify-content-end align-items-baseline"
              closeButton
            >
              <Row className="justify-content-between w-100">
                <Col className="d-flex text-center align-items-center col-10">
                  <h5 className="text-lg regular m-2">
                    {lead.jObject.basic.data.orgnumber}
                  </h5>
                  <h2 className="display-sm semibold">
                    {lead.jObject.basic.data.name}
                  </h2>
                </Col>
                <Col className="d-flex justify-content-end">
                  {qualifiedSuccess ? (
                    <BsFillBookmarkCheckFill size={30} color="#e69500" />
                  ) : !lead.qualified ? (
                    <Button
                      variant={isQualifyError ? "warning" : "success"}
                      className=" semi-bold d-flex align-items-center"
                      onClick={() => mutate()}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {isQualifyError ? (
                        <p>Ej kvalificerad</p>
                      ) : qualifyLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <p>Kvalificera</p>
                          <BiSend size={20} className="mx-3" />
                        </>
                      )}
                    </Button>
                  ) : lead.qualified && !lead.syncedToCrm ? (
                    <BsFillBookmarkCheckFill size={30} color="#e69500" />
                  ) : lead.qualified && lead.syncedToCrm ? (
                    <Row className="px-3">
                      <Col>
                        <BsFillBookmarkCheckFill size={30} color="#e69500" />
                      </Col>
                      <Col>
                        <BsCloudCheckFill size={35} color="green" />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Offcanvas.Header>

            <LeadMenu selectedMenuItem={handleNavSelection} options={options} />
          </div>

          <Offcanvas.Body className={`${isSmallScreen ? styles.leadOffCanvasBodySmall : styles.leadOffCanvasBody }`}>
            {selectedNav === "Overview" && (
              <LeadCompanyOverview
                leadId={props.leadId}
                setBgBlur={setBgBlur}
                lead={lead}
                leadActivities={lead.activities}
              />
            )}
            {selectedNav === "Economy" && (
              <LeadCompanyEconomy lead={lead.jObject.financialSummary.data} />
            )}
            {selectedNav === "Board" && (
              <LeadCompanyBoard lead={lead.jObject.boards.data} />
            )}
            {selectedNav === "Vehicle" && (
              <LeadCompanyVehicles lead={lead.jObject} leadId={lead.id} />
            )}
            {selectedNav === "Phone" && (
              <LeadCompanyPhoneNumbers lead={lead.jObject.phoneNumbers.data} />
            )}
            {selectedNav === "SimilarCompanies" && "Liknande Företag"}
          </Offcanvas.Body>
        </>
      )}
    </Offcanvas>
  );
};

export default LeadCompanyDetailsOffCanvas;
