import Agent from "api/Agent"
import { segmentTypes } from "features/segment/segment-details/assets/config"
import { SegmentDetailsContext } from "features/segment/segment-details/SegmentDetailsOffCanvas"
import { useContext, useEffect, useState } from "react"
import { Alert, Button, Col, Row, Spinner, Stack } from "react-bootstrap"
import { toast } from "react-toastify"
import SegmentPreviewChart from "../components/SegmentPreviewChart/SegmentPreviewChart"
import { SEGMENT_PREVIEW_AGGREGATIONS } from "../SegmentPreview"


const PreviewLayout = ({ segment }) => {
  const { previewState, setPreviewState } = useContext(SegmentDetailsContext)
  const personAggr = SEGMENT_PREVIEW_AGGREGATIONS.person
  const companyAggr = SEGMENT_PREVIEW_AGGREGATIONS.company

  const [searchQuery, setSearchQuery] = useState(null)
  const [clickEvent, setClickEvent] = useState(null)

  const groupBy = {
    [segmentTypes.Person]: [
      personAggr.gender,
      personAggr.has_phone,
      personAggr.birth_year,
      personAggr.residence.type,
      personAggr.residence.living_area,
      personAggr.address.city,
    ],
    [segmentTypes.Company]: [
      companyAggr.address.city,
    ],
  }[segment?.type === segmentTypes.VehicleOwner ? segment?.subType : segment?.type]


  const previewSettings = {
    id: segment?.id, groupBy: groupBy?.join(','),
  }
  const preview = Agent.Segments.GET.PREVIEW(previewSettings);
  const previewWithFilter = Agent.Segments.GET.PREVIEW({ ...previewSettings, search: searchQuery, });

  const resetFilterSelection = () => {
    setSearchQuery(null)
    setClickEvents([])
    setClickEvent(null)
  }
  const promptFilterError = () => {
    toast.error(`Det gick inte att filtrera på [${clickEvent?.field}]`)
  }

  useEffect(() => {
    if (previewWithFilter.isError && searchQuery) {
      promptFilterError()
      resetFilterSelection();
    }
  }, [previewWithFilter.isError])

  useEffect(() => {
    if (previewWithFilter.isSuccess && searchQuery) {
      if (!previewWithFilter.data?.result || (preview.data?.count === previewWithFilter.data?.count)) {
        promptFilterError();
        resetFilterSelection();
      }
      else {

        setPreviewState({ ...previewState, size: previewWithFilter.data?.count })
      }
    }
    if (!searchQuery) {

      setPreviewState({ ...previewState, size: preview.data?.count })
    }
  }, [previewWithFilter.isSuccess, searchQuery])

  if (preview.isLoading) return <Row className="p-3 pb-5 justify-content-center"> <Spinner /> </Row>

  if (preview.isError) return <Row className="p-3 pb-5 justify-content-center"> <Alert variant="danger" className="d-flex flex-column gap-2">Ett fel uppstod. <Button variant="success" onClick={preview.refetch}>Försök igen</Button></Alert> </Row>

  if (!preview.data?.result) return <Row className="p-3 pb-5 justify-content-center"> <Alert variant="info" className="d-flex flex-column gap-2">Granskning av detta filter gav inga resultat</Alert> </Row>

  const data = previewWithFilter.data?.result ?? preview.data?.result

  const addClickEvent = (event) => {
    const events = previewState?.filters ?? []
    setClickEvent(event);
    const state = { ...previewState, filters: [...events, event] }
    setPreviewState(state)
    return state

  }

  const setClickEvents = (events) => {
    if (Array.isArray(events))
      setPreviewState({ ...previewState, filters: events })
    else
      setPreviewState({ ...previewState, filters: [] })

  }

  const handleClick = (event) => {
    const { field, chart, click } = event

    const isUnselect = (e) => (e?.field === field) && (e?.click?.key === click?.key || click?.key === undefined);
    if (isUnselect(clickEvent) || previewState.filters?.some(e => isUnselect(e))) {
      setSearchQuery(null)
      setClickEvent(null)
      setClickEvents(previewState.filters.filter(e => isUnselect(e) == false))
      return;
    }

    const selectedChart = {
      ...chart, datasets: chart?.datasets?.map((dataSet, dataSetIndex) => ({
        ...dataSet,
        backgroundColor: dataSet.data.map((_, index) => {
          const currentColorPallette = chart.datasets[dataSetIndex].backgroundColor;
          const SELECT_COLOR = currentColorPallette?.at(index % currentColorPallette?.length) ?? "#3fbde2";
          const BASE_COLOR = "#D3D3D3	";
          return (click?.event?.index === index ? SELECT_COLOR : BASE_COLOR)
        }),
        borderColor: "#000"
      }))
    }
    const updatedPreviewState = addClickEvent({ ...event, chart: selectedChart })

    const queries = updatedPreviewState.filters?.map(e => {

      const fields = e?.field.split('.');
      return `where${fields?.length > 0 ? fields?.map(x => (`[${x}]`))?.join('') : `[${field}]`
        }=${e?.click?.key}`

    })

    setSearchQuery(queries.join(','))
  }
  const chartValue = clickEvent => (!clickEvent ? undefined : { field: clickEvent?.field, data: clickEvent?.chart })

  if ([segment?.type, segment?.subType].includes(segmentTypes.Company)) return (
    <Stack className="p-3 pb-5 gap-3">
      <div className="d-flex flex-column gap-0">
        <span id="general" className="display-sm semi-bold" style={{
          color: '#017397',
        }}>Allmänt</span>
        <hr className="m-0 p-0" style={{ borderWidth: "3px", borderColor: "#335876", opacity: 0.6 }} />

      </div>
      <Row className="gx-5 gy-4">

        {preview.isSuccess && preview.data?.result && preview.data?.result.filter(aggr => [
          companyAggr.address.city,

        ].includes(aggr?.field)).map((aggr, index, arr) => {

          const relativeColumnSize = index + 1 === arr.length && (arr.length % 2) ? 12 : 6;
          return <SegmentPreviewChart aggr={aggr} col={relativeColumnSize} key={aggr.field} segment={segment} onClick={handleClick} value={chartValue(previewState.filters?.find(event => event?.field === aggr?.field))} />
        }
        )}

      </Row >
    </Stack >
  )

  return (

    <Stack className="p-3 pb-5 gap-3">
      <div className="d-flex flex-column gap-0">
        <span id="general" className="display-sm semi-bold" style={{
          color: '#017397',
        }}>Allmänt</span>
        <hr className="m-0 p-0" style={{ borderWidth: "3px", borderColor: "#335876", opacity: 0.6 }} />

      </div>
      <Row className="gx-5 gy-4 justify-content-center">

        {preview.isSuccess && data && data?.filter(aggr => [
          personAggr.gender,
          personAggr.has_phone,
          personAggr.birth_year,

        ].includes(aggr?.field)).map((aggr, index, arr) => {

          const relativeColumnSize = index + 1 === arr.length && (arr.length % 2) ? 12 : 6;
          return <SegmentPreviewChart aggr={aggr} col={relativeColumnSize} key={aggr.field} segment={segment} onClick={handleClick} value={chartValue(previewState.filters?.find(event => event?.field === aggr?.field))} />
        }

        )}

      </Row >
      <div className="d-flex flex-column gap-0">
        <span id="residence" className="display-sm semi-bold" style={{
          color: '#017397',
        }}>Boende</span>
        <hr className="m-0 p-0" style={{ borderWidth: "3px", borderColor: "#335876", opacity: 0.6 }} />

      </div>
      <Row className="gx-5 gy-4">

        {preview.isSuccess && data && data?.filter(aggr => [
          personAggr.residence.type,
          personAggr.residence.living_area,
          personAggr.address.city,

        ].includes(aggr?.field)).map((aggr, index, arr) => {

          const relativeColumnSize = index + 1 === arr.length && (arr.length % 2) ? 12 : 6;
          return <SegmentPreviewChart aggr={aggr} col={relativeColumnSize} key={aggr.field} segment={segment} onClick={handleClick} value={chartValue(previewState.filters?.find(event => event?.field === aggr?.field))} />
        }
        )}

      </Row >
    </Stack >
  )
}

export default PreviewLayout
