import axios from "api/axios";
import { endPoints } from "api/endPoints";


const URL = endPoints.visuals.URL;

export const getVisualMapData = async (GuidID, startDate, endDate, mapBounds, filters) => {
  let path = `${URL}${GuidID}/data?from=${startDate}&to=${endDate}&neLat=${mapBounds.neLat}&neLng=${mapBounds.neLng}&swLat=${mapBounds.swLat}&swLng=${mapBounds.swLng}`;
  if (filters) path += `&filters=${JSON.stringify(filters)}`

  const response = await axios.get(path);
  return response.data;
};