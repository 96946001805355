import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { Offcanvas, Row } from "react-bootstrap";
import { segmentStatuses, segmentTypes } from "./assets/config";
import NavBar from "./components/nav/NavBar";
import PreviewFilterBreadcrumNav from "./components/nav/PreviewFilterBreadcrumNav";
import AiSegmentSettings from "./features/AiSegmentSettings";
import DecisionMakers from "./features/DecisionMakers";
import Overview from "./features/Overview";
import SegmentPreview from "./features/SegmentPreview/SegmentPreview";
import SegmentSettings from "./features/SegmentSettings";
import styles from "./style.module.scss";

export const SegmentDetailsContext = createContext()

export default function SegmentDetailsOffCanvas({
  show,
  setShow,
  segment,
  options,
  inCampaignDetails
}) {
  const [previewState, setPreviewState] = useState({ filters: [], size: null });
  const NAVIGATION_OPTIONS = {
    Overview: "Översikt",
    Settings: "Filter",
    Preview: "Granska",
    DecisionMakers: "Beslutsfattare",
  }
  const OVERVIEW_ENABLED = !inCampaignDetails; // QUICKFIX = disable overview page. ISSUE: the overview page contains CampaignTable. When the CampaignTable render it reset the navigation of the campaignDetails to "Overview" and override the segmentDetails to render. Probably caused by the redux dispatch that show the campaignTable.

  const NAVIGATION_PERSON = [];
  if (OVERVIEW_ENABLED) NAVIGATION_PERSON.push(NAVIGATION_OPTIONS.Overview)
  if (segment?.status === segmentStatuses.Ready) NAVIGATION_PERSON.push(NAVIGATION_OPTIONS.Preview)
  NAVIGATION_PERSON.push(NAVIGATION_OPTIONS.Settings)

  const NAVIGATION_COMPANY = [];
  if (OVERVIEW_ENABLED) NAVIGATION_COMPANY.push(NAVIGATION_OPTIONS.Overview)
  if (segment?.status === segmentStatuses.Ready) NAVIGATION_COMPANY.push(NAVIGATION_OPTIONS.Preview)
  NAVIGATION_COMPANY.push(NAVIGATION_OPTIONS.Settings)
  NAVIGATION_COMPANY.push(NAVIGATION_OPTIONS.DecisionMakers)

  const handleClose = () => setShow(false);
  const [navBarSelection, setNavBarSelection] = useState(
    OVERVIEW_ENABLED ? NAVIGATION_OPTIONS.Overview : NAVIGATION_OPTIONS.Settings
  );

  useEffect(() => {
    show && setNavBarSelection(OVERVIEW_ENABLED ? NAVIGATION_OPTIONS.Overview : NAVIGATION_OPTIONS.Settings);
  }, [show]);

  return (
    <SegmentDetailsContext.Provider value={{ previewState, setPreviewState }}>
      <Offcanvas
        className={styles.leadDetailsOffCanvas}
        show={show}
        onHide={handleClose}
        placement="end"
      >
        <>
          <div className={styles.leadDetailsOffCanvas_Header}>
            <Offcanvas.Header
              className="flex-row-reverse justify-content-end align-items-baseline py-0 px-3"
              closeButton
            >
              {options && (
                <div className="ms-auto me-1" style={{ scale: "1.2" }}>
                  {options}
                </div>
              )}
              <h2 className="display-sm semibold px-3 pt-1">{segment?.name}</h2>
            </Offcanvas.Header>

            <NavBar
              setCurrent={setNavBarSelection}
              current={navBarSelection}
              options={
                [segment?.type, segment?.subType].includes(segmentTypes.Person)
                  ? NAVIGATION_PERSON
                  : NAVIGATION_COMPANY
              }
            />
            {NAVIGATION_OPTIONS.Preview === navBarSelection &&
              <PreviewFilterBreadcrumNav />
            }

          </div>

          <Offcanvas.Body
            className={`px-3 pt-1 ${segment?.isAiGenerated ? "ai-segment" : ""}`}
          >
            {{
              [NAVIGATION_OPTIONS?.Overview]: <Overview segment={segment} />,
              [NAVIGATION_OPTIONS.Settings]: segment?.isAiGenerated ? <AiSegmentSettings segment={segment} /> : <SegmentSettings segment={segment} />,
              [NAVIGATION_OPTIONS.DecisionMakers]: <DecisionMakers segment={segment} />,
              [NAVIGATION_OPTIONS.Preview]: <SegmentPreview segment={segment} />,
            }[navBarSelection]}
          </Offcanvas.Body>
        </>
      </Offcanvas>
    </SegmentDetailsContext.Provider>
  );
}
