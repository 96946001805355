import { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { BsFilePlus, BsArrowRightCircle } from "react-icons/bs";
import { useQuery } from "react-query";
import { getAvailableMetaPages } from "features/campaign/api";
import ImagePickerModal from "./ImagePickerModal";
import LandingPageForm from "../../landingpage/LandingPageForm";
import { useEffect } from "react";
import { forwardRef } from "react";
import Agent from "api/Agent";
import LandingPageOffCanvas from "./LandingPageOffCanvas";
import AddImages from "./AddImages";

const EditGoogleAd = forwardRef(
  (
    {
      campaign,
      activity,
      previewMutation,
      googleAdsObject,
      setGoogleAdsObject,
      setBgBlur,
    },
    ref
  ) => {
    const { data } = useQuery("availablePages", async () => {
      if (campaign) {
        const availablePages = await getAvailableMetaPages(activity.id);
        return availablePages.data;
      }
      return null;
    });

    //Set landingPageId-----------
    const [landingPageId, setLandingPageId] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const { data: lpTemplates } = Agent.LandingPages.GET.ALL();
    const { data: landingPage } = Agent.LandingPages.GET.ID(landingPageId);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
      if (selectedTemplate) {
        const template = lpTemplates[selectedTemplate - 1];
        setLandingPageId(template.id);
        setGoogleAdsObject({
          ...googleAdsObject,
          landingPageID: template.id,
        });
        console.log({ template });
      } else if (activity?.ad?.landingPageID) {
        setLandingPageId(activity.ad.landingPageID);
      } else {
        setLandingPageId(null);
      }
    }, [selectedTemplate, activity]);

    useEffect(() => {
      if (landingPage) {
        setPreviewUrl(landingPage.previewUrl);
        setGoogleAdsObject({
          ...googleAdsObject,
          adTargetUrl: landingPage.previewUrl,
        });
      } else {
        setPreviewUrl(null);
      }
    }, [landingPage]);

    //---------------------------------------------------------

    const [targetType, setTargetType] = useState(
      activity?.ad?.landingPageID ? 2 : 1
    );
    const [validated, setValidated] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [selectedImage2, setSelectedImage2] = useState(null);
    // const [selectedImage3, setSelectedImage3] = useState(null);
    // const [selectedImage4, setSelectedImage4] = useState(null);

    const [metaTemplate, setMetaTemplate] = useState(null);
    const [templateSelect, setTemplateSelect] = useState(null);
    const { data: metaTemplates } = Agent.CampaignsActivity.GET.ADTEMPLATE(
      activity?.id
    );

    console.log({ templateSelect }, { metaTemplate });

    useEffect(() => {
      if (!templateSelect) {
        setGoogleAdsObject({
          ...googleAdsObject,
          adTitle: "",
          adTargetUrl: "",
          adDescription: "",
          metaPageId: "",
        });
        return setMetaTemplate(null);
      }

      const template = metaTemplates[templateSelect - 1];
      setMetaTemplate(template);

      setGoogleAdsObject({
        ...googleAdsObject,
        adTitle: template?.title || "",
        adTargetUrl: template?.object_story_spec?.link_data?.link || "",
        adDescription:
          template?.body ||
          template?.object_story_spec?.link_data?.description ||
          "",
        metaPageId: template?.object_story_spec?.page_id || "",
      });
    }, [templateSelect]);

    useEffect(() => {
      if (!selectedImage?.id) return;

      setGoogleAdsObject({ ...googleAdsObject, imageId: selectedImage?.id });
    }, [selectedImage]);

    const onClickSubmit = (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      setValidated(true);
      if (!form.checkValidity()) {
        e.stopPropagation();
        return false;
      }

      const updatedCampaign = {
        ...activity,
        ad: {
          ...activity.ad,
          title: googleAdsObject.adTitle,
          description: googleAdsObject.adDescription,
          metaPageId: googleAdsObject.metaPageId,
          targetUrl: googleAdsObject.adTargetUrl,
          // buttonText: googleAdsObject.adButtonText,
          landingPageID: googleAdsObject.landingPageID,
          // startDate: campaign.startDate,
          // endDate: campaign.endDate,
          adMedias: [
            {
              externalId: googleAdsObject.imageId,
              //?ADD PROPERTIES HERE FOR MULTIPLE IMAGES
            },
          ],
        },
      };

      previewMutation.mutate(updatedCampaign);
    };

    const [show, setShow] = useState(false);

    const handleCloseLpOffcanvas = () => {
      setShow(false);
      setBgBlur(false);
    };

    const handleShowLpOffcanvas = () => {
      setShow(true);
      setBgBlur(true);
    };

    return (
      <>
        {show && (
          <LandingPageOffCanvas
            show={show}
            campaign={campaign}
            activity={activity}
            handleClose={handleCloseLpOffcanvas}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={onClickSubmit}
        >
          <Row className="mt-1 row-cols-1 row-cols-lg-2 g-3">
            <Col>
              <Form.Label
                htmlFor="campaignTemplate"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Kampanj mall
              </Form.Label>
              <Form.Select
                className="py-1"
                aria-label="Default select example"
                value={templateSelect || ""}
                onChange={(e) => setTemplateSelect(+e?.target?.value)}
              >
                <option value={""}>Välj en kampanj</option>
                {metaTemplates?.map((_template, index) => (
                  <option key={index} value={index + 1}>
                    {_template?.title}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col>
              <Form.Label
                htmlFor="cta-text"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Välj Meta sida
              </Form.Label>

              <Form.Select
                id="meta-pages"
                aria-label="Default select example"
                value={googleAdsObject?.metaPageId}
                onChange={(e) =>
                  setGoogleAdsObject({
                    ...googleAdsObject,
                    metaPageId: e.target.value,
                  })
                }
                required
              >
                <option value={""}>Välj din Google Ads sida</option>
                {data?.map((page, index) => (
                  <option key={index} value={page?.id}>
                    {page?.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Ange en Google Ads sida.
              </Form.Control.Feedback>
            </Col>
          </Row>
          {/* //!RADIOCHECK FÖR LANDNINGSSIDA */}
          <Row className="mt-3">
            <Col>
              <Form.Check
                inline
                label="Sätt Måladress"
                name="group1"
                type="radio"
                checked={targetType === 1}
                onChange={() => setTargetType(1)}
                value={1}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                label="Skapa Landningssida"
                name="group1"
                type="radio"
                checked={targetType === 2}
                onChange={() => setTargetType(2)}
                value={2}
              />
            </Col>
          </Row>

          <Row className="row-cols-1 mb-3">
            <Col>
              {targetType === 1 ? (
                <>
                  <Form.Label
                    htmlFor="targetadress"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    Måladress
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) =>
                        setGoogleAdsObject({
                          ...googleAdsObject,
                          adTargetUrl: e.target.value,
                        })
                      }
                      type="text"
                      name="targetadress"
                      defaultValue={googleAdsObject.adTargetUrl}
                      id="targetadress"
                      required
                      aria-describedby="basic-addon3"
                    />
                    <Form.Control.Feedback type="invalid">
                      Ange en måladress.
                    </Form.Control.Feedback>
                  </InputGroup>
                </>
              ) : (
                <Row className="align-items-center mt-3">
                  <Col className="col-6">
                    <Form.Select
                      className="py-1"
                      aria-label="Default select example"
                      value={selectedTemplate || ""}
                      onChange={(e) => setSelectedTemplate(+e?.target?.value)}
                    >
                      <option value={null}>Välj en landningssida</option>
                      {lpTemplates?.map((_template, index) => (
                        <option key={index} value={index + 1}>
                          {_template?.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col className="d-flex justify-content-end col-6">
                    <Button variant="success" onClick={handleShowLpOffcanvas}>
                      Skapa ny landningssida <BsArrowRightCircle />
                    </Button>
                  </Col>
                  {previewUrl && (
                    <Col className="mt-3">
                      <p
                        className="text-md"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Förhandsvisning:{" "}
                        <a
                          style={{
                            color: "#3ebee3",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                          href={previewUrl}
                        >
                          {previewUrl}
                        </a>
                      </p>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <h3 className=" text-lg semi-bold float-start me-auto">
              Annonstext
            </h3>
          </Row>
          <Row className="row-cols-1">
            <Col>
              <Form.Label
                htmlFor="ad-title"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Rubrik
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setGoogleAdsObject({
                    ...googleAdsObject,
                    adTitle: e.target.value,
                  })
                }
                as="textarea"
                rows={1}
                id="ad-title"
                name="ad-title"
                defaultValue={googleAdsObject?.adTitle}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en rubrik.
              </Form.Control.Feedback>
            </Col>

            <Col>
              <Form.Label
                htmlFor="ad-description"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Beskrivning
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setGoogleAdsObject({
                    ...googleAdsObject,
                    adDescription: e.target.value,
                  })
                }
                defaultValue={googleAdsObject?.adDescription}
                as="textarea"
                rows={3}
                id="ad-description"
                name="ad-description"
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en beskrivning.
              </Form.Control.Feedback>
            </Col>
          </Row>

          <AddImages
            metaTemplate={metaTemplate}
            googleAdsObject={googleAdsObject}
            setGoogleAdsObject={setGoogleAdsObject}
            activity={activity}
            campaign={campaign}
          />
        </Form>
      </>
    );
  }
);

export default EditGoogleAd;
