import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showFiltersList: false,
  activeSegment: [],
  activeFilterCategory: [],
  selectedFilterCategories: [],
  filterValues: [],
  segmentTypeId: 0,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSegmentData: (state, action) => {
      state.activeSegment = [action.payload];
    },
    setSegmentType: (state, action) => {
      state.segmentTypeId = action.payload;
    },
    //Toggles the filter dropdownlist
    setShowFiltersList: (state) => {
      state.showFiltersList = !state.showFiltersList;
    },
    //Set the active filters to display filter inputs
    setActiveFilterCategory: (state, action) => {
      state.activeFilterCategory = action.payload;
    },
    //Unset the active filters to display nothing
    clearActiveFilterCategory: (state) => {
      state.activeFilterCategory = initialState.activeFilterCategory;
    },
    //Set the selected filterCategories for a segment
    setSegmentFilterCategories: (state, action) => {
      const newCategories = action.payload.filter((category) => {
        // Check if the category is already in selectedFilterCategories
        return !state.selectedFilterCategories.some(
          (selectedCategory) => selectedCategory === category
        );
      });
      state.selectedFilterCategories = [
        ...state.selectedFilterCategories,
        ...newCategories,
      ];
    },
    pushToSelectedCategories: (state, action) => {
      state.selectedFilterCategories = [
        ...state.selectedFilterCategories,
        action.payload,
      ];
    },
    //Remove a selected filterCategory for a segment
    removeFilterCategory: (state, action) => {
      state.selectedFilterCategories = state.selectedFilterCategories.filter(
        (filterCategory) =>
          filterCategory[0].filterCategoryID !== action.payload.id
      );
    },
    editFilterCategory: (state, action) => {
      state.selectedFilterCategories.map((filterCategory, i) => {
        if (filterCategory.filterCategoryID === action.payload.id) {
          //set the filterCategory to active filter and enable editing top happen onChange
          console.log(filterCategory);
          return filterCategory;
        } else {
          return "";
        }
      });
    },
    setFilterValues: (state, action) => {
      state.filterValues = action.payload;
    },
  },
});

export const {
  setSegmentType,
  setSegmentData,
  setShowFiltersList,
  setActiveFilterCategory,
  clearActiveFilterCategory,
  setSegmentFilterCategories,
  pushToSelectedCategories,
  removeFilterCategory,
  editFilterCategory,
  setFilterValues,
} = filterSlice.actions;
