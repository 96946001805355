import { useState } from 'react';
import { Row, Col, Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setActiveChannel } from "features/campaign/campaignSlice";
import styles from "./style.module.css";
import { AiFillSetting } from "react-icons/ai";
import { CampaignOptions, CampaignActivityTypes } from "../../options";
import { useMediaQuery } from 'react-responsive';
import {
  BsFillCheckCircleFill,
  BsEyeFill,
  BsFillPlayCircleFill,
  BsPauseCircleFill,
} from "react-icons/bs";
import Agent from 'api/Agent';

export default function ChannelDetailNav({ selectedChannel, activeChannels, disabled, campaignId }) {
  const campaignQuery = Agent.Campaigns.GET.ID(campaignId);
  const activeChannel = useSelector((state) => state.campaign.activeChannel);
  const dispatch = useDispatch();
  const handleSelection = (selection) => {

    dispatch(setActiveChannel(selection));
    selectedChannel(selection);
  };
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });

  // const [showCollections, setShowCollections] = useState(false);
  // const toggleCollections = () => {
  //   setShowCollections(!showCollections);
  // };


  const collections = [CampaignOptions.Overview, CampaignOptions.Statistics, CampaignOptions.Segment, CampaignOptions.LeadGeneration];
  if (campaignId) {
    const hasAnyLandingPage = campaignQuery.data?.campaignActivities.find(x => x?.ad?.landingPageID)
    const showSignups = () => collections.push(CampaignOptions.SignUps)
    if (hasAnyLandingPage) showSignups()
  }
  return (
    <Row className="d-flex flex-row justify-content-start p-0 m-0">


      <Row className="row-cols-1 row-cols-md-2 flex-wrap justify-content-evenly p-0 m-0 " >
        <div className="d-flex flex-row row-cols-auto flex-wrap justify-items-center" style={{ borderBottom: '3px solid rgb(243 243 243)', borderRight: '2px solid rgb(243 243 243)', flexGrow: 1 }}>
          {collections?.map((collection, index) => (
            <div

              key={index}
              role={!disabled ? 'button' : ''}
              onClick={() => !disabled && handleSelection(collection)}
              style={

                { minWidth: "fit-content", flexGrow: 1, }
              }
              tabIndex={index}
              className={`text-center text-md semi-bold px-1  py-2 ${activeChannel === collection ? styles.activeNavItem : ''}`}
            >
              <span>{collection}</span>
            </div>
          ))}
        </div>

        <Row className=" row-cols-auto justify-content-evenly align-items-center" style={{ borderBottom: '3px solid rgb(243 243 243)', borderRight: '1px solid rgb(243 243 243)', flexGrow: 1 }}>
          {activeChannels && !disabled &&
            activeChannels.map(
              (channel, index) =>
                CampaignActivityTypes[channel.type] && (
                  <div

                    style={

                      { minWidth: "fit-content", flexGrow: 1, maxWidth: "250px" }
                    }

                    key={index}
                    role="button"
                    onClick={() =>
                      handleSelection(CampaignActivityTypes[channel.type])
                    }
                    className={
                      `${activeChannel === CampaignActivityTypes[channel.type]
                        ? styles.activeNavItem
                        : null
                      }
        text-center text-md semi-bold py-2 px-1 gap-1  d-flex flex-row row-cols-auto align-items-center justify-content-between`
                    }
                  >
                    {channel.type === 0 || channel.type === 2 ? (
                      <>
                        {channel.state === 0 ? (
                          <AiFillSetting size={15} color="rgb(167 171 178)" />
                        ) : channel.state === 1 ? (
                          <BsFillCheckCircleFill size={15} color="#42A146" />
                        ) : channel.state === 2 ? (
                          <BsFillPlayCircleFill size={15} color="#3EBEE3" />
                        ) : channel.state === 4 ? (
                          <BsPauseCircleFill size={15} color="#C8C58B" />
                        ) : channel.state === 5 ? (
                          <BsEyeFill size={15} color="#DDCD9E" />
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <AiFillSetting
                        size={15}
                        color={
                          channel.type === 0
                            ? channel.state === 0
                              ? "rgb(167 171 178)"
                              : channel.state === 1
                                ? "#42A146"
                                : channel.state === 2
                                  ? "#3EBEE3"
                                  : channel.state === 5
                                    ? "#DDCD9E"
                                    : "#DDCD9E"
                            : channel.state === 0
                              ? "rgb(167 171 178)"
                              : channel.state === 1
                                ? "#ded1a9"
                                : channel.state === 2
                                  ? "#96cead"
                                  : "#DDCD9E"
                        }
                      />
                    )}
                    <Col className="text-center" style={{ flexGrow: 1 }} >
                      {CampaignActivityTypes[channel.type]}
                    </Col>
                  </div>
                )
            )}
        </Row>
      </Row>
    </Row>
  );
}


// {ifXsScreen ? (
//         <Row className="col-12 justify-content-end">
//           {/* <Col className="text-center text-md semi-bold py-2 navItem">
//             <Col role="button" onClick={toggleCollections} className="d-flex align-items-start pt-3">
//               {showCollections ? <BsThreeDotsVertical size={32}/> : <BsThreeDots size={32} /> }
//             </Col>
//           </Col>
//           <Collapse in={showCollections}> */}
//           <Row >
//
//             {collections?.map((collection, index) => (
//               <Col
//                 key={index}
//                 role={!disabled ? 'button' : ''}
//                 onClick={() => !disabled && handleSelection(collection)}
//                 style={
//                   index >= collections?.length - 1
//                     ? {
//                       paddingRight: '20px',
//                       borderRight: '1px solid rgb(243 243 243)',
//                     }
//                     : null
//                 }
//                 tabIndex={index}
//                 className={`text-center text-sm semi-bold py-2 navItem ${activeChannel === collection ? styles.activeNavItem : ''}`}
//               >
//                 <Row className="justify-content-evenly col-12">
//                   <Col>{collection}</Col>
//                 </Row>
//               </Col>
//             ))}
//
//             {activeChannels && !disabled &&
//               activeChannels.map(
//                 (channel, index) =>
//                   CampaignActivityTypes[channel.type] && (
//                     <Col
//                       sm={12}
//                       key={index}
//                       role="button"
//                       onClick={() =>
//                         handleSelection(CampaignActivityTypes[channel.type])
//                       }
//                       className={
//                         `${activeChannel === CampaignActivityTypes[channel.type]
//                           ? styles.activeNavItem
//                           : null
//                         }
//         text-center text-sm semi-bold py-2 ` + styles.navItem
//                       }
//                     >
//                       <div className="d-flex align-items-center justify-content-evenly">
//                         <Col>
//                           {channel.type === 0 || channel.type === 2 ? (
//                             <>
//                               {channel.state === 0 ? (
//                                 <AiFillSetting size={15} color="rgb(167 171 178)" />
//                               ) : channel.state === 1 ? (
//                                 <BsFillCheckCircleFill size={15} color="#42A146" />
//                               ) : channel.state === 2 ? (
//                                 <BsFillPlayCircleFill size={15} color="#3EBEE3" />
//                               ) : channel.state === 4 ? (
//                                 <BsPauseCircleFill size={15} color="#C8C58B" />
//                               ) : channel.state === 5 ? (
//                                 <BsEyeFill size={15} color="#DDCD9E" />
//                               ) : (
//                                 ""
//                               )}
//                             </>
//                           ) : (
//                             <>
//                               <AiFillSetting
//                                 size={15}
//                                 color={
//                                   channel.type === 0
//                                     ? channel.state === 0
//                                       ? "rgb(167 171 178)"
//                                       : channel.state === 1
//                                         ? "#42A146"
//                                         : channel.state === 2
//                                           ? "#3EBEE3"
//                                           : channel.state === 5
//                                             ? "#DDCD9E"
//                                             : "#DDCD9E"
//                                     : channel.state === 0
//                                       ? "rgb(167 171 178)"
//                                       : channel.state === 1
//                                         ? "#ded1a9"
//                                         : channel.state === 2
//                                           ? "#96cead"
//                                           : "#DDCD9E"
//                                 }
//                               />
//                             </>
//                           )}
//                         </Col>
//                         <Col className="text-center">
//                           {CampaignActivityTypes[channel.type]}
//                         </Col>
//                       </div>
//                     </Col>
//                   )
//               )}
//           </Row>
//           {/* </Collapse> */}
//         </Row>
//
//       ) : (
//             )}
