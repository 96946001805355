import { useDroppable } from "@dnd-kit/core";
import React from "react";
import { Form } from "react-bootstrap";

export default function DroppableTextBox({ id, text, setText }) {
  const { setNodeRef } = useDroppable({
    id,
  });

  const updateText = (text, limt = 765) => {
    if (text.length <= limt) {
      setText(text);
    }
  };

  return (
    <Form.Control
      required
      key={id}
      ref={setNodeRef}
      as="textarea"
      rows={5}
      value={text}
      onChange={(e) => updateText(e.target.value)}
    />
  );
}
