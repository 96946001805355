import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Numeral from "react-numeral";
import {
  getSegmentPreview,
  segmentPreviewObject,
} from "features/segment/api";
import { useParams } from "react-router-dom";
import Agent from "api/Agent";

const SegmentReachDisplay = () => {
  //Get segment by ID------------------------------------------------------
  const { id } = useParams();
  const { data: segment } = Agent.Segments.GET.ID(id)

  //-----------------------------------------------------------
  const [segmentCount, setSegmentCount] = useState(0);
  const [hasLocalFilters, setHasLocalFilters] = useState(false);

  const queryClient = new useQueryClient();
  const segmentCountMutation = useMutation(getSegmentPreview, {
    onSuccess: (response) => {
      setSegmentCount(response.Paging.total);
      setHasLocalFilters(response.HasLocalFilters);
      queryClient.invalidateQueries("segments");
    },
  });

  useEffect(() => {
    if (segment) {
      segmentCountMutation.mutate(segmentPreviewObject(segment));
    }
  }, [segment]);

  return (
    <>
      <p className="text-lg regular">
        Storlek på målgrupp:
        <span className="display-sm semi-bold mx-1">
          {segmentCount === 0 ? (
            "-"
          ) : (
            <Numeral value={segmentCount} format={"0,0"} />
          )}
        </span>
        {segment?.type === 0 ? "företag" : segment?.type === 1 ? "personer" : "fordonsägare"}
      </p>
      {hasLocalFilters && (
        <p className="ml-3 pl-3 text-sm" style={{ color: "rgb(130 130 130)" }}>
          (
          <em>
            Detta segment använder lokala filter. Exakt beräkning görs efter det
            har sparats
          </em>
          )
        </p>
      )}
    </>
  );
};

export default SegmentReachDisplay;
