import Stepper from "components/stepper/stepper";
import styles from "../../../style.module.css";
import { Button, Col, Row } from "react-bootstrap";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const SMSStepper = ({
  activeStep,
  setActiveStep,
  steps,
  saveActivity,
  currentActivity,
  text,
}) => {
  const onContinue = (e) => {
    setActiveStep(e - 1);
  };
  const onPrev = (e) => {
    setActiveStep(e - 1);
  };
  return (
    <Col className={styles.campaignChannelFooter}>
      <Row className="row-cols-3 px-2">
        <Col className="d-flex align-items-center justify-content-start">
          {activeStep > 0 && (
            <Button
              variant="outline-dark"
              style={{ fontWeight: 600, padding: "8px 14px" }}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              <BsArrowLeft size={20} /> Gå tillbaka
            </Button>
          )}
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <Stepper
            steps={steps?.length}
            disableContinue
            activeStep={activeStep}
            onClick={(e) => e !== undefined && setActiveStep(e)}
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {steps.length - 1 !== activeStep && (
            <Button
              variant={"success"}
              style={{ fontWeight: 600, padding: "8px 14px" }}
              className="d-flex align-items-center "
              onClick={() =>
                saveActivity({
                  ...currentActivity,
                  ad: { ...currentActivity.ad, primaryText: text },
                })
              }
            >
              Spara & fortsätt <BsArrowRight size={20} />
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default SMSStepper;
