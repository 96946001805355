import React, { useState, useEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  DrawingManagerF,
  CircleF,
  PolygonF,
  StandaloneSearchBox
} from "@react-google-maps/api";
import { Button, Spinner } from "react-bootstrap";
import { useCallback } from "react";
import { useRef } from "react";
import "./style.scss"
import { MdDelete, MdLocationOff, MdLocationPin, MdMap } from "react-icons/md";
import { mapOptions, libraries } from "./assets/options";
import { BiArea } from "react-icons/bi";

const defaults = {
  height: "100%",
};


/*
https://github.com/JustFly1984/react-google-maps-api/issues/2978
Compatibility Issue with React 18+. ReactStrictMode will disable rendering  Add prefix "F" to elements
*/
const GoogleMapAreaSelect = ({
  mapRef,
  height = defaults.height,
  drawable = [],
  setDrawable
}) => {
  const drawableRefs = useRef([])
  const activeDrawableIndex = useRef();
  const drawingManagerRef = useRef();
  const searchBoxRef = useRef(null);

  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: API_KEY,
    libraries,
  });
  const circle = drawable?.find(x => x?.center)
  const [center, setCenter] = useState(circle?.center ? circle?.center : { lat: 59.336895330467506, lng: 18.02942413172608 });
  const [searchBoxResultCenter, setSearchBoxResultCenter] = useState(null);
  const [searchText, setSearchText] = useState('');
  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(searchBoxRef.current);
    if (!circle?.center) return null

    setCenter(circle?.center)
    const bounds = new window.google.maps.Circle({
      center: circle?.center,
      radius: circle?.radius,
    }).getBounds();

    map.fitBounds(bounds);

  }, []);

  useEffect(() => {
    if (!searchBoxResultCenter) return;
    const circleCenter = drawable.at(activeDrawableIndex || (drawable?.length - 1))?.center;
    if ((JSON.stringify(searchBoxResultCenter) !== JSON.stringify(circleCenter)) || !circleCenter) {
      setSearchText('')

    }

  }, [drawable?.length])

  if (!isLoaded) {
    return (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Laddar in område...
      </Button>
    );
  }

  if (loadError) {
    return (
      <Button variant="danger" disabled>
        Ett fel uppstod vid inladdning av kartan... försök igen senare.
      </Button>
    );
  }

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };


  const drawableOptions = {
    fillOpacity: 0.3,
    strokeOpacity: 1,
    fillColor: "#3ebee3",
    strokeColor: "#3ebee3",
    strokeWeight: 3,
    draggable: true,
    editable: true,
  };

  const drawingManagerOptions = {
    polygonOptions: drawableOptions,
    circleOptions: drawableOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.BOTTOM_CENTER,
      drawingModes: [
        //window.google?.maps?.drawing?.OverlayType?.POLYGON, DISABLED UNITIL API SUPPORT 
        window.google?.maps?.drawing?.OverlayType?.CIRCLE],
    },
  };

  const onLoadDrawable = (drawable, index) => {
    if (drawableRefs?.current) drawableRefs.current = []
    drawableRefs.current[index] = drawable;
    activeDrawableIndex.current = index;

  };

  const onMouseDownDrawable = (index) => {
    activeDrawableIndex.current = index;
  };

  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = (event) => {
    const map = mapRef.current;

    if (event.type === window.google.maps.drawing.OverlayType.CIRCLE) {
      const newDrawable = { center: event?.overlay?.center, radius: event?.overlay?.radius, type: event?.type }

      event.overlay?.setMap(null);
      setDrawable([
        //...drawable,
        newDrawable,
      ]);


      // fit bounds
      const bounds = new window.google.maps.Circle({
        center: event?.overlay?.center,
        radius: event?.overlay?.radius,
      }).getBounds();

      map.fitBounds(bounds);
      setCenter(bounds.getCenter());

      activeDrawableIndex.current = drawable?.length - 1
    }
    if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newDrawable = event.overlay
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      // start and end point should be same for valid geojson
      const startPoint = newDrawable[0];
      newDrawable.push(startPoint);

      event.overlay?.setMap(null);
      setDrawable([
        //...drawable,
        { coords: newDrawable, type: event.type },
      ]);

      // fit bounds
      const bounds = new window.google.maps.LatLngBounds();
      newDrawable.forEach((coord) => bounds.extend(coord));

      map.fitBounds(bounds);
      setCenter(bounds.getCenter());

      activeDrawableIndex.current = drawable?.length - 1
    }

    if (map.getZoom() > 16) map.setZoom(16)
    
    drawingManagerRef.current.setDrawingMode(null);

  };

  const onDeleteDrawing = () => {
    if (drawable[activeDrawableIndex.current])
      return setDrawable(
        drawable.filter(
          (drawable, index) => index !== activeDrawableIndex.current
        )
      );

    if (drawable.length > 0)
      return setDrawable(drawable.slice(0, drawable?.length - 1));
  };

  const onEditDrawable = (index) => {
    const drawableRef = drawableRefs.current[index];
    if (drawableRef) {
      if (drawable.at(index)?.type === window.google.maps.drawing.OverlayType.CIRCLE) {
        const allDrawable = [...drawable];
        allDrawable[index].center = drawableRef?.center;
        allDrawable[index].radius = drawableRef?.radius;
        setDrawable(allDrawable);
      }
      if (drawable.at(index)?.type === window.google.maps.drawing.OverlayType.POLYGON) {
        const coordinates = drawableRef
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

        const allDrawable = [...drawable];
        allDrawable[index].coords = coordinates;
        setDrawable(allDrawable);
      }
    }
  };

  const onPlacesChanged = () => {
    const autocomplete = searchBoxRef.current?.getPlaces();
    if (autocomplete?.length === 0) {
      return;
    }

    const place = autocomplete?.at(0)

    if (!place.geometry || !place.geometry.location) {
      console.warn('Returned place contains no geometry');
      return;
    }

    const placeBounds = new window.google.maps.LatLngBounds();
    placeBounds.extend(place.geometry.location);


    setSearchText([place?.name || "", place?.formatted_address || ""].join(", "))

    const newDrawable = {
      center: placeBounds?.getCenter(),
      radius: 100,
      type: window.google.maps.drawing.OverlayType.CIRCLE,
    };

    const circleBounds = new window.google.maps.Circle({
      center: newDrawable.center,
      radius: newDrawable.radius,
    }).getBounds();
    const map = mapRef.current;
    map.fitBounds(circleBounds);
    map.setCenter(circleBounds?.getCenter);

    setSearchBoxResultCenter(circleBounds?.getCenter())
    setDrawable([
      //...drawable,
      newDrawable,
    ]);
    activeDrawableIndex.current = drawable?.length - 1;


  };

  const fitBoundsOnIndex = (index) => {

    const drawing = drawableRefs?.current?.at(index)
    if (!drawing) return;

    const map = mapRef.current;
    // map.setZoom(mapOptions?.zoom)
    if (drawable.at(index)?.type === window.google.maps.drawing.OverlayType.CIRCLE) {
      const bounds = new window.google.maps.Circle({
        center: drawable.at(index)?.center,
        radius: drawable.at(index)?.radius,
      }).getBounds();

      map.fitBounds(bounds);
    }

    if (drawable.at(index)?.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const bounds = new window.google.maps.LatLngBounds();
      drawable.at(index)?.coords.forEach((coord) => bounds.extend(coord));

      map.fitBounds(bounds);
    }

    map.setCenter(drawing.center);

  }


  const CenterOnDrawing = () => {
    if (!drawable?.length) return;
    const index = (activeDrawableIndex?.current || (drawable?.length - 1))

    fitBoundsOnIndex(index)
  }

  return (
    <div style={{ height: height, position: "relative" }}>
      <div ref={searchBoxRef} style={{ position: 'absolute', top: 10, left: 10, zIndex: 1, width: "100%" }}>
        <StandaloneSearchBox onLoad={ref => searchBoxRef.current = ref} on
          onPlacesChanged={onPlacesChanged} >
          <input
            type="text"
            placeholder="Sök på platser"
            value={searchText}
            onChange={handleInputChange}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `40%`,
              minWidth: "140px",
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </StandaloneSearchBox>
      </div>
      {(drawable[activeDrawableIndex.current] || drawable?.length === 1) && (
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            position: "absolute",
            bottom: "20px",
            left: "55px",
            zIndex: 10,
            width: "40px",
            height: "40px",
            backgroundColor: "#FFF",
            borderRadius: 0,
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            color: "#CB4C4E",
            cursor: "pointer",
          }}
          onClick={onDeleteDrawing}
        >
          <MdDelete size={26} />
        </div>
      )}
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            position: "absolute",
            bottom: "20px",
            left: "10px",
            zIndex: 10,
            width: "40px",
            height: "40px",
            backgroundColor: "#FFF",
            borderRadius: 0,
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            color: "#343a40",
            cursor: "pointer",
          }}
          onClick={() => CenterOnDrawing()}
        >
          {drawable?.length ?
            <MdLocationPin size={24} />
            : <MdLocationOff size={24} />}
        </div>


      <GoogleMap
        options={mapOptions}
        mapContainerStyle={{
          minHeight: "27vh",
          width: "100%",
          height: "100%",
          borderRadius: "5px",
        }}
        center={center && center}
        onLoad={onLoad}
      >
        <DrawingManagerF
          onLoad={onLoadDrawingManager}
          onOverlayComplete={onOverlayComplete}
          options={drawingManagerOptions}
        />
        {drawable
          ?.filter(
            (_drawable) =>
              _drawable?.type === window.google.maps.drawing.OverlayType.POLYGON
          )
          ?.map((iterator, index) => (
            <PolygonF
              key={index}
              onLoad={(event) => onLoadDrawable(event, index)}
              onMouseDown={() => onMouseDownDrawable(index)}
              onMouseUp={() => {
                onEditDrawable(index);
              }}
              onDragEnd={() => onEditDrawable(index)}
              options={{
                ...drawableOptions,
                strokeOpacity: activeDrawableIndex.current === index || index <= 1 ? 1 : 0.1,
                editable: activeDrawableIndex.current === index || index <= 1,
              }}
              paths={iterator.coords}
              draggable
              editable
            />
          ))}

        {drawable
          ?.filter(
            (_drawable) =>
              _drawable?.type === window.google.maps.drawing.OverlayType.CIRCLE
          )
          ?.map((iterator, index) => (
            <CircleF
              key={index}
              onLoad={(event) => onLoadDrawable(event, index)}
              onMouseDown={() => onMouseDownDrawable(index)}
              onMouseUp={() => {
                onEditDrawable(index);
              }}
              onDragEnd={() => onEditDrawable(index)}
              onRadiusChanged={() => onEditDrawable(index)}
              options={{
                ...drawableOptions,
                strokeOpacity: activeDrawableIndex.current === index || index <= 1 ? 1 : 0.1,
                editable: activeDrawableIndex.current === index || index <= 1,
              }}
              center={iterator?.center}
              radius={iterator?.radius}
              draggable
              editable
            />
          ))}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapAreaSelect;
