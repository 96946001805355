import axios from "api/axios";
import { endPoints } from "api/endPoints";

//FILTER CRUDS
const FILTERTYPES_URL = endPoints.filtertypes.FILTERTYPES_URL;

export const getFiltertypes = async () => {
  const response = await axios.get(FILTERTYPES_URL);
  return response.data;
};

//FILE CRUDS
const CUSTOM_LISTS_URL = endPoints.customLists.CUSTOM_LISTS_URL;

export const uploadFile = async (formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(CUSTOM_LISTS_URL, formData, config);
};

export const segmentByFileCompanyObject = (customListID) => {
  return {
    name: "Namnlös Målgrupp",
    customListID: customListID,
    type: 0,
    filters: "[]",
    filterCategories: "[]",
  };
};

export const segmentByFilePersonObject = (customListID) => {
  return {
    name: "Namnlös Målgrupp",
    customListID: customListID,
    type: 1,
    filters: "[]",
    filterCategories: "[]",
  };
};

export const segmentByFileCombinedObject = (customListID) => {
  return {
    name: "Namnlös Målgrupp",
    customListID: customListID,
    type: 2,
    filters: "[]",
    filterCategories: "[]",
  };
};

//SEGMENT CRUDS

const SEGMENTS_URL = endPoints.segments.SEGMENTS_URL;
const SEGMENTS_COUNT_URL = endPoints.segments.SEGMENTS_COUNT_URL;

export const getSegmentPreview = async (filters, page, limit, pit) => {
  const response = await axios.post(
    `${SEGMENTS_COUNT_URL}${page ? `?page=${page}` : ""}${
      limit ? `&limit=${limit}` : ""
    }${pit ? `&pit=${pit}` : ""}`,
    filters
  );
  return response.data;
};

export const getSegments = async () => {
  const response = await axios.get(SEGMENTS_URL);
  return response.data;
};
export const getSegmentById = async (id) => {
  return await axios.get(`${SEGMENTS_URL}/${id}`);
};
export const getSegmentScoring = async (id) => {
  return await axios.get(`${SEGMENTS_URL}/${id}/scoringmodeltypes`);
};
export const addSegment = async (Segment) => {
  return await axios.post(SEGMENTS_URL, Segment);
};

export const saveSegmentFlag = async ({ id }) => {
  return await axios.post(`${SEGMENTS_URL}/${id}/save`);
};

export const updateSegment = async ({ id }, updatedSegment) => {
  return await axios.put(`${SEGMENTS_URL}/${id}`, updatedSegment);
};

export const copySegment = async ({ id }) => {
  return await axios.post(`${SEGMENTS_URL}/${id}/copy`);
};

export const deleteSegment = async ({ id }) => {
  return await axios.delete(`${SEGMENTS_URL}/${id}`, id);
};
export const segmentCompanyObject = (name) => {
  return {
    name: name,
    type: 0,
    filters: "[]",
    filterCategories: "[]",
    // filters: JSON.stringify(filters),
  };
};

export const segmentPersonObject = (name) => {
  return {
    name: name,
    type: 1,
    filters: "[]",
    filterCategories: "[]",
  };
};

export const segmentVehicleObject = (name, subType) => {
  return {
    name: name,
    type: 2,
    subType: subType,
    filters: "[]",
    filterCategories: "[]",
  };
};

export const segmentPreviewObject = (filters) => {
  return filters;
};
