import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import styles from "../style.module.scss";
import { useMediaQuery } from "react-responsive";

// const options = [
//   { id: 0, name: "Overview", label: "Översikt" },
//   { id: 1, name: "Economy", label: "Ekonomi" },
//   { id: 3, name: "Board", label: "Styrelse" },
//   { id: 4, name: "Vehicle", label: "Fordon" },
//   { id: 5, name: "Phone", label: "Telefonnummer" },
//   { id: 6, name: "SimilarCompanies", label: "Liknande Företag" },
// ];

const LeadMenu = ({ selectedMenuItem, options }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 1100 });
  const [active, setActive] = useState(options[0].name);
  const handleSelection = (selection) => {
    setActive(selection);
    selectedMenuItem(selection);
  };

  return (
    <Row className="d-flex justify-content-start px-4 g-1">
      {options.map((option) => (
        <Col
          key={option.id}
          role="button"
          onClick={() => handleSelection(option.name)}
          className={`${active === option.name ? styles.navItem : null}
                   text-center mr-2 text-md semi-bold py-2 ${isSmallScreen ? "col": "col-1"}`}
        >
          {option.label}
        </Col>
      ))}
    </Row>
  );
};

export default LeadMenu;
