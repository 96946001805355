import { Col, Row, Form } from "react-bootstrap";
import EditTargetGroup from "./editsettingscomponents/EditTargetGroup";
import EditTargetType from "./editsettingscomponents/EditTargetType";

const EditSettingsContainer = () => {

  return (
    <Row className="row-cols-1 mt-4 g-3 text-lg semi-bold edit-settings-container">


      <Col >
        <EditTargetGroup />
      </Col>
      <Col >
        <EditTargetType />
      </Col>

    </Row>
  )
};

export default EditSettingsContainer;
