import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";
import axios from "api/axios";

export const Offers = {
  GET: {
    ALL: (page = 1, limit, search, showExpired) => {
      const queryParams = [
        page && `page=${page}`,
        search && `search=${search}`,
        limit && `limit=${limit}`,
        `showExpired=${showExpired}`,
      ]?.filter((x) => x);
      const queryClient = useQueryClient();

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios.get(`offers/v1${queryString}`).then(({ data }) => data),
        queryKey: ["offers", queryString],
        // retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET offers with query: ${queryString} \n Error: `,
            error
          );
        },
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
              axios.get(`offers/v1/${id}`).then(({ data }) => data)
          ).catch((e) => Promise.reject(e)),
        // queryFn: () => axios.get(`offers/v1/${id}`).then(({ data }) => data),
        enabled : !!id,
        queryKey: ["offer", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(`Failed to GET offer with ID: ${id} \n Error: `, error);
        },
      });
    },
    PRODUCTS: () => {
      return useQuery({
        queryFn: () => axios.get(`offers/v1/products`).then(({ data }) => data),
        queryKey: ["products"],
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (error) => {
          console.warn(`Failed to GET products \n Error: `, error);
        },
      });
    },
  },
  POST: () => {
    const queryClient = useQueryClient();
    return useMutation(
      (object) =>
        Promise.resolve(
          axios.post(`/offers/v1`, object).then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("offers");
        },
        onError: (error) => {
          console.warn(`Failed to POST offer \n Error: `, error);
          toast(`[Fel] Det gick inte att skapa erbjudande`, {
            type: "error",
          });
        },
      }
    );
  },
  PUT: () => {
    const queryClient = useQueryClient();
    return useMutation(
      (object) =>
        Promise.resolve(
          axios.put(`/offers/v1/${object.id}`, object).then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("offer");
          queryClient.invalidateQueries("offers");
        },
        onError: (error) => {
          console.warn(`Failed to POST offer \n Error: `, error);
          toast(`[Fel] Det gick inte att skapa erbjudande`, {
            type: "error",
          });
        },
      }
    );
  },
  PUTIMAGE: () => {
    const queryClient = useQueryClient();
    return useMutation(
      (id, { file }) =>
        Promise.resolve(
          axios
            .put(`/offers/v1${id}/image`, file, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("offerImage");
        },
        onError: (error) => {
          console.warn(`Failed to POST offerimage \n Error: `, error);
          toast(`[Fel] Det gick inte att spara bild för erbjudande`, {
            type: "error",
          });
        },
      }
    );
  },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/offers/v1/${id}`), {
        onSuccess: (x) => {
          queryClient.invalidateQueries(["offers"]);
          queryClient.removeQueries([id]);
          toast("Erbjudance borttaget", { type: "info" });
        },
        // onError: (error) => {
        //   console.warn(`Failed to delete offer '${id}' \n Error: `, error);
        //   toast("[Fel] Erbjudandet gick inte att ta bort ", { type: "error" });
        // },
      });
    },
  },
};
