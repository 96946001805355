import Agent from "api/Agent";
import { Button, Spinner } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

const ExportExcelButton = ({ activityId, filters }) => {
  const getExportMutation = Agent.CampaignsActivity.GET.LEADS_EXPORT_MUTATION();

  const handleClick = () => {
    getExportMutation.mutateAsync({ activityId: activityId, filter: filters },
      {
        onSuccess: (data) => {
          downloadExcel(data);
        },
        onError: () => { toast.error("Ett fel uppstod vid export av lista. Försök igen.") },
      })

  }
  const downloadExcel = (data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
    const excelFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const fileBuffer = new Uint8Array(excelFile);
    const blob = new Blob([fileBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "leads.xlsx";
    link.click();
    URL.revokeObjectURL(url);
  };


  return (
    <Button
      variant="secondary"
      className="text-md btn-lg px-2"
      onClick={() => handleClick()}
      disabled={getExportMutation.isLoading}
    >
      {getExportMutation.isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="px-1">Laddar...</span>
        </>
      ) : (
        <div className="d-flex flex-row justify-content-between gap-1 align-items-center">
          <span>Exportera lista </span>
          <FiDownload />
        </div>
      )}
    </Button>)
}

export default ExportExcelButton
