import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getVisualData } from "./api";
import moment from "moment";
import { CARD_TYPES } from "./options";

export function GetSelectedPeriod(){
  const selectedPeriod = useSelector(state => state.statistics.selectedPeriod);
  const startDate = moment(selectedPeriod[0].startDate).format("YYYY-MM-DD");
  const endDate = moment(selectedPeriod[0].endDate).format("YYYY-MM-DD")
  return { startDate, endDate }
}

export function useVisualData(id, filters, limit = 5) {
  const visuals = useSelector((state) => state.statistics.visual.layout);
  
  const visualIndex = Object.keys(visuals).find(key => visuals[key].id === id);
  
  const { startDate, endDate } = GetSelectedPeriod();
  const queryName = visuals[visualIndex] !== undefined ? `visual-${startDate}-${endDate}/${visuals[visualIndex].id}-${CARD_TYPES[ visuals[visualIndex].type]}-limit:${limit}` : ""


  const { isLoading, error, data, refetch } = useQuery({
    queryKey: queryName,
    queryFn: () => getVisualData(id, startDate, endDate, filters, limit),
    retry: false,
    staleTime : 60000 * 10,
    cacheTime: 60000 * 10,
  });
  return { isLoading, error, data, refetch };
}
