import { useState } from "react";
import styles from "../../../style.module.css";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import Stepper from "components/stepper/stepper";

const formStates = {
  normal: "normal",
  error: "error",
};

const TikTokStepper = ({
  steps,
  activity,
  campaign,
  tikTokObject,
  activeStep,
  setActiveStep,
  mutate,
  isError,
  isLoading,
  formRef,
  formRef2,
}) => {
  const [formState, setFormState] = useState(formStates.normal);
  // const onClickSaveStep1 = () => {
  //   validateForm1()
  //     .then(() => {
  //       const updatedCampaign = {
  //         ...activity,
  //         // state: 1,
  //         ad: {
  //           ...activity.ad,
  //           title: tikTokObject.adTitle,
  //           description: tikTokObject.adDescription,
  //           metaPageId: tikTokObject.metaPageId,
  //           targetUrl: tikTokObject.adTargetUrl,
  //           buttonText: tikTokObject.adButtonText,
  //           primaryText: tikTokObject.primaryText,
  //           landingPageID: tikTokObject.landingPageID,
  //           startDate: activity?.ad?.startDate
  //             ? activity.ad.startDate
  //             : campaign?.startDate
  //             ? campaign?.startDate
  //             : null,
  //           endDate: activity?.ad?.endDate
  //             ? activity?.ad?.endDate
  //             : campaign?.endDate,
  //           budget: tikTokObject.budget,
  //           adMedias: [
  //             {
  //               externalId: tikTokObject.imageId,
  //             },
  //           ],
  //         },
  //       };
  //       mutate(updatedCampaign);
  //     })
  //     .catch(BadFormResponse);
  // };

  const BadFormResponse = () => {
    setFormState(formStates.error);
    // trigger onSubmit event for the form. in order to highlight form validation in the form.
    if (formRef.current) {
      const submitButton = formRef.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
    if (formRef2.current) {
      const submitButton = formRef2.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
  };

  const validateForm1 = async () => {
    setFormState(formStates.normal);
    const form = formRef?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const validateForm2 = async () => {
    setFormState(formStates.normal);
    const form = formRef2?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const onClickSaveStep1 = () => {
    // validateForm2()
    //   .then(() => {
    const updatedCampaign = {
      ...activity,
      createAd: true,
      targetGroupMaxSize: tikTokObject.targetGroupMaxSize,
      directMarketingTarget: tikTokObject.directMarketingTarget,
      ad: {
        ...activity.ad,
        startDate: tikTokObject.startDate,
        endDate: tikTokObject.endDate,
        budget: tikTokObject.budget,
        adMedias: [
          {
            externalId: tikTokObject.imageId,
          },
        ],
      },
    };
    mutate(updatedCampaign);
    // })
    // .catch(BadFormResponse);
  };

  return (
    <Col className={styles.campaignChannelFooter} style={{ height: "130px" }}>
      <Row className="row-cols-3 px-2">
        <Col className="d-flex align-items-center justify-content-start">
          {/* {activeStep === 1 && (
            <Button
              variant="outline-dark"
              style={{ fontWeight: 600, padding: "8px 14px" }}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              <BsArrowLeft size={20} /> Gå tillbaka
            </Button>
          )} */}
        </Col>

        <Col className="d-flex align-items-center justify-content-center">
          <Stepper
            steps={steps?.length}
            disableContinue
            activeStep={activeStep}
            onClick={(e) => e !== undefined && setActiveStep(e)}
          />
        </Col>

        <Col className="d-flex align-items-center justify-content-end">
          {steps.length - 1 !== activeStep && (
            <Button
              variant={
                formState === formStates.error || isError ? "danger" : "success"
              }
              style={{
                fontWeight: 600,
                padding: "12px 20px",
                fontSize: "20px",
              }}
              onClick={
                activeStep === 0
                  ? onClickSaveStep1
                  : activeStep === 1
                  ? onClickSaveStep2
                  : () => setActiveStep((prev) => prev + 1)
              }
            >
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                "Ett fel uppstod"
              ) : formState === formStates.error ? (
                <span>Inkomplett formulär</span>
              ) : (
                <span>
                  Skapa målgrupp <BsArrowRight size={20} />
                </span>
              )}
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default TikTokStepper;
