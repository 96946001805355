import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { TextInput } from "components/forms";
import { Button, Row, Col, Table, Spinner } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { TfiReload } from "react-icons/tfi";
import { BsArrowDownShort } from "react-icons/bs";
import PaginationBar from "components/pagination/PaginationBar";
import * as XLSX from "xlsx";
// import FilterLeadsButton from "./components/FilterLeadsButton";
import Agent from "api/Agent";

const SignUps = ({ campaign }) => {

  const {
    data: signups,
    isLoading,
    isError,
    refetch,
  } = Agent.SignUps.GET.ALL(campaign.id);
  console.log({ signups });
  const [currentPage, setCurrentPage] = useState(1);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const signupsdata = [
  //   {
  //     id: 1,
  //     name: "Kalle Svensson",
  //     email: "test@test.se",
  //     phone: "+46701234567",
  //     packageName: "Hockeykväll",
  //     numberOfPeople: 2,
  //     game: "Frölunda vs. Malmö Redhawks",
  //     price: "2600kr",
  //   },
  // ];

  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(signups);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
    const excelFile = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const fileBuffer = new Uint8Array(excelFile);
    const blob = new Blob([fileBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "signups.xlsx";
    link.click();
    URL.revokeObjectURL(url);
  };

  const columns = [
    { label: "Företagsamn", id: 1 },
    { label: "Tel.", id: 2 },
    { label: "Email", id: 3 },
    { label: "Paket", id: 4 },
    { label: "Match", id: 5 },
    { label: "Antal", id: 6 },
    { label: "Kostnad", id: 7 },
    { label: "Kommentar", id: 8 },
  ];

  return (
    <>
      <Row
        style={{ padding: "40px 70px 100px 70px" }}
        className="justify-content-between"
      >
        <Col className="col-12 mb-5">
          <Row>
            <Col className="col-12">
              <Row className="justify-content-between">
                <Col className="col-10">
                  <h4 className="display-sm semi-bold">Anmälningar</h4>
                  <p className="text-sm">
                    I tabellen nedan visas de som har anmält sig till det
                    rekommenderade paketet via landningssidan.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <Row className="justify-content-between">
            <Col className="col-3">
              <Row className="justify-content-start g-0">
                <Col>
                  <Button
                    variant="success"
                    className="mb-4 text-md btn-lg"
                    onClick={() => refetch()}
                  >
                    Uppdatera <TfiReload style={{ marginLeft: "6px" }} />
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="secondary"
                    className="mb-4 text-md btn-lg"
                    onClick={downloadExcel}
                  >
                    Exportera lista <FiDownload style={{ marginLeft: "6px" }} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <CardContainer
            style={{ padding: "0" }}
            children={
              <>
                {isLoading ? (
                  <Spinner />
                ) : isError ? (
                  <p className="m-5">Inga anmälningar gjorda!</p>
                ) : (
                  <Table
                    id="segmentTable"
                    className="table-container"
                    responsive
                    hover
                    size="lg"
                  >
                    <thead className="text-sm semi-bold">
                      <tr>
                        {columns.map((col) => (
                          <th key={col.id} style={{ color: "grey" }}>
                            {col.label}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody className="text-md regular">
                      {signups
                        ?.slice((currentPage - 1) * 10, currentPage * 10)
                        .map((item) => {
                          return (
                            <tr
                              key={item.id}
                              // onClick={() => handleShow(item.id, item.type)}
                            >
                              <td>{item.companyName}</td>
                              <td>{item.phone}</td>
                              <td>{item.email}</td>
                              <td>{item.package}</td>
                              <td>{item.game}</td>
                              <td>{item.amount}</td>
                              <td>{item.totalCost}</td>
                              <td>{item.comment && item.comment}</td>
                            </tr>
                          );
                        })}

                      {/* {leads && leads.length === 0 && (
                        <Col className="d-flex align-items-center justify-content-end p-3">
                          <h5 style={{ color: "#ddd" }}>Inga leads hittade.</h5>
                        </Col>
                      )} */}
                    </tbody>
                  </Table>
                )}

                {/* PAGINATION------------------------------ */}

                {signups && signups.length > 0 && (
                  <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    countPerPage={10}
                    totalCount={signups.length}
                  />
                )}

                {/* {signupsdata && signupsdata.length === 0 && (
                    <Col className="d-flex align-items-center justify-content-center p-3">
                      <h5 style={{ color: "#ddd" }}>
                        Inga Leads hittade. Rensa dina filter och/eller klicka
                        på att uppdatera och prova igen!
                      </h5>
                    </Col>
                  )} */}
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default SignUps;
