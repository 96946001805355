import { useState } from "react";
import { Button, Offcanvas, Row, Spinner, Col } from "react-bootstrap";
import PersonMenu from "./persons/components/PersonMenu";
import styles from "./style.module.scss";
import Agent from "api/Agent";
import PersonOverview from "./persons/pages/PersonOverview";
import AccountPersonInABoard from "./persons/pages/AccountPersonInABoard";
import AccountPersonPhoneNumbers from "./persons/pages/AccountPersonPhoneNumbers";

const options = [
  { id: 0, name: "Overview", label: "Översikt" },
  { id: 3, name: "Board", label: "I styrelse" },
  { id: 5, name: "Phone", label: "Telefon" },
];

const ClientPersonDetailsOffCanvas = (props) => {
  const [selectedNav, setSelectedNav] = useState(options[0].name);
  const [bgBlur, setBgBlur] = useState(false);

  const {
    data: account,
    isLoading,
    isError,
  } = Agent.Accounts.GET.ID(props.personId, props.accountType);

  console.log({ account });

  const handleNavSelection = (selection) => {
    setSelectedNav(selection);
  };

  return (
    <>
      {account && (
        <Offcanvas
          className={styles.accountDetailsOffCanvas}
          show={props.show}
          onHide={props.handleClose}
          placement="end"
          style={bgBlur ? { filter: "brightness(50%)" } : {}}
        >
          {isLoading && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner style={{ width: "5rem", height: "5rem" }} />
            </div>
          )}
          {isError && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <span className="semi-bold text-danger">
                Det gick inte att läsa in detaljerad information
              </span>
            </div>
          )}

          {account.jObject && (
            <>
              <div className={styles.leadDetailsOffCanvas_Header}>
                <Offcanvas.Header
                  className="flex-row-reverse justify-content-end align-items-baseline"
                  closeButton
                >
                  <Row className="justify-content-between w-100">
                    <Col className="d-flex text-center align-items-center col-10">
                      <h5 className="text-lg regular m-2">
                        {account?.jObject.basic.data.name.last_name}
                      </h5>
                      <h2 className="display-sm semibold">
                        {account?.jObject.basic.data.name.given_name
                          ? account?.jObject.basic.data.name.given_name
                          : account?.jObject.basic.data.name.first_name}
                      </h2>
                    </Col>
                  </Row>
                </Offcanvas.Header>

                <PersonMenu
                  selectedMenuItem={handleNavSelection}
                  options={options}
                />
              </div>

              <Offcanvas.Body className={styles.leadOffCanvasBody}>
                {selectedNav === "Overview" && (
                  <PersonOverview
                    accountId={props.accountId}
                    setBgBlur={setBgBlur}
                    account={account}
                    accountActivities={account.activities}
                  />
                )}

                {selectedNav === "Board" && (
                  <AccountPersonInABoard
                    account={account.jObject?.boards.data}
                  />
                )}

                {selectedNav === "Phone" && (
                  <AccountPersonPhoneNumbers
                    account={account.jObject?.phoneNumbers.data}
                  />
                )}
              </Offcanvas.Body>
            </>
          )}

          {!account.jObject && (
            <>
              <div className={styles.leadDetailsOffCanvas_Header}>
                <Offcanvas.Header
                  className="flex-row-reverse justify-content-end align-items-baseline"
                  closeButton
                >
                  <Row className="justify-content-between w-100">
                    <Col className="d-flex text-center align-items-center col-10">
                      <h5 className="text-lg regular m-2">
                        {account.lastName ? account.lastName : "Namn saknas"}
                      </h5>
                      <h2 className="display-sm semibold">
                        {account.firstName && account.firstName}
                      </h2>
                    </Col>
                  </Row>
                </Offcanvas.Header>

                <PersonMenu
                  selectedMenuItem={handleNavSelection}
                  options={[{ id: 0, name: "Overview", label: "Översikt" }]}
                />
              </div>

              <Offcanvas.Body className={styles.leadOffCanvasBody}>
                {selectedNav === "Overview" && (
                  <PersonOverview
                    accountId={props.accountId}
                    setBgBlur={setBgBlur}
                    account={account}
                    accountActivities={account.activities}
                  />
                )}
              </Offcanvas.Body>
            </>
          )}
        </Offcanvas>
      )}
    </>
  );
};

export default ClientPersonDetailsOffCanvas;
