import { Col, Offcanvas, Row, Stack, Button } from "react-bootstrap";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveChannel, setSelectedChannel } from "../campaignSlice";
import styles from "./style.module.css";
import ChannelDetailNav from "./features/nav/ChannelDetailNav";
import { useEffect } from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { CampaignActivityTypes, CampaignOptions } from "./options";
import "./style.scss";
import CampaignTooltip from "./features/CampaignTooltip";
import Agent from "api/Agent";
import RenderChannels from "./components/RenderChannels";
import { Link, useNavigate } from "react-router-dom";

const CampaignDetailsOffCanvas = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedChannel = useSelector(
    (state) => state.campaign.selectedChannel
  );
  const handleNavSelection = (selection) => {
    dispatch(setSelectedChannel(selection));
  };
  //FOR SETTING THE OFFCANVAS BG TO BLUR WHEN LANDINGPAGEOFFCANVAS IS OPEN
  const [bgBlur, setBgBlur] = useState(false);

  useEffect(() => {
    dispatch(setSelectedChannel(CampaignOptions.Overview));
    dispatch(setActiveChannel(CampaignOptions.Overview));
  }, [])


  const {
    data: campaign,
    isLoading: campaignIsLoading,
    isError,
    error,
  } = Agent.Campaigns.GET.ID(props.campaignId);

  if (campaignIsLoading)
    return (
      <Offcanvas
        className={styles.campaignDetailsOffCanvas}
        show={props.show}
        onHide={props.handleClose}
        placement="end"
      >
        <span> Laddar... </span>
      </Offcanvas>
    );

  if (isError)
    return (
      <Offcanvas
        className={styles.campaignDetailsOffCanvas}
        show={props.show}
        onHide={props.handleClose}
        placement="end"
      >
        <p>{error.message}</p>
      </Offcanvas>
    );

  const MissingSegment = !campaign?.segmentID;
  const NotFinished = campaign?.completedSteps.length < 6;
  const conditions = [
    MissingSegment && !NotFinished && (
      <div
        style={{ background: "#ffc10780" }}
        className="border h-100 col-10 col-md-6 mx-auto p-4 rounded d-flex flex-column align-items-center text-center"
      >
        <span
          style={{ color: "#414141" }}
          className="text-center semi-bold display-sm "
        >
          <strong>Målgrupp saknas!</strong>
        </span>
        <span
          style={{ color: "rgb(65 65 65 / 51%)" }}
          className="semi-bold text-lg "
        >
          En målgrupp behövs för att driva en kampanj.
        </span>
        <Button
          className="mt-3"
          variant="success"
          style={{ width: "300px", fontWeight: 600 }}
          onClick={() =>
            navigate(`/campaigns/create-campaign/${campaign?.id}/target-group`)
          }
        >
          Justera målgrupp <BsArrowRightCircle className="mx-3" />
        </Button>
      </div>
    ),
    NotFinished && (
      <div
        style={{ background: "#ffc10780" }}
        className="h-100 border col-10 col-md-6 mx-auto p-4 rounded d-flex flex-column align-items-center text-center"
      >
        <span
          style={{ color: "#414141" }}
          className="text-center semi-bold display-sm"
        >
          <strong>Kampanjen är inte färdigskapad!</strong>
        </span>
        <span
          style={{ color: "rgb(65 65 65 / 51%)" }}
          className="semi-bold text-lg "
        >
          Justera kampanjen och fortsätt sedan här.
        </span>
        <Button
          className="mt-3"
          variant="success"
          style={{ width: "300px", fontWeight: 600 }}
          onClick={() =>
            navigate(
              `/campaigns/create-campaign/${campaign?.id}/${campaign.completedSteps[campaign.completedSteps.length - 1] !==
                ""
                ? campaign.completedSteps[campaign.completedSteps.length - 1]
                : "type-of-campaign"
              }`
            )
          }
        >
          Justera kampanjen <BsArrowRightCircle className="mx-3" />
        </Button>
      </div>
    ),
  ].filter((x) => x);

  if (conditions.some((x) => x))
    return (
      <Offcanvas
        className={styles.campaignDetailsOffCanvas}
        show={props.show}
        onHide={props.handleClose}
        placement="end"
      >
        <Offcanvas.Header
          className="flex-row-reverse justify-content-end align-items-baseline pb-0"
          closeButton
        >
          <Col className="d-flex align-items-center justify-content-end px-5">
            <CampaignTooltip selectedChannel={selectedChannel} />
          </Col>
          <Col className="d-flex justify-content-start ms-3 align-items-baseline">
            <h2 className="display-sm semibold">{campaign.name}</h2>
          </Col>
        </Offcanvas.Header>
        <ChannelDetailNav
          selectedChannel={handleNavSelection}
          activeChannels={campaign.campaignActivities}
          campaignId={props?.campaignId}
          disabled
        />
        <Offcanvas.Body>
          <Stack direction="horizontal" className="p-5 gap-3 mt-4">
            {conditions.map((x) => x)}
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    );


  return (
    <Offcanvas
      className={styles.campaignDetailsOffCanvas}
      show={props.show}
      onHide={props.handleClose}
      placement="end"
      style={bgBlur ? { filter: "brightness(50%)" } : {}}
    >
      <>
        <div className={styles.campaignDetailsOffCanvas_Header}>
          <Offcanvas.Header
            className="flex-row-reverse row-cols-auto justify-content-between align-items-baseline pt-2 pb-0"
            closeButton
          >
            <Col className="d-flex align-items-center justify-content-end pe-3">
              <CampaignTooltip selectedChannel={selectedChannel} />
            </Col>
            <Col className="d-flex justify-content-start ms-3 align-items-baseline" style={{ flexGrow: 1, flexShrink: 1 }}>
              <h2 className="display-sm semibold ">{campaign.name}</h2>
            </Col>
          </Offcanvas.Header>

          <ChannelDetailNav
            selectedChannel={handleNavSelection}
            activeChannels={campaign.campaignActivities}
            campaignId={props?.campaignId}
          />
        </div>

        <Offcanvas.Body>
          {
            <RenderChannels
              campaignIsLoading={campaignIsLoading}
              campaign={campaign}
              selectedChannel={selectedChannel}
              setBgBlur={setBgBlur}
            />
          }
        </Offcanvas.Body>
      </>
    </Offcanvas>
  );
};

export default CampaignDetailsOffCanvas;
