import { Row, Col, Container } from "react-bootstrap";
import { ButtonMain } from "components/buttons";
import { NewUser } from "features/users/my-users/components";
import ExportsTable from "features/exports/my-exports/ExportsTable";

const Exports = () => {
  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className="justify-content-between">
            <Col>
              <h2>Mina Exporter</h2>
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <ExportsTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Exports;
