import React, { useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Collapse,
  Spinner,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useQueryClient } from "react-query";
import {
  addCustomersList,
  verifyCustomersList,
  downloadListTemplate,
} from "features/accounts/api";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./style.module.scss";
import {
  MdFileUpload,
  MdCheckCircle,
  MdFileDownload,
  MdOutlineCancel,
  MdArrowRightAlt,
  MdOutlineInfo,
} from "react-icons/md";
import { useEffect } from "react";

const AddClientsOffCanvas = ({ show, setShow }) => {
  const queryClient = useQueryClient();
  const handleClose = () => setShow(false);
  const [accountType, setAccountType] = useState(6);
  const [delimiterChoice, setDelimiter] = useState(",");
  const [idColumn, setIdColumn] = useState("id");
  const [nameColumn, setNameColumn] = useState("name");
  const [listVerified, setListVerified] = useState(false);
  const [missingColumns, setMissingColumns] = useState(null);
  const [listNeedsAdjustment, setListNeedsAdjustment] = useState(false);
  const [listUploaded, setListUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [presavedFile, setPresavedFile] = useState(null);
  const [response, setResponse] = useState(null);
  const handleAccountTypeChange = (value) => {
    setAccountType(value);
  };

  const handleOptionChange = (event) => {
    setDelimiter(event.target.value);
  };

  //VERIFY FILE FUNCTIONALITY
  const fileVerifyInputRef = useRef(null);

  const handleClickVerify = () => {
    fileVerifyInputRef.current.click();
  };

  const handleFileVerify = (e) => {
    e.preventDefault();
    setLoading(true);
    const delimiter = delimiterChoice;
    const file = fileVerifyInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    setResponse(null)
    verifyCustomersList(delimiter, accountType, formData).then((res) => {
      setResponse(res)
      console.log({ res });
      if (!res.IsValid) {
        setLoading(false);
        setListNeedsAdjustment(true);
        setMissingColumns(res.MissingColumns);
        setListVerified(false);
      }

      if (res.IsValid) {
        setListNeedsAdjustment(false);
        setLoading(false);
        setListVerified(true);
        setPresavedFile(formData);
      }
    });
  };

  //GET LIST TEMPLATE
  const downloadTemplate = () => {
    downloadListTemplate(accountType).then((res) => {
      window.open(res.request.responseURL);
    });
  };

  //UPLOAD FILE FUNCTIONALITY
  const fileInputRef = useRef(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const handleFileUpload = (e) => {
    e.preventDefault();
    const type = accountType;
    const delimiter = delimiterChoice;
    const isFirstRowHeader = true;
    const file = presavedFile;
    const idColumnName = idColumn;
    const nameColumnName = nameColumn;
    setLoading(true);
    setShowOverlay(false);
    addCustomersList(
      type,
      delimiter,
      isFirstRowHeader,
      idColumnName,
      nameColumnName,
      file
    ).then((res) => {
      console.log({ res });
      setLoading(false);
      setListUploaded(true);
      queryClient.invalidateQueries(["accounts", accountType]);
    });
  };

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: "unset" }}>
      <Popover.Header as="h3">
        Är du säker på att du vill ladda upp denna lista?
      </Popover.Header>
      <Popover.Body>
        <Button
          variant="success"
          className="mx-2 text-md btn-lg"
          onClick={handleFileUpload}
        >
          {loading ? <Spinner /> : "Ja, ladda upp!"}
        </Button>
        <Button
          variant="secondary"
          className="mx-2 text-md btn-lg"
          onClick={() => setShowOverlay(false)}
        >
          Avbryt
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      className={styles.offcanvas}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Ladda upp lista</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          {!listUploaded ? (
            <Form>
              <Col className="col-6">
                <Form.Label>Avgränsare</Form.Label>
                <Form.Select
                  value={delimiterChoice.value}
                  onChange={handleOptionChange}
                >
                  <option value=",">Decimal (,)</option>
                  <option value=".">Punkt (.)</option>
                  <option value="tab">Tab</option>
                  <option value=";">Semikolon (;)</option>
                  <option value="|">Pipe (|)</option>
                </Form.Select>
              </Col>
              <Col className="col-12 my-3">
                <Form.Label>Typ</Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      type="radio"
                      id="default-radio"
                      label="Företag"
                      value={6}
                      checked={accountType === 6}
                      onChange={() => handleAccountTypeChange(6)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      id="default-radio"
                      label="Personer"
                      value={5}
                      checked={accountType === 5}
                      onChange={() => handleAccountTypeChange(5)}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="radio"
                      id="default-radio"
                      label="Gjorda köp"
                      value={3}
                      checked={accountType === 3}
                      onChange={() => handleAccountTypeChange(3)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="mt-5">
                <input
                  type="file"
                  onChange={handleFileVerify}
                  ref={fileVerifyInputRef}
                  style={{ display: "none" }}
                />
                <Button
                  variant={listVerified ? "warning" : "secondary"}
                  disabled={listVerified}
                  className="text-md btn-lg"
                  style={{
                    marginRight: "10px",
                    color: listVerified ? "#fff" : "",
                    background: listVerified ? "#84c58f" : "",
                  }}
                  onClick={handleClickVerify}
                >
                  {loading ? (
                    <Spinner />
                  ) : listVerified ? (
                    <>
                      Lista Verifierad!{" "}
                      <MdCheckCircle style={{ marginLeft: "6px" }} />
                    </>
                  ) : (
                    <>
                      Verifiera lista{" "}
                      <MdFileUpload style={{ marginLeft: "6px" }} />
                    </>
                  )}
                </Button>

                {/* <MdArrowRightAlt size={30} color="grey" /> */}

                {!listVerified && (
                  <>
                    <Button
                      variant="success"
                      className="text-md btn-lg mx-2"
                      onClick={downloadTemplate}
                    >
                      Ladda ned mall för lista
                      <MdFileDownload style={{ marginLeft: "6px" }} />
                    </Button>
                    <p
                      style={{
                        color: "rgb(197 173 72)",
                        marginTop: "20px",
                        fontSize: "12px",
                      }}
                    >
                      <MdOutlineInfo className="m-2" />
                      Knapparna baseras på valen av avgränsare och typ, så glöm
                      inte att sätta dessa först.
                    </p>
                  </>
                )}

                {listVerified && (
                  <>
                    <MdArrowRightAlt size={30} color="grey" />
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={popover}
                      show={showOverlay}
                    >
                      <Button
                        variant="success"
                        className="mx-2 text-md btn-lg"
                        onClick={() => setShowOverlay(true)}
                      >
                        Ladda upp lista{" "}
                        <MdFileUpload style={{ marginLeft: "6px" }} />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
              </Col>
              {listNeedsAdjustment && (
                <Col style={{ color: "grey" }} className="m-3">
                  <p
                    style={{
                      color: "red",
                      marginBottom: "20px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Listan behöver justeras, ladda ned en mall och prova igen.
                  </p>
                  <div className="d-flex flex-column gap-2" >
                    {
                      response && Object.keys(response).map(key =>
                        typeof response[key] == "object" && !!response[key]?.length ?
                          <div className="d-flex flex-column gap-2">
                            <code>{`${key} [${response[key]?.length}]:`}</code>
                            {
                              response[key]?.map(x => <code>{JSON.stringify(x)}</code>)
                            }
                          </div>
                          :
                          <div className="d-flex flex-column gap-2">
                            <code>{`${key} :`} {JSON.stringify(response[key])}</code>
                          </div>
                      )}
                  </div>
                  {/* <h6>Följande kolumner saknas:</h6> */}
                  {/* <ul> */}
                  {/*   {missingColumns?.map((col) => ( */}
                  {/*     <li>{col}</li> */}
                  {/*   ))} */}
                  {/* </ul> */}
                </Col>
              )}
            </Form>
          ) : (
            <Col className="w-100 d-flex align-items-center justicy-content-center">
              <MdCheckCircle color="green" size={120} />{" "}
              <p className="display-md">Lista Uppladdad!</p>
            </Col>
          )}
        </Container>
      </Offcanvas.Body>
    </Offcanvas >
  );
};

export default AddClientsOffCanvas;
