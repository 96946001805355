import axios from "api/axios";
import { endPoints } from "api/endPoints";

const CAMPAIGNS_URL = endPoints.campaigns.CAMPAIGNS_URL;

export const updateCampaignActivity = async (
  { id },
  { activityId },
  updatedCampaign
) => {
  return await axios.put(
    `${CAMPAIGNS_URL}/${id}/activity/${activityId}`,
    updatedCampaign
  );
};

export const updateCampaignActivityIsActive = async (id, activityId, obj) => {
  return await axios.put(`${CAMPAIGNS_URL}/${id}/activity/${activityId}`, obj);
};

export const sendCampaignEmail = async (id, activityId) => {
  console.log({ id }, { activityId });
  return await axios.post(
    `/campaigns/v1/${id}/activity/${activityId}/email/send`
  );
};

export const sendTestCampaignEmail = async (id, activityId, mail) => {
  console.log({ id }, { activityId });
  return await axios.post(
    `/campaigns/v1/${id}/activity/${activityId}/email/test?testEmail=${mail}`
  );
};
export const unscheduleEmail = async (id, activityId) => {
  console.log({ id }, { activityId });
  return await axios.post(
    `/campaigns/v1/${id}/activity/${activityId}/email/unschedule`
  );
};

export const campaignObject2 = (name, startDate, endDate, budget, offerID) => {
  return {
    name: name,
    startDate: startDate,
    endDate: endDate,
    totalBudget: budget,
    offerId: offerID,
  };
};

export const campaignObject = (
  // name = `Ny kampanj - ${Math.floor(Math.random() * 100) + 1}`,
  name,
  offerId,
  type = 0,
  autoscoring = true,
  autoSegment = true,
  conversionType = 0
) => {
  return {
    name: name,
    offerId: offerId ? offerId : null,
    type: type,
    autoscoring: autoscoring,
    autoSegment: autoSegment,
    conversionType: conversionType,
  };
};

//AVAILABLE CAMPAIGN IMAGES
export const addImage = async ({ activityId }, image) => {
  return await axios.post(
    `${CAMPAIGNS_URL}/${activityId}/availableimages`,
    image,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const getAvailableMetaPages = async (activityId, adAccountId) => {
  const queryParams = [adAccountId && `adAccountId=${adAccountId}`]?.filter(
    (x) => x
  );
  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return await axios.get(
    `${CAMPAIGNS_URL}/${activityId}/availablePages${queryString}`
  );
};

export const getCampaignLeads = async (
  activityId,
  page,
  search,
  isInCrm,
  hasActivity,
  score
) => {
  const queryParams = [
    page && `page=${page}`,
    search && `search=${search}`,
    isInCrm !== "" && isInCrm !== undefined && `isInCrm=${isInCrm}`,
    hasActivity !== "" &&
      hasActivity !== undefined &&
      `hasActivity=${hasActivity}`,
    score !== "" && score !== undefined && `score=${score}`,
  ]?.filter((x) => x);

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
  return await axios.get(`${CAMPAIGNS_URL}/${activityId}/leads${queryString}`);
};
