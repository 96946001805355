import "../style.scss";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { optionsPrioritize } from "../data/stepsData";
import CampaignRadioBox from "../components/CampaignRadioBox";
import StepTopRow from "../components/StepTopRow";
import StepBottomRow from "../components/StepBottomRow";
import ScoreRow from "../components/ScoreRow";
import { getSegmentScoring } from "features/segment/api";
import Agent from "api/Agent";
import { useMediaQuery} from "react-responsive";

const Prioritize = () => {
  const isXsScreen = useMediaQuery({ maxWidth: 900 });
  const paddingBottomStyle = isXsScreen ? { paddingBottom: '300px' } : {};

  const navigate = useNavigate();
  //Get Campaign-----------------------------------------------
  //Get campaign id from URL
  const { id } = useParams();

  //Get campaign data using id
  const { data: campaign } = Agent.Campaigns.GET.ID(id);

  const [autoScoring, setAutoScoring] = useState(true);
  const [scoring, setScoring] = useState(null);
  const [scoringId, setScoringId] = useState(null);

  const onClickHandler = (option) => {
    setAutoScoring(option.value);
    !option.value && getScoring();
  };

  useEffect(() => {
    if (campaign) {
      setAutoScoring(campaign.autoScoring);
      setScoringId(campaign.scoringModelID);
    }
  }, [campaign]);

  //Submit-------------------------------------------------
  //Update campaign function
  const [loading, setLoading] = useState(false);
  const { mutate: updateCampaign } = Agent.Campaigns.PUT.CAMPAIGN();
  const mutate = (campaign) =>
    updateCampaign(campaign, {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        navigate(`/campaigns/create-campaign/${id}/ad-channel`);
      },
    });

  const { mutate: getScoring } = useMutation(
    () => getSegmentScoring(campaign?.segmentID),
    {
      onSuccess: ({ data }) => {
        setScoring(data);
      },
    }
  );
  //onClick
  const onClickSubmit = () => {
    const updatedCampaign = {
      ...campaign,
      autoScoring: autoScoring,
      scoringModelID: scoringId,
      completedSteps: !campaign.completedSteps.some(
        (step) => step === "prioritize"
      )
        ? [...campaign.completedSteps, "prioritize"]
        : [...campaign.completedSteps],
    };
    mutate(updatedCampaign);
  };

  return (
    <>
    <Row className="m-0 " style={paddingBottomStyle} >
      {/* TOP----------------------- */}
      <StepTopRow heading="Prioritering" />
      {/* MIDDLE----------------------- */}
      <Col className="col-12 p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h4 className="text-lg regular">
              Här kan du prioritera vilka personer i din målgrupp som kampanjen
              i första hand ska riktas mot
            </h4>
          </Col>
          <Col className="col-12">
            <Row className="justify-content-between gx-4">
              {optionsPrioritize.map((option) => (
                <Col key={option.id} onClick={() => onClickHandler(option)}>
                  <CampaignRadioBox
                    {...option}
                    current={option}
                    value={autoScoring}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
      {/* ScoreRow */}
    <Col className="col-12 p-5">
    <Row className="gy-4">
      {!autoScoring &&
        scoring &&
        scoring.map((score, index) => (
          <Col key={index} className="col-12 px-5">
            <ScoreRow
              label={score?.name}
              subText={score?.description}
              value={1}
            />
          </Col>
        ))}
        </Row>
        </Col>  

      {/* BOTTOM-----------------------  */}

      {autoScoring ? (
        <StepBottomRow
          // prioritize={false}
          onClickSubmit={() => onClickSubmit()}
          loading={loading}
        />
      ) : (
        <StepBottomRow
          // prioritize={true}
          onClickSubmit={() => onClickSubmit()}
          loading={loading}
        />
      )}
      </Row> 
      </>
  );
};

export default Prioritize;
