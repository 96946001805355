import { Row, } from "react-bootstrap";

import Navbar from "../../settings-details/components/settingsNav/Navbar";


const SettingsTabs = () => {



  return (
    
    <Row className="gy-2 m-0 row-cols-1 ">
    {/* <Navbar></Navbar> */}

    </Row>
  );
};



export default SettingsTabs;


