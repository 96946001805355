import { FilterItem } from 'components/filter'
import React from 'react'
import * as icons from "react-icons/md";

export default function DisplayCategory({ category }) {
  return (
    <FilterItem
      title={category?.title}
      subTitle={category?.subTitle}
      icon={icons[category?.icon] && React.createElement(icons[category?.icon], {
        className: "my-auto",
        style: {
          width: "25px",
          height: "25px",
        },
      }
      )}
      iconBg={category?.iconBg ? category?.iconBg : "#dddddd"}
    />
  )
}
