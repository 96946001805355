import { Col, Row } from "react-bootstrap";

import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

const MobileSMSPreview = ({ text, senderName }) => {
  const styles = {
    mobileFrame: {
      position: "absolute",
      top: "50px",
      zIndex: 1,
      width: "380px",
      objectFit: "scale-down",
      pointerEvents: "none",
    },
    iframe: {
      width: "100%",
      height: "100%",
    },
    infoContainer: {
      width: "318px",
      height: "650px",
      backgroundColor: "#fff",
      borderRadius: "8px",
    },
  };

  return (
    <Row className="d-flex align-items-center justify-content-center">
      {/* PREVIEW--------------------------------------------- */}
      <Col className="col-12 d-flex justify-content-center mt-5">
        <DeviceFrameset
          device="iPhone 8"
          color="silver"
          width={318}
          height={568}
        >
          <Col style={styles.infoContainer}>
            <Row
              className="align-items-center justify-content-center flex-column text-center"
              style={{
                borderBottom: "1px solid #eaecf0",
                height: "70px",
                background: "rgb(239 237 235 / 46%)",
                position: "relative",
              }}
            >
              <Col
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "rgb(179 209 215)",
                  fontSize: "20px",
                  top: "5px",
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  background: "rgb(209 237 242 / 55%)",
                }}
              >
                {senderName ? senderName.substring(0, 1) : "A"}
              </Col>
              <Col
                style={{
                  marginTop: "45px",
                  fontSize: "13px",
                  color: "rgb(145 173 179)",
                }}
              >
                {senderName ? senderName : "Autotarget"}
              </Col>
            </Row>

            <Row style={{ width: "90%" }}>
              {text && (
                <Col
                  className="m-4"
                  style={{
                    height: "100%",
                    background: "rgba(239, 237, 235, 0.49)",
                    borderRadius: "8px",
                    padding: "3px 10px",
                    fontSize: "14px",
                    fontWeight: 600,
                    boxShadow:
                      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                    border: "1px solid #eaecf0",
                  }}
                >
                  {text}
                </Col>
              )}
            </Row>
          </Col>
        </DeviceFrameset>
      </Col>
    </Row>
  );
};

export default MobileSMSPreview;
