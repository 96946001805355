import { Card, Carousel } from "react-bootstrap"

const PrintPreview = ({sources = []}) => {
  return (
<Card
          className={"card-container  "}
        >
          <Card.Body className={"px-2 pt-1 pb-4 d-flex flex-column"}>
            <span className="text-md semi-bold py-2">Granska utskick</span>

            <Carousel                       >
              {
                sources.filter(x => x)?.map((img, index) => <Carousel.Item key={index} interval={10000} >
                  <div className="d-flex justify-content-center align-items-center ">
                    <img
                      alt={"first page of print, front"}
                      crossOrigin="anonymous"
                      src={
                        (img)}
                      style={{ width: "100%", maxHeight: "70vh", objectFit: "contain", borderRadius:"2px" }}
                    ></img>
                  </div>
                  <Carousel.Caption
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "10px",
                    }}
                  >
                    <h3>{index === 0 ? "Framsida" : "Baksida"}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                )
              }
            </Carousel>
          </Card.Body>
        </Card>
  )
}

export default PrintPreview
        
