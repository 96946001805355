import { Row, Col } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";

const CampaignActivityTargets = {
  Company: 0,
  BoardMembers: 1,
  DecisionMaker: 2,
  Person: 3,
  Household: 4,
  Contacts: 5,
  AllInHousehold: 6
};

const EditTargetType = ({ activity }) => {
  // Map the enum values to their descriptions
  const targetDescriptions = {
    [CampaignActivityTargets.Company]: "Företag",
    [CampaignActivityTargets.BoardMembers]: "Alla personer i styrelsen",
    [CampaignActivityTargets.DecisionMaker]: "Beslutsfattare",
    [CampaignActivityTargets.Person]: "Personer i målgruppen",
    [CampaignActivityTargets.Household]: "Övriga personer i hushållet",
    [CampaignActivityTargets.Contacts]: "Kontaktpersoner",
    [CampaignActivityTargets.AllInHousehold]: "Alla i hushållet"
  };

  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{minWidth:"170px"}}>
        <p>Typ av mottagare</p>
      </Col>
      <Col className="flex-grow-1">
        <p style={{ color: "#545F61" }} className="text-center">
          {/* Access the description based on the activity.directMarketingTarget */}
          {targetDescriptions[activity?.directMarketingTarget]}
        </p>
      </Col>
      <Col className="flex-shrink-1 edit-col-icon">
        <AiFillLock color="rgb(212 212 212)" />
      </Col>
    </Row>
  );
};

export default EditTargetType;
