import {
  CampaignActivityTypes,
  CampaignOptions,
} from "features/campaign/campaign-details/options";
import { useState } from "react";
import { useRef } from "react";
import {
  Dropdown,
  Form,
  Button,
  Badge,
  Accordion,
  DropdownDivider,
  AccordionButton,
} from "react-bootstrap";
import { BiTargetLock } from "react-icons/bi";
import { FaTiktok } from "react-icons/fa";
import { MdFilterList, MdMailOutline, MdOutlineSms } from "react-icons/md";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { TbBrandMeta } from "react-icons/tb";

const STATUS_PREFIX = "status-";
const ACTIVITY_PREFIX = "activity-";

const FilterDropdown = ({ setActivityFilter, setStatusFilter }) => {
  const ref = useRef();
  const [activeCount, setActiveCount] = useState(null);
  const resetForm = () => {
    ref?.current?.reset();
    handleChange();
  };
  const filterPrefixValues = (array, prefixSelector) => {
    const checkedValues = array.filter((x) => x?.includes(prefixSelector));
    return checkedValues?.map((x) => x.split(prefixSelector).pop());
  };

  const handleChange = () => {
    const formData = new FormData(ref.current);
    const formDataArray = Array.from(formData.entries());
    // formDataArray contains both key and value. key will be missing if not checked, and "on" if checked.
    // We will only need the key (ie. the first item in each entry)
    const checkedInputs = formDataArray?.map((x) => x[0]);
    setActiveCount(checkedInputs.length ?? 0);

    const statusFilter = filterPrefixValues(checkedInputs, STATUS_PREFIX);
    try {
      const numberOfStatusFilters = Array.from(ref.current.elements)?.filter(
        (x) => x?.name?.includes(STATUS_PREFIX)
      )?.length;
      const isAllSelected = numberOfStatusFilters === statusFilter?.length;

      if (isAllSelected) setStatusFilter([]);
      else setStatusFilter(statusFilter);
    } catch {
      return false;
    }

    const activityFilter = filterPrefixValues(checkedInputs, ACTIVITY_PREFIX);
    try {
      const numberOfActivityFilters = Array.from(ref.current.elements)?.filter(
        (x) => x?.name?.includes(ACTIVITY_PREFIX)
      )?.length;
      const isAllSelected = numberOfActivityFilters === activityFilter?.length;

      if (isAllSelected) setActivityFilter([]);
      else setActivityFilter(activityFilter);
    } catch {
      return false;
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="light"
        className="d-flex align-items-center px-4 py-2 gap-1 filter-button semi-bold text-md"
      >
        <MdFilterList />
        <span>Filter</span>
        {activeCount > 0 && (
          <span className="filter-indicator">{activeCount}</span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-2 rounded shadow ">
        <Form
          ref={ref}
          style={{ minWidth: "280px", maxWidth: "320px" }}
          className="d-flex flex-column justify-content-center align-content-center p-2"
          onInput={handleChange}
        >
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <StatusOptions />
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <ActivityOptions />
            </Accordion.Item>
          </Accordion>
        </Form>
        <AccordionButton
          type="button"
          style={{ borderTop: "2px solid gray" }}
          onClick={() => resetForm()}
          className="semi-bold "
        >
          Återställ Filter
        </AccordionButton>
      </Dropdown.Menu>
    </Dropdown>
  );
};

function StatusOptions() {
  const options = {
    0: { variant: "warning", label: "Schemalagd" },
    1: { variant: "success", label: "Aktiv" },
    2: { variant: "info", label: "Paus" },
    3: { variant: "danger", label: "Avslutad" },
  };

  return (
    <>
      <Accordion.Header>Status</Accordion.Header>

      <Accordion.Body className="py-3 px-2 d-flex flex-column justify-content-center">
        {Object.keys(options)?.map((key) => (
          <Form.Group
            key={key}
            className="mb-3 d-flex justify-content-around align-items-center gap-3 p-1"
          >
            <Form.Check type="checkbox" name={`${STATUS_PREFIX}${key}`} />
            <div style={{ width: "110px" }}>
              <Badge pill bg={options[key]?.variant} className="w-100">
                {options[key]?.label}
              </Badge>
            </div>
          </Form.Group>
        ))}
      </Accordion.Body>
    </>
  );
}

function ActivityOptions() {
  const options = {
    [CampaignOptions.Meta]: {
      prop: "enableFacebook",
      icon: <TbBrandMeta size={18} color="#216FE4" />,
    },
    [CampaignOptions.Linkedin]: {
      prop: "enableLinkedIn",
      icon: <SiLinkedin size={18} color="#0A66C2" />,
    },
    [CampaignOptions.GoogleAds]: {
      prop: "enableGoogleAds",
      icon: <SiGoogleads size={18} color="#216FE4" />,
    },
    [CampaignOptions.TikTok]: {
      prop: "enableTikTok",
      icon: <FaTiktok size={18} color="#000" />,
    },
    [CampaignOptions.LeadGeneration]: {
      prop: "enableLeadGeneration",
      icon: <BiTargetLock size={18} color="rgb(167 118 113)" />,
    },
    [CampaignOptions.Email]: {
      prop: "enableEmail",
      icon: <MdMailOutline size={18} color="#B3CCC3" />,
    },
    [CampaignOptions.SMS]: {
      prop: "enableSms",
      icon: <MdOutlineSms size={18} color="#017397" />,
    },
  };
  return (
    <>
      <Accordion.Header>Kanaler</Accordion.Header>

      <Accordion.Body className="p-4">
        {Object.keys(options)?.map((key) => (
          <Form.Group
            key={options[key].prop}
            className="mb-3 d-flex justify-content-between align-items-center  p-1"
          >
            <Form.Check
              type="checkbox"
              name={`${ACTIVITY_PREFIX}${options[key].prop}`}
            />
            <span className="semi-bold">{key}</span>
            {options[key].icon}
          </Form.Group>
        ))}
      </Accordion.Body>
    </>
  );
}

export default FilterDropdown;
