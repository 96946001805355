import {
  Table,
  Row,
  Button,
  Col,
  Spinner,
} from "react-bootstrap";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { TextInput } from "components/forms";
import { useState } from "react";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";

import Agent from "api/Agent";
import AdminFiltersDetails from "./adminFiltersDetails/AdminFiltersDetails";
import NewFilterCategory from "./newFilterCategory/NewFilterCategory";
import { segmentTypes_sv } from "features/segment/segment-details/assets/config";
import { MdCancel, MdCheckCircleOutline, MdSearch } from "react-icons/md";
import FilterDropdown from "../components/FilterDropdown";
import moment from "moment";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import EditFilterCategoryModal from "./adminFiltersDetails/components/EditFilterCategoryModal/EditFilterCategoryModal";
import { toast } from "react-toastify";


const AdminFiltersTable = () => {

  const [confirmDelete, setConfirmDelete] = useState([]);
  //Paging
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ prop: "name", desc: false});

  //TableFilters
  const [segmentType, setSegmentType] = useState(undefined);

  const { data: filterCategories, isLoading, error } = Agent.FilterCategories.GET.ALL(currentPage, 10, search, sort?.prop, sort?.desc, segmentType);
  const deleteMutation = Agent.FilterCategories.DEL.FILTER_CATEGORY();
  const [showDetails, setShowDetails] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(undefined);

  // Filters Details 
  const handleShowDetails = (id) => {
    setShowDetails(true);
    setCustomerId(id);
  };
  const handleCloseDetails = () => {
    setShowDetails(false);
    setCustomerId(null);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Table header columns 
  const handleTableHeaderClick = (property) => {
    if (!property || property?.includes(".")) return;
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };
  const isColumnSortable = (col) => !!col?.sortable;
  const isSorting = (col) => sort?.prop === col.property && col.property;

  /*
   * label : table header label
   * property : reflect table column data from Agent data
   * format : a function to format the data to a readable format 
   * sortable : boolean prop is sorting is allowed
   */
  const columns = [
    { label: "Namn", id: 1, property: "name", sortable: true },
    { label: "Rubrik", id: 2, property: "title", sortable: true },
    { label: "Beskrivning", id: 3, property: "subTitle", sortable: true },
    { label: "Typ", id: 4, property: "segmentType", format: (type) => type === null ? "Alla" : segmentTypes_sv[type]?.text, sortable: true },
    { label: "Skapad", property: "createdOn", format: (date) => moment(date).format("yyyy-MM-DD"), sortable: true, id: 5 },
    { label: "Antal filtertyper", id: 6, property: "countOfFilterTypes", },
    { label: "", id: 7 }
  ];


  const handleEditCategory = (id) => {
    setSelectedCategoryId(id);
    setShowEditCategoryModal(true)
  }

  const handleDeleteCategory = (id) => {
    setConfirmDelete(confirmDelete.filter((x) => x !== id));

    toast.promise(
    deleteMutation.mutateAsync(id),
    { success : "Kategori borttagen", loading: "Tar bort kategori...", error:"Kunde inte ta bort kategori"})
  };


  return (<>

    {showDetails && (
      <AdminFiltersDetails
        show={showDetails}
        id={customerId}
        handleClose={handleCloseDetails}
      />
    )}
    <Row className="d-flex flex-row align-items-baseline justify-content-between px-0 mx-0">
      <div className="col-auto">
        <FilterDropdown setSegmentType={setSegmentType} />
      </div>
      <div style={{ width: "300px" }}>
        <TextInput
          type="search"
          placeholder="Sök"
          icon={<MdSearch />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>



    </Row>
    <CardContainer
      style={{ padding: "0" }}
      children={
        <>
          <Table>
            <thead className="text-sm semi-bold">
              <tr>
                {columns.map((col) => (
                  <th key={col.id} style={{ width: "150px", userSelect: "none" } + isColumnSortable(col) && { cursor: "pointer" }}
                    onClick={() =>
                      col.property &&
                      handleTableHeaderClick(col.property)
                    }
                  > {isSorting(col) ? (
                    <p>
                      {col?.label}
                      {sort?.desc ? (
                        <FaSortDown className="ms-1" size={12} />
                      ) : (
                        <FaSortUp className="ms-1" size={12} />
                      )}
                    </p>
                  ) : (
                    <p>{col.label}
                      {isColumnSortable(col) && <FaSort className="ms-1" size={12} />}
                    </p>
                  )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-md regular">
              {filterCategories?.data?.map((r) =>
                <tr key={r.id} onClick={() => handleShowDetails(r.id)} >
                  {columns?.filter(x => x?.property !== undefined).map(x => <td key={x?.property}  className="text-sm">{x?.format ? x?.format(r[x?.property]) : r[x?.property]}</td>)}
                  <td style={{ textAlign: "center" }}onClick={(e) => e.stopPropagation()}>
                    
                              {!confirmDelete?.find(
                                (_delete) => _delete === r?.id
                              ) ? (
                                <>
                                  <button
                                    className="edit-button"
                                    onClick={(event) =>
                                      handleEditCategory(r.id)
                                    }
                                  >
                                    <FiEdit3 size={18} />
                                  </button>
                                  <button
                                    className="delete-button"
                                    onClick={() =>
                                      setConfirmDelete([
                                        ...confirmDelete,
                                        r.id,
                                      ])
                                    }
                                  >
                                    <FiTrash2 size={18} />
                                  </button>
                                </>
                              ) : deleteMutation.isLoading 
                                ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <MdCancel
                                    size={20}
                                    className="edit-button me-2"
                                    onClick={(e) => {
                                      setConfirmDelete(
                                        confirmDelete.filter(
                                          (x) => x !== r.id
                                        )
                                      );
                                    }}
                                  />
                                  <MdCheckCircleOutline
                                    size={20}
                                    className="delete-button"
                                    onClick={() => handleDeleteCategory(r.id)}
                                  />
                                </>
                              )}

                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {filterCategories?.paging !== undefined && !isLoading && (
            <PaginationBar
              paging={filterCategories?.paging}
              setCurrentPage={handlePageChange}
            />
          )}
        </>
      }
    />
    <EditFilterCategoryModal show={showEditCategoryModal} setShow={setShowEditCategoryModal} categoryId={selectedCategoryId} setCategoryId={setSelectedCategoryId} />
  </>
  );
};

                    // <Button variant="light" size="sm" onClick={() => handleEditCategory(r?.id)}>
                    //   <FiEdit3 size={18} />
                    // </Button>
export default AdminFiltersTable;
