import { CardContainer } from "components/cards";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import SegmentTable from "./SegmentTable";
import {
  columnsSegments,
  columnsSegmentsSm,
} from "features/campaign/create-campaign/components/steps/data/stepsData";
import "../style.scss";
import { useNavigate } from "react-router-dom";
import Agent from "api/Agent";

const MERINFO_THEME = process.env['REACT_APP_MERINFO_THEME'];

const SegmentOverviewContainer = ({ ifXsScreen, ifSmallScreen, ifMdAddCircleOutlineIsSmall, xsValue }) => {
  const isMerinfoTheme = MERINFO_THEME === "true";
  const {
    data: segments,
    isError,
    isLoading,
    error,
  } = Agent.Segments.GET.ALL()

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/segments");
  };

  return (
    <CardContainer
      h100
      padding
      children={
        <>
          <Container className="m-0 g-0" fluid>
            <Row className="mb-4 justify-content-between">
              <Col>
                <h4 className="text-lg semi-bold">Mina {isMerinfoTheme ? "Kontaktlistor" : "Målgrupper"}</h4>
              </Col>
              <Col className="col-2 d-flex justify-content-end align-items-center">
                <FaUsers size={30} color="#ddd" />
              </Col>
            </Row>
            <Row
            // style={{ height: "18vh" }}
            >
              {isLoading ? (
                <Col className="justify-content-center text-center">
                  <Spinner />
                </Col>
              ) : !isLoading && segments?.data?.length > 0 ? (

                <SegmentTable
                  tableName="segments"
                  columns={ifSmallScreen ? columnsSegmentsSm : columnsSegments}
                  ifSmallScreen={ifSmallScreen}

                />

              ) : (
                <Container fluid >
                  <Row className="h-100">
                    <Col className="col-12">
                      <Row className={`justify-content-evenly  mx-auto ${ifXsScreen ? 'p-0' : 'h-100 p-3'}`}>
                        <Col md={6} className="overviewInfoBox h-100 ">
                          <Alert variant="warning" className={ifXsScreen ? 'd-flex' : 'h-100 d-flex'}>
                            <Row className="justify-content-center text-center">
                              <Col
                                className={` semi-bold d-flex align-items-center ${ifXsScreen ? 'text-sm ' : 'text-lg'}`}
                                style={{ color: "rgb(174 165 138)" }}
                              >
                                {isMerinfoTheme
                                  ?
                                  "Skapa din första kontaktlista"
                                  :
                                  "Skapa din första målgrupp för att se statistik från dina annonser och utskick här!"
                                }
                              </Col>
                            </Row>
                          </Alert>
                        </Col>
                        <Col xs={12} md={6} className="overviewCreateButton" onClick={handleButtonClick}>
                          <Row className="justify-content-evenly mx-auto">
                            {!ifMdAddCircleOutlineIsSmall &&
                              <Col xs={3} md={3} lg={3} className="d-flex align-items-center">
                                <MdAddCircleOutline size={65} />
                              </Col>
                            }
                            <Col xs={xsValue} md={9}>
                              <Row className="g-0 flex-column">
                                <Col>
                                  <h4 className={`semi-bold m-0 ${ifXsScreen ? 'text-sm ' : ''}`}>
                                    Skapa {isMerinfoTheme ? "Kontaktlista" : "Målgrupp"}
                                  </h4>
                                </Col>
                                <Col>
                                  <p className="text-sm ">
                                    Företag, Personer eller egen lista
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              )}
            </Row>
          </Container>


        </>
      }
    />
  );
};

export default SegmentOverviewContainer;
