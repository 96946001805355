import styles from "../../../style.module.css";
import { useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import Agent from "api/Agent";
import Stepper from "components/stepper/stepper";
import { useContext } from "react";
import axios from "api/axios";
import { useState } from "react";
import { createConfirmation } from "react-confirm";
import Confirm from "components/dialog/Confirm";
import { DRContext } from "./DRWizard";
import { toast } from "react-toastify";

const componentStates = {
  default: "default",
  loading: "loading",
  error: "error",
};


const DRStepper = ({ activeStep, setActiveStep, steps, mutate, activity, FormRef }) => {
  const [componentState, setComponentState] = useState(
    componentStates.default
  );
  const segmentQuery = Agent.Segments.GET.ID(activity?.segmentID)
  const drObject = useSelector((state) => state.directAd.drObject);
  const cache = useSelector((state) => state.directAd.wizard.cache);
  const { mutateAsync: updateActivity } = Agent.CampaignsActivity.PUT.ACTIVITY();
  const uploadFile = Agent.CampaignsActivity.POST.FILES();
  const uploadDataExportMutation = Agent.CampaignsActivity.POST.DATAEXPORT();
  const CreateDrListMutation= Agent.CampaignsActivity.POST.CreateDirectMarketingList();
  const { render } = useContext(DRContext);
  const confirm = createConfirmation(Confirm);

  const triggerBadForm = () => {
    // trigger onSubmit event for the form. in order to highlight form validation in the form. 
    if (FormRef.current) {
      const submitButton = FormRef.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
        setComponentState(componentStates.error);

      }
    }
  }


  const handleBtnStates = async (promise) => {
    setComponentState(componentStates.loading);
    if (FormRef?.current && !FormRef?.current?.checkValidity()) return triggerBadForm();

    const isLastStep = activeStep >= 1;
    if (isLastStep) {
      const confirmation = await confirm({
        confirmation: 'Kampanjen kommer nu publiceras. OK?'
      });
      if (false === confirmation) return setComponentState(componentStates.default);
    }

    try {
      await promise();
      setTimeout(() => {
        setComponentState(componentStates.default);
        setActiveStep(activeStep + 1);
      }, 500);
    } catch (error) {
      setComponentState(componentStates.error);
    }
  };


  const onClickSaveStep1 = async () => {
    const wizardTemplate = await getTemplateFromWizard();

    return updateActivity(wizardTemplate);
  }

  const getTemplateFromWizard = async () => {
    try {
      if (!cache) return Promise.resolve(null);
      const template = activity?.ad?.template
        ? JSON.parse(activity.ad.template)
        : {};
      const updatedWizard = JSON.parse(cache);


      Object.keys(updatedWizard)?.forEach((propertyName) => {
        if (!template[propertyName]) template[propertyName] = {};
        if (template[propertyName]?.print) template[propertyName].print = {};
        Object.keys(updatedWizard[propertyName]).forEach((prop) => {
          template[propertyName][prop] = updatedWizard[propertyName][prop];
        });
      });

      const updatedAd = {
        ...activity.ad,
        template: JSON.stringify(template),
      };

      const updatedActivity = {
        ...activity,
        ad: updatedAd
      };

      return Promise.resolve(updatedActivity);
    } catch (error) {
      return Promise.reject({
        error: error,
        message: "Failed to update activity with DR_Wizard cache",
      });
    }
  };

  const onClickSaveStep2 = async () => {
    const pages = ["selectedImageFront", "selectedImageBack"];
    const template = activity?.ad?.template
      ? JSON.parse(activity.ad.template)
      : {};

    const rendered_images = await Promise.all(
      pages.map(async (page) => {
        const renderPage = render.find(x => Object.keys(x).includes(page));
        if (!renderPage) {
          return Promise.reject(null);
        }
        return new Promise(async (resolve, reject) => {
          try {
            const res = await fetch(Object.values(renderPage));
            const blob = await res.blob();

            const file = new File([blob], `render_${page}.png`, {
              type: "image/png",
            });

            const formData = new FormData();
            formData.append("file", file);

            resolve(formData); // Resolve the custom promise with formData
          } catch (err) {
            console.error(err);
            return reject(err); // Reject the custom promise with the error
          }
        });
      })
    );

    const uploadPromises = pages.map(async (page, index) => {
      const formData = rendered_images[index];

      if (!formData) return (null);
      try {
        const { data } = await axios.post(`/campaigns/v1/${activity.id}/files`, formData, {
          headers: {
            "Content-Type": 'multipart/form-data',
          },
        });

        template[page].print = {};
        template[page].print.render = data;

        return data;
      } catch (err) {
        console.error(err);
        return { error: err, id: activity.id };
      }
    });
    await Promise.all(uploadPromises).then(() => {
      const updatedCampaign = {
        ...activity,
        directMarketingTarget: drObject.directMarketingTarget,
        targetGroupMaxSize: drObject.targetGroupMaxSize,
        ad: {
          ...activity?.ad,
          template: JSON.stringify(template),
          startDate: drObject.ad.startDate,
        },
      };

      // const dataExport = drObject?.dataExports?.at(0) ?? {max : segmentQuery?.data?.size, min : segmentQuery?.data?.size > 500 ? 500 : segmentQuery?.data?.size, campaignActivityID : activity?.id };
      uploadDataExportMutation.mutateAsync( drObject?.dataExports?.at(0), {
        onSuccess : () => mutate(updatedCampaign, {
          onSuccess : () => CreateDrListMutation.mutateAsync({activityID : activity.id})
        }),
        onError : () => toast.error("Kundlistan gick inte att definera")

      })

    })
    return null;
  };

  return (
    <Col className={styles.campaignChannelFooter}>
      <Row className=" row-cols-3 px-2">
        <Col className=" d-flex align-items-center justify-content-start">
          {activeStep > 0 && (activeStep < 2) && (
            <Button
              variant="outline-dark"
              style={{ fontWeight: 600, padding: "8px 14px" }}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              <BsArrowLeft size={20} /> Gå tillbaka
            </Button>
          )}
        </Col>

        <Col className="d-flex align-items-center justify-content-center">
          <Stepper
            steps={steps?.length}
            disableContinue
            activeStep={activeStep}
            onClick={(e) => e !== undefined && setActiveStep(e)}
          />
        </Col>

        <Col className=" d-flex align-items-center justify-content-end">
          {steps.length - 1 >= activeStep && (
            <Button
              variant={componentState === componentStates.error ? "danger" : "success"}
              className="p-2 semi-bold"
              onClick={() =>
                activeStep === 0
                  ? handleBtnStates(onClickSaveStep1)
                  : activeStep === 1
                    ? handleBtnStates(onClickSaveStep2)
                    : null
              }
            >
              {
                componentState === componentStates.default &&
                <>
                  {!activeStep ? (
                    <span>
                      Spara & fortsätt <BsArrowRight size={20} />
                    </span>
                  ) : null}

                  {activeStep ? (
                    <span>
                      Spara <BsArrowRight size={20} />
                    </span>
                  ) : null}

                </>
              }
              {componentState === componentStates.loading && <Spinner />}
              {componentState === componentStates.error && <span> Ett fel uppstod </span>}

            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
};
export default DRStepper;
