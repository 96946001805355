import { Row, Col, Container } from "react-bootstrap";
import { SettingsTabs } from "features/settings";


const Settings = () => {
  return (
    <Container className="py-5 h-100">
      <Row>
        <Col className="mb-4" >
          <h2>Settings</h2>
        </Col>
      </Row>
      <Row >
        <Col>
            <SettingsTabs/>
        </Col>
    
      </Row>
    </Container>
  );
};

export default Settings;
