import { CampaignActivityTypes, CampaignOptionsTypes } from "features/campaign/campaign-details/options"
import DrTable from "./components/table/DrTable"

const DR = () => {
  return (
    <div>
              {/*(!props.typeSmall && !props?.withSegmentID) && (
          <Row className="d-flex flex-row align-items-baseline justify-content-between px-0 mx-0">
            <Col md={6} sm={12} className="pb-3 d-flex">
              <Button
                className="semi-bold"
                style={{ marginRight: "5px" }}
                variant="outline-dark"
                onClick={() => setShowStatistics(true)}
              >
                <AiOutlineLineChart /> Statistik
              </Button>

            </Col>
            <Col
              md={6}
              sm={12}
              style={{ width: ifMediumScreen ? "100%" : "300px" }}
            >
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </Col>
          </Row>
        )*/}

      <DrTable activityType={CampaignOptionsTypes.DirectMarketing}/>
    </div>
  )
}

export default DR
