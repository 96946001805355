import React from "react";

import { MdQrCode } from "react-icons/md";

export default function QR({ content }) {
  return (
    <>
          


        <div
        style={{
          position: "absolute",
          zIndex:2,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#01ade3",
          borderRadius: "3px",
          opacity: content ? 1 : 0.8,
        }}
      />
<div style={{ position: "absolute",
          zIndex:2,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
display: "flex",
          justifyContent: "center",
          alignItems: "center",

      }}>
        {content && content}
        </div>
      <div
        style={{
          width: "60%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 5
        }}
      >
        <div
          style={{ width: "100%", height: "100%", zIndex: 10 }}
          className="d-flex flex-column justify-content-center align-content-center"
        >
{!content ? <MdQrCode
        style={{ width: "80%", height: "80%" }}
        className="mx-auto"
      /> : <></>}


        </div>
      </div>
    </>
  );
}
