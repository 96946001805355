import { ButtonLarge } from "components/buttons";
import {
  uploadFile,
  segmentByFilePersonObject,
  segmentByFileCompanyObject,
  segmentByFileCombinedObject,
  addSegment,
} from "features/segment/api";
import { useRef, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

const UploadFileButton = (props) => {
  const [fileResponseId, setFileResponseId] = useState(null);
  const navigate = useNavigate();

  //Upload file and get ID------------
  const fileInputRef = useRef();
  const onClickUploadFile = () => {
    fileInputRef.current.click();
  };

  const [loadingUpload, setLoadingUpload] = useState(false);
  const queryClient = new useQueryClient();
  const uploadFileMutation = useMutation(uploadFile, {
    onMutate: () => {
      setLoadingUpload(true);
    },
    onSuccess: (response) => {
      console.log(response.data);
      setFileResponseId(response.data.id);
      queryClient.invalidateQueries("files");
      setLoadingUpload(false);
    },
    onError: (error) => {
      setLoadingUpload(false);
      console.log(error.message);
    },
    onSettled: () => {
      setLoadingUpload(false);
    },
  });

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    console.log({ formData });
    uploadFileMutation.mutate(formData);
  };

  //Get file-id and create segment
  const addSegmentByFileMutation = useMutation(addSegment, {
    onMutate: () => {
      setLoadingUpload(true);
    },
    onSuccess: (response) => {
      console.log(response.data);
      queryClient.invalidateQueries("segments");
      navigate(`create-segment/${response.data.id}`);
      setLoadingUpload(false);
    },
    onError: () => {
      setLoadingUpload(false);
    },
    onSettled: () => {
      setLoadingUpload(false);
    },
  });

  useEffect(() => {
    console.log({ fileResponseId });

    if (fileResponseId && props.type === 0) {
      addSegmentByFileMutation.mutate(
        segmentByFileCompanyObject(fileResponseId)
      );
    } else if (fileResponseId && props.type === 1) {
      addSegmentByFileMutation.mutate(
        segmentByFilePersonObject(fileResponseId)
      );
    } else if (fileResponseId && props.type === 2) {
      addSegmentByFileMutation.mutate(
        segmentByFileCombinedObject(fileResponseId)
      );
    } else {
      return;
    }
  }, [fileResponseId]);

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <ButtonLarge
        onClick={onClickUploadFile}
        title={!loadingUpload ? props.title : <Spinner />}
        subTitle={props.subTitle}
        upload
      />
    </>
  );
};

export default UploadFileButton;
