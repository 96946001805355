import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import * as icons from "react-icons/md";
import EditSegment from "../EditSegment";
import "../style.scss";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useEffect } from "react";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";

export default function SegmentFilterElement({
  filter,
  filterCategory,
  segment,
  index,
}) {
  const [showDetails, setShowDetails] = useState(false);

  const id = filter.find((filterCategory) => filterCategory);
  const filterType = filterCategory?.filterTypes?.filter((x) => x.id === id)[0];
  const ifXsScreen = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <Row>
      <Row className="mx-auto">
        <Col
          className={`d-flex ${ifXsScreen ? "flex-column justify-content-center" : "flex-row"} `}
          style={{
            marginBottom: showDetails ? "25px" : "",
          }}
        >
          {!ifXsScreen && (
            <Col lg={1} md={2} sm={2} >
              <h4 className="display-sm bold my-auto mx-2" style={{ color: "grey" }}>
                {index + 1}.
              </h4>
            </Col>
          )}
          <Col lg={1} md={2} sm={12} className={ifXsScreen ? "text-center" : "text-left"}  >
            {icons[filterCategory?.icon] &&
              React.createElement(icons[filterCategory?.icon], {
                className: "my-auto",
                style: {
                  color: filterCategory?.iconBg,
                  width: "35px",
                  height: "35px",
                },
              })}
          </Col>
          <Col lg={3} md={4} sm={12} className={ifXsScreen ? "text-center" : "text-left"}
          >
            <h4 className={`display-sm bold my-auto ${ifXsScreen ? "text-sm":""}`}>{filterType?.name}</h4>
          </Col>
          <Col lg={3} md={4} sm={12}
            className={`toggle-filter-settings mx-3  ${ifXsScreen ? "text-center align-items-center" : "text-left align-items-center "} `}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? (
              <MdKeyboardArrowUp size={30} />
            ) : (
              <MdKeyboardArrowDown size={30} />
            )}
          </Col>
        </Col>
      </Row>

      {showDetails && (
        <Col className={ifXsScreen ? "" : "px-5"}>
          <EditSegment
            segment={segment}
            index={index}
            filterType={filterType}
            filterCategory={filterCategory}
            filter={filter}
          />
        </Col>
      )}
    </Row>
  );
}
