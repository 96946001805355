import { Col, Row, Form } from "react-bootstrap";
import EditSMS from "../components/EditSMS";
import MobileSMSPreview from "../components/MobileSMSPreview";
import Agent from "api/Agent";
import { useState, useEffect } from "react";
import { CampaignOptionsTypes } from "features/campaign/campaign-details/options";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const SMSStep1 = ({
  campaign,
  text,
  setText,
  senderName,
  setSenderName,
  activity,
}) => {
  //Get all previous sms-campaigns for selection-----------
  const { data: allCampaigns } = Agent.Campaigns.GET.ALL();
  const smsCampaigns = allCampaigns?.data
    ?.filter((_campaign) => _campaign?.enableSms)
    ?.filter((_campaign) => _campaign?.id !== campaign?.id);

  const [selectedPreviousCampaign, setSelectedPreviousCampaign] =
    useState(null);
  const [templateSelect, setTemplateSelect] = useState(null);
  console.log({ selectedPreviousCampaign });

  const {
    data: campaignTemplate,
    isLoading: campaignIsLoading,
    isError,
    error,
  } = Agent.Campaigns.GET.ID(campaign.campaignId);

  useEffect(() => {
    if (!smsCampaigns || !smsCampaigns[templateSelect] || templateSelect === null) return;
    campaignTemplate.mutateAsync(smsCampaigns[templateSelect]?.id, {
      onSuccess: (res) =>
        setSelectedPreviousCampaign(
          res?.campaignActivities?.find(
            (x) => x?.type === CampaignOptionsTypes.SMS
          )
        ),
    });
  }, [templateSelect]);

  useEffect(() => {
    if (selectedPreviousCampaign) {
      setText(
        selectedPreviousCampaign.ad?.primaryText
          ? selectedPreviousCampaign.ad?.primaryText
          : ""
      );
    }
  }, [selectedPreviousCampaign]);

  return (
    <Row className="justify-content-between">
      {/* CONTENT LEFT---------- */}
      <Col className="col-6">
        <Row>
          <Col className="col-10">
            <h4 className="display-sm semi-bold">Skapa smskampanj</h4>
          </Col>
          <Col className="col-2 d-flex align-items-center justify-content-end">
            {statusBadgeSetter(activity)}
          </Col>
          <Col className="mt-3">
            <Row
              className="flex-column g-3 text-lg"
              style={{ color: "#474E5C" }}
            >
              <Col>Såhär gör du</Col>
              <Col>
                <p>
                  <strong>1.</strong> Duplicera och anpassa ett tidigare
                  utskick, eller skapa ett helt nytt
                </p>
              </Col>
              <Col>
                <p>
                  <strong>2.</strong> Ställ målgrupp, titel m.m. och dubbelkolla
                  att allt är rätt
                </p>
              </Col>
              <Col>
                <p>
                  <strong>3.</strong> Skicka eller schemalägg utskicket.
                </p>
              </Col>
            </Row>
          </Col>
          <hr className="my-3" />
          <Col className="col-12">
            <Form.Label
              htmlFor="choose-campaign"
              className="p-0 text-md"
              style={{ color: "#344054" }}
            >
              Utgå ifrån en av dina tidigare sms-kampanjer
            </Form.Label>
            <Form.Select
              id="meta-pages"
              aria-label="Default select example"
              value={templateSelect || ""}
              onChange={(e) => setTemplateSelect(+e?.target?.value)}
              required
            >
              <option value={" "}>Välj en kampanj</option>
              {smsCampaigns &&
                smsCampaigns?.map((_campaign, i) => (
                  <option key={i} value={i}>
                    {_campaign.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col className="col-12">
            <EditSMS
              setSenderName={setSenderName}
              text={text}
              setText={setText}
              activity={activity}
            />
          </Col>
        </Row>
      </Col>
      {/* CONTENT RIGHT---------- */}
      <Col className="col-6 mx-auto">
        <MobileSMSPreview text={text} senderName={senderName} />
      </Col>
    </Row>
  );
};

export default SMSStep1;
