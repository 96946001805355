import Agent from "api/Agent"
import PaginationBar from "components/pagination/PaginationBar";
import moment from "moment";
import { useContext, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { SiMicrosoftexcel } from "react-icons/si";
import { DRContext } from "../../../DR";

const DataExportTable = ({ sortByProp, sortDesc }) => {
  const drContext = useContext(DRContext);

  const rowsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const dataExportsQuery = Agent.CampaignsActivity.GET.DATAEXPORTS(currentPage, rowsPerPage, sortByProp, sortDesc, drContext?.activity?.id);
  const dataExportStates = { 0: "Ny", 1: "Redo", 2: "Skickad", 3: "För liten" }

  const columns = [
    { header: "Datum", data: (item) => moment(item?.scheduledDate).isValid() ? moment(item?.scheduledDate).format('yyyy-MM-DD') : "-", },
    { header: "Antal mottagare", data: (item) => item?.dataExportSize, },
    { header: "Status", data: (item) => dataExportStates[item?.state] ?? dataExportStates[0], },
    {
      header: "Visa", data: (item) => {
        const isExported = !!item?.dateExported
        if (isExported) {
          const isDisabled = !item.absoluteUri;
          return (
            <Button
              onClick={() => { window.open(item.absoluteUri, '_blank').focus() }}
              variant={isDisabled ? "danger" : "outline-dark"}
              disabled={isDisabled}
              className="d-flex align-content-center justify-content-center border" >
              <SiMicrosoftexcel className="me-1" />
            </Button >
          );
        }
        return <Spinner size="sm" />
      },

    },
  ];
  return (
    <Table
      className="table-container"
      responsive
      hover
      size="lg"
    >
      <thead className="text-sm semi-bold">
        <tr>
          {columns.map((col, index) => (
            <th
              align="center"
              style={{ width: "150px", userSelect: "none" }}
              key={index}
            >
              <p className="text-center ">
                {col.header}
              </p>
            </th>
          ))
          }
        </tr>
      </thead>

      <tbody>
        {dataExportsQuery.isSuccess && dataExportsQuery.data?.data?.map(dataExport => <tr key={dataExport?.id}>
          {columns.map((col, index) => <td key={index} align="center">{col.data(dataExport)} </td>)}

        </tr>)}

      </tbody>
      {dataExportsQuery.isError && (
        <caption
          style={{ captionSide: "bottom", columnSpan: "100%" }}
          className="p-3 bg-danger"
        >
          <h5 className=" text-white semi-bold text-center w-100">
            Ett fel uppstod vid hämtning av data
          </h5>
        </caption>
      )}

      {dataExportsQuery.isLoading && (
        <caption
          style={{ captionSide: "bottom", columnSpan: "100%" }}
          className="p-3"
        >
          <h5 className=" text-black text-center w-100">
            <Spinner />
          </h5>
        </caption>
      )}

      {dataExportsQuery.isSuccess && !dataExportsQuery.data?.data?.length && (
        <caption
          style={{ captionSide: "bottom", columnSpan: "100%" }}
          className="p-3 text-center"
        >
          <span className="text-xl text-black text-muted ">
            Inga utskick hittade.
          </span>
        </caption>
      )}
      {dataExportsQuery.data?.paging && (
        <tr>
          <td colSpan={99} align="center" className="py-0">
            <PaginationBar
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
              countPerPage={rowsPerPage}
              totalCount={dataExportsQuery.data?.paging?.total}
            />
          </td>
        </tr>
      )}
    </Table>



  )
}

export default DataExportTable


