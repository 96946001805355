import Agent from 'api/Agent';
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Button, Modal, Row, Stack } from 'react-bootstrap';
import NewDataExportForm from '../../../form/NewDataExportForm';
import DataExportTable from '../DataExportTable';
import styles from './style.module.css'

const AddDataExportModal = ({ show, handleClose }) => {
  const newDataExportFormRef = useRef();
  const triggerFormSubmit = () => {
    const submitButton = newDataExportFormRef.current.querySelector('[type="submit"]');
    if (submitButton) {
      submitButton.click();
    }
  }

  const PostDataExportMutatation = Agent.CampaignsActivity.POST.DATAEXPORT();
  const CreateDirectMarketingList = Agent.CampaignsActivity.POST.CreateDirectMarketingList();
  const handleSubmit = async (data) => {

    const submit = await PostDataExportMutatation.mutateAsync(data)
    const isSuccess = submit?.status == 200;
    if (isSuccess) {
      if (data?.campaignActivityID) {
        await CreateDirectMarketingList.mutateAsync(data?.campaignActivityID);
        handleClose()
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered contentClassName={styles.wideModal} className={styles.mwNone}>

      <Modal.Header closeButton >
        <Modal.Title>Lägg till utskick</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Stack gap={3} className="d-flex px-2 mx-auto" style={{ maxWidth: '90%' }}>

          <Row className='justify-content-center'>
            <div >
              <NewDataExportForm ref={newDataExportFormRef} onSubmit={(data) => handleSubmit(data)} />

              <Row className='d-flex justify-content-end row-cols-auto px-2'>
                <Button variant='success' onClick={() => triggerFormSubmit()} disabled={PostDataExportMutatation.isLoading ?? CreateDirectMarketingList.isLoading}> Spara</Button>
              </Row>
            </div>
          </Row>

          <div>
            <span className='semi-bold text-md my-2'>Senast skapade utskick</span>
            <DataExportTable sortByProp={"createdOn"} sortDesc={true} />
          </div>
        </Stack>
      </Modal.Body>

      <Modal.Footer className='d-flex justify-content-start'>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddDataExportModal
