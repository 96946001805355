import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { MdDelete, MdFilterList, MdOutlineFilterListOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllFilters,
  removeFilter,
  removeFilterProp,
} from "./assets/statisticsSlice";

export default function StatisticsFilterModal() {
  const { filters } = useSelector((state) => state.statistics);
  const { layout, types } = useSelector((state) => state.statistics.visual);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(!filters?.length);
  
  useEffect(() => {
    setDisabled(!filters?.length);
  }, [filters?.length]);

  const handleFilterRemoval = (value, id) => {
    const filter = filters.find(filter => filter?.id === id);
    const filterContainsOnlyOneValue = filter.values?.length <= 1;
    if (filterContainsOnlyOneValue) return dispatch(removeFilter(filter));
    return dispatch(removeFilterProp({ id: filter.id, value: value }));
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  const handleRemovalAllFilters = () => {dispatch(deleteAllFilters()); handleClose()};
  return (
    <>
      <Button
        onClick={handleShow}
        variant="outline-dark  "
        className="d-flex align-items-center justify-content-around text-md semi-bold px-4 position-relative"
      >
        {disabled ? (
          <MdOutlineFilterListOff className="me-1" />
        ) : (
          <MdFilterList className="me-1" />
        )}
        <span className="mx-1">Filter</span>
        {filters?.flatMap(filter => filter?.values)?.length > 0 && <span className="filter-indicator">{filters?.flatMap(filter => filter?.values)?.length}</span>}

      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Aktiva filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Komponent</th>
                <th>Komponent typ</th>
                <th>Filtrerar på</th>
                <th style={{ minWidth: "100px" }}></th>
              </tr>
            </thead>
            <tbody>
              {filters?.map((filter) =>
                filter?.values?.map((value, index) => (
                  <tr key={index}>
                    <td className=" text-capitalize">
                      {
                        layout?.find((visual) => filter?.id === visual?.id)
                          ?.name
                      }
                    </td>
                    <td>
                      {
                        types?.find(
                          (type) =>
                            type?.id ===
                            layout?.find((visual) => filter?.id === visual?.id)
                              ?.type
                        )?.name
                      }
                    </td>
                    <td>{value}</td>
                    <td>
                      <Button
                        variant="outline-danger"
                        onClick={() => handleFilterRemoval(value, filter?.id)}
                        className="w-100"
                      >
                        <MdDelete className="w-100 ps-auto text-xl" />
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
        <Button variant="danger" onClick={() => handleRemovalAllFilters()}>
            Ta bort alla filter
          </Button>
          <Button variant="success" onClick={handleClose}>
            Stäng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
