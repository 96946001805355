import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import * as icons from "react-icons/md";
import AiEditSegment from "../AiEditSegment";
import "../style.scss";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useEffect } from "react";
import Agent from "api/Agent";
import { debounce } from "lodash";
import { useQueryClient } from "react-query";

export default function AiSegmentFilterElement({
  filter,
  aiFilters,
  segment,
  index,
  postAiSegmentPreview
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [enabled, setEnabled] = useState(filter.filter_enabled);
  const { mutate: putAiSegment } = Agent.Segments.PUT.AIFILTERCONFIG(
    segment?.id
  );
  const qt = useQueryClient();
  const toggleDisabled = !!qt.isMutating();
  const toggleEnabled = () => {
    setEnabled((prevEnabled) => {
      const filterName = Object.keys(aiFilters)[index];
      const newFilter = {
        ...aiFilters,
        [filterName]: { ...filter, filter_enabled: !prevEnabled },
      };

      console.log({ newFilter });
      postAiSegmentPreview({ id: segment?.id, newFilter: newFilter })
      putAiSegment({ id: segment?.id, newFilter: newFilter });

      return !prevEnabled;
    });
  };

  useEffect(() => {
    if (enabled === false) {
      setShowDetails(false);
    }
  }, [enabled]);

  const [value, setValue] = useState([
    filter.selection_importance_threshold,
    filter.selection_importance_max,
  ]);


  const setNewValueRequest = (newValue) => {
    setValue(() => {

      const filterName = Object.keys(aiFilters)[index];
      const newFilter = {
        ...aiFilters,
        [filterName]: {
          ...filter,
          selection_importance_threshold: newValue[0],
        },
      };

      console.log({ newFilter });
      postAiSegmentPreview({ id: segment?.id, newFilter: newFilter })
      putAiSegment({ id: segment?.id, newFilter: newFilter });
      return newValue;
    });

  };
  const setNewValue = debounce((newValue) => setNewValueRequest(newValue), 300);

  return (
    <Row>
      <Col
        className="col-11 d-flex"
        style={{
          filter: !enabled ? "opacity(0.3)" : "",
          marginBottom: showDetails ? "25px" : "",
        }}
      >
        <h4 className="display-sm bold my-auto mx-2" style={{ color: "grey" }}>
          {index + 1}.
        </h4>
        {icons[filter.ft_icon] &&
          React.createElement(icons[filter.ft_icon], {
            className: "my-auto",
            style: {
              color: filter?.ft_iconbg,
              width: "35px",
              height: "35px",
            },
          })}
        {/* <h4 className="display-sm bold my-auto">{filterType?.name}</h4> */}
        <h4 className="display-sm bold my-auto">{filter.filter_label}</h4>
        {enabled && (
          <div
            className="toggle-filter-settings mx-3 d-flex align-items-center"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? (
              <MdKeyboardArrowUp size={30} />
            ) : (
              <MdKeyboardArrowDown size={30} />
            )}
          </div>
        )}
      </Col>
      <Col className="col-1">
        <Form.Check type="switch" disabled={toggleDisabled} checked={enabled} onChange={toggleEnabled} />
      </Col>
      {showDetails && (
        <Col
          className="px-5"
          style={{ filter: !enabled ? "opacity(0.3)" : "" }}
        >
          <AiEditSegment
            segment={segment}
            aiFilters={aiFilters}
            index={index}
            value={value}
            setNewValue={setNewValue}
            filter={filter}
          />
        </Col>
      )}
    </Row>
  );
}
