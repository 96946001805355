import Agent from "api/Agent";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const EditEmail = React.forwardRef(({ placeholder, validated, onChange, ad }, ref) => {

  const emaildomains = Agent.EmailDomains.GET.ALL();
  return (
    <Form ref={ref} noValidate validated={validated} onChange={onChange}>
      <Row className="mt-1 row-cols-1 g-4">

        <Col>
          <Form.Label
            htmlFor="emailDomainID"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Avsändare
          </Form.Label>
          <Form.Select
            name="emailDomainID"
            id="sender"
            placeholder={placeholder?.sender}
            defaultValue={ad?.emailDomainID}
            className="text-md p-2"
            required
          >
            <option disabled value="" selected={!emaildomains?.data} >Kontakta admin/support för att tilldela mail</option>
            {emaildomains?.data?.map(emailDomain => <option value={emailDomain?.id}>{`${emailDomain?.name} | ${emailDomain?.fromEmail}`}</option>)}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            Välj en mail att göra utskicket ifrån.
          </Form.Control.Feedback>
        </Col>

        <Col>
          <Form.Label
            htmlFor="title"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Rubrik
          </Form.Label>
          <Form.Control
            type="text"
            name="title"
            id="title"
            placeholder={placeholder?.title}
            defaultValue={ad?.title || ""}
            required
          />
          <Form.Control.Feedback type="invalid">
            Ange en rubrik.
          </Form.Control.Feedback>
        </Col>

        <Col>
          <Form.Label
            htmlFor="description"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Beskrivning
          </Form.Label>
          <Form.Control
            aria-label="description"
            as="textarea"
            rows={5}
            id="description"
            name="description"
            placeholder={placeholder?.description + "..."}
            defaultValue={ad?.description || ""}
          />
        </Col>
      </Row>
    </Form>
  );
});
EditEmail.displayName = "EditEmail";
export default EditEmail;
