import { Row, Col, Form } from "react-bootstrap";
import { useState } from "react";
import ImagePickerModal from "./ImagePickerModal";

const AddImages = ({ metaTemplate, googleAdsObject, setGoogleAdsObject, activity, campaign }) => {
  const [multipleImages, setMultipleImages] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImage4, setSelectedImage4] = useState(null);
  
  return (
    <>
      <Col className="mt-3">
        <h3 className="text-lg semi-bold me-auto">Annonsbild(-er)</h3>
      </Col>

      <Row className="mt-3">
        <Col>
          <Form.Check
            inline
            label="Använd samma bild för alla typer"
            name="multipleImages"
            type="radio"
            checked={!multipleImages}
            onChange={() => setMultipleImages(false)}
            value={1}
          />
        </Col>
        <Col>
          <Form.Check
            inline
            label="Flera bilder"
            name="multipleImages"
            type="radio"
            checked={multipleImages}
            onChange={() => setMultipleImages(true)}
            value={2}
          />
        </Col>
      </Row>

      <Row className="p-0 m-0 g-4">
        {multipleImages && (
          <>
            <Col>
              <h6>Reels</h6>
              <ImagePickerModal
                multi
                adType={1}
                imageHash={
                  metaTemplate?.object_story_spec?.link_data?.image_hash ||
                  googleAdsObject?.imageId
                }
                googleAdsObject={googleAdsObject}
                setGoogleAdsObject={setGoogleAdsObject}
                activityId={activity?.id}
                campaignId={campaign?.id}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
              />
            </Col>
            <Col>
              <h6>Wall</h6>
              <ImagePickerModal
                multi
                adType={2}
                imageHash={
                  metaTemplate?.object_story_spec?.link_data?.image_hash ||
                  googleAdsObject?.imageId2
                }
                googleAdsObject={googleAdsObject}
                setGoogleAdsObject={setGoogleAdsObject}
                activityId={activity?.id}
                campaignId={campaign?.id}
                setSelectedImage={setSelectedImage2}
                selectedImage={selectedImage2}
              />
            </Col>
            <Col>
              <h6>Messenger</h6>
              <ImagePickerModal
                multi
                adType={3}
                imageHash={
                  metaTemplate?.object_story_spec?.link_data?.image_hash ||
                  googleAdsObject?.imageId3
                }
                googleAdsObject={googleAdsObject}
                setGoogleAdsObject={setGoogleAdsObject}
                activityId={activity?.id}
                campaignId={campaign?.id}
                setSelectedImage={setSelectedImage3}
                selectedImage={selectedImage3}
              />
            </Col>
            <Col>
              <h6>Story</h6>
              <ImagePickerModal
                multi
                adType={4}
                imageHash={
                  metaTemplate?.object_story_spec?.link_data?.image_hash ||
                  googleAdsObject?.imageId4
                }
                googleAdsObject={googleAdsObject}
                setGoogleAdsObject={setGoogleAdsObject}
                activityId={activity?.id}
                campaignId={campaign?.id}
                setSelectedImage={setSelectedImage4}
                selectedImage={selectedImage4}
              />
            </Col>
          </>
        )}
        {!multipleImages && (
          <ImagePickerModal
            imageHash={
              metaTemplate?.object_story_spec?.link_data?.image_hash ||
              googleAdsObject?.imageId
            }
            googleAdsObject={googleAdsObject}
            setGoogleAdsObject={setGoogleAdsObject}
            activityId={activity?.id}
            campaignId={campaign?.id}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
          />
        )}
      </Row>
    </>
  );
};

export default AddImages;
