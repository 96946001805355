import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MdAssignmentAdd } from 'react-icons/md';
import AddDataExportModal from './AddDataExportModal/AddDataExportModal';

function AddDataExportButton() {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div>
      <Button variant="success" onClick={() => handleShow()}><span className="text-md semi-bold align-center ">Nytt utskick</span> <MdAssignmentAdd size={"1.5rem"}/></Button>

      <AddDataExportModal show={showModal} handleClose={handleClose} />
    </div>
  );
}


export default AddDataExportButton
