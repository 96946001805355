import { createSlice } from "@reduxjs/toolkit";
import { CampaignOptions } from "./campaign-details/options";

const initialState = {
  activeCampaignStep: {
    id: 1,
    name: "Typ av kampanj & målsättning",
    iconBg: "#96C97E",
    status: 1,
  },
  visited: [],
  prevLocation: null,
  campaignId: null,
  showCanvas: false,
  selectedChannel: CampaignOptions.Overview,
  activeChannel: CampaignOptions.Overview,
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setActiveCampaignStep: (state, action) => {
      state.activeCampaignStep = action.payload;
    },
    setVisited: (state, action) => {
      state.visited = action.payload;
    },
    setPrevLocation: (state, action) => {
      state.prevLocation = action.payload;
    },
    setCampaignId: (state, action) => {
      state.campaignId = action.payload;
    },
    setShowCanvas: (state, action) => {
      state.showCanvas = action.payload;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    setActiveChannel: (state, action) => {
      state.activeChannel = action.payload;
    },
  },
});

export const {
  setActiveCampaignStep,
  setVisited,
  setPrevLocation,
  setCampaignId,
  setShowCanvas,
  setSelectedChannel,
  setActiveChannel,
} = campaignSlice.actions;
