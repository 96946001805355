import "../style.scss";
import { Row, Col, InputGroup, Form, Spinner, Button } from "react-bootstrap";
import { MdEdit, MdCheck } from "react-icons/md";
import { useState } from "react";
import { getSegmentById, updateSegment } from "features/segment/api";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

const SetSegmentName = () => {
  //Toggle between input and display name
  const [showInput, setShowInput] = useState(false);

  //Get segment id from URL
  const { id } = useParams();

  //Get segment data using id
  const { data: segment } = useQuery(["segment", id], () =>
    getSegmentById(id).then((res) => {
      setSegmentName(res.data.name);
      return res.data;
    })
  );

  //Set segment name to display
  const [segmentName, setSegmentName] = useState(segment?.name);
  const queryClient = useQueryClient();
  //Update segment function
  const { mutate } = useMutation(
    (newSegment) => updateSegment({ id: segment.id }, newSegment),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("segments");
        queryClient.invalidateQueries("segment");
      },
    }
  );

  //Set the name onChange
  const handleNameChange = (e) => {
    setSegmentName(e.target.value);
  };

  //Submit the PUT-request on click
  const onClickSubmit = (eventType) => {
    const updatedSegment = {
      ...segment,
      name: segmentName,
    };
    mutate(updatedSegment);
    if (eventType === "mouse-leave") {
      setTimeout(() => {
        setShowInput(!showInput);
      }, "3000");
    } else {
      setShowInput(!showInput);
    }
  };

  return (
    <Row className="d-flex justify-items-end p-0 m-0">
      {showInput === true ? (
        <>
          <Col
            className=""
            onMouseLeave={() => onClickSubmit("mouse-leave")}
          >
            <Form>
              <InputGroup className="align-items-center">
                <Form.Control
                  placeholder={segmentName ? segmentName : "Namnlös Målgrupp"}
                  onChange={handleNameChange}
                  defaultValue={segmentName && segmentName !== "Namnlös Målgrupp" ? segmentName : null}
                />
              </InputGroup>
            </Form>
          </Col>
          <Col className="col-2 name-col">
            <Button onClick={() => onClickSubmit("click")} variant="link">
              <MdCheck />
            </Button>
          </Col>
        </>
      ) : (
        <>
          <Col className="">
            <h2 className="display-xs semi-bold mb-0">
              {segmentName ? segmentName : <Spinner />}
            </h2>
          </Col>
          <Col
            onClick={() => setShowInput(!showInput)}
            className="col-2 name-col edit-name-col"
            style={{width:"45px"}}
          >
            <MdEdit />
          </Col>
        </>
      )}
    </Row>
  );
};

export default SetSegmentName;
