import { useState } from "react";
import AddClientsOffCanvas from "features/accounts/add-account/AddClientsOffCanvas";
import { useMutation, useQueryClient } from "react-query";

import { Row, Col, Button } from "react-bootstrap";
import { MdFileUpload } from "react-icons/md";

import Companies from "./Companies";
import Persons from "./Persons";

const ClientsTable = (props) => {
  //OFFCANVAS STUFF
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };

  return (
    <>
      {show && <AddClientsOffCanvas show={show} setShow={setShow} />}
      <Row>
        <Col>
          <Button
            variant="success"
            className="mb-4 text-md btn-lg"
            onClick={handleClick}
          >
            Ladda upp lista <MdFileUpload style={{ marginLeft: "6px" }} />
          </Button>
        </Col>

        {props.accountType === 6 ? (
          //COMPANIES TABLE---------------------------------------------------------
          <Companies {...props} />
        ) : (
          //PERSONS TABLE---------------------------------------------------------
          <Persons {...props} />
        )}
      </Row>
    </>
  );
};

export default ClientsTable;
