import { ButtonMain } from "components/buttons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiPlusSm } from "react-icons/hi";
import { HiArrowRightCircle } from "react-icons/hi2";
import { MdOutlineCampaign } from "react-icons/md";
import { campaignObject, campaignObject2 } from "features/campaign/api";
import { Spinner, Modal, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Agent from "api/Agent";
import moment from "moment";
import { useRef } from "react";

const CreateCampaignButton = ({ isSmallScreen, offer }) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(offer?.title ? offer.title : "");
  const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(1, "month").format('yyyy-MM-DD'));
  const [budget, setBudget] = useState(1000);

  //OnFormSubmit-----------------------------
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { mutate: postCampaign } = Agent.Campaigns.POST.CAMPAIGN();

  

  const onClickHandler = async (e) => {
    e.preventDefault();
    if (formRef?.current?.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    postCampaign(campaignObject2(name, startDate, endDate, budget, offer?.id), {
      onMutate: () => {
        setLoadingCreate(true);
      },
      onSuccess: (response) => {
        setLoadingCreate(false);
        navigate(`/campaigns/create-campaign/${response.data.id}/type-of-campaign`)
      },
      onError: () => {
        setLoadingCreate(false);
      },
      onSettled: () => {
        setLoadingCreate(false);
      },
    });
  };



  return (
    <>
      <ButtonMain
        onClick={() => setShowModal(true)}
        text={"Ny Kampanj"}
        variant="success"
        reverse
        icon={<HiPlusSm />}
        width={isSmallScreen ? "100%" : undefined}
        className="d-flex align-items-center justify-content-center"
      />
      {showModal && (
        <Modal
          size="md"
          show={showModal}
          onHide={() => setShowModal(false)}
          style={{ border: "none" }}
        >
          <Modal.Body className="p-4">
            <Row className="gy-3">
              <Col className="col-12">
                <Row className="justify-content-between px-3 align-items-center">
                  <Col xs={12} md={9}>
                    <h4 className="display-sm semi-bold">Namn på kampanjen</h4>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    className={`d-flex ${
                      isSmallScreen
                        ? "align-items-center justify-content-center "
                        : "justify-content-end "
                    }`}
                  >
                    <MdOutlineCampaign size={100} color="#8080801f" />
                  </Col>
                </Row>
              </Col>
              <Col className="col-12">
                <Form ref={formRef} className="d-flex flex-column p-3 " onSubmit={(e) => onClickHandler(e)}>
                  <Form.Control
                  className="py-3"
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Min nya kampanj..."
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Ange ett namn
                  </Form.Control.Feedback>
                  <Col className="col-12 my-3">
                    <Form.Label
                      className="p-0 text-md"
                      style={{ color: "#344054" }}
                    >
                      Datum mellan
                    </Form.Label>
                    <Row>
                      <Form.Group className="col-6">
                        <Form.Control
                        className="py-3"
                          type="date"
                          name="startDate"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ange ett startdatum
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="col-6">
                        <Form.Control
                        className="py-3"
                          type="date"
                          name="endDate"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ange ett slutdatum
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                  </Col>
                  <Form.Group className="col-12 mb-3">
                    <Form.Label
                      className="p-0 text-md"
                      style={{ color: "#344054" }}
                    >
                      Total Budget
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                      className="py-3"
                        type="number"
                        name="title"
                        id="title"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        required
                      />
                      <InputGroup.Text id="suffix">kr</InputGroup.Text>
                    </InputGroup>

                    <Form.Control.Feedback type="invalid">
                      Ange budget
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    className="mt-4 semi-bold p-2"
                    variant="success"
                    type="submit"
                  >
                    {loadingCreate ? (
                      <Spinner />
                    ) : (
                      <>
                        Skapa Kampanj <HiArrowRightCircle size={20} />
                      </>
                    )}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CreateCampaignButton;
