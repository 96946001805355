import "./assets/style.scss";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import {
  Table,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import {
  MdArrowRightAlt,
} from "react-icons/md";
import Agent from "api/Agent";
import CaptionStatus from "./components/CaptionStatus";
import ActivityIcons from "./components/ActivityIcons";
import TableHeader from "./components/TableHeader";
import TableActions from "./components/TableActions";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";
import moment from "moment";
import { CampaignDetailsOffCanvas } from "features/campaign/campaign-details";
import BadgeState from "./components/BadgeStatus";

const CampaignTable = ({ typeSmall, rows, activityFilter, statusFilter, customerID, search, pagingDisabled }) => {
  const ifBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const ifMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  //OffCanvas Campaign details
  const [showCampaignID, setShowCampaignID] = useState(null);
  const [sort, setSort] = useState({ prop: "createdOn", desc: true });



  const handleShow = (id) => {
    setShowCampaignID(id)
  };

  //Table search and pagination
  const rowsPerPage = rows ? rows : 8;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: campaignsResponse,
    isError,
    isLoading,
    isSuccess,
    error,
  } = Agent.Campaigns.GET.ALL(
    currentPage,
    rowsPerPage,
    search,
    sort?.prop,
    sort?.desc,
    activityFilter,
    statusFilter,
    customerID
  );
  const campaigns = campaignsResponse?.data;
  const enablePaging = !pagingDisabled && campaignsResponse?.paging && campaignsResponse.paging?.total_pages > 1;

  let cells = [
    {
      header: "Kampanj",
      sort: "name",
      data: (campaign) => <Row className="flex-column g-0">
        <Col>{campaign?.name}</Col>
        {campaign?.startDate && !ifSmallScreen && (
          <Col className="table-dates">
            {campaign?.startDate?.substring(0, 10)}
            <MdArrowRightAlt
              className="mx-1"
              size={12}
            />
            {campaign?.endDate?.substring(0, 10)}
          </Col>
        )}
      </Row>,
    },
    {
      header: "Status",
      sort: "state",
      data: (campaign) => <BadgeState state={campaign?.state} />,
    },
    {
      header: "Skapad",
      sort: "createdOn",
      data: (campaign) => {
        if (!campaign.createdOn) return "";
        const date = moment(campaign?.createdOn);
        if (!date.isValid()) return "";
        return <>
          {date.format('yyyy-MM-DD')}
          <p
            className="text-sm"
            style={{
              color: "#7B8FA1",
              margin: "-10px 0",
            }}
          >
            {date.format('HH:mm')}
          </p>
        </>
      }
    },
    {
      header: "Visningar",
      data: (campaign) => campaign?.statistics?.exposures
    },
    {
      header: "Klick",
      data: (campaign) => campaign?.statistics?.clicks
    },
    {
      header: "CTR",
      data: (campaign) => campaign?.statistics?.ctr?.toFixed(2)
    },
    {
      header: "Konv.",
      data: (campaign) => campaign?.statistics?.conversions
    },
    {
      header: "Måluppfyllnad",
      data: (campaign) => <div style={{ padding: "14px 14px 0px 0px" }}>
        <ProgressBar
          variant="success"
          now={
            campaign?.statistics &&
            campaign?.statistics?.fulfillment &&
            campaign?.statistics?.fulfillment
          }
          visuallyHidden
        />
      </div>
    },

    {
      header: "Kanaler",
      data: (campaign) => <ActivityIcons item={campaign} />
    },
    {
      header: " ",
      name: "tableActions",
      data: (campaign) => <TableActions item={campaign} />,
      stopPropagation: true
    }
  ];

  if (typeSmall) { cells = cells?.filter(x => !["Visningar", "Klick", "CTR", "Konv.", "Måluppfyllnad"].includes(x?.header)) }

  if (!ifBigScreen) {
    cells?.splice(3, 5);
  }

  if (ifSmallScreen) {
    cells?.splice(1, 2);
  }

  const handleTableHeaderClick = (property) => {
    if (!property || property?.includes(".")) return;
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };

  return (
    <CardContainer
      style={{ padding: "0" }}
      children={
        <>
          <div className="table-responsive">
            <Table
              id="segmentTable"
              className="table-container"
              responsive
              hover
              size="lg"
            >
              <TableHeader columns={cells} sort={sort} handleTableHeaderClick={handleTableHeaderClick} />


              <tbody className="text-md regular">
                {isSuccess &&
                  campaigns?.map((campaign) => <tr key={campaign?.id} onClick={() => handleShow(campaign?.id)}>
                    {cells?.map((cell, index) => <td
                      key={cell?.header ?? index}
                      onClick={(e) => {
                        if (cell?.stopPropagation) e.stopPropagation()
                      }
                      }>
                      {typeof cell?.data == "function" && cell?.data(campaign)}
                    </td>
                    )}
                  </tr>)
                }
              </tbody>
              <CaptionStatus isError={isError} isLoading={isLoading} totalCount={campaigns?.length} />
            </Table>

            {enablePaging && (
              <PaginationBar
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                countPerPage={rowsPerPage}
                totalCount={campaignsResponse?.paging?.total}
              />
            )}

            <CampaignDetailsOffCanvas
              show={!!showCampaignID}
              handleClose={() => setShowCampaignID(null)}
              campaignId={showCampaignID}
            />

          </div>
        </>
      }
    />
  );
};

export default CampaignTable;
