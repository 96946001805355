import { debounce } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, InputGroup, Pagination, Row } from "react-bootstrap";

export default function PaginationBar({
  currentPage,
  setCurrentPage,
  PAGINATION_MAXITEMS = 3,
  countPerPage = 10,
  totalCount,
  showTotal,
  paging,
}) {
  currentPage = paging?.current_page ?? currentPage;
  countPerPage = paging?.per_page ?? countPerPage;
  totalCount = paging?.total ?? totalCount;

  const [pagination, setPagination] = useState(currentPage);
  const TOTAL_PAGES = Math.ceil(totalCount / countPerPage);
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= TOTAL_PAGES) setCurrentPage(newPage);
  };

  useEffect(() => {
    setPagination(currentPage);
  }, [currentPage]);

  const debouncedPageChange = debounce(() => {
    handlePageChange(pagination);
  }, 1000);
  useEffect(() => {
    if (pagination === currentPage) return;
    debouncedPageChange();
  }, [pagination]);

  const currentRecord = countPerPage * (currentPage - 1);
  const nextRecord =
    currentRecord + countPerPage > totalCount
      ? totalCount
      : +currentRecord + +countPerPage;

  const IsLastPage = !(currentPage - TOTAL_PAGES);

  try {
    return (
      <Row className="w-100 d-flex justify-content-center">
        <Pagination
          size="sm"
          className="justify-content-center big-table-pagination"
        >
          {currentPage >= Math.floor(PAGINATION_MAXITEMS / 2) && (
            <Pagination.First
              active={currentPage === 1}
              onClick={() => handlePageChange(1)}
            ></Pagination.First>
          )}

          <Pagination.Prev
            disabled={currentPage <= 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />

          {Array(
            Math.floor(TOTAL_PAGES / 2) + 1 < PAGINATION_MAXITEMS
              ? Math.floor(TOTAL_PAGES / 2) + 1
              : currentPage - TOTAL_PAGES > -2
              ? Math.floor(PAGINATION_MAXITEMS + (currentPage - TOTAL_PAGES))
              : PAGINATION_MAXITEMS
          )
            .fill()

            .map((_, index, arr) => {
              const PAGINATION_INDEX =
                currentPage <= 2
                  ? index + 1
                  : IsLastPage
                  ? currentPage - (arr.length - (index + 1))
                  : currentPage - Math.floor(arr.length / 2) + index;

              return (
                <Pagination.Item
                  key={index}
                  active={+PAGINATION_INDEX === +currentPage}
                  onClick={() =>
                    PAGINATION_INDEX !== currentPage &&
                    handlePageChange(PAGINATION_INDEX)
                  }
                >
                  {+PAGINATION_INDEX === +currentPage ? (
                    <InputGroup className="p-0 m-0">
                      <Form.Control
                        className=" text-center fw-bold"
                        type="text"
                        value={pagination}
                        onClick={(e) => e.target.select()}
                        onChange={(e) =>
                          +e.target.value <= TOTAL_PAGES && +e.target.value > 0
                            ? setPagination(+e.target.value)
                            : null
                        }
                      />
                    </InputGroup>
                  ) : (
                    PAGINATION_INDEX
                  )}
                </Pagination.Item>
              );
            })}

          {TOTAL_PAGES > currentPage && (
            <>
              <Pagination.Ellipsis
                linkStyle={{
                  backgroundColor: "transparent",
                  border: "none",
                  pointerEvents: "none",
                }}
              />
              <Pagination.Last
                active={currentPage === TOTAL_PAGES}
                onClick={() => handlePageChange(TOTAL_PAGES)}
              >
                {TOTAL_PAGES}
              </Pagination.Last>
            </>
          )}
          <Pagination.Next
            disabled={currentPage >= TOTAL_PAGES}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
        {!!totalCount && !showTotal && (
          <span className="text-center text-md page-count">
            {`${currentRecord + 1}
          ${nextRecord ? `- ${nextRecord} av ${totalCount} träffar` : ``}
            `}
          </span>
        )}
      </Row>
    );
  } catch (error) {
    console.error(`There was a issue rendering ${PaginationBar.name}`, error);
  }
}
