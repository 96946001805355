import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Users = {
  GET: {
    ALL: (id) => {
      //   console.log(id);
      const queryClient = useQueryClient();
      return useQuery({
        queryFn: async () => {
          const result = await criteria(id).catch((badInput) => badInput);
          if (result !== id) return;
          return axios
            .get(`users/v1?customerID=${id}`)
            .then(({ data }) => data);
        },
        queryKey: ["users", id],
        staleTime: 1000 * 60 * 4,
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all users \n Error: `, err),
      });
    },
  },
  POST: () => {
    const queryClient = useQueryClient();
    return useMutation(
      (object) =>
        Promise.resolve(
          axios.post(`/users/v1/adduser`, object).then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("users");
          toast(`Användare skapad!`, {
            type: "success",
          });
        },
        onError: (error) => {
          console.warn(`Failed to POST user \n Error: `, error);
          toast(`[Fel] Det gick inte att skapa användare`, {
            type: "error",
          });
        },
      }
    );
  },
};
