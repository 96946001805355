import "../style.scss";
import { Row, Col } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import { FilterTypesList } from "features/filter";
import { useSelector } from "react-redux";

const ActiveFilterContainer = () => {
  const activeFilterCategory = useSelector(
    (state) => state.filter.activeFilterCategory
  );

  return (
    <>
      {activeFilterCategory.length < 1 ? (
        <Row className="h-100 justify-content-center align-items-center">
          <Col className="add-filter-info-box col-7 text-center d-flex justify-content-center align-items-center">
            <Row className="flex-column g-3">
              <Col>
                <MdInfoOutline size={48} color="#979A97" />
              </Col>
              <Col>
                <p className="text-md">
                  Lägg till en filterkategori till vänster innan du kan göra inställningar här
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <FilterTypesList />
      )}
    </>
  );
};

export default ActiveFilterContainer;
