
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';

import styles from "../../../style.module.css";
import { Chart, Filler } from "chart.js";
import { EMAIL_CHART_COLORS, EMAIL_CHART_COLOR_OPACITY } from '../helper';
import { Card, Col, Container } from 'react-bootstrap';
Chart.register(
  Filler // 1. Register Filler plugin
);

const CampaignBudgetProgress= ({ statistics }) => {
  const chartData = {
    labels: statistics?.map(stats => moment(stats?.date).format("YYYY-MM-DD")),
    datasets: [
      {
        label: 'Budget',
        backgroundColor: EMAIL_CHART_COLORS[2] + EMAIL_CHART_COLOR_OPACITY[20],
        lineTension: 0.2,

        pointRadius: 4,
        borderColor: EMAIL_CHART_COLORS[2],
        data: statistics?.map(statistic => statistic?.budget),
        fill: 1,


      },
      {
        label: 'Kostnad',
        pointRadius: 4,
        lineTension: 0.2,
        backgroundColor: EMAIL_CHART_COLORS[1] + EMAIL_CHART_COLOR_OPACITY[60],
        borderColor: EMAIL_CHART_COLORS[0],
        data: statistics?.map(statistic => statistic?.budgetSpent),
        fill: 'start'
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      filler: {
        propagate: true,
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Dagar',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'SEK',
        },
        suggestedMin: 0,
      },
    },
  }
  return (
    <Col >
      <Card className="card-container p-2 h-100" bg="light">
        <Container className="m-0 g-0" fluid>
          <div className={styles.cardRow}>
            <Col>
              <h4 className="text-lg semi-bold text-capitalize">Ekonomi över tid</h4>
            </Col>
          </div>
          <div className={styles.cardRow} style={{ minHeight: "400px" }}>
            <Line data={chartData} options={options} />
          </div>
        </Container>
      </Card>
    </Col>
  );
};

export default CampaignBudgetProgress
