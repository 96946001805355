import "../style.scss";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { removeSession } from "context/sessionSlice";
import { useQueryClient } from "react-query";

const Logout = ({ ifBigScreen }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.removeQueries();
    dispatch(removeSession());
  };

  return (
    <Link variant="link" onClick={() => handleLogout()}>
      <Row className="px-2">
        <Col
          className={
            ifBigScreen
              ? "col-3 d-flex align-items-center justify-content-end"
              : "col-12 d-flex align-items-center justify-content-center"
          }
        >
          <MdLogout size={ifBigScreen ? 18 : 25} className="logout-icon" />
        </Col>
        {ifBigScreen && (
          <Col className="col-8 d-flex align-items-center">
            <p className="text-lg regular logout-text">Logga Ut</p>
          </Col>
        )}
      </Row>
    </Link>
  );
};

export default Logout;
