import { FILTERSOURCE_SV } from "api/endpoints/FilterTypes";
import { segmentTypes_sv } from "features/segment/segment-details/assets/config";
import moment from "moment";
import { useRef } from "react";
import { Form, Row } from "react-bootstrap";
import { FILTERTYPES, FILTERTYPES_SV } from "../../FilterTypesTable";
import FilterTypeNumber from "./FilterTypeNumber";
import SelectCustomerId from "./SelectCustomerId";

export default function FilterTypeForm({ data, disabled }) {
  const isLocal = useRef();
  return (<>
    <Row className="row-cols-1 row-cols-xl-2 gy-2">

      <Form.Group >
        <Form.Label>Namn</Form.Label>
        <Form.Control type="text" className="text-md" disabled={disabled} name="name" defaultValue={data?.name} />
      </Form.Group>

      <Form.Group >
        <Form.Label>Typ av målgrupp</Form.Label>
        <Form.Select required className="text-md" disabled={disabled} name="segmentType" defaultValue={data?.segmentType == null ? "null" : data?.segmentType}>
          <option value="">Välj</option>
          <option value={"null"}>Alla</option>
          {Object.keys(segmentTypes_sv)?.map(key => <option key={segmentTypes_sv[key]?.text} value={key}>{segmentTypes_sv[key]?.text}</option>)}

        </Form.Select>

      </Form.Group>
    </Row>

    <Row>

      <Form.Group >
        <Form.Label>Path</Form.Label>
        <Form.Control type="text" className="text-md" disabled={disabled} name="path" defaultValue={data?.path} />
      </Form.Group>
    </Row>

    <Row className="row-cols-2 row-cols-xl-4 gy-2">

      <Form.Group >
        <Form.Label>isLocal</Form.Label>
        <Form.Switch className="text-md" disabled={disabled} name="isLocal" defaultChecked={data?.isLocal} ref={isLocal} />
      </Form.Group>

      <Form.Group >
        <Form.Label>isExclude</Form.Label>
        <Form.Switch className="text-md" disabled={disabled} name="isExclude" defaultChecked={data?.isExclude} />
      </Form.Group>

      <Form.Group >
        <Form.Label>Källa</Form.Label>
        <Form.Select className="text-md" disabled={disabled} name="filterSource" defaultValue={data?.filterSource}>
          <option value="">Välj</option>
          {Object.keys(FILTERSOURCE_SV)?.map(key => <option key={FILTERSOURCE_SV[key]} value={key}>{FILTERSOURCE_SV[key]}</option>)}

        </Form.Select>
      </Form.Group>

      <Form.Group >
        <Form.Label>Skapad</Form.Label>
        <Form.Control type="text" className="text-md" disabled defaultValue={moment(data?.createdOn).format("yyyy-MM-DD")} />
      </Form.Group>

    </Row>
    <Row>
      <SelectCustomerId defaultId={data?.customerID} />

    </Row>

    <Row>

      <Form.Group >
        <Form.Label>Typ</Form.Label>
        <Form.Select className="text-md" disabled={disabled} name="type" defaultValue={+data?.type}>
          <option value="">Välj</option>
          {Object.keys(FILTERTYPES_SV)?.map(key => <option key={FILTERTYPES_SV[key]} value={+key}>{FILTERTYPES_SV[key]}</option>)}

        </Form.Select>
      </Form.Group>
    </Row>
    {+FILTERTYPES.Number === +data?.type &&
      <FilterTypeNumber filterType={data} disabled={disabled} />
    }
  </>)
}
