import { Spinner, Badge } from "react-bootstrap";
import { useState } from "react";
import { Table, Row, Col, Image } from "react-bootstrap";
import { CardContainer } from "components/cards";
import Numeral from "react-numeral";
import PaginationBar from "components/pagination/PaginationBar";
import { MdOutlineContentCopy, MdSearch, MdDownload } from "react-icons/md";
import moment from "moment";
import { segmentStatuses_sv } from "features/segment/segment-details/assets/config";
import { TextInput } from "components/forms";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import SegmentDetailsOffCanvas from "features/segment/segment-details/SegmentDetailsOffCanvas";
import Agent from "api/Agent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ExportsTable = (props) => {
  const navigate = useNavigate();
  //Table order, search and pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = props.rows ?? 10;
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ prop: "createdOn", desc: true });

  //Table filters
  const [statusFilter, setStatusFilter] = useState([]);
  const [segmentType, setSegmentType] = useState(undefined);
  const [isAiGenerated, setIsAiGenerated] = useState(false);
  const [disableDownloadExportBtn, setDisableDownloadExportBtn] = useState(null);

  const {
    data: segmentsData,
    isError,
    isLoading,
    error,
  } = Agent.DataExports.GET.ALL(
    currentPage,
    rowsPerPage,
  );
  const data = segmentsData?.data


  // const data = [
  //   {
  //     name: "Sparningstest",
  //     customListID: null,
  //     size: 30537,
  //     type: 1,
  //     subType: null,
  //     status: 1,
  //     prize: "500kr",
  //     id: "e07417a6-835b-4b4f-f82a-08dc807e36d8",
  //     createdOn: "2024-05-30T07:57:55.5281356",
  //     modifiedOn: "2024-06-05T12:26:30.3322122",
  //   },
  //   {
  //     name: "Sparni",
  //     customListID: null,
  //     size: 30537,
  //     type: 1,
  //     subType: null,
  //     status: 3,
  //     prize: "500kr",
  //     id: "e07417a6-825b-4b4f-f82a-08dc807e36d8",
  //     createdOn: "2024-05-30T07:57:55.5281356",
  //     modifiedOn: "2024-06-05T12:26:30.3322122",
  //   },
  //   {
  //     name: "Spa",
  //     customListID: null,
  //     size: 30537,
  //     type: 1,
  //     subType: null,
  //     status: 1,
  //     prize: "500kr",
  //     id: "e07417a6-845b-4b4f-f82a-08dc807e36d8",
  //     createdOn: "2024-05-30T07:57:55.5281356",
  //     modifiedOn: "2024-06-05T12:26:30.3322122",
  //   },
  // ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Segment Details offCanvas
  const [showDetails, setShowDetails] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(false);

  const handleShowSegmentDetails = (segment) => {
    setShowDetails(!showDetails);
    setSelectedSegment(segment);
  };

  // const queryClient = new useQueryClient();
  // const { mutate } = useMutation((id) => deleteSegment({ id }), {
  //   onMutate: (id) => {
  //     setLoadingDelete(true);
  //     setSelectedObject({ id });
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries("segments");
  //     setSelectedObject({ id: null });
  //   },
  //   onError: () => {
  //     setLoadingDelete(false);
  //     setSelectedObject({ id: null });
  //   },
  //   onSettled: () => {
  //     setLoadingDelete(false);
  //   },
  // });

  //*-----------------------------------------------

  const columns = [
    { label: "Målgrupp", property: "name", sortable: true },
    { label: "Status", property: "status", sortable: true },
    { label: "Skapad", property: "createdOn", sortable: true },
    { label: "Typ", property: "type", sortable: true },
    { label: "Storlek", property: "size", sortable: true },
    { label: "Pris", property: "prize" },
    { label: "", property: "download" },
  ];

  const handleTableHeaderClick = (property) => {
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };

  const handleSearch = (input) => {
    setSearch(input);
    currentPage > 1 && setCurrentPage(1);
  };

  return (
    <>
      <Row>
        {props.filter && (
          <Row className="d-flex flex-row align-items-baseline justify-content-between px-0 mx-0">
            <div className="col-auto">
              {/* <FilterDropdown
                setStatusFilter={setStatusFilter}
                setIsAiGenerated={setIsAiGenerated}
                setSegmentType={setSegmentType}
              /> */}
            </div>
            <div style={{ width: "300px" }}>
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </Row>
        )}

        <Col className="col-12">
          <CardContainer
            style={{ padding: "0" }}
            children={
              <div className="table-responsive">
                <Table
                  id="segmentTable"
                  className="table-container"
                  responsive
                  hover
                  size={props.tableSize}
                >
                  <thead className="text-sm semi-bold">
                    <tr>
                      {columns.map((col, index) => (
                        <th
                          style={{ width: "150px", userSelect: "none" }}
                          key={index}
                          onClick={() =>
                            col.sortable && handleTableHeaderClick(col.property)
                          }
                        >
                          {sort?.prop === col.property && col.property ? (
                            <p>
                              {col.label}
                              {sort?.desc ? (
                                <FaSortDown className="ms-1" size={12} />
                              ) : (
                                <FaSortUp className="ms-1" size={12} />
                              )}
                            </p>
                          ) : (
                            <p>
                              {col.label}
                              {!!col?.sortable && (
                                <FaSort className="ms-1" size={12} />
                              )}
                            </p>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="text-md regular">
                    {data?.map((item) => (
                      <tr
                        key={item?.id}
                        onClick={(e) => handleShowSegmentDetails(item)}
                      >
                        <td
                          style={{
                            maxWidth: "400px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          {item?.segment?.name}
                        </td>
                        <td>
                          {
                            <Badge
                              pill
                              bg={item?.dateExported ? "success" : "warning"}
                            >
                              {item?.dateExported ? "Klar" : "Pågående"}
                            </Badge>
                          }
                        </td>
                        <td>
                          {moment(item?.createdOn).format("yyyy-MM-DD")}
                          <p
                            className="text-sm"
                            style={{ color: "#7B8FA1", margin: "-10px 0" }}
                          >
                            {moment(item?.createdOn).format("HH:mm")}
                          </p>
                        </td>
                        <td>
                          {item?.segment?.type === 0
                            ? "Företag"
                            : item?.segment?.type === 1
                              ? "Personer"
                              : item?.segment?.type === 2 && item?.segment?.subType === 0
                                ? "Fordonsägare (företag)"
                                : item?.segment?.type === 2 && item?.segment?.subType === 1
                                  ? "Fordonsägare (personer)"
                                  : ""}
                        </td>
                        <td>
                          <Numeral value={item?.dataExportSize} format={"0,0"} />
                        </td>
                        <td style={{ color: "#2D6450" }}>{item?.cost}</td>

                        <td>
                          <div>
                            {
                              !!item.dateExported &&
                              <button className="edit-button" disabled={disableDownloadExportBtn === item?.id} onClick={async () => {
                                setDisableDownloadExportBtn(item?.id);
                                try {

                                  let res = await Agent.DataExports.GET.ID_NO_QUERY(item?.id)
                                  console.log(res)
                                  window.open(res?.file)
                                } catch (error) {
                                  toast.error(`Export gick inte att hämta export för ${item?.segment?.name}`)
                                }

                                setDisableDownloadExportBtn(null);
                              }}>
                                {disableDownloadExportBtn === item?.id ? <Spinner size="sm" /> : <MdDownload size={18} />}
                              </button>
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data?.paging && !props?.data && !props?.inCampaignDetails && (
                  <PaginationBar
                    setCurrentPage={handlePageChange}
                    paging={data?.paging}
                  />
                )}
                {data && data.length === 0 && (
                  <Col className="d-flex align-items-center justify-content-center p-3">
                    <h5 style={{ color: "#ddd" }}>Inga målgrupper hittade.</h5>
                  </Col>
                )}
              </div>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ExportsTable;
