import "../style.scss";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { targetOptions, columnsSegments } from "../data/stepsData";
import CampaignRadioBox from "../components/CampaignRadioBox";
import CampaignTableSelect from "../components/CampaignTableSelect";
import StepTopRow from "../components/StepTopRow";
import StepBottomRow from "../components/StepBottomRow";
import Agent from "api/Agent";
import { MdSearch } from "react-icons/md";
import { TextInput } from "components/forms";
import RangeSlider from "react-range-slider-input";
import { useMediaQuery } from "react-responsive";
import { getCustomerSettings } from "features/settings/api";

const TargetGroup = () => {
  const isXsScreen = useMediaQuery({ maxWidth: 900 });
  const paddingBottomStyle = isXsScreen ? { paddingBottom: "300px" } : {};
  const navigate = useNavigate();
  const { data: settings } = useQuery("customer", getCustomerSettings);
  //Get Campaign-----------------------------------------------
  //Get campaign id from URL
  const { id } = useParams();

  //Get campaign data using id
  const { data: campaign, refetch, isLoading } = Agent.Campaigns.GET.ID(id);

  //Get all segments -----------------------------------------

  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");

  //Set if autoSegment---------------------------------------

  const [autoSegment, setAutoSegment] = useState(true);
  const [segmentId, setSegmentId] = useState(null);
  const cachedAutoSegmentSize = `${campaign?.id}_autosegment_value`;
  const [autoSegmentSize, setAutoSegmentSize] = useState(
    window.sessionStorage.getItem(cachedAutoSegmentSize) ?? 10
  );
  const [disabled, setDisabled] = useState(false);
  const [segmentType, setSegmentType] = useState(
    !settings.enableCompanyPurchaseProcessing &&
      settings.enablePersonPurchaseProcessing
      ? 1
      : 0
  );

  useEffect(() => {
    if (campaign) {
      if (
        settings &&
        !settings.enableCompanyPurchaseProcessing &&
        !settings.enablePersonPurchaseProcessing
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [campaign, settings]);

  const {
    data: autoSegmentPreview,
    isLoading: autoSegmentPreviewIsLoading,
    refetch: refetchAutoSegmentPreview,
    isRefetching: autoSegmentPreviewIsRefetching,
  } = Agent.Campaigns.GET.AUTOSEGMENTPREVIEW(id, autoSegmentSize, segmentType);

  const onClickHandler = (targetOptions) => {
    setAutoSegment(targetOptions.value);
  };

  useEffect(() => {
    if (campaign) {
      setAutoSegment(campaign.autoSegment);
      setSegmentId(campaign.segmentID);
    }
  }, [campaign]);

  //Set Autosegment size
  const handleRangeInput = (value) => {
    if (value > 10) return;
    setAutoSegmentSize(value);

    try {
      window.sessionStorage.setItem(cachedAutoSegmentSize, value);
    } catch (error) {
      console.error("Error setting session storage item:", error);
    }
  };

  //Set Segment ID
  const onClickHandlerSegmentId = (id) => {
    setSegmentId(id);
  };

  //Toggle disableDateOptimization
  const updateCampaign = Agent.Campaigns.PUT.CAMPAIGN();
  const [disableDateOptimization, setDisableDateOptimization] = useState(
    campaign?.disableDateOptimization
  );

  const onToggleUpdateCampaign = () => {
    setDisableDateOptimization(!disableDateOptimization);
    updateCampaign.mutate(
      {
        ...campaign,
        disableDateOptimization: !disableDateOptimization,
      },
      {
        onSuccess: () => {
          refetchAutoSegmentPreview();
        },
      }
    );
  };

  //Submit-------------------------------------------------
  //Set segmentId if autoSegment
  const postAutoSegment = Agent.Campaigns.POST.AUTOSEGMENT();
  //Update campaign function

  const mutateAsync = async (campaign) =>
    await updateCampaign.mutateAsync(campaign, {
      onSuccess: async () => {
        await refetch();
        navigate(`/campaigns/create-campaign/${id}/prioritize`);
      },
    });
  //onClick
  const onClickSubmit = () => {
    if (autoSegment) {
      postAutoSegment.mutate(
        { id, filterSize: autoSegmentSize, segmentType },
        {
          onSuccess: (res) => {
            const updatedCampaign = {
              ...campaign,
              autoSegment: autoSegment,
              autoScoring: true,
              segmentID: res.data.segmentID,
              completedSteps: !campaign.completedSteps.some(
                (step) => step === "target-group"
              )
                ? [...campaign.completedSteps, "target-group"]
                : [...campaign.completedSteps],
            };

            mutateAsync(updatedCampaign);
          },
        }
      );
    } else {
      const updatedCampaign = {
        ...campaign,
        autoSegment: autoSegment,
        autoScoring: true,
        segmentID: segmentId,
        completedSteps: !campaign.completedSteps.some(
          (step) => step === "target-group"
        )
          ? [...campaign.completedSteps, "target-group"]
          : [...campaign.completedSteps],
      };

      mutateAsync(updatedCampaign);
    }
  };

  const handleSearch = (input) => {
    setSearch(input);
    currentPage > 1 && setCurrentPage(1);
  };

  return (
    <Row className="m-0 px-0 pt-0" style={paddingBottomStyle}>
      {/* TOP----------------------- */}
      <StepTopRow heading="Målgruppssegment" />
      {/* MIDDLE----------------------- */}
      {/* Radio Boxes */}
      <Col className="col-12 p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h4 className="text-lg regular">
              Välj vilka målgruppssegment du vill använda i kampanjen
            </h4>
          </Col>
          <Col className="col-12">
            <Row className="justify-content-between gx-4">
              {targetOptions.map((option) => (
                <Col
                  key={option.value}
                  onClick={
                    option.id === 1 && (autoSegmentPreviewIsLoading || disabled)
                      ? null
                      : () => onClickHandler(option)
                  }
                >
                  <CampaignRadioBox
                    {...option}
                    loading={
                      option.id === 1 &&
                      !autoSegment &&
                      (autoSegmentPreviewIsLoading ??
                        autoSegmentPreviewIsRefetching)
                    }
                    disabled={option.id === 1 && disabled}
                    data={option.id === 1 ? autoSegmentPreview : null}
                    current={option}
                    value={autoSegment}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
      {/* CONTENT----------------------------------------------------------------------------------- */}
      {autoSegment && (
        <Col className="px-5 py-2">
          <Row className="gy-4">
            {/* <Col className="col-12">
              <Row>
                <Col className="col-8">
                  <h4 className="text-lg regular">
                    Ställ in storleksspann och typ för din automatiskt
                    genererade målgrupp
                  </h4>
                </Col>
                <Col className="col-4"></Col>
              </Row>
            </Col> */}
            <Col className="col-6">
              <Form>
                {/* <Form.Label
                  htmlFor="campaignTemplate"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Inaktivera datumoptimering
                </Form.Label>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Form.Check
                    className="py-1 big-switch"
                    type="switch"
                    defaultChecked={campaign.disableDateOptimization}
                    onChange={() => onToggleUpdateCampaign()}
                  />
                )}

                <Form.Label
                  htmlFor="activity_targetGroupMaxSize"
                  className="p-0 text-md mt-5"
                  style={{ color: "#344054" }}
                >
                  Välj storleksspann
                </Form.Label>
                <div className="d-flex justify-content-center flex-wrap">
                  <Form.Control
                    type="text"
                    name="activity_targetGroupMaxSize"
                    value={autoSegmentSize}
                    required
                    onChange={(e) =>
                      handleRangeInput(!e.target.value ? 0 : +e.target.value)
                    }
                    style={{ flex: "1", minWidth: "100px" }}
                  />
                  <div
                    className="d-flex flex-wrap justify-content-center"
                    style={{ flex: 8, minWidth: "200px" }}
                  >
                    <div style={{ flex: "6" }} className="px-2  my-auto">
                      <RangeSlider
                        thumbsDisabled={[true, false]}
                        value={[1, autoSegmentSize]}
                        onInput={(e) => handleRangeInput(e?.at(1))}
                        min={1}
                        max={10}
                        className="single-thumb"
                        step={1}
                      />
                    </div>
                    <Form.Control
                      style={{ flex: "2", minWidth: "70px" }}
                      disabled
                      className="text-center"
                      type="text"
                      value={10}
                    />
                  </div> 
                </div>*/}
                {settings &&
                (settings.enableCompanyPurchaseProcessing &&
                  settings.enablePersonPurchaseProcessing) ? (
                  <>
                    <Form.Label
                      htmlFor="activity_targetGroupMaxSize"
                      className="p-0 text-md"
                      style={{ color: "#344054" }}
                    >
                      Välj typ av målgrupp
                    </Form.Label>
                    <Form.Select
                      className="py-3 text-xl semi-bold"
                      aria-label="Default select example"
                      defaultValue={segmentType}
                      onChange={(e) => setSegmentType(e.target.value)}
                    >
                      {settings.enableCompanyPurchaseProcessing && (
                        <option value={0}>Företag</option>
                      )}
                      {settings.enablePersonPurchaseProcessing && (
                        <option value={1}>Personer</option>
                      )}
                    </Form.Select>
                  </>
                ) : (
                  ""
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      )}
      {/* Select table */}
      {!autoSegment && (
        <Col className="p-5">
          <Row className="gy-4">
            <Col className="col-12">
              <Row>
                <Col className="col-8">
                  <h4 className="text-lg regular">
                    Välj vilka målgruppssegment du vill använda i kampanjen
                  </h4>
                </Col>
                <Col className="col-4">
                  <TextInput
                    type="search"
                    placeholder="Sök"
                    icon={<MdSearch />}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-12">
              <CampaignTableSelect
                tableName={"segments"}
                onClick={onClickHandlerSegmentId}
                value={segmentId}
                columns={columnsSegments}
                search={search}
              />
            </Col>
          </Row>
        </Col>
      )}
      {/* BOTTOM----------------------- */}
      <StepBottomRow
        onClickSubmit={() => onClickSubmit()}
        disabled={autoSegmentPreviewIsLoading || autoSegmentPreviewIsRefetching}
        loading={
          isLoading || postAutoSegment.isLoading || updateCampaign.isLoading
        }
      />
    </Row>
  );
};

export default TargetGroup;
