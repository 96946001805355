
import { CampaignActivityStateOptions } from 'features/campaign/campaign-details/options';
import React from 'react';
import { Row, Col, Button, Spinner, Form } from 'react-bootstrap';
import { BsEyeFill, BsFillCheckCircleFill, BsFillPlayCircleFill, BsPauseCircleFill } from 'react-icons/bs';

const DRAdStateIndicator = ({ activity}) => {
  switch (activity?.state) {
    case CampaignActivityStateOptions.Preparing:
      return (
        <Row className="mt-5 g-4" style={{ color: "#DDCD9E" }}>
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <BsEyeFill size={94} />
          </Col>
          <Col className="col-12 text-center">
            <h5 className="display-sm bold">Din annons förbereds</h5>
          </Col>
        </Row>
      );
    case CampaignActivityStateOptions.Ready:
      return (
        <Row className="mt-5 g-4" style={{ color: "#42A146" }}>
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <BsFillCheckCircleFill size={94} />
          </Col>
          <Col className="col-12 text-center">
            <h5 className="text-lg bold">Annonsen är redo</h5>
          </Col>
          {/*
            <Col className="col-12 d-flex justify-content-center">
              <Button
                onClick={() => {}}
                disabled
                className="d-flex align-items-center semi-bold"
                variant="success"
              >
                Publicera
                <BsFillPlayCircleFill className="mx-2" />
              </Button>
            </Col>
          */}
        </Row>
      );
    case CampaignActivityStateOptions.Running:
      return (
        <Row className="mt-5 g-4" style={{ color: "#3EBEE3" }}>
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <BsFillPlayCircleFill size={94} />
          </Col>
          <Col className="col-12 text-center">
            <h5 className="display-sm bold">Din annons är pågående </h5>
          </Col>
          {/*
            <Col className="col-12 d-flex justify-content-center">
              <Button
                onClick={() => {}}
                disabled
                className="d-flex align-items-center semi-bold"
                variant="warning"
              >
                Pausa <BsPauseCircleFill className="mx-2" />
              </Button>
            </Col>
          */}
        </Row>
      );
    case CampaignActivityStateOptions.OnHold:
      return (
        <Row className="mt-5 g-4" style={{ color: "#C8C58B" }}>
          <Col className="col-12 d-flex justify-content-center align-items-center">
            <BsPauseCircleFill size={94} />
          </Col>
          <Col className="col-12 text-center">
            <h5 className="display-sm bold">Din annons är för tillfället pausad</h5>
          </Col>
          {/*
            <Col className="col-12 d-flex justify-content-center">
              <Button
                onClick={() => {}}
                disabled
                className="d-flex align-items-center semi-bold"
                variant="success"
              >
                Starta  <BsFillPlayCircleFill className="mx-2" />
              </Button>
            </Col>
          */}
        </Row>
      );
    default:
      return null;
  }
};

export default DRAdStateIndicator;
