import { Col, Row } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";
import MobileSMSPreview from "../components/MobileSMSPreview";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const SMSStep3 = ({ text, currentActivity }) => {
  return (
    <Row className="justify-content-between">
      <Col className="col-5">
        <Row>
          <Col className="col-10">
            <h4 className="display-sm semi-bold">Schemalägg smskampanj</h4>
          </Col>
          <Col className="col-2 d-flex align-items-center justify-content-end">
                {statusBadgeSetter(currentActivity)}
              </Col>
          <Col>
            <Row className="mt-5 g-4" style={{ color: "#42A246" }}>
              <Col className="col-12 d-flex justify-content-center align-items-center">
                <BsCheck2Circle size={94} />
              </Col>
              <Col className="col-12 text-center">
                <h5 className="display-sm bold">
                  Din Smskampanj är schemalagd!
                </h5>
              </Col>
            </Row>
            <Row className="mt-5 py-5 g-3 text-lg semi-bold">
              <Col className="col-12">
                <Row>
                  <Col className="col-6">
                    <p>Datum och tid</p>
                  </Col>
                  <Col className="col-6">
                    <p></p>
                    <p></p>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12">
                <Row>
                  <Col className="col-6">
                    <p>Målgrupp</p>
                  </Col>
                  <Col className="col-6">
                    <p>...</p>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12">
                <Row>
                  <Col className="col-6">
                    <p>Antal mottagare</p>
                  </Col>
                  <Col className="col-6">
                    <p>...</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="offset-1 col-6">
        <MobileSMSPreview text={text} />
      </Col>
    </Row>
  );
};

export default SMSStep3;
