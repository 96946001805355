import React, { useState } from "react";
import { ButtonGroup, Button, Modal } from "react-bootstrap";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { sv } from "react-date-range/dist/locale";
import {
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range/dist/defaultRanges";
import { MdCalendarToday } from "react-icons/md";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPeriod } from "../../../features/stats/assets/statisticsSlice";
import { useQueryClient } from "react-query";
import moment from "moment";
import { useEffect } from "react";

const staticRangesLabels = {
  Today: "Idag",
  Yesterday: "Igår",
  "This Week": "Denna vecka",
  "Last Week": "Förra veckan",
  "This Month": "Denna månad",
  "Last Month": "Förra månaden",
};

const inputRangesLabels = {
  "days up to today": "dagar fram till idag",
  "days starting today": "dagar från idag",
};

const additionalRanges = [
  {
    label: "Inom 4 månader",
    range: () => ({
      startDate: moment().subtract(4, "months").toDate(),
      endDate: new Date(),
    }),
  },
  {
    label: "Detta år",
    range: () => ({
      startDate: moment().startOf("year").toDate(),
      endDate: new Date(),
    }),
  },
  {
    label: "Förra året",
    range: () => ({
      startDate: moment().subtract(1, "years").startOf("year").toDate(),
      endDate: moment().subtract(1, "years").endOf("year").toDate(),
    }),
  },
];

function translateRange(dictionary) {
  return (item) =>
    dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
}

const svStaticRanges = defaultStaticRanges.map(
  translateRange(staticRangesLabels)
);
const svInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

export default function ModalDateRangePicker() {
  const queryClient = new useQueryClient();
  const state = useSelector((state) => state.statistics);
  const selectedPeriod = useSelector(
    (state) => state.statistics.selectedPeriod
  );
  const [selection, setSelection] = useState(null);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!show) {
      let shallowCopy = [...selectedPeriod];
      let newSelectedPeriod = shallowCopy.map((period) => ({
        ...period,
        startDate: moment(period.startDate).toDate(),
        endDate: moment(period.endDate).toDate(),
      }));

      setSelection(newSelectedPeriod);
    }
  }, [show, selectedPeriod]);

  const handleShow = () => setShow(true);
  const submitDateSelection = () => {
    let shallowCopy = [...selection];
    let newSelectedPeriod = shallowCopy.map((period) => ({
      ...period,
      startDate: moment(period.startDate).toISOString(),
      endDate: moment(period.endDate).toISOString(),
    }));
    dispatch(setSelectedPeriod([...newSelectedPeriod]));
    Object.keys(state.visual)
      .map((key) => "visual-" + key)
      .forEach((key) => queryClient.invalidateQueries(key));
    handleClose();
  };

  return (
    selection && (
      <>
        <ButtonGroup
          aria-label="Basic example"
          style={{ maxWidth: "fit-content", textWrap: "nowrap" }}
          className="d-flex p-1 g-3 rounded date-picker-group"
        >
          {/* <Button
            variant="light"
            active={true}
            // onClick={() => setSelected(optionName)}
            className="mx-1 px-4 align-items-center "
          >
            <p>1 mån</p>
          </Button>
          <Button
            variant="light"
            active={false}
            className="mx-1 px-4 align-items-center"
          >
            <p>3 mån</p>
          </Button>
          <Button
            variant="light"
            active={false}
            className="mx-1 px-4 align-items-center"
          >
            <p>6 mån</p>
          </Button> */}
          <Button
            onClick={handleShow}
            variant="light"
            style={{ width: "80px" }}
            className="d-flex align-items-center mx-1 px-4 justify-content-around text-md semi-bold p-1"
          >
            <MdCalendarToday />
            {/* <span>
              {`${moment(selection[0].startDate).format("YY-MM-DD")} - ${moment(
                selection[0].endDate
              ).format("YY-MM-DD")}`}
            </span> */}
          </Button>
        </ButtonGroup>

        <Modal contentClassName={styles.modal} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Välj datum</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <DateRangePicker
              onChange={(item) => {
                setSelection([item.selection]);
              }}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              months={1}
              color={"#3ebee3"}
              rangeColors={["#3ebee3"]}
              dateDisplayFormat="d MMM yyyy"
              ranges={selection}
              direction="horizontal"
              locale={sv}
              staticRanges={[
                ...svStaticRanges,
                ...createStaticRanges(additionalRanges),
              ]}
              inputRanges={svInputRanges}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="success" onClick={() => submitDateSelection()}>
              Välj
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
}
