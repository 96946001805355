import Agent from "api/Agent";
import { createContext } from "react";
import { Row, Stack, Col, Button, Spinner } from "react-bootstrap";
import { CampaignActivityStateOptions } from "../../options";
import { useNavigate } from "react-router-dom";
import Draft from "./states/draft/Draft";
import { BsArrowRightCircle } from "react-icons/bs";
import EmailPreview from "./components/EmailPreview";
import EmailFooter from "./components/EmailFooter";
import EditSettingsContainer from "./components/EditSettingsContainer";
import EmailStates from "./components/EmailStates";
import moment from "moment";
export const EmailContext = createContext(null);

const Email = ({ campaign }) => {
  const navigate = useNavigate();
  const currentActivity = campaign.campaignActivities
    ?.filter((_activity) => _activity.type === 5)
    .find((x) => x);

  const { data: segment, isLoading } = Agent.Segments.GET.ID(
    campaign?.segmentID
  );

  const today = moment().format('YYYY-MM-DDTHH:mm:ss');

  const NoEmailsInSegment = !segment?.emailCount;
  const conditions = [
    NoEmailsInSegment && (
      <div
        style={{ background: "#ffc10780" }}
        className="border h-100 col-10 col-md-6 mx-auto p-4 rounded d-flex flex-column align-items-center text-center"
      >
        <span
          style={{ color: "#414141" }}
          className="text-center semi-bold display-sm "
        >
          <strong>Epost adresser saknas</strong>
        </span>
        <span
          style={{ color: "rgb(65 65 65 / 51%)" }}
          className="semi-bold text-lg "
        >
          Det finns inga epost adresser i nuvarande målgrupp.
        </span>
        <Button
          className="mt-3"
          variant="success"
          style={{ width: "300px", fontWeight: 600 }}
          onClick={() =>
            navigate(`/campaigns/create-campaign/${campaign?.id}/target-group`)
          }
        >
          Justera målgrupp <BsArrowRightCircle className="mx-3" />
        </Button>
      </div>
    ),
  ].filter((x) => x);

  if (isLoading) return <Spinner />;

  // Override conditions til fully implimented
  // if (conditions.some((x) => x))
  //   return <Stack className="mt-4 gap-3"> {conditions?.map((x) => x)} </Stack>;

  return (
    <EmailContext.Provider
      value={{
        activity: currentActivity,
        segment: segment,
        campaign: campaign,
      }}
    >
      {CampaignActivityStateOptions.Draft === currentActivity?.state ? (
        <Draft />
      ) : (
        <Row className="d-flex align-content-top justify-content-between gap-4 px-3">
          <Col className="mt-2 g-3 mx-auto" style={{ maxWidth: "600px" }}>
            <Col>
              <EmailStates />
            </Col>
            {currentActivity.state !== 2 && (
              <div className="row d-flex row-cols-1 g-2 mt-1">
                <EditSettingsContainer
                  campaign={campaign}
                  activity={currentActivity}
                  segment={segment}
                />
              </div>
            )}
          </Col>

          <Col className="col-10 col-lg-6 col-xl-5 justify-content-center mx-auto">
            <EmailPreview ad={currentActivity?.ad} />
          </Col>
          {/* <EmailFooter /> */}
        </Row>
      )}
    </EmailContext.Provider>
  );
};

export default Email;
