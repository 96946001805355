import {  useLocation} from "react-router-dom";
import { ResetPasswordContainer } from "features/authentication";
import "./style.scss";
import img from "assets/img/login-bg.jpg";
import { Container, Col, Row, Image, Button, Spinner } from "react-bootstrap";
import Agent from "api/Agent";
import ErrorUI from "components/ErrorUI/ErrorUI";
import { useEffect } from "react";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const query = useQueryParams();
  const email = query.get("email");
  const token = query.get("token");

  // will return 404 if invalid and 200 if OK.
  const validateTokenMutation = Agent.Authenticate.POST.ValidateToken()

  // check validity on component mount
  useEffect(() => {
    validateTokenMutation.mutateAsync({ token : token, email: email })
  },[]);

  const tokenIsValid = validateTokenMutation.isSuccess;
  if (tokenIsValid) return (
    <Container fluid className="login-container">
      <Row>
        <Col md={6} className="col">
          <ResetPasswordContainer />
          {/* Email: {resetEmail}
          Token: {resetToken} */}
        </Col>
        <Col md={6} className="col">
          <Image className="login-img" fluid src={img} />
        </Col>
      </Row>
    </Container>
  );

  const tokenIsInvalid = validateTokenMutation.isError && validateTokenMutation.error?.request?.status === 404;
  if (tokenIsInvalid) return <ErrorUI
    header="Denna sida är inte giltig"
    description={"Den här sidan är inte giltig längre, den har antingen blivit förbrukad eller utgått."}
    Buttons={<Button variant="outline-dark" onClick={() => { document.location.href = "/forgot-password"; }}
    >Begär en ny länk här</Button>} />

  if (validateTokenMutation.isLoading) return <ErrorUI 
    icon={<Spinner />} 
    description="Validerar att sidan är akriv..." />

  // if request failed and token validity is unknown
  return <ErrorUI 
    header="Ett oväntat fel uppstod" 
    description={"Vi kan inte ta reda på om denna sida är giltig eller existerar. Försök igen"} 
    Buttons={<>
      <Button variant="outline-dark" onClick={() => { document.location.reload() }}>Ladda om & försök igen</Button>
      <Button variant="outline-dark" onClick={() => { document.location.href = "/forgot-password"; }}>Begär en ny länk här</Button>
    </>} 
  />
};

export default ResetPassword;
