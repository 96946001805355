import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const MainContent = () => {
  return (
    <Col className={`m-0 p-0 main-content`}>
      <Outlet />
    </Col>
  );
};

export default MainContent;
