import Agent from "api/Agent";
import axios from "api/axios";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { updateCampaignActivity } from "features/campaign/api";
import { useQueryClient, useMutation } from "react-query";
import LexicalEditor from "components/editors/HTML_Editor/LexicalEditor"; 
const LandingPageForm = ({
  campaign,
  activity,
  setSelectedTemplate,
  handleClose,
}) => {

const [validated, setValidated] = useState(false);
  const lpFormRef = useRef();
  const { data: lpTemplates } = Agent.LandingPages.GET.ALL();
  const [imgId, setImgId] = useState("");
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState();
  // const [selectedTemplate, setSelectedTemplate] = useState(null);

  // useEffect(() => {
  //   if (selectedTemplate) {
  //     const template = lpTemplates[selectedTemplate - 1];
  //     console.log({ template });
  //   }
  // }, [selectedTemplate]);

  // Handle image upload and conversion to URL--------
  const lpImgRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  // const { mutate: postImg } = Agent.Files.POST();
  const { data: file } = Agent.Files.GET.ID(imgId);

  // Move the postImg initialization inside the component
  const { mutate: postImg } = useMutation(
    ({ file }) =>
      Promise.resolve(
        axios
          .post(`/files/v1`, file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => data)
      ).catch((err) => Promise.reject({ error: err })),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("files");
      },
      onError: (error) => {
        console.warn(`Failed to POST file \n Error: `, error);
        toast(`[Fel] Det gick inte att skapa en ny fil`, {
          type: "error",
        });
      },
    }
  );

  const handleImageUpload = (e) => {
    e.preventDefault();
    setLoading(true);

    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("file", file);
    console.log({ formData });
    postImg(
      { file: formData },
      {
        onSuccess: (data) => {
          console.log("File-id:", data.id);
          setImgId(data.id);
        },
      }
    );
  };

  useEffect(() => {
    if (file) {
      setSelectedImage(file.url);
      setLoading(false);
    }
  }, [file]);

  //Create landingpage-object handling--------
  const { mutate: postLandingPageForm } = Agent.LandingPages.POST();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),

    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaign");
        queryClient.invalidateQueries("campaigns");
        console.log({ activity });
      },
    }
  );

  const onClickSubmit = (e) => {
    e.preventDefault();
    
    if (lpFormRef?.current?.checkValidity() === false) {
      e.stopPropagation();

    setValidated(true);
      return false;
    }


    const landingPageObject = {
      title: title,
      body: html,
      headerImageURL: selectedImage,
      campaignId: campaign.id,
    };

    postLandingPageForm(landingPageObject, {
      onMutate: () => { },
      onSuccess: (data) => {
        const updatedCampaign = {
          ...activity,
          ad: {
            ...activity.ad,
            landingPageID: data.id,
          },
        };
        mutate(updatedCampaign);

        handleClose();
      },
    });
        setValidated(false);
    
  };

  //TODO: 1. Create Property for if the campaign is setting targetadress or landingpage (or base the default value on if there is an existing landingpageId or not?) 2. Load prev landingpages values into the inputs. 3. Load defaultvalues if already created from landingpageId. 4. Image passing Okay?
  return (
    <Container className="p-0">
      <Row>
        {/* LEFT SIDE------- */}
        <Col className="col-12">
          <Row className="g-5">
            <Col>
              <Form ref={lpFormRef} className="px-4" onSubmit={onClickSubmit} noValidate validated={validated}>
                <Row className="flex-column g-4">
                  {/* <Col>
                    <Form.Label
                      htmlFor="campaignTemplate"
                      className="p-0 text-md"
                      style={{ color: "#344054" }}
                    >
                      Utgå från tidigare skapad landningssida
                    </Form.Label>
                    <Form.Select
                      className="py-1"
                      aria-label="Default select example"
                      value={selectedTemplate || ""}
                      onChange={(e) => setSelectedTemplate(+e?.target?.value)}
                    >
                      <option value={""}>Välj en landningssida</option>
                      {lpTemplates?.map((_template, index) => (
                        <option key={index} value={index + 1}>
                          {_template?.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Col> */}
                  <Col>
                    <Form.Label
                      htmlFor="title"
                      className="p-0 text-lg"
                      style={{ color: "#344054" }}
                    >
                      Rubrik
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="title"
                      name="title"
                      required
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Ange en rubrik.
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label
                      htmlFor="ad-description"
                      className="p-0 text-lg"
                      style={{ color: "#344054" }}
                    >
                      Landningssida
                    </Form.Label>
<>
                    <LexicalEditor onHtmlChanged={(html) => setHtml(html)}/>
                    </>
                  </Col>

                  <Col>
                    <Row className="p-0 m-0 ">
                      <Col className="col-12 ">
                        <Form.Label
                          htmlFor="image"
                          className="p-0 text-lg"
                          style={{ color: "#344054" }}
                        >
                          Bakgrundsbild
                        </Form.Label>
                        {loading && <Spinner />}
                        <Form.Control
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => handleImageUpload(e)}
                          name="campaignImg"
                          ref={lpImgRef}
                        />

                        <Form.Control.Feedback type="invalid">
                          Välj en bild till kampanjen.
                        </Form.Control.Feedback>
                      </Col>

                      <Col className="col-12  pb-2 d-flex justify-content-center">
                        {selectedImage != null && (
                          <img
                            alt="campaign preview"
                            className=" mw-100"
                            style={{ maxHeight: "300px" }}
                            src={selectedImage}
                          />
                        )}
                      </Col>
                      {/*
                      <Col>
                    <Form.Label
                      htmlFor="title"
                      className="p-0 text-lg"
                      style={{ color: "#344054" }}
                    >
                      Dirigera till 
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="redirect"
                      name="redirect"
                    />
                  </Col>
                  */}

                      <Button type="submit" variant="success" className="mt-2">
                        {isLoading ? <Spinner /> : "Spara landningssida"}
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
        {/* RIGHT SIDE------- */}
      </Row>
    </Container>
  );
};

export default LandingPageForm;
