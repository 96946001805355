import { CardContainer } from "components/cards";
import "./style.scss";
import { getVehicles } from "features/leads/api";
import { useState } from "react";
import { Col, Button, Table, Pagination } from "react-bootstrap";
import { useQuery } from "react-query";

const VehiclesToday = ({ accountId }) => {
  const [page, setPage] = useState(1);

  const { isLoading, isError, error, data } = useQuery(
    ["vehicles", accountId, page],
    () =>
      getVehicles(accountId, page).then((res) => {
        console.log("Vehicles:", { res });
        return res.data;
      }),
    { keepPreviousData: true }
  );

  const columns = [
    { id: 1, label: "Märke" },
    { id: 2, label: "Modell" },
    { id: 3, label: "Årsmodell" },
    { id: 4, label: "Färg" },
  ];

  if (isLoading) return "Loading...";

  if (isError) return <p>{error.message}</p>;

  const getPages = () => {
    const elements = [];
    for (let i = 1; i <= data.Paging.total_pages; i++) {
      elements.push(
        <div
          className={`${page === i ? "active page" : "page"}`}
          onClick={() => setPage(i)}
          key={i}
        >
          {i}
        </div>
      );
    }
    return elements; // [<div>1</div>, <div>2</div>....]
  };

  return (
    <Col className="h-100">
      <CardContainer
        marginBottom
        children={
          <>
            <Table
              id="segmentTable"
              className="table-container"
              responsive
              hover
              size="lg"
            >
              <thead className="text-sm semi-bold">
                <tr>
                  {columns.map((col) => (
                    <th key={col.id}>{col.label}</th>
                  ))}
                </tr>
              </thead>

              <tbody className="text-md regular">
                {data.Results.map((item, i) => (
                  <tr key={i}>
                    <td>{item.basic.data.make}</td>
                    <td>{item.basic.data.model}</td>
                    <td>{item.basic.data.model_year}</td>
                    <td>{item.basic.data.color}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* PAGINATION------------------------------ */}
            <div className="vehicle-pagination">
              <div
                // Previous page (<) inactive if current page is 1
                className={`vehicle-pagination-arrow prev ${
                  page === 1 ? "inactive" : ""
                }`}
                onClick={() => page !== 1 && setPage((page) => page - 1)}
              >
                {"<"}
              </div>
              <div className="pages">{getPages()}</div>

              <div
                className={`vehicle-pagination-arrow next ${
                  page === data.Paging.total_pages ? "inactive" : ""
                }`}
                onClick={() =>
                  page !== data.Paging.total_pages &&
                  setPage((page) => page + 1)
                }
              >
                {">"}{" "}
              </div>
            </div>
          </>
        }
      />
    </Col>
  );
};

export default VehiclesToday;
