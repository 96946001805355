import { Badge } from "react-bootstrap";
import { BiTargetLock } from "react-icons/bi";
import { FaTiktok } from "react-icons/fa";
import { MdEmail, MdOutlineSms } from "react-icons/md";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { TbBrandMeta } from "react-icons/tb";

export const CampaignOptions = {
  Overview: "Översikt",
  Statistics: "Statistik",
  Segment: "Målgrupp",
  LeadGeneration: "Leads",
  SignUps: "Anmälningar",
  TikTok: "TikTok",
  Facebook: "Meta",
  Meta: "Meta",
  GoogleAds: "GoogleAds",
  Linkedin: "Linkedin",
  SMS: "SMS",
  Email: "Email",
  DirectMarketing: "Direktreklam",
  AddNewChannel: "+ Lägg till kanal",
};

export const Campaign = { Offer: "Erbjudande", Branding: "Branding" };
export const CampaignTypes = { 0: Campaign.Offer, 1: Campaign.Branding };

export const Conversion = {
  NewCustomers: "Nya kunder",
  Purchases: "Köp",
  Interactions: "Interaktioner",
};

export const ConversionTypes = {
  0: Conversion.NewCustomers,
  1: Conversion.Purchases,
  2: Conversion.Interactions,
};

export const CampaignActivityTypes = {
  0: CampaignOptions.Meta,
  1: CampaignOptions.GoogleAds,
  2: CampaignOptions.Linkedin,
  3: CampaignOptions.SMS,
  // 4: CampaignOptions.LeadGeneration,
  5: CampaignOptions.Email,
  6: CampaignOptions.DirectMarketing,
  7: CampaignOptions.TikTok,
};


export const CampaignOptionsTypes = {
  Meta: 0,
  GoogleAds: 1,
  Linkedin: 2,
  SMS: 3,
  LeadGeneration: 4,
  Email: 5,
  DirectMarketing: 6,
  TikTok: 7,
};

export const CampaignActivityIcons = {
  [CampaignOptionsTypes.Meta]: <TbBrandMeta color="rgb(33, 111, 228)" />,
  [CampaignOptionsTypes.Linkedin]: <SiLinkedin color="#0A66C2" />,
  [CampaignOptionsTypes.TikTok]: <FaTiktok color="#000" />,
  [CampaignOptionsTypes.DirectMarketing]: <BiTargetLock color="rgb(167 118 113)" />,
  [CampaignOptionsTypes.GoogleAds]: <SiGoogleads color="rgb(33, 111, 228)" />,
  [CampaignOptionsTypes.Email]: <MdEmail color="rgb(33, 111, 228)" />,
  [CampaignOptionsTypes.SMS]: <MdOutlineSms color="#017397" />
}


export const CampaignActivityState = {
  0: "Ej påbörjad",
  1: "Påbörjad",
  2: "Aktiv",
  3: "Avslutad",
  4: "Pausad",
};
export const CampaignActivityStateOptions = {
  Draft: 0,
  Ready: 1,
  Running: 2,
  Ended: 3,
  OnHold: 4,
  Preparing: 5,
  Error: 6,
};
export const CampaignActivityStateColor = {
  0: "#3B719F",
  1: "#EBD4A2",
  2: "#5FA052",
  3: "#e53935",
  4: "#e53935",
};

export const MetaPreview = {
  Facebook: [
    { STORY_MOBILE: "FACEBOOK_STORY_MOBILE" },
    { REELS_MOBILE: "FACEBOOK_REELS_MOBILE" },
  ],
  Instagram: [{ INSTAGRAM_REELS: "INSTAGRAM_REELS" }],
};

export const statusBadgeSetter = (activity) => {
  if (activity?.state === 0) {
    return <Badge bg="secondary">Ej påbörjad</Badge>;
  } else if (activity.state === 1) {
    return (
      <Badge pill bg="success">
        Godkänd
      </Badge>
    );
  } else if (activity.state === 2) {
    return (
      <Badge pill bg="info">
        Körs
      </Badge>
    );
  } else if (activity.state === 5) {
    return (
      <Badge pill bg="warning">
        Granskas
      </Badge>
    );
  } else {
    return (
      <Badge pill bg="warning">
        Pausad
      </Badge>
    );
  }
};

export const CampaignActivityTargets = {
  Company: 0,
  BoardMembers: 1,
  DecisionMaker: 2,
  Person: 3,
};
export const CampaignActivityTargets_SV = {
  [CampaignActivityTargets.Person]: "Personer",
  [CampaignActivityTargets.Company]: "Företag",
  [CampaignActivityTargets.BoardMembers]: "Styrelsemedlemmar",
  [CampaignActivityTargets.DecisionMaker]: "Beslutstagare",
};
// public enum CampaignActivityStates { Draft = 0, Ready = 1, Running = 2, Ended = 3, OnHold = 4, Preparing = 5, Error = 6 }
// public enum CampaignActivityStatusReasons { None = 0, MetaUpdatingAudience = 10, MetaAudienceTooSmall = 11, GoogleUpdatingAudience = 20, LinkedInUpdatingAudience = 30 }

const CampaignActivityStatus = {
  Draft: 0,
  Ready: 1,
  Running: 2,
  Ended: 3,
  OnHold: 4,
  Preparing: 5,
  Error: 6,
};

const CampaignActivityStatusReasons = {
  None: 0,
  MetaUpdatingAudience: 10,
  MetaAudienceTooSmall: 11,
  GoogleUpdatingAudience: 20,
  LinkedInUpdatingAudience: 30,
};
