import "../style.scss";
import { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import StepTopRow from "../components/StepTopRow";
import StepBottomRow from "../components/StepBottomRow";
import CampaignInput from "../components/CampaignInput";
import ImageInput from "../components/ImageInput";
import AdContentDisplay from "../components/AdContentDisplay";
import Agent from "api/Agent";

const AdContent = () => {
  const navigate = useNavigate();

  //Get Campaign-----------------------------------------------
  //Get campaign id from URL
  const { id } = useParams();

  //Get campaign data using id
  const { data: campaign } = Agent.Campaigns.GET.ID(id)
  //State variables
  const [adTargetUrl, setAdTargetUrl] = useState("");
  const [adButtonText, setAdButtonText] = useState("");
  const [adTitle, setAdTitle] = useState("");
  const [adDescription, setAdDescription] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (campaign) {
      setAdTargetUrl(campaign.adTargetUrl ? campaign.adTargetUrl : "");
      setAdButtonText(campaign.adButtonText ? campaign.adButtonText : "");
      setAdTitle(campaign.adTitle ? campaign.adTitle : "");
      setAdDescription(campaign.adDescription ? campaign.adDescription : "");
      setImages(campaign.adImageUrl ? JSON.parse(campaign.adImageUrl) : []);
    }
  }, [campaign]);

  //Submit-------------------------------------------------
  //Update campaign function
  const { mutate } = Agent.Campaigns.PUT.CAMPAIGN();
  //onClick
  const onClickSubmit = () => {
    const updatedCampaign = {
      ...campaign,
      adTargetUrl: adTargetUrl,
      adButtonText: adButtonText,
      adTitle: adTitle,
      adDescription: adDescription,
      adImageUrl: JSON.stringify(images),
    };
    mutate(updatedCampaign, {onSuccess: () => navigate("/campaigns", { replace: true })});
  };

  useEffect(() => {
    console.log({ images });
  }, [images]);

  return (
    <Row className="m-0 p-0 h-100">
      {/* TOP----------------------- */}
      <StepTopRow heading="Annonsinnehåll" />
      {/* MIDDLE----------------------- */}
      <Col className="col-6 p-5" style={{ borderRight: "1px solid #dddddd" }}>
        <Row className="gy-4">
          <Col className="col-12">
            <CampaignInput
              label="Måladdress"
              value={adTargetUrl}
              onChange={(e) => setAdTargetUrl(e.target.value)}
              type="text"
              placeholder="http://..."
            />
          </Col>
          <Col className="col-12">
            <CampaignInput
              value={adButtonText}
              onChange={(e) => setAdButtonText(e.target.value)}
              label="CTA text"
              type="text"
              placeholder="Köp din biljett..."
            />
          </Col>
          <Col className="col-12">
            <CampaignInput
              value={adTitle}
              onChange={(e) => setAdTitle(e.target.value)}
              heading="Annonstext"
              label="Rubrik"
              type="text"
              placeholder="Köp din biljett..."
            />
          </Col>
          <Col className="col-12">
            <CampaignInput
              value={adDescription}
              onChange={(e) => setAdDescription(e.target.value)}
              as="textarea"
              label="Beskrivning"
              type="text"
              placeholder="Köp din biljett..."
            />
          </Col>
          <Col className="col-12">
            <ImageInput images={images} setImages={setImages} />
          </Col>
        </Row>
      </Col>
      <Col className="col-6 h-100 p-5">
        <AdContentDisplay
          adTargetUrl={adTargetUrl}
          adButtonText={adButtonText}
          adTitle={adTitle}
          adDescription={adDescription}
          images={images}
        />
      </Col>
      {/* BOTTOM----------------------- */}
      <StepBottomRow onClickSubmit={() => onClickSubmit()} />
    </Row>
  );
};

export default AdContent;
