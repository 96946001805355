import "../style.scss";
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { MdCheckCircle } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import Agent from "api/Agent";

const CampaignListItem = (props) => {
  //Get campaign id from URL
  const { id } = useParams();

  const [completedLinks, setCompletedLinks] = useState([]);

  //Get campaign data using id
  const { data: campaign } = Agent.Campaigns.GET.ID(id)
  
  useEffect(() => {
    if (campaign) {
      setCompletedLinks(campaign?.completedSteps);
    }
  }, [campaign?.completedSteps]);

  const onClickHandler = (event) => {
    event.preventDefault();
  }

  return (
    <>
    {props.ifSmallScreen ? (
      <>
      <NavLink
      className={({ isActive }) =>
        completedLinks.includes(props.link)
          ? "campaign-item-container done"
          : isActive
          ? "active-step campaign-item-container"
          : "campaign-item-container inactive"
      }
      onClick={!completedLinks.includes(props.link) && onClickHandler}
      style={!completedLinks.includes(props.link) ? {cursor: "text"} : {cursor: "pointer"}}
      to={props.link}
      key={props.id}
    >
      {campaign?.completedSteps?.includes(props.link) && (
        <Col className="done-check-icon">
          <MdCheckCircle size={23} />
        </Col>
      )}
      <Row className="justify-content-center align-items-center px-3">
        <Col
          className="col-12 filter-item-icon-col"
          style={{ backgroundColor: props.iconBg }}
        >
          {props.icon}
        </Col>
      </Row>
    </NavLink>
      </>
    ) : (
      <>
      <NavLink
      className={({ isActive }) =>
        completedLinks.includes(props.link)
          ? "campaign-item-container done"
          : isActive
          ? "active-step campaign-item-container"
          : "campaign-item-container inactive"
      }
      onClick={!completedLinks.includes(props.link) && onClickHandler}
      style={!completedLinks.includes(props.link) ? {cursor: "text"} : {cursor: "pointer"}}
      to={props.link}
      key={props.id}
    >
      {campaign?.completedSteps?.includes(props.link) && (
        <Col className="done-check-icon">
          <MdCheckCircle size={23} />
        </Col>
      )}
      <Row className="justify-content-center align-items-center px-3">
        <Col
          className="col-12 filter-item-icon-col"
          style={{ backgroundColor: props.iconBg }}
        >
          {props.icon}
        </Col>
        <Col>
          <Row className="gy-0">
            <Col className="col-12">
              <Row className="justify-content-between">
                <Col className="col-12">
                  <h4 className="display-xs semi-bold m-0">{props.title}</h4>
                </Col>
                <Col className="col-12">
                  <p className="text-xs regular m-0">{props.subText}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </NavLink>
      </>
    )
    }
    </>
  );
};

export default CampaignListItem;
