import { CardContainer } from "components/cards";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { MdAddCircleOutline, MdCampaign } from "react-icons/md";
import "../style.scss";
import CampaignStats from "./CampaignStats";
import { useNavigate } from "react-router-dom";
import Agent from "api/Agent";

const CampaignOverviewContainer = ({ifXsScreen, ifMdAddCircleOutlineIsSmall,xsValue }) => {
  const {
    data,
    isLoading,
  } = Agent.Campaigns.GET.ALL();
  const campaigns = data?.data
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/campaigns");
  };

  return (
    <CardContainer
      h100
      padding
      children={
        <Container className="m-0 g-0" fluid>
          <Row className="mb-4 justify-content-between">
            <Col>
              <h4 className="text-lg semi-bold">Mina Kampanjer</h4>
            </Col>
            <Col className="col-2 d-flex justify-content-end align-items-center">
              <MdCampaign size={30} color="#ddd" />
            </Col>
          </Row>
          <Row
          //  style={{ height: "20vh" }}
           >
            {isLoading ? (
              <Col className="justify-content-center text-center">
              <Spinner />
              </Col>
            ) : !isLoading && campaigns && campaigns.length > 0 ? (
              <CampaignStats {...{ifXsScreen, ifMdAddCircleOutlineIsSmall,xsValue }} />
            ) : (
                  <Container fluid >
                    <Row className="h-100">
                      <Col className="col-12">
                        <Row className={`justify-content-evenly  mx-auto ${ifXsScreen ? 'p-0' : 'h-100 p-3'}`}>
                          <Col md={6} className="overviewInfoBox h-100 ">
                            <Alert variant="warning" className={ifXsScreen ? 'd-flex' : 'h-100 d-flex'}>
                              <Row className="justify-content-center text-center">
                                <Col
                                  className={` semi-bold d-flex align-items-center ${ifXsScreen ? 'text-sm ' : 'text-lg'}`}
                                  style={{ color: "rgb(174 165 138)" }}
                                >
                                  Skapa din första kampanj för att se statistik från dina annonser och utskick här!
                                </Col>
                              </Row>
                            </Alert>
                          </Col>
                          <Col xs={12} md={6} className="overviewCreateButton" onClick={handleButtonClick}>
                            <Row className="justify-content-evenly mx-auto">
                              {!ifMdAddCircleOutlineIsSmall &&
                                <Col xs={3} md={3} lg={3} className="d-flex align-items-center">
                                  <MdAddCircleOutline size={65} />
                                </Col>
                              }
                              <Col xs={xsValue} md={9}>
                                <Row className="g-0 flex-column">
                                  <Col>
                                    <h4 className={`semi-bold m-0 ${ifXsScreen ? 'text-sm ' : ''}`}>
                                    Ladda upp kundlista
                                    </h4>
                                  </Col>
                                  <Col>
                                    <p className="text-sm ">
                                    Meta, LinkedIn, Email....
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
            )}
          </Row>
        </Container>
      }
    />
  );
};

export default CampaignOverviewContainer;
