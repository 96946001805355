import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsArrowRightShort } from "react-icons/bs";
import RangeSlider from "react-range-slider-input";

const AiEditSegment = ({ filter, value, setNewValue }) => {
  function parseStringToRange(inputString) {
    const cleanedString = inputString.replace(/[()\[\]]/g, "");
    const numbers = cleanedString.split(",");
    const parsedNumbers = numbers.map((num) => parseInt(num.trim()));
    return parsedNumbers;

  }
  const [sliderValue, setSliderValue] = useState(value);
  const handleInput = (e) => {

    setSliderValue(e);
    setNewValue(e);
  }

  return (
    <Row className="px-3">
      <Col className="col-5">
        <Row className="g-2">
          <Col className="col-12">
            <h6 className="display-xs" style={{ color: "#00000073" }}>
              Prioritering:{" "}
              <span style={{ color: "#00789e" }} className="bold">
                {filter.feature_importance}
              </span>
            </h6>
          </Col>
          <Col className="col-12">
            <h6 className="display-xs" style={{ color: "#00000073" }}>
              Kategori:{" "}
              <span style={{ color: "#00789e" }} className=" bold">
                {filter.filter_category}
              </span>
            </h6>
          </Col>
        </Row>
      </Col>

      <Col className="col-7">
        <Row className="g-2">
          {filter.selection?.length > 1 && (
            <Col className="col-12 d-flex">
              <Col className="col-3">
                <h6 className="display-xs" style={{ color: "#00000073" }}>
                  Tröskelvärde:
                </h6>
              </Col>
              <Col className="col-10 g-3 threshold-slider d-flex justify-content-between align-items-center">
                <Col className="col-7 position-relative slider-opposite">
                  <RangeSlider
                    thumbsDisabled={[false, true]}
                    min={filter.selection_importance_min}
                    max={filter.selection_importance_max}
                    value={sliderValue}
                    onInput={handleInput}
                    className="single-thumb"
                    rangeSlideDisabled={true}
                    step={0.01}
                  />
                  <div
                    style={{
                      position: "absolute",
                      color: "rgb(189 189 189)",
                      fontSize: "12px",
                      fontWeight: 200,
                      bottom: "15px",
                      left: "0px",
                    }}
                  >
                    <strong>{filter.selection_importance_min?.toFixed(2)}</strong>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      color: "rgb(189 189 189)",
                      fontSize: "12px",
                      fontWeight: 200,
                      bottom: "15px",
                      right: "0px",
                    }}
                  >
                    <strong>{filter.selection_importance_max.toFixed(2)}</strong>
                  </div>
                </Col>
                <Col className="col-5">
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#00789e",
                      marginLeft: "10px",
                      fontWeight: 700,
                    }}
                  >
                    {value[0].toFixed(2)} <BsArrowRightShort size={19} />{" "}
                    {value[1]}
                  </p>
                </Col>
              </Col>
            </Col>
          )}

          {/* )} */}
          <Col className="col-12 d-flex">
            <Col>
              <h6 className="display-xs" style={{ color: "#00000073" }}>
                <Row className=" g-1">
                  <Col className="col-1" style={{ width: "fit-content" }}>
                    Filter:{" "}
                  </Col>

                  {filter.ft_type === 2 &&
                    filter.selection
                      ?.filter(
                        (item) =>
                          item.importance >= filter?.selection_importance_threshold
                      )
                      ?.map((selection) => {
                        const parsedValues = parseStringToRange(selection.value);
                        return (
                          <Col>
                            <span
                              style={{
                                color: "#fff",
                                textWrap: "nowrap",
                                width: "fit-content",
                                backgroundColor: "rgb(0 120 158)",
                                borderRadius: "4px",
                                padding: "3px 6px",
                              }}
                            >
                              Mellan {parsedValues[0]} - {parsedValues[1]}
                              {selection.importance &&
                                (
                                  <span className="regular text-sm">
                                    ({selection.importance.toFixed(2)}){" "}
                                  </span>
                                )
                              }
                            </span>
                          </Col>
                        );
                      })}
                  {filter.ft_type !== 2 &&
                    filter.selection
                      ?.filter(
                        (item) =>
                          item.importance >= value[0] &&
                          item.importance <= value[1]
                      )
                      .map((selection) => (
                        <Col className="bold my-1">
                          <span
                            style={{
                              color: "#fff",
                              textWrap: "nowrap",
                              width: "fit-content",
                              backgroundColor: "rgb(0 120 158)",
                              borderRadius: "4px",
                              padding: "3px 6px",
                            }}
                          >
                            {filter.ft_type === 6 && selection.value === "0"
                              ? "Nej"
                              : filter.ft_type === 6 && selection.value === "1"
                                ? "Ja"
                                : selection.value}
                            {filter.ft_type !== 6 && (
                              <span className="regular text-sm">
                                ({selection.importance.toFixed(2)}){" "}
                              </span>
                            )}
                          </span>
                        </Col>
                      ))}
                </Row>
              </h6>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AiEditSegment;
