import { Col, Row, Form } from "react-bootstrap";
import {
  patchUpdateCampaignActivity,
  patchUpdateCampaignActivityAd,
} from "features/campaign/campaign-details/api";
import { AiOutlineEdit } from "react-icons/ai";
import EditDateTime from "./editsettingscomponents/EditDateTime";
import { useMutation, useQueryClient } from "react-query";
import Agent from "api/Agent";
import EditTargetGroup from "./editsettingscomponents/EditTargetGroup";
import EditTargetGroupMaxSize from "./editsettingscomponents/EditTargetGroupMaxSize";
import EditBudget from "./editsettingscomponents/EditBudget";
import EditTargetURL from "./editsettingscomponents/EditTargetURL";

const EditSettingsContainer = ({ activity, campaign }) => {
  const queryClient = useQueryClient();
  const { mutate: updateAd, isLoading: updateAdLoading } = useMutation(
    (obj) => patchUpdateCampaignActivityAd(activity.ad.id, obj),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id]);
      },
    }
  );

  const { data: segment } = Agent.Segments.GET.ID(campaign.segmentID);

  return (
    <Row className="mt-5 g-3 text-lg semi-bold edit-settings-container">
      <Col className="col-12">
        <EditDateTime
          activity={activity}
          updateAd={updateAd}
          updateAdLoading={updateAdLoading}
        />
      </Col>

      <Col className="col-12">
        <EditTargetGroup campaign={campaign} segment={segment} />
      </Col>
      <Col className="col-12">
        <EditTargetGroupMaxSize campaign={campaign} activity={activity} />
      </Col>
      <Col className="col-12">
        <EditBudget activity={activity} />
      </Col>
      <Col className="col-12">
        <EditTargetURL activity={activity} />
      </Col>
    </Row>
  );
};

export default EditSettingsContainer;
