import PaginationBar from 'components/pagination/PaginationBar';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Table } from 'react-bootstrap'

export default function VisualCardTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const countperPage = 7;
  const values = data?.Chart?.data?.datasets?.at(0)?.data;
  const props = data?.Chart?.data?.labels?.map((label, index) => ({ label: label, value: values?.at(index) }));
  const totalCount = props?.length;

  useEffect(() => {setCurrentPage(1)},[])
  
  return <Table>
    <thead>
      <tr>
        <th>
          Namn
        </th>
        <th>
          Antal
        </th>
      </tr>
    </thead>
    <tbody>
      {props.slice((currentPage - 1) * countperPage, ((currentPage - 1) * countperPage) + countperPage)?.map(prop =>
        <tr key={prop?.label}>
          <td>{prop?.label}</td>
          <td>{prop?.value}</td>
        </tr>
      )}

    </tbody>
    <caption>
      <PaginationBar showTotal={true} currentPage={currentPage} setCurrentPage={setCurrentPage} totalCount={totalCount} countPerPage={countperPage} />
    </caption>
  </Table>

}


