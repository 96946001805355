import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { BiTargetLock } from 'react-icons/bi';
import { FaTiktok } from 'react-icons/fa';
import { MdMailOutline, MdOutlineSms } from 'react-icons/md';
import { SiGoogleads, SiLinkedin } from 'react-icons/si';
import { TbBrandMeta } from 'react-icons/tb';

const ActivityIcons = ({ item }) => {
  return (
    <Row className="gx-2">
      {item?.enableFacebook && (
        <Col className="text-center col-4">
          <TbBrandMeta size={18} color="#216FE4" />
        </Col>
      )}
      {item?.enableLinkedIn && (
        <Col className="text-center col-4">
          <SiLinkedin size={18} color="#0A66C2" />
        </Col>
      )}
      {item?.enableGoogleAds && (
        <Col className="text-center col-4">
          <SiGoogleads size={18} color="#216FE4" />
        </Col>
      )}
      {item?.enableTikTok && (
        <Col className="text-center col-4">
          <FaTiktok size={18} color="#000" />
        </Col>
      )}
      {item?.enableLeadGeneration && (
        <Col className="text-center col-4">
          <BiTargetLock size={18} color="rgb(167 118 113)" />
        </Col>
      )}
      {item?.enableEmail && (
        <Col className="text-center col-4">
          <MdMailOutline size={18} color="#B3CCC3" />
        </Col>
      )}
      {item?.enableSms && (
        <Col className="text-center col-4">
          <MdOutlineSms size={18} color="#017397" />
        </Col>
      )}
    </Row>
  );
};

export default ActivityIcons;

