import { configureStore } from "@reduxjs/toolkit";
import { filterSlice } from "features/filter/filterSlice";
import { campaignSlice } from "features/campaign/campaignSlice";
import { statisticsSlice } from "features/stats/assets/statisticsSlice";
import { leadSlice } from "features/leads/leadSlice";
import { directAdSlice } from "features/campaign/campaign-details/features/dr/drSlice";
import { sessionSlice } from "context/sessionSlice";
import { filterAdminSlice } from "features/adminFilters/filterAdminSlice";

// import { segmentSlice } from "features/segment/segmentSlice";

export const store = configureStore({
  reducer: {
    session : sessionSlice.reducer,
    filter: filterSlice.reducer,
    campaign: campaignSlice.reducer,
    statistics: statisticsSlice.reducer,
    lead: leadSlice.reducer,
    directAd: directAdSlice.reducer,
    filterAdmin: filterAdminSlice.reducer,
    
    // segment: segmentSlice.reducer,
  },
});
