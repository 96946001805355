import { Col, Row } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import EditDateTime from "./EditDateTime";
import { patchUpdateCampaignActivityAd } from "features/campaign/campaign-details/api";

const EditSettingsContainer = ({ activity, campaign }) => {
  const queryClient = useQueryClient();
  const { mutate: updateAd, isLoading: updateAdLoading } = useMutation(
    (obj) => patchUpdateCampaignActivityAd(activity.ad.id, obj),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id]);
      },
    }
  );
  return (
    <Row className="mt-5 g-3 text-lg semi-bold edit-settings-container">
      <Col className="col-12">
        <EditDateTime
          activity={activity}
          updateAdLoading={updateAdLoading}
          updateAd={updateAd}
        />
      </Col>
      {/* <Col className="col-12">
        <EditTargetGroup campaign={campaign} segment={segment} />
      </Col>
      <Col className="col-12">
        <EditTargetGroupMaxSize campaign={campaign} activity={activity} />
      </Col>
      <Col className="col-12">
        <EditBudget activity={activity} />
      </Col>
      <Col className="col-12">
        <EditTargetURL activity={activity} />
      </Col> */}
    </Row>
  );
};

export default EditSettingsContainer;
