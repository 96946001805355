import VisualTotalSelect from "./assets/VisualTotalSelect";
import styles from "./assets/style.module.css";
import VisualSimple from "../../components/cards/visual-card/VisualCardDesign";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { useVisualData } from "./assets/hooks";
import { MdArrowDownward, MdArrowForward, MdArrowUpward } from "react-icons/md";
import VisualSegmentedMap from "./visual-segmentedMap/VisualSegmentedMap";
import { CARD_TYPE } from "./assets/options";
import { useDispatch, useSelector } from "react-redux";
import {
  addFilters,
  addFilterTotal,
  removeFilter,
  removeFilterProp,
} from "./assets/statisticsSlice";
import { colorPallette } from "./assets/config";
import { useEffect } from "react";
import moment from "moment";
import VisualCardTable from "components/cards/visual-card/VisualCardTable";

const DEFAULT = {
  TABLE_LIMIT: 60,
  GRAPH_LIMIT: 10
}

export default function VisualCard({ visual }) {
  const dispatch = useDispatch();
  const filter = useSelector((state) =>
    state.statistics.filters.filter((x) => x.id === visual?.id)
  );
  const filters = useSelector((state) => state.statistics.filters);
  const toggleTable = useSelector((state) => state.statistics.visual.viewAsTable)?.find(x => x === visual?.id)
    ;
  const { isLoading, error, data, refetch } = useVisualData(
    visual?.id,
    filters,
    toggleTable ? DEFAULT.TABLE_LIMIT : DEFAULT.GRAPH_LIMIT
  );

  // console.log(visual?.type === CARD_TYPE.SumTotal && { visual }, { data });

  useEffect(() => {
    refetch();
  }, [filters]);

  if (isLoading)
    return (
      <VisualSimple visual={visual}>
        <span>Laddar...</span>
      </VisualSimple>
    );

  if (error)
    return (
      <VisualSimple visual={visual} bg={"danger"}>
        <span className=" text-center bold  text-xl">Error...</span>
      </VisualSimple>
    );
  const handleChartClick = (event, elements) => {
    try {
      if (!elements?.length) return;
      const { datasetIndex, index } = elements[0];
      const clickedValue = data?.Chart?.labelValues[index] ?? data?.Chart.data.labels[index];
      const activeFilter = filter?.filter((filter) =>
        filter.values?.some((value) => value === clickedValue)
      );
      const newFilter = !activeFilter?.length;
      if (newFilter)
        return dispatch(addFilters({ id: visual?.id, value: clickedValue }));

      const clickedValueIsOnlyValue = activeFilter[0].values?.length <= 1;
      if (clickedValueIsOnlyValue)
        return dispatch(removeFilter(activeFilter[0]));
      return dispatch(
        removeFilterProp({ id: visual?.id, value: clickedValue })
      );
    } catch (error) {
      console.error(
        "There was an error managing the click event for chart",
        error
      );
    }
  };

  const handleSumTotalClick = (option, triggeredAction) => {
    try {
      if (triggeredAction.action === "clear") {
        return dispatch(
          removeFilter({
            id: visual?.id,
            value: triggeredAction.removedValues[0].value,
          })
        );
      }
      const clickedValue = option.value;
      const activeFilter = filter?.filter((filter) =>
        filter.values?.some((value) => value === clickedValue)
      );
      const newFilter = !activeFilter?.length;

      if (newFilter)
        return dispatch(
          addFilterTotal({ id: visual?.id, value: clickedValue })
        );
    } catch (error) {
      console.error(
        "There was an error managing the click event for chart",
        error
      );
    }
  };

  const IsChart =
    visual.type !== CARD_TYPE.SumTotal && visual.type !== CARD_TYPE.Map;
  if (IsChart) {
    try {
      data.Chart.options = addDefaultBackgroundColorToChart(data.Chart.options);
      data.Chart.options = {
        ...data.Chart.options,
        ...addDefaultOptionsToChart(handleChartClick),
      };
    } catch (error) {
      console.error(
        "There was an issue applying default chart options \n",
        error
      );
    }
  }

  const handleChartBackgroundColor = (colorIndex) => {
    try {
      if (filter?.length <= 0) return data.Chart.options.backgroundColor;
      return (
        data?.Chart?.data?.datasets[0]?.data &&
        Object.keys(data?.Chart?.data?.datasets[0]?.data)?.map(
          (color, index) => {
            const labels = data?.Chart?.labelValues ?? data?.Chart?.data?.labels;
            const selectedProps = labels.filter((prop) =>
              filter.some((filter) =>
                filter?.values?.some((value) => value === prop)
              )
            );
            const indexOfSelectedProps = selectedProps?.map((x) =>
              labels?.indexOf(x)
            );

            const baseColor =
              colorIndex !== undefined
                ? colorPallette[colorIndex]
                : colorPallette[0];

            color = addTintToHexColor(baseColor, 0.6);
            if (indexOfSelectedProps.some((prop) => prop === index) > 0) {
              color = addTintToHexColor(baseColor, 0);
            }
            return color;
          }
        )
      );
    } catch (error) {
      console.error(
        "There was an error configuring the background color for chart",
        error
      );
    }
  };


  const VisualChart = {
    [CARD_TYPE.Map]:
      <VisualSegmentedMap
        ID={visual.id}
        handleChartBackgroundColor={handleChartBackgroundColor}
        addDefaultOptionsToChart={addDefaultOptionsToChart(
          handleChartClick
        )}
      />,
    [CARD_TYPE.DoughnutChart]:
      <Doughnut
        data={data.Chart.data}
        options={{
          ...data.Chart.options,
          onClick: handleChartClick,
          backgroundColor: handleChartBackgroundColor(2),
        }}
      />,
    [CARD_TYPE.LineChart]:
      <Line
        data={data.Chart.data}
        options={{
          ...data.Chart.options,
          borderColor: "rgb(0, 0, 0)",
        }}
      />,

    [CARD_TYPE.PieChart]:
      <Pie
        data={data.Chart.data}
        options={{
          ...data.Chart.options,

          backgroundColor: handleChartBackgroundColor(4),
        }}
      />,

    [CARD_TYPE.BarChart]:
      <Bar
        data={data.Chart.data}
        options={{
          ...data.Chart.options,

          backgroundColor: handleChartBackgroundColor(0),
        }}
      />
  }[visual?.type]


  return (
    <VisualSimple title={visual.name} visual={visual}>
      {visual.type === CARD_TYPE.SumTotal && (
        <>
          <div className={styles.row}>
            {data.Total !== undefined && (
              <h1 className="display-sm semi-bold">{data.Total}</h1>
            )}
            <div
              className={styles.badge}
              style={data.Diff < 0 ? { backgroundColor: "#FFDEDB" } : null}
            >
              {data.Diff !== null && (
                <span className="d-flex justify-content-between align-items-center mx-1">
                  {data.Diff > 0 ? (
                    <>
                      <MdArrowUpward /> {data.Diff + "%"}
                    </>
                  ) : data.Diff < 0 ? (
                    <>
                      <MdArrowDownward /> {data.Diff + "%"}
                    </>
                  ) : (
                    <>
                      <MdArrowForward /> {data.Diff + "%"}
                    </>
                  )}
                </span>
              )}
            </div>
          </div>

          {data.HasOptions && (
            <VisualTotalSelect
              visual={visual}
              handleSumTotalClick={handleSumTotalClick}
              filter={filter}
            />
          )}
        </>
      )}
      <div
        className="py-2 h-100"
        style={visual.type !== CARD_TYPE.SumTotal ? { minHeight: "400px" } : {}}
      >
        {toggleTable ? <VisualCardTable data={data} /> : VisualChart}
      </div>
    </VisualSimple>
  );
}

function addDefaultBackgroundColorToChart(options) {
  if (!options.backgroundColor) {
    options.backgroundColor = colorPallette;
  }

  return options;
}

function addDefaultOptionsToChart(handleChartClick) {
  return {
    onClick: handleChartClick,
    plugins: {
      tooltip: {
        callbacks: {
          title: function(context) {
            let label = context[0]?.label || "";

            return ChartTooltipFormat(label);
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function(val, index) {
            const value = this.getLabelForValue(val);
            return ChartLabelsFormat(value);
          },
        },
        grid: {
          display: false,
        },

        stacked: true,
      },
      y: {
        beginAtZero: true,

        stacked: false,

        ticks: {
          beginAtZero: true,
        },
      },
    },

    maintainAspectRatio: false,
  };
}

function addTintToHexColor(hexColor, tintAmount) {
  const color = hexColor.replace("#", "");

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const tintedR = Math.min(Math.round(r + (255 - r) * tintAmount), 255);
  const tintedG = Math.min(Math.round(g + (255 - g) * tintAmount), 255);
  const tintedB = Math.min(Math.round(b + (255 - b) * tintAmount), 255);

  const tintedHex = `#${tintedR.toString(16).padStart(2, "0")}${tintedG
    .toString(16)
    .padStart(2, "0")}${tintedB.toString(16).padStart(2, "0")}`;

  return tintedHex;
}

function ChartLabelsFormat(value) {
  const date = moment(value, "YYYY-MM-DDTHH:mm:ssZ", true);
  if (date.isValid()) return moment(value).format("YYYY-MM-DD hh:mm");

  const MAX_LENGTH = 16;
  const IsLongLabel = value.length > MAX_LENGTH;
  if (IsLongLabel) return value.slice(0, MAX_LENGTH) + "...";

  return value;
}

function ChartTooltipFormat(value) {
  const date = moment(value, "YYYY-MM-DDTHH:mm:ssZ", true);
  if (date.isValid()) return moment(value).format("YYYY-MM-DD hh:mm");

  return value;
}
