import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drObject: {
    directMarketingTarget: 0,
    targetGroupMaxSize: 0,
    generateQrCode: false,
    ad: {
      targetUrl: "",
      template: {
        adSize: { size: "A5", orientation: "landscape" },
        selectedImageFront: null,
        selectedImageBack: null,
      },
      startDate: "",
      endDate: "",
    },
  },
  wizard : {
    cache : null
  }
};

export const directAdSlice = createSlice({
  name: "directAd",
  initialState,
  reducers: {
    updateDrObject: (state, action) => {
      state.drObject = action.payload;
    },
    updateDrTemplate: (state, action) => {
      state.drObject.ad.template = action.payload;
    },
    setWizardCache: (state, action) => {
      state.wizard.cache = action.payload;
    },
    
  },
});

export const { updateDrObject, updateDrTemplate, setWizardCache } = directAdSlice.actions;
