import { useContext, useEffect } from "react";
import { SegmentDetailsContext } from "../../SegmentDetailsOffCanvas";
import PreviewLayout from "./layouts/PreviewLayout";

export const SEGMENT_PREVIEW_AGGREGATIONS = {
  person: {
    gender: "gender",
    birth_year: "birth_year",
    is_board_member: "is_board_member",
    has_phone: "has_phone",
    address: {
      zip_code: "address.zip_code",
      city: "address.city",
      municipality_code: "address.municipality_code",
      county_code: "address.county_code",
      move_date: "address.move_date",
    },
    currentVehicles: {
      make: "currentVehicles.make",
      model: "currentVehicles.model",
      purchase_date: "currentVehicles.purchase_date",
      type_high: "currentVehicles.type_high",
      vehicle_year: "currentVehicles.vehicle_year",
      model_year: "currentVehicles.model_year",
      chassi: "currentVehicles.chassi",
      color: "currentVehicles.color",
      eco_class: "currentVehicles.eco_class",
      emission_class: "currentVehicles.emission_class",
      leasing: "currentVehicles.leasing",
      bought_on_credit: "currentVehicles.bought_on_credit",
      status: "currentVehicles.status",
      fuels: "currentVehicles.fuels",
    },
    historicalVehicles: {
      make: "historicalVehicles.make",
      model: "historicalVehicles.model",
      sold_date: "historicalVehicles.sold_date",
      purchase_date: "historicalVehicles.purchase_date",
      type_high: "historicalVehicles.type_high",
      vehicle_year: "historicalVehicles.vehicle_year",
      model_year: "historicalVehicles.model_year",
      chassi: "historicalVehicles.chassi",
      color: "historicalVehicles.color",
      eco_class: "historicalVehicles.eco_class",
      emission_class: "historicalVehicles.emission_class",
      fuels: "historicalVehicles.fuels",
    },
    residence: {
      type: "residence.type",
      living_area: "residence.living_area"
    }
  },
  company: {
    name_legal: "name_legal",
    address: {
      zip_code: "address.zip_code",
      city: "address.city",
      municipality_code: "address.municipality_code",
      county_code: "address.county_code"
    }

  }
};


const SegmentPreview = ({ segment }) => {
  const { setPreviewState } = useContext(SegmentDetailsContext)
  useEffect(() => { setPreviewState({ filters: [], size: null }) }, [])

  return < PreviewLayout segment={segment} />

}

export default SegmentPreview
