import { Row, Col, Button, Container } from "react-bootstrap";
import { ButtonMain } from "components/buttons";
import { useState } from "react";
import { ModalCreateSegment } from "features/segment";
import { SegmentTable } from "features/segment";
import { HiPlusSm } from "react-icons/hi";
import { useMediaQuery } from 'react-responsive';
import MerinfoSegmentTable from "features/segment/my-segments/components/MerinfoSegmentTable";

const MyTargetGroups = ({ siteUrl }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const isMerinfoTheme = siteUrl === "merinfo";
  return (
    <Container className="py-5 h-100 ">
      <ModalCreateSegment show={show} setShow={setShow} />
      <Row className="row-cols-1 gap-2 mx-0 px-0">
        <Row className={`mx-0 px-0 justify-content-sm-between justify-content-center gap-2 align-items-baseline row-cols-auto ${isSmallScreen ? '' : ''}`} style={{ marginTop: isSmallScreen ?  0: '' }}>
          {isMerinfoTheme ?
            <h2>Mina Kontaktlistor</h2>
            :
            <h2>Mina Målgrupper</h2>
          }
          <ButtonMain
            onClick={handleShow}
            text={isMerinfoTheme ? "Ny Kontaktlista" : "Ny Målgrupp"}
            variant="success"
            reverse
            icon={<HiPlusSm />}
          />
        </Row>
        {isMerinfoTheme
          ?
          <MerinfoSegmentTable rows="10" tableSize="lg" filter />
          :

          <SegmentTable rows="10" tableSize="lg" filter />
        }
      </Row>
    </Container>
  );
};

export default MyTargetGroups;
