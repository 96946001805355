import { Row, Col } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";

const EditTargetURL = ({ activity }) => {
  return (
    <Row>
      <Col className="col-md-4 regular">
        <p>Måladress</p>
      </Col>
      <Col
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          whiteSpace: "nowrap",
        }}
      >
        <p style={{ color: "#545F61", textDecoration: "underline" }}>
          <a href={activity?.ad?.targetUrl} target="_blank">
            {activity?.ad?.targetUrl}
          </a>
        </p>
      </Col>
      <Col className="col-2 edit-col-icon">
          <AiFillLock color="rgb(212 212 212)" />
        </Col>
        {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {/* <Col className="col-2 edit-col-icon">
        <AiFillEdit />
      </Col> */}
    </Row>
  );
};

export default EditTargetURL;
