import "../style.scss";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import { Numeral } from "react-numeral";

const CampaignRadioBox = (props) => {
  return (
    <Container
      className={
        props.loading || props.disabled
          ? "campaign-radio-box disabled-radio-button"
          : props.check && props.current?.includes(props.option)
          ? "campaign-radio-box selected-radio-button"
          : props.current.value === props.value && !props.check
          ? "campaign-radio-box selected-radio-button"
          : "campaign-radio-box not-selected-radio-button"
      }
    >
      {props.current.value === props.value &&
      !props.loading &&
      !props.disabled ? (
        <Col className="done-check-icon">
          <MdCheckCircle size={23} />
        </Col>
      ) : props.check && props.current?.includes(props.value) ? (
        <Col className="done-check-icon">
          <MdCheckCircle size={23} />
        </Col>
      ) : (
        ""
      )}
      <Row className="flex-column justify-content-between g-2">
        <Col className="radio-box-icon">
          <Row>
            <Col className="col-2">{props.icon}</Col>
            <Col className="display-xs bold justify-content-end align-items-center d-flex">
              {props.loading ? (
                <>
                  <Col className="col-10">
                    <p className="text-sm regular" style={{ color: "#2f98b6" }}>
                      Laddar målgruppens maximala räckvidd, detta kan ta en
                      stund...
                    </p>
                  </Col>
                  <Col>
                    <Spinner />
                  </Col>
                </>
              ) : (
                props.data && (
                  <p className="regular">
                    Maximal räckvidd:{" "}
                    <span className="bold display-sm">
                      <Numeral value={props.data.segmentSize} format={"0,0"} />{" "}
                    </span>
                  </p>
                )
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <p className="radio-box-title text-lg bold">{props.title}</p>
        </Col>
        <Col>
          <p className="radio-box-subtext text-md regular">{props.subText}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CampaignRadioBox;
