import Agent from 'api/Agent';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap'

export default function EmailPreview({ad, formData, placeholder}) {
  const {data : mail} = Agent.EmailTemplate.GET.ID(ad?.emailTemplateID)
  const [preview, setPreview] = useState(false);  

  const {data : emailData} = Agent.EmailDomains.GET.ID((formData?.emailDomainID ?? ad?.emailDomainID ))
  const sender = ((emailData?.fromEmail || placeholder?.sender));
  const title = ((formData?.title ||ad?.title || placeholder?.title));
  const description = (formData?.description ||ad?.description || placeholder?.description);

  const truncate = (text, length) => text.length > length ? text?.substring(0,length) + "..." : text;

  useEffect(() => {
    if (mail?.html) {
      setPreview(true);
    } else {
      setPreview(false);
    }
  }, [mail?.html]);


  return (
    <div>
      
      <Row className="justify-content-between">
          <Col>
            <Card className="d-flex flex-row justify-content-start align-items-center p-2 my-2">
              <div className="d-flex justify-content-center align-items-center rounded-circle text-white bold text-center text-uppercase" style={{width:"3rem", height:"3rem", backgroundColor:"lightblue"}}>{sender?.substring(0,1)}</div>
              <Stack className="px-4">
                <span className="bold text-xl">{sender && sender}</span>
                <span className="bold text-md" style={{color:"#0F6CBD"}}>{title && truncate(title)}</span>
                <span className="semi-bold text-muted text-sm">{description && truncate(description)}</span>
              </Stack>

            </Card>
          </Col>
        </Row>

        <Row style={{ height: "72vh" }}>
          <Col>
          {preview && (
            <iframe
              title="Email Preview"
              className="px-2 my-2 rounded-2"
              srcDoc={mail?.html}
              style={{
                width: "100%",
                height: "100%",
                background: "#fff",
                border: "1px solid #eaecf0",
                boxShadow:
                  "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
              }}
            />
          )}
          </Col>
        </Row>
    </div>
  )
}

// Error: BadRequest - The resource submitted could not be validated. For field-specific details, see the 'errors' array.:    at IFG.Data.Services.MailChimpService.CreateAudienceAsync(JObject audience) in D:\a\1\s\IFG.Data.Services\MailChimpService.cs:line 92
//    at IFG.Data.API.Controllers.V1.CampaignsController.PutCampaignActivity(Guid id, Guid activityId, CampaignActivityViewModel model) in D:\a\1\s\IFG.Data.API\Controllers\V1\CampaignsController.cs:line 877