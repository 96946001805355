import { Button, ButtonGroup } from "react-bootstrap";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { CampaignActivityTypes, CampaignOptions, CampaignActivityIcons } from "features/campaign/campaign-details/options";

export default function StatisticsFilter({
  selected,
  setSelected ,
  activeChannels,
}) {
  // Convert active channels to lowercase for easier comparison
  const activeChannelsLowerCase = activeChannels?.map(channel => channel?.toLowerCase());

  // Get keys of CampaignActivityTypes
  const activeKeys = Object.keys(CampaignActivityTypes);

  // Filter active keys based on active channels
  const keyValuesOfActiveChannels = activeKeys?.filter(key => {
    const activityType = CampaignActivityTypes[key]?.toLowerCase();
    return activeChannelsLowerCase?.some(channel => channel?.includes(activityType));
  });

  // Create buttons for each activity type
  const activityButtons  = Object.keys(CampaignActivityTypes)?.filter(key => keyValuesOfActiveChannels.some(channel => channel === key)).map(key => {
    const optionName = CampaignActivityTypes[key];

    return (
      <Button
        key={key}
        variant="light"
        active={selected === key}
        onClick={() => setSelected(key)}
        className="d-flex flex-row px-4 gap-1 align-items-center justify-content-center"
      >
        {CampaignActivityIcons[key]}
        {optionName}
      </Button>
    );
  }).filter(Boolean); // Filter out falsy values (like undefined)

  return (
    <ButtonGroup
      aria-label="Basic example"
      style={{ background: "#fff" }}
      className="d-flex flex-row gap-1 flex-wrap p-1 rounded"
    >
      {/* Button for Overview */}
      <Button
        variant="light"
        active={selected === undefined}
        onClick={() => setSelected(undefined)}
      >
        Alla kanaler
      </Button>
      
      {/* Render activity type buttons */}
      {activityButtons?.map(option => option)}
    </ButtonGroup>
  );
}
