import { Row, Col, Table } from "react-bootstrap";
import { CardContainer } from "components/cards";

const AccountPersonInABoard = ({ account }) => {
  return (
    <Col className="col-12">
      <CardContainer
        children={
          <Row>
            <Col className="col-12 p-5">
              <h2 className="display-sm bold">
                {account ? "I styrelsen för:" : "Styrelsemedlemmar"}
              </h2>
            </Col>
            <Col className="col-12">
              <Table
                id="segmentTable"
                className="table-container"
                responsive
                hover
                size="lg"
              >
                <tr>
                  <th className="text-md bold" style={{ color: "#000" }}>
                    Namn
                  </th>
                  <th className="text-md bold" style={{ color: "#000" }}>
                    Position
                  </th>
                  <th className="text-md bold" style={{ color: "#000" }}>
                    I styrelsen sedan:
                  </th>
                </tr>

                <tbody className="text-md regular">
                  {account.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item?.positions?.map((pos) => pos.text + "  ")}</td>
                      <td>{item.date_start}</td>
                      {/* <td className="bold">{item.date_to}</td>
                      <td>{item.turnover}</td>
                      <td>{item.result_after_financial_items}</td>
                      <td>{item.number_of_employees}</td>
                      <td>{item.share_assets}</td>
                      <td>{item.quick_ratio.toFixed(2)}%</td>
                      <td>{item.risk_margin.toFixed(2)}%</td>
                      <td>{item.profit_margin.toFixed(2)}%</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        }
      />
    </Col>
  );
};

export default AccountPersonInABoard;
