import { OffersTable } from "features/offers";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { getCustomerSettings } from "features/settings/api";
import { useQuery } from "react-query";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { colorPallette } from "features/stats/assets/config";
import CreateOfferOffCanvas from "features/offers/create-offer/CreateOfferOffCanvas";
import { HiPlusSm } from "react-icons/hi";
import { useMediaQuery } from 'react-responsive';


const MyOffers = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  const { data: settings } = useQuery("customer", getCustomerSettings);
  const [comparisons, setComparisons] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-md-5">
          <Row className={`justify-content-between ${isSmallScreen ? 'flex-column' : ''}`}>
            <Col style={{marginTop: isSmallScreen ? 15 : ''}}>
              <h2>Mina {settings?.offerPluralName ? settings.offerPluralName : "Erbjudanden"}</h2>
            </Col>
            <Col className={`col-lg-4 col-md-5 d-flex ${isSmallScreen ? 'mt-4 justify-content-start' : 'justify-content-end'}`}>
              <Button
                variant="success"
                onClick={handleShow}
                className={`semi-bold ${isSmallScreen ? 'w-100' : ''}`}
              >
                <HiPlusSm size={25} /> Lägg till{" "}
                {settings?.offerName ? settings.offerName : "erbjudande"}
              </Button>
              <CreateOfferOffCanvas
                settings={settings}
                show={show}
                handleClose={handleClose}
              />
            </Col>
          </Row>
        </Col>
        <Col className={`col-12 ${comparisons?.length > 0 && "col-lg-8"}`}>
          <OffersTable
            settings={settings}
            setComparisons={setComparisons}
            comparisons={comparisons}
          />
        </Col>
        {comparisons?.length > 0 && (
          <Col
            className="col-lg-4 d-flex flex-column"
            style={{ height: "400px" }}
          >
            <Card
              border="light"
              tabIndex={0}
              className="card-container p-3 h-100"
              bg={"light"}
            >
              <h4 className=" text-md semi-bold text-capitalize">
                köpta biljetter
              </h4>
              <Col className={`h-100 px-1 py-4`}>
                <Bar
                  width={100}
                  height={50}
                  options={{
                    maintainAspectRatio: false,
                    backgroundColor: colorPallette[1],
                    scales: {
                      x: {
                        ticks: {
                          callback: function (val, index) {
                            const shotenBy = 7;
                            if (this.getLabelForValue(val)?.length > shotenBy)
                              return (
                                this.getLabelForValue(val)?.substring(
                                  0,
                                  shotenBy
                                ) + "..."
                              );

                            return this.getLabelForValue(val);
                          },
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: comparisons?.map((x) => x.title),
                    datasets: [
                      {
                        data: comparisons?.map((x) => x.purchaseCount),
                      },
                    ],
                  }}
                />
              </Col>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default MyOffers;
