import {
  Button,
  Col,
  Row,
  Stack,
} from "react-bootstrap";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import Agent from "api/Agent";
import DataExportCard from "./components/DataExportCard/DataExportCard";
import PrintPreview from "./components/PrintPreview";
import EditSettingsContainer from "./components/EditSettingsContainer";
import { BsFillPlayCircleFill, BsPauseCircleFill } from "react-icons/bs";
import DRAdStateIndicator from "./components/DRAdStateIndicator";
import { useEffect } from "react";

const DrCampaign = ({ campaign, activity }) => {
  //Get segment by id-----
  const { data: segment } = Agent.Segments.GET.ID(campaign.segmentID);
  const { refetch } = Agent.Segments.GET.ID(campaign.ID);
  useEffect(() => {refetch()},[])

  const parsedTemplate =
    activity?.ad?.template && JSON.parse(activity.ad.template);


  return (
    <Row className="gap-2 mx-auto pt-3" style={{ maxWidth: "1400px" }}>

      <Row className="ps-4">
        <Col className="col-12 col-lg-5">
          <Stack className="gap-3">


            <div className="d-flex flex-row justify-content-between">
              <Col>
                <h4 className="display-sm semi-bold">DR-kampanj</h4>
              </Col>

              <Col className=" d-flex align-items-center justify-content-end">
                {statusBadgeSetter(activity)}
              </Col>
            </div>


            <DRAdStateIndicator activity={activity}/>



            <EditSettingsContainer activity={activity} campaign={campaign} />

            <DataExportCard activityID={activity?.id} />

          </Stack>
        </Col>
        <Col className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
          <PrintPreview sources={Object.values(parsedTemplate)?.filter(x => (x?.print?.render?.url || x?.print?.render?.previewUrl))?.map(x => (x?.print?.render?.url || x?.print.render?.previewUrl))} />
        </Col>

      </Row>
    </Row>
  );
};

export default DrCampaign;

{/*
  segment card
<Card className={"card-container  "} bg="light">
          <Card.Body className={"p-4 "}>
            <Stack className="col gap-3 text-md semi-bold">
              <Row className=" row-cols-2">
                <p>Vald Målgrupp</p>

                <p>
                  {campaign?.autoSegment
                    ? "Automatisk"
                    : !campaign?.autoSegment && segment
                      ? segment.name
                      : "-"}
                </p>
              </Row>

              <Row className=" row-cols-2 ">
                <p>Typ av mottagare</p>
                <p>
                  {activity?.directMarketingTarget === 0
                    ? "Företagsadress"
                    : activity?.directMarketingTarget === 1
                      ? "Alla personer i styrelsen"
                      : activity?.directMarketingTarget === 2
                        ? "Beslutsfattare"
                        : ""}
                </p>
              </Row>
              <Row className=" row-cols-2">
                <p>Antal mottagare</p>

                <p>
                  {activity?.targetGroupMaxSize
                    ? activity.targetGroupMaxSize + "st"
                    : segment
                      ? `${segment.size} st`
                      : "-"}
                </p>
              </Row>
            </Stack>
          </Card.Body>
        </Card>


*/}

{/*
  progress card
<Card className={"card-container "} bg="light">
          <Card.Body className={"p-4 "}>
            <Stack gap={3}>
              <Row className="d-flex row-cols-auto justify-content-between gap-2 ">
                <div className=" d-flex justify-content-start">
                  <MdCreate className="align-self-center me-auto" />
                </div>

                <div className="d-flex justify-content-center text-center">
                  <span className="">Utskick skapat och schemalagt</span>
                </div>

                <div className="d-flex justify-content-center">
                  <FcCheckmark
                    size={18}
                    className="align-self-center "
                    strokeWidth={2}
                    style={{ color: "green" }}
                  />
                </div>
              </Row>

              <Row className="d-flex row-cols-auto justify-content-between gap-2 ">
                <div className=" d-flex justify-content-start">
                  <MdNotifications className="align-self-center" />
                </div>

                <div className="d-flex justify-content-center text-center">
                  <span>Tryckeri meddelat</span>
                </div>

                <div className="d-flex justify-content-center">
                  <FcCheckmark
                    size={18}
                    className="align-self-center "
                    strokeWidth={2}
                    style={{ color: "green" }}
                  />
                </div>
              </Row>

              <Row className="d-flex row-cols-auto justify-content-between gap-2 ">
                <div className=" d-flex justify-content-start">
                  <MdWorkHistory className="align-self-center" />
                </div>

                <div className="d-flex justify-content-center text-center">
                  <span>Avvaktar granskning.</span>
                </div>

                <div className="d-flex justify-content-center">
                  <FcCheckmark
                    size={18}
                    className="align-self-center "
                    strokeWidth={2}
                    style={{ color: "green" }}
                  />
                </div>
              </Row>
            </Stack>
          </Card.Body>
        </Card>

*/}
