import GoogleMapAreaSelect from 'components/maps/google-map-main/GoogleMapAreaSelect'
import React from 'react'
import { useState } from 'react';
import { useRef } from 'react'
import { Card } from 'react-bootstrap';

export default function FilterMap({mutate, filter, parsedFilters, segment }) {
  const mapRef = useRef(null);

  const drawable = parsedFilters.filter(x => x[filter?.id])?.flatMap(x => x[filter?.id])
  const uploadSelection= (selection) => {


    const filterIndex = parsedFilters?.findIndex((f) => f[filter.id]);

    // create a new filter with the selected value
    if (filterIndex === -1) {
      const updatedSegment = {
        ...segment,
        filters: JSON.stringify([...parsedFilters, { [filter.id]: selection}]),
      };
      mutate(updatedSegment);
    }

    // update the existing filter with the selected value
    else {
      const updatedFilter = { ...parsedFilters[filterIndex] };
      updatedFilter[filter.id] = selection;
      const newFilters = [...parsedFilters];
      newFilters[filterIndex] = updatedFilter;

      mutate({ ...segment, filters: JSON.stringify(newFilters) });
    }
  };

  const handleSetDrawable = (selection) => {
    uploadSelection(selection)
  }

  return (
    <Card>

      <GoogleMapAreaSelect mapRef={mapRef} drawable={drawable} setDrawable={handleSetDrawable}/>
    </Card>
  )
}
