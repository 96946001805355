
import {
  Table,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { TextInput } from "components/forms";
import { useState } from "react";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";

import Agent from "api/Agent";
import { MdCancel, MdCheckCircleOutline, MdSearch } from "react-icons/md";
import FilterDropdown from "./FilterDropdown";
import moment from "moment";
import EditFilterTypeModal from "./EditFilterTypeModal/EditFilterTypeModal";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { segmentTypes_sv } from "features/segment/segment-details/assets/config";
import { FILTERSOURCE_SV } from "api/endpoints/FilterTypes";
import { toast } from "react-toastify";

export const FILTERTYPES = { Value: 1, Number: 2, Date: 3, Text: 4, Boolean: 5, SingleValue: 6, Coordinates: 7 };
export const FILTERTYPES_SV = { [FILTERTYPES.Value]: "Multi select", [FILTERTYPES.Number]: "Nummer", [FILTERTYPES.Date]: "Datum", [FILTERTYPES.Text]: "Text", [FILTERTYPES.Boolean]: "Boolean", [FILTERTYPES.SingleValue]: "Enkel select", [FILTERTYPES.Coordinates]: "Koordinater" };

const FilterTypesTable = ({ filterCategory }) => {
  const [confirmDelete, setConfirmDelete] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showFilterType, setShowFilterType] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  //Paging
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ prop: "createdOn", desc: true });

  //Filter 
  const [filter, setFilter] = useState({});

  const { data: filterCategories, isLoading, error } = Agent.FilterTypes.GET.ALL(currentPage, 10, search, sort?.prop, sort?.desc, filterCategory?.id, filter)
  const deleteMutation = Agent.FilterTypes.DEL.FILTER_TYPE();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Table header columns 
  const handleTableHeaderClick = (property) => {
    if (!property || property?.includes(".")) return;
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };
  const isColumnSortable = (col) => !!col?.sortable;
  const isSorting = (col) => sort?.prop === col.property && col.property;


  const columns = [
    { label: "Namn", property: "name", sortable: true, id: "1" },
    { label: "Målgrupp", property: "segmentType", format: (type) => segmentTypes_sv[type]?.text ?? "Alla", sortable: true, id: "2" },
    { label: "Path", property: "path", sortable: true, id: "3" },
    { label: "isLocal", property: "isLocal", format: (isLocal) => isLocal ? "Ja" : "Nej", sortable: true, id: "4" },
    { label: "isExclude", property: "isExclude", format: (isExclude) => isExclude ? "Ja" : "Nej", sortable: true, id: "5" },
    { label: "Källa", property: "filterSource", format: (isExclude) => FILTERSOURCE_SV[isExclude], sortable: true, id: "6" },
    { label: "Skapad", property: "createdOn", format: (date) => moment(date).format("yyyy-MM-DD"), sortable: true, id: "7" },
    { label: "Typ", property: "type", format: (type) => FILTERTYPES_SV[type], sortable: true, id: "8" },
    { label: "Filter", property: "filters", format: (filters) => `${filters?.length}st`, id: "9" },
    { label: "", id: 10 },
  ];


  const handleDeleteCategory = (id) => {
    setConfirmDelete(confirmDelete.filter((x) => x !== id));

    toast.promise(
      deleteMutation.mutateAsync(id),
      { success: "Kategorityp borttagen", loading: "Tar bort kategorityp...", error: "Kunde inte ta bort kategorityp" })
  };

  const handleEditCategory = (id) => { setSelectedId(id); setShowEditModal(true) }



  return (<>

    <Row className="d-flex flex-row align-items-center justify-content-between p-0 mx-0">
      <div className="col-auto">
        <FilterDropdown setFilter={setFilter} filter={filter} />
      </div>
      <div style={{ width: "300px" }} >
        <TextInput
          type="search"
          placeholder="Sök"
          icon={<MdSearch />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>



    </Row>
    <CardContainer
      style={{ padding: "0" }}
      children={
        <>
          <Table>
            <thead className="text-sm semi-bold">
              <tr>
                {columns.map((col) => (
                  <th key={col.id} style={{ width: "150px", userSelect: "none" } + isColumnSortable(col) && { cursor: "pointer" }}
                    onClick={() =>
                      isColumnSortable(col) &&
                      handleTableHeaderClick(col.property)
                    }
                  > {isSorting(col) ? (
                    <p>
                      {col?.label}
                      {sort?.desc ? (
                        <FaSortDown className="ms-1" size={12} />
                      ) : (
                        <FaSortUp className="ms-1" size={12} />
                      )}
                    </p>
                  ) : (
                    <p>{col.label}
                      {isColumnSortable(col) && <FaSort className="ms-1" size={12} />}
                    </p>
                  )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-md regular">
              {filterCategories?.data?.map((r) =>
                <tr key={r.id}
                  onClick={() => { handleEditCategory(r.id) }}
                >
                  {columns?.filter(x => x?.property !== undefined).map(x => <td className="text-sm" key={x?.property}>{x?.format ? x?.format(r[x?.property]) : r[x?.property]}</td>)}
                  <td style={{ textAlign: "center" }} onClick={(e) => e.stopPropagation()}>

                    {!confirmDelete?.find(
                      (_delete) => _delete === r?.id
                    ) ? (
                      <button
                        className="delete-button"
                        onClick={() =>
                          setConfirmDelete([
                            ...confirmDelete,
                            r.id,
                          ])
                        }
                      >
                        <FiTrash2 size={18} />
                      </button>
                    ) : deleteMutation.isLoading
                      ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          <MdCancel
                            size={20}
                            className="edit-button me-2"
                            onClick={(e) => {
                              setConfirmDelete(
                                confirmDelete.filter(
                                  (x) => x !== r.id
                                )
                              );
                            }}
                          />
                          <MdCheckCircleOutline
                            size={20}
                            className="delete-button"
                            onClick={() => handleDeleteCategory(r.id)}
                          />
                        </>
                      )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {filterCategories?.paging !== undefined && !isLoading && (
            <PaginationBar
              paging={filterCategories?.paging}
              setCurrentPage={handlePageChange}
            />
          )}
        </>
      }
    />
    <EditFilterTypeModal show={showEditModal} setShow={setShowEditModal} selectedId={selectedId} setSelectedId={setSelectedId} filterCategory={filterCategory} />
  </>
  );
};

export default FilterTypesTable;
