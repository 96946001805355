import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "./stepper.scss"; // Make sure to create this CSS file

/**
 * Stepper component with integrated progress bar.
 *
 * @param {Object} props - Component props.
 * @param {number} props.activeStep - The currently active step index.
 * @param {function} props.onClick Function triggered when a step is clicked.
 *   Returns the clicked step index
 * @param {Number} props.steps - Number of steps.
 * @param {boolean} props.disableNonLinearClick - disable clicking on non-linear steps. ie. from 1 to 3
 * @param {boolean} props.disablePrev - Disable clicking on previous steps.
 * @param {boolean} props.disableContinue - Disable clicking on future steps.
 * @returns {JSX.Element} - Stepper component JSX element.
 */

const Stepper = ({
  activeStep = 0,
  onClick,
  steps,
  disableNonLinearClick = false,
  disablePrev = false,
  disableContinue = false,
  smallSteps = false
}) => {
  const stepsArray = Array[steps];
  const progress = (activeStep / (steps - 1)) * 100;

  const handleClick = (step) => {
    if (!onClick) return;
    const STEP_MAX = steps.length - 1;
    const STEP_MIN = 0;
    if (step > STEP_MAX && step < STEP_MIN) return;
    if (disablePrev && step < activeStep) return;
    if (disableContinue && step > activeStep) return;
    if (disableNonLinearClick) {
      const STEP_DIFFERENCE = step - activeStep;
      if (!(STEP_DIFFERENCE >= -1 && STEP_DIFFERENCE <= 1)) return;
    }
    return onClick(step);
  };

  function selectValuesGreaterThen(currentIndex) {
    return Array.from({ length: steps }, (_, index) => index)
      .filter((index) => index >= activeStep)
      .find((index) => index === currentIndex);
  }

  function selectValuesLessThen(currentIndex) {
    return Array.from({ length: steps }, (_, index) => index)
      .filter((index) => index <= activeStep)
      .find((index) => index === currentIndex);
  }

  function selectValuesNextTo(currentIndex) {
    return Array.from({ length: steps }, (_, index) => index)
      .filter((index) => index - activeStep >= -1 && index - activeStep <= 1)
      .find((index) => index === currentIndex);
  }
  const isInteractive = (index) => {
    if (disablePrev) return selectValuesGreaterThen(index);
    if (disableContinue) return selectValuesLessThen(index);
    if (disableNonLinearClick) return selectValuesNextTo(index);
    return index;
  };

  return (
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="stepper-container">
            <div class="step-indicators d-flex flex-row justify-content-between">
              {Array.from({ length: steps }, (_, index) => (
                <div class="step-bg" key={index}>
                  <div
                    onClick={() => handleClick(index)}
                    style={
                      isInteractive(index) !== undefined
                        ? { cursor: "pointer" }
                        : { userSelect: "none" }
                    }
                    class={` step-circle ${smallSteps ? "small " : ""} ${index <= activeStep ? "active" : ""}`}
                  >
                    {!smallSteps &&
                      < span > {index + 1}</span>
                    }
                  </div>
                </div>
              ))}
            </div>
            <div class="progress-bar w-100">
              <div class="progress" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

export default Stepper;
