import React, { useEffect, useRef, useState } from "react";
import { DndContext } from "@dnd-kit/core";
import { Droppable } from "./Droppable";
import { Draggable } from "./Draggable/Draggable";
import {
  restrictToParentElement,
  
} from "@dnd-kit/modifiers";


export default function DraggableContainer({items, setItems, contentRef, orientation, disabled}) {
  
  
  function handleDragEnd(ev) {
    const note = items.find((x) => x.id === ev.active.id);
    note.position.x += ev.delta.x;
    note.position.y += ev.delta.y;

    // note.rect = ev.find(x => x.id.includes("droppable"))?.data?.droppableContainer?.rect?.current
    const rect = ev?.collisions.find(x => x.id.includes("droppable"))?.data?.droppableContainer?.rect?.current
    note.fixed_size = [ rect.width * (+note.relative_size[0] * 0.01), rect.height *(+note.relative_size[1] * 0.01)]
    const _items = items.map((x) => {
      if (x.id === note.id) return note;
      return x;
    });

    setItems(_items);
  }

  const handleContainerResize = () => {
    const updateditems = items.map((item) => {
      if (!item?.fixed_size || !contentRef?.current?.clientWidth) return item;

      const IS_OUTSIDE_HORIZONTAL_BOUNDS = item.position.x + item?.fixed_size[0] > contentRef.current.clientWidth
      if (IS_OUTSIDE_HORIZONTAL_BOUNDS)
        item.position.x = contentRef?.current?.clientWidth - item?.fixed_size[0];

      if (item?.position?.x <= 0)
        item.position.x = 0

      const IS_OUTSIDE_VERTICAL_BOUNDS = item.position.y + item?.fixed_size[1] > contentRef.current.clientHeight
      if (IS_OUTSIDE_VERTICAL_BOUNDS)
        item.position.y = contentRef?.current?.clientHeight - item?.fixed_size[1];

      if (item?.position?.y <= 0)
        item.position.y = 0
   
      return item;
    });

    setItems(updateditems);
    
  };

  useEffect(() => {
    handleContainerResize(); // Initial sizing

    window.addEventListener('resize', handleContainerResize);

    return () => {
      window.removeEventListener('resize', handleContainerResize);
    };
  }, []);

  useEffect(() => {
    handleContainerResize();
  }, [orientation]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: "10px",
      }}
    >
      <DndContext
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
        
      >
        <Droppable>
          {items.filter(x => !x.disabled).map((item) => (
            <Draggable
              styles={{
                position: "absolute",
                left: `${item?.position?.x}px`,
                top: `${item?.position?.y}px`,
                zIndex: "15px"
              }}
              key={item?.id}
              id={item?.id}
              content={item?.content}
              size={item?.relative_size}
              disabled={disabled}
            />
          ))}
        </Droppable>
      </DndContext>
    </div>
  );
}
