import { Col, Row } from "react-bootstrap";

const StepTopRow = (props) => {
  return (
    <Col className="top-row col-12">
    <Row className=" justify-content-between align-items-center">
      <Col className="col-4 display-xs bold text-nowrap">{props.heading}</Col>
      {/* <Col className="col-2 d-flex justify-content-end text-end">...</Col> */}
    </Row>
  </Col>
  )
}

export default StepTopRow