import { CardContainer } from "components/cards";
import React from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import SegmentFilterElement from "../components/segmentFilter/SegmentFilterElement";
import { isArray } from "lodash";
import { useQuery } from "react-query";
import { getFilterCategories } from "features/filter/api";
import Agent from "api/Agent";
import { segmentType } from "../assets/config";
import { RxReset } from "react-icons/rx";
import { useState } from "react";
import { useEffect } from "react";

export default function SegmentSettings({ segment }) {
  const queryKey = ["filter", segment?.type];
  const { data, error, isLoading } = useQuery({
    queryKey: queryKey,
    queryFn: getFilterCategories,
    enabled: !isNaN(segment?.type),
    staleTime: 60 * 1000 * 5,
  });

  const filters =
    segment?.filters &&
    data &&
    JSON.parse(segment?.filters)
      ?.map((x) => Object.entries(x).find((filter) => filter))
      ?.sort((a, b) => {
        if (JSON.stringify(a)?.length < JSON.stringify(b)?.length) return -1;

        return 1;
      });
  const getFilterCategory = (id) =>
    data?.filter((x) => x?.filterTypes.find((y) => y?.id === id))[0];

  return (
    <Row className="gy-2 m-0 row-cols-1 ">
      <Col>
        <Row className="flex-column g-5">
          <Col>
            <h3 className="display-md semi-bold mb-4">Filterinställningar</h3>

            <Row className="g-3 pb-5">
              {filters
                ?.filter(
                  (filter) =>
                    filter?.find((filterProperties) =>
                      isArray(filterProperties)
                    )?.length > 0
                )
                ?.map((filter, index) => {
                  const filterCategory = getFilterCategory(
                    filter.find((filterCategory) => filterCategory)
                  );
                  return (
                    <>
                      <CardContainer
                        h100
                        padding
                        children={
                          <div className="h-100 p-3" style={{ overflowY: 'auto' }}>
                          <SegmentFilterElement
                            index={index}
                            key={index}
                            filter={filter}
                            filterCategory={filterCategory}
                          />
                         </div> 
                        }
                      />
                    </>
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
