import { CardContainer } from "components/cards";
import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Agent from "api/Agent";
import { RxReset } from "react-icons/rx";
import { useState } from "react";
import { useEffect } from "react";
import AiSegmentFilterElement from "../components/segmentFilter/AiSegmentFilterElement";
import { useQueryClient } from "react-query";

export default function AiSegmentSettings({ segment }) {
  const [restore, setRestore] = useState(false);
  const [segmentSize, setSegmentSize] = useState("");

  const {
    data: aiFilters,
    isLoading: aiFiltersIsLoading,
    refetch
  } = Agent.Segments.GET.AIFILTERCONFIG(segment?.id, restore);
  const loading = aiFiltersIsLoading;

  const { mutate: postAiSegmentPreview, isLoading: previewLoading, data: preview } =
    Agent.Segments.POST.AISEGMENTPREVIEW(segment?.id);

  useEffect(() => {
    postAiSegmentPreview(
      { id: segment?.id, newFilter: aiFilters },
    );
  }, []);
  // useEffect(() => {
  //   postAiSegmentPreview(
  //     { id: segment?.id, newFilter: aiFilters },
  //     {
  //       onSuccess: (res) => {
  //         setSegmentSize(res.data.count);
  //       },
  //     }
  //   );
  // }, [aiFilters]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (restore) {
  //       setLoading(true);
  //       await refetch(segment?.id, restore);
  //       setRestore(false);
  //       setLoading(false);
  //     }
  //   };
  //
  //   fetchData();
  // }, [restore]);

  if (aiFiltersIsLoading || loading) return <Spinner />;

  return (
    <Row className="gy-2 m-0 row-cols-1 ">
      <Col>
        <Row className="flex-column g-5">
          <Col>
            <h3 className="display-md semi-bold mb-4">Filterinställningar</h3>

            <Row className="g-3 pb-5">
              {Object.keys(aiFilters).map((key, index) => {
                const filter = aiFilters[key];
                return (
                  <Col className="col-12" key={index}>
                    <CardContainer
                      h100
                      padding
                      children={
                        <AiSegmentFilterElement
                          index={index}
                          aiFilters={aiFilters}
                          refetch={() => { }}
                          filter={filter}
                          segment={segment}
                          postAiSegmentPreview={postAiSegmentPreview}
                          setSegmentSize={setSegmentSize}
                        />
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <div className="filter-details-footer">
          <Col>
            {/* <Button */}
            {/*   variant="secondary" */}
            {/*   style={{ fontWeight: "bold" }} */}
            {/*   onClick={() => refetch()} */}
            {/* > */}
            {/*   Återställ målgrupp <RxReset size={20} /> */}
            {/* </Button> */}
          </Col>
          <Col className="d-flex justify-content-end">
            <h4 className="regular">
              Målgruppens storlek:{" "}
              <strong>
                {previewLoading || aiFiltersIsLoading || loading ? (
                  <Spinner />
                ) : (
                  `${preview?.data?.count ?? ""} ${segment.type === 0 ? "Företag" : "Personer"}`
                )}

              </strong>{" "}
            </h4>
          </Col>
        </div>
      </Col>
    </Row>
  );
}
