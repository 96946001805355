import { useSelector } from "react-redux";

const useCheckUser = () => {
  const session = useSelector((store) => store.session);
  const userRole = session?.role;
  if (userRole) {
    if (userRole?.includes("SuperAdmin")) return 3;
    if (userRole?.includes("Admin")) return 1;
    if (userRole === "User") return 2;
  }
  return 0;
};

export default useCheckUser;
