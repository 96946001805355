import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { FiPlusCircle } from "react-icons/fi";
import axios from "api/axios";
import { Col, Row, Spinner, Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useMutation, useQueryClient } from "react-query";
import Agent from "api/Agent";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./style.module.scss";
import { useMediaQuery } from "react-responsive";

const UpdateOfferOffCanvas = ({ settings, show, handleClose, id }) => {
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const queryClient = new useQueryClient();
  const lpImgRef = useRef();

  const { data: offer, isLoading: offerIsLoading } = Agent.Offers.GET.ID(id);

  //Offcanvas states-------------------
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [product, setProduct] = useState("");
  const [choosenTags, setChoosenTags] = useState([]);
  const [imgId, setImgId] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: tags, isLoading: tagsIsLoading } = Agent.Tags.GET.ALL();

  useEffect(() => {
    if (offer) {
      setTitle(offer.title);
      setStartDate(offer.startTime);
      setEndDate(offer.endTime);
      setSelectedImage(offer.imageUrl);
      setProduct(offer.productName);
      if (tags) {
        const updatedTags = offer.offerTags.map((tag) => {
          const matchingTag = tags.find((type) => type.id === tag.tagID);
          if (matchingTag) {
            return { ...tag, name: matchingTag.displayName };
          } else {
            return tag;
          }
        });
        setChoosenTags(updatedTags);
      }
    }
  }, [offer]);

  //Products
  const { data: products, isLoading: productsIsLoading } =
    Agent.Offers.GET.PRODUCTS();

  const [sortedProducts, setSortedProducts] = useState("");

  useEffect(() => {
    if (products) {
      const newData = products.map((obj) => ({
        label: obj,
        value: obj,
      }));

      setSortedProducts([...newData]);
    }
  }, [products]);

  //Tags
  const [typeOfTag, setTypeOfTag] = useState("");
  const [tagValue, setTagValue] = useState("");

  const [addTagsLoading, setAddTagsLoading] = useState(false);

  const [sortedTags, setSortedTags] = useState("");

  useEffect(() => {
    if (tags) {
      const newData = tags.map((obj) => ({
        ...obj,
        label: obj.displayName,
        value: obj.id,
      }));

      setSortedTags([...newData]);
    }
  }, [tags]);

  const {
    data: tagValues,
    refetch,
    isLoading: tagValuesIsLoading,
  } = Agent.Tags.GET.ID(typeOfTag.id);

  const [sortedTagValues, setSortedTagValues] = useState("");
  useEffect(() => {
    if (tagValues) {
      const newData = tagValues.values.map((obj) => ({
        label: obj,
        value: obj,
      }));

      setSortedTagValues([...newData]);
    }
  }, [tagValues]);

  useEffect(() => {
    refetch();
  }, [typeOfTag]);

  const addTagOnClick = () => {
    setAddTagsLoading(true);

    setChoosenTags([
      ...choosenTags,
      {
        name: typeOfTag.displayName,
        value: tagValue.value,
        tagId: typeOfTag.id,
      },
    ]);

    setTimeout(() => {
      setAddTagsLoading(false);
      setTagValue("");
    }, 1000);
  };

  const removeTag = (value) => {
    setChoosenTags(choosenTags.filter((tag) => tag.value !== value));
  };

  const { data: file } = Agent.Files.GET.ID(imgId);

  // Move the postImg initialization inside the component
  const { mutate: postImg } = useMutation(
    ({ file }) =>
      Promise.resolve(
        axios
          .post(`/files/v1`, file, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => data)
      ).catch((err) => Promise.reject({ error: err })),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("files");
      },
      onError: (error) => {
        console.warn(`Failed to POST file \n Error: `, error);
        toast(`[Fel] Det gick inte att skapa en ny fil`, {
          type: "error",
        });
      },
    }
  );

  const [savedImageFile, setSavedImageFile] = useState(null);

  const handleImageUpload = (e) => {
    e.preventDefault();
    setLoading(true);

    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("file", file);
    console.log({ formData });
    setSavedImageFile(formData);
    postImg(
      { file: formData },
      {
        onSuccess: (data) => {
          console.log("File-id:", data.id);
          setImgId(data.id);
        },
      }
    );
  };

  useEffect(() => {
    if (file) {
      setSelectedImage(file.url);
      setLoading(false);
    }
  }, [file]);

  const { mutate: putOffer, isLoading } = Agent.Offers.PUT();

  const [offerId, setOfferId] = useState(null);
  // add new customer button
  const onClickSubmit = (e) => {
    e.preventDefault();

    const modifiedTagsArray = choosenTags.map((obj) => {
      // Destructure the object to get only 'value' and 'tagId'
      const { value, tagId } = obj;
      return { value, tagId };
    });

    const newOffer = {
      ...offer,
      title: title,
      startTime: startDate,
      endTime: endDate,
      imageUrl: selectedImage,
      offerTags: choosenTags,
      productName: product,
    };
    putOffer(newOffer, {
      onSuccess: (res) => {
        setOfferId(res.id);
        handleClose();
      },
    });
  };

  if (offerIsLoading) return <Spinner />;

  if (offer)
    return (
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={styles.offcanvas}
      >
        <Offcanvas.Header
          closeButton
          className="flex-row-reverse justify-content-end align-items-baseline px-3"
          style={{ borderBottom: "1px lightgrey solid", background: "#eeeeee" }}
        >
          <Col></Col>
          <Offcanvas.Title className="px-3 display-sm semi-bold">
            Uppdatera {settings?.offerName ? settings.offerName : "erbjudande"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Row className="g-3 p-5">
              <Col className="col-12">
                <label className={styles.label} htmlFor="name">
                  {settings?.offerName ? settings.offerName : "Erbjudande"}
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="title"
                    className="p-3 text-lg"
                    placeholder={`Namn på ${
                      settings?.offerName ? settings.offerName : "erbjudandet"
                    }`}
                    aria-label="title"
                    type="text"
                    aria-describedby="basic-addon1"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    size={ifXsScreen ? "sm" : ""}
                  />
                </InputGroup>
              </Col>
              <Col className="col-12 mb-3">
                <label className={styles.label} htmlFor="name">
                  Välj produkt
                </label>
                <Row>
                  <Col className="col-12 mb-3">
                    {productsIsLoading ? (
                      <Spinner />
                    ) : (
                      <Select
                        onChange={(value) => setProduct(value.value)}
                        className="whiteBg"
                        classNamePrefix="select"
                        isLoading={productsIsLoading}
                        isSearchable={true}
                        placeholder="Välj typ av produkt"
                        name="color"
                        defaultValue={{ value: product, label: product }}
                        options={sortedProducts}
                      />
                    )}
                  </Col>
                  <label className={styles.label} htmlFor="name">
                    Taggar
                  </label>
                  <Col className="col-6">
                    {tagsIsLoading ? (
                      <Spinner />
                    ) : (
                      <Select
                        onChange={(value) => setTypeOfTag(value)}
                        className="whiteBg"
                        classNamePrefix="select"
                        isLoading={tagsIsLoading}
                        isSearchable={true}
                        placeholder="Välj taggtyp"
                        name="color"
                        options={sortedTags}
                      />
                    )}
                  </Col>
                  <Col className="col-6">
                    {tagValuesIsLoading ? (
                      <Spinner />
                    ) : (
                      <Select
                        onChange={(value) => setTagValue(value)}
                        className="whiteBg"
                        classNamePrefix="select"
                        isLoading={tagValuesIsLoading}
                        isSearchable={true}
                        name="color"
                        placeholder="Välj taggvärde"
                        isDisabled={typeOfTag === ""}
                        options={sortedTagValues}
                      />
                    )}
                  </Col>
                </Row>
                <Button
                  variant="success"
                  onClick={addTagOnClick}
                  className="w-100 my-3 py-3"
                  disabled={tagValue === ""}
                >
                  {addTagsLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      Lägg till tagg <FiPlusCircle />
                    </>
                  )}
                </Button>
                <hr />
                <Row>
                  <Col
                    className="text-md"
                    style={{
                      color: "rgb(52, 64, 84)",
                      maxWidth: "fit-content",
                    }}
                  >
                    Valda taggar:
                  </Col>
                  {choosenTags?.map((tag) => (
                    <Col style={{ maxWidth: "fit-content" }}>
                      <Badge
                        className="bg-warning d-flex text-center align-items-center"
                        style={{ border: "1px solid #bebebe" }}
                      >
                        {tag.name} - {tag.value}
                        <div
                          onClick={() => removeTag(tag.value)}
                          className={styles.removeofferbox}
                        >
                          x
                        </div>
                      </Badge>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col className="col-12 ">
                <Form.Label
                  htmlFor="image"
                  className={styles.label}
                  style={{ color: "#344054" }}
                >
                  Bild för erbjudandet
                </Form.Label>
                {loading && <Spinner />}
                <Form.Control
                  type="file"
                  className="py-3"
                  accept="image/png, image/jpeg"
                  onChange={(e) => handleImageUpload(e)}
                  name="campaignImg"
                  ref={lpImgRef}
                  required
                  size={ifXsScreen ? "sm" : ""}
                />

                <Form.Control.Feedback type="invalid">
                  Välj en bild till kampanjen.
                </Form.Control.Feedback>
              </Col>

              <Col className="col-12  pb-2 d-flex justify-content-center">
                {selectedImage != null && (
                  <img
                    alt="campaign preview"
                    className="mw-100"
                    style={{ maxHeight: "300px" }}
                    src={selectedImage}
                  />
                )}
                <Col>
                  <hr className={styles.line} />
                </Col>
              </Col>
              <Col>
                <label className={styles.label} htmlFor="name">
                  Startdatum
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="startDate"
                    aria-label="startDate"
                    className="py-3"
                    type="datetime-local"
                    aria-describedby="basic-addon2"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    size={ifXsScreen ? "sm" : ""}
                  />
                </InputGroup>
              </Col>
              <Col>
                <label className={styles.label} htmlFor="name">
                  Slutdatum
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="endDate"
                    className="py-3"
                    aria-label="endDate"
                    type="datetime-local"
                    aria-describedby="basic-addon3"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    size={ifXsScreen ? "sm" : ""}
                  />
                </InputGroup>
              </Col>
              <Col className="col-12">
                <hr className={styles.line} />
              </Col>

              <Col className="col-12 w-100 pt-4 d-flex">
                <Button
                  type="button"
                  variant="success"
                  onClick={onClickSubmit}
                  className="semi-bold display-xs w-100 py-3"
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    `Uppdatera ${
                      settings?.offerName ? settings.offerName : "erbjudandet"
                    }`
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    );
};

export default UpdateOfferOffCanvas;
