import React, { useEffect, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useState } from "react";
import DraggableContainer from "./components/DraggableContainer";
import QR from "./components/Draggable/Items/QR";
import Address from "./components/Draggable/Items/Address";

export default function DirectCampaignPreview({
  propertyName,
  activity,
  onLoad
}) {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const parsedTemplate =
    activity?.ad?.template && JSON.parse(activity.ad.template);


  const qrTemplate = parsedTemplate[propertyName]?.draggables?.find(draggable => draggable.id === [propertyName, "QR"].join("-"));
  const adressTemplate = parsedTemplate[propertyName]?.draggables?.find(draggable => draggable.id === [propertyName, "Address"].join("-"));
  const defaultItems = [
    {
      id: [propertyName, "QR"].join("-"),
      content: <QR content={<span className="bold text-black mx-auto opacity-1"> QR </span>} />,
      disabled: qrTemplate?.disabled && qrTemplate?.disabled,
      position: qrTemplate?.position ? qrTemplate.position : {
        x: 0,
        y: 0,
      },
      relative_size: ["15", "15"],
    },
    {
      id: [propertyName, "Address"].join("-"),
      content: <Address content={<span className="bold text-black mx-auto opacity-1"> Address </span>} />,
      disabled: adressTemplate?.disabled && adressTemplate.disabled,
      position: adressTemplate?.position ? adressTemplate.position : {
        x: 0,
        y: 0,
      },
      relative_size: ["30", "15"],
    },
  ]
  const [items, setItems] = useState(defaultItems);



  const QR_DISABLED = items.find((x) => x?.id.includes("QR"))?.disabled;
  const ADDRESS_DISABLED = items.find((x) =>
    x?.id.includes("Address")
  )?.disabled;




  const scaleContentToFit = () => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (container && content) {
      const LANDSCAPE_A4_ASPECT_RATIO = 210 / 297;
      const PORTRAIT_A4_ASPECT_RATIO = 297 / 210;

      const aspectRatio =
        !!parsedTemplate.adSize?.landscape || parsedTemplate.adSize?.landscape === undefined
          ? LANDSCAPE_A4_ASPECT_RATIO
          : PORTRAIT_A4_ASPECT_RATIO
      
      console.log(!parsedTemplate.adSize?.landscape)

      const containerWidth = container.clientWidth;
      const newContentWidth = containerWidth; const newContentHeight = newContentWidth * aspectRatio;

      content.style.width = newContentWidth + "px";
      content.style.height = newContentHeight + "px";

      updateDraggables()
    }
  };



  const updateDraggables = () => {
    const newContentWidth = contentRef?.current?.clientWidth;
    const newContentHeight = contentRef?.current?.clientHeight;

    const asd = defaultItems?.map((item) => {
      if (item?.id?.includes("QR")) {
        try {
          const relativePositionX =
            +qrTemplate?.position?.x / +qrTemplate?.parent_size[0];
          const relativePositionY =
            +qrTemplate?.position?.y / +qrTemplate?.parent_size[1];

          item.position.x = relativePositionX * newContentWidth;
          item.position.y = relativePositionY * newContentHeight;
        } catch (error) {
          throw error;
        }
      } else if (item?.id?.includes("Address")) {
        try {
          const relativePositionX2 =
            +adressTemplate?.position?.x / +adressTemplate?.parent_size[0];
          const relativePositionY2 =
            +adressTemplate?.position?.y / +adressTemplate?.parent_size[1];

          item.position.x = relativePositionX2 * newContentWidth;
          item.position.y = relativePositionY2 * newContentHeight;
        } catch (error) {
          throw error;
        }
      }

      return item;
    });
    setItems(asd)
  };



  useEffect(() => { scaleContentToFit() }, [activity])
  useEffect(() => { scaleContentToFit() }, [])
  useEffect(() => {
    scaleContentToFit();
    window.addEventListener("resize", scaleContentToFit);

    return () => {
      window.removeEventListener("resize", scaleContentToFit);
    };
  }, [contentRef?.current?.clientWidth]);


  return (
    <>
      <div className="d-flex justify-content-center h-100" style={{ position: "relative", width: "100%" }} ref={containerRef}>
        {!!parsedTemplate[propertyName] && (
          <div
            ref={contentRef}
            style={{ position: "relative", height: "100%", maxHeight:"100%" }}
          >
            <div
              style={{
                position: "relative",
                maxWidth: "100%",
                height: "100%",
                backgroundColor:"white"
              }}
            >
              <img
                crossOrigin="anonymous"
                alt="selection for upload"
                src={!!parsedTemplate[propertyName]?.previewUrl ? parsedTemplate[propertyName]?.previewUrl : parsedTemplate[propertyName]?.url}
                style={{ height: "100%", width: "100%" }}
                onLoad={onLoad}
              />
              <DraggableContainer
                items={items}
                setItems={setItems}
                contentRef={contentRef}
                orientation={parsedTemplate?.adSize?.landscape}
                disabled={true}
              />
            </div>


          </div>
        )}
      </div>



      {/* Choose QR-Code -------------------------------- */}
      {!!parsedTemplate[propertyName]?.targetUrl && !onLoad && (
        <Row className="row-cols-1">
          {!QR_DISABLED && (
            <Col className="my-3">
              <Form.Label
                htmlFor="qrlandingpage"
                className="p-0 text-muted semi-bold"
              >
                Landningssida för QR-kod
              </Form.Label>

              <InputGroup>
                <InputGroup.Text id="basic-addon5">https://</InputGroup.Text>
                <Form.Control

                  value={
                    parsedTemplate[propertyName]?.targetUrl
                  }
                  type="text"
                  disabled
                />

              </InputGroup>
            </Col>
          )}
        </Row>
      )}
    </>
  );
}
