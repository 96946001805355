import React from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const TableHeader = ({ columns, columnsSmall, sort, handleTableHeaderClick, typeSmall }) => {
  const renderHeaderColumns = (cols) => {
    return cols.map((col, index) => (
      <th
        key={index}
        style={{ width: "150px", userSelect: "none" }}
        onClick={() => handleTableHeaderClick(col?.sort)}
       role="button"
      >
        <p>
          {col?.header}
          {col?.sort&& renderSortIcon(col?.sort)}
        </p>
      </th>
    ));
  };

  const renderSortIcon = (property) => {
    if (sort?.prop === property) {
      return sort.desc ? <FaSortDown className="ms-1" size={12} /> : <FaSortUp className="ms-1" size={12} />;
    }
    return <FaSort className="ms-1" size={12} />;
  };

  return (
    <thead className="text-sm semi-bold">
      <tr>
        {!typeSmall ? renderHeaderColumns(columns) : renderHeaderColumns(columnsSmall)}
      </tr>
    </thead>
  );
};

export default TableHeader;

