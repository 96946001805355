import { useDraggable } from '@dnd-kit/core';
import { CSS } from "@dnd-kit/utilities";
import React from 'react'

export default function DraggableVariableTag({id, property}) {
  const {attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id,
    data: `[${property.name}]`
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    cursor: "grab",
    userSelect: "none",
    boxShadow: isDragging ? "0px 0px 8px rgba(0, 0, 0, 0.3)" : "none",
    padding: "4px 8px",
    borderRadius: 4,
    margin: "5px 5px",
    fontSize: 14,
    fontWeight: "bold",
    display: "inline-block",
    
    border: `2px solid ${isDragging ? "#2D6433" : "#2D6450"}`,
    color: isDragging ? " #2D6433" : " #2D6450",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {property.label}
    </div>
  );
}
