import "./style.scss";
import { Col, Button, Container,Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import ModalDateRangePicker from "components/modals/modal-date-range-picker/ModalDateRangePicker";
import VisualCardContainer from "./VisualCardContainer";
import StatisticsFilterModal from "features/stats/StatisticsFilterModal";


const Statistics2 = () => {
  // const navigate = useNavigate();

  // const onClickGoBack = () => {
  //   navigate("/home");
  // };

  return (
    <Container className="py-5 h-100">
      <Row>
        <Col className="d-flex justify-content-between align-items-center mb-4">
          <div className="h-100 float-start w-100 p-2">
            <h2 className="display-md semi-bold w-100 m-0">Statistik</h2>
            <span className="text-md w-100">
              Följ och se utvecklingen av era kampanjer och konverteringar. 
            </span>
          </div>
          <div className="d-flex">
            <Col className="p-2 d-flex">
              <ModalDateRangePicker />
            </Col>
            {/* <Col className="p-2">
              <StatisticsFilterModal />
            </Col> */}
          </div>
        </Col>
      </Row>
      <Col className="d-flex flex-column statistics-content-container">
        <VisualCardContainer />
      </Col>
    </Container>
  );
};

export default Statistics2;
