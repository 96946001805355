import { Segments } from "./endpoints/Segments";
import { Campaigns } from "./endpoints/Campaigns";
import { CampaignsActivity } from "./endpoints/CampaignsActivity";
import { Leads } from "./endpoints/Leads";
import { Offers } from "./endpoints/Offers";
import { Files } from "./endpoints/Files";
import { LandingPages } from "./endpoints/LandingPages";
import { EmailTemplate } from "./endpoints/EmailTemplate";
import { EmailDomains } from "./endpoints/EmailDomains";
import { Customers } from "./endpoints/Customers";
import { Users } from "./endpoints/Users";
import { Accounts } from "./endpoints/Accounts";
import { Jobs } from "./endpoints/Jobs";
import { Prompts } from "./endpoints/Prompts";
import { SignUps } from "./endpoints/SignUps";
import { OpenAI } from "./endpoints/OpenAi";
import { Filters } from "./endpoints/Filters";
import { FilterTypes } from "./endpoints/FilterTypes";
import { FilterCategories } from "./endpoints/FilterCategories";
import { FilterGroups } from "./endpoints/FilterGroups";
import { Authenticate } from "./endpoints/Authenticate";
import { Tags } from "./endpoints/Tags";
import { Packages } from "./endpoints/Packages";
import { DataExports } from "./endpoints/DataExports";
import { CustomLists } from "./endpoints/CustomLists";

const Agent = {
  Campaigns,
  Tags,
  Segments,
  CampaignsActivity,
  Leads,
  Offers,
  Files,
  LandingPages,
  EmailTemplate,
  EmailDomains,
  Customers,
  Accounts,
  Users,
  Jobs,
  Prompts,
  SignUps,
  OpenAI,
  Filters,
  FilterTypes,
  FilterCategories,
  FilterGroups,
  Authenticate,
  Packages,
  DataExports,
  CustomLists,
};
export default Agent;

export const criteria = async (input) => {
  if (!input) return Promise.reject("Input is required. Call rejected");
  return Promise.resolve(input);
};
