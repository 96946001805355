import { Button, Col, Form, Row } from "react-bootstrap";
import "react-range-slider-input/dist/style.css";
import DirectCampaignWizard from "./DirectCampaignWizard/DirectCampaignWizard";
import Agent from "api/Agent";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import { HiOutlineDocument } from "react-icons/hi2";
import { useState } from "react";
import style_module from "./style.module.css"

const settings = {
  orientation : {
    landscape : "Liggande",
    portrait : "Stående",
    }
}

const EditDRTemplate = ({ activity }) => {
  const parsedTemplate =
  activity?.ad?.template && JSON.parse(activity.ad.template);

  const [orientation, setOrientaion] = useState(!!parsedTemplate?.adsize?.landscape || parsedTemplate?.adsize?.landscape === undefined ? settings.orientation.landscape : settings.orientation.portrait);

  const { mutate: updateActivity, isLoading } = Agent.CampaignsActivity.PUT.ACTIVITY();
  
  const handleAdSize = (landscape, size = "A5") => {
    if (landscape) setOrientaion(settings.orientation.landscape)
    if (!landscape) setOrientaion(settings.orientation.portrait)
    const template = activity?.ad?.template
      ? JSON.parse(activity.ad.template)
      : {};
    template["adSize"] = { landscape: landscape && landscape, size: size };
    const updatedAd = { ...activity?.ad, template: JSON.stringify(template) };
    updateActivity({ ...activity, ad: updatedAd });
  };

  return (
    <>
      {/* Heading -------------------------------- */}
      <Row>
        <Col className="col-12">
          <Row className="justify-content-between">
            <Col>
              <h4 className="display-sm semi-bold">Skapa ditt reklamblad</h4>
            </Col>
            <Col className="col-2 d-flex align-items-center justify-content-end">
              {statusBadgeSetter(activity)}
            </Col>
          </Row>
        </Col>
      </Row>

      <Form>
        <Row className="mt-1 row-cols-1 g-5">
          {/* Choose Size/Orientation -------------------------------- */}
          <Col>
            <Form.Label
              htmlFor="targetadress"
              className="p-0 text-muted semi-bold"
            >
              Layout
            </Form.Label>
            <Col className="d-flex justify-content-start gap-3"> 
              <div role={"button"} className={(orientation?.includes(settings.orientation.portrait) ? style_module["btn-select"] : style_module.btn) + " px-4 py-3 d-flex flex-column align-items-center user-select-none"} onClick={() => handleAdSize(false)}>
                <HiOutlineDocument size={40} />
                <span className="bold text-sm">Stående</span>
              </div>

              <div role={"button"} className={(orientation?.includes(settings.orientation.landscape) ? style_module["btn-select"] : style_module.btn) + " px-4 py-3 d-flex flex-column align-items-center user-select-none"} onClick={() => handleAdSize(true)} >
                <HiOutlineDocument size={40}  style = {{transform: 'rotate(90deg)' }} /> 
                <span className="bold text-sm">Liggande</span>
              </div>
            </Col>
          </Col>
          <Col>
            <Row className="row-cols-1 row-cols-lg-2 gy-3 justify-content-start">
              {/* Choose Front Image -------------------------------- */}
              <Col className="d-flex flex-column justify-content-start">
                <Form.Label
                  htmlFor="targetadress"
                  className="p-0 text-muted semi-bold"
                >
                  Framsida
                </Form.Label>
                <DirectCampaignWizard
                 propertyName={"selectedImageFront"}
                 activity={activity}
                  isLoading={isLoading}
                  landscape={orientation === settings.orientation.landscape}
                />
              </Col>
              {/* Choose Back Image -------------------------------- */}
              <Col className="d-flex flex-column justify-content-start">
                <Form.Label
                  htmlFor="targetadress"
                  className="p-0 text-muted semi-bold"
                >
                  Baksida
                </Form.Label>

                <DirectCampaignWizard
                  propertyName={"selectedImageBack"}
                  activity={activity}
                  isLoading={isLoading}
                  landscape={orientation === settings.orientation.landscape}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditDRTemplate;
