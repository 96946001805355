import React from "react";
import { FaRegAddressCard } from "react-icons/fa";

export default function Address({ content }) {
  return (
    <>
      <div
        style={{
          zIndex: 10,
          width: "60%",
          height: "60%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{
          position: "absolute",
          zIndex: 2,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

        }}>
          {content && content}
        </div>


        <div
          style={{ width: "100%", height: "100%" }}
          className="d-flex flex-column justify-content-center align-content-center"
        >
          {!content ?
            <FaRegAddressCard
              style={{ width: "80%", height: "80%" }}
              className="mx-auto"
            /> : <></>}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#01ade3",
          borderRadius: "3px",
          opacity: 0.8,
        }}
      />


    </>
  );
}
