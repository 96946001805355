import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const CampaignsActivity = {
  GET: {
    PREVIEWS: (activityID) =>
      useQuery({
        queryFn: () =>
          criteria(activityID).then(() =>
            axios
              .get(`/campaigns/v1/${activityID}/previews`)
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "previews-" + activityID],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get campaignsActivity PREVIEWS, of acticity: '${activityID}' \n Error: `,
            err
          ),
      }),
    ADTEMPLATE: (activityID, adAccountId) => {
      return useQuery({
        queryFn: () =>
          criteria(adAccountId).then(() =>
            axios
              .get(
                `/campaigns/v1/${activityID}/adtemplates?adAccountId=${adAccountId}`
              )
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "adtemplate-" + activityID],
        staleTime: 1000 * 60 * 5,
        // cacheTime: 1000 * 60 * 10, // 10 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get AD TEMPLATE, of acticity: '${activityID}' \n Error: `,
            err
          ),
      });
    },
    AVAILABLEACCOUNTS: (activityId) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(activityId).then(() =>
              axios
                .get(`/campaigns/v1/${activityId}/adaccounts`)
                .then(({ data }) => data)
            )
          ).catch((e) => Promise.reject(e)),
        queryKey: ["adaccounts", activityId],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30,
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get ad accounts '${activityId}' \n Error: `,
            err
          ),
      });
    },
    AVAILABLEPAGES: (activityID, adAccountId) => {
      return useQuery({
        queryFn: () =>
          criteria(adAccountId).then(() =>
            axios
              .get(
                `/campaigns/v1/${activityID}/availablePages?adAccountId=${adAccountId}`
              )
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "metaPages" + activityID],
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get metapages, of acticity: '${activityID}' \n Error: `,
            err
          ),
      });
    },
    AVAILABLEADCAMPAIGNS: (activityID, adAccountId) => {
      return useQuery({
        queryFn: () =>
          criteria(adAccountId).then(() =>
            axios
              .get(
                `/campaigns/v1/${activityID}/adcampaigns?adAccountId=${adAccountId}`
              )
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "adCampaigns" + activityID],
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get adCampaigns, of acticity: '${activityID}' \n Error: `,
            err
          ),
      });
    },
    AVAILABLEADSETS: (activityID, adCampaignId, adAccountId) => {
      const queryParams = [
        adAccountId && `adAccountId=${adAccountId}`,
        adCampaignId && `adCampaignId=${adCampaignId}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
      return useQuery({
        queryFn: () =>
          criteria(adCampaignId).then(() =>
            axios
              .get(`/campaigns/v1/${activityID}/adcampaignadsets${queryString}`)
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "adSets" + activityID],
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get adSets, of acticity: '${activityID}' \n Error: `,
            err
          ),
      });
    },
    AVAILABLEIMAGES: (activityID, adAccountId) => {
      return useQuery({
        queryFn: () =>
          criteria(adAccountId).then(() =>
            axios
              .get(
                `/campaigns/v1/${activityID}/availableImages?adAccountId=${adAccountId}`
              )
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "metaAvailableImages" + activityID],
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get metapages, of acticity: '${activityID}' \n Error: `,
            err
          ),
      });
    },
    REPORT: (activityID) =>
      useQuery({
        queryFn: () =>
          criteria(activityID).then(() =>
            axios
              .get(`/campaigns/v1/${activityID}/report`)
              .then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", "report-" + activityID],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get campaignsActivity report, of acticity: '${activityID}' \n Error: `,
            err
          ),
      }),
    LEADS: (activityId, page, filter) => {
      const queryParams = [
        page && `page=${page}`,
        filter?.search && `search=${filter?.search}`,
        filter?.type !== undefined && `type=${filter?.type}`,
        filter?.isInCrm !== "" &&
        filter?.isInCrm !== undefined &&
        `isInCrm=${filter?.isInCrm}`,
        filter?.hasActivity !== "" &&
        filter?.hasActivity !== undefined &&
        `hasActivity=${filter?.hasActivity}`,
        filter?.score !== "" &&
        filter?.score !== undefined &&
        `score=${filter?.score}`,
        filter?.companyType && `companyType=${filter?.companyType}`,
        filter?.municipality && `municipality=${filter?.municipality}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios
            .get(`/campaigns/v1/${activityId}/leads${queryString}`)
            .then(({ data }) => data),
        queryKey: ["campaignLeads", activityId, queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET leads with query: ${queryString} \n Error: `,
            error
          );
          toast.error("[Fel] Det gick inte att hämta leads");
        },
      });
    },
    LEADS_EXPORT_MUTATION: () => {
      return useMutation({
        mutationFn: ({ activityId, filter }) => {
          const queryParams = [
            filter?.search && `search=${filter?.search}`,
            filter?.type !== undefined && `type=${filter?.type}`,
            filter?.isInCrm !== "" &&
            filter?.isInCrm !== undefined &&
            `isInCrm=${filter?.isInCrm}`,
            filter?.hasActivity !== "" &&
            filter?.hasActivity !== undefined &&
            `hasActivity=${filter?.hasActivity}`,
            filter?.score !== "" &&
            filter?.score !== undefined &&
            `score=${filter?.score}`,
            filter?.companyType && `companyType=${filter?.companyType}`,
            filter?.municipality && `municipality=${filter?.municipality}`,
          ]?.filter((x) => x);

          const queryString =
            queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

          return axios
            .get(`/campaigns/v1/${activityId}/leads/export${queryString}`)
            .then(({ data }) => data);
        },
      });
    },
    LEADS_OPTIONS: (activityId, propertyName, filter, enabled) => {
      const queryParams = [
        propertyName && `propertyName=${propertyName}`,
        filter?.search && `search=${filter?.search}`,
        filter?.type !== undefined && `type=${filter?.type}`,
        filter?.isInCrm !== "" &&
        filter?.isInCrm !== undefined &&
        `isInCrm=${filter?.isInCrm}`,
        filter?.hasActivity !== "" &&
        filter?.hasActivity !== undefined &&
        `hasActivity=${filter?.hasActivity}`,
        filter?.score !== "" &&
        filter?.score !== undefined &&
        `score=${filter?.score}`,
        filter?.companyType && `companyType=${filter?.companyType}`,
        filter?.municipality && `municipality=${filter?.municipality}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios
            .get(`/campaigns/v1/${activityId}/leads/options${queryString}`)
            .then(({ data }) => data),
        enabled: !!enabled,
        queryKey: ["campaignLeads", "campaignLeadsOptions", activityId, queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET leads with query: ${queryString} \n Error: `,
            error
          );
          toast.error("[Fel] Det gick inte att hämta leads");
        },
      });
    },

    CONVERSIONS: (activityID, page, limit = 10) => {
      const query = `/campaigns/v1/${activityID}/conversions?page=${page}&limit=${limit}`;
      return useQuery({
        queryFn: () =>
          criteria(activityID).then(() =>
            axios.get(query).then(({ data }) => data)
          ),
        queryKey: ["CampaignsActivity", query],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get campaignsActivity convesions, of acticity: '${activityID}' \n Error: `,
            err
          ),
      });
    },
    DATAEXPORTS: (page,
      limit,
      sortByProperty,
      desc,
      activityID) => {
      const queryParams = [
        page && `page=${page}`,
        limit != undefined && `limit=${limit}`,
        sortByProperty && `orderByProperty=${sortByProperty}`,
        desc && `desc=${true}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      const query = `/campaigns/v1/${activityID}/data/export${queryString}`;
      return useQuery({
        queryFn: () =>
          axios.get(query).then(({ data }) => data),
        enabled: !!activityID,
        queryKey: ["dataExport", query],
        retry: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: true,
      });
    },
  },
  PUT: {
    PREVIEWS: () => {
      const queryClient = useQueryClient();
      return useMutation(
        (activity) =>
          axios
            .put(`/campaigns/v1/${activity?.id}/previews`, activity)
            .then((x) => x)
            .catch((err) => Promise.reject({ error: err, id: activity?.id })),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["CampaignsActivity"]);
          },
          onError: ({ error, id }) => {
            console.warn(
              `Failed to PUT campaignsActivity PREVIEWS, of acticity '${id}' \n Error: `,
              error
            );
          },
        }
      );
    },
    ADMEDIA: () => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ activity, adMediaId, ad }) =>
          axios
            .put(
              `/campaigns/v1/${activity?.campaignID}/activity/${activity?.id}/admedia/${adMediaId}`,
              ad
            )
            .then((x) => Promise.resolve(activity))
            .catch((err) => Promise.reect({ error: err, id: activity?.id })),
        {
          onSuccess: (activity) => {
            queryClient.invalidateQueries(["MobilePreview"]);
            queryClient.invalidateQueries(["CampaignsActivity"]);
            queryClient.invalidateQueries(["campaign", activity?.campaignID]);
          },
          onError: ({ error, id }) => {
            console.warn(
              `Failed to PUT adMedia, of acticity '${id}' \n Error: `,
              error
            );
          },
        }
      );
    },
    AddUserToCampaign: () => {
      // const queryClient = useQueryClient();
      return useMutation(({ activity, email }) =>
        axios.put(
          `/campaigns/v1/${activity?.campaignID}/activity/${activity?.id}/adduser?email=${email}`
        )
      );
    },

    ACTIVITY: () => {
      const queryClient = useQueryClient();
      return useMutation(
        (activity) =>
          axios
            .put(
              `/campaigns/v1/${activity?.campaignID}/activity/${activity?.id}`,
              activity
            )
            .then((x) => Promise.resolve(activity))
            .catch((err) => Promise.reect({ error: err, id: activity?.id })),
        {
          onSuccess: (activity) => {
            queryClient.invalidateQueries(["CampaignsActivity"]);
            queryClient.invalidateQueries(["campaign", activity?.campaignID]);
            queryClient.invalidateQueries(["campaigns"]);
          },
          onError: ({ error, id }) => {
            console.warn(
              `Failed to PUT campaignsActivity PREVIEWS, of acticity '${id}' \n Error: `,
              error
            );
          },
        }
      );
    },
  },

  POST: {
    FILES: () => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ activity, formdata }) =>
          Promise.resolve(
            axios
              .post(`/campaigns/v1/${activity.id}/files`, formdata, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(({ data }) => data)
          ).catch((err) => Promise.reject({ error: err, id: activity.id })),
        {
          onSuccess: (data) => { },
          onError: ({ error, id }) => {
            console.warn(
              `Failed to POST image files for campaignActivity: '${id}' \n Error: `,
              error
            );
            toast("Filen gick inte att ladda upp", { type: "error" });
          },
        }
      );
    },
    DATAEXPORT: () => {
      const queryClient = useQueryClient();
      return useMutation({
        mutationKey: ["dataExport"],
        mutationFn: async (dataExport) => axios.post(`/campaigns/v1/${dataExport?.campaignActivityID}/data/export`, dataExport),


      },
        {
          onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ["dataExport"] })
          }
        }
      );
    },
    CreateDirectMarketingList: () => {
      const queryClient = useQueryClient();
      return useMutation(
        async (activityID) =>
          axios
            .post(`/campaigns/v1/${activityID}/createmarketinglist`,)
      );
    },

  },

  DEL: {
    FILE: () => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ activity, BLOB_URI }) =>
          axios
            .delete(`/campaigns/v1/${activity?.id}/files?blobUri=${BLOB_URI}`)
            .then(({ data }) =>
              Promise.resolve({ data: data, blob_uri: BLOB_URI }).catch((e) =>
                Promise.reject({ error: e, id: activity?.id })
              )
            ),
        {
          onSuccess: ({ data, blob_uri }) => {
            console.warn(`[Successfully] Deleted file`, blob_uri);
            toast("Fil borttagen", { type: "success" });
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to delete file ${id} \n Error:`, error);
            toast("Filen gick inte att ta bort från servern", {
              type: "warning",
            });
          },
        }
      );
    },
  },
};
