import { Row, Col } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";

const EditBudget = ({ activity }) => {
  return (
    <Row>
      <Col className="col-md-4 regular">
        <p>Budget</p>
      </Col>
      <Col>
        <p style={{ color: "#545F61" }}>{activity?.ad?.budget && `${activity?.ad?.budget} kr/dag`}</p>
      </Col>
      <Col className="col-2 edit-col-icon">
        <AiFillLock color="rgb(212 212 212)" />
      </Col>
      {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {/* <Col className="col-2 edit-col-icon">
        <AiFillEdit />
      </Col> */}
    </Row>
  );
};

export default EditBudget;
