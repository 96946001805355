import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';
import {
  defaultStaticRanges,
  defaultInputRanges
} from "react-date-range/dist/defaultRanges";
import { MdCalendarMonth, MdCalendarToday } from 'react-icons/md';
import styles from './style.module.css'
import moment from 'moment';


export default function ModalDateRangePicker({
  show = false,
  setShow = () => { },
  onClear = () => { },
  onSubmit = () => { } }) {

  const [value, setValue] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }]);

  const handleSubmit= () => {
    const selection = value?.find(x => x);
    const fromDate = moment(selection?.startDate)?.format("yyyy-MM-DD")
    const toDate = moment(selection?.endDate)?.format("yyyy-MM-DD")
    onSubmit({ fromDate, toDate })
    handleClose();
  }


  const handleClear= () => {
    setValue([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }])
    onClear();
    handleClose();
  }
  const handleClose = () => {
    setShow(false);

  };


  // Translations
  const svStaticRanges = defaultStaticRanges.map(translateRange({
    "Today": "Idag",
    "Yesterday": "Igår",
    "This Week": "Denna vecka",
    "Last Week": "Förra veckan",
    "This Month": "Denna månad",
    "Last Month": "Förra månaden",
  }));
  const svInputRanges = defaultInputRanges.map(translateRange({
    "days up to today": "dagar fram till idag",
    "days starting today": "dagar från idag"
  }));

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
  }

  return (<Modal contentClassName={styles.modal} show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Välj datum</Modal.Title>
    </Modal.Header>

    <Modal.Body className='p-0'>
      <DateRangePicker
        onChange={(item) => {
          setValue([item.selection]);

        }}
        showSelectionPreview={false}
        moveRangeOnFirstSelection={false}
        months={1}
        color={"#3ebee3"}
        rangeColors={["#3ebee3"]}
        dateDisplayFormat='d MMM yyyy'
        ranges={value}
        direction="horizontal"
        locale={sv}
        inputRanges={svInputRanges}
        staticRanges={[...svStaticRanges, ...createStaticRanges([
          {
            label: "Inom 4 månader",
            range: () => ({
              startDate: moment().subtract(4, "months").toDate(),
              endDate: new Date(),
            }),
          },
          {
            label: "Detta år",
            range: () => ({
              startDate: moment().startOf("year").toDate(),
              endDate: new Date(),
            }),
          },
          {
            label: "Förra året",
            range: () => ({
              startDate: moment().subtract(1, "years").startOf("year").toDate(),
              endDate: moment().subtract(1, "years").endOf("year").toDate(),
            }),
          },
        ])]}
      />

      <Modal.Footer className='d-flex justify-content-between '>
        <Button variant='danger' onClick={() => handleClear()}>Återställ</Button>
        <Button variant='success' onClick={() => handleSubmit()}>Välj</Button>


      </Modal.Footer>

    </Modal.Body>

  </Modal>

  );
}
