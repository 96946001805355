import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  name: "Namnlös Målgrupp",
  type: 0,
  segmentTypeName: "company",
  //areafilters
  Counties: [],
  Municipalities: [],
  PostalCodes: [],
  //companyfilters
  CompanyTypes: [],
  SniCodes: [],
  Remarks: [],
  VehicleTypes: [],
  Result: [0, 10000000],
  Vehicles: [0, 500],
  Turnover: [0, 10000000],
  Employees: [0, 1000],
  //peoplefilters
  Age: [25, 45],
  Gender: [],
  Marriage: [],
  HouseHoldSize: [2, 4],
});

export { setGlobalState, useGlobalState, getGlobalState };

// VehicleTypes: [],
//   Result: [0, 10000000],
//   Vehicles: [50, 250],
//   Turnover: [0, 10000000],
//   Employees: [50, 250],
//   //peoplefilters
//   Age: [25, 45],
//   Gender: [],
//   Marriage: [],
//   HouseHoldSize: [2, 4],

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   segmentName: "",
//   segmentFilter: {
//     Counties: [],
//     Municipalities: [],
//     PostalCodes: [],
//     //companyfilters
//     CompanyTypes: [],
//     SniCodes: [],
//     Remarks: [],
//     VehicleTypes: [],
//     Result: [0, 10000000],
//     Vehicles: [50, 250],
//     Turnover: [0, 10000000],
//     Employees: [50, 250],
//     //peoplefilters
//     Age: [25, 45],
//     Gender: [],
//     Marriage: [],
//     HouseHoldSize: [2, 4],
//   },
// };

// export const segmentSlice = createSlice({
//   name: "segment",
//   initialState,
//   reducers: {
//     setSegmentName: (state, action) => {
//       state.segmentName = action.payload;
//     },
//     setFilters: (state, action) => {
//       state.segmentFilter = [...state.segmentFilter, action.payload];
//     },
//   },
// });

// export const { setSegmentName, setFilters } = segmentSlice.actions;
