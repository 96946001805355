import { Col, Offcanvas, Row, Stack, Button, Spinner, ButtonGroup } from "react-bootstrap";
import { useState } from "react";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import styles from "./style.module.css";
import "./style.scss";
import Agent from "api/Agent";
import StatisticsContainer from "./StatisticsContainer";
import ModalDateRangePicker from "./modal-date-range-picker/ModalDateRangePicker";
import moment from "moment";


const CampaignOverallStatistics = ({ show, handleClose }) => {
  const [activityFilter, setActivityFilter] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [fromDate, setFromDate] = useState(undefined);
  const { data, isLoading, error, isError } = Agent.Campaigns.GET.STATISTICS(fromDate, toDate, activityFilter);
  const notFound = error?.request?.status === 404;

  //FOR SETTING THE OFFCANVAS BG TO BLUR WHEN LANDINGPAGEOFFCANVAS IS OPEN
  const [bgBlur, setBgBlur] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);


  const handleDateSubmit = (range) => {
    setFromDate(range?.fromDate)
    setToDate(range?.toDate)

  }
  const handleDateClear = () => {
    setFromDate(undefined)
    setToDate(undefined)

  }

  return (
    <Offcanvas
      className={styles.campaignDetailsOffCanvas}
      show={show}
      onHide={handleClose}
      placement="end"
      style={bgBlur ? { filter: "brightness(50%)" } : {}}
    >
      <>
        <div className={styles.campaignDetailsOffCanvas_Header}>
          <Offcanvas.Header
            className="flex-row-reverse justify-content-end align-items-baseline"
            closeButton
          >
            <Col className="d-flex justify-content-end mx-5">
              <ButtonGroup>
                <Button variant="success" onClick={() => setShowDateRange(true)}>
                  <MdCalendarMonth size={25} />
                </Button>
              </ButtonGroup>
            </Col>
            <Col className="d-flex justify-content-start ms-3 align-items-baseline">
              <h2 className="display-sm semibold">Kampanjstatistik</h2>
            </Col>
          </Offcanvas.Header>
        </div>

        <Offcanvas.Body className="px-5 py-3">
          {isLoading && <Spinner />}
          {!isLoading && !isError && (
            <StatisticsContainer campaignStatistic={Array(data.data)} setActivityFilter={setActivityFilter} activityFilter={activityFilter}/>
          )}
          {isError && notFound ? <>Det finns inga kampanjer mellan {fromDate} - {toDate}</>
            : isError && <>Ett oväntat fel uppstod. försök igen</>}
        </Offcanvas.Body>
      </>
      <ModalDateRangePicker show={showDateRange} setShow={setShowDateRange} onSubmit={handleDateSubmit} onClear={handleDateClear} />
    </Offcanvas>
  );
};

export default CampaignOverallStatistics;
