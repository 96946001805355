import {
  Row,
  Col,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { updateLead } from "features/leads/api";
import { useMutation, useQueryClient } from "react-query";
import { CardContainer } from "components/cards";
import { TextInput } from "components/forms";
import { ButtonMain } from "components/buttons";
import { useState } from "react";
import { MdFace } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

const ContactForm = ({ lead, leadId, person }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' })
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);

  const queryClient = new useQueryClient();
  const { mutate } = useMutation(
    (newContact) => updateLead({ id: leadId }, newContact),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("lead");
        queryClient.invalidateQueries("leads");
        setLoading(false);
        setToggleForm(false);
      },
    }
  );

  const onClickSubmit = (e) => {
    e.preventDefault();
    const updatedLead = {
      ...lead,
      email: email ? email : lead.email,
      firstName: firstName ? firstName : lead.firstName,
      lastName: lastName ? lastName : lead.lastName,
      phone: phone ? phone : lead.phone,
      description: description ? description : lead.description,
    };

    mutate(updatedLead);

    console.log({ updatedLead });
  };

  return (
    <CardContainer
      padding
      marginBottom
      children={
        <Row className="justify-content-between">
          <Col className="col-12">
            <h3 className="display-sm semi-bold">Kontaktperson</h3>
          </Col>
          <Col className="my-4 col-9">
            <Row>
              <Col className="col-2 d-flex justify-content-center">
                <MdFace size={60} color="#F7DDBD" />
              </Col>

              {person ? (
                <Col style={{ margin: "auto" }}>
                  <p className="text-md regular">
                    Namn:{" "}
                    <span className="semi-bold">
                      {" "}
                      {lead?.jObject.basic.data.name.given_name
                        ? lead?.jObject.basic.data.name.given_name
                        : lead?.jObject.basic.data.name.first_name}{" "}
                      {` ${lead?.jObject.basic.data.name.last_name}`}
                    </span>
                  </p>

                  {/* {lead.email && (
                    <p className="text-md regular">
                      E-post: <span className="semi-bold">{lead.email}</span>
                    </p>
                  )} */}
                </Col>
              ) : (
                <Col style={{ margin: "auto" }}>
                  {lead.firstName && lead.lastName && (
                    <p className="text-md regular">
                      Namn:{" "}
                      <span className="semi-bold">
                        {" "}
                        {lead.firstName} {lead.lastName}
                      </span>
                    </p>
                  )}
                  {lead.email && (
                    <p className="text-md regular">
                      E-post: <span className="semi-bold">{lead.email}</span>
                    </p>
                  )}
                </Col>
              )}
            </Row>
          </Col>
          {!toggleForm && !person && (
            <Row>
            <Col className="col-3 d-flex align-items-center">
              <ButtonMain
                text={loading ? <Spinner /> : "Uppdatera"}
                variant="success"
                onClick={() => setToggleForm(true)}
              />
            </Col>
            </Row>
           
          )}

          {toggleForm && !person && (
            <>
              <hr />
              <Col className="col-12">
                <Form>
                  <Row>
                    <Col className="col-12">
                      <TextInput
                        label="Email"
                        type="e-mail"
                        placeholder={lead ? lead.email : "namn@company.com"}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col className="col-6">
                      <TextInput
                        label="Förnamn"
                        placeholder={lead ? lead.firstName : "Förnamn"}
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Col>
                    <Col className="col-6">
                      <TextInput
                        label="Efternamn"
                        placeholder={lead ? lead.lastName : "Efternamn"}
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Col>
                    <Col className="col-12">
                      <Form.Label>Telefonnummer</Form.Label>
                      <InputGroup className="mb-3">
                        <DropdownButton
                          variant="outline-secondary"
                          title="SE"
                          id="input-group-dropdown-1"
                        >
                          <Dropdown.Item href="#">EN</Dropdown.Item>
                          <Dropdown.Item href="#">NO</Dropdown.Item>
                        </DropdownButton>

                        <Form.Control
                          type="number"
                          placeholder={lead ? lead.phone : "+46 70 0000 00"}
                          aria-label=""
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="col-12">
                      <TextInput
                        label="Info"
                        as="textarea"
                        rows={3}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                    <Col className="col-4">
                      <ButtonMain
                        text={loading ? <Spinner /> : "Uppdatera"}
                        variant="success"
                        onClick={(e) => onClickSubmit(e)}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          )}
        </Row>
      }
    />
  );
};

export default ContactForm;
