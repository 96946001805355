import { Form, Button, InputGroup } from "react-bootstrap";
import {  AiFillEyeInvisible, AiFillEye,} from "react-icons/ai";
import "./style.scss";
import { useState} from "react";

const TextInputEye = (props) => {

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form.Group className="mb-4 input-group-lg">
      {props.label && <Form.Label>{props.label}</Form.Label>}
    <InputGroup className="input-group-lg">
      <Form.Control
        as={props.as}
        rows={props.rows}
        style={{ color: "#667085" }}
        onChange={props.onChange}
        value={props.value}
        type={showPassword ? "text" : "password"}
        placeholder={props.placeholder}
      />
      <Button
        showPassword={props.showPassword}
        onClick={toggleShowPassword}
        style={{color:"#667085",background:"white", borderColor:"#d0d5dd", borderLeft:"none" }}
      >
       {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
      </Button>
      </InputGroup>
    </Form.Group>
  );
};

export default TextInputEye;
