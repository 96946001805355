import { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { forwardRef } from "react";
import Agent from "api/Agent";
import TargetGroupSize from "./form/TargetGroupSize";
import TargetTypeSelect from "./form/TargetTypeSelect";

const EditMetaAccount = forwardRef(
  (
    { activity, metaAdObject, setMetaAdObject, segment, segmentIsLoading },
    ref
  ) => {
    //Get adAccounts

    const { data: adAccounts, isLoading: accountsIsLoading } =
      Agent.CampaignsActivity.GET.AVAILABLEACCOUNTS(activity.id);

    //Get Meta pages
    const {
      data,
      refetch,
      isRefetching: pagesIsRefetching,
      isLoading: pagesIsLoading,
    } = Agent.CampaignsActivity.GET.AVAILABLEPAGES(
      activity?.id,
      metaAdObject.adAccountId
    );

    //Get Meta AdCampaigns
    const {
      data: adCampaigns,
      refetch: refetchAdCampaigns,
      isRefetching: adCampaignsIsRefetching,
      isLoading: adCampaignsIsLoading,
    } = Agent.CampaignsActivity.GET.AVAILABLEADCAMPAIGNS(
      activity?.id,
      metaAdObject.adAccountId
    );

    //Get Meta AdSets
    const {
      data: adSets,
      refetch: refetchAdSets,
      isRefetching: adSetsIsRefetching,
      isLoading: adSetsIsLoading,
    } = Agent.CampaignsActivity.GET.AVAILABLEADSETS(
      activity?.id,
      metaAdObject.metaCampaignId
    );

    const [validated, setValidated] = useState(false);

    useEffect(() => {
      if (metaAdObject.adAccountId) {
        refetch();
        refetchAdCampaigns();
      }
    }, [metaAdObject.adAccountId]);

    useEffect(() => {
      if (metaAdObject.metaCampaignId) {
        refetchAdSets();
      }
    }, [metaAdObject.metaCampaignId]);

    useEffect(() => {
      if (adAccounts && !activity?.ad?.adAccountId) {
        setMetaAdObject({
          ...metaAdObject,
          adAccountId: adAccounts[0].id,
        });
      }

      if (activity?.ad?.adAccountId) {
        setMetaAdObject({
          ...metaAdObject,
          adAccountId: activity.ad.adAccountId,
        });
      }
    }, [adAccounts]);

    useEffect(() => {
      if (data && !activity?.ad?.metaPageId) {
        setMetaAdObject({
          ...metaAdObject,
          metaPageId: data[0].id,
        });
      }

      if (activity?.ad?.metaPageId) {
        setMetaAdObject({
          ...metaAdObject,
          metaPageId: activity.ad.metaPageId,
        });
      }
    }, [data]);

    return (
      <Form ref={ref} noValidate validated={validated}>
        <Row className="mt-1 g-3">
          <Col className="col-12">
            <Form.Label
              htmlFor="campaignTemplate"
              className="p-0 text-md"
              style={{ color: "#344054" }}
            >
              Välj Metakonto
            </Form.Label>
            {accountsIsLoading ? (
              <Spinner />
            ) : (
              <Form.Select
                className="py-3 text-xl semi-bold"
                aria-label="Default select example"
                defaultValue={
                  metaAdObject?.adAccountId
                    ? metaAdObject.adAccountId
                    : adAccounts
                    ? adAccounts[0].id
                    : ""
                }
                onChange={(e) =>
                  setMetaAdObject({
                    ...metaAdObject,
                    adAccountId: Number(e.target.value),
                  })
                }
              >
                <option value={""}>Välj ett konto</option>
                {adAccounts?.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </Col>
          <Col className="col-12">
            <Form.Label
              htmlFor="campaignTemplate"
              className="p-0 text-md"
              style={{ color: "#344054" }}
            >
              Koppla till befintlig kampanj
            </Form.Label>

            <Form.Check
              className="py-1 big-switch"
              type="switch"
              defaultChecked={metaAdObject.skipAdCreative}
              onChange={() =>
                setMetaAdObject({
                  ...metaAdObject,
                  skipAdCreative: !metaAdObject.skipAdCreative,
                })
              }
            />
          </Col>
          {/* //SKIP ADCREATION------------------------------------------------------------------------------------------------------------------------------------------- */}
          {metaAdObject.skipAdCreative ? (
            <>
              <Col className="col-12">
                <Form.Label
                  htmlFor="campaignTemplate"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Välj Kampanj
                </Form.Label>
                {adCampaignsIsLoading || adCampaignsIsRefetching ? (
                  <Col className="col-12">
                    <Spinner />
                  </Col>
                ) : (
                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    aria-label="Default select example"
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        metaCampaignId: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Välj Metakampanj</option>
                    {adCampaigns?.map((page, index) => (
                      <option key={index} value={page?.id}>
                        {page?.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Col>

              <Col className="col-12">
                <Form.Label
                  htmlFor="campaignTemplate"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Välj AdSet
                </Form.Label>
                {adSetsIsLoading || adSetsIsRefetching ? (
                  <Col className="col-12">
                    <Spinner />
                  </Col>
                ) : (
                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    aria-label="Default select example"
                    disabled={!metaAdObject.metaCampaignId}
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        metaAdSetId: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Välj adset</option>
                    {adSets?.map((page, index) => (
                      <option key={index} value={page?.id}>
                        {page?.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Col>
              {segmentIsLoading ? (
                <Spinner />
              ) : (
                <>
                  <hr />
                  <TargetGroupSize
                    activity={activity}
                    metaAdObject={metaAdObject}
                    setMetaAdObject={setMetaAdObject}
                    segment={segment}
                  />
                  <TargetTypeSelect
                    metaAdObject={metaAdObject}
                    setMetaAdObject={setMetaAdObject}
                    segment={segment}
                  />
                </>
              )}

              {/* //------------------------------------------------------------------------------------------------------------------------------------------- */}
            </>
          ) : (
            <>
              <Col>
                <Form.Label
                  htmlFor="cta-text"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Välj Meta sida
                </Form.Label>
                {pagesIsLoading || pagesIsRefetching ? (
                  <Col className="col-12">
                    <Spinner />
                  </Col>
                ) : (
                  <Form.Select
                    className="py-3 text-xl semi-bold"
                    disabled={!metaAdObject.adAccountId}
                    id="meta-pages"
                    aria-label="Default select example"
                    defaultValue={
                      metaAdObject?.metaPageId
                        ? metaAdObject.metaPageId
                        : data
                        ? data[0].id
                        : ""
                    }
                    onChange={(e) =>
                      setMetaAdObject({
                        ...metaAdObject,
                        metaPageId: Number(e.target.value),
                      })
                    }
                    required
                  >
                    <option value={""}>Välj din facebook sida</option>
                    {data?.map((page, index) => (
                      <option key={index} value={page?.id}>
                        {page?.name}
                      </option>
                    ))}
                  </Form.Select>
                )}

                <Form.Control.Feedback type="invalid">
                  Ange en Meta sida.
                </Form.Control.Feedback>
              </Col>
            </>
          )}
        </Row>
      </Form>
    );
  }
);

export default EditMetaAccount;
