
import { Row, Col, Container } from "react-bootstrap";
import { ButtonMain } from "components/buttons";

import useCheckUser from "hooks/useCheckUser";
import { UsersTable } from "features/users/my-users/components";
import {NewUser} from "features/users/my-users/components"



const UsersAccounts = () => {


const checkUser = useCheckUser();


  return (
<Container className="py-5 h-100">
<Row className="gy-3">
  <Col className="col-12 mb-5">
    <Row className="justify-content-between">
      <Col>
        <h2>Mina Användare</h2>
      </Col>
    {/* {checkUser === 3 && (
      <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
      <NewUser />
      </Col>
      )} */}
    </Row>
  </Col>
  <Col className="col-12">
   <UsersTable/>
  </Col>
</Row>
</Container>
  );
};


export default UsersAccounts;
