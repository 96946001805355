import {
  MdRoom,
  MdStore,
  MdDirectionsCar,
  MdMoney,
  MdTune,
  MdCircle,
  MdAssignmentInd,
  MdFamilyRestroom,
  MdLocalPhone,
  MdShoppingCart,
  MdPersonSearch,
  MdFingerprint,
  MdAdsClick,
  MdHouse,
  MdOutlineLocalOffer,
  MdSportsHockey,
  MdTrendingUp
} from "react-icons/md";

export const iconMap = {
  MdRoom: MdRoom,
  MdStore: MdStore,
  MdDirectionsCar: MdDirectionsCar,
  MdMoney: MdMoney,
  MdTune: MdTune,
  MdAssignmentInd: MdAssignmentInd,
  MdFamilyRestroom: MdFamilyRestroom,
  MdLocalPhone: MdLocalPhone,
  MdShoppingCart: MdShoppingCart,
  MdPersonSearch: MdPersonSearch,
  MdFingerprint: MdFingerprint,
  MdAdsClick: MdAdsClick,
  MdHouse: MdHouse,
  MdOutlineLocalOffer: MdOutlineLocalOffer,
  MdSportsHockey: MdSportsHockey,
  MdTrendingUp: MdTrendingUp
};

export const setColor = (filter) => {
  if (filter[0].filterCategoryName === "Geography") {
    return "#96c97e";
  } else if (filter[0].filterCategoryName === "CompanyType") {
    return "#7EC9BB";
  } else if (filter[0].filterCategoryName === "Vehicle") {
    return "rgb(181, 126, 201)";
  } else if (filter[0].filterCategoryName === "Economy") {
    return "rgb(201 183 126)";
  }
};

// export const setIcon = (filter) => {
//   if (filter[0].filterCategoryName === "Geography") {
//     return <MdRoom size={33} />;
//   } else if (filter[0].filterCategoryName === "CompanyType") {
//     return <MdStore size={33} />;
//   } else if (filter[0].filterCategoryName === "Vehicle") {
//     return <MdDirectionsCar size={33} />;
//   } else if (filter[0].filterCategoryName === "Economy") {
//     return <MdMoney size={33} />;
//   }
// };

// RangeSlider Min/Max Values------------------------
export const valueMin = (filterType) => {
  return Number(filterType.filters[0].query) <
    Number(filterType.filters[1].query)
    ? Number(filterType.filters[0].query)
    : Number(filterType.filters[1].query);
};
export const valueMax = (filterType) => {
  return Number(filterType.filters[0].query) >
    Number(filterType.filters[1].query)
    ? Number(filterType.filters[0].query)
    : Number(filterType.filters[1].query);
};
// RangeSlider Marks-----------------------------------
export const marks = (filterType) => {
  return {
    start:
      Number(filterType.filters[0].query) < Number(filterType.filters[1].query)
        ? filterType.filters[0].name
        : filterType.filters[1].name,
    500: filterType.name === "Vehicles" ? filterType.filters[1].name : "",
    10000: filterType.name === "Employees" ? filterType.filters[0].name : "",
    100000000:
      Number(filterType.filters[0].query) > Number(filterType.filters[1].query)
        ? filterType.filters[0].name
        : filterType.filters[1].name,
  };
};
// RangeSlider Steps-----------------------------------
export const step = (valueMax) => {
  return valueMax >= 100000000 ? 100000 : valueMax === 10000 ? 100 : 10;
};

export const filterCategoryNames = (name) => {
  if (name === "Geography") {
    return "Geografi";
  } else if (name === "CompanyType") {
    return "Typ av Företag";
  } else if (name === "Vehicle") {
    return "Fordon";
  } else if (name === "Economy") {
    return "Ekonomi";
  } else {
    return "Filter Kategori";
  }
};

export const filterNamesSwe = (name) => {
  if (name === "Counties") {
    return "Län";
  } else if (name === "Municipalities") {
    return "Kommuner";
  } else if (name === "CompanyTypes") {
    return "Typ av Företag";
  } else if (name === "SniCodes") {
    return "Branscher";
  } else if (name === "Remarks") {
    return "Utan Anmärkningar";
  } else if (name === "VehicleTypes") {
    return "Typ av Fordon";
  } else if (name === "Result") {
    return "Resultat";
  } else if (name === "Vehicles") {
    return "Antal Fordon";
  } else if (name === "Turnover") {
    return "Omsättning";
  } else if (name === "Employees") {
    return "Antal Anställda";
  }
};

export const filterCategoryIcon = (filterCat) => {
  return filterCat === "Geography" ? (
    <MdRoom size={33} />
  ) : filterCat === "CompanyType" ? (
    <MdStore size={33} />
  ) : filterCat === "Vehicle" ? (
    <MdDirectionsCar size={33} />
  ) : filterCat === "Economy" ? (
    <MdMoney size={33} />
  ) : (
    ""
  );
};

export const filterCategoryIconColor = (filterCat) => {
  return filterCat === "Geography"
    ? "#96c97e"
    : filterCat === "CompanyType"
    ? "#7EC9BB"
    : filterCat === "Vehicle"
    ? "rgb(181, 126, 201)"
    : filterCat === "Economy"
    ? "rgb(201 183 126)"
    : "";
};
