import { useContext } from "react";
import { Col, Row } from "react-bootstrap"
import { SegmentDetailsContext } from "../../SegmentDetailsOffCanvas";
import styles from "./style.module.css";

const PreviewFilterBreadcrumNav = () => {
  const { previewState } = useContext(SegmentDetailsContext)
  const { size, filters } = previewState;

  return (
    <Row className="ps-4 justify-content-start row-cols-auto gap-1 align-content-strech" style={{ minHeight: "50px" }} >

      <Col className={"align-content-center " + styles.activeNavItem}>
        <span
          className={
            `text-center text-md semi-bold py-2` + styles.navItem
          }
        >
          Storlek: {size?.toLocaleString()}
        </span>
      </Col>

      {filters?.map(event => <div className="d-flex flex-row row-cols-auto gap-1 justify-content-around py-1 px-0 align-items-center">
        {/* BREADCRUM */}
        <span
          className={
            `
text-center text-md semi-bold py-2 px-1 ` + styles.color
          }
          style={{ userSelect: "none" }}
        >
          {"\>"}
        </span>
        {/* FILTER */}
        <Col className="d-flex flex-column gap-0">
          <span
            className={
              `
text-center text-md bold py-0 px-0 ` + styles.navItem
            }
          >
            {`${event?.field_label}`}
          </span>
          <span
            className={
              `
text-center text-md semi-bold py-0 px-0 ` + styles.navItem
            }
          >
            {` ${event?.click?.label}`}
          </span>
        </Col>
      </div>

      )

      }
    </Row >
  )
}

export default PreviewFilterBreadcrumNav
