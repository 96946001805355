import { useRef } from "react";
import { Button, ButtonGroup, Col, Row, Image } from "react-bootstrap";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { MdInfoOutline } from "react-icons/md";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

export default function MobilePreview({
  activity,
  adBannerPreview,
  selectedMedia,
  setSelectedMedia,
  channel,
  onAccountPage,
  adType,
}) {
  const media = {
    Facebook: "Facebook",
    Instagram: "Instagram",
  };

  const preview =
    adBannerPreview &&
    channel !== "LINKEDIN" &&
    adBannerPreview.find((mediaType) =>
      mediaType.format.includes(selectedMedia.toUpperCase())
    );

  const iframeRef = useRef(null);

  const styles = {
    mobileFrame: {
      position: "absolute",
      top: "50px",
      zIndex: 1,
      width: "380px",
      objectFit: "scale-down",
      pointerEvents: "none",
    },
    iframe: {
      width: "100%",
      height: "100%",
    },
    infoContainer: {
      width: "318px",
      height: "650px",
    },
  };

  return (
    <Row className="d-flex align-items-center justify-content-center">
      {/* BUTTONGROUP--------------------------------------------- */}
      <Col className="col-12 d-flex justify-content-around ">
        <ButtonGroup>
          {adBannerPreview &&
            channel !== "LINKEDIN" &&
            adBannerPreview.find((x) =>
              x.format.includes(media.Facebook.toUpperCase())
            ) && (
              <Button
                variant="light"
                style={{ marginRight: "4px" }}
                active={selectedMedia === media.Facebook}
                onClick={() => setSelectedMedia(media.Facebook)}
              >
                <FiFacebook className="me-1" color="#006198" />
                {media.Facebook}
              </Button>
            )}

          {adBannerPreview &&
            channel !== "LINKEDIN" &&
            adBannerPreview.find((x) =>
              x.format.includes(media.Instagram.toUpperCase())
            ) && (
              <Button
                variant="light"
                active={selectedMedia === media.Instagram}
                onClick={() => setSelectedMedia(media.Instagram)}
              >
                <FiInstagram className="me-1" color="#d62976" />
                {media.Instagram}
              </Button>
            )}
        </ButtonGroup>
      </Col>

      {/* PREVIEW--------------------------------------------- */}
      <Col className="col-12 d-flex justify-content-center mt-1">
        <DeviceFrameset
          device="iPhone 8"
          color="silver"
          width={318}
          height={568}
        >
          {(!adBannerPreview || adBannerPreview.length < 1) &&
            !preview &&
            !onAccountPage && (
              <Col
                className="add-filter-info-box text-center d-flex justify-content-center align-items-center"
                style={styles.infoContainer}
              >
                <Row className="flex-column g-3">
                  <Col>
                    <MdInfoOutline size={48} color="#979A97" />
                  </Col>
                  <Col>
                    {channel && channel === "LINKEDIN" ? (
                      <p className="text-md">
                        En förhandsvisning av din annons visas först i steg 4!
                      </p>
                    ) : (
                      <p className="text-md">
                        Fyll i formuläret till vänster för att se en
                        förhandsgranskning av din annons här
                      </p>
                    )}
                  </Col>
                </Row>
              </Col>
            )}

          {onAccountPage && (
            <Col
              className="add-filter-info-box text-center d-flex justify-content-center align-items-center"
              style={styles.infoContainer}
            >
              <Image
                height={180}
                src={
                  adType === "googleAds"
                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Google_Ads_logo.svg/1641px-Google_Ads_logo.svg.png"
                    : adType === "linkedin"
                    ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/2048px-LinkedIn_icon.svg.png"
                    : "https://1000logos.net/wp-content/uploads/2021/10/logo-Meta.png"
                }
              />
            </Col>
          )}

          {adBannerPreview && channel !== "LINKEDIN" && (
            <iframe
              ref={iframeRef}
              src={preview?.url ? preview.url : ""}
              scrolling="no"
              style={styles.iframe}
              title="Mobile View"
              seamless
            />
          )}
          {adBannerPreview &&
            adBannerPreview.length > 0 &&
            channel === "LINKEDIN" && (
              <iframe
                ref={iframeRef}
                src={
                  adBannerPreview[0]?.preview
                    ? adBannerPreview[0].preview.match(/src='([^']+)'/)?.[1]
                    : ""
                }
                scrolling="no"
                style={styles.iframe}
                title="Mobile View"
                seamless
              />
            )}
        </DeviceFrameset>
      </Col>
    </Row>
  );
}
