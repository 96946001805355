import axios from "api/axios";
import { endPoints } from "api/endPoints";

const USERS_URL = endPoints.users.USERS_URL;
const ADD_USERS_URL = "/api/users/v1/adduser";

export const getUsers = async () => {
  const response = await axios.get(USERS_URL);
  return response.data;
};

export const addUser = async (addNewUserObj) => {
  return await axios.post(ADD_USERS_URL, addNewUserObj);
};
