export function getNestedPropertyValue(obj, propertyPath) {
  const properties = propertyPath?.split('.');
  let value = obj;
  
  for (let i = 0; i < properties?.length; i++) {
    if (value && typeof value === 'object') {
      value = value[properties[i]];
    } else {
      value = undefined;
      break;
    }
  }
  
  return value;
}