import React, { useEffect, useRef } from "react";
import { Alert, Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { IoMdTrash } from "react-icons/io";
import DragAndDropFile from "features/campaign/campaign-details/components/DragAndDropFile/DragAndDropFile";
import { useState } from "react";
import { MdQrCode2 } from "react-icons/md";
import { TbAddressBookOff, TbQrcodeOff } from "react-icons/tb";
import DraggableContainer from "./components/DraggableContainer";
import { FaAddressCard } from "react-icons/fa";
import QR from "./components/Draggable/Items/QR";
import Address from "./components/Draggable/Items/Address";
import Agent from "api/Agent";
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from "react-redux";
import { setWizardCache } from "../../drSlice";
import { toast } from "react-toastify";
import { fitDraggablesFromCache, scaleContentToFit, loadCashedWizzard} from "./utils"

export default function DirectCampaignWizard({
  propertyName,
  activity,
  isLoading,
  landscape
}) {
  const cache = useSelector((state) => state.directAd.wizard.cache);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const { mutateAsync: postFiles, isLoading: isUploadingFile } = Agent.CampaignsActivity.POST.FILES();
  const { mutate: delFile } = Agent.CampaignsActivity.DEL.FILE();
  const { mutate: updateActivity } = Agent.CampaignsActivity.PUT.ACTIVITY();


  const parsedTemplate =
    activity?.ad?.template && JSON.parse(activity.ad.template);


  const qrTemplate = parsedTemplate && parsedTemplate[propertyName]?.draggables?.find(draggable => draggable.id === [propertyName, "QR"]?.join("-"));
  const adressTemplate = parsedTemplate && parsedTemplate[propertyName]?.draggables?.find(draggable => draggable.id === [propertyName, "Address"]?.join("-"));

  const [items, setItems] = useState([
    {
      id: [propertyName, "QR"].join("-"),
      content: <QR />,
      disabled: !!qrTemplate?.disabled,
      position: qrTemplate?.position ?? {
        x: 0,
        y: 0,
      },
      relative_size: ["15", "15"],
    },
    {
      id: [propertyName, "Address"].join("-"),
      content: <Address />,
      disabled: !!adressTemplate?.disabled,
      position: adressTemplate?.position ?? {
        x: 0,
        y: 0,
      },
      relative_size: ["30", "15"],
    },
  ]);
  const QR_DISABLED = items.find((x) => x.id.includes("QR"))?.disabled;
  const ADDRESS_DISABLED = items.find((x) =>
    x.id.includes("Address")
  )?.disabled;

  const isLandscape = parsedTemplate?.adSize?.landscape === true || landscape === true;
  const handleUploadImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    var formdata = new FormData();
    formdata.append("file", file);
    toast.promise(
      postFiles(
        { activity: activity, formdata: formdata },
        {
          onSuccess: (data) => {
            const template = activity?.ad?.template
              ? JSON.parse(activity.ad.template)
              : {};
            template[propertyName] = data;
            const updatedAd = {
              ...activity?.ad,
              template: JSON.stringify(template),
            };
            updateActivity({ ...activity, ad: updatedAd });
          },
        }
      )
      ,
      {
        pending: 'Laddar upp bild',
        success: 'Bild uppladdad',
        error: 'Ett fel uppstod. försök igen'
      }
    )

    return false;
  };



  const handleRemoveImage = () => {
    const template = activity?.ad?.template
      ? JSON.parse(activity.ad.template)
      : {};

    delFile({ activity: activity, BLOB_URI: template[propertyName]?.url }, {
      onSettled: () => {
        template[propertyName] = null;
        const updatedAd = {
          ...activity?.ad,
          template: JSON.stringify(template),
        };
        updateActivity({ ...activity, ad: updatedAd });
      },
    });

  };


  useEffect(() => {
    scaleContentToFit(containerRef, contentRef, isLandscape);
    window.addEventListener("resize", scaleContentToFit(containerRef, contentRef, isLandscape));
    return () => {

      window.removeEventListener("resize", scaleContentToFit(containerRef, contentRef, isLandscape));
    };
  }, []);

  useEffect(() => {
    scaleContentToFit(containerRef, contentRef, isLandscape);
    handleDraggableCache();
  }, [items, parsedTemplate?.adSize?.landscape, landscape]);

  const handleDraggableStates = (id) => {
    const cloneItems = [...items];
    const objIndex = cloneItems.findIndex((obj) => obj.id.includes(id));
    cloneItems[objIndex].disabled = !cloneItems[objIndex].disabled;
    setItems(cloneItems);
  };
  const handleDraggableCache = debounce(() => {
    draggableCache();
  }, 100)
  const draggableCache = () => {
    const template = cache
      ? JSON.parse(cache)
      : {};

    if (!template[propertyName]) template[propertyName] = {}

    template[propertyName].draggables = items?.map(item => { return { id: item?.id, position: item?.position, size: item?.fixed_size, disabled: item?.disabled, parent_size: [contentRef?.current?.clientWidth, contentRef?.current?.clientHeight] } });

    dispatch(setWizardCache(JSON.stringify(template)))

  }

  const handleTargetUrl = debounce((input) => {
    uploadTargetUrl(input)
  }, 100);

  const uploadTargetUrl = (input) => {
    const template = cache
      ? JSON.parse(cache)
      : {};

    if (!template[propertyName]) template[propertyName] = {}

    template[propertyName].targetUrl = input;
    dispatch(setWizardCache(JSON.stringify(template)))
  }

  return (
    <>
      {(parsedTemplate && (parsedTemplate[propertyName]?.previewUrl || parsedTemplate[propertyName]?.url) && !isLoading) ? (
        <div style={{
          position: "relative", padding: "5px 60px",
          backgroundColor: "lightgray",
          borderRadius: "5px",
          border: "3px gray solid"
        }}>
          <div ref={containerRef}>
            <div
              ref={contentRef}
              style={{ position: "relative", height: "100%", maxHeight: "100%" }}
            >
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  height: "100%",
                  backgroundColor: "white"


                }}
              >
                <img
                  alt="selection for upload"
                  src={!!parsedTemplate[propertyName]?.previewUrl ? parsedTemplate[propertyName]?.previewUrl : parsedTemplate[propertyName]?.url}
                  style={{ height: "100%", width: "100%" }}
                />
                <DraggableContainer
                  items={items}
                  setItems={setItems}
                  contentRef={contentRef}
                  orientation={parsedTemplate?.adSize?.landscape}
                />


              </div>

            </div>
          </div>

          <Button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 9,
            }}
            variant="danger"
            className="semi-bold rounded-end p-2"
            onClick={() => handleRemoveImage()}
          >
            <IoMdTrash size={30} />
          </Button>

          <Button
            style={{
              position: "absolute",
              top: "130px",
              right: "10px",
              zIndex: 9,
            }}
            variant="primary"
            className="semi-bold rounded-end p-2"
            onClick={() => handleDraggableStates("Address")}
          >
            {ADDRESS_DISABLED ? (
              <FaAddressCard size={30} />
            ) : (
              <TbAddressBookOff size={30} />
            )}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "70px",
              right: "10px",
              zIndex: 9,
            }}
            variant="primary"
            className="semi-bold rounded-end p-2"
            onClick={() => handleDraggableStates("QR")}
          >
            {QR_DISABLED ? (
              <MdQrCode2 size={30} />
            ) : (
              <TbQrcodeOff size={30} />
            )}
          </Button>
        </div>)
        : (
          <DragAndDropFile handleImageUpload={handleUploadImage} loading={isUploadingFile || isLoading} />
        )}

      {/* Choose QR-Code -------------------------------- */}
      {parsedTemplate && parsedTemplate[propertyName] && (
        <Row className="row-cols-1 px-2">
          <Col className="my-3">
            <Form.Label
              htmlFor="qrlandingpage"
              className="p-0 text-muted semi-bold"
            >
              Ange en landningssida för QR-kod
            </Form.Label>

            <InputGroup>
              <InputGroup.Text id="basic-addon5">https://</InputGroup.Text>
              <Form.Control
                disabled={QR_DISABLED}
                placeholder={`www.dinsida.se/qr-kod-leder-hit`}
                onChange={(e) => {
                  handleTargetUrl(e.target.value)
                }}
                defaultValue={
                  activity?.ad?.targetUrl ? activity.ad.targetUrl : ""
                }
                type="text"
                name="qrlandingpage"
                id="qrlandingpage"
                required
                aria-describedby="basic-addon5"
              />
              <Form.Control.Feedback type="invalid">
                Ange en måladress.
              </Form.Control.Feedback>
            </InputGroup>


          </Col>
          {QR_DISABLED &&
            <Alert variant="info" className="text-sm semi-bold ">Aktivera och placera din QR kod först innan du anger en landningsida</Alert>
          }
        </Row>
      )}
    </>
  );
}
