import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Campaigns = {
  GET: {
    ALL: (
      page,
      limit,
      search,
      sortByProperty,
      desc,
      activityFilter,
      statusFilter,
      customerID,
      includesSegmentID
    ) => {
      const queryParams = [
        page && `page=${page}`,
        limit != undefined && `limit=${limit}`,
        search && search?.trim().length > 0 && `searchTerm=${search}`,
        sortByProperty && `orderByProperty=${sortByProperty}`,
        activityFilter && `activityFilter=${activityFilter}`,
        statusFilter && `statusFilter=${statusFilter}`,
        desc && `desc=${true}`,
        customerID && `customerID=${customerID}`,
        includesSegmentID && `includesSegmentID=${includesSegmentID}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/campaigns/v1/${queryString}`).then(({ data }) => data)
          ).catch((e) => Promise.reject(e)),
        queryKey: ["campaigns", queryString],
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all campaigns \n Error: `, err),
      });
    },
    ACTIVITIES: (
      page,
      limit,
      sortByProperty,
      desc,
      customerID,
      activityState,
      activityType
    ) => {
      const queryParams = [
        page && `page=${page}`,
        limit != undefined && `limit=${limit}`,
        sortByProperty && `orderByProperty=${sortByProperty}`,
        customerID && `customerID=${customerID}`,
        activityState && `activityState=${activityState}`,
        desc && `desc=${true}`,
        activityType && `activityType=${activityType}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/campaigns/v1/activities/${queryString}`).then(({ data }) => data)
          ).catch((e) => Promise.reject(e)),
        queryKey: ["campaigns", "activities" + queryString],
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all activities\n Error: `, err),
      });
    },

    STATISTICS: (fromDate, toDate, activityFilter) => {
      const queryParams = [
        fromDate && `fromDate=${fromDate}`,
        toDate && `toDate=${toDate}`,
        activityFilter && `activityFilter=${activityFilter}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios
            .get(`/campaigns/v1/statistics${queryString}`)
            .then((data) => data),
        queryKey: ["campaigns", "statistics", "statistics" + queryString],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
      });
    },
    COUNT: () =>
      useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/campaigns/v1/count`).then((data) => data)
          ).catch((e) => Promise.reject(e)),
        queryKey: ["campaigns", "count"],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get campaigns count \n Error: `, err),
      }),
    SEGMENTS: (campaignId) => {
      const query = `/campaigns/v1/${campaignId}/segments`;
      return useQuery({
        queryFn: async () => {
          const res = await axios.get(query);
          return res.data
        },
        enabled: !!campaignId,
        queryKey: ["campaign", campaignId, query],
        retry: false,
      })
    },
    DRDownloadURI: (id) =>
      useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(id).then(() =>
              axios
                .get(`/campaigns/v1/${id}/drdownloaduri`)
                .then(({ data }) => data)
            )
          ).catch((e) => Promise.reject(e)),
        queryKey: ["campaigns", "DRDownloadURI"],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get campaigns DRDownloadURI of '${id}' \n Error: `,
            err
          ),
      }),
    TARGETINGOPTIONS: (id) =>
      useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(id).then(() =>
              axios
                .get(`/campaigns/v1/${id}/targetingoptions`)
                .then(({ data }) => data)
            )
          ).catch((e) => Promise.reject(e)),
        queryKey: ["campaigns", "targetingoptions"],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) =>
          console.warn(
            `Failed to get campaigns targetingoptions of '${id}' \n Error: `,
            err
          ),
      }),
    ID: (id) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(id).then(() =>
              axios.get(`/campaigns/v1/${id}`).then(({ data }) => data)
            )
          ).catch((e) => Promise.reject(e)),
        queryKey: ["campaign", id],
        staleTime: 1000 * 60 * 1,
        refetchInterval: 1000 * 60 * 5,
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get campaign '${id}' \n Error: `, err),
      });
    },
    AUTOSEGMENTPREVIEW: (id, filterSize, segmentType) => {
      const queryParams = [
        filterSize && `filterSize=${filterSize}`,
        segmentType !== null && `segmentType=${segmentType}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () => axios.get(`/campaigns/v1/${id}/autosegmentpreview${queryString}`).then(({ data }) => data),
        refetchOnWindowFocus: (query) => query.state.status === "error",
        refetchOnMount: (query) => query.state.status === "error",
        staleTime: 1000 * 60 * 4,
        queryKey: ["campaignsegmentpreview", id + queryString],
        enabled: !!id,
        retry: false,
        onError: (err) => console.warn(`Failed to get campaign '${id}' \n Error: `, err),
      });
    },
  },
  PUT: {
    CAMPAIGN: () => {
      const queryClient = useQueryClient();
      return useMutation(
        (campaign) =>
          Promise.resolve(
            axios
              .put(`/campaigns/v1/${campaign?.id}`, campaign)
              .then(() => campaign?.id)
          ).catch((e) => Promise.reject({ error: e, id: campaign?.id })),
        {
          onSuccess: (id) => {
            queryClient.invalidateQueries("campaigns");
            queryClient.invalidateQueries(["campaign", id]);
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to PUT campaign ${id} \n Error: `, error);
          },
        }
      );
    },
  },
  POST: {
    CAMPAIGN: () => {
      const queryClient = useQueryClient();
      return useMutation((campaign) => axios.post(`/campaigns/v1/`, campaign), {
        onSuccess: () => {
          queryClient.invalidateQueries("campaigns");
        },
        onError: ({ error, id }) => {
          console.warn(`Failed to POST new campaign \n Error: `, error);
          toast("[Fel] Det gick inte att skapa en ny kampanj ", {
            type: "error",
          });
        },
      });
    },

    SEGMENT: () => {
      const queryClient = useQueryClient();
      return useMutation(async ({ campaignId, segmentId }) => {
        await axios.post(`/campaigns/v1/${campaignId}/segments`, { campaignID: campaignId, segmentID: segmentId })
        return campaignId;

      }, {
        onSuccess: (campaignId) => {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey?.includes(campaignId)
          });
        },
      });
    },
    AUTOSEGMENT: (id) => {
      const queryClient = useQueryClient();
      return useMutation(
        ({ id, filterSize, segmentType }) => {
          const queryParams = [
            filterSize && `filterSize=${filterSize}`,
            segmentType !== undefined && `segmentType=${segmentType}`,
          ]?.filter((x) => x);

          const queryString =
            queryParams.length > 0 ? `?${queryParams.join("&")}` : "";


          return axios.post(`/campaigns/v1/${id}/autosegment${queryString}`)
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("campaignautosegment");
          },
          onError: ({ error }) => {
            console.warn(`Failed to POST new campaign \n Error: `, error);
            toast("[Fel] Det gick inte att skapa en ny kampanj ", {
              type: "error",
            });
          },
        }
      );
    },
    SENDMAIL: ({ id: id, activityId: activityId }) => {
      const queryClient = useQueryClient();
      return useMutation(
        () =>
          axios.post(`/campaigns/v1/${id}/activity/${activityId}/email/send`),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("campaigns");
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to POST new campaign \n Error: `, error);
            toast("[Fel] Det gick inte att skapa en ny kampanj ", {
              type: "error",
            });
          },
        }
      );
    },
    SENDTESTMAIL: ({ id: id, activityId: activityId }) => {
      const queryClient = useQueryClient();
      return useMutation(
        () =>
          axios.post(`/campaigns/v1/${id}/activity/${activityId}/email/test`),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("campaigns");
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to POST new campaign \n Error: `, error);
            toast("[Fel] Det gick inte att skapa en ny kampanj ", {
              type: "error",
            });
          },
        }
      );
    },
  },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/campaigns/v1/${id}`), {
        onSuccess: (x) => {
          queryClient.invalidateQueries(["campaigns"]);
          queryClient.removeQueries([id]);
          toast("Kampanjen borttagen ", { type: "info" });
        },
        onError: (error) => {
          console.warn(`Failed to delete campaign '${id}' \n Error: `, error);
          toast("[Fel] Kampanjen gick inte att ta bort ", { type: "error" });
        },
      });
    },
    SEGMENT: () => {
      const queryClient = useQueryClient();
      return useMutation(async (campaignSegment) => {
        await axios.delete(`/campaigns/v1/${campaignSegment?.campaignID}/segments/${campaignSegment?.id}`)
        return campaignSegment?.campaignID
      },
        {
          onSuccess: (campaignId) => {
            queryClient.invalidateQueries({
              predicate: (query) =>
                query.queryKey?.includes(campaignId)
            });

          },
        }
      );
    },

  },
};
