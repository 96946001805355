
import React, { useState, useRef } from "react";
import RangeSlider from "react-range-slider-input";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import styles from "./style.module.css";
import _ from 'lodash';
import { useEffect } from "react";

/**
 * A reusable slider component with minimum and maximum values.
 * @param {object} props - The props object.
 * @param {number} props.max - The maximum value for the slider.
 * @param {number} [props.min=500] - The minimum value for the slider.
 * @param {number} [props.step=1] - The step value for the slider.
 * @param {function} props.onChange - A function to be called when the slider value changes.
 * @returns {JSX.Element} - JSX element representing the MinMaxSlider component.
 */
const MinMaxSlider = ({ max, min = 500, step = 1, onChange, onMount}) => {
  // Define range settings
  const rangeSettings = {
    min: max < min ? max : min,
    max: max ?? 10000,
    step: step,
  };

  // Refs for input fields
  const minInputRef = useRef();
  const maxInputRef = useRef();

  // State for slider value
  const [rangeSliderValue, setRangeSliderValue] = useState([rangeSettings.min, rangeSettings.max]);

  const handleRangeInput = (values) => {
    debouncedHandleInput.cancel();
    debouncedHandleInput(values);
  };

  const handleInput = (values) => {
    let newValues = values?.map((value, index) => {
      if (typeof value === "undefined") return (rangeSliderValue?.[index] ?? (index === 0 ? rangeSettings.min : rangeSettings.max));
      if (typeof +value !== "number" || isNaN(+value)) return (index === 0 ? rangeSettings.min : rangeSettings.max);
      if (+value > rangeSettings.max) return rangeSettings.max;
      if (+value < rangeSettings.min) return rangeSettings.min;
      return value;
    });
    handleRangeSlide(newValues);
  };

  // Debounced version of handleInput
  const debouncedHandleInput = _.debounce(handleInput, 1000);

  const handleRangeSlide = (val) => {
    setRangeSliderValue(val);
    minInputRef.current.value = Math.min(...val);
    maxInputRef.current.value = Math.max(...val);
    if (typeof onChange === "function") onChange(val);
  };

  useEffect(() => {
    if (typeof onMount == "function" ) onMount(rangeSliderValue)
  },[])

  return (
      <div className="d-flex justify-content-center flex-nowrap gap-2">
        <FloatingLabel
          controlId="min_max_slider_min"
          label={`Min, ${rangeSettings.min}`}
          className={`text-md ${styles.labels}`}
        >
          <Form.Control
            type="text"
            ref={minInputRef}
            defaultValue={rangeSettings.min}
            name="min_max_slider_min"
            className="text-md semi-bold"
            required
            onInput={(e) =>
              handleRangeInput([e.target.value, undefined])
            }
          />
        </FloatingLabel>
        <div
          className={`d-flex flex-wrap justify-content-center ${styles?.hidesmallslider}`}
          style={{ flex: 8, minWidth: "200px" }}
        >
          <div style={{ flex: "6" }} className="px-2  my-auto">
            <RangeSlider
              value={rangeSliderValue}
              onInput={(e) => handleRangeSlide(e)}
              min={rangeSettings.min}
              max={rangeSettings.max}
              step={rangeSettings.step}
            />
          </div>
        </div>
        <FloatingLabel
          controlId="min_max_slider_max"
          label={`Max, ${rangeSettings.max}`}
          className={`text-md ${styles.labels}`}
        >
          <Form.Control
            type="text"
            ref={maxInputRef}
            defaultValue={rangeSettings.max}
            name="min_max_slider_max"
            className="text-md semi-bold"
            required
            onInput={(e) =>
              handleRangeInput([undefined, e.target.value])
            }
          />
        </FloatingLabel>
      </div>
  );
};

export default MinMaxSlider;

