import Agent from 'api/Agent';
import { segmentTypes } from 'features/segment/segment-details/assets/config';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Stack } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdInfo } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ExportSegmentOffCanvas({ show, onClose, segment }) {
  const navigate = useNavigate();
  const exportSegmentMutation = Agent.Segments.POST.EXPORT();
  const customerSettings = Agent.Customers.GET.CUSTOMERSETTINGS();

  const exportTypes = {
    EXCLUDE_PHONENUMBER: "Exkludera telefonnummer",
    INCLUDE_PHONENUMBER: "Med telefonnummer",
    INCLUDE_PHONENUMBER_OPERATOR: "Med telefonnummer och operatör"
  }

  const handleClose = () => typeof onClose === "function" && onClose(false);
  const segmentType = [segment?.type, segment?.subType].includes(segmentTypes.Company) ? segmentTypes.Company : segmentTypes.Person;

  const [exportType, setExportType] = useState();
  const [numberLimit, setNumberLimit] = useState();
  const [phoneFormat, setPhoneFormat] = useState();
  const [uniqueHouseholds, setUniqueHouseholds] = useState();
  const [householdSelection, setHouseholdSelection] = useState();
  const [limitCount, setLimitCount] = useState();
  const [email, setEmail] = useState();
  const [termsAccepted, setTermsAccepted] = useState();


  const basePrice = (segmentType == segmentTypes.Person ? customerSettings.data?.personExportPrice : customerSettings.data?.companyExportPrice) ?? 0;
  const exportUnitPrice = {
    [exportTypes.EXCLUDE_PHONENUMBER]: basePrice,

    [exportTypes.INCLUDE_PHONENUMBER]: basePrice + (customerSettings.data?.phoneExportPrice ?? 0),
    [exportTypes.INCLUDE_PHONENUMBER_OPERATOR]: basePrice + (customerSettings.data?.phoneExportPrice ?? 0)
  }[exportType]


  const setDefaultValues = () => {
    setExportType(exportTypes.EXCLUDE_PHONENUMBER);
    setNumberLimit(1);
    setPhoneFormat('');
    setUniqueHouseholds(false);
    setHouseholdSelection('');
    setLimitCount(segment?.size);
    setEmail('');
    setTermsAccepted(false);
  }

  useEffect(() => {
    setDefaultValues();
  }, [show])


  const handleExport = async () => {
    const exportData = {
      exportType,
      numberLimit,
      phoneFormat,
      uniqueHouseholds,
      householdSelection,
      limitCount,
      email,
      termsAccepted
    };
    await toast.promise(exportSegmentMutation.mutateAsync({
      id: segment?.id,
      include_phonenumber: exportType == exportTypes.INCLUDE_PHONENUMBER,
      include_phonenumber_operator: exportType == exportTypes.INCLUDE_PHONENUMBER_OPERATOR,
      phoneLimit: numberLimit,
      phoneFormat: phoneFormat,
      email,
    }),
      {
        success: `${segment?.name} exporterad.`,
        pending: "...",
        error: `${segment?.name} gick inte att exportera. försök igen.`,

      });
  };

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return null;
    }
    handleExport();

  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: "fit-content" }}>
      <Offcanvas.Header closeButton style={{ borderBottom: "1px solid gray" }}>
        <Offcanvas.Title>Exportera urval</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='p-5 py-2' >
        {exportSegmentMutation.isSuccess ?

          <Stack gap={2} style={{ maxWidth: "800px" }} className=" py-5">
            <div>
              <span>
                Exporten är nu påbörjad. Se den bland dina exporter och få tillgång till kontaktlistan när den är klar.
              </span>
            </div>
            <hr />
            <Button type="button" variant='success' className='px-3 text-xl' style={{ minWidth: "200px" }} onClick={() => navigate("/exports")}>Gå till dina exporter</Button>
          </Stack>
          :
          <Form noValidate validated={validated} onSubmit={handleSubmit} >
            <Stack gap={2} style={{ maxWidth: "800px" }}>
              <span>
                Du kan nu exportera din kontaktlista på <strong>{segment?.size ?? 0} {segmentType === segmentTypes.Person ? "personer" : "företag"}</strong> som din selektering gav.
              </span>
              <span>
                Exporten är oftast klar inom <strong>10 minuter</strong> och då får du en notis om det i systemet och/eller till din e-postadress.
              </span>
              <hr />
              <Row className='row-cols-1 row-cols-sm-2 gy-1'>
                <span className='bold'>Typ av export</span>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={exportTypes.EXCLUDE_PHONENUMBER}
                    name="exportType"
                    value={exportTypes.EXCLUDE_PHONENUMBER}
                    checked={exportType === exportTypes.EXCLUDE_PHONENUMBER}
                    onChange={(e) => setExportType(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label={exportTypes.INCLUDE_PHONENUMBER}
                    name="exportType"
                    value={exportTypes.INCLUDE_PHONENUMBER}
                    checked={exportType === exportTypes.INCLUDE_PHONENUMBER}
                    onChange={(e) => setExportType(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label={exportTypes.INCLUDE_PHONENUMBER_OPERATOR}
                    name="exportType"
                    value={exportTypes.INCLUDE_PHONENUMBER_OPERATOR}
                    checked={exportType === exportTypes.INCLUDE_PHONENUMBER_OPERATOR}
                    onChange={(e) => setExportType(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className='row-cols-1 row-cols-sm-2 gy-1'>
                {
                  segmentType === segmentTypes.Person ?
                    <span className='bold'>Antal telefonnummer per person</span>
                    :
                    <span className='bold'>Antal telefonnummer per företag</span>
                }
                <Form.Group>
                  <Form.Control
                    type="number"
                    min={1}
                    value={numberLimit}
                    onChange={(e) => setNumberLimit(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ange en gräns på minst ett nummer per kontakt
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className='row-cols-1 row-cols-sm-2 gy-1'>
                <span className='bold'>Format för telefonnummer</span>
                <Form.Group>

                  <Form.Select aria-label="Default select example"
                    onChange={(e) => setPhoneFormat(e.target.value)}>
                    <option value="">Internationell landskod (46)</option>
                    <option value="1">Nationell landskod (0)</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              {/* <hr /> */}

              {/* <Row className='row-cols-1 row-cols-sm-2 gy-1'> */}
              {/*   <span className='bold'>Unika hushåll</span> */}
              {/*   <Form.Group> */}
              {/*     <Form.Control */}
              {/*       type="text" */}
              {/*       value={householdSelection} */}
              {/*       onChange={(e) => setHouseholdSelection(e.target.value)} */}
              {/*     /> */}
              {/*   </Form.Group> */}
              {/* </Row> */}

              {/* <Row className='row-cols-1 row-cols-sm-2 gy-1'> */}
              {/*   <span className='bold'>Urval för hushåll</span> */}
              {/*   <Form.Group> */}
              {/*     <Form.Control */}
              {/*       type="text" */}
              {/*       value={householdSelection} */}
              {/*       onChange={(e) => setHouseholdSelection(e.target.value)} */}
              {/*     /> */}
              {/*   </Form.Group> */}
              {/* </Row> */}

              {/* <hr /> */}

              {/* <Row className='row-cols-1 row-cols-sm-2 gy-1'> */}
              {/*   {segmentType === segmentTypes.Person ? ( */}
              {/*     <span className='bold'>Begränsa antal personer</span> */}
              {/*   ) : ( */}
              {/*     <span className='bold'>Begränsa antal företag</span> */}
              {/*   )} */}
              {/*   <Form.Group> */}
              {/*     <InputGroup hasValidation> */}
              {/*       <Form.Control */}
              {/*         type="number" */}
              {/*         min={1} */}
              {/*         value={limitCount} */}
              {/*         onChange={(e) => e.target.value <= segment?.size && setLimitCount(e.target.value)} */}
              {/*       /> */}
              {/*       <span> */}
              {/*         av {segment?.size ?? 0} {segmentType === segmentTypes.Person ? "personer" : "företag"} i din kontaktlista, som inte exporterats tidigare. */}
              {/*       </span> */}
              {/**/}
              {/*     </InputGroup> */}
              {/*   </Form.Group> */}
              {/* </Row> */}
              <hr />
              <Row className='row-cols-1 row-cols-sm-2 gy-1'>
                <span className='bold'>Pris för export</span>

                <div className='d-flex flex-column gap-2'>

                  <span className='semibold'>{exportUnitPrice * segment?.size} kr ex. moms</span>

                  <div className='text-muted d-flex flex-row align-items-center justify-content-between'>

                    <MdInfo size={32} className="pe-2" style={{ minWidth: "32px" }} />

                    <span className='text-md'>Detta segment har lokala filter vilket innebär att priset är preliminärt tills exporten är klar. Priset anger ett maxpris.</span>
                  </div>
                </div>
              </Row>
              <Row className='row-cols-1 row-cols-sm-2 gy-1'>
                <span className='bold'>E-postadress</span>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className='row-cols-1 row-cols-sm-2 gy-1'>
                <span className='bold'>Villkor *</span>
                <Form.Group>
                  <InputGroup hasValidation>
                    <Form.Check
                      type='checkbox'
                      label="Genom att kryssa i denna ruta godkänner jag..."
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      required

                    />
                    <Form.Control.Feedback type="invalid">
                      Du behöver godkänna våra villkor
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className='row-cols-auto justify-content-end pt-2'>
                <Button type="submit" variant='success' disabled={exportSegmentMutation.isLoading} className='px-3 text-xl' >Exportera</Button>
              </Row>
              <Row className='row-cols-auto justify-content-start pt-2'>
                <span className='text-sm text-muted'>* nödvändiga fält</span>
              </Row>
            </Stack>
          </Form>

        }
      </Offcanvas.Body>
    </Offcanvas >
  );
}
