import Agent from "api/Agent";
import { useState, useEffect } from "react";
import { Row, Col, Button, Container, Form, DropdownButton, Dropdown } from "react-bootstrap";
import { MdFilterList } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import Select from 'react-select';

const FilterLeadsButton = ({ activityId, filters, setFilters }) => {
  const leadType = filters.leadType !== undefined ? { leadType: filters.leadType } : undefined;

  const municipalityOptions = Agent.CampaignsActivity.GET.LEADS_OPTIONS(activityId, "municipality", leadType, true)
  const [selectMunicipalityValue, setSelectMunicipalityValue] = useState(null);
  const handleMunicipalitySelect = (e) => {
    setSelectMunicipalityValue(e)
    setFilters({
      ...filters,
      municipality: e?.value,
    })
  }

  const companyTypes = Agent.CampaignsActivity.GET.LEADS_OPTIONS(activityId, "companyType", leadType, true)
  const [selectCompanyTypeValue, setSelectCompanyTypeValue] = useState(null);
  const handleCompanySelect = (e) => {
    setSelectCompanyTypeValue(e)
    setFilters({
      ...filters,
      companyType: e?.value,
    })

  }

  const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const [filtersCount, setFiltersCount] = useState(0);

  function countPropertiesWithValues(obj) {
    let count = 0;
    for (let key in obj) {
      if (obj[key]) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {
    setFiltersCount(countPropertiesWithValues(filters));
  }, [filters]);

  return (
    <Dropdown className="" autoClose={"outside"}>
      <Dropdown.Toggle

        // onClick={() => setShowFilters(!showFilters)}
        variant="light"
        className="filter-button text-md semi-bold py-1"


      >
        <Row className="row-cols-auto justify-content-between align-items-baseline">
          <Col >
            <MdFilterList size={20} />
          </Col>
          <Col className="text-start p-0 m-0">
            Filter
          </Col>
          <Col className="my-0 py-0">
            {filtersCount > 0 && (
              <span className="filter-indicator">{filtersCount}</span>
            )}
          </Col>
        </Row>
      </Dropdown.Toggle>
      <Dropdown.Menu align={{ lg: "end" }} >
        <Dropdown.Item style={{ minWidth: "250px", backgroundColor: "#FFF", border: "none", color: "#000" }}>
          <Row className="g-3 flex">
            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Synkad till CRM</p>
              <Form.Select
                aria-label="Default select example"
                value={filters.isInCrm}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    isInCrm: e.target.value,
                  })
                }
              >
                <option value={""}>Alla</option>
                <option value={1}>Synkad</option>
                <option value={0}>Ej Synkad</option>
              </Form.Select>
            </Col>
            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Har aktivitet-/er</p>
              <Form.Select
                aria-label="Default select example"
                value={filters.hasActivity}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    hasActivity: e.target.value,
                  })
                }
              >
                <option value={""}>Alla</option>
                <option value={1}>Har aktiviteter</option>
                <option value={0}>Har ej aktiviteter</option>
              </Form.Select>
            </Col>
            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Score</p>
              <Form.Select
                aria-label="Default select example"
                value={filters.score}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    score: e.target.value,
                  })

                }
              >
                <option value={""}>Alla</option>
                <option value={0}>Lågt rankade</option>
                <option value={1}>Medel rankade</option>
                <option value={2}>Högt rankade</option>
              </Form.Select>
            </Col>

            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Företagstyp</p>
              <Select
                styles={{ control: (styles) => ({ ...styles, backgroundColor: "white" }) }}
                placeholder="Sök & välj.."
                options={companyTypes.data?.map(type => ({ label: type, value: type }))}
                isLoading={companyTypes.isLoading}
                isDisabled={companyTypes.isError}
                onChange={(e) => handleCompanySelect(e)}
                value={selectCompanyTypeValue}
              >
              </Select>
            </Col>

            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Kommun</p>
              <div style={{ backgroundColor: "white" }}>
                <Select
                  styles={{ control: (styles) => ({ ...styles, backgroundColor: "white" }) }}
                  placeholder="Sök & välj.."
                  options={municipalityOptions.data?.map(type => ({ label: type, value: type }))}
                  isLoading={municipalityOptions.isLoading}
                  isDisabled={municipalityOptions.isError}
                  onChange={(e) => handleMunicipalitySelect(e)}
                  value={selectMunicipalityValue}
                >
                </Select>
              </div>
            </Col>

            <Col className="col-12 d-flex justify-content-end">
              <Button
                variant="link"
                onClick={() => {
                  setFilters(prev => ({
                    ...prev,
                    isInCrm: "",
                    hasActivity: "",
                    score: "",
                    companyType: "",
                    municipality: "",
                  }))
                  setSelectCompanyTypeValue(null)
                  setSelectMunicipalityValue(null)
                }
                }
              >
                Rensa
              </Button>
            </Col>
          </Row>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown >

  );
};

export default FilterLeadsButton;
