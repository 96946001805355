import { Row, Col, Nav, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./style.scss";

import { useMediaQuery } from "react-responsive";

const MenuItem = (props) => {
  const ifBigScreen = useMediaQuery({ query: "(min-width: 1224px)" });
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const bigScreenStyle = {
    paddingLeft: "30px",
  };
  const smallScreenStyle = {
    justifyContent: "center",
  };

  const handleMenuItemClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? "menu-item menu-item-active" : "menu-item"
      }
      to={props.link}
      style={
        ifBigScreen
          ? bigScreenStyle
          : ifSmallScreen
          ? bigScreenStyle
          : smallScreenStyle
      }
      onClick={handleMenuItemClick}
    >
      <Row className={`${ifSmallScreen ? "gx-4" : "gx-5"}`}>
        <Col className="col-2 menu-item-icon">{props.icon}</Col>
        {ifBigScreen && (
          <Col className="d-flex align-items-center">
            <p
              className={`${
                ifSmallScreen
                  ? "menu-item-text text-md regular text-start"
                  : "menu-item-text text-lg regular text-start"
              }`}
            >
              {props.title}
            </p>
          </Col>
        )}
        {ifSmallScreen && (
          <Col className="d-flex align-items-center">
            <p
              className={`${
                ifSmallScreen
                  ? "menu-item-text text-md regular text-start"
                  : "menu-item-text text-lg regular text-start"
              }`}
            >
              {props.title}
            </p>
          </Col>
        )}
      </Row>
    </NavLink>
  );
};

export default MenuItem;
