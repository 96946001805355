import { Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { MdFace } from "react-icons/md";

const ContactForm = ({ account }) => {
  return (
    <CardContainer
      padding
      marginBottom
      children={
        <Row className="justify-content-between">
          <Col className="col-12">
            <h3 className="display-sm semi-bold">Kontaktperson</h3>
          </Col>
          <Col className="my-4 col-9">
            <Row>
              <Col className="col-2 d-flex justify-content-center">
                <MdFace size={60} color="#F7DDBD" />
              </Col>

              <Col style={{ margin: "auto" }}>
                <p className="text-md regular">
                  Namn:
                  {account.jObject ? (
                    <span className="semi-bold">
                      {" "}
                      {account?.jObject.basic.data.name.given_name
                        ? account?.jObject.basic.data.name.given_name
                        : account?.jObject.basic.data.name.first_name}{" "}
                      {` ${account?.jObject.basic.data.name.last_name}`}
                    </span>
                  ) : !account.jObject && account.firstName ? (
                    <span className="semi-bold">
                      {` ${account.firstName} ${account.lastName}`}
                    </span>
                  ) : (
                    <span className="semi-bold"> Namn saknas</span>
                  )}
                </p>
                <p className="text-md regular">
                  Email:
                  <span className="semi-bold">{` ${account?.email}`}</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  );
};

export default ContactForm;
