import { useState } from "react";
import { Table, Badge, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import Numeral from "react-numeral";
import { getCustomerSettings } from "features/settings/api";
import { useQuery } from "react-query";
import PaginationBar from "components/pagination/PaginationBar";
import Agent from "api/Agent";
import {
  segmentTypes_sv,
  segmentTypes,
} from "features/segment/segment-details/assets/config";

const SegmentTable = (props) => {
  //Pagination
  const rowsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: segments,
    isError,
    isLoading,
    error,
  } = Agent.Segments.GET.ALL(
    currentPage,
    rowsPerPage,
    undefined,
    "createdOn",
    true
  );

  const navigate = useNavigate();
  const { data: settings } = useQuery("customer", getCustomerSettings);

  //Edit segment
  const onEditClick = (id) => {
    navigate(`/segments/create-segment/${id}`);
  };

  return (
    <>
      <Row>
        <div>
          <Table className="campaign-table " responsive hover>
            <thead>
              <tr>
                {props.columns.map((column) => (
                  <th className="campaign-column-title" key={column.id}>
                    {props.tableName === "offers" && settings?.offerPluralName
                      ? settings?.offerPluralName
                      : column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.tableName === "segments" && isLoading ? (
                <tr>
                  <td colSpan={99}>
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              ) : !segments?.data?.length ? (
                <tr className="text-center">
                  <td></td>
                  <td>Inga tillgängliga målgrupper</td>
                </tr>
              ) : (
                segments?.data?.map((row) => (
                  <tr key={row.id} onClick={() => props.onClick(row.id)}>
                    <td style={{ whiteSpace: "nowrap" }}>{row.name}</td>
                    <td>
                      {row.status === 0 ? (
                        <Badge pill bg="warning">
                          Kalkylerar...
                        </Badge>
                      ) : row.status === 1 ? (
                        <Badge pill bg="success">
                          Redo
                        </Badge>
                      ) : (
                        <Badge pill bg="danger">
                          Ogiltig
                        </Badge>
                      )}
                    </td>
                    {!props.ifSmallScreen && (
                      <>
                        <td>{segmentTypes_sv[row?.type]?.text}</td>

                        <td>{row.size}</td>

                        <td style={{ color: "#2D6450", whiteSpace: "nowrap" }}>
                          {row.campaignsCount}{" "}
                          {!props.ifSmallScreen &&
                            (row.campaignsCount === 1
                              ? "kampanj"
                              : "kampanjer")}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          {segments?.data &&
            props.tableName === "segments" &&
            segments?.paging?.total > 3 && (
              <PaginationBar
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                countPerPage={rowsPerPage}
                totalCount={segments?.paging?.total}
                showTotal={true}
              />
            )}
        </div>
      </Row>
    </>
  );
};

export default SegmentTable;
