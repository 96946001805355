import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function CaptionStatus({isError, isLoading, totalCount}) {
  if (isError) return (
    <caption
      style={{ captionSide: "bottom", columnSpan: "100%" }}
      className="p-3 bg-danger"
    >
      <h5 className=" text-white semi-bold text-center w-100">
        Ett fel uppstod vid hämtning av data
      </h5>
    </caption>
  )

  if ( isLoading ) return (
    <caption
      style={{ captionSide: "bottom", columnSpan: "100%" }}
      className="p-3"
    >
      <h5 className=" text-black text-center w-100">
        <Spinner />
      </h5>
    </caption>
  )

  if (!isLoading && !isError && !totalCount) return (
    <caption
      style={{ captionSide: "bottom", columnSpan: "100%" }}
      className="p-3 text-center"
    >
      <span className="text-xl text-black text-muted ">
        Inga kampanjer hittade.
      </span>
    </caption>
  )

  return <></>
}
