import { Spinner, Badge } from "react-bootstrap";
import { useState } from "react";
import { Table, Row, Col, Image } from "react-bootstrap";
import { CardContainer } from "components/cards";
import Numeral from "react-numeral";
import PaginationBar from "components/pagination/PaginationBar";
import { MdOutlineContentCopy, MdSearch, MdDownload } from "react-icons/md";
import moment from "moment";
import { segmentStatuses_sv } from "features/segment/segment-details/assets/config";
import { TextInput } from "components/forms";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import SegmentDetailsOffCanvas from "features/segment/segment-details/SegmentDetailsOffCanvas";
import Agent from "api/Agent";

const ListsTable = (props) => {
  const { data } = Agent.CustomLists.GET.LISTS();

  console.log({ data });
  //Table order, search and pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = props.rows;
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ prop: "createdOn", desc: true });

  const dummyData = [
    {
      name: "Våra personkunder Sommar 2024",
      size: 3087,
      type: 4,
      subType: null,
      id: "e07417a6-835b-4b4f-f82a-08dc807e36d8",
      createdOn: "2024-05-30T07:57:55.5281356",
      modifiedOn: "2024-06-05T12:26:30.3322122",
    },
    {
      name: "Våra företagskunder Sommar 2024",
      size: 3087,
      type: 3,
      subType: null,
      id: "e07417a6-835b-4b4f-f82a-08dc807e36d8",
      createdOn: "2024-05-30T07:57:55.5281356",
      modifiedOn: "2024-06-05T12:26:30.3322122",
    },
    {
      name: "Adresser att exkludera",
      size: 3087,
      type: 6,
      subType: null,
      id: "e07417a6-835b-4b4f-f82a-08dc807e36d8",
      createdOn: "2024-05-30T07:57:55.5281356",
      modifiedOn: "2024-06-05T12:26:30.3322122",
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //*-----------------------------------------------

  const columns = [
    { label: "Listnamn", property: "name", sortable: true },
    { label: "Skapad", property: "createdOn", sortable: true },
    { label: "Typ", property: "type", sortable: true },
    { label: "Storlek", property: "size", sortable: true },
    { label: "", property: "download" },
  ];

  const handleTableHeaderClick = (property) => {
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };

  const handleSearch = (input) => {
    setSearch(input);
    currentPage > 1 && setCurrentPage(1);
  };

  return (
    <>
      <Row>
        {props.filter && (
          <Row className="d-flex flex-row align-items-baseline justify-content-between px-0 mx-0">
            <div className="col-auto"></div>
            <div style={{ width: "300px" }}>
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </Row>
        )}

        <Col className="col-12">
          <CardContainer
            style={{ padding: "0" }}
            children={
              <div className="table-responsive">
                <Table
                  id="segmentTable"
                  className="table-container"
                  responsive
                  hover
                  size={props.tableSize}
                >
                  <thead className="text-sm semi-bold">
                    <tr>
                      {columns.map((col, index) => (
                        <th
                          style={{ width: "150px", userSelect: "none" }}
                          key={index}
                          onClick={() =>
                            col.sortable && handleTableHeaderClick(col.property)
                          }
                        >
                          {sort?.prop === col.property && col.property ? (
                            <p>
                              {col.label}
                              {sort?.desc ? (
                                <FaSortDown className="ms-1" size={12} />
                              ) : (
                                <FaSortUp className="ms-1" size={12} />
                              )}
                            </p>
                          ) : (
                            <p>
                              {col.label}
                              {!!col?.sortable && (
                                <FaSort className="ms-1" size={12} />
                              )}
                            </p>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="text-md regular">
                    {data?.Results.map((item) => (
                      <tr key={item?.id}>
                        <td
                          style={{
                            maxWidth: "400px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          {item?.file.displayName}
                        </td>

                        <td>
                          {moment(item?.createdOn).format("yyyy-MM-DD")}
                          <p
                            className="text-sm"
                            style={{ color: "#7B8FA1", margin: "-10px 0" }}
                          >
                            {moment(item?.createdOn).format("HH:mm")}
                          </p>
                        </td>
                        <td>
                          {item?.listType === 0
                            ? "Företag"
                            : item?.listType === 1
                            ? "Personer"
                            : item?.listType === 3
                            ? "Org.Nummer"
                            : item?.listType === 4
                            ? "Personnummer"
                            : item?.listType === 5
                            ? "Telefonnummer"
                            : item?.listType === 6
                            ? "Addresser"
                            : ""}
                        </td>
                        <td>
                          <Numeral format={"0,0"} value={item?.file.rowCount} />{" "}
                          st
                        </td>
                        <td>
                          <div>
                            <button className="edit-button">
                              <a href={item?.file.url}>
                                <MdDownload size={18} />
                              </a>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data?.paging && !props?.data && !props?.inCampaignDetails && (
                  <PaginationBar
                    setCurrentPage={handlePageChange}
                    paging={data?.paging}
                  />
                )}
                {data && data.length === 0 && (
                  <Col className="d-flex align-items-center justify-content-center p-3">
                    <h5 style={{ color: "#ddd" }}>Inga listor hittade.</h5>
                  </Col>
                )}
              </div>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ListsTable;
