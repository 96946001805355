import { Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { GoogleMapMain } from "components/maps";
import ContactForm from "../components/ContactForm";
import AccountTimeLine from "../../components/AccountTimeLine";

const accountOverview = ({ accountId, setBgBlur, account, accountActivities }) => {
  return (
    <Row className="gy-5" style={{ overflow: "hidden" }}>
      {/* DESCRIPTION and MAP------------------- */}
      <Col className="col-6" style={{ height: "100%", overflowY: "auto" }}>
        <Row className="g-4">
          <Col className="col-12">
            <CardContainer
              padding
              children={
                <Row>
                  <Col className="col-12">
                    <Row className="g-2">
                      <Col className="col-12">
                        <h3 className="display-sm semi-bold">Beskrivning</h3>
                        <p className="text-lg regular">
                          {account?.jObject?.basic?.data?.description?.replace(
                            /\//g,
                            ""
                          )}
                        </p>
                      </Col>
                      <Col className="col-6">
                        <h6 className="text-lg bold">Organisationsnummer</h6>
                        <p className="text-lg regular">
                          {account.jObject.basic.data.orgnumber}
                        </p>
                      </Col>
                      <Col className="col-6">
                        <h6 className="text-lg bold">Adress</h6>
                        <p className="text-lg regular">
                          {account.jObject.basic.data.address},
                          {account.jObject.basic.data.zipcode
                            .toString()
                            .substring(0, 3)}
                          {account.jObject.basic.data.zipcode
                            .toString()
                            .substring(3, 5)}
                          , {account.jObject.basic.data.town}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 mt-4">
                    <GoogleMapMain
                      address={account.jObject.basic.data.address}
                      height="100%"
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col className="col-12">
            <ContactForm accountId={accountId} account={account} />
          </Col>
        </Row>
      </Col>

      {/* TIMELINE------------------- */}
      <Col className="col-6" style={{ height: "100%", overflowY: "auto" }}>
        <AccountTimeLine
          accountId={accountId}
          accountActivities={accountActivities}
          setBgBlur={setBgBlur}
          account={account}
        />
      </Col>
    </Row>
  );
};

export default accountOverview;
