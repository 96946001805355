import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { TextInput } from "components/forms";
import { Button, Row, Col, Table, Spinner } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { TfiReload } from "react-icons/tfi";
import {
  LeadCompanyDetailsOffCanvas,
  LeadPersonsDetailsOffCanvas,
} from "features/leads/lead-details";
import { BsArrowDownShort } from "react-icons/bs";
import PaginationBar from "components/pagination/PaginationBar";
import * as XLSX from "xlsx";
import FilterLeadsButton from "./components/FilterLeadsButton";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";
import ExportExcelButton from "./components/ExportExcelButton";
import DownloadExcelModal from "./components/DownloadExcelModal";
import { useSelector } from "react-redux";

const LeadGeneration = ({ activity }) => {
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const paddingValue = ifSmallScreen ? "20px 15px 100px 15px" : "40px 70px 100px 70px";


  const [show, setShow] = useState(false);
  const [leadId, setLeadId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setLeadId(null);
  };

  const handleShow = (id, type) => {
    setFilters(prev => ({ ...prev, leadType: type }));
    setLeadId(id);
    setShow(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    leadType: 0,
    search: "",
    syncedToCrm: "",
    hasActivities: "",
    score: "",
    companyType: "",
  });

  const { data: leads, isError, isFetched, refetch, error } = Agent.CampaignsActivity.GET.LEADS(
    activity?.id,
    currentPage,
    filters,
  );
  const LEAD_TYPES = {
    PERSON: 1,
    COMPANY: 0
  }
  const leadsType = new Set(leads?.Results?.flatMap(x => x?.type))?.values()?.next()?.value;



  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const personColumns = [
    { label: "Namn", id: 1 },
    // { label: "Telefon", id: 2 }, #2355. we need to control access to phonenumbers
    { label: "I CRM", id: 3 },
    { label: "Aktiviteter", id: 4 },
    { label: "Ort", id: 5 },
    { label: "Ålder", id: 6 },
    {
      label: (
        <p>
          <BsArrowDownShort size={20} />
          Score
        </p>
      ),
      id: 7,
    },
  ]

  const companyColumns = [
    { label: "Namn", id: 1 },
    { label: "I CRM", id: 2 },
    { label: "Aktiviteter", id: 3 },
    { label: "Ort", id: 4 },
    { label: "Typ av företag", id: 5 },
    {
      label: (
        <p>
          <BsArrowDownShort size={20} />
          Score
        </p>
      ),
      id: 6,
    },
  ]

  if (isError) return <p>{error.message}</p>;

  const columns = { [LEAD_TYPES.PERSON]: personColumns, [LEAD_TYPES.COMPANY]: companyColumns }[leadsType]
  const filteredLeads = leads?.Results
  return (
    <>
      {leadId && filters.leadType === 0 && (
        <LeadCompanyDetailsOffCanvas
          show={show}
          handleClose={handleClose}
          leadId={leadId}
        />
      )}

      {leadId && filters.leadType === 1 && (
        <LeadPersonsDetailsOffCanvas
          show={show}
          handleClose={handleClose}
          leadId={leadId}
        />
      )}
      <Row
        style={{ padding: paddingValue, maxWidth: "1600px" }}
        className="justify-content-between mx-auto gap-4 align-items-center"
      >
        <Col className="col-12 ">
          <Row>
            <Col className="col-12">
              <Row className="justify-content-between">
                <Col className="col-10">
                  <h4 className="display-sm semi-bold">Leadsgenerering</h4>
                  <p className="text-sm">
                    I tabellen nedan genereras leads automatisk baserat på
                    resultaten av din kampanj.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <Row className="justify-content-center justify-content-lg-between row-cols-auto gap-2 align-items-center pb-2">
            <Col > {/* className={`${ifSmallScreen ? "col-12" : "col-4"}`} */}
              <Row className="justify-content-between g-1 align-items-center">
                <div className="col-auto " >
                  <Button
                    variant="success"
                    className="text-md btn-lg px-2"
                    onClick={() => refetch()}
                  >
                    <div className="d-flex flex-row justify-content-between gap-1 align-items-center">
                      <span>
                        Uppdatera
                      </span>
                      <TfiReload />
                    </div>
                  </Button>
                </div>
                <div className="col-auto " >
                  <ExportExcelButton activityId={activity?.id} filters={filters} />
                </div>
              </Row>
            </Col>
            <Col >{/*  className={`${ifSmallScreen ? "col-12" : "col-5"}`} */}
              <Row
                className="justify-content-center justify-content-lg-end gap-1 align-items-center row-cols-auto"
              >
                <Col
                  className="py-0"
                // className={`${ifXsScreen ? "col-12 order-2 " : "col-8"}`}
                >
                  <TextInput
                    type="search"
                    placeholder="Sök"
                    icon={<MdSearch />}
                    onChange={(e) => {
                      setFilters(prev => ({ ...prev, search: e.target.value }));
                    }}
                  />
                </Col>
                <Col>
                  <FilterLeadsButton
                    activityId={activity?.id}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          {isFetched ? (
            <CardContainer
              style={{ padding: "0" }}
              children={
                <>
                  <div className="table-responsive">
                    <Table
                      id="segmentTable"
                      className="table-container"
                      responsive
                      hover
                      size="lg"
                    >
                      <thead className="text-sm semi-bold">
                        <tr>
                          {columns?.map((col) => (
                            <th key={col.id} style={{ color: "grey" }}>
                              {col.label}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody className="text-md regular">
                        {filteredLeads &&
                          filteredLeads?.map((item) => {
                            return (
                              <tr
                                key={item?.id}
                                onClick={() => handleShow(item?.id, item?.type)}
                              >
                                <td>
                                  <div className="px-2" style={{ cursor: "auto", userSelect: "text", width: "max-content" }}
                                    onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>

                                    {item?.type === 0
                                      ? item?.title
                                      : `${item?.firstName} ${item?.lastName}`}

                                  </div>
                                </td>
                                {/* #2355. we need to control access to phonenumbers
                              leadsType === LEAD_TYPES.PERSON &&
                                <td  ><div className="px-2" style={{ cursor: "auto", userSelect: "text", width: "max-content" }}
                                  onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>{item?.phone ? "+" + item?.phone : "-"}</div></td>
                              */}
                                <td>{item?.syncedToCrm ? "Ja" : "Nej"}</td>
                                <td>{item?.activityCount} st</td>
                                <td>{item?.type === 0 ? item?.city : item?.city}</td>
                                <td>
                                  {item?.type === 0
                                    ? item?.companyType
                                    : `${item?.age} år`}
                                </td>
                                <td>{item?.score}</td>
                              </tr>
                            );
                          })}

                        {filteredLeads && filteredLeads.length === 0 && (
                          <Col className="d-flex align-items-center justify-content-start p-3">
                            <h5 style={{ color: "#ddd" }}>
                              Inga leads hittade. Rensa dina filter och/eller klicka
                              på att uppdatera och prova igen!
                            </h5>
                          </Col>
                        )}
                      </tbody>
                    </Table>
                    {/* PAGINATION------------------------------ */}

                    {filteredLeads && filteredLeads.length > 0 && (
                      <PaginationBar
                        currentPage={currentPage}
                        setCurrentPage={handlePageChange}
                        countPerPage={leads?.Paging.per_page}
                        totalCount={leads?.Paging.total}
                      />
                    )}

                  </div>
                </>
              }
            />
          ) : (
            <Spinner />
          )}
        </Col>
      </Row >
    </>
  );
};

export default LeadGeneration;
