import { Col, Row } from "react-bootstrap";
import MobilePreview from "../../../components/MobilePreview";
import EditLinkedinAd from "../components/EditLinkedinAd";
import "../../../style.scss";
import { forwardRef } from "react";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const LinkedinStep1 = forwardRef(
  (
    {
      setBgBlur,
      campaign,
      activity,
      linkedinAdObject,
      setLinkedinAdObject,
      previewMutation,
      adBannerPreview,
    },
    ref
  ) => {
    return (
      <Row className=" row-cols-1 row-cols-xl-2 ">
        {/* CONTENT LEFT---------- */}
        <Col>
          <Row className="d-flex align-items-center row-cols-auto flex-wrap-reverse">
            <Col className="float-start">
              <span className="display-sm semi-bold">
                Skapa annons för Linkedin
              </span>
            </Col>
            <Col className=" float-start ms-auto">
              {statusBadgeSetter(activity)}
            </Col>
          </Row>
          <EditLinkedinAd
            previewMutation={previewMutation}
            campaign={campaign}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            ref={ref}
            setBgBlur={setBgBlur}
          />
        </Col>
        {/* CONTENT RIGHT---------- */}
        <Col>
          <MobilePreview
            channel="LINKEDIN"
            activity={activity}
            adBannerPreview={adBannerPreview ? adBannerPreview : null}
          />
        </Col>
      </Row>
    );
  }
);

export default LinkedinStep1;
