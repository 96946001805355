import { useMutation, useQueryClient } from "react-query";
import { updateCampaignActivity } from "features/campaign/api";
import GoogleAdsStep1 from "./wizard/GoogleAdsStep1";
import GoogleAdsStep2 from "./wizard/GoogleAdsStep2";
import GoogleAdsStep3 from "./wizard/GoogleAdsStep3";
import GoogleAdsStepper from "./wizard/GoogleAdsStepper";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useRef } from "react";
import { putPreview } from "../../api";
import Agent from "api/Agent";

const GoogleAds = ({ campaign, activity, setBgBlur }) => {
  const { data: segment } = Agent.Segments.GET.ID(campaign?.segmentID);
  const [activeStep, setActiveStep] = useState(activity?.externalId ? 2 : 0);
  const [selectedMedia, setSelectedMedia] = useState("Facebook");

  const formRef = useRef(null);
  const formRef2 = useRef(null);
  //Form states--------------------------------------

  const [googleAdsObject, setGoogleAdsObject] = useState({
    metaPageId: activity?.ad?.metaPageId ? activity?.ad.metaPageId : 0,
    imageId: null,
    imageId2: null,
    imageId3: null,
    imageId4: null,
    adTitle: activity?.ad ? activity.ad.title : "",
    adDescription: activity?.ad ? activity.ad.description : "",
    adTargetUrl: activity?.ad ? activity.ad.targetUrl : "",
    adButtonText: activity?.ad ? activity.ad.buttonText : "",
    primaryText: activity?.ad ? activity.ad.primaryText : "",
    landingPageID: activity?.ad ? activity.ad.landingPageID : "",
    adType: "0",
    startDate: activity?.ad?.endDate
      ? activity.ad.startDate
      : campaign?.startDate
      ? campaign.startDate
      : "",
    endDate: activity?.ad?.endDate
      ? activity.ad.endDate
      : campaign?.endDate
      ? campaign.endDate
      : "",
    targetGroupMaxSize: activity?.targetGroupMaxSize
      ? activity.targetGroupMaxSize
      : segment
      ? segment.size
      : 0,
    directMarketingTarget: activity?.directMarketingTarget
      ? activity.directMarketingTarget
      : null,
    budget: activity?.ad?.budget
      ? activity.ad.budget
      : campaign?.totalBudget
      ? campaign.totalBudget
      : 100,
  });

  console.log({ activity });

  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
        setActiveStep((prev) => prev + 1);
      },
    }
  );

  //Trigger preview---------------------------------------

  const [adBannerPreview, setAdBannerPreview] = useState(null);

  const previewMutation = useMutation(
    (adObject) => putPreview(activity?.id, adObject),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          "MobilePreview/" + activity?.id + "/" + selectedMedia
        );
        console.log({ response });
        setAdBannerPreview(response);
      },
    }
  );

  useEffect(() => {
    const updatedCampaign = {
      ...activity,
      ad: {
        ...activity.ad,
        title: googleAdsObject.adTitle,
        description: googleAdsObject.adDescription,
        metaPageId: googleAdsObject.metaPageId,
        targetUrl: googleAdsObject.adTargetUrl,
        landingPageID: googleAdsObject.landingPageID,
        adMedias: [
          {
            externalId: googleAdsObject.imageId,
          },
        ],
      },
    };
    if (googleAdsObject.imageId) {
      previewMutation.mutate(updatedCampaign);
    }
  }, [googleAdsObject.imageId]);

  const steps = [
    {
      step: 0,
      children: (
        <Container
          className="googleads-container"
          key={0}
          style={{ padding: "15px 70px 100px 70px" }}
        >
          <GoogleAdsStep1
            campaign={campaign}
            activity={activity}
            googleAdsObject={googleAdsObject}
            setGoogleAdsObject={setGoogleAdsObject}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            ref={formRef}
            setBgBlur={setBgBlur}
            previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
    {
      step: 1,
      children: (
        <Container
          className="googleads-container"
          key={1}
          style={{ padding: "40px 70px 100px 70px" }}
        >
          <GoogleAdsStep2
            campaign={campaign}
            activity={activity}
            googleAdsObject={googleAdsObject}
            setGoogleAdsObject={setGoogleAdsObject}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            mutate={mutate}
            ref={formRef2}
            previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
            segment={segment}
          />
        </Container>
      ),
    },
    {
      step: 2,
      children: (
        <Container
          className="googleads-container"
          key={2}
          style={{ padding: "40px 70px 100px 70px" }}
        >
          <GoogleAdsStep3
            campaign={campaign}
            mutate={mutate}
            activity={activity}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
  ];

  return (
    <>
      {steps.map((step) => {
        if (step.step === activeStep) {
          return step.children;
        }
      })}
      {activeStep !== 2 && (
        <GoogleAdsStepper
          mutate={mutate}
          isError={isError}
          isLoading={isLoading}
          activity={activity}
          campaign={campaign}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          googleAdsObject={googleAdsObject}
          formRef={formRef}
          formRef2={formRef2}
        />
      )}
    </>
  );
};

export default GoogleAds;
