import axios from "api/axios";

import { endPoints } from "api/endPoints";

const FILTERTYPES_URL = endPoints.filtertypes.FILTERTYPES_URL;
const FILTERCATEGORIES_URL = endPoints.filtertypes.FILTERCATEGORIES_URL;
const FILTERS_URL = endPoints.filtertypes.FILTERS_URL;

export const getFilters = async ({ segmentTypeId }) => {
  const response = await axios.get(`${FILTERTYPES_URL}`, {
    params: { segmentTypeId },
  });
  return response.data;
};

export const getFilterCategories = async ({ queryKey }) => {
  const [_, segmentTypeId] = queryKey;
  const { data } = await axios.get(
    `${FILTERCATEGORIES_URL}?segmentType=${segmentTypeId}`
  );
  return data;
};

export const getFilterSelectValues = async (filter, segment) => {
  const { data } = await axios.get(`${FILTERS_URL}/${filter.id}`);

  if (filter.id === "a17dcb9f-aea6-48e4-99c9-08dad1176ea3") {
    const segmentFilters = segment && JSON.parse(segment?.filters);

    // First step: find the matching object in array2
    const matchingObject = segmentFilters.find(
      (obj) => Object.keys(obj)[0] === "86b39e59-4235-42ab-99c8-08dad1176ea3"
    );
    if (matchingObject) {
      // Second step: filter array1
      const filteredOptions = data.filter((obj) => {
        const matchingIds = matchingObject[
          "86b39e59-4235-42ab-99c8-08dad1176ea3"
        ].map((el) => el.id);
        if (matchingIds?.length <= 0) {
          return data;
        } else {
          return matchingIds.includes(obj.parentFilterID);
        }
      });
      return filteredOptions.map((item) => ({
        value: item.id,
        label: item.name,
        query: item.query ? item.query : "",
      }));
    }

    return data.map((item) => ({
      value: item.id,
      label: item.name,
      query: item.query ? item.query : "",
    }));
    // console.log({ filteredOptions });
  } else {
    return data.map((item) => {
      return {
        value: item.id,
        label: item.name,
        query: item.query ? item.query : "",
      };
    });
  }
};

export const getFilterRangeValues = async (filter, id) => {
  const { data } = await axios.get(`${FILTERS_URL}/${filter.id || id}`);
  return data;
};
