import Agent from "api/Agent";
import { CardContainer } from "components/cards";
import FilterInputs from "components/filter/inputs/FilterInputs";
import SegmentFilterElement from "features/segment/segment-details/components/segmentFilter/SegmentFilterElement";
import { useEffect, useState } from "react";
import { Accordion, Modal, Row, Spinner } from "react-bootstrap";

const ViewFilterTypeModal = ({ show, selectedId, filterCategory }) => {
  const [input, setInput] = useState([]);
  useEffect(() => { setInput([]) }, [show])

  const { data, isLoading, isError, isSuccess } = Agent.FilterTypes.GET.ID(selectedId)

  const handleInput = (e) => { const filter = e?.filters; console.log(filter); setInput(filter) }
  const formatOutput = (_input) => {
    // Parse the input JSON string and extract its first element
    const parsedInput = JSON.parse(_input);
    const firstEntry = parsedInput?.[0];

    // Convert the first entry into an array of key-value pairs
    const entriesArray = Object.entries(firstEntry);

    // Filter the entries array to find the array-like entry
    const arrayLikeEntry = entriesArray.find(([key, value]) => Array.isArray(value));

    // Return the array-like entry found, if any
    return arrayLikeEntry;
  };
  if (input?.length) formatOutput(input)
  const values = [{ [data?.id]: input ?? [] }];
  return (
    <Row className="justify-content-center align-items-center p-0">
      {isError ? <span>Ett fel uppstod</span> :
        isLoading && <Spinner />}

      {isSuccess && data && <Accordion defaultActiveKey={1} flush >

        <Accordion.Item eventKey={1} >
          <Accordion.Header>
            <span className="bold">Input</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-column justify-conent-center gap-2 px-4">
              <FilterInputs filter={data} values={values} segment={{ filters: JSON.stringify(values) }} onChange={handleInput} setDynamicFilter={() => { }} />
              <code >
                {input?.length ? input : "[]"}
              </code>
            </div>
          </Accordion.Body>

        </Accordion.Item>
        <Accordion.Item eventKey={2}>
          <Accordion.Header>
            <span className="bold">Output</span>
          </Accordion.Header>
          <Accordion.Body  >
            <div className="d-flex flex-row row-cols-1 justify-content-center">
              {input?.length ?
                <CardContainer
                  h100
                  padding
                  children={
                    <div className="h-100 p-3" style={{ overflowY: 'auto' }}>
                      <SegmentFilterElement
                        index={0}
                        filter={formatOutput(input)}

                        filterCategory={{ ...filterCategory, filterTypes: [data] }}
                      />
                    </div>
                  }

                /> : "input saknas"
              }
            </div>
          </Accordion.Body>
        </Accordion.Item>


      </Accordion>}
    </Row>

  )
}
export default ViewFilterTypeModal
