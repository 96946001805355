import React, { useState } from "react";
import DraggableVariableTag from "./DraggableVariableTag";
import { Form, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getSmsLabels } from "features/campaign/campaign-details/api";

const VariableContainer = ({ activity }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: "smslabels",
    queryFn: () => getSmsLabels(activity.id),
    staleTime: 60 * 1000 * 5,
  });

  const [searchText, setSearchText] = useState("");
  const filteredVariables = data?.filter((variable) =>
    variable.label.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="variable-container">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Form.Control
            type="text"
            className="prevent-validation"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Sök variabler"
          />
          {filteredVariables.map((variable, index) => (
            <DraggableVariableTag
              id={variable.name}
              property={variable}
              key={index}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default VariableContainer;
