import { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { MdPreview } from "react-icons/md";
import { BsArrowRightCircle } from "react-icons/bs";
import { useQuery } from "react-query";
import { getAvailableMetaPages } from "features/campaign/api";
import ImagePickerModal from "./ImagePickerModal";
import LandingPageForm from "../../landingpage/LandingPageForm";
import Select from "react-select";
import { useEffect } from "react";
import { forwardRef } from "react";
import Agent from "api/Agent";
import LandingPageOffCanvas from "./LandingPageOffCanvas";
import PromptsDropdown from "../../Dropdowns/PromptsDropdown";

const EditLinkedinAd = forwardRef(
  (
    {
      campaign,
      activity,
      previewMutation,
      linkedinAdObject,
      setLinkedinAdObject,
      setBgBlur,
    },
    ref
  ) => {
    const chatMutation = Agent.OpenAI.POST.CHAT();
    //!EDIT TO LINKEDIN
    const { data } = useQuery("linkedin/availablePages", async () => {
      if (campaign) {
        const availablePages = await getAvailableMetaPages(activity.id);
        return availablePages.data;
      }
      return null;
    });

    //Set landingPageId-----------
    const [landingPageId, setLandingPageId] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const { data: lpTemplates } = Agent.LandingPages.GET.ALL();
    const { data: landingPage } = Agent.LandingPages.GET.ID(landingPageId);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
      if (selectedTemplate) {
        const template = lpTemplates[selectedTemplate - 1];
        setLandingPageId(template.id);
        setLinkedinAdObject({
          ...linkedinAdObject,
          landingPageID: template.id,
        });
        console.log({ template });
      } else if (activity?.ad?.landingPageID) {
        setLandingPageId(activity.ad.landingPageID);
      } else {
        setLandingPageId(null);
      }
    }, [selectedTemplate, activity]);

    useEffect(() => {
      if (landingPage) {
        setPreviewUrl(landingPage.previewUrl);
        setLinkedinAdObject({
          ...linkedinAdObject,
          adTargetUrl: landingPage.previewUrl,
        });
      } else {
        setPreviewUrl(null);
      }
    }, [landingPage]);

    //---------------------------------------------------------

    const [targetType, setTargetType] = useState(
      activity?.ad?.landingPageID ? 2 : 1
    );
    const [validated, setValidated] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    const [linkedinTemplate, setLinkedinTemplate] = useState(null);
    const [templateSelect, setTemplateSelect] = useState(null);
    const [sortedLinkedinTemplates, setSortedLinkedinTemplates] =
      useState(null);

    const {
      data: LinkedinTemplates,
      refetch: refetchTemplates,
      isRefetching: templatesIsRefetching,
      isLoading: templatesIsLoading,
    } = Agent.CampaignsActivity.GET.ADTEMPLATE(
      activity?.id,
      linkedinAdObject.metaPageId
    );

    useEffect(() => {
      if (linkedinAdObject.metaPageId) {
        refetchTemplates();
      }
    }, [linkedinAdObject.metaPageId]);

    useEffect(() => {
      if (LinkedinTemplates) {
        const sortedTemplates = LinkedinTemplates.map((obj, index) => ({
          label: obj.headline + ` - (${obj.createdAt})`,
          value: index + 1,
        }));

        setSortedLinkedinTemplates([...sortedTemplates]);
      }
    }, [LinkedinTemplates]);

    useEffect(() => {
      // if (!templateSelect) {
      //   setLinkedinAdObject({
      //     ...linkedinAdObject,
      //     adTitle: "",
      //     adTargetUrl: "",
      //     adDescription: "",
      //     // metaPageId: "",
      //   });
      //   return setLinkedinTemplate(null);
      // }

      const template = templateSelect && LinkedinTemplates[templateSelect - 1];
      setLinkedinTemplate(template);

      setLinkedinAdObject({
        ...linkedinAdObject,
        adTitle: template?.headline || linkedinAdObject?.adTitle || "",
        adTargetUrl:
          template?.landingPage || linkedinAdObject?.adTargetUrl || "",
        adDescription:
          template?.body ||
          template?.description ||
          linkedinAdObject?.adDescription ||
          "",
        // metaPageId: template?.object_story_spec?.page_id || "",
      });
    }, [templateSelect]);

    useEffect(() => {
      if (!selectedImage?.id) return;

      setLinkedinAdObject({ ...linkedinAdObject, imageId: selectedImage?.id });
    }, [selectedImage]);

    const onClickSubmit = (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      setValidated(true);
      if (!form.checkValidity()) {
        e.stopPropagation();
        return false;
      }

      const updatedCampaign = {
        ...activity,
        ad: {
          ...activity.ad,
          title: linkedinAdObject.adTitle,
          description: linkedinAdObject.adDescription,
          metaPageId: linkedinAdObject.metaPageId,
          targetUrl: linkedinAdObject.adTargetUrl,
          landingPageID: linkedinAdObject.landingPageID,
          adMedias: [
            {
              externalId: linkedinAdObject.imageId,
            },
          ],
        },
      };

      previewMutation.mutate(updatedCampaign);
    };

    const [show, setShow] = useState(false);

    const handleCloseLpOffcanvas = () => {
      setShow(false);
      setBgBlur(false);
    };

    const handleShowLpOffcanvas = () => {
      setShow(true);
      setBgBlur(true);
    };

    return (
      <>
        {show && (
          <LandingPageOffCanvas
            show={show}
            campaign={campaign}
            activity={activity}
            handleClose={handleCloseLpOffcanvas}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={onClickSubmit}
        >
          <Row className="mt-1  g-3">
            <Col>
              <Form.Label
                htmlFor="cta-text"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                * Välj LinkedIn sida
              </Form.Label>

              <Form.Select
                id="meta-pages"
                className="py-3 text-xl semi-bold"
                aria-label="Default select example"
                value={linkedinAdObject?.metaPageId}
                onChange={(e) =>
                  setLinkedinAdObject({
                    ...linkedinAdObject,
                    metaPageId: e.target.value,
                  })
                }
                required
              >
                <option value={""}>Välj din LinkedIn sida</option>
                {data?.map((page, index) => (
                  <option key={index} value={page?.id}>
                    {page?.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Ange en Linkedin sida.
              </Form.Control.Feedback>
            </Col>
            <Col className="col-12">
              <Form.Label
                htmlFor="campaignTemplate"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Annonsmall
              </Form.Label>
              {templatesIsLoading || templatesIsRefetching ? (
                <Col className="col-12">
                  <Spinner />
                </Col>
              ) : (
                <Select
                  onChange={(e) => setTemplateSelect(+e?.value)}
                  className="whiteBg text-xl semi-bold"
                  disabled={linkedinAdObject.metaPageId === null}
                  classNamePrefix="select"
                  isLoading={templatesIsLoading || templatesIsRefetching}
                  isSearchable={true}
                  placeholder="Välj en annonsmall"
                  name="select-ad-template"
                  options={
                    sortedLinkedinTemplates ? sortedLinkedinTemplates : []
                  }
                />
                // <Form.Select
                //   disabled={!linkedinAdObject.metaPageId}
                //   className="py-3 text-xl semi-bold"
                //   aria-label="Default select example"
                //   value={templateSelect || ""}
                //   onChange={(e) => setTemplateSelect(+e?.target?.value)}
                // >
                //   <option value={""}>Välj en annonsmall</option>
                //   {LinkedinTemplates?.map((_template, index) => (
                //     <option key={index} value={index + 1}>
                //       {_template?.headline}{" "}
                //       <span> ({_template?.createdAt})</span>
                //     </option>
                //   ))}
                // </Form.Select>
              )}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Form.Check
                inline
                label="Sätt Måladress"
                className="py-3 text-xl semi-bold"
                name="group1"
                type="radio"
                checked={targetType === 1}
                onChange={() => setTargetType(1)}
                value={1}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                className="py-3 text-xl semi-bold"
                label="Skapa Landningssida"
                name="group1"
                type="radio"
                checked={targetType === 2}
                onChange={() => setTargetType(2)}
                value={2}
              />
            </Col>
          </Row>

          <Row className="row-cols-1 mb-3">
            <Col>
              {targetType === 1 ? (
                <>
                  <Form.Label
                    htmlFor="targetadress"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    * Måladress
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://
                    </InputGroup.Text>
                    <Form.Control
                      className="py-3 text-xl semi-bold"
                      onChange={(e) =>
                        setLinkedinAdObject({
                          ...linkedinAdObject,
                          adTargetUrl: e.target.value,
                        })
                      }
                      type="text"
                      name="targetadress"
                      defaultValue={linkedinAdObject.adTargetUrl}
                      id="targetadress"
                      required
                      aria-describedby="basic-addon3"
                    />
                    <Form.Control.Feedback type="invalid">
                      Ange en måladress.
                    </Form.Control.Feedback>
                  </InputGroup>
                </>
              ) : (
                <Row className="align-items-center mt-3">
                  <Col className="col-6">
                    <Form.Select
                      className="py-3 text-xl semi-bold"
                      aria-label="Default select example"
                      value={selectedTemplate || ""}
                      onChange={(e) => setSelectedTemplate(+e?.target?.value)}
                    >
                      <option value={null}>Välj en landningssida</option>
                      {lpTemplates?.map((_template, index) => (
                        <option key={index} value={index + 1}>
                          {_template?.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col className="d-flex justify-content-end col-6">
                    <Button variant="success" onClick={handleShowLpOffcanvas}>
                      Skapa ny landningssida <BsArrowRightCircle />
                    </Button>
                  </Col>
                  {previewUrl && (
                    <Col className="mt-3">
                      <p
                        className="text-md"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Förhandsvisning:{" "}
                        <a
                          style={{
                            color: "#3ebee3",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                          href={previewUrl}
                        >
                          {previewUrl}
                        </a>
                      </p>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <h3 className=" text-lg semi-bold float-start me-auto">
              Annonstext
            </h3>
          </Row>
          <Row className="row-cols-1 g-2">
            <Col>
              <Form.Label
                htmlFor="ad-title"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                * Rubrik
              </Form.Label>
              <Form.Control
                className="py-3 text-xl semi-bold"
                onChange={(e) =>
                  setLinkedinAdObject({
                    ...linkedinAdObject,
                    adTitle: e.target.value,
                  })
                }
                as="textarea"
                rows={1}
                id="ad-title"
                name="ad-title"
                defaultValue={linkedinAdObject?.adTitle}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en rubrik.
              </Form.Control.Feedback>
            </Col>

            <Col>
              <div className="d-flex flex-row py-1 justify-content-between align-items-baseline">
                <Form.Label
                  htmlFor="ad-description"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  * Beskrivning
                  {/* <span className="text-sm">(max 75 tecken)</span> */}
                </Form.Label>
                <Col>
                  <PromptsDropdown
                    maxLength={600}
                    description={
                      ref?.current?.elements["ad-description"]?.value
                    }
                    setDescription={(_description) => {
                      setLinkedinAdObject({
                        ...linkedinAdObject,
                        adDescription: _description,
                      });
                      ref.current.elements["ad-description"].value =
                        _description;
                    }}
                    chatMutation={chatMutation}
                  />
                </Col>
              </div>

              <Form.Control
                className="py-3 text-xl semi-bold"
                onChange={(e) =>
                  setLinkedinAdObject({
                    ...linkedinAdObject,
                    adDescription: e.target.value,
                  })
                }
                defaultValue={linkedinAdObject?.adDescription}
                as="textarea"
                rows={3}
                id="ad-description"
                name="ad-description"
                // maxLength={75}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en beskrivning.
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Col className="mt-2">
            <h3 className=" text-lg semi-bold me-auto">Annonsbild</h3>
          </Col>

          <Row className="p-0 m-0">
            <ImagePickerModal
              imageHash={linkedinTemplate?.image || linkedinAdObject?.imageId}
              linkedinAdObject={linkedinAdObject}
              setLinkedinAdObject={setLinkedinAdObject}
              activityId={activity?.id}
              campaignId={campaign?.id}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
          </Row>
        </Form>
      </>
    );
  }
);

export default EditLinkedinAd;
