import Statistics from "../features/statistics/Statistics";
import Meta from "../features/meta/Meta";
import Linkedin from "../features/linkedin/Linkedin";
import GoogleAds from "../features/googleads/GoogleAds";
import TikTok from "../features/tiktok/TikTok";
import { CampaignOptions } from "../options";
import Email from "../features/email/Email";
import SMS from "../features/sms/SMS";
import LeadGeneration from "../features/leadgeneration/LeadGeneration";
import Overview from "../features/overview/Overview";
import DR from "../features/dr/DR";
import SegmentInfo from "../features/segment/SegmentInfo";
import SignUps from "../features/signups/SignUps";
import "../style.scss";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setSelectedChannel } from "features/campaign/campaignSlice";

const RenderChannels = ({ setBgBlur, campaign, selectedChannel }) => {
  try {
    const dispatch = useDispatch();

    const handleNavSelection = (selection) => {
      dispatch(setSelectedChannel(selection));
    };
    const channels = {
      [CampaignOptions.Overview]: (
        <Overview
          campaign={campaign}
          selectedChannel={handleNavSelection}
          activeChannels={campaign.campaignActivities}
        />
      ),
      [CampaignOptions.Statistics]: <Statistics campaign={campaign} />,
      [CampaignOptions.Segment]: <SegmentInfo campaign={campaign} />,
      [CampaignOptions.SignUps]: <SignUps campaign={campaign}  />,
      [CampaignOptions.Meta]: (
        <Meta
          campaign={campaign}
          setBgBlur={setBgBlur}
          activity={
            campaign.campaignActivities &&
            campaign.campaignActivities.find((activity) => activity.type === 0)
          }
        />
      ),
      [CampaignOptions.Linkedin]: (
        <Linkedin
          campaign={campaign}
          setBgBlur={setBgBlur}
          activity={
            campaign.campaignActivities &&
            campaign.campaignActivities.find((activity) => activity.type === 2)
          }
        />
      ),
      [CampaignOptions.GoogleAds]: (
        <GoogleAds
          campaign={campaign}
          activity={
            campaign.campaignActivities &&
            campaign.campaignActivities.find((activity) => activity.type === 1)
          }
        />
      ),
      [CampaignOptions.TikTok]: (
        <TikTok
          campaign={campaign}
          activity={
            campaign.campaignActivities &&
            campaign.campaignActivities.find((activity) => activity.type === 7)
          }
        />
      ),
      [CampaignOptions.Email]: <Email campaign={campaign} />,
      [CampaignOptions.SMS]: <SMS campaign={campaign} />,
      [CampaignOptions.LeadGeneration]: (
        <LeadGeneration
          activity={campaign.campaignActivities.find(
            (activity) => activity.type === 4
          )}
          campaign={campaign}
        />
      ),
      [CampaignOptions.DirectMarketing]: (
        <DR
          campaign={campaign}
          activity={campaign.campaignActivities.find(
            (activity) => activity.type === 6
          )}
        />
      ),
    };
    return channels[selectedChannel];
  } catch (err) {
    console.warn("There was a issue rendering channel activity \n", err);
    return (
      <Row className="d-flex justify-content-center align-items-top ">
        <div
          style={{ backgroundColor: "#C73E1D" }}
          className="col-10 col-md-6 mx-auto p-4 rounded mt-4"
        >
          <span className="text-center semi-bold text-lg text-white">
            Ett fel uppstod vid hämtning av aktivitet...
          </span>
        </div>
      </Row>
    );
  }
};

export default RenderChannels;
