import FilterPropBool from "./segmentFilter/filterProps/FilterPropBool";
import FilterPropText from "./segmentFilter/filterProps/FilterPropText";
import FilterPropMap from "./segmentFilter/filterProps/FilterPropMap";
import { Col, Row } from "react-bootstrap";
import FilterPropMultiRange from "./segmentFilter/filterProps/FilterPropMultiRange";
import { useMediaQuery } from "react-responsive";

const EditSegment = ({ filterType, filterCategory, filter }) => {

  const ifXsScreen = useMediaQuery({ query: "(max-width: 767px)" });

  const FILTER_TYPE = {
    MULTIRANGE: 2,
    BOOL: 5,
    MAP: 7,
  };
  const FilterTypes = {
    DEFAULT: <FilterPropText filter={filter} filterCategory={filterCategory} />,
    [FILTER_TYPE.BOOL]: (
      <FilterPropBool filter={filter} filterType={filterType} />
    ),
    [FILTER_TYPE.MULTIRANGE]: (
      <FilterPropMultiRange filter={filter} filterType={filterType} />
    ),
    [FILTER_TYPE.MAP]: (
      <FilterPropMap filter={filter} filterType={filterType}></FilterPropMap>
    ),
  };

  return (
    <Row className="px-3">
      {/* <Col className="col-5">
        <Row className="g-2">
          <Col className="col-12">
            <h6 className="display-xs" style={{ color: "#00000073" }}>
              Prioritering:{" "}
              <span style={{ color: "#00789e" }} className="bold">
                {filter.feature_importance}
              </span>
            </h6>
          </Col>
          <Col className="col-12">
            <h6 className="display-xs" style={{ color: "#00000073" }}>
              Kategori:{" "}
              <span style={{ color: "#00789e" }} className=" bold">
                {filter.filter_category}
              </span>
            </h6>
          </Col>
        </Row>
      </Col> */}

      <Col className="col-12">
        <Row className="g-2">
          <Col className="col-12 d-flex">
            <Col>
              <h6 className="display-xs" style={{ color: "#00000073" }}>
                <Row className=" g-1">
                  <Col className={ifXsScreen ? "text-sm col-1" : "col-1"} style={{ width: "fit-content" }}>
                    Filter:{" "}
                  </Col>
                  <Col className={ifXsScreen ? "text-sm" : ""} style={{ width: "fit-content" }} >
                    {FilterTypes[filterType?.type]
                      ? FilterTypes[filterType?.type]
                      : FilterTypes["DEFAULT"]}
                  </Col>
                </Row>
              </h6>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
    // </Col>
  );
};

export default EditSegment;
