import { useState, useEffect, createContext } from "react";
import { CampaignActivityStateOptions } from "../../options";
import DrCampaign from "./DrCampaign";
import DRWizard from "./wizard/DRWizard";

export const DRContext = createContext();

const DR = ({ campaign, activity, campaignIsLoading }) => {
  const isDrCreated = activity.state !== CampaignActivityStateOptions.Draft;

  return <DRContext.Provider value={{ campaign , activity }}>
    {
      isDrCreated ?
        <DrCampaign campaign={campaign} activity={activity} />
        :
        <DRWizard campaign={campaign} activity={activity} campaignIsLoading={campaignIsLoading} />
    }

  </DRContext.Provider>

};

export default DR;
