import {
  Offcanvas,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useState } from "react";
import { TextInput } from "components/forms";
import { FiPhone, FiUsers, FiMessageSquare, FiBell } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import ActivityItem from "./ActivityItem";
// import { updateaccount } from "features/accounts/api";
import { useMutation, useQueryClient } from "react-query";
import { ButtonMain } from "components/buttons";

const AddActivityOffCanvas = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const activityOptions = [
    {
      id: 0,
      name: "Telefonsamtal",
      icon: <FiPhone />,
    },
    {
      id: 1,
      name: "E-mail",
      icon: <MdMailOutline />,
    },
    {
      id: 2,
      name: "Möte",
      icon: <FiUsers />,
    },
    {
      id: 3,
      name: "Kommentar",
      icon: <FiMessageSquare />,
    },
    {
      id: 4,
      name: "Påminnelse",
      icon: <FiBell />,
    },
  ];

  //DatePicker
  const [startDate, setStartDate] = useState(new Date().toJSON().slice(0, 10));
  const [checkedId, setCheckedId] = useState(null);
  const [notes, setNotes] = useState("");

  const handleDateChange = (value) => {
    setStartDate(value);
  };

  // const queryClient = new useQueryClient();
  // const { mutate } = useMutation(
  //   (newActivity) => updateaccount({ id: props.accountId }, newActivity),
  //   {
  //     onMutate: () => {
  //       setLoading(true);
  //     },
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("accounts");
  //       queryClient.invalidateQueries("account");
  //       setLoading(false);
  //       props.handleClose();
  //     },
  //     onError: () => {
  //       setLoading(false);
  //       setErrorMsg(
  //         "Något gick fel... Testa att uppdatera accountets kontaktuppgifter till vänster och prova igen!"
  //       );
  //     },
  //   }
  // );

  // const onClickSubmit = () => {
  //   if (props.accountActivities.length === 0) {
  //     const updatedaccount = {
  //       ...props.account,
  //       jObject: null,
  //       activities: [{ type: checkedId, date: startDate, notes: notes }],
  //     };

  //     mutate(updatedaccount);
  //   } else {
  //     const updatedaccount = {
  //       ...props.account,
  //       jObject: null,
  //       activities: [
  //         ...props.accountActivities,
  //         { type: checkedId, date: startDate, notes: notes },
  //       ],
  //     };
  //     mutate(updatedaccount);

  //     console.log({ updatedaccount });
  //   }
  // };

  return (
    <Offcanvas
      className="account-activity-offcanvas"
      show={props.show}
      onHide={props.handleClose}
      placement="end"
    >
      <>
        <div>
          <Offcanvas.Header
            className="flex-row-reverse justify-content-end align-items-baseline"
            closeButton
          >
            <h2 className="display-md bold p-3">Lägg till aktivitet</h2>
          </Offcanvas.Header>
        </div>

        <Offcanvas.Body className="p-5">
          <Row className="gy-3">
            <p className="text-md regular" style={{ color: "#6A6D6A" }}>
              Typ
            </p>
            {activityOptions.map((activity) => (
              <ActivityItem
                {...activity}
                setCheckedId={setCheckedId}
                checkedId={checkedId}
              />
            ))}
            <p className="text-md regular" style={{ color: "#6A6D6A" }}>
              Datum
            </p>
            <Col className="col-12">
              <Form.Group controlId="exampleForm.ControlInput1">
                <InputGroup>
                  <Form.Control
                    onChange={(e) => handleDateChange(e.target.value)}
                    className="campaign-input"
                    placeholder={startDate}
                    value={startDate}
                    type="date"
                    defaultValue={startDate}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col className="col-12">
              <TextInput
                label="Anteckning"
                as="textarea"
                rows={3}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Col>
            <Col>
              <ButtonMain
                text={loading ? <Spinner /> : "Spara aktivitet"}
                variant="success"
                // onClick={onClickSubmit}
              />
              {errorMsg && (
                <p style={{ color: "#e67f7f", fontSize: "12px" }}>{errorMsg}</p>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </>
    </Offcanvas>
  );
};

export default AddActivityOffCanvas;
