

import { segmentStatuses_sv, segmentTypes_sv } from 'features/segment/segment-details/assets/config';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Dropdown, Form, AccordionButton, } from 'react-bootstrap';
import { MdFilterList } from 'react-icons/md';
import { FILTERTYPES_SV } from './FilterTypesTable';

const FilterDropdown = ({setFilter, filter }) => {
  const ref = useRef();
  const [activeCount, setActiveCount] = useState(!filter ? 0 : Object.values(filter)?.filter(x => x)?.length)
  const resetForm = () => {
    ref?.current?.reset();
    handleChange();
  }

  const getFilterState = () => {

    if (!ref?.current) return null;
    const formData = new FormData(ref?.current);
    const formDataArray = Array.from(formData?.entries());


    const enabledOptions = formDataArray?.filter(x => x?.length > 1 && !!x?.at(1))
    setActiveCount(enabledOptions?.length ?? 0)

    return formDataArray;
  }

  const handleChange = () => {
    const formDataArray = getFilterState();
    const type = formDataArray?.find(x => x?.at(0) === "type")?.at(1);

    if (typeof setFilter == "function") setFilter(prevFilter => ({
      ...prevFilter,
      type: type ? type : undefined,
    }));
    const segmentType = formDataArray?.find(x => x?.at(0) === "segmentType")?.at(1);
    if (typeof setFilter == "function") setFilter(prevFilter => ({
      ...prevFilter,
      segmentType: segmentType || undefined
    }));

    const isLocal = formDataArray?.find(x => x?.at(0) === "isLocal")?.at(1);
    if (typeof setFilter == "function") setFilter(prevFilter => ({
      ...prevFilter,
      isLocal: !isLocal ? undefined : isLocal?.includes(1)
    }));

    const isExclude = formDataArray?.find(x => x?.at(0) === "isExclude")?.at(1);
    if (typeof setFilter == "function") setFilter(prevFilter => ({
      ...prevFilter,
      isExclude: !isExclude ? undefined : isExclude?.includes(1)
    }));
  }

  useEffect(() => { getFilterState(); }, [ref]);




  return (
    <Dropdown >
      <Dropdown.Toggle variant='light' className='d-flex align-items-center px-4 py-2 gap-1 filter-button semi-bold text-md' >
        <MdFilterList />
        <span>Filter</span>
        {activeCount > 0 && <span className="filter-indicator">{activeCount}</span>}
      </Dropdown.Toggle>


      <Dropdown.Menu className='mt-2 rounded shadow '>
        <Form ref={ref} style={{ minWidth: "280px", maxWidth: "320px" }} className="d-flex flex-column justify-content-center align-content-center p-2" onInput={handleChange}>

          <TypeFilter  defaultValue={filter?.type}/>
          <SegmentTypeFilter defaultValue={filter?.segmentType}/>
          <IsLocalFilter  defaultValue={filter?.isLocal}/>
          <IsExclusiveFilter  defaultValue={filter?.isExclude}/>


        </Form>
        <AccordionButton type='button' style={{ borderTop: "2px solid gray" }} onClick={() => resetForm()} className="semi-bold ">Återställ Filter</AccordionButton>
      </Dropdown.Menu>
    </Dropdown >)
}

function SegmentTypeFilter({defaultValue}) {

  const options = Object.keys(segmentTypes_sv)?.map(key => {
    const { text } = segmentTypes_sv[key] ?? { text: undefined };
    return (
      <option key={text} value={key}>{text}</option>
    )
  });

  return <Form.Group className=' d-flex flex-column justify-content-start align-items-start gap-1 p-1'>
    <Form.Text>Typ av målgrupp</Form.Text>
    <Form.Select name='segmentType' defaultValue={defaultValue}>
      <option value="">Alla</option>
      {options}
    </Form.Select>
  </Form.Group>
}
function TypeFilter({defaultValue}) {

  const options = Object.keys(FILTERTYPES_SV)?.map(key => {
    const text = FILTERTYPES_SV[key] ?? { text: undefined };
    return (
      <option key={text} value={key}>{text}</option>
    )
  });

  return <Form.Group className=' d-flex flex-column justify-content-start align-items-start gap-1 p-1'>
    <Form.Text>Typ</Form.Text>
    <Form.Select name='type' defaultValue={defaultValue}>
      <option value="">Alla</option>
      {options}
    </Form.Select>
  </Form.Group>
}


function IsLocalFilter({defaultValue}) {
  return <Form.Group className=' d-flex flex-column justify-content-start align-items-start gap-1 p-1'>
    <Form.Text>IsLocal</Form.Text>
    <Form.Select name='isLocal' defaultValue={defaultValue}>
      <option value="">Alla</option>
      <option value={1}>Ja</option>
      <option value={2}>Nej</option>
    </Form.Select>
  </Form.Group>
}

function IsExclusiveFilter({defaultValue}) {
  return <Form.Group className=' d-flex flex-column justify-content-start align-items-start gap-1 p-1'>
    <Form.Text>isExclude</Form.Text>
    <Form.Select name='isExclude' defaultValue={defaultValue}>
      <option value="">Alla</option>
      <option value={1}>Ja</option>
      <option value={2}>Nej</option>
    </Form.Select>
  </Form.Group>
}
export default FilterDropdown
