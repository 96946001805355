import Agent from "api/Agent";
import { useState } from "react";
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row, Stack } from "react-bootstrap"
import { AiFillEdit } from "react-icons/ai";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";

const AppendPersonToSegment = ({ activity }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const AddUserToCampaignMutation = Agent.CampaignsActivity.PUT.AddUserToCampaign();
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    AddUserToCampaignMutation.reset();
    setValidated(false);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
      return false;
    }

    const email = form?.querySelector('[name="email"]')?.value;

    await toast.promise(
      AddUserToCampaignMutation.mutateAsync({ email: email, activity: activity },
        {
          onSuccess: ({ data }) => !!data ? toast.success("Person tillagd i kampanj") : toast.info("Personen gick inte att hitta/(lägga till)")
        }
      ),
      {
        pending: "Lägger till person...",
        error: "Ett oväntat fel uppstod"
      })

    if (AddUserToCampaignMutation.isSuccess && AddUserToCampaignMutation.data?.data) handleClose();
  };


  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <p className="regular text-center">Kontrollperson</p>

      <p style={{ color: "#545F61" }} className="flex-grow-1 text-center">
        Lägg till person utöver målgrupp
      </p>

      <Col role={"button"} className="flex-shrink-1 edit-col-icon" onClick={handleShow}>
        <BsPersonFillAdd color="green" />
      </Col>

      <Modal show={show} onHide={handleClose} centered>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Kontrollperson</Modal.Title>

          </Modal.Header>
          <Modal.Body>
            <Alert>
              <Stack>
                <span>
                  Email till facebook kontot finns under:
                </span>
                <span className="text-md bold text-black">Profil {'>'} Om {'>'} Kontaktuppgifter och allmän info</span>
                <span className="text-sm text-muted">standard inställning ger enbart synlighet för ägaren av profilen</span>
              </Stack>

            </Alert>
            <FloatingLabel
              controlId="floatingInput"
              label="Email till facebook konto"
              className="mb-3"
            >
              <Form.Control type="email" name="email" placeholder="name@example.com" required />
              <Form.Control.Feedback type="invalid">Ange en giltig email</Form.Control.Feedback>
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="secondary" onClick={handleClose}>
              Stäng
            </Button>
            <Button type="submit" variant="primary" disabled={AddUserToCampaignMutation.isLoading}>
              Spara
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Row>
  )
}

export default AppendPersonToSegment
