import { Row, Col, Form, Spinner } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useState } from "react";

const EditOptimizationGoal = ({
  activity,
  updateActivity,
  updateActivityLoading,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(
    activity?.optimizationGoal ? activity.optimizationGoal : 5
  );

  const updateOptimizationGoal = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/optimizationGoal",
        value: newValue,
      },
    ];
    updateActivity(patchObj);
    setShowInput(false);
  };
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{ minWidth: "170px" }}>
        <p>Optimeringsmål</p>
      </Col>

      {showInput ? (
        <Col className="flex-grow-1">
          <Form.Select
            id="optimizationGoal"
            aria-label="Default select example"
            defaultValue={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          >
            <option value={5}>Antal unika visningar</option>
            <option value={8}>Antal klick</option>
            <option value={13}>Antal visade annonser</option>
          </Form.Select>
        </Col>
      ) : (
        <Col className="flex-grow-1">
          {updateActivityLoading ? (
            <Spinner />
          ) : (
            <p className="text-center" style={{ color: "#545F61" }}>
              {activity?.optimizationGoal && activity.optimizationGoal === 2
                ? "Antal unika Visningar"
                : activity?.optimizationGoal && activity.optimizationGoal === 3
                ? "Antal klick"
                : "Antal visade annonser"}
            </p>
          )}
        </Col>
      )}

      {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {showInput ? (
        <Col
          className="flex-shrink-1 edit-col-icon"
          onClick={updateOptimizationGoal}
        >
          <FaSave color="green" />
        </Col>
      ) : (
        <Col
          className="flex-shrink-1 edit-col-icon"
          onClick={() => setShowInput(true)}
        >
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditOptimizationGoal;
