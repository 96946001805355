import { CampaignActivityStateOptions } from "features/campaign/campaign-details/options";
import moment from "moment";
import { useState } from "react";
import { useContext } from "react";
import { Button, Spinner } from "react-bootstrap"
import { BsArrowRight } from "react-icons/bs"
import { EmailContext } from "../../../Email";
import { DraftContext } from "../Draft";

const SaveFormButton = ({ formRef, setFormValidation, onSubmit, isLastStep, ifXsScreen }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { saveActivity } = useContext(DraftContext);
  const { activity } = useContext(EmailContext);

  const handleSaveFormAsync = async () => {
    setFormValidation(true);
    const form = formRef?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
    const data = new FormData(form);
    const updatedAdProps = {};
    const updatedActivityProps = {};
    for (let [key, value] of data.entries()) {
      if (key?.split("_") && key?.split("_")[0]?.includes("activity")) {
        updatedActivityProps[key?.split("_")[1]] = value;
      } else {
        updatedAdProps[key] = value;
      }
    }
    const updatedAd = { ...activity?.ad };

    Object.keys(updatedAdProps).forEach((key) => {
      if (updatedAd.hasOwnProperty(key)) {
        updatedAd[key] = updatedAdProps[key];

        if (key?.includes("startDate")) {
          const dateObj = moment(updatedAdProps[key], "YYYY-MM-DD");
          const timeObj = moment(updatedAdProps["startTime"], "HH:mm");

          const combinedObj = moment(dateObj).set({
            hour: timeObj.hour(),
            minute: timeObj.minute(),
          });
          updatedAd[key] = combinedObj.format("YYYY-MM-DDTHH:mm:ss");
        }
      }
    });

    const updatedActivity = { ...activity, ad: updatedAd };
    if (isLastStep)
      updatedActivity.state = CampaignActivityStateOptions.Preparing;

    Object.keys(updatedActivityProps)?.forEach((key) => {
      if (updatedActivity.hasOwnProperty(key)) {
        updatedActivity[key] = updatedActivityProps[key];
      }
    });
    await saveActivity(updatedActivity);
  };

  const handleClick = async () => {
    setLoading(true)
    setError(false)
    try {

      await onSubmit(handleSaveFormAsync)
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }

  const isLoading = !!saveActivity.isLoading || !!loading
  const isError = !!saveActivity.isError || !!error

  return (
    <Button
      variant={
        isError ? "danger" : "success"
      }
      size={ifXsScreen ? "sm" : ""}
      style={{ fontWeight: 600, padding: "8px 14px" }}
      className="d-flex align-items-center "
      onClick={
        () => handleClick()
      }
      disabled={isLoading}
    >
      {
        isLoading ?
          <Spinner style={{ width: "20px", height: "20px" }} />
          :
          isError ?
            <span className={ifXsScreen ? "text-white text-sm" : "text-white"}>
              Ett fel uppstod
            </span>
            :
            <span className={ifXsScreen ? "text-sm" : ""}>
            <p className="d-none d-sm-inline"> Spara & fortsätt </p> 
              <BsArrowRight size={ifXsScreen ? 10 : 20}  />
            </span>
      }
    </Button>
  )
}

export default SaveFormButton
