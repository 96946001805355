import Agent from "api/Agent";
import { Form } from "react-bootstrap";
import Select from "react-select"

export default function SelectCustomerId({ defaultId }) {

  const { data, isLoading, isError } = Agent.Customers.GET.ALL();
  const options = data?.map(customer => ({ label: customer?.name, value: customer?.id }))

  return <Form.Group >
    <Form.Label>Kund</Form.Label>
    <Select
      name="customerID"
      placeholder="Välj kund"
      isLoading={isLoading}
      isClearable
      defaultValue={options?.find(x => x?.value === defaultId)}
      options={
        options
      }

    />

  </Form.Group>
}
