import "./assets/style.scss";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { Table, Button } from "react-bootstrap";
import Agent from "api/Agent";
import { AiOutlineUserAdd } from "react-icons/ai";
import CaptionStatus from "./components/CaptionStatus";
import TableHeader from "./components/TableHeader";
import { FiEdit3 } from "react-icons/fi";
import TableActions from "./components/TableActions";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";
import moment from "moment";
import CustomerDetailsOffCanvas from "features/customers/components/customer-details/CustomerDetailsOffCanvas";
import NewUserModal from "./components/AddNewUser/NewUserModalCopy";

const CustomerTable = ({ rows, search, disablePaging }) => {
  const ifBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const ifMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [customerSelected, setCustomer] = useState(null);
  //Table search and pagination
  const rowsPerPage = rows ? rows : 8;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditCustomerSettings, setShowEditCustomerSettings] =
    useState(false);

  const onEditClick = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    setShowEditCustomerSettings(true);
  };

  const onAddClick = (event, customer) => {
    event.stopPropagation();
    setShowAddUser(true);
    setCustomer(customer);
  };

  const {
    data: customersResponse,
    isError,
    isLoading,
    isSuccess,
    error,
  } = Agent.Customers.GET.PAGINATED(currentPage, rowsPerPage, search);

  const customers = customersResponse?.data;
  const enablePaging =
    !disablePaging &&
    customersResponse?.paging &&
    customersResponse.paging?.total_pages > 1;

  let cells = [
    {
      header: "Kund",
      data: (customer) => customer?.name,
    },
    {
      header: "Skapad",
      data: (customer) => {
        if (!customer.createdOn) return "";
        const date = moment(customer?.createdOn);
        if (!date.isValid()) return "";
        return date.format("yyyy-MM-DD");
      },
    },
    {
      header: " ",
      data: (customer) => {
        return (
          <button
            style={{
              border: "0",
              background: "0",
              padding: "0 10px 0 0",
            }}
            onClick={(event) => onEditClick(event)}
          >
            <FiEdit3 size={18} />
          </button>
        );
      },
    },
    {
      header: " ",
      name: "adduser",
      data: (customer) => {
        return (
          <Button
            variant="success"
            size="sm"
            onClick={(event) => onAddClick(event, customer)}
          >
            <AiOutlineUserAdd size={15} />
          </Button>
        );
      },
    },

    // {
    //   header: " ",
    //   name: "tableActions",
    //   data: (customer) => (
    //     <>

    //     </>
    //     // <TableActions
    //     //   setCustomer={setCustomer}
    //     //   customerSelected={customerSelected}
    //     //   customer={customer}
    //     //   showNewUserModal={() => setShowNewUserModal(true)}
    //     // />
    //   ),
    // },
  ];

  if (!ifBigScreen) {
    cells?.splice(3, 5);
  }

  if (ifSmallScreen) {
    cells?.splice(1, 2);
  }

  return (
    <CardContainer
      style={{ padding: "0" }}
      children={
        <>
          <div className="table-responsive">
            <Table
              id="segmentTable"
              className="table-container"
              responsive
              hover
              size="lg"
            >
              <TableHeader columns={cells} />

              <tbody className="text-md regular">
                {isSuccess &&
                  customers?.map((customer) => (
                    <tr
                      key={customer?.id}
                      onClick={() => {
                        setCustomer(customer);
                        setShowCustomerDetails(true);
                      }}
                    >
                      {/* <td></td> */}
                      {cells?.map((cell, index) => (
                        <td key={cell?.header ?? index}>
                          {typeof cell?.data == "function" &&
                            cell?.data(customer)}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
              <CaptionStatus
                isError={isError}
                isLoading={isLoading}
                totalCount={customers?.length}
              />
            </Table>

            {enablePaging && (
              <PaginationBar
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                paging={customersResponse?.paging}
              />
            )}
          </div>
          <CustomerDetailsOffCanvas
            show={showCustomerDetails}
            setShow={setShowCustomerDetails}
            id={customerSelected?.id}
            data={customerSelected}
            dataName={customerSelected?.name}
          />
          <NewUserModal
            show={showAddUser}
            onClose={() => setShowAddUser(false)}
            customerId={customerSelected?.id}
          />
        </>
      }
    />
  );
};

export default CustomerTable;
