import React from "react";
import { Row } from "react-bootstrap";
import StatisticsTotalCardRow from "../components/StatisticsTotalCardRow";
import CampaignPerformanceChart from "../components/CampaignPerformanceChart";
import CampaignBudgetProgress from "../components/CampaignBudgetProgress";
import CampaignMetricChart from "../components/CampaignMetricChart";
import CampaignConversions from "../components/CampaignConversions";

export default function EmailStats({ campaignStatistic, activity }) {
  if (!campaignStatistic) return <></>;

  const total_stats_items = [
    {
      label: "Konverteringar",
      value: campaignStatistic.reduce((a, b) => +a + +b.conversions, 0),
    },
    {
      label: "Skickade Mail",
      value: campaignStatistic?.reduce((a, b) => +a + +b?.messagesSent, 0),
    },
    {
      label: "Öppnade Mail",
      value: campaignStatistic?.reduce((a, b) => +a + +b?.opens, 0),
    },
    {
      label: "CTR",
      value: campaignStatistic?.reduce((a, b) => +a + +b?.ctr, 0) / campaignStatistic?.length,
      unit: "%"
    },
    {
      label: "Spenderad Budget",
      value: campaignStatistic?.reduce((a, b) => +a + +b?.budgetSpent, 0),
      unit: "kr"
    },
    {
      label: "Värde",
      value: campaignStatistic?.reduce((a, b) => +a + +b?.conversionValue, 0),
      unit: "kr"
    },

  ]

  return (
    <Row className="gap-4 p-0 m-0">
      <StatisticsTotalCardRow items={total_stats_items} />

      <Row className="row-cols-1 gy-1 p-0 m-0">
        <CampaignPerformanceChart statistics={campaignStatistic} />
      </Row>
      <Row className="row-cols-1 row-cols-xl-2 gy-1 p-0 m-0">
        <CampaignMetricChart statistics={campaignStatistic} />

        <CampaignBudgetProgress statistics={campaignStatistic} />

        
      </Row>

      <Row className="m-0 p-0 ">

        <CampaignConversions activity={activity}/>
      </Row>
    </Row>
  );
}


