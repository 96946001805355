import { Row, Col, Container, Button } from "react-bootstrap";
import ListsTable from "features/customLists/my-lists/ListsTable";
import { MdUpload } from "react-icons/md";
import { useState } from "react";
import UploadList from "features/customLists/upload-list/UploadList";


const Lists = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);



  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className="justify-content-between">
            <Col>
              <h2>Mina Spärrlistor</h2>
            </Col>
            <Col className="col-lg-4 col-md-5 d-flex justify-content-end">
              <Button
                variant="success"
                onClick={() => setShowOffCanvas(true)}
                className="semi-bold"
              >
                <MdUpload size={25} /> Ladda upp lista
              </Button>
              <UploadList show={showOffCanvas} setShow={setShowOffCanvas} />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <ListsTable />
        </Col>
      </Row>
    </Container>
  );
};

export default Lists;
