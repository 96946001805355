import { BarChart } from "components/charts";
import { Col, Spinner } from "react-bootstrap";
import { getLeadStats } from "../api";
import { useQuery } from "react-query";

const LeadsStats = () => {
  // const options = {
  //   scales: {
  //     x: {
  //       stacked: true,
  //     },
  //     y: {
  //       beginAtZero: true,
  //     },
  //     xAxes: [{ stacked: true }],
  //     yAxes: [
  //       {
  //         stacked: false,
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // };
  const {
    data: leads,
    isError,
    isLoading,
    error,
  } = useQuery("leadsStats", getLeadStats);

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return <p>{error.message}</p>
  }

  const data = {
    labels: [...leads?.data.labels.map((label) => label)], // use date_from property as chart labels
    datasets: [
      {
        label: "Hög ranking (60%+)",
        data: [...leads?.data.datasets[2].data.map((data) => data)], // use turnover property as chart values
        backgroundColor: "#017397",
        borderColor: "rgba(54, 162, 235, 0)",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.2,
        categoryPercentage: 1.0,
        // barThickness: "flex",
        pointStyle: "circle", // set the shape of the markers to circles
        pointRadius: 5, // set the size of the circles to 5 pixels
      },

      {
        label: "Medel ranking (30-60%)",
        data: [...leads?.data.datasets[1].data.map((data) => data)], // use turnover property as chart values
        backgroundColor: "#3EBEE3",
        borderColor: "rgba(54, 162, 235, 0)",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.35,
        categoryPercentage: 1.0,
        // barThickness: "flex",
        pointStyle: "circle", // set the shape of the markers to circles
        pointRadius: 5, // set the size of the circles to 5 pixels
      },
      {
        label: "Låg Ranking (0-29%)",
        data: [...leads?.data.datasets[0].data.map((data) => data)], // use turnover property as chart values
        backgroundColor: "#8BD8EE",
        borderColor: "rgba(54, 162, 235, 0)",
        borderRadius: 10,
        borderWidth: 1,
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        // barThickness: "flex",
        pointStyle: "circle", // set the shape of the markers to circles
        pointRadius: 5, // set the size of the circles to 5 pixels
      },
    ],
  };
  return (
    <Col style={{ height: "100%" }}>
      <BarChart data={data} />
    </Col>
  );
};

export default LeadsStats;
