import { useState, useEffect } from "react";
import { Col, Row, InputGroup, Form } from "react-bootstrap";

const FilterTextInput = ({ filter, segment, parsedFilters, mutate }) => {
  const getDefaultValues = (filters, filterId) => {
    const foundfilter = filters?.find((f) => f[filterId]);

    if (foundfilter) {
      const values = foundfilter[filterId];
      if (values && values.length > 0) {
        return values[0];
      }
    }
    return filter.name;
  };

  const defaultValues = getDefaultValues(
    segment && JSON.parse(segment?.filters),
    filter.id
  );

  const [text, setText] = useState(defaultValues ? defaultValues.value : "");
  const [selectedOperator, setSelectedOperator] = useState(
    defaultValues ? defaultValues.op : 1
  );

  const handleChange = (value) => {
    setText(value);
  };

  useEffect(() => {
    const filterIndex =
      segment && JSON.parse(segment?.filters).findIndex((f) => f[filter.id]);

    if (selectedOperator === 11 || selectedOperator === 12) {
      setText("");
    }

    // if (text || text === "") {
    if (filterIndex === -1) {
      // create a new filter with the selected value
      const updatedSegment = {
        ...segment,
        filters:
          parsedFilters &&
          JSON.stringify([
            ...parsedFilters,
            { [filter.id]: [{ op: selectedOperator, value: text }] },
          ]),
      };
      mutate(updatedSegment);
    } else {
      // update the existing filter with the selected value
      const updatedFilter = {
        [filter.id]: [
          {
            op: selectedOperator,
            value: text,
          },
        ],
      };
      const newFilters = parsedFilters && [...parsedFilters];
      if (newFilters) {
        newFilters[filterIndex] = updatedFilter;
        mutate({ ...segment, filters: JSON.stringify(newFilters) });
      }
    }
  }, [text, selectedOperator]);

  //Set default values for AsyncSelect based on segment.filters--------------

  return (
    <Form.Group controlId="exampleForm.ControlInput10">
      <Row>
        <Col className="col-12">
          <Row>
            <Col className="col-4">
              <Form.Select
                className="campaign-input"
                style={{ wordSpacing: 0 }}
                defaultValue={
                  defaultValues ? defaultValues.op : selectedOperator
                }
                onChange={(e) => setSelectedOperator(Number(e.target.value))}
              >
                <option value={1}>Är lika med</option>
                <option value={8}>Är inte lika med</option>
                <option value={10}>Innehåller</option>
                <option value={11}>Innehåller inte data</option>
                <option value={12}>Innehåller data</option>
              </Form.Select>
            </Col>
            <Col>
              <InputGroup>
                <Form.Control
                  onChange={(e) => handleChange(e.target.value)}
                  disabled={selectedOperator === 11 || selectedOperator === 12}
                  className="campaign-input"
                  placeholder={
                    defaultValues ? defaultValues.value : filter?.name
                  }
                  defaultValue={
                    defaultValues && defaultValues.value === filter?.name
                      ? null
                      : defaultValues.value
                  }
                  type="text"
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default FilterTextInput;
