import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from "./style.module.css";

export default function NavBar({ setCurrent, current, options }) {
  return (
    // <Row className="d-flex flex-row justify-content-start p-0 m-0">
    //
    //
    //   <Row className="row-cols-1 row-cols-md-2 flex-wrap justify-content-evenly p-0 m-0 " >
    //     <div className="d-flex flex-row row-cols-auto flex-wrap justify-items-center" style={{ borderBottom: '3px solid rgb(243 243 243)', borderRight: '2px solid rgb(243 243 243)', flexGrow: 1 }}>
    //       {collections?.map((collection, index) => (
    //         <div
    //
    //           key={index}
    //           role={!disabled ? 'button' : ''}
    //           onClick={() => !disabled && handleSelection(collection)}
    //           style={
    //
    //             { minWidth: "fit-content", flexGrow: 1, }
    //           }
    //           tabIndex={index}
    //           className={`text-center text-md semi-bold px-1  py-2 ${activeChannel === collection ? styles.activeNavItem : ''}`}
    //         >
    //           <span>{collection}</span>
    //         </div>
    //       ))}
    //     </div>

    // text-center text-md semi-bold py-2 ` + styles.navItem


    <Row className="d-flex flex-row justify-content-start p-0 m-0">
      <Row className=" row-cols-auto flex-wrap justify-content-evenly p-0 m-0 ">
        {options?.map((option, index) =>
          <div

            key={index}
            role="button"
            style={

              { minWidth: "fit-content", flexGrow: 1, }
            }
            tabIndex={index}
            className={`text-center text-md semi-bold px-1  py-2 ${current === option ? styles.activeNavItem : ''}`}
            onClick={() => setCurrent(option)}
          >
            <span
            >
              {option}
            </span>
          </div>
        )}



      </Row>
    </Row >
  )
}
