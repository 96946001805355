import axios from "api/axios";
import { endPoints } from "api/endPoints";

export const getPreview = async (activityID) => {
  console.log({ activityID });
  const response = await axios.get(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityID}/previews`
  );
  return response.data;
};

export const putPreview = async (activityID, adObject) => {
  const response = await axios.put(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityID}/previews`,
    adObject
  );
  return response.data;
};

export const putCampaign = async (campaign) => {
  const response = await axios.put(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${campaign.id}`,
    campaign
  );
  return response.data;
};

export const putCampaignActivity = async (campaignActivity) => {
  const response = await axios.put(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${campaignActivity.campaignID}/activity/${campaignActivity.id}`,
    campaignActivity
  );
  return response.data;
};

//Activity Patches
export const patchUpdateCampaignActivity = async (id, obj) => {
  const response = await axios.patch(
    `${endPoints.campaigns.CAMPAIGNS_URL}/activity/${id}`,
    obj
  );
  return response.data;
};

export const patchUpdateCampaignActivityAd = async (id, obj) => {
  const response = await axios.patch(
    `${endPoints.campaigns.CAMPAIGNS_URL}/activityad/${id}`,
    obj
  );
  return response.data;
};
//---------------------

export const uploadNewAdImage = async (activityId, file, accountId) => {
  const queryParams = [accountId && `adAccountId=${accountId}`]?.filter(
    (x) => x
  );

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityId}/availableImages${queryString}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct Content-Type header
      },
    }
  );

  return response.data;
};

export const getAvailableImages = async (activityId, accountId) => {
  const response = await axios.get(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityId}/availableImages?adAccountId=${accountId}`
  );
  return response.data;
};

export const createMarketingList = async ({ activityId }) => {
  const response = await axios.post(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityId}/createmarketinglist`
  );
  return response.data;
};

export const downloadDrList = async (id) => {
  const response = await axios.get(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${id}/drdownloaduri`
  );
  return response.data;
};

export const getSmsLabels = async (activityId) => {
  const response = await axios.get(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityId}/smslabels`
  );
  return response.data;
};

export const getExternalStatuses = async (activityID) => {
  const response = await axios.get(
    `${endPoints.campaigns.CAMPAIGNS_URL}/${activityID}/externalstatuses`
  );
  return response.data;
};
