import { Button, Modal, Row, Stack } from "react-bootstrap";
import { useState } from "react";
import Agent from "api/Agent";
import GenericSettings from "./GenericSettings";
import { createContext } from "react";
import TemplateContainer from "./TemplateContainer";
import TemplateFilters from "./TemplateFilters";


export const TemplateModalContext = createContext(null);

const TemplateModal = ({ setTemplate, size}) => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [search, setSearch] = useState(null)
  const [rename, setRename] = useState(false);
  const [removable, setRemovable] = useState(false);
  const [generic, setGeneric] = useState(true)

  const handleClose = () => {
    setShowTemplateModal(!showTemplateModal);
  }

  return (<TemplateModalContext.Provider
    value={{
      search: search,
      setSearch: setSearch,
      rename: rename,
      setRename: setRename,
      removable: removable,
      setRemovable: setRemovable,
      generic: generic,
      setGeneric: setGeneric,

    }}>
    <Button
      variant="outline-secondary"
      // style={{ fontWeight: 600, padding: "8px 14px" }}
      onClick={() => { setShowTemplateModal(true) }}
      size={size}
      
    >
      Visa mallar
    </Button>

    <Modal show={showTemplateModal} onHide={() => setShowTemplateModal(false)} size="lg" fullscreen="sm-down" centered >
      <Modal.Header closeButton className="d-flex align-items-baseline px-4">
        <Modal.Title >
          <h4 className="display-sm semi-bold">Välj ditt utskick</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0 mb-0">
        <Row className="justify-content-between row-cols-1 align-content-between py-1  mx-auto " style={{ paddingBottom: "80px" }}>
          <Stack className="d-flex justify-content-center p-3" style={{ maxWidth: '1600px' }}>
            <TemplateFilters />

            <TemplateContainer setTemplate={setTemplate} handleCloseModal={handleClose} />

            {generic &&
              <GenericSettings />
            }
          </Stack>


        </Row>
      </Modal.Body>
    </Modal>
  </TemplateModalContext.Provider>
  );
}

export default TemplateModal

