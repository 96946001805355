import { useState } from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
  SplitButton,
} from "react-bootstrap";
import { BsFilePlus, BsArrowRightCircle } from "react-icons/bs";
import { useQuery } from "react-query";
import { getAvailableMetaPages } from "features/campaign/api";
import ImagePickerModal from "./ImagePickerModal";
import LandingPageForm from "../../landingpage/LandingPageForm";
import { useEffect } from "react";
import { forwardRef } from "react";
import Agent from "api/Agent";
import LandingPageOffCanvas from "./LandingPageOffCanvas";
import PromptsDropdown from "../../Dropdowns/PromptsDropdown";
import { toast } from "react-toastify";

const EditMetaAd = forwardRef(
  (
    {
      campaign,
      activity,
      previewMutation,
      metaAdObject,
      setMetaAdObject,
      setBgBlur,
    },
    ref
  ) => {
    const chatMutation = Agent.OpenAI.POST.CHAT();

    //Set landingPageId-----------
    const [landingPageId, setLandingPageId] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const { data: lpTemplates } = Agent.LandingPages.GET.ALL();
    const { data: landingPage } = Agent.LandingPages.GET.ID(landingPageId);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
      if (selectedTemplate) {
        const template = lpTemplates[selectedTemplate - 1];
        setLandingPageId(template.id);
        setMetaAdObject({
          ...metaAdObject,
          landingPageID: template.id,
        });
        console.log({ template });
      } else if (activity?.ad?.landingPageID) {
        setLandingPageId(activity.ad.landingPageID);
      } else {
        setLandingPageId(null);
      }
    }, [selectedTemplate, activity]);

    useEffect(() => {
      if (landingPage) {
        setPreviewUrl(landingPage.previewUrl);
        setMetaAdObject({
          ...metaAdObject,
          adTargetUrl: landingPage.previewUrl,
        });
      } else {
        setPreviewUrl(null);
      }
    }, [landingPage]);

    //---------------------------------------------------------

    const [targetType, setTargetType] = useState(
      activity?.ad?.landingPageID ? 2 : 1
    );
    const [validated, setValidated] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [metaTemplate, setMetaTemplate] = useState(null);
    const [templateSelect, setTemplateSelect] = useState(null);
    const [sortedMetaTemplates, setSortedMetaTemplates] = useState(null);

    const {
      data: metaTemplates,
      refetch: refetchTemplates,
      isRefetching: templatesIsRefetching,
      isLoading: templatesIsLoading,
    } = Agent.CampaignsActivity.GET.ADTEMPLATE(
      activity?.id,
      metaAdObject.adAccountId
    );

    useEffect(() => {
      if (metaTemplates) {
        const sortedTemplates = metaTemplates.map((obj, index) => ({
          label: obj.title,
          value: index + 1,
        }));

        setSortedMetaTemplates([...sortedTemplates]);
      }
    }, [metaTemplates]);

    useEffect(() => {
      if (!templateSelect) {
        setMetaAdObject({
          ...metaAdObject,
          adTitle: "",
          adTargetUrl: "",
          adDescription: "",
          // metaPageId: "",
        });
        return setMetaTemplate(null);
      }

      const template = metaTemplates[templateSelect - 1];
      setMetaTemplate(template);

      setMetaAdObject({
        ...metaAdObject,
        adTitle: template?.title || "",
        adTargetUrl: template?.object_story_spec?.link_data?.link || "",
        adDescription:
          template?.body ||
          template?.object_story_spec?.link_data?.description ||
          "",
        // metaPageId: template?.object_story_spec?.page_id || "",
      });
    }, [templateSelect]);

    console.log({ metaTemplates });

    useEffect(() => {
      if (!selectedImage?.id) return;

      setMetaAdObject({ ...metaAdObject, imageId: selectedImage?.id });
    }, [selectedImage]);

    const onClickSubmit = (e) => {
      e.preventDefault();
      const form = e.currentTarget;
      setValidated(true);
      if (!form.checkValidity()) {
        e.stopPropagation();
        return false;
      }

      const updatedCampaign = {
        ...activity,
        ad: {
          ...activity.ad,
          title: metaAdObject.adTitle,
          description: metaAdObject.adDescription,
          metaPageId: metaAdObject.metaPageId,
          adAccountId: metaAdObject.adAccountId,
          targetUrl: metaAdObject.adTargetUrl,
          landingPageID: metaAdObject.landingPageID,
          adMedias: [
            {
              imageId: metaAdObject.imageId,
            },
          ],
        },
      };

      previewMutation.mutate(updatedCampaign);
    };

    const [show, setShow] = useState(false);

    const handleCloseLpOffcanvas = () => {
      setShow(false);
      setBgBlur(false);
    };

    const handleShowLpOffcanvas = () => {
      setShow(true);
      setBgBlur(true);
    };

    return (
      <>
        {show && (
          <LandingPageOffCanvas
            show={show}
            campaign={campaign}
            activity={activity}
            handleClose={handleCloseLpOffcanvas}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        <Form
          ref={ref}
          noValidate
          validated={validated}
          onSubmit={onClickSubmit}
        >
          <Row className="mt-1 g-3">
            <Col>
              <Form.Label
                htmlFor="campaignTemplate"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Annonsmall
              </Form.Label>
              {templatesIsLoading || templatesIsRefetching ? (
                <Col className="col-12">
                  <Spinner />
                </Col>
              ) : (
                <Select
                  onChange={(e) => setTemplateSelect(+e?.value)}
                  className="whiteBg py-3 text-xl semi-bold"
                  disabled={!metaAdObject.adAccountId}
                  classNamePrefix="select"
                  isLoading={templatesIsLoading || templatesIsRefetching}
                  isSearchable={true}
                  placeholder="Välj en annonsmall"
                  name="select-ad-template"
                  options={sortedMetaTemplates}
                />
                // <Form.Select
                //   disabled={!metaAdObject.adAccountId}
                //   className="py-3 text-xl semi-bold"
                //   aria-label="Default select example"
                //   value={templateSelect || ""}
                //   onChange={(e) => setTemplateSelect(+e?.target?.value)}
                // >
                //   <option value={""}>Välj en annonsmall</option>
                //   {metaTemplates?.map((_template, index) => (
                //     <option key={index} value={index + 1}>
                //       {_template?.title}
                //     </option>
                //   ))}
                // </Form.Select>
              )}
            </Col>
            <hr />
          </Row>
          {/* //!RADIOCHECK FÖR LANDNINGSSIDA */}
          <Row>
            <Col>
              <Form.Check
                disabled={!metaAdObject.metaPageId || !metaAdObject.adAccountId}
                inline
                className="py-3 text-xl semi-bold"
                label="Sätt Måladress"
                name="group1"
                type="radio"
                checked={targetType === 1}
                onChange={() => setTargetType(1)}
                value={1}
              />
            </Col>
            <Col>
              <Form.Check
                disabled={!metaAdObject.metaPageId || !metaAdObject.adAccountId}
                inline
                className="py-3 text-xl semi-bold"
                label="Skapa Landningssida"
                name="group1"
                type="radio"
                checked={targetType === 2}
                onChange={() => setTargetType(2)}
                value={2}
              />
            </Col>
          </Row>

          <Row className="row-cols-1 mb-3">
            <Col>
              {targetType === 1 ? (
                <>
                  <Form.Label
                    htmlFor="targetadress"
                    className="p-0 text-md"
                    style={{ color: "#344054" }}
                  >
                    Måladress
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://
                    </InputGroup.Text>
                    <Form.Control
                      className="py-3 text-xl semi-bold"
                      placeholder="www.minhemsida.se"
                      disabled={
                        !metaAdObject.metaPageId || !metaAdObject.adAccountId
                      }
                      onChange={(e) =>
                        setMetaAdObject({
                          ...metaAdObject,
                          adTargetUrl: e.target.value,
                        })
                      }
                      type="text"
                      name="targetadress"
                      defaultValue={metaAdObject.adTargetUrl}
                      id="targetadress"
                      required
                      aria-describedby="basic-addon3"
                    />
                    <Form.Control.Feedback type="invalid">
                      Ange en måladress.
                    </Form.Control.Feedback>
                  </InputGroup>
                </>
              ) : (
                <Row className="align-items-center mt-3">
                  <Col className="col-6">
                    <Form.Select
                      disabled={
                        !metaAdObject.metaPageId || !metaAdObject.adAccountId
                      }
                      className="py-3 text-xl semi-bold"
                      aria-label="Default select example"
                      value={selectedTemplate || ""}
                      onChange={(e) => setSelectedTemplate(+e?.target?.value)}
                    >
                      <option value={null}>Välj en landningssida</option>
                      {lpTemplates?.map((_template, index) => (
                        <option key={index} value={index + 1}>
                          {_template?.name ? _template.name : _template?.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col className="d-flex justify-content-end col-6">
                    <Button variant="success" onClick={handleShowLpOffcanvas}>
                      Skapa ny landningssida <BsArrowRightCircle />
                    </Button>
                  </Col>
                  {previewUrl && (
                    <Col className="mt-3">
                      <p
                        className="text-md"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Förhandsvisning:{" "}
                        <a
                          style={{
                            color: "#3ebee3",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                          href={previewUrl}
                        >
                          {previewUrl}
                        </a>
                      </p>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <h3 className=" text-lg semi-bold float-start me-auto">
              Annonstext
            </h3>
          </Row>
          <Row className="row-cols-1">
            <Col>
              <Form.Label
                htmlFor="ad-title"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Rubrik
              </Form.Label>
              <Form.Control
                placeholder="Min rubrik..."
                className="py-3 text-xl semi-bold"
                disabled={!metaAdObject.metaPageId || !metaAdObject.adAccountId}
                onChange={(e) =>
                  setMetaAdObject({
                    ...metaAdObject,
                    adTitle: e.target.value,
                  })
                }
                as="textarea"
                rows={1}
                id="ad-title"
                name="ad-title"
                defaultValue={metaAdObject?.adTitle}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en rubrik.
              </Form.Control.Feedback>
            </Col>

            <Col>
              <div className="d-flex flex-row py-1 justify-content-between align-items-baseline">
                <Form.Label
                  htmlFor="ad-description"
                  className="p-0 text-md col"
                  style={{ color: "#344054" }}
                >
                  Beskrivning
                </Form.Label>
                <Col>
                  <PromptsDropdown
                    maxLength={300}
                    description={
                      ref?.current?.elements["ad-description"]?.value
                    }
                    setDescription={(_description) => {
                      setMetaAdObject({
                        ...metaAdObject,
                        adDescription: _description,
                      });
                      ref.current.elements["ad-description"].value =
                        _description;
                    }}
                    chatMutation={chatMutation}
                  />
                </Col>
              </div>
              <Form.Control
                className="py-3 text-xl semi-bold"
                placeholder="Min beskrivning..."
                disabled={
                  !metaAdObject.metaPageId ||
                  !metaAdObject.adAccountId ||
                  chatMutation?.isLoading
                }
                onChange={(e) =>
                  setMetaAdObject({
                    ...metaAdObject,
                    adDescription: e.target.value,
                  })
                }
                defaultValue={metaAdObject?.adDescription}
                as="textarea"
                rows={3}
                id="ad-description"
                name="ad-description"
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en beskrivning.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="d-flex flex-row justify-content-end"></Row>
          <Col className="mt-2">
            <h3 className=" text-lg semi-bold me-auto">Annonsbild</h3>
          </Col>

          <Row className="p-0 m-0 g-4">
            <ImagePickerModal
              imageHash={
                metaTemplate?.object_story_spec?.link_data?.image_hash ||
                metaAdObject?.imageId
              }
              offerId={campaign?.offerID}
              metaAdObject={metaAdObject}
              setMetaAdObject={setMetaAdObject}
              activityId={activity?.id}
              campaignId={campaign?.id}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
            {/* <Col className="col-12 m-0 p-0">
              <Form.Label
                htmlFor="cta-text"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Välj Meta sida
              </Form.Label>

              <Form.Select
                id="meta-pages"
                aria-label="Default select example"
                value={metaAdObject.adType}
                onChange={(e) =>
                  setMetaAdObject({
                    ...metaAdObject,
                    adType: e.target.value,
                  })
                }
                required
              >
                <option value={0}>En bild/video</option>
                <option value={1}>Bildsamling</option>
                <option value={2}>Karusell</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Ange en annonstyp.
              </Form.Control.Feedback>
            </Col> */}
            {/* {metaAdObject.adType === "0" ? (
              <ImagePickerModal
                imageHash={
                  metaTemplate?.object_story_spec?.link_data?.image_hash ||
                  metaAdObject?.imageId
                }
                metaAdObject={metaAdObject}
                setMetaAdObject={setMetaAdObject}
                activityId={activity?.id}
                campaignId={campaign?.id}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
              />
            ) : (
              <>
                <ImagePickerModal
                  imageHash={
                    metaTemplate?.object_story_spec?.link_data?.image_hash ||
                    metaAdObject?.imageId
                  }
                  metaAdObject={metaAdObject}
                  setMetaAdObject={setMetaAdObject}
                  activityId={activity?.id}
                  campaignId={campaign?.id}
                  setSelectedImage={setSelectedImage}
                  selectedImage={selectedImage}
                />
                <ImagePickerModal
                  imageHash={metaAdObject?.imageId2}
                  metaAdObject={metaAdObject}
                  setMetaAdObject={setMetaAdObject}
                  activityId={activity?.id}
                  campaignId={campaign?.id}
                  setSelectedImage={setSelectedImage2}
                  selectedImage={selectedImage2}
                />
                <Col className="mx-auto mb-2">
                  <div className="d-flex justify-content-center h-100 position-relative">
                    <span
                      style={{ color: "#3ebee3" }}
                      className="semi-bold image-picker-container"
                    >
                      <BsFilePlus size={50} /> <br />
                      <p className="text-lg">Lägg till</p>
                    </span>
                  </div>
                </Col>
              </>
            )} */}
          </Row>
        </Form>
      </>
    );
  }
);
EditMetaAd.displayName = "EditMetaAd";

export default EditMetaAd;
