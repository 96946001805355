import { LeadsTable } from "features/leads";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { setLeadType } from "features/leads/leadSlice";
import { useMediaQuery } from 'react-responsive';
import {
  Row,
  Col,
  Container,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";

const MyLeads = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const leadType = useSelector((state) => state.lead.leadType);

  const radios = [
    { name: "Företag", value: 0 },
    { name: "Personer", value: 1 },
  ];

  const queryClient = useQueryClient();
  const onClickSwitch = (e) => {
    dispatch(setLeadType(Number(e.currentTarget.value)));
    console.log({ leadType });
  };

  return (
    <Container className="py-5">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className={`justify-content-between ${isSmallScreen ? 'flex-column' : ''}`}>
            <Col style={{marginTop: isSmallScreen ? 15 : ''}}>
              <h2>Mina Leads</h2>
            </Col>
            <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
              <ButtonGroup>
                {radios.map((radio, i) => (
                  <ToggleButton
                    className="dark-toggle-button"
                    key={i}
                    id={i}
                    type="radio"
                    variant={"outline-secondary"}
                    value={radio.value?.toString()}
                    checked={leadType.toString() === radio.value?.toString()}
                    onChange={(e) => onClickSwitch(e)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <LeadsTable />
        </Col>
      </Row>
    </Container>
  );
};

export default MyLeads;
