import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";
import axios from "api/axios";

export const OpenAI = {
  POST: {
    CHAT: () => {

      const queryClient = useQueryClient();
      return useMutation(async ({ message, promptId, maxLength}) => {
        const res = await axios.post(`openAi/v1/chat`, {message : message, promptID : promptId, maxLength: +maxLength});
        return res?.data;
      }, {
        onSuccess: () => {
          // queryClient.invalidateQueries();
        },
        onError: (error) => {
          console.warn(`Failed to POST Chat \n Error: `, error);
        },
      })
    },
  },
};
