import Agent from "api/Agent"
import { Button, Dropdown, Image } from "react-bootstrap"
import { MdRedo, MdUndo } from "react-icons/md";
import { toast } from "react-toastify";
import styles from "./style.module.css"

const CATEGORIES_SV = {
  0: "Anpassa ton",
  1: "Justera innehåll"
}

const PromptsDropdown = ({ description, setDescription, chatMutation, maxLength }) => {
  // Fetch prompts data
  const { data: prompts, error, isLoading } = Agent.Prompts.GET.ALL();

  // Display none if there is no prompts and without error
  if (!prompts && !error) return <></>

  const onItemClick = (selectedPrompt) => {
    try {
      handleChat(selectedPrompt)
    } catch (error) {
      console.warn("Unable to handleAiPrompt. error: ", error)
    }
  }

  // Extract unique categories and sort them by size
  const categories = [...new Set(prompts?.flatMap(x => x?.category))];
  const categoriesSortedBySize = categories.sort((a, b) => (a === null ? -1 : b === null ? 1 : a - b));

  // Group prompts by category
  const categorizedPrompts = categoriesSortedBySize?.map(category =>
    prompts?.filter(x => x.category === category)
  );

  // Generate dropdown items
  const dropdownItems = categorizedPrompts?.map((category) =>
    category?.map((prompt, index) => {
      const item = (
        <Dropdown.Item eventKey={prompts?.indexOf(prompt)} className="m-0 d-flex flex-column " onClick={() => onItemClick(prompt)}>
          <span className="text-sm bold">{prompt?.title}</span>
          <small className="text-xs">{prompt?.description}</small>
        </Dropdown.Item>
      );

      const itemWithDivider = (
        <>
          <Dropdown.Divider />
          <Dropdown.ItemText className="pb-2 text-md">{CATEGORIES_SV[prompt?.category] ?? prompt?.categoryName}</Dropdown.ItemText>
          {item}
        </>
      );

      return (index === 0 && prompt?.category !== null) ? itemWithDivider : item;
    })
  );

  const handleChat = (prompt) => {

    if (!description || description?.length < 6) {
      toast.warn("Du behöver minst 6 tecken i beskrivningen för att generera något innehåll")
      return null
    }
    toast.promise(
      chatMutation.mutateAsync(({ message: description, promptId: prompt?.id, maxLength : maxLength}),
        {
          onSuccess: (res) => {
            setDescription(res?.reply)
          },
        }
      ),
      {
        pending: 'Genererar text...',
        success: 'Klar!',
        error: 'Ett fel uppstod. Försök igen'
      })

  }
  const { message, reply } = chatMutation?.data ?? { message: undefined, reply: undefined };

  return (
    <Dropdown
      drop="end"
      className="d-flex flex-row justify-content-end pt-2"
    >

        {message && message !== description &&
          <Button variant={"light"} onClick={() => setDescription(message)}><MdUndo  /> </Button>
        }
        {reply && reply !== description &&
          <Button variant={"light"} onClick={() => setDescription(reply)} > <MdRedo /></Button>
        }
      <Dropdown.Toggle className="px-3 py-1 " variant={"outline-light"} disabled={!!error || isLoading || chatMutation?.isLoading} direction="end">
        <Image
          src="https://everydayaitools.com/wp-content/uploads/2023/03/ai-1.png"
          width={28}
          className={chatMutation?.isLoading || isLoading ? styles.spinner : ""}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {
          dropdownItems
        }

      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PromptsDropdown
