import { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import "./style.css";

const MULTIPALE_FILES = false;

export default function DragAndDropFile({
  setFiles,
  files,
  handleImageUpload,
  loading,
}) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      MULTIPALE_FILES
        ? setFiles([...files, ...e.dataTransfer.files])
        : setFiles([...e.dataTransfer.files]);
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      MULTIPALE_FILES
        ? setFiles([...files, ...e.target.files])
        : setFiles([...e.target.files]);
      // handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  return (
    <>
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => {
          e.preventDefault();
          return false;
        }}
      >
        {loading ? (
          <div id="label-file-upload">
            <Spinner />
          </div>
        ) : (
          <>
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={MULTIPALE_FILES}
              onChange={(e) => handleImageUpload(e)}
              accept="image/png, image/jpeg, application/pdf"
              disabled={loading}
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <BiUpload
                size={70}
                className="mb-2 text-grey bold"
                style={{ opacity: 0.4 }}
              />
              <div>
                <p>Drag och släpp en fil här eller</p>
                <div className="upload-button" onClick={(e) => onButtonClick(e)}>
                  Välj en fil
                </div>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </>
        )}
      </form>
    </>
  );
}
