import { Spinner } from "react-bootstrap";
import {
  LeadCompanyDetailsOffCanvas,
  LeadPersonsDetailsOffCanvas,
} from "features/leads/lead-details";

import { useState } from "react";
import { TextInput } from "components/forms";
import { Table, Row, Col } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { CardContainer } from "components/cards";
import { useSelector } from "react-redux";
import PaginationBar from "components/pagination/PaginationBar";
import Agent from "api/Agent";
import FilterLeadsButton from "./FilterLeadsButton";

const LeadsTable = () => {
  const leadType = useSelector((state) => state.lead.leadType);

  //OffCanvas Leads details
  const [show, setShow] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState("");

  const [filters, setFilters] = useState({
    syncedToCrm: "",
    hasActivities: "",
    score: "",
  });

  const handleClose = () => {
    setShow(false);
    setLeadId(null);
  };

  const handleShow = (id) => {
    setLeadId(id);
    setShow(true);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoading,
    isError,
    data: leads,
  } = Agent.Leads.GET.ALL(
    currentPage,
    leadType,
    search,
    filters.syncedToCrm,
    filters.hasActivities,
    filters.score
  );

  //Pagination

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      label: leadType === 0 ? "Företag" : "Personer",
      property: leadType === 0 ? "title" : "firstName",
    },
    { label: "I CRM", property: "crm" },
    { label: "Aktiviteter", property: "activityCount" },

    { label: "Ort", property: "city" },

    leadType === 0
      ? { label: "Typ av företag", property: "companyType" }
      : { label: "Ålder", property: "age" },

    {
      label: "Score",
      property: "score",
    },
  ];

  const handleTableHeaderClick = (property) => {
    if (sort?.name === property)
      return setSort({ name: property, desc: !sort.desc });

    return setSort({ name: property, desc: false });
  };

  const filteredData = leads?.Results;

  return (
    <>
      {leadId && leadType === 0 && (
        <LeadCompanyDetailsOffCanvas
          show={show}
          handleClose={handleClose}
          handleClosee={(e) => handleClose}
          leadId={leadId}
        />
      )}

      {leadId && leadType === 1 && (
        <LeadPersonsDetailsOffCanvas
          show={show}
          handleClose={handleClose}
          leadId={leadId}
        />
      )}

      <Row>
        <Col className="col-12">
          <Row className="justify-content-between">
            <Col
              style={{ position: "relative" }}
              className="d-flex align-items-start col-3"
            >
              <FilterLeadsButton
                mainTable
                filters={filters}
                setFilters={setFilters}
              />
            </Col>
            <Col className="col-3">
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col className="col-12">
          <CardContainer
            style={{ padding: "0" }}
            children={
              <div className="table-responsive">
                <Table
                  id="segmentTable"
                  className="table-container"
                  responsive
                  hover
                  size="lg"
                >
                  <thead className="text-sm semi-bold">
                    <tr>
                      {columns.map((col, index) => (
                        <th
                          style={{ width: "150px", userSelect: "none" }}
                          key={index}
                          onClick={() => handleTableHeaderClick(col.property)}
                        >
                          {sort?.name === col.property ? (
                            <p>
                              {col.label}

                              {sort?.desc ? (
                                <BsArrowDownShort size={20} />
                              ) : (
                                <BsArrowUpShort size={20} />
                              )}
                            </p>
                          ) : (
                            <p>{col.label}</p>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="text-md regular">
                    {!isLoading &&
                      !isError &&
                      filteredData.map((item) => {
                        return (
                          <tr key={item.id} onClick={() => handleShow(item.id)}>
                            <td
                              style={{
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.type === 0
                                ? item.title
                                : `${item.firstName} ${item.lastName}`}
                            </td>
                            <td>{item.syncedToCrm ? "Ja" : "Nej"}</td>
                            <td>{item.activityCount} st</td>
                            <td>{item.city}</td>

                            <td>
                              {item.type === 0 && item.companyType
                                ? item.companyType
                                : item.type === 1
                                ? `${item.age} år`
                                : ""}
                            </td>

                            <td>{item.score}</td>
                          </tr>
                        );
                      })}
                  </tbody>

                  {isError && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3 bg-danger"
                    >
                      <h5 className=" text-white semi-bold text-center w-100">
                        Ett fel uppstod med tabell data
                      </h5>
                    </caption>
                  )}

                  {isLoading && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3"
                    >
                      <h5 className=" text-black text-center w-100">
                        <Spinner />
                      </h5>
                    </caption>
                  )}

                  {!isLoading && !isError && !filteredData?.length && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3 text-center"
                    >
                      <span className="text-xl text-black text-muted ">
                        Inga leads hittade.
                      </span>
                    </caption>
                  )}
                </Table>

                {!isLoading && !isError && filteredData?.length > 0 && (
                  <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    countPerPage={leads?.Paging.per_page}
                    totalCount={leads?.Paging?.total}
                  />
                )}
              </div>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default LeadsTable;
