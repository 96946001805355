import { Offcanvas } from "react-bootstrap";
import LandingPageForm from "../../landingpage/LandingPageForm";

const LandingPageOffCanvas = (props) => {
  return (
    <Offcanvas
      className="lead-activity-offcanvas"
      show={props.show}
      onHide={props.handleClose}
      placement="end"
    >
      <Offcanvas.Header
        className="flex-row-reverse justify-content-end align-items-baseline"
        closeButton
      >
        <h2 className="display-md bold p-3">Skapa Landningssida</h2>
      </Offcanvas.Header>

      <Offcanvas.Body className="p-5">
        <LandingPageForm
          campaign={props.campaign}
          activity={props.activity}
          setSelectedTemplate={props.setSelectedTemplate}
          handleClose={props.handleClose}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default LandingPageOffCanvas;
