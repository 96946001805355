import ModifyCampaignChannelTable from "./components/ModifyCampaignChannelTable";
import OverviewChannelTable from "./components/OverviewChannelTable";
import { Modal, Row, Col, Popover, Dropdown } from "react-bootstrap";
import OfferTable from "features/offers/my-offers/components/OfferTable";

const Overview = (props) => {
  return (
    <Row
      // className="p-3 p-md-5"
      style={{ padding: "20px 10px 0px 30px" }}
    >
      <Row className=" row-cols-auto justify-content-end p-0">
        <Dropdown drop="down-centered">
          <Dropdown.Toggle
            style={{ padding: "10px 16px" }}
            className="text-md btn-lg"
            variant="success"
            id="dropdown-basic"
          >
            Tillgängliga kanaler
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <ModifyCampaignChannelTable campaign={props.campaign} />
          </Dropdown.Menu>
        </Dropdown>
      </Row>
      <Row className="py-2">
        <OverviewChannelTable campaign={props.campaign} selectedChannel={props.selectedChannel} />

      </Row>
      {props.campaign?.offerID &&
        <Row className="py-2">
          <OfferTable offerID={props.campaign?.offerID} />
        </Row>
      }
    </Row>
  );
};

export default Overview;
