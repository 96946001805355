import axios from "api/axios";
import { endPoints } from "api/endPoints";

const LEADS_URL = endPoints.leads.LEADS_URL;

// export const getLeads = async () => {
//   const response = await axios.get(LEADS_URL);
//   return response.data;
// };

export const getLeads = async (page, type, limit, search) => {
  const url = [LEADS_URL]
  const searchParams = [page && `page=${page}`, search && `search=${search}`, limit && `limit=${limit}`, type !== undefined && `type=${type}` ]?.filter(x => x)?.map((param, index) => 
  {
    if (!index) return "?" + param;
    return "&" + param
  }).join("")
  
  url.push(searchParams)
  return await axios.get(url.join(""));
};

// export const getLeadById = async (id) => {
//   return await axios.get(`${LEADS_URL}/${id}`);
// }; // Use Agent instead

export const updateLead = async ({ id }, updatedSegment) => {
  return await axios.put(`${LEADS_URL}/${id}`, updatedSegment);
};

export const getVehicles = async (id, page) => {
  return await axios.get(`${LEADS_URL}/${id}/vehicles?page=${page}`);
};

export const qualifyLead = async ({ id }) => {
  return await axios.post(`${LEADS_URL}/${id}/qualify`);
};