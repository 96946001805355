import { Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { useState } from "react";
import { useQuery } from "react-query";
import VehiclesOverview from "../components/VehiclesOverview";
import VehiclesToday from "../components/VehiclesToday";

const CompanyVehicles = ({ account, accountId }) => {
  const [page, setPage] = useState(0);
  const vehicleAggregations = account?.vehicleaggregations;

  return (
    <Row className="gy-4 w-100 m-0">
      {vehicleAggregations ? (
        <>
          <Col className="col-12 m-0">
            <ButtonGroup style={{ background: "#fff", padding: "6px" }}>
              <Button variant="light" onClick={() => setPage(0)}>
                Översikt
              </Button>
              <Button variant="light" onClick={() => setPage(1)}>
                Fordon idag
              </Button>
              <Button variant="light" onClick={() => setPage(2)}>
                Fordonshistorik
              </Button>
            </ButtonGroup>
          </Col>
          {page === 0 && (
            <VehiclesOverview vehicleAggregations={vehicleAggregations} />
          )}
          {page === 1 && <VehiclesToday accountId={accountId} />}
        </>
      ) : (
        <p className="m-4">Inga fordon hittades hos företaget.</p>
      )}
    </Row>
  );
};

export default CompanyVehicles;
