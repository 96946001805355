import { Row, Col, Container } from "react-bootstrap";
import CampaignTable from "../../features/campaign/my-campaigns/components/CampaignTable";
import CreateCampaignButton from "features/campaign/my-campaigns/components/CreateCampaignButton";
import { useMediaQuery } from 'react-responsive';


const MyCampaigns = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className={`justify-content-between ${isSmallScreen ? 'flex-column' : ''}`}>
            <Col style={{marginTop: isSmallScreen ? 15 : ''}}>
              <h2>Mina Kampanjer</h2>
            </Col>
            <Col className={`col-lg-4 col-md-5 d-flex ${isSmallScreen ? 'mt-4 justify-content-start' : 'justify-content-end'}`}>
              <CreateCampaignButton
              isSmallScreen={isSmallScreen}
               />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <CampaignTable />
        </Col>
      </Row>
    </Container>
  );
};

export default MyCampaigns;
