import React, { useState, useEffect } from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { addUser, getUsers } from "features/users/api";
import Agent from "api/Agent";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "./style.module.scss";
import {
  AiOutlineUserAdd,
  AiOutlineFileImage,
  AiFillEyeInvisible,
  AiFillEye,
} from "react-icons/ai";

const NewUserModal = ({ show, onClose, customerId }) => {
  const navigate = useNavigate();

  console.log({ customerId });

  //modal show
  const handleClose = (e) => typeof onClose == "function" && onClose(false);

  const [firstName, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const queryClient = new useQueryClient();

  useEffect(() => {
    if (password === confirmPassword) {
      setPasswordError("");
    } else {
      setPasswordError("Passwords are not correct");
    }
  }, [password, confirmPassword]);

  const resetStates = () => {
    setName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  // const { mutate } = useMutation(
  //   ({ addNewUserObj }) => addUser(addNewUserObj),
  //   {
  //     onMutate: () => {
  //       setLoadingCreate(true);
  //     },
  //     onSuccess: (response) => {
  //       console.log(response.data);
  //       queryClient.invalidateQueries("users");
  //       // navigate(`create-user/${response.data.id}`);
  //       setLoadingCreate(false);
  //     },
  //     onError: () => {
  //       setLoadingCreate(false);
  //     },
  //     onSettled: () => {
  //       setLoadingCreate(false);
  //     },
  //   }
  // );
  const { mutate, isLoading } = Agent.Users.POST();

  const handleAddValue = (e) => {
    e.preventDefault();
    const addNewUserObj = {
      firstName: firstName,
      customerID: customerId,
      lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };

    if (password === confirmPassword && isValid) {
      setPasswordError("");
      mutate(addNewUserObj, {
        onSuccess: () => {
          resetStates();
          handleClose();
        },
      });
      console.log(addNewUserObj);
    } else {
      console.log("passwords are not correct");
    }
  };

  //password part

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // const toggleShowConfitmPassword = () => {
  //   setShowPassword(!confirmShowPassword);
  // };

  const validatePassword = (password) => {
    // length <= 6
    if (password.length < 6) {
      return false;
    }

    // at least a letter
    const letterRegex = /[a-zA-Z]/;
    if (!letterRegex.test(password)) {
      return false;
    }

    // digit
    const digitRegex = /[0-9]/;
    if (!digitRegex.test(password)) {
      return false;
    }

    // special character
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(password)) {
      return false;
    }
    return true;
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsValid(validatePassword(newPassword));
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      className={styles.offcanvas}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Lägg till ny användare</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          <Form>
            <Col className="col-12 mb-5">
              <Row>
                <Col style={{ padding: "35px 10px 15px" }}>
                  <h5>Kontaktuppgifter</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Förnamn</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      aria-label="Namn"
                      aria-describedby="basic-addon1"
                      value={firstName}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <label>Efternamn</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      aria-label="Namn"
                      aria-describedby="basic-addon1"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              {/* <Row>
                  <Col>
                    <label>Telefonnummer</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        aria-label="Namn"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </Col>
                </Row> */}
              <Row>
                <Col style={{ padding: "35px 10px 15px" }}>
                  <h5>Inloggningsuppgifter</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Email / Användarnamn</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="namn@company.com"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Lösenord</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      aria-label="password"
                      aria-describedby="basic-addon1"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={toggleShowPassword}
                    >
                      {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Bekräfta lösenord</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      aria-label="Namn"
                      aria-describedby="basic-addon1"
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={toggleShowPassword}
                    >
                      {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              <Col style={{ paddingBottom: "10px", color: "red" }}>
                {passwordError && <div>{passwordError}</div>}
              </Col>
            </Col>
            <Button variant="success" onClick={handleAddValue}>
              {isLoading ? <Spinner /> : "Skapa användare"}
            </Button>
          </Form>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NewUserModal;
