import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";
import axios from "api/axios";

export const Prompts = {
  GET: {
    ALL: () => {
      const queryClient = useQueryClient();
      return useQuery({
        queryFn: async () => {
          const result = await axios.get(`prompts/v1`);
          return result?.data;
        },
        queryKey: ["prompts"],
        // retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET all prompts with \n Error: `,
            error
          );
        },
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: async () => {
          const result = await axios.get(`prompts/v1/${id}`)
          return result?.data;
        },
        queryKey: ["prompt", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(`Failed to GET prompt with ID: ${id} \n Error: `, error);
        },
      });
    },
  },
  POST: {
    PROMPT_MUTATION :
  () => { 
    const queryClient = useQueryClient();
    return useMutation(
      (prompt) =>
        Promise.resolve(
          axios.post(`/prompts/v1/${prompt?.id}`, prompt).then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("prompts");
        },
        onError: (error) => {
          console.warn(`Failed to POST prompt \n Error: `, error);
          toast(`[Fel] Det gick inte att skapa prompt`, {
            type: "error",
          });
        },
      }
    );
  },
}
};
