import { getFilterRangeValues } from "features/filter/api";
import { isArray } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ButtonGroup, ToggleButton, Col } from "react-bootstrap";
import { useMutation } from "react-query";

export default function FilterPropBool({ filter, filterType }) {
  const [filterRangeProps, setFilterRangeProps] = useState([]);
  const filterTypeRangeDetails = filterRangeProps?.filter(
    (y) => y.filterTypeID === filterType.id || !y.filterTypeID
  );
  const filterProps = filter.find((filterProperties) =>
    isArray(filterProperties)
  );

  const getfilterTypeBoolDetails = useMutation((filter) =>
    getFilterRangeValues(filter)
  );

  useEffect(() => {
    getfilterTypeBoolDetails.mutate(filterType, {
      onSuccess: (_data) => {
        const rangePropDetails = [..._data];

        // some filter properties does not include the active selection, the returned options could be [0 : yes, 1 : no] yet the selection is at times [2] that don't exist.
        // If that is the case then append a generic prop for the active selection
        const rangeDetailMissing = filterProps.filter(
          (_filter) => !rangePropDetails.map((x) => x.query).includes(_filter)
        );
        console.log({ rangeDetailMissing });
        if (rangeDetailMissing && rangeDetailMissing[0] === 1)
          rangeDetailMissing.map((missingRange) =>
            rangePropDetails.push({ query: missingRange, name: "Nej" })
          );
        if (rangeDetailMissing && rangeDetailMissing[0] === 0)
          rangeDetailMissing.map((missingRange) =>
            rangePropDetails.push({ query: missingRange, name: "Ja" })
          );
        if (rangeDetailMissing && rangeDetailMissing[0] === 2)
          rangeDetailMissing.map((missingRange) =>
            rangePropDetails.push({ query: missingRange, name: "Båda" })
          );

        return setFilterRangeProps(rangePropDetails);
      },
    });
  }, []);

  return (
    <Col className="bold mx-1" style={{ color: "#00789e" }}>
      {filter[1][0] === 0 ? "Ja" : filter[1][0] === 1 ? "Nej" : "Båda"}
    </Col>
    // <ButtonGroup>
    //   {filterProps?.map((filterProp, propIndex) =>
    //     [...filterTypeRangeDetails].map((rangeProp, rangeIndex) => (
    //       <ToggleButton
    //         disabled
    //         key={propIndex + rangeIndex}
    //         type="radio"
    //         variant={"outline-secondary"}
    //         checked={rangeProp?.query === filterProp}
    //         className="text-capitalize"
    //       >
    //         {rangeProp?.name}
    //       </ToggleButton>
    //     ))
    //   )}
    // </ButtonGroup>
  );
}
