import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  const chartRef = useRef();
  const textCenter = props.textCenter;
  const data = {
    labels: props.labels,
    datasets: [
      {
        data: props.values,
        backgroundColor: props.colors !== undefined ? props.colors : [
          "#2D6450",
          "#B3CCC3",
          "#10CCB3",
          "#B0EF82",
          "#AA80B7",
        ],
        hoverBorderWidth: 0,
        hoverBorderColor: [
          "#009CBC",
          "#C78575",
          "#10CCB3",
          "#B0EF82",
          "#AA80B7",
        ],
        borderRadius: 0,
        cutout: "70%",
        stroke: {
          width: 0
        }
      },
    ],
  };

  const options = {
    // textCenter,
    stroke: {
      width: 0
    },
    plugins: {
      legend: {
        position: "right",

        display: true,
        labels: {
          usePointStyle: true,
        },
        
      },
    },
    maintainAspectRatio: false,
    // aspectRatio: 1,
    
  };

  // const plugins = [
  //   {
  //     beforeDraw: function (chart) {
  //       var width = chart.width,
  //         height = chart.height,
  //         ctx = chart.ctx;
  //       ctx.restore();
  //       var fontSize = (width / 125).toFixed(2);
  //       ctx.fillStyle = "grey";
  //       ctx.font = fontSize + "em Nunito";
  //       ctx.textBaseline = "bottom";
  //       var text = chart.config.options.textCenter, //important to store count in options and not directly here
  //         textX = Math.round((width - ctx.measureText(text).width) / 2),
  //         textY = height / 2;
  //       ctx.fillText(text, textX, textY);
  //       ctx.save();
  //       chart.update();
  //     },
  //   },
  // ];

  // const onClickHandler = (e) => {
  //   const activePoints = getElementAtEvent(chartRef.current, e);
  //   if (activePoints.length > 0) {
  //     const index = activePoints[0];
  //     const type = chartRef.current.data.labels[index.index];
  //     console.log(index, type);
  //     props.selectedLabelType(type);
  //   } else {
  //     console.log("No type found");
  //   }
  // };

  return (
    <Doughnut
      style={{ cursor: "pointer" }}
      // ref={chartRef}
      data={data}
      options={options}
      height={props.height}
      width={"100%"}
      // plugins={plugins}
      // onClick={onClickHandler}
    />
  );
};

export default DoughnutChart;
