import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

const ScoreRow = ({label, subText, value}) => {
  const isXsScreen = useMediaQuery({ maxWidth: 570 });

  const scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [current, setCurrent] = useState(value);

  const onClickHandler = (score) => {
    setCurrent(score);
  };

  return (
    <>

      <Row className="gy-2">
        <Col className="p-0 col-12">
          <h4 className="display-xs semi-bold">
            {label} <span className="regular"> - {subText}</span>
          </h4>
        </Col>
        <Col className="col-12">
          <Row className="score-row">
            {scores.map((score) => (
              <Col
                key={score}
                onClick={() => onClickHandler(score)}
                className={
                  score === current ? (isXsScreen ? "score-col-selected-xs" : "score-col-selected") : (isXsScreen ? "score-col-xs" : "score-col")
                }
              >
                {score}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

  </>
  );
};

export default ScoreRow;
