import { Col, Row } from "react-bootstrap";
import MobilePreview from "../../../components/MobilePreview";
import "../../../style.scss";
import { forwardRef } from "react";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import EditMetaAccount from "../components/EditMetaAccount";

const MetaStep0 = forwardRef(
  (
    { activity, metaAdObject, setMetaAdObject, segment, segmentIsLoading },
    ref
  ) => {
    return (
      <Row className=" row-cols-1 row-cols-xl-2">
        {/* CONTENT LEFT---------- */}
        <Col>
          <Row className="d-flex align-items-center row-cols-auto flex-wrap-reverse">
            <Col className="float-start">
              <span className="display-sm semi-bold">
                Välj Meta konto och typ
              </span>
            </Col>
            <Col className=" float-start ms-auto">
              {statusBadgeSetter(activity)}
            </Col>
          </Row>
          <EditMetaAccount
            segmentIsLoading={segmentIsLoading}
            segment={segment}
            activity={activity}
            metaAdObject={metaAdObject}
            setMetaAdObject={setMetaAdObject}
            ref={ref}
          />
        </Col>
        {/* CONTENT RIGHT---------- */}
        <Col className="col-6 h-100 d-flex justify-content-center align-items-center">
          <MobilePreview onAccountPage />
        </Col>
      </Row>
    );
  }
);

export default MetaStep0;
