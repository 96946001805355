import { ButtonMain } from "components/buttons";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { BiSave } from "react-icons/bi";
//react-query
import { useQueryClient, useMutation } from "react-query";
import {
  addSegment,
  segmentCompanyObject,
  saveSegmentFlag,
} from "features/segment/api";
import { useParams } from "react-router-dom";
import { useState } from "react";

const SaveSegment = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const queryClient = new useQueryClient();
  const { mutate } = useMutation(() => saveSegmentFlag({ id }), {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("segments");
      navigate("/segments", { replace: true });
    },
    onError: () => {
      setLoading(false);
    },
  });

  const navigate = useNavigate();
  const handleSubmit = () => {
    mutate();
  };
  return (
    <ButtonMain
      onClick={handleSubmit}
      loading={loading}
      icon={<BiSave />}
      text={loading ? <Spinner /> : "Spara Målgrupp"}
      variant="success"
    />
  );
};

export default SaveSegment;
