import "../style.scss";
import { useState } from "react";
import { Row, Col, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ButtonLarge } from "components/buttons";
import { MdBusiness } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { FaCar } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useGlobalState } from "features/segment/segmentSlice";
import UploadFileButton from "features/segment/create-segment/components/UploadFileButton";
import { useMediaQuery } from 'react-responsive';
//react-query
import { useQueryClient, useMutation } from "react-query";
import {
  addSegment,
  segmentCompanyObject,
  segmentPersonObject,
  segmentVehicleObject,
} from "features/segment/api";
import { setSegmentType } from "features/filter/filterSlice";

const ModalCreateSegment = ({ show, setShow }) => {

  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Get all filters from react-hooks-global
  const [name] = useGlobalState("name");
  const filters = [];

  //OnFormSubmit-----------------------------
  const [loadingCreate, setLoadingCreate] = useState(false);
  const queryClient = new useQueryClient();
  const addSegmentMutation = useMutation(addSegment, {
    onMutate: () => {
      setLoadingCreate(true);
    },
    onSuccess: (response) => {
      console.log(response.data);
      queryClient.invalidateQueries("segments");
      navigate(`create-segment/${response.data.id}`);
      setLoadingCreate(false);
    },
    onError: () => {
      setLoadingCreate(false);
    },
    onSettled: () => {
      setLoadingCreate(false);
    },
  });

  const onClickCreateSegment = async (segType, segSubType) => {
    dispatch(setSegmentType(segType));
    if (segType === 0) {
      addSegmentMutation.mutate(segmentCompanyObject(name, segType, filters));
    } else if (segType === 1) {
      addSegmentMutation.mutate(segmentPersonObject(name, segType, filters));
    } else if (segType === 2) {
      addSegmentMutation.mutate(segmentVehicleObject(name, segSubType, segType, filters));
    }
  };
  // className="create-segment-modal"
  // {ifPhoneScreen? "sm":"lg"}
  return (
    <Modal
      size="lg"
      className="create-segment-modal"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Skapa en ny målgrupp</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h5 className="display-xs semi-bold">
              Bygg upp din målgrupp från start
            </h5>
          </Col>
          <Col sm={12} md={6}>
            <ButtonLarge
              onClick={() => onClickCreateSegment(0)}
              title="Företag"
              icon={
                loadingCreate ? (
                  <Spinner />
                ) : (
                  <MdBusiness size={48} color="#006C33" />
                )
              }
              arrow
            />
          </Col>
          <Col sm={12} md={6}>
            <ButtonLarge
              onClick={() => onClickCreateSegment(1)}
              title="Personer"
              icon={<FiUsers size={48} color="#B73324" />}
              arrow
            />
          </Col>
          <Col sm={12} md={6}>
            <ButtonLarge
              onClick={() => onClickCreateSegment(2, 0)}
              title="Fordonsägare"
              suffix="(företag)"
              icon={<FaCar size={48} color="rgb(150 165 157)" />}
              arrow
            />
          </Col>
          <Col sm={12} md={6}>
            <ButtonLarge
              onClick={() => onClickCreateSegment(2, 1)}
              title="Fordonsägare"
              suffix="(privatpersoner)"
              icon={<FaCar size={48} color="rgb(193 124 116)" />}
              arrow
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-4">
          <Col className="col-12">
            <h5 className="display-xs semi-bold">Ladda upp en egen lista</h5>
          </Col>
          <Col className={ifXsScreen ? "col-12": ""}>
            <UploadFileButton
              title="Företag"
              subTitle="En lista av företag och kontaktuppgifter"
              type={0}
            />
          </Col>
          <Col className={ifXsScreen ? "col-12": ""}>
            <UploadFileButton
              title="Personer"
              subTitle="En lista av personer och deras kontaktuppgifter"
              type={1}
            />
          </Col>
          {/* <Col>
            <UploadFileButton
              title="Företag & Personer"
              subTitle="En kombinerad lista av företag och personer"
              type={2}
            />
          </Col> */}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCreateSegment;
