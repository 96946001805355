import { Col, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { getCustomerSettings } from "features/settings/api";
import { segmentTypes } from "features/segment/segment-details/assets/config";
import {useState, useEffect} from "react"

const TargetTypeSelect = ({ defaultValue, value, onInit, onChange, segment }) => {

  const isCompany = [segment?.type, segment?.subType].includes( segmentTypes.Company);
  const DEFUALT_TARGET = isCompany ? 1 : 3
  const [directMarketingTarget, setDirectMarketingTarget] = useState(defaultValue ?? DEFUALT_TARGET)

  //reset onMount
  useEffect(() => { 
    setDirectMarketingTarget(defaultValue ?? DEFUALT_TARGET) 
    if (typeof onInit== "function") onInit({directMarketingTarget : +value})
  },[]);

  const handleChange = (value) => {
    setDirectMarketingTarget(value);
    if (typeof onChange == "function") onChange({directMarketingTarget : +value})

  }
  const { data: settings } = useQuery("customer", getCustomerSettings);
  return (
    <>
      {isCompany ? (
        <Col className="col-12">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="py-3 text-xl semi-bold"
            onChange={(e) =>
              handleChange(
              e.target.value,
              )
            }
        value={value ?? directMarketingTarget}
          >
            <option value={1}>Alla personer i styrelsen</option>
            <option value={2}>Ordförande, VD & styrelseledamöter</option>

            {settings?.isContactPersonsTargetingAvailable && (
              <option value={5}>Kontaktpersoner</option>
            )}
          </Form.Select>
        </Col>
      )
      : (
        <Col className="col-12">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            className="py-3 text-xl semi-bold"
            aria-label="Default select example"
            onChange={(e) =>
              handleChange(
              e.target.value,
              )
            }
        value={value ?? directMarketingTarget}
          >
            <option value={3}>Personer i målgruppen</option>
            <option value={4}>Övriga personer i hushållet</option>
            <option value={6}>Alla i hushållet</option>
          </Form.Select>
        </Col>
      )}
    </>
  );
};

export default TargetTypeSelect;
