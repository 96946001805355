import { Container, Row, Col } from "react-bootstrap";
import SegmentOverviewContainer from "features/overview/segment/SegmentOverviewContainer";
import CampaignOverviewContainer from "features/overview/campaign/CampaignOverviewContainer";
import { getOverviewStats } from "features/overview/api";
import { useMediaQuery } from "react-responsive";
import LeadsOverviewContainer from "features/overview/leads/LeadsOverviewContainer";
import CustomersOverviewContainer from "features/overview/customers/CustomersOverviewContainer";
import ExportsOverviewContainer from "features/overview/exports/ExportsOverviewContainer";

const Home = ({ siteUrl }) => {
  const ifMediumScreen = useMediaQuery({
    query: "(min-width: 901px) and (max-width: 1200px)",
  });
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const ifMdAddCircleOutlineIsSmall = useMediaQuery({
    query: "(max-width: 350px)",
  });
  const xsValue = ifMdAddCircleOutlineIsSmall ? 12 : 9;

  const screenSizes = {
    ifXsScreen: ifXsScreen,
    ifSmallScreen: ifSmallScreen,
    ifMediumScreen: ifMediumScreen,
    ifMdAddCircleOutlineIsSmall: ifMdAddCircleOutlineIsSmall,
    xsValue: xsValue,
  };

  return (
    <Container className="p-5">
      <Row className="g-5">
        <Col
          className="col-12"
          style={{ marginTop: ifSmallScreen ? "70px" : "" }}
        >
          <h2>Välkommen tillbaka!</h2>
          {siteUrl === "merinfo" ? (
            <p>Skapa, ändra och exportera dina kontaktlistor.</p>
          ) : (
            <p>Skapa, ändra och se dina kampanjer och målgrupper.</p>
          )}
        </Col>

        {siteUrl === "merinfo" ? (
          <>
            <Col className="col-12 h-100" style={{ height: "45vh", marginTop: "70px" }}>
              <SegmentOverviewContainer {...screenSizes} />
            </Col>
            <Col className="col-12 h-100" style={{ height: "45vh", marginTop: "70px" }}>
              <ExportsOverviewContainer {...screenSizes} />
            </Col>
          </>
        ) : (
          <>
            {ifSmallScreen ? (
              <>
                <Col className="col-12 h-100" style={{ height: "35vh" }}>
                  <SegmentOverviewContainer {...screenSizes} />
                </Col>
                <Col className="col-12 h-100" style={{ height: "35vh" }}>
                  <CampaignOverviewContainer {...screenSizes} />
                </Col>
                <Col className="col-12 h-100" style={{ height: "45vh" }}>
                  <LeadsOverviewContainer {...screenSizes} />
                </Col>
                <Col className="col-12 h-100" style={{ height: "45vh" }}>
                  <CustomersOverviewContainer {...screenSizes} />
                </Col>
              </>
            ) : (
              <>
                {ifMediumScreen ? (
                  <>
                    <Col className="col-12 h-100" style={{ height: "31vh" }}>
                      <SegmentOverviewContainer {...screenSizes} />
                    </Col>
                    <Col className="col-12 h-100" style={{ height: "35vh" }}>
                      <CampaignOverviewContainer {...screenSizes} />
                    </Col>
                    <Col className="col-12 h-100" style={{ height: "40vh" }}>
                      <LeadsOverviewContainer {...screenSizes} />
                    </Col>
                    <Col className="col-12 h-100" style={{ height: "40vh" }}>
                      <CustomersOverviewContainer {...screenSizes} />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="col-12">
                      <Row
                        className=" h-100"
                      // style={{ height: "24vh" }}
                      >
                        <Col className="col-6 h-100">
                          <SegmentOverviewContainer {...screenSizes} />
                        </Col>
                        <Col className="col-6 h-100">
                          <CampaignOverviewContainer {...screenSizes} />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-12">
                      <Row
                        // style={{ height: "30vh" }}
                        className=" h-100"
                      >
                        <Col className="col-6 h-100">
                          <LeadsOverviewContainer {...screenSizes} />
                        </Col>
                        <Col className="col-6 h-100">
                          <CustomersOverviewContainer {...screenSizes} />
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Home;
