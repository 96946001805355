import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarChart = (props) => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  const data = {
    labels: props.labels
      ? props.labels
      : ["Data1", "Data2", "Data3", "Data4", "Data5", "Data6"],
    datasets: [
      {
        data: props.data ? props.data : [100, 50, 100, 50, 100, 50],
        backgroundColor: [
          "#009CBC",
          "#C78575",
          "#10CCB3",
          "#B0EF82",
          "#AA80B7",
          "#" + randomColor,
          "#" + randomColor,
        ],
        hoverBorderColor: [
          "#009CBC",
          "#C78575",
          "#10CCB3",
          "#B0EF82",
          "#AA80B7",
          "#" + randomColor,
          "#" + randomColor,
        ],
        spacing: 5,
        borderWidth: 0,
        borderRadius: 2,
        hoverBorderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        grid: {
          color: "rgb(36 179 208 / 6%)",
          z: -1,
          tickLength: 1,
        },
        ticks: {
          display: false,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };
  return <PolarArea data={data} options={options} />;
};

export default PolarChart;
