import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.css";
import Numeral from "react-numeral";
import { isNaN } from "lodash";

export default function StatisticsCounts({ items }) {
  return (
    <>
      <Row className=" g-4 mt-2 justify-content-around">
        {items?.map((item) => {
          const value = isNaN(item?.value) ? 0 : item?.value;
          return (
            <Col key={item?.label}>
              <div className={styles.cardRow}>
                <Col
                  className="col-3"
                  // style={{ borderRight: "1px solid lightgrey" }}
                >
                  <h4
                    className="text-xl semi-bold text-capitalize"
                    style={{ color: "grey" }}
                  >
                    {item?.label}
                  </h4>
                  <div className={styles.cardRow} style={{ fontSize: "36px" }}>
                    {`${value} kampanjer`}
                  </div>
                </Col>

                <Col
                  className="d-flex flex-column text-lg col-3"
                  style={{ gap: "10px" }}
                >
                  {item.totals.map((value) => (
                    <Col>
                      <p>
                        <span
                          style={{
                            color: value.color,
                            backgroundColor: value.bgColor,
                            borderRadius: "4px",
                            padding: "3px",
                          }}
                        >
                          {value.label}:
                        </span>{" "}
                        {value.value}
                      </p>
                    </Col>
                  ))}
                </Col>
                <Col
                  className="d-flex flex-column text-lg col-3"
                  style={{ gap: "10px" }}
                >
                  {item.channels.map((value, index) => {
                    if (index <= 3) {
                      return (
                        <Col>
                          <p style={{ color: "grey" }}>
                            {value.icon}
                            {value.label}:{" "}
                            <span style={{ color: "#000" }}>{value.value}</span>
                          </p>
                        </Col>
                      );
                    }
                  })}
                </Col>
                <Col
                  className="d-flex flex-column text-lg col-3"
                  style={{ gap: "10px" }}
                >
                  {item.channels.map((value, index) => {
                    if (index > 3) {
                      return (
                        <Col>
                          <p style={{ color: "grey" }}>
                            {value.icon}
                            {value.label}:{" "}
                            <span style={{ color: "#000" }}>{value.value}</span>
                          </p>
                        </Col>
                      );
                    }
                  })}
                </Col>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
