import Agent from "api/Agent";
import { Accordion, Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FILTERTYPES } from "../FilterTypesTable";
import styles from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import FilterTypeSelect from "./components/FilterTypeSelect";
import ViewFilterTypeModal from "./components/ViewFilterTypeModal/ViewFilterTypeModal";
import FilterTypeForm from "./components/FilterTypeForm";

const EditFilterTypeModal = ({ show, setShow, selectedId, setSelectedId, categoryId, filterCategory }) => {
  const putFilterTypeMutation = Agent.FilterTypes.PUT.FILTER_TYPE();
  const postFilterTypeMutation = Agent.FilterTypes.POST.FILTER_TYPE();

  const [activeKey, setActiveKey] = useState([
    null,
    "1"
  ]);
  const { data, isLoading, isError, isSuccess } = Agent.FilterTypes.GET.ID(selectedId)

  const [editFilterType, setEditFilterType] = useState(false)
  const formRef = useRef();
  const handleClose = () => {
    if (typeof setSelectedId == "function") setSelectedId(undefined)
    setShow(false);
  };

  const resetForm = () => {
    const form = formRef?.current;
    form?.reset();
  }

  useEffect(() => { setEditFilterType(!selectedId); resetForm() }, [show])

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('form')
    const formData = new FormData(form);
    const formDataArray = Array.from(formData?.entries());

    if (!formDataArray) {
      console.warn("Failed to get formData")
      return false
    }

    const request = formDataArray.reduce((result, [key, value]) => {

      if (form.elements[key].type?.includes('checkbox')) {
        result[key] = form.elements[key].checked;
      }
      else if (["select-one", "number"]?.includes(form.elements[key].type)) {
        result[key] = value === "null" ? null : +value
      }
      else result[key] = value;

      return result;
    }, { ...data })

    if (!request?.filterCategoryID) request.filterCategoryID = categoryId;

    if (request?.id)
      toast.promise(putFilterTypeMutation.mutateAsync(request, { onSuccess: setEditFilterType(false) }), { success: "Uppdaterat filter typ", loading: "...", error: "Ett fel uppstod, kunde inte uppdatera filter typ" })
    else
      toast.promise(postFilterTypeMutation.mutateAsync(request, { onSuccess: setEditFilterType(false) }), { success: `Skapat filter typ [${request?.name}]`, loading: "...", error: "Ett fel uppstod, kunde inte skapa filter typ" })
  }

  const handleAccordionToggle = (key) => {
    if (editFilterType && +key !== 1)
      setEditFilterType(false);

    return setActiveKey([null, key])
  };
  return (
    <Modal show={show} onHide={handleClose} dialogClassName={styles["dialog"]} centered>
      <Modal.Header closeButton>
        {selectedId ?
          <Modal.Title>{data?.name}</Modal.Title>
          :
          <Modal.Title>Skapa ny filter typ</Modal.Title>
        }
      </Modal.Header>
      <Modal.Body className="d-flex flex-column p-0">
        <Row className="justify-content-center align-items-center">
          {isError ? <span>Ett fel uppstod</span> :
            isLoading && <Spinner />}
        </Row>
        {(!selectedId || isSuccess) && <>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)} className={`d-flex flex-column gap-2 text-sm `} id="form">
            <Accordion flush
              className={"w-100 p-0 m-0"}
              onSelect={(e) => handleAccordionToggle(e)}
              activeKey={activeKey} >


              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <Row className="w-100 row-cols-auto justify-content-between ">
                    <span className="semi-bold display-sm">Inställningar</span>
                    {selectedId &&
                      <Form.Group className="d-flex flex-column gap-0 align-items-center p-0">
                        <Form.Label>Ändra</Form.Label>
                        <Form.Switch checked={editFilterType} onClick={(e) => {
                          e.stopPropagation();
                          setEditFilterType(prev => !prev);
                          resetForm();
                        }} />
                      </Form.Group>
                    }
                  </Row>
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column gap-2">

                  <FilterTypeForm data={data} disabled={!editFilterType} />

                  {editFilterType && <Row className="row-cols-auto justify-content-end me-2 mt-2 px-4">
                    {selectedId ?
                      <Button type="submit" variant="success" disabled={putFilterTypeMutation.isLoading}>Uppdatera filter typ</Button>
                      :
                      <Button type="submit" variant="success" disabled={postFilterTypeMutation.isLoading}>Skapa filter typ</Button>
                    }
                  </Row>
                  }
                </Accordion.Body>
              </Accordion.Item>


              {[+FILTERTYPES.SingleValue, +FILTERTYPES.Value].includes(+data?.type) &&
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <span className="semi-bold display-sm">Filter alternativ</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <FilterTypeSelect filterType={data} />
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              }



              {selectedId &&
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <span className="semi-bold display-sm">
                      Granska UI
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <ViewFilterTypeModal selectedId={selectedId} filterCategory={filterCategory} />
                  </Accordion.Body>
                </Accordion.Item>
              }

            </Accordion>
          </Form></>}
      </Modal.Body>
    </Modal >

  )
}



export default EditFilterTypeModal
