import { Button, Offcanvas, Table, Col, Row, Spinner, } from "react-bootstrap";
import { CardContainer } from "components/cards";
import styles from "./style.module.scss";
import Agent from "api/Agent";




const JobDetailsOffcanvas = ({show , handleClose, id}) =>{


  const columns = [
    { label: "Typ av jobb", id: 1 },
    { label: "Starttid", id: 2 },
    { label: "Sluttid", id: 3 },
  ];

  const {
    // isLoading,
    // isError,
    data,
  } = Agent.Jobs.GET.TASKRUNS(id);

  // if (isLoading) return <Spinner />;

  // if (isError) return <p>{error.message}</p>;

return(
    <Offcanvas
     show={show} 
     onHide={handleClose}
     className={styles.offcanvas}
     placement="end"
     >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Name Job Run </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
    <Row className="p-4">
         <Col className="col-12">
          <CardContainer
          style={{ padding: "0" }}
            children={
              <>
                <Table>
                  <thead className="text-sm semi-bold">
                    <tr style={{ textAlign: "center" }}>
                      {columns.map((col) => (
                        <th key={col.id}>{col.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-md regular">
                    {data?.Results.map((r) => (
                      <tr 
                      key={r.id}
                      >
                      <td>{r.name}</td>
                      <td style={{ textAlign: "center" }}>
                          {r.startTime.substring(0, 10)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {r.endTime && `${r.endTime.substring(0, 10)} ${r.endTime.substring(11, 16)} `}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            }
          />
          </Col>
          </Row>
    </Offcanvas.Body>
  </Offcanvas>
);

}

export default JobDetailsOffcanvas;