import { Row, Col, Form, Spinner } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useState } from "react";

const EditBillingEvents = ({
  activity,
  updateActivity,
  updateActivityLoading,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(
    activity?.billingEvent ? activity.billingEvent : 2
  );

  const updateBillingEvent = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/billingEvent",
        value: newValue,
      },
    ];
    updateActivity(patchObj);
    setShowInput(false);
  };
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{minWidth:"170px"}}>
        <p>Betalningstyp</p>
      </Col>

      {showInput ? (
        <Col className="flex-grow-1">
          <Form.Select
            id="billingevent"
            aria-label="Default select example"
            defaultValue={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          >
            <option value={2}>Antal visningar</option>
            <option value={3}>Antal Klick</option>
          </Form.Select>
        </Col>
      ) : (
        <Col className="flex-grow-1">
          {updateActivityLoading ? (
            <Spinner />
          ) : (
            <p style={{ color: "#545F61" }} className="text-center">
              {activity?.billingEvent && activity.billingEvent === 2
                ? "Antal Visningar"
                : activity?.billingEvent && activity.billingEvent === 3
                ? "Antal Klick"
                : "Antal visningar"}
            </p>
          )}
        </Col>
      )}

      {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {showInput ? (
        <Col className="flex-shrink-1 edit-col-icon" onClick={updateBillingEvent}>
          <FaSave color="green" />
        </Col>
      ) : (
        <Col className="flex-shrink-1 edit-col-icon" onClick={() => setShowInput(true)}>
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditBillingEvents;
