import { Col, Row } from "react-bootstrap";
import MobilePreview from "../../../components/MobilePreview";
import EditGoogleAd from "../components/EditGoogleAd";
import "../../../style.scss";
import { forwardRef } from "react";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const GoogleAdsStep1 = forwardRef(
  (
    {
      setBgBlur,
      campaign,
      activity,
      googleAdsObject,
      setGoogleAdsObject,
      selectedMedia,
      setSelectedMedia,
      previewMutation,
      adBannerPreview
    },
    ref
  ) => {

    return (
      <Row className=" row-cols-1 row-cols-xl-2 ">
        {/* CONTENT LEFT---------- */}
        <Col>
          <Row className="d-flex align-items-center row-cols-auto flex-wrap-reverse">
            <Col className="float-start">
              <span className="display-sm semi-bold">
                Skapa annons för Google Ads
              </span>
            </Col>
            <Col className=" float-start ms-auto">
              {statusBadgeSetter(activity)}
            </Col>
          </Row>
          <EditGoogleAd
            previewMutation={previewMutation}
            campaign={campaign}
            activity={activity}
            googleAdsObject={googleAdsObject}
            setGoogleAdsObject={setGoogleAdsObject}
            ref={ref}
            setBgBlur={setBgBlur}
          />
        </Col>
        {/* CONTENT RIGHT---------- */}
        <Col>
          <MobilePreview
            activity={activity}
            adBannerPreview={
              adBannerPreview ? adBannerPreview : null
            }
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
          />
        </Col>
      </Row>
    );
  }
);

export default GoogleAdsStep1;
