import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterType: 0,
};

export const filterAdminSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterType: (state, action) => {
      state.filterType = action.payload;
    },
  },
});

export const { setFilterType } = filterAdminSlice.actions;
