import { Doughnut } from "react-chartjs-2";
import { Col } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data }) => {
  const chartColors = [
    {
      backgroundColor: "#3fbde2",
    },
    {
      backgroundColor: "#b0e6fc",
    },
    {
      backgroundColor: "#C9F0FC",
    },
    {
      backgroundColor: "#065E77",
    },
    {
      backgroundColor: "#9C27B0",
    },
  ];

  const dataSet = {
    labels: ["Kvinnor", "Män"],
    datasets: [
      {
        label: "Visitors",
        data: data,
        backgroundColor: chartColors
          .slice(0, data?.length)
          .map((color) => color.backgroundColor),
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,0)",
      },
    ],
  };

  return (
    <Doughnut
      data={dataSet}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        cutout: "80%",
        plugins: {
          legend: {
            position: "right",
            labels: {
              usePointStyle: true,
            },
          },
        },
      }}
    />
  );
};

export default DoughnutChart;
