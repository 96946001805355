import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import axios from "api/axios";

const PATH = "/filterCategories/v1/"
export const FilterCategories= {
  GET: {
    ALL: (page, limit, search, sortbyproperty, desc, segmentType) => {
      const queryparams = [
        page && `page=${page}`,
        limit !== undefined && `limit=${limit}`,
        search && search?.trim().length > 0 && `searchterm=${search}`,
        sortbyproperty && `orderbyproperty=${sortbyproperty}`,
        desc && `desc=${true}`,
        segmentType !== undefined && `segmentType=${segmentType}`,
      ]?.filter((x) => x);

      const querystring =
        queryparams.length > 0 ? `?${queryparams.join("&")}` : "";

      return useQuery({
        queryFn: async () => {
          const result = await axios.get(`${PATH}${querystring}`);
          return result?.data;
        },
        queryKey: ["filterCategories", "filterTypes", querystring],
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`failed to get all filterCategories \n error: `, err),
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: async () => {
          const result = await axios.get(`${PATH}${id}`)
          return result?.data;
        },
        queryKey: ["filterCategory", "filterTypes", id],
        enabled: !!id,
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(`Failed to GET filter type with ID: ${id} \n Error: `, error);
        },
      });
    },
  },
  POST: {
    FILTER_CATEGORY:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (filterCategory) => {
            const result = await axios.post(`${PATH}`, filterCategory)
            return result?.data;
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries("filterCategories");
            },
            onError: (error) => {
              console.warn(`Failed to POST filterCategory \n Error: `, error);
            },
          }
        );
      },
  },
  PUT: {
    FILTER_CATEGORY:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (filterCategory) => {
            const result = await axios.put(`${PATH}`, filterCategory)
            return { id: filterCategory?.id, data: result?.data };
          },
          {
            onSuccess: ({ id }) => {
              queryClient.invalidateQueries("filterCategories", id);
            },
            onError: (error) => {
              console.warn(`Failed to PUT filterCategory \n Error: `, error);
            },
          }
        );
      },
  },
  DEL: {
    FILTER_CATEGORY:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (id) => {
            const result = await axios.delete(`${PATH}${id}`)
            return { id: id, data: result?.data };
          },
          {
            onSuccess: ({ id }) => {
              queryClient.invalidateQueries("filterCategories", id);
            },
            onError: (error) => {
              console.warn(`Failed to DEL filterCategory \n Error: `, error);
            },
          }
        );
      },
  }


};
