import "../style.scss";
import jwt_decode from "jwt-decode";
import { useState, useEffect, useContext } from "react";
import { axiosLogin } from "api/axios";
import { endPoints } from "api/endPoints";
import { Form, Spinner, Col, Row, Nav, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { FcGoogle } from "react-icons/fc";
import { ButtonMain } from "components/buttons";
import { TextInput, TextInputEye } from "components/forms";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "context/sessionSlice";
import { store } from "app/store";
import { useQueryClient } from "react-query";
const LoginForm = ({ errorMessage, setErrorMessage, siteUrl }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  //Get Endpoint
  const LOGIN_URL = endPoints.LOGIN_URL;
  //Credential input States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //Routing hooks
  const navigate = useNavigate();
  const location = useLocation();
  //Set errorMessage depending on inputs
  useEffect(() => {
    setErrorMessage("");
  }, [email, password, setErrorMessage]);

  //LOGIN SUBMIT FUNCTION--------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosLogin.post(
        LOGIN_URL,
        JSON.stringify({ email, password, isMerInfoUi: siteUrl === 'merinfo' })
      );
      //Set JWT variable
      dispatch(setSession(response?.data));

      const userRole = session?.role;

      setLoading(false);

      setEmail("");
      setPassword("");
      const from = userRole.includes("Admin")
        ? location.state?.from?.pathname || "/admin/home"
        : location.state?.from?.pathname || "/home";

      //Ensure that there is no persistent queries from previous session, clear the network cashe on new session.
      queryClient.removeQueries();

      navigate(from, { replace: true });
      //Catch errors
    } catch (error) {
      if (!error?.response) {
        setErrorMessage(error.message);
      } else if (error.response?.status === 400) {
        setErrorMessage("Missing Username or Password");
      } else if (error.response?.status === 401) {
        setErrorMessage("Unauthorized");
      } else {
        setErrorMessage("Login Failed");
      }
      setLoading(false);
    }
  };

  const onClickResetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <Row className="p-lg-3 flex-column align-items-center">
      <Col className="col-9 col-xl-7 mb-3">
        <h3 className="display-lg semi-bold">Logga In</h3>
        <p className="text-md regular">
          Fyll i dina uppgifter för att logga in på{" "}
          {siteUrl === "merinfo" ? "Merinfo" : "Autotarget"}
        </p>
      </Col>
      <Col className="col-9 col-xl-7">
        <Form onSubmit={handleSubmit}>
          <TextInput
            label="E-post"
            type="email"
            placeholder="Din E-postadress"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextInputEye
            label="Lösenord"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

          <Form.Group className="mb-4 " controlId="formBasicCheckbox">
            <Row className="justify-content-end">
              {/* <Col className="col-sm-6">
                <Form.Check type="checkbox" label="Kom ihåg mig" />
              </Col> */}
              <Col className="col-sm-6">
                <Nav.Link
                  className="text-end forgot-password"
                  onClick={onClickResetPassword}
                >
                  Glömt Lösenord?
                </Nav.Link>
              </Col>
            </Row>
          </Form.Group>
          {loading ? (
            <Row className="justify-content-center">
              <Spinner />
            </Row>
          ) : (
            <Row className="gx-2">
              <Col className="col-12 mb-3">
                <ButtonMain
                  width
                  variant="info"
                  type="submit"
                  text="Logga In"
                />
              </Col>
              {/* <Col className="col-12">
                <ButtonMain
                  width
                  variant="outline-dark"
                  type="submit"
                  text="Logga In med Google"
                  icon={<FcGoogle size={30} />}
                  reverse
                />
              </Col> */}
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default LoginForm;
