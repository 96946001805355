import { sendTestCampaignEmail } from "features/campaign/api";
import { useMutation, useQueryClient } from "react-query";
import { Button, Col, Row, Modal, Form, Spinner } from "react-bootstrap";
import { EmailContext } from "../Email";
import { useContext } from "react";
import { useState } from "react";

const SendTestModal = ({ showModal, setShowModal }) => {
  const queryClient = useQueryClient();
  const { activity, campaign, segment } = useContext(EmailContext);
  const [mail, setMail] = useState("");

  const { mutate: sendTestMail, isLoading } = useMutation(
    () => sendTestCampaignEmail(activity.campaignID, activity.id, mail),
    {
      onSuccess: () => {
        setShowModal(false);
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={() => setShowModal(false)}
      style={{ border: "none" }}
    >
      {" "}
      <Modal.Body className="p-4">
        <Row className="gy-3">
          <Col className="col-12">
            <Row className="justify-content-between px-3">
              <Col>
                <h4 className="display-sm semi-bold">
                  Till följande mailadresser
                </h4>
              </Col>
            </Row>
          </Col>
          <Col className="col-12">
            <Form className="d-flex flex-column p-3">
              <Form.Control
                type="mail"
                name="title"
                id="title"
                placeholder="minmail@test.se"
                onChange={(e) => setMail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ange en mail
              </Form.Control.Feedback>
              <Button
                className="mt-4 semi-bold p-2"
                onClick={() => sendTestMail()}
                variant="success"
              >
                {isLoading ? <Spinner /> : "Skicka testmail"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SendTestModal;
