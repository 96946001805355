import "./style.scss";
import { Row, Col, Container } from "react-bootstrap";
import ModalDateRangePicker from "components/modals/modal-date-range-picker/ModalDateRangePicker";
import VisualCardContainer from "./VisualCardContainer";
import StatisticsFilterModal from "features/stats/StatisticsFilterModal";
import DoughnutChart from "components/charts/doughnut-chart/DoughnutChart";

const Statistics = () => {
  return (
    <Container className="py-5 h-100">
      <Col>
        <div className="d-flex h-100 p-0 mb-4">
          <div className="h-100 float-start w-100 p-2">
            <h2 className="display-md semi-bold w-100 m-0">Statistik</h2>
            <span className="text-md w-100">
              Följ dina kampanjers och ert företags utveckling.
            </span>
          </div>
          <div className="float-end p-2">
            <ModalDateRangePicker />
          </div>
          <div className="float-end p-2">
            <StatisticsFilterModal />
          </div>
        </div>
      </Col>
      {/* <Row className="h-100">
        <DoughnutChart colSize="3" />
        <DoughnutChart colSize="5" />
        <DoughnutChart colSize="4" />
      </Row> */}

      <Row className=" flex-column statistics-content-container">
        <VisualCardContainer />
      </Row>
    </Container>
  );
};

export default Statistics;
