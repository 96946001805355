import { Row, Col, Container, Spinner } from "react-bootstrap";
import { getFilterCategories } from "features/filter/api";
import { FilterItem } from "components/filter";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as iconMap from "react-icons/md";
import { MdCircle } from "react-icons/md";
import { setActiveFilterCategory } from "features/filter/filterSlice";
import { useParams } from "react-router-dom";
import { updateSegment } from "features/segment/api";
import { MdBusiness, MdPersonSearch } from "react-icons/md";
import { FaCar } from "react-icons/fa6";
import { useState, useEffect } from "react";
import Agent from "api/Agent";

const SelectedFilterCategories = ({ segment, isActiveId, setIsActiveId }) => {
  // const [isActiveId, setIsActiveId] = useState("");

  //Get segment id from the URL
  const { id } = useParams();
  //Fetch segment data using id

  const [segmentTypeId, setSegmentTypeId] = useState(segment?.type);
  useEffect(() => {
    if (segment) {
      setSegmentTypeId(segment?.type);
    }
  }, [segment]);

  //OnClickEventHandler
  const dispatch = useDispatch();
  const onClickHandler = (filterCategory) => {
    setIsActiveId(filterCategory.id);
    //set subfilters to display in right Col
    dispatch(setActiveFilterCategory(filterCategory));
  };

  //Update segment filtercategories using mutate() function
  const queryClient = new useQueryClient();
  const { mutate } = useMutation(
    (newSegment) => updateSegment({ id: segment?.id }, newSegment),
    {
      onSuccess: () => {
        // Re-fetch the segment data after the filter categories are updated
        dispatch(setActiveFilterCategory([]));
        queryClient.invalidateQueries(["segment", id]);
      },
    }
  );

  const onClickRemoveHandler = (event, filterCategory) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    //Get the segment.filterCategories
    const filterCategoriesArr = JSON.parse(segment.filterCategories);
    //Get the segment.filters
    const filtersParsed = JSON.parse(segment.filters);

    //Check if the clicked filter category is already included in the array
    if (filterCategoriesArr.includes(filterCategory.id)) {
      //If the filter category is already included, remove it from the array
      const updatedFilterCategories = filterCategoriesArr.filter(
        (item) => item !== filterCategory.id
      );

      const updatedFilters = filtersParsed.filter((obj) => {
        const objKey = Object.keys(obj)[0];
        const match = filterCategory.filterTypes.find(
          (filter) => filter.id === objKey
        );
        return !match;
      });

      //Update the segment data with the new filter categories array
      const updatedSegment = {
        ...segment,
        filterCategories: JSON.stringify(updatedFilterCategories),
        filters: JSON.stringify(updatedFilters),
      };
      //Update the segment data using the mutate() function
      mutate(updatedSegment);
    } else {
      //If the filter category is not included, add it to the array
      filterCategoriesArr.push(filterCategory.id);
      //Update the segment data with the new filter categories array
      const updatedSegment = {
        ...segment,
        filterCategories: JSON.stringify(filterCategoriesArr),
      };
      //Update the segment data using the mutate() function
      mutate(updatedSegment);
    }
  };

  //Fetch FilterCategories

  const {
    data: filterCategories,
    isLoading,
    isError,
    error,
  } = useQuery(["filter", segmentTypeId], getFilterCategories, {
    enabled: !!segment,
  });

  if (isLoading) return <Spinner />;

  if (isError) return <p>{error.message}</p>;

  //Convert the filter categories stringified array to a regular array
  const filterCategoriesArr = segment && JSON.parse(segment?.filterCategories);

  return (
    <Col className="col-12">
      <Row className="gy-3">
        <Col className="col-12">
          <Container className="segment-type-container">
            <Row>
              <Col
                className="col-2"
                style={{ color: "#979A97", fontSize: "33px" }}
              >
                {segment?.type === 0 && !segment?.customListID ? (
                  <MdBusiness />
                ) : segment?.type === 1 && !segment?.customListID ? (
                  <MdPersonSearch />
                ) : segment?.type === 2 && !segment?.customListID ? (
                  <FaCar />
                ) : segment?.type === 0 && segment?.customListID ? (
                  <MdBusiness />
                ) : segment?.type === 1 && segment?.customListID ? (
                  <MdPersonSearch />
                ) : (
                  <>
                    <MdPersonSearch /> + <MdBusiness />
                  </>
                )}
              </Col>
              <Col className="d-flex align-items-center">
                <h4 className="display-xs semi-bold m-0">
                  {segment?.type === 0 && !segment?.customListID
                    ? "Alla Företag"
                    : segment?.type === 1 && !segment?.customListID
                    ? "Alla Personer"
                    : segment?.type === 2 &&
                      !segment?.customListID &&
                      segment.subType === 0
                    ? "Alla Fordonsägare (företag)"
                    : segment?.type === 2 &&
                      !segment?.customListID &&
                      segment.subType === 1
                    ? "Alla Fordonsägare (personer)"
                    : segment?.type === 0 && segment?.customListID
                    ? "Egen lista"
                    : segment?.type === 1 && segment?.customListID
                    ? "Egen lista"
                    : !segment?.type && segment?.customListID
                    ? "Egen lista (kombinerad)"
                    : ""}
                </h4>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col className="col-12">
          {filterCategories?.map((item) => {
            const Icon = iconMap[item.icon];
            if (filterCategoriesArr?.includes(item.id)) {
              return (
                <FilterItem
                  filterTypes={item.filterTypes}
                  filters={segment.filters}
                  key={item.id}
                  title={item.title}
                  subTitle={item.subTitle}
                  icon={item.icon ? <Icon size={33} /> : <MdCircle size={33} />}
                  iconBg={item.iconBg ? item.iconBg : "#dddddd"}
                  onClick={() => onClickHandler(item)}
                  canBeRemoved
                  selected
                  active={isActiveId === item.id ? true : false}
                  onClickRemove={(event) => onClickRemoveHandler(event, item)}
                />
              );
            }

            return null;
          })}
        </Col>
      </Row>
    </Col>
  );
};

export default SelectedFilterCategories;
