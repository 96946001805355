import { useState } from "react";
import styles from "../../../style.module.css";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import Stepper from "components/stepper/stepper";

const formStates = {
  normal: "normal",
  error: "error",
};

const LinkedinStepper = ({
  steps,
  activity,
  campaign,
  linkedinAdObject,
  activeStep,
  setActiveStep,
  mutate,
  isError,
  isLoading,
  formRef,
  formRef0,
  formRef2,
}) => {
  const [formState, setFormState] = useState(formStates.normal);

  const onClickSaveStep0 = () => {
    validateForm0()
      .then(() => {
        const updatedCampaignNoAd = {
          ...activity,
          skipAdCreative: linkedinAdObject.skipAdCreative,
          createAd: true,
          enableTargetGroupMaxSize: linkedinAdObject.enableTargetGroupMaxSize,
          targetGroupMaxSize: linkedinAdObject.targetGroupMaxSize,
          directMarketingTarget: linkedinAdObject.directMarketingTarget,
          ad: {
            ...activity.ad,
            adAccountId: linkedinAdObject.adAccountId,
            metaCampaignId: linkedinAdObject.metaCampaignId,
            metaAdSetId: linkedinAdObject.metaAdSetId,
          },
        };

        const updatedCampaign = {
          ...activity,
          skipAdCreative: linkedinAdObject.skipAdCreative,
          ad: {
            ...activity.ad,
            adAccountId: linkedinAdObject.adAccountId,
            metaPageId: linkedinAdObject.metaPageId,
          },
        };
        mutate(
          linkedinAdObject.skipAdCreative
            ? updatedCampaignNoAd
            : updatedCampaign
        );
      })
      .catch(BadFormResponse);
  };

  const onClickSaveStep1 = () => {
    validateForm1()
      .then(() => {
        const updatedCampaign = {
          ...activity,
          // state: 1,
          ad: {
            ...activity.ad,
            title: linkedinAdObject.adTitle,
            description: linkedinAdObject.adDescription,
            metaPageId: linkedinAdObject.metaPageId,
            targetUrl: linkedinAdObject.adTargetUrl,
            buttonText: linkedinAdObject.adButtonText,
            primaryText: linkedinAdObject.primaryText,
            landingPageID: linkedinAdObject.landingPageID,
            startDate: activity?.ad?.startDate
              ? activity.ad.startDate
              : campaign?.startDate
              ? campaign?.startDate
              : null,
            endDate: activity?.ad?.endDate
              ? activity?.ad?.endDate
              : campaign?.endDate,
            budget: Number(linkedinAdObject.budget),
            adMedias: [
              {
                externalId: linkedinAdObject.imageId,
              },
            ],
          },
        };
        mutate(updatedCampaign);
      })
      .catch(BadFormResponse);
  };
  const onClickSaveStep2 = () => {
    validateForm2()
      .then(() => {
        const updatedCampaign = {
          ...activity,
          createAd: true,
          targetGroupMaxSize: linkedinAdObject.targetGroupMaxSize,
          directMarketingTarget: linkedinAdObject.directMarketingTarget,
          targeting:
            linkedinAdObject.targeting &&
            JSON.stringify(linkedinAdObject.targeting),
          ad: {
            ...activity.ad,
            startDate: linkedinAdObject.startDate,
            endDate: linkedinAdObject.endDate,
            budget: linkedinAdObject.budget,
            adMedias: [
              {
                externalId: linkedinAdObject.imageId,
              },
            ],
          },
        };
        mutate(updatedCampaign);
      })
      .catch(BadFormResponse);
  };

  const BadFormResponse = () => {
    setFormState(formStates.error);
    // trigger onSubmit event for the form. in order to highlight form validation in the form.
    if (formRef0.current) {
      const submitButton = formRef.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
    if (formRef.current) {
      const submitButton = formRef.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
    if (formRef2.current) {
      const submitButton = formRef2.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
  };

  const validateForm0 = async () => {
    setFormState(formStates.normal);
    const form = formRef0?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const validateForm1 = async () => {
    setFormState(formStates.normal);
    const form = formRef?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const validateForm2 = async () => {
    setFormState(formStates.normal);
    const form = formRef2?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  return (
    <Col className={styles.campaignChannelFooter}>
      <Row className="row-cols-3 px-2">
        <Col className="d-flex align-items-center justify-content-start">
          {(activeStep === 1 || activeStep === 2) && (
              <Button
                variant="outline-dark"
                style={{ fontWeight: 600, padding: "8px 14px" }}
                onClick={() => setActiveStep((prev) => prev - 1)}
              >
                <BsArrowLeft size={20} /> Gå tillbaka
              </Button>
            )}
        </Col>

        <Col className="d-flex align-items-center justify-content-center">
          <Stepper
            steps={steps?.length}
            disableContinue
            activeStep={activeStep}
            onClick={(e) => e !== undefined && setActiveStep(e)}
          />
        </Col>

        <Col className="d-flex align-items-center justify-content-end">
          {steps.length - 1 !== activeStep && (
            <Button
              variant={
                formState === formStates.error || isError ? "danger" : "success"
              }
              style={{ fontWeight: 600, padding: "8px 14px" }}
              onClick={
                activeStep === 0
                  ? onClickSaveStep0
                  : activeStep === 1
                  ? onClickSaveStep1
                  : activeStep === 2
                  ? onClickSaveStep2
                  : () => setActiveStep((prev) => prev + 1)
              }
            >
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                "Ett fel uppstod"
              ) : formState === formStates.error ? (
                <span>Inkomplett formulär</span>
              ) : (
                <span>
                  Spara & fortsätt <BsArrowRight size={20} />
                </span>
              )}
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default LinkedinStepper;
