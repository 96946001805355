import { Button, ButtonGroup } from "react-bootstrap";
import {
  CampaignOptionsTypes,
  CampaignOptions,
  CampaignActivityTypes,
} from "../../../options";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { MdEmail } from "react-icons/md";

export default function StatisticsFilter({
  selected,
  setSelected,
  activeChannels,
}) {
  const icons = {
    [CampaignOptions.Meta]: <TbBrandMeta color="rgb(33, 111, 228)" size={30} />
    ,
    [CampaignOptions.Google]: <SiGoogleads color="rgb(33, 111, 228)" size={30} />
    ,
    [CampaignOptions.Email]: <MdEmail color="rgb(33, 111, 228)" size={30} />
  }
  const options = [...new Set(activeChannels)]?.map(option => {
    const optionName = CampaignActivityTypes[option];
    if (!optionName) return null

    return <Button
      key={option}
      variant="light"
      active={selected === optionName}
      onClick={() => setSelected(optionName)}
      className="d-flex flex-row px-4 gap-1 align-items-center justify-content-center "
    >
      {icons[optionName]}
      {optionName}
    </Button>
  }
  ).filter(x => x)
  return (
    <ButtonGroup
      aria-label="Basic example"
      // className="py-2"
      style={{ background: "#fff", }}
      className="d-flex flex-row gap-1 flex-wrap p-1 rounded "
    >
      {options && options?.length === 1 ?
        options?.map(option => option)
        : <>
          <Button
            variant="light"
            active={selected === CampaignOptions.Overview}
            onClick={() => setSelected(CampaignOptions.Overview)}
          >
            Alla annonser
          </Button>
          {options?.map(option => option)}

        </>
      }
    </ButtonGroup>
  );
}
