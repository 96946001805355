import { Col, Row, Stack } from "react-bootstrap";
import EmailEditor from "../../components/EmailEditor";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import Agent from "api/Agent";

const EmailStep1 = ({
  emailEditorRef,
  SetEditorReady,
  templateID,
  currentActivity
}) => {
  const { data: mail, isLoading, error } = Agent.EmailTemplate.GET.ID(templateID ?? currentActivity?.ad?.emailTemplateID)

  if (templateID) {
    if (error) {
      return <>Ett fel uppstod</>
    }
    if (isLoading) return <>Laddar...</>
  }
  return (
    <Row className="row-cols-1 h-100 px-0 m-0" style={{ paddingBottom: "80px" }}>
      <Col>
        <Row className="justify-content-between">
          <Col>
            <h4 className="display-sm semi-bold">Anpassa utskicket</h4>
          </Col>
          <Col className="col-2 d-flex align-items-center justify-content-end">
            {statusBadgeSetter(currentActivity)}
          </Col>
        </Row>
      </Col>


      <Col className="mx-auto p-0 0" style={{ minWidth: "1100px" }}>
        <EmailEditor
          SetEditorReady={SetEditorReady}
          emailEditorRef={emailEditorRef}
          template={mail?.json}

        />

      </Col>
    </Row>
  );
};

export default EmailStep1;
