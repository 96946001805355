import React, { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row, Spinner } from "react-bootstrap";
import { MdCheckCircle, MdImage } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAvailableImages,
  uploadNewAdImage,
} from "features/campaign/campaign-details/api";
import DragAndDropFile from "features/campaign/campaign-details/components/DragAndDropFile/DragAndDropFile";
import PaginationBar from "components/pagination/PaginationBar";
import Agent from "api/Agent";

const PAGING_LENGTH = 6;

const UPLOAD_STATES = {
  NEUTRAL: "Ladda upp",
  SUCCESS: "Uppladdad",
  ERROR: "Error",
};

const QUERYKEY = "meta/availableImages";

export default function ImagePickerModal({
  activityId,
  offerId,
  campaignId,
  setSelectedImage,
  selectedImage,
  metaAdObject,
  setMetaAdObject,
  imageHash,
  disabled
}) {
  const [show, setShow] = useState(false);
  const [userUpload, setUserUpload] = React.useState([]);
  const [image, setImage] = React.useState(null);
  const [imgFormData, setImgFormData] = useState(null);
  const queryClient = useQueryClient();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImage(selectedImage);
    setShow(true);
  };
  //Offerimages
  const { data: offer, isLoading: offersLoading } =
    Agent.Offers.GET.ID(offerId);

  //

  const [page, setPage] = useState(1);

  const { data, isLoading, error, refetch, isRefetching } =
    Agent.CampaignsActivity.GET.AVAILABLEIMAGES(
      activityId,
      metaAdObject.adAccountId
    );

  useEffect(() => {
    if (metaAdObject.adAccountId) {
      refetch();
    }
  }, [metaAdObject.adAccountId]);

  const uploadNewImage = useMutation(
    (file) => uploadNewAdImage(activityId, file, metaAdObject.adAccountId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERYKEY);
      },
    }
  );

  useEffect(() => {
    const templateImage = data?.find((img) => img?.hash?.includes(imageHash) || img?.id?.includes(imageHash));
    if (templateImage !== -1) setSelectedImage(templateImage);
  }, [imageHash]);
  useEffect(() => {
    const templateImage = data?.find((img) => img?.hash?.includes(imageHash) || img?.id?.includes(imageHash));
    if (templateImage !== -1) setSelectedImage(templateImage);
  }, []);

  const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    uploadNewImage.mutate(file);
  };

  const handleImageSelect = (img) => {
    const isAlreadySelected = image?.id === img?.id;
    if (isAlreadySelected) return setImage(null);
    setImage(img);
    setMetaAdObject({ ...metaAdObject, imageId: img.id });
  };

  const handleSubmit = () => {
    setSelectedImage(image);
    handleClose();
  };

  return (
    <Col className="mx-auto mb-2 p-1">
      {isRefetching ? (
        <Spinner />
      ) : (
        <div
          className="d-flex justify-content-center h-100 position-relative"
          onClick={!disabled && metaAdObject.adAccountId  ? handleShow : null}
        >
          <Image
            rounded
            src={
              selectedImage?.url
                ? selectedImage.url
                : selectedImage?.imageUrl
                ? selectedImage.imageUrl
                : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            }
            style={{
              width: selectedImage ? "300px" : "100%",
              height: selectedImage ? "auto" : "150px",
              backgroundColor: !selectedImage && "#eeecec",
              border: !selectedImage && "4px dotted lightgray",
            }}
          />
          {!selectedImage && (
            <span
              className={
                  !disabled ?
                   "semi-bold image-picker-container"
                   :
                                       "semi-bold image-picker-container-disabled"
              }
            >
              <MdImage size={50} /> <br />{" "}
                {
                  !disabled &&
                  <p className="text-lg">Välj en bild</p>
                }
            </span>
          )}
        </div>
      )}

      <Modal
        className="image-picker-modal"
        show={show}
        onHide={handleClose}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bild galleri</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex align-items-baseline flex-column gap-3">
          <Row className="w-100">
            <Col className="col-12">
              <h3 className=" text-lg semi-bold me-auto">Lägg till bilder</h3>
            </Col>
            <Col className="col-12">
              <DragAndDropFile
                loading={uploadNewImage.isLoading}
                handleImageUpload={handleImageUpload}
                setFiles={setUserUpload}
                files={userUpload}
              />
            </Col>
          </Row>

          <Row>
            {offerId && offer && offer?.imageUrl && (
              <Col className="col-5">
                <h3 className=" text-lg semi-bold me-auto">
                  Bilder från erbjudande
                </h3>

                <Row className="w-100 row-cols-1">
                  {offer?.imageUrl && (
                    <div
                      key={offer.id}
                      className="mw-100 p-2 overflow-hidden position-relative"
                      onClick={() => handleImageSelect(offer)}
                    >
                      <Image
                        rounded
                        style={{
                          display: "block",
                          maxWidth: "100%",
                          width: "auto",
                          height: "auto",
                          maxHeight: "300px",
                          opacity: image?.id === offer.id ? "0.5" : "1",
                          userSelect: "none",
                        }}
                        className="mx-auto"
                        src={offer.imageUrl}
                        alt=""
                      />
                      {image?.id === offer?.id && (
                        <MdCheckCircle
                          style={{
                            position: "absolute",
                            fontSize: "6rem",
                            bottom: "calc(40%)",
                            zIndex: "10",
                            width: "100%",
                            userSelect: "none",
                          }}
                        />
                      )}
                    </div>
                  )}
                </Row>
              </Col>
            )}

            <Col>
              <h3 className=" text-lg semi-bold me-auto">Uppladdade bilder</h3>

              {isLoading && <span>Hämtar och laddar in bilder...</span>}
              {error && <span>Ett fel uppstod vid hämtning av bilder.</span>}

              <Row className="w-100 row-cols-3">
                {data &&
                  data
                    .sort((a, b) => {
                      if (a.id === selectedImage?.id) {
                        return -1;
                      } else if (b.id === selectedImage?.id) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })
                    .slice(
                      page * PAGING_LENGTH - PAGING_LENGTH,
                      page * PAGING_LENGTH
                    )
                    .map((img, index) => (
                      <div
                        key={index}
                        className="mw-100 p-2 overflow-hidden position-relative"
                        onClick={() => handleImageSelect(img)}
                      >
                        <Image
                          rounded
                          style={{
                            display: "block",
                            maxWidth: "100%",
                            width: "auto",
                            height: "auto",
                            maxHeight: "300px",
                            opacity: image?.id === img.id ? "0.5" : "1",
                            userSelect: "none",
                          }}
                          className="mx-auto"
                          src={img.url}
                          alt=""
                        />
                        {image?.id === img?.id && (
                          <MdCheckCircle
                            style={{
                              position: "absolute",
                              fontSize: "6rem",
                              bottom: "calc(40%)",
                              zIndex: "10",
                              width: "100%",
                              userSelect: "none",
                            }}
                          />
                        )}
                      </div>
                    ))}
              </Row>
              <PaginationBar
                currentPage={page}
                setCurrentPage={setPage}
                countPerPage={PAGING_LENGTH}
                totalCount={data?.length}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Stäng
          </Button>
          <Button
            type="submit"
            variant="success"
            onClick={() => {
              handleSubmit();
            }}
          >
            Välj
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}
