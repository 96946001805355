import Agent from 'api/Agent'
import PaginationBar from 'components/pagination/PaginationBar';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Card, Col, Form, Spinner, Table } from 'react-bootstrap'
import styles from "../../../style.module.css";

export default function CampaignConversions({ activity }) {
  const [currentPage, setCurrentPage] = useState(1);
  const countperPage = 7;

  const { data, isLoading, isError } = Agent.CampaignsActivity.GET.CONVERSIONS(activity?.id, currentPage, countperPage)

  useEffect(() => { setCurrentPage(1) }, [])

  return <Col className='mb-2'>
    <Card className="card-container p-2 pb-0 d-flex align-content-center" bg="light">

      <div className={styles.cardRow}>
        <Col>
          <h4 className="text-lg semi-bold text-capitalize p-1">Detaljerade konverteringar</h4>
        </Col>
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th className='text-sm semi-bold ' align='center'>
              Företag 
            </th>
            <th className='text-sm semi-bold text-center' align='center'>
              Produkt
            </th>
            <th className='text-sm semi-bold text-center' align='center'>
              Första köp 
            </th>
            <th className='text-sm semi-bold text-center' align='center'>
              Antal
            </th>
            <th className='text-sm semi-bold text-center' align='center'>
              Pris
            </th>
            <th className='text-sm semi-bold text-center' align='center'>
              Datum
            </th>

          </tr>
        </thead>
        <tbody>
          { isLoading ?
            <tr>
              <td colSpan={99} className="text-center" >
                <Spinner className='my-2'/>
              </td>
            </tr>
            :
            isError ?
              <tr>
                <td colSpan={99} className="text-center" >
                  <span>Ett fel uppstod. Försök igen..</span>
                </td>
              </tr>
              :
              !data?.Results?.length ?
                <tr>
                  <td colSpan={99} className="text-center" >
                    <span>Inga resultat</span>
                  </td>
                </tr>
                : data?.Results?.map((prop, index) =>
                  <tr key={index}>
                    <td className="text-md " >{prop?.company ? prop?.company : "-"}</td>
                    <td className="text-md " align='center'>{prop?.productname ? prop?.productname : "-"}</td>
                    <td className="text-md " align='center'><Form.Check checked={prop?.isfirstpurchase} disabled/></td>
                    <td className="text-md " align='center'>{prop?.quantity ? prop?.quantity + " st" : "-" }</td>
                    <td className="text-md " align='center'>{prop?.price ? Math.round(prop?.price).toLocaleString() + " kr" : "-"}</td>
                    <td className="text-md " align='center' style={{minWidth:"120px"}}>{prop?.transactiondate && moment(prop?.transactiondate).isValid() ? moment(prop?.transactiondate).format('yyyy-MM-DD') : "-"}</td>
                  </tr>
                )}

        </tbody>

      </Table>
        {
          data?.Paging && data?.Results?.length ?
          <caption className='p-0 '>
            <PaginationBar paging={data?.Paging} showTotal={true} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </caption> : <></>
        }
    </Card>
  </Col>
}
