import "../style.scss";
import { useState } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import logo from "assets/img/autotarget-logo-blue.png";
import logoMerInfo from "assets/img/merinfo.png";

import LoginForm from "./LoginForm";

const LoginContainer = ({ ifBigScreen, siteUrl }) => {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Container fluid className="login-form-container">
      <Row className="flex-column justify-content-between h-100 py-4">
        <Col className="col-12">
          <Image
            className="logo-img"
            fluid
            src={siteUrl === "merinfo" ? logoMerInfo : logo}
          />
        </Col>
        <Col className="col-12">
          <LoginForm
            siteUrl={siteUrl}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </Col>
        <Col className="col-12">
          <p className="text-sm regular">{`@ ${
            siteUrl === "merinfo" ? "Merinfo" : "Autotarget"
          } 2023`}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginContainer;
