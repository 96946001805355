import { store } from "app/store";
import axios from "axios";

const BASE_URL = process.env['REACT_APP_API_URL'];

const useToken = (config) => {
  const token = store.getState().session?.token; 
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
}
// AUTH API
export const axiosLogin = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// Add the interceptor to the AUTH API Axios instance
axiosLogin.interceptors.request.use(useToken);

// FETCH APIs
const MOCK_BASE_URL = "http://localhost:3500";
//*RUN MOCK SERVER: json-server --watch src/data/db/db.json --routes src/data/db/routes.json --port 3500

// Create a separate Axios instance for other requests
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// Add the interceptor to the FETCH APIs Axios instance
axiosInstance.interceptors.request.use(useToken);

export default axiosInstance;


