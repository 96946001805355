import { CampaignActivityStateOptions } from "features/campaign/campaign-details/options";
import { useMutation, useQueryClient } from "react-query";
import { useContext } from "react";
import { EmailContext } from "../Email";
import Agent from "api/Agent";
import { useState } from "react";
import {
  BsEyeFill,
  BsFillCheckCircleFill,
  BsFillSendCheckFill,
  BsFillPlayCircleFill,
  BsPauseCircleFill,
  BsSendCheckFill,
} from "react-icons/bs";
import { Button, Col, Row, Modal, Form, Spinner } from "react-bootstrap";
import { MdOutlineError } from "react-icons/md";
import {
  updateCampaignActivity,
  sendCampaignEmail,
  unscheduleEmail,
} from "features/campaign/api";
import SendTestModal from "./SendTestModal";

export default function EmailStates() {
  const { activity, campaign, segment } = useContext(EmailContext);
  // const { mutate: sendMail } = Agent.Campaigns.POST.SENDMAIL(id, activityId);

  console.log(activity.id);
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: publishLoading,
    isError: publishIsError,
  } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );

  const { mutate: sendMail, isLoading: sendingLoading } = useMutation(
    () => sendCampaignEmail(activity.campaignID, activity.id),
    {
      onSuccess: () => {
        const scheduledEmail = {
          ...activity,
          state: 2,
          isActive: true,
        };
        mutate(scheduledEmail);
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );
  const scheduleEmail = (e) => {
    e.preventDefault();
    // const scheduledEmail = {
    //   ...activity,
    //   state: 2,
    //   isActive: true,
    // };

    sendMail();
  };

  const { mutate: unscheduleMail, isLoading: unscheduleLoading } = useMutation(
    () => unscheduleEmail(activity.campaignID, activity.id),
    {
      onSuccess: () => {
        const pausedSendout = {
          ...activity,
          state: 0,
        };

        mutate(pausedSendout);
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );
  const pausSendout = (e) => {
    unscheduleMail();
    // e.preventDefault();
  };

  const testCancel = () => {
    const pausedSendout = {
      ...activity,
      state: 0,
    };

    mutate(pausedSendout);
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const today = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  console.log(today < activity.ad.startDate);

  const [showModal, setShowModal] = useState(false);

  const States = {
    [CampaignActivityStateOptions.Preparing]: (
      <Row className="mt-5 g-4" style={{ color: "#DDCD9E" }}>
        <Col className="col-12 d-flex justify-content-center align-items-center">
          <BsEyeFill size={94} />
        </Col>
        <Col className="col-12 text-center">
          <h5 className="display-sm bold">
            Ditt utskick granskas av email-leverantören. <br />
            <span
              style={{
                display: "block",
                lineHeight: "25px",
                fontSize: "12px",
                color: "#c5c5c5",
                fontWeight: "400",
                fontStyle: "italic",
                marginTop: "10px",
              }}
            >
              Detta kan ta en stund baserat på målgruppsstorleken. När
              granskningen är klar kan du återvända hit för att skicka
              iväg/scehmalägga ditt utskick.
            </span>
          </h5>
        </Col>
      </Row>
    ),
    [CampaignActivityStateOptions.Ready]: (
      <Row className="mt-5 g-4" style={{ color: "#42A146" }}>
        <Col className="col-12 d-flex justify-content-center align-items-center">
          <BsFillCheckCircleFill size={94} />
        </Col>
        <Col className="col-12 text-center">
          <h5 className="text-lg bold">
            Din målgrupp och utskick är godkänt och redo att
            skickas/schemaläggas!
          </h5>
        </Col>
        <Col className="col-12 d-flex justify-content-evenly">
          <Button
            className="d-flex align-items-center semi-bold"
            variant={activity.ad.startDate < today ? "warning" : "success"}
            style={{
              backgroundColor: activity.ad.startDate < today ? "#cc6249" : "",
            }}
            onClick={
              activity.ad.startDate > today ? (e) => scheduleEmail(e) : null
            }
          >
            {sendingLoading ? (
              <Spinner />
            ) : activity.ad.startDate < today ? (
              "Tid passerat"
            ) : (
              <>
                Skicka/Schemalägg <BsFillPlayCircleFill className="mx-2" />
              </>
            )}
          </Button>
          <Button
            className="d-flex align-items-center semi-bold"
            variant="secondary"
            onClick={() => setShowModal(true)}
          >
            Gör ett testutskick
            <BsSendCheckFill className="mx-2" />
          </Button>
        </Col>
        {showModal && (
          <SendTestModal showModal={showModal} setShowModal={setShowModal} />
        )}
      </Row>
    ),
    [CampaignActivityStateOptions.Running]: (
      <Row className="mt-5 g-4" style={{ color: "#42A146" }}>
        {activity.statusReason === 30 || today > activity.ad.startDate ? (
          <>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <BsFillSendCheckFill size={94} />
            </Col>
            <Col className="col-12 text-center">
              <h5 className="text-lg bold">
                Ditt utskick har genomförts och skickades{" "}
                {`${activity.ad.startDate.substring(
                  0,
                  10
                )} kl. ${activity.ad.startDate.substring(11, 16)}`}
                ! <br /> Följ kampanjens statistik via statistik-fliken.
              </h5>
            </Col>
          </>
        ) : (
          <>
            <Col className="col-12 d-flex justify-content-center align-items-center">
              <BsFillPlayCircleFill size={94} />
            </Col>
            <Col className="col-12 text-center">
              <h5 className="text-lg bold">
                Ditt utskick är schemalagt och kommer skickas{" "}
                {`${activity.ad.startDate.substring(
                  0,
                  10
                )} kl. ${activity.ad.startDate.substring(11, 16)}`}
                !
              </h5>
            </Col>
            <Col className="col-12 d-flex justify-content-center">
              <Button
                className="d-flex align-items-center semi-bold"
                variant="warning"
                onClick={(e) => pausSendout(e)}
              >
                Pausa & redigera <BsPauseCircleFill className="mx-2" />
              </Button>
            </Col>
          </>
        )}
      </Row>
    ),
    [CampaignActivityStateOptions.OnHold]: (
      <Row className="mt-5 g-4" style={{ color: "#C8C58B" }}>
        <Col className="col-12 d-flex justify-content-center align-items-center">
          <BsPauseCircleFill size={94} />
        </Col>
        <Col className="col-12 text-center">
          <h5 className="text-lg bold">
            Ditt utskick är för tillfället pausat.
          </h5>
        </Col>
        <Col className="col-12 d-flex justify-content-center">
          <Button
            className="d-flex align-items-center semi-bold"
            variant="success"
            onClick={(e) => scheduleEmail(e)}
          >
            Schemalägg <BsFillPlayCircleFill className="mx-2" />
          </Button>
        </Col>
        <Col className="col-12 d-flex justify-content-center">
          <Button
            className="d-flex align-items-center semi-bold"
            variant="success"
            onClick={(e) => testCancel(e)}
          >
            Test <BsFillPlayCircleFill className="mx-2" />
          </Button>
        </Col>
      </Row>
    ),
    [CampaignActivityStateOptions.Error]: (
      <Row className="mt-5 g-4" style={{ color: "#dc3545" }}>
        <Col className="col-12 d-flex justify-content-center align-items-center">
          <MdOutlineError size={94} />
        </Col>
        <Col className="col-12 text-center">
          <h5 className="text-lg bold">Ett fel uppstod med annons</h5>
        </Col>
        <Col className="col-12 d-flex justify-content-center"></Col>
      </Row>
    ),
  };

  return States[activity?.state];
}

// SJP Live med Magnus Betnér
// You're in for a treat!
