import { Row, Col, Container, Table } from "react-bootstrap";
import { ButtonMain } from "components/buttons";
import { CustomersTable, NewCustomerForm } from "features/customers";
import { JobsTable } from "features/job";

const Jobs = () => {
  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className="justify-content-between">
            <Col>
              <h2>Mina jobb</h2>
            </Col>
            <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
           
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <JobsTable/>
        </Col>
      </Row>
    </Container>
  );
};

export default Jobs;
