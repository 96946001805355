import React from "react";
import { Col, Form, Stack } from "react-bootstrap";
import RangeSlider from "react-range-slider-input";
import { useState } from "react";
import style from "./assets/style.module.css";

export default function EditEmailTargetGroup({segment, activity}) {
  const rangeSettings = { min: 0, max: segment?.size, step: 1 };
  const [rangeSliderValue, setRangeSliderValue] = useState(activity && activity["targetGroupMaxSize"] ? activity["targetGroupMaxSize"] : rangeSettings?.max);

  const handleRangeInput = (value) => {
    if (value > rangeSettings.max) return;
    if (value < rangeSettings.min) return;
    setRangeSliderValue(value);
  };

  return (
    
    <Stack>

      <Col>
      <Form.Label
        htmlFor="activity_targetGroupMaxSize"
        className="p-0 text-md"
        style={{ color: "#344054" }}
      >
        Antal utskick i målgrupp
      </Form.Label>
      <div className={style["flex-align-center"]}>
        <Form.Control
          type="text"
          name="activity_targetGroupMaxSize"
          value={rangeSliderValue}
          required
          onChange={(e) =>
            handleRangeInput(!e.target.value ? 0 : +e.target.value)
          }
          style={{ flex: "1", minWidth: "100px" }}
        />
        <div
          className={[style["flex-align-center"], style["slider"], "py-1"].join(
            " "
          )}
          style={{
            flex: "8",
          }}
        >
          <div style={{ flex: "6" }} className="px-2  my-auto">
            <RangeSlider
              thumbsDisabled={[true, false]}
              value={[0, rangeSliderValue]}
              onInput={(e) => setRangeSliderValue(e[1])}
              min={rangeSettings.min}
              max={rangeSettings.max}
              className="single-thumb"
              step={rangeSettings.step}
            />
          </div>
          <Form.Control
            style={{ flex: "4", minWidth: "70px" }}
            disabled
            className=" text-center"
            type="text"
            value={rangeSettings.max}
          />
        </div>
      </div>
      </Col>

    </Stack>
  );
}
