import React, { useEffect } from "react";
import { GoogleMapMain } from "components/maps";
import { Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { getVisualMapData } from "components/maps/google-map-main/assets/api";
import { GetSelectedPeriod } from "../assets/hooks";
import { useState } from "react";
import { useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

export default function VisualSegmentedMap({ ID, addDefaultOptionsToChart, handleChartBackgroundColor }) {
  const filters = useSelector((state) =>
    state.statistics.filters)
  const [visualData, setVisualData] = useState(null);
  const mapRef = useRef(null);
  const { startDate, endDate } = GetSelectedPeriod();
  const { mutate: getVisualData } = useMutation({
    mutationFn: () => {
      if (!mapRef.current) return () => null;
      const bounds = mapRef.current.getBounds();
      const mapPayload = {
        neLat: bounds.getNorthEast().lat(),
        neLng: bounds.getNorthEast().lng(),
        swLat: bounds.getSouthWest().lat(),
        swLng: bounds.getSouthWest().lng(),
      };
      return getVisualMapData(ID, startDate, endDate, mapPayload, filters);
    },
    onSuccess: (data) => {
      setVisualData(data);
    },
  });

  const handleDebouncedFilterUpdate = debounce(async () => {
    getVisualData();
  }, 500);

  useEffect(() => {
    handleDebouncedFilterUpdate();
  }, [filters?.length]);

  return (
    <Row className="h-100">
      {/* LEFT */}
      <div className="col-6 col-lg-8 h-100">
        <GoogleMapMain
          visualID={ID}
          height="400px"
          data={visualData?.Map?.areas}
          fetchData={getVisualData}
          mapRef={mapRef}
        />
      </div>

      {/* RIGHT */}
      <div className={"col-6 col-lg-4"}>
        {visualData && (
          <Bar
            data={visualData?.Chart.data}
            options={{
              ...visualData?.Chart.options,
              
              backgroundColor: handleChartBackgroundColor(0),
              ...addDefaultOptionsToChart
            }}
          />
        )}
      </div>
    </Row>
  );
}
