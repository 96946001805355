import React from 'react'
import { FiEdit3 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function EditCampaignButton({item}) {
  const navigate = useNavigate();

  const onEditClick = (event, id) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    navigate(`/campaigns/create-campaign/${id}/type-of-campaign`);
  };

  return (
    <button
      className="edit-button"
      onClick={(event) =>
        onEditClick(event, item.id)
      }
    >
      <FiEdit3 size={18} />
    </button>
  )
}
