import React, { useState, useEffect, useCallback } from "react";
import { useJsApiLoader, GoogleMap, InfoWindowF } from "@react-google-maps/api";
import { Button, Spinner } from "react-bootstrap";
import { mapOptions, libraries } from "./assets/options";
import { useDebouncedCallback } from "./assets/hooks";
import { infoBoxTemplate, polygonRenderer } from "./polygonRenderer";
import { useSelector } from "react-redux";

const defaults = {
  address: "Stockholm",
  height: "100%",
};

/*
https://github.com/JustFly1984/react-google-maps-api/issues/2978
Compatibility Issue with React 18+. ReactStrictMode will disable rendering  Add prefix "F" to elements
*/
const GoogleMapMain = ({
  visualID,
  mapRef,
  address = defaults.address,
  height = defaults.height,
  fetchData,
  data,
}) => {
  const { filters } = useSelector((state) => state.statistics);
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: API_KEY,
    libraries,
  });

  const [center, setCenter] = useState(null);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const [postalPolygons, setPostalPolygons] = useState([]);
  const [infoBoxOpen, setInfoBoxOpen] = useState(false);
  const [infoBoxContent, setInfoBoxContent] = useState("");
  const [infoBoxPosition, setInfoBoxPosition] = useState(null);

  const onIdle = useCallback(() => {
    if (!mapRef || !fetchData) return;
    fetchData();
  }, []);

  const onIdleDebounced = useDebouncedCallback(onIdle, 1000);

  const onLoad = useCallback(function callback(map) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        const bounds = new window.google.maps.LatLngBounds({
          lat: location.lat(),
          lng: location.lng(),
        });
        map.fitBounds(bounds);

        const defaultZoomListener = window.google.maps.event.addListener(
          map,
          "idle",
          () => {
            if (map.getZoom() > mapOptions.zoom) map.setZoom(mapOptions.zoom);
            window.google.maps.event.removeListener(defaultZoomListener);
          }
        );

        setCenter(map.getCenter());
        if (mapRef?.current) {
          mapRef.current = map;
        }
      } else {
        console.error(
          `Geocode was not successful for the following reason: ${status}`
        );
      }
    });
  }, []);

  useEffect(() => {
    if (!data || !isLoaded) return;
    const polygons = polygonRenderer(
      data,
      setInfoBoxContent,
      setInfoBoxOpen,
      setInfoBoxPosition
    );
    setPostalPolygons(polygons);
  }, [isLoaded, data]);
  useEffect(() => {
    if (filters?.length) return;
    setTimeout(() => setDataIsLoaded(false), 1000);
  }, [filters]);

  useEffect(() => {
    if (!data) return;
    if (dataIsLoaded) return;

    setDataIsLoaded(true);

    const postal = data.find((_postal) => _postal);
    if (!postal) return;
    const collectionOfOneCoordFromEachPostal = data.map((_postal) =>
      _postal?.coordinates.map((x) => x.find((coord) => coord))
    );

    if (!collectionOfOneCoordFromEachPostal) return;
    const polygonBounds = new window.google.maps.LatLngBounds();
    collectionOfOneCoordFromEachPostal.forEach((coordArr) =>
      coordArr.find((coord) => polygonBounds.extend(coord))
    );
    const polygonCenter = polygonBounds.getCenter();

    if (!mapRef?.current) return;
    const map = mapRef.current;
    map.fitBounds(polygonBounds);
    if (map.getZoom() < 5) map.setZoom(5);
    setCenter(polygonCenter);
  }, [dataIsLoaded, data]);

  useEffect(() => {
    try {
      if (!data) return;
      if (!filters) return;

      const selectedPostal = data.find((postal) =>
        filters.some((_filter) =>
          _filter?.values?.find(
            (value) =>
              postal.title.toLowerCase().replace(/\s/g, "") ===
              value.toLowerCase().replace(/\s/g, "")
          )
        )
      );
      if (!selectedPostal) return;

      const coordinates = selectedPostal.coordinates.find((x) => x);
      if (!coordinates) return;

      const polygonBounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coord) => polygonBounds.extend(coord));
      const polygonCenter = polygonBounds.getCenter();

      const content = infoBoxTemplate(selectedPostal);

      const isAlreadyRendered =
        JSON.stringify(content) === JSON.stringify(infoBoxContent);
      if (isAlreadyRendered) return;

      if (!mapRef?.current) return;
      const map = mapRef.current;
      map.fitBounds(polygonBounds);

      setInfoBoxOpen(true);
      setInfoBoxContent(content);
      setInfoBoxPosition(polygonCenter);
    } catch (e) {
      console.error("There was an error on the user select from chart to map");
    }
  }, [filters, data]);

  useEffect(() => {
    setInfoBoxOpen(false);
    setInfoBoxContent(null);
  }, [filters]);

  if (!isLoaded) {
    return (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Laddar in område...
      </Button>
    );
  }

  if (loadError) {
    return (
      <Button variant="danger" disabled>
        Ett fel uppstod vid inladdning av kartan... försök igen senare.
      </Button>
    );
  }

  return (
    <div style={{ height: height }}>
      <GoogleMap
        ref={mapRef}
        options={mapOptions}
        mapContainerStyle={{
          minHeight: "27vh",
          width: "100%",
          height: "100%",
          borderRadius: "5px",
        }}
        onLoad={onLoad}
        onIdle={onIdleDebounced}
        center={center && center}
      >
        {postalPolygons && postalPolygons.map((x) => x)}
        {infoBoxOpen && (
          <InfoWindowF
            position={infoBoxPosition}
            onCloseClick={() => setInfoBoxOpen(false)}
          >
            {infoBoxContent}
          </InfoWindowF>
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapMain;
