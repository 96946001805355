import { Col, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { getCustomerSettings } from "features/settings/api";
import { segmentTypes } from "features/segment/segment-details/assets/config";
import { useEffect } from "react";

const TargetTypeSelect = ({ metaAdObject, setMetaAdObject, segment }) => {
  const { data: settings } = useQuery("customer", getCustomerSettings);
  useEffect(() => {
    if (segment) {
      setMetaAdObject({
        ...metaAdObject,
        directMarketingTarget: metaAdObject.directMarketingTarget
          ? metaAdObject.directMarketingTarget
          : segment.type === 0
          ? 1
          : segment.type === 1
          ? 3
          : null,
      });
    }
  }, [segment]);
  return (
    <>
      {segment?.type === segmentTypes.Company && (
        <Col className="col-12 mb-5">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="py-3 text-xl semi-bold"
            onChange={(e) =>
              setMetaAdObject({
                ...metaAdObject,
                directMarketingTarget: e.target.value,
              })
            }
            defaultValue={1}
          >
            <option value={1}>Alla personer i styrelsen</option>
            <option value={2}>Ordförande, VD & styrelseledamöter</option>

            {settings?.isContactPersonsTargetingAvailable && (
              <option value={5}>Kontaktpersoner</option>
            )}
          </Form.Select>
        </Col>
      )}
      {segment?.type === segmentTypes.Person && (
        <Col className="col-12 mb-5">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            className="py-3 text-xl semi-bold"
            aria-label="Default select example"
            onChange={(e) =>
              setMetaAdObject({
                ...metaAdObject,
                directMarketingTarget: e.target.value,
              })
            }
            defaultValue={3}
          >
            <option value={3}>Personer i målgruppen</option>
            <option value={4}>Övriga personer i hushållet</option>
            <option value={6}>Alla i hushållet</option>
          </Form.Select>
        </Col>
      )}
      {/* <Col
        className="col-12 d-flex text-end display-sm semi-bold justify-content-end"
        style={{ color: "rgb(189 189 189)" }}
      >
        <h5>
          Total räckvidd:
          <span className="bold" style={{ color: "#000" }}>
            {" "}
            {metaAdObject.targetGroupSize}{" "}
            {segment.type === 0
              ? "Företag"
              : segment.type === 1
              ? "Personer"
              : ""}
          </span>
        </h5>
      </Col> */}
    </>
  );
};

export default TargetTypeSelect;
