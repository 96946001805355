import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { MdBarChart, MdDeleteForever, MdEdit, MdMoreVert, MdSettings, MdTableRows } from 'react-icons/md';
import { RemoveVisual, ToggleVisualTable } from '../../../features/stats/assets/statisticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import VisualCardModifySettingsModal from './VisualCardModifySettingsModal';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >

        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

export default function VisualCardOptions({ visual }) {
  const viewAsTable = useSelector(state => state.statistics.visual.viewAsTable)
  const toggleTable = viewAsTable?.find(x => x === visual?.id)
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <Dropdown drop="start">
        <Dropdown.Toggle as={CustomToggle}  >
          <MdMoreVert />
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          <Dropdown.Item
            eventKey="1"
            style={{ backgroundColor: "#FFF", borderColor: "#FFF" }}
            className="d-flex align-items-center justify-content-start"
            onClick={() => { dispatch(RemoveVisual(visual.id)) }}
          >
            <MdDeleteForever className=" text-danger me-1" />
            <span className=" text-danger bold " >Ta bort</span>
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            style={{ backgroundColor: "#FFF", borderColor: "#FFF" }}
            className="d-flex align-items-center justify-content-start"
            onClick={() => { dispatch(ToggleVisualTable(visual.id)) }}
          >
            {toggleTable ? <>
            <MdTableRows className="text-dark  me-1" />
            <span className=" text-dark  bold " >Visa graf</span>
            </> : <>
            <MdBarChart className="text-dark  me-1" />
            <span className=" text-dark  bold " >Visa tabell</span>
            </>}
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="3"
              style={{ backgroundColor: "#FFF", borderColor: "#FFF" }}
              className="d-flex align-items-center justify-content-start"
              onClick={() => { handleShow() }}
            >
              <MdSettings className="text-dark  me-1" />
              <span className=" text-dark  bold " >Ändra</span>
            </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>

      <VisualCardModifySettingsModal onClose={handleClose} visible={show} visual={visual} />
    </>
  );

};
