import { useContext } from "react"
import { Alert, Form, Row } from "react-bootstrap"
import { TemplateModalContext } from "./TemplateModal"

const GenericSettings = () => {
  const { setRemovable, removable, setRename, rename } = useContext(TemplateModalContext)
  return (
    <Row className='d-flex flex-row row-cols-auto  align-items-center justify-content-between  py-2'>
      <Alert variant='secondary ' className='d-flex flex-row align-items-center justify-content-start gap-1 p-2 shadow-sm rounded' style={{ width: "240px" }}>
        <Form.Switch checked={rename} onChange={() => setRename(!rename)} />
        <span className="bold py-1">Döp om mallar </span>
      </Alert>

      <Alert variant='danger' className='d-flex flex-row align-items-baseline justify-content-end gap-1 p-2 shadow-sm rounded' style={{ width: "240px" }}>
        <span className="bold py-1">Ta bort mallar</span>
        <Form.Switch checked={removable} onChange={() => setRemovable(!removable)} />
      </Alert>
    </Row>
  )
}

export default GenericSettings
