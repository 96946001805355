import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Card, Col, Container } from "react-bootstrap";
import styles from "../../../style.module.css";
import { EMAIL_CHART_COLORS, EMAIL_CHART_COLOR_OPACITY } from '../helper';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const CampaignMetricChart = ({ statistics = [] }) => {
  const items =
    [{
      label: "Vidarbefodringar",
      property: "forwards",
    },
    {
      label: "Spam rapporter",
      property: "abuseReports",
    },
    {
      label: "Uppsagda prenumeranter",
      property: "unsubscribed",
    },

    ]
  const itemsValueSum = items?.map(item => {
    try {
      return statistics?.flatMap(stats => stats[item.property])?.reduce((acc, val) => acc + val)
    }
    catch (e) {
      console.warn("issue parsing statistics in CampaignMetricChart. Error: \n", e)
      return 0
    }
  })

  const data = {
    labels: items?.map(x => x?.label),
    datasets: [
      {
        label: '# of Votes',
        data: itemsValueSum,
        backgroundColor: items?.map((_, i) => EMAIL_CHART_COLORS[i] + EMAIL_CHART_COLOR_OPACITY[60]),
        borderWidth: 1,
      },
    ],
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Col >
      <Card className="card-container p-2 " bg="light">
        <Container className="m-0 g-0" fluid>
          <div className={styles.cardRow}>
            <Col>
              <h4 className="text-lg semi-bold text-capitalize">Mail aktivitet</h4>
            </Col>
          </div>
          <div className={styles.cardRow} style={{ minHeight: "400px" }}>
            <PolarArea data={data} options={options} />
          </div>
        </Container>
      </Card>
    </Col>
  )
}

export default CampaignMetricChart
