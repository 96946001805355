import Agent from 'api/Agent';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Alert, Button, Form, Modal, Row } from 'react-bootstrap'
import { act } from 'react-dom/test-utils';
import { FaCog } from 'react-icons/fa'
import { toast } from 'react-toastify';
import EditAdImage from './components/EditAdImage';
import "./style.css"

export default function EditAdContent({ activity }) {

  const putAdMediaMutation = Agent.CampaignsActivity.PUT.ADMEDIA();
  // Modal
  const [viewAdContent, setViewAdContent] = useState(false);
  const handleClose = () => {
    setValidated(false)
    setContent({ title: "", desc: "", image: "" });
    setEnabled({ desc: false, title: false, img: false });
    setViewAdContent(false)
  };
  const handleShow = () => setViewAdContent(true);

  // Form
  const [content, setContent] = useState({ title: "", desc: "", image: "" });
  const [enabled, setEnabled] = useState({ desc: false, title: false, img: false });
  const [validated, setValidated] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return false;
    }

    if (Object.values(enabled).every(x => !x)) { console.warn("all inputs are disabled. no action taken"); return false; }

    const newAd = { ...activity?.ad };
    if (enabled.title) newAd.title = content.title;
    if (enabled.desc) newAd.description = content.desc;
    if (enabled.img) {
      const adMediaIndex = newAd?.adMedias?.indexOf(defaultAdMedia);
      newAd.adMedias.at(adMediaIndex).imageId = content.image?.id
    }

    await toast.promise(putAdMediaMutation.mutateAsync(({ activity: activity, adMediaId: defaultAdMedia?.id, ad: newAd })),
      {
        success: "innehåll uppdaterat",
        pending: "...",
        error: "Det gick inte att uppdatera innehållet"
      })
    if (putAdMediaMutation.isSuccess) {
      handleClose();
    }
  };

  //default ad img
  const [defaultImg, setDefaultImg] = useState(null);
  const defaultAdMedia = activity?.ad?.adMedias?.at(activity?.ad?.adMedias?.length - 1);
  const defaultImgId = defaultAdMedia?.imageId ?? defaultAdMedia?.externalId;

  const availableImagesQuery = Agent.CampaignsActivity.GET.AVAILABLEIMAGES(activity?.id, activity?.ad?.adAccountId);
  useEffect(() => {
    if (availableImagesQuery.isSuccess && !!defaultImgId) {
      setDefaultImg(availableImagesQuery?.data?.find(x => x?.id?.includes(defaultImgId)) ?? null);
    }
  }, [availableImagesQuery.isSuccess])

  return (
    <div>
      {/* Trigger modal */}
      <Button variant={"light"} className="py-1 px-2 bg-white border" onClick={handleShow}>
        <FaCog size={18} />
      </Button>


      {/* Modal of ad content */}
      <Modal show={viewAdContent} onHide={handleClose} centered>
        <Form noValidate validated={validated} onSubmit={handleSubmit} id="formAdContent">
          <Modal.Header closeButton>
            <Modal.Title>Ändra innehåll</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className='d-flex flex-column gap-3'>
              <div >
                <Row className='row-cols-auto justify-content-between align-items-baseline  m-0 p-0'>

                  <Form.Label className=" text-md semi-bold p-0">Rubrik</Form.Label>

                  <Alert variant='danger' className='d-flex flex-row px-2 py-2 gap-1 align-content-center my-1'>
                    <span className='text-sm bold '>
                      Ändra rubrik
                    </span>

                    <Form.Check type='switch' className='d-flex flex-column align-items-center ' >
                      <Form.Check.Input checked={enabled.title} onChange={() => setEnabled(prev => ({ ...prev, title: !prev.title }))} style={{ width: "28px", height: "12px" }} />
                    </Form.Check>
                  </Alert>

                </Row>

                <Form.Control
                  maxLength={150}
                  required
                  disabled={!enabled.title}
                  value={enabled.title ? content?.title : activity?.ad?.title}
                  onChange={(e) => { if (enabled.title) setContent(prev => ({ ...prev, title: e?.target?.value })) }}
                  as="textarea"
                  rows={2}
                />
                <Form.Control.Feedback type="invalid" className='text-md semi-bold'>
                  Ange en rubrik
                </Form.Control.Feedback>
              </div>

              <div >
                <Row className='row-cols-auto justify-content-between align-items-baseline  m-0 p-0'>

                  <span className=" text-md semi-bold  p-0">Beskrivning</span>

                  <Alert variant='danger' className='d-flex flex-row p-2 gap-1 align-content-center my-1'>
                    <span className='text-sm bold '>
                      Ändra beskrivning
                    </span>

                    <Form.Check type='switch' className={`d-flex flex-column align-items-center `}>
                      <Form.Check.Input checked={enabled.desc} onChange={() => setEnabled(prev => ({ ...prev, desc: !prev.desc }))} style={{ width: "28px", height: "12px" }} />
                    </Form.Check>
                  </Alert>
                </Row>

                <Form.Control
                  maxLength={300}
                  disabled={!enabled.desc}
                  value={enabled.desc ? content?.desc : activity?.ad?.description}
                  onChange={(e) => { if (enabled.desc) setContent(prev => ({ ...prev, desc: e?.target?.value })) }}

                  as="textarea"
                  rows={6}
                />

              </div>


              <div >
                <Row className='row-cols-auto justify-content-between align-items-baseline  m-0 p-0'>
                  <Form.Label className=" text-lg semi-bold me-auto">Annonsbild</Form.Label>

                  <Alert variant='danger' className='d-flex flex-row p-2 gap-1 align-content-center my-1'>
                    <span className='text-sm bold '>
                      Ändra annonsbild
                    </span>

                    <Form.Check type='switch' className='d-flex flex-column align-items-center' >
                      <Form.Check.Input checked={enabled.img} onChange={() => setEnabled(prev => ({ ...prev, img: !prev.img }))} style={{ width: "28px", height: "12px" }} />
                    </Form.Check>
                  </Alert>

                </Row>

                <Form.Group>
                  <Form.Control type='text' style={{ visibility: "hidden", height: "1px" }} value={!enabled.img ? "true" : content.image} required />
                  <EditAdImage
                    activity={activity}
                    disabled={!enabled.img}
                    onChange={(img) => {
                      if (enabled.img) setContent(prev => ({ ...prev, image: img }))
                    }}
                    value={enabled.img ? content?.image : defaultImg}
                  />
                  <Form.Control.Feedback type="invalid" className='text-md semi-bold'>
                    Välj en bild
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer >
            <Row className='w-100 row-cols-auto justify-content-between'>
              <Button variant="secondary" onClick={handleClose} type="button" className="text-md semi-bold">
                Stäng
              </Button>
              <Button variant="danger" type="submit" className="text-md semi-bold" disabled={!Object.values(enabled).some(x => !!x)}>
                Spara Ändringar
              </Button>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}
