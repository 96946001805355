import FilterBooleanButton from "features/filter/add-filters/components/FilterBooleanButton";
import FilterDatePicker from "features/filter/add-filters/components/FilterDatePicker";
import FilterDatePickerDynamic from "features/filter/add-filters/components/FilterDatePickerDynamic";
import FilterMap from "features/filter/add-filters/components/FilterMap";
import FilterMultiSelect from "features/filter/add-filters/components/FilterMultiSelect";
import FilterRangeSlider from "features/filter/add-filters/components/FilterRangeSlider";
import FilterRegularSelect from "features/filter/add-filters/components/FilterRegularSelect";
import FilterTextInput from "features/filter/add-filters/components/FilterTextInput";
import SaveFilterGroupModal from "features/filter/save-filter-group/SaveFilterGroupModal";
import React, { useState } from "react";
import { MdOutlineSave } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const FilterInputs = ({
  filter,
  segment,
  values,
  onChange,
  dynamicFilter,
  setDynamicFilter,
}) => {
  const [showModal, setShowModal] = useState(false);
  const FilterTypes = {
    Value: 1,
    Number: 2,
    Date: 3,
    Text: 4,
    Boolean: 5,
    SingleValue: 6,
    Coordinates: 7,
  };
  switch (filter.type) {
    case FilterTypes.Value:
      return (
        <Col className="col-12 position-relative">
          {showModal && (
            <SaveFilterGroupModal
              showModal={showModal}
              setShowModal={setShowModal}
              filter={filter}
              segment={segment}
            />
          )}
          <Row>
            <Col>
              <FilterMultiSelect
                filter={filter}
                segment={segment}
                parsedFilters={values}
                mutate={onChange}
              />
            </Col>
            <Col
              className="col-1 d-flex align-items-center justify-content-center"
              onClick={() => setShowModal(true)}
            >
              <MdOutlineSave
                style={{ cursor: "pointer" }}
                size={40}
                className="save-filtergroup"
              />
            </Col>
          </Row>
        </Col>
      );
    case FilterTypes.Number:
      return (
        <FilterRangeSlider
          filter={filter}
          segment={segment}
          parsedFilters={values}
          mutate={onChange}
        />
      );
    case FilterTypes.Date:
      return (
        <>
          {dynamicFilter ? (
            <FilterDatePickerDynamic
              filter={filter}
              segment={segment}
              parsedFilters={values}
              mutate={onChange}
              setDynamicFilter={setDynamicFilter}
            />
          ) : (
            <FilterDatePicker
              setDynamicFilter={setDynamicFilter}
              filter={filter}
              segment={segment}
              parsedFilters={values}
              mutate={onChange}
            />
          )}
        </>
      );
    case FilterTypes.Text:
      return (
        <FilterTextInput
          filter={filter}
          segment={segment}
          parsedFilters={values}
          mutate={onChange}
        />
      );
    case FilterTypes.Boolean:
      return (
        <FilterBooleanButton
          key={filter.id}
          filter={filter}
          segment={segment}
          parsedFilters={values}
          mutate={onChange}
          initialValue={null}
        />
      );
    case FilterTypes.SingleValue:
      return (
        <FilterRegularSelect
          filter={filter}
          segment={segment}
          parsedFilters={values}
          mutate={onChange}
        />
      );
    case FilterTypes.Coordinates:
      return (
        <FilterMap
          filter={filter}
          segment={segment}
          parsedFilters={values}
          mutate={onChange}
        />
      );
    default:
      return null;
  }
};

export default FilterInputs;
