import AsyncSelect from "react-select/async";
import { getFilterSelectValues } from "features/filter/api";
import { useState, useEffect } from "react";

//Rangeslider

const FilterMultiSelect = ({ filter, segment, parsedFilters, mutate }) => {
  const loadOptions = async (inputValue, filter) => {
    const filterOptions = await getFilterSelectValues(filter, segment).then(
      (response) => {
        return response.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
    );
    return filterOptions;
  };

  const handleSelectChange = (selectedValues, filter) => {
    const value = selectedValues.map((option) => {
      return {
        id: option.value,
        name: option.label,
        query: option.query,
        // op: opValue,
      };
    });
    const filterIndex = parsedFilters.findIndex((f) => f[filter.id]);

    // create a new filter with the selected value
    if (filterIndex === -1) {
      const updatedSegment = {
        ...segment,
        filters: JSON.stringify([...parsedFilters, { [filter.id]: value }]),
      };
      mutate(updatedSegment);
    }

    // update the existing filter with the selected value
    else {
      const updatedFilter = { ...parsedFilters[filterIndex] };
      updatedFilter[filter.id] = value;
      const newFilters = [...parsedFilters];
      newFilters[filterIndex] = updatedFilter;

      mutate({ ...segment, filters: JSON.stringify(newFilters) });
    }
  };

  //Set default values for AsyncSelect based on segment.filters--------------

  const getDefaultSelectValues = (filters, filterId) => {
    const filter = filters?.find((f) => f[filterId]);
    if (filter) {
      const values = filter[filterId];
      // Check if values is an array before calling map
      if (Array.isArray(values)) {
        return values.map((v) => ({
          query: v.query,
          value: v.id,
          label: v.name,
        }));
      }
    }
    return [];
  };

  const defaultValues = getDefaultSelectValues(
    segment && JSON.parse(segment?.filters),
    filter.id
  );

  //!Set operator value (needs to be fixed)
  // useEffect(() => {
  //   if (parsedFilters) {
  //     const filters = parsedFilters?.find((f) => f[filter.id]);
  //     setOpValue(filters[filter.id][0]?.op ? filters[filter.id][0].op : 2);
  //     console.log({filters})
  //   }
  // }, [parsedFilters]);

  return (
    <AsyncSelect
      key={filter.id}
      cacheOptions
      defaultOptions
      defaultValue={defaultValues}
      onChange={(selectedValues) => handleSelectChange(selectedValues, filter)}
      placeholder={filter.name === "Remarks" ? "Inga" : "Alla"}
      isMulti
      name={filter.name}
      loadOptions={(inputValue) => loadOptions(inputValue, filter)}
    />
  );
};

export default FilterMultiSelect;
