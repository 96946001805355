
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// custom style
import './style.css';

/**
 * Based of swiperJS manipulation demo. With breakpoints to control slides per view in a responsive way. Hid the pagination bullets by directly modify its class, in ./style.css. This will affect all   swipers components and should probebly be modified if we expand its use across the application, however this is currently the only component with swiper.
 * DEMO example: https://swiperjs.com/demos#manipulation
 * React code: https://codesandbox.io/p/sandbox/dv22fx
 * @component
 * @param {Object} props - React props for the component.
 * @param {Array} props.templates - An array of template elements to be displayed in the slideshow.
 * @returns {JSX.Element} Returns the JSX element representing the template slideshow.
 * @example
 * <TemplateSlideShow templates={
    mails?.map((mail) => (<TemplateCard template={mail} onClick={setTemplate} key={mail?.id} />)
    )}
 */

const TemplateSlideShow = ({ templates }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={10}
      slidesPerView={1}
      breakpoints={{
        '@0.00': {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        '@0.50': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '@1.00': {
          slidesPerView: 3,
          spaceBetween: 40,
        },

      }}
      navigation={true}
      pagination={{
        clickable: true
      }}
      scrollbar={{
        draggable: true,
      }}
    >
      {templates &&
        templates.length > 0 &&
        templates.map((template, index) => (
          <SwiperSlide key={index}>{template}</SwiperSlide>
        ))}
    </Swiper>
  );
};

export default TemplateSlideShow;
