
import { useQueryClient } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { setCampaignId, setShowCanvas } from "features/campaign/campaignSlice";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "components/forms";
import {
  Table,
  Row,
  Col,
  Spinner,
  Badge,
  ProgressBar,
  Button,
} from "react-bootstrap";
import {
  MdArrowRightAlt,
  MdCheckCircleOutline,
  MdCancel,
  MdMailOutline,
  MdOutlineSms,
} from "react-icons/md";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";
import Agent from "api/Agent";
import { FaSort, FaSortDown, FaSortUp, FaTiktok } from "react-icons/fa";

export default function DrTable({
  rowsPerPage = 8,
  onRowClick,
  customerID,
  activityState,
  activityType
}) {
  const queryClient = useQueryClient();
  const ifBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const ifMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

  const [sort, setSort] = useState({ prop: "createdOn", desc: true });

  const handleRowClick = (row) => {
    if (typeof onRowClick == "function") onRowClick(row)
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: campaignsResponse,
    isSuccess,
    isError,
    isLoading,
    error,
  } = Agent.Campaigns.GET.ALL(
    currentPage,
    rowsPerPage,
    undefined,
    sort?.prop,
    sort?.desc,
    activityType,
    activityState,

  );
  const data = campaignsResponse?.data;

  const columns = [
    { header: "Kampanj", data : (item) => item?.name, sort: "name" },
  ];

  if (!ifBigScreen) {
    columns.splice(3, 5);
  }

  if (ifSmallScreen) {
    columns.splice(1, 2);
  }

  const handleTableHeaderClick = (property) => {
    if (!property || property?.includes(".")) return;
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };

  return (
    <Row>
      <Col className="col-12">
        <CardContainer
          style={{ padding: "0" }}
          children={
            <>
              <div className="table-responsive">
                <Table
                  id="segmentTable"
                  className="table-container"
                  responsive
                  hover
                  size="lg"
                >
                  <thead className="text-sm semi-bold">
                    <tr>
                      {columns.map((col, index) => (
                        <th
                          style={{ width: "150px", userSelect: "none" }}
                          key={index}
                          onClick={() =>
                            col.property &&
                            handleTableHeaderClick(col.property)
                          }
                        >
                          {sort?.prop === col.property && col.property ? (
                            <p>
                              {col.header}
                              {sort?.desc ? (
                                <FaSortDown className="ms-1" size={12} />
                              ) : (
                                <FaSortUp className="ms-1" size={12} />
                              )}
                            </p>
                          ) : (
                            <p>
                              {col.header}
                              {!!col?.sortable && (
                                <FaSort className="ms-1" size={12} />
                              )}
                            </p>
                          )}
                        </th>
                      ))
                      }
                    </tr>
                  </thead>

                  <tbody className="text-md regular">
                    {isSuccess && data.map(( row, index ) => 
                      <tr key={row?.id ?? index}>
                        {columns.map(col => <td>{ col.data(row) }</td>)}
                      </tr>

                    )}
                  </tbody>
                  {isError && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3 bg-danger"
                    >
                      <h5 className=" text-white semi-bold text-center w-100">
                        Ett fel uppstod vid hämtning av data
                      </h5>
                    </caption>
                  )}

                  {isLoading && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3"
                    >
                      <h5 className=" text-black text-center w-100">
                        <Spinner />
                      </h5>
                    </caption>
                  )}

                  {!isLoading && !isError && !data?.length && (
                    <caption
                      style={{ captionSide: "bottom", columnSpan: "100%" }}
                      className="p-3 text-center"
                    >
                      <span className="text-xl text-black text-muted ">
                        Inga kampanjer hittade.
                      </span>
                    </caption>
                  )}
                </Table>

                {campaignsResponse && (
                  <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    countPerPage={rowsPerPage}
                    totalCount={campaignsResponse?.paging?.total}
                  />
                )}
              </div>
            </>
          }
        />
      </Col>
    </Row>
  );
};

