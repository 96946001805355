import {
  Table,
  Row,
  Col,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { TextInput } from "components/forms";
import { useState } from "react";
import { CardContainer } from "components/cards";
import PaginationBar from "components/pagination/PaginationBar";
import JobDetails from "./JobDetails/JobDetails";
import Agent from "api/Agent";
import {FcOk, FcCancel, FcRefresh} from "react-icons/fc";
import { da } from "react-date-range/dist/locale";

const JobsTable = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const {
    isLoading,
    isError,
    error,
    data,
  } = Agent.Jobs.GET.ALL(
    currentPage,
    undefined,
    search,

  );

  const handleShowDetails = (id) => {
    setShowDetails(true);
    setCustomerId(id);
  };
  const handleCloseDetails = () => {
    setShowDetails(false);
    setCustomerId(null);

  };



  // Pagination------------------------------------------------------------
  const rowsPerPage = 10;
  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { label: "Typ av jobb", id: 1 },
    { label: "Senast körd", id: 2 },
    { label: "Nästa körning", id: 3 },
    { label: "Status", id: 4 },
  ];

  const icons = {
    "1": <FcOk size={30}/>,
    "2": <FcCancel size={30}/>,
    "3": <FcRefresh size={30}/>
  };


  // const dataTable = data?.filter((job) => {
  //   if (search === " ") {
  //     return job;
  //   } else if (job.name.toLowerCase().includes(search.toLowerCase())) {
  //     return job;
  //   }
  // });

  if (isLoading) return <Spinner />;

  if (isError) return <p>{error.message}</p>;

  return (
    <>
    { showDetails &&
      <JobDetails
        show={showDetails}
        id={customerId}
        handleClose={handleCloseDetails}
        data={data}
        
      />
    }

      <Col className="col-12">
        <Row className="justify-content">
          <Col className="col-5">
            <TextInput
              type="search"
              placeholder="Filtrera efter sökord"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Col>
      <CardContainer
        style={{ padding: "0" }}
        children={
          <>
            <Table>
              <thead className="text-sm semi-bold">
                <tr style={{ textAlign: "center" }}>
                  {columns.map((col) => (
                    <th key={col.id}>{col.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-md regular">
                {data?.Results.map((r) => {
                  return(
                  <tr
                    key={r.id}
                    
                    onClick={() => handleShowDetails(r.id)}
                  >
                    <td>{r.name}</td>
                    <td style={{ textAlign: "center" }}>{r.lastCompletedRun && `${r.lastCompletedRun.substring(0, 10)} ${r.lastCompletedRun.substring(11, 16)}`}</td>
                    <td style={{ textAlign: "center" }}>
                    {r.nextRun ? `${r.nextRun.substring(0, 10)} ${r.nextRun.substring(11, 16)}` : ""}
                    </td>
                    {/* <td>{r.person}</td> */}
                    <td style={{ textAlign: "center" }}>{icons[r.status]}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
            {data?.length > 0 && (
                <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    countPerPage={data?.Paging.per_page}
                    totalCount={data?.Paging.total}
                />
              )} 
            
          </>
        }
      />
    </>
  );
};

export default JobsTable;
