import axios from "api/axios";
import { endPoints } from "api/endPoints";

const ACCOUNTS_URL = endPoints.accounts.ACCOUNTS_URL;

export const getAccounts = async (accountType, currentPage, search) => {
  return await axios.get(
    `${ACCOUNTS_URL}?type=${accountType}&page=${currentPage}&limit=${10}&search=${search}`
  );
};

export const deleteAccount = async ({ id, accountType }) => {
  return await axios.delete(`${ACCOUNTS_URL}/${id}?type=${accountType}`);
};

export const updateAccount = async ({ id, accountType }, obj) => {
  return await axios.patch(`${ACCOUNTS_URL}/${id}?type=${accountType}`, obj);
};

export const getListColumnNames = async (delimiter, file) => {
  const queryParams = new URLSearchParams({
    delimiter,
  }).toString();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${ACCOUNTS_URL}/listimportfilecolumns?${queryParams}`,
    formData,
    config
  );
  console.log(response.data);
  return response.data;
};

export const verifyCustomersList = async (delimiter, type, file) => {
  const queryParams = new URLSearchParams({
    delimiter,
    type
  }).toString();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  
  const response = await axios.post(
    `${ACCOUNTS_URL}/validateimportfile?${queryParams}`,
    file,
    config
  );
  console.log(response.data);
  return response.data;
};

export const downloadListTemplate = async (type) => {
  return await axios.get(`${ACCOUNTS_URL}/importtemplate?type=${type}`);
};

export const addCustomersList = async (
  type,
  delimiter,
  isFirstRowHeader,
  idColumnName,
  nameColumnName,
  file
) => {
  const queryParams = new URLSearchParams({
    type,
    delimiter,
    isFirstRowHeader,
    idColumnName,
    nameColumnName,
  }).toString();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(
    `${ACCOUNTS_URL}/importaccounts?${queryParams}`,
    file,
    config
  );
};
