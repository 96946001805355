import { Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ButtonMain } from "components/buttons";
import { useMediaQuery } from "react-responsive";
import { MdArrowBack, MdCheck } from "react-icons/md";


const StepBottomRow = (props) => {
  const ifSizeBtnBack = useMediaQuery({ query: "(max-width: 400px)" });
  const ifSizeBtnAccept = useMediaQuery({ query: "(max-width: 350px)" });
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1);
  }
  return (
    <Col
      className="campaign-bottom-container bg-light "
      style={props.prioritize === true ? { position: "sticky", right: 0,} : {}}
    >
      <Row className="justify-content-between align-items-center h-100 px-4 w-100">
        {props.noGoBack ? (
          <Col></Col>
        ) : (
          <Col className="col-2">
            <ButtonMain
              text={ifSizeBtnBack ? <MdArrowBack /> : "Gå tillbaka"}
              variant="light"
              onClick={handleGoBack}
            />
          </Col>
        )}
        <Col className="col-3 d-flex justify-content-end align-items-center">
          <ButtonMain
            onClick={props.onClickSubmit}
            disabled={props.disabled}
            text={
              props.loading ? <Spinner size="small" /> : (ifSizeBtnAccept ? <MdCheck /> : "Spara & Gå vidare")
            }
            variant="success"
          />
        </Col>
      </Row>
    </Col>
  );
};

export default StepBottomRow;

