import "../style.scss";
import { Container, Row, Col } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";

const AdCheckBox = (props) => {
  return (
    <Container
      className={
        props.value
          ? "campaign-radio-box selected-radio-button"
          : "campaign-radio-box not-selected-radio-button"
      }
    >
      {/* CHECKMARK */}
      {props.value && !props.disabled && (
        <Col className="done-check-icon">
          <MdCheckCircle style={{ zIndex: 12 }} size={23} />
        </Col>
      )}
      {/* RADIOBUTTONS */}
      {!props.disabled && (
        <>
          {!props.value ? (
            <Col
              style={{
                position: "absolute",
                top: "20px",
                right: "10px",
                height: "22px",
                width: "22px",
                border: "1px solid #ddd",
                borderRadius: "50%",
                backgroundColor: "#fff",
              }}
            ></Col>
          ) : (
            <Col
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                height: "23px",
                width: "23px",
                border: "1px solid #00789E",
                borderRadius: "50%",
                backgroundColor: "#EAF9FF",
                display: "block",
                textAlign: "center",
                lineHeight: "23px",
              }}
            >
              <Col
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  border: "1px solid #00789E",
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                  backgroundColor: "#00789E",
                }}
              ></Col>
            </Col>
          )}
        </>
      )}

      {props.slim ? (
        <Row className="justify-content-between g-2 gap-1 ">
          <Col className="radio-box-icon col-2 ">{props.icon}</Col>

          <Col className="col-10">
            <Row className="flex-column">
              <Col>
                <p className="radio-box-title text-lg bold">{props.title}</p>
              </Col>
              <Col>
                <p className="radio-box-subtext text-md regular">
                  {props.subText}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="flex-column justify-content-between g-2" style={{ filter: props.disabled ? "opacity(25%)" : "" }}>
          <Col
            className="radio-box-icon"
            
          >
            {props.icon}
          </Col>
          <Col>
            <p className="radio-box-title text-lg bold">{props.title}</p>
          </Col>
          <Col>
            <p className="radio-box-subtext text-md regular">{props.subText}</p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AdCheckBox;
