import Agent from 'api/Agent';
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';
import { MdCancel, MdCheckCircleOutline } from 'react-icons/md';

export default function DeleteCampaignButton({ item, onConfirm }) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { mutate, isLoading } = Agent.Campaigns.DEL.ID();
  const deleteCampaign = (id) =>
    mutate(id);

  const onDeleteClick = (id) => {
    handleCancelConfirm(id);
    deleteCampaign(id);
  };

  const handleConfirm = () => {
    setConfirmDelete(true);
    if (typeof onConfirm == "function") onConfirm(true)
  }
  const handleCancelConfirm = () => {
    setConfirmDelete(false);
    if (typeof onConfirm == "function") onConfirm(false)
  }

  if (isLoading) return <Spinner size="sm" />

  if (confirmDelete) return <>
    <MdCancel
      size={20}
      className="edit-button me-2"
      onClick={() =>
        handleCancelConfirm(
          item.id
        )
      }
    />
    <MdCheckCircleOutline
      size={20}
      className="delete-button"
      onClick={() => onDeleteClick(item.id)}
    />
  </>

  return (

    <button
      className="delete-button"
      onClick={() => handleConfirm(item?.id)
      }
    >
      <FiTrash2 size={18} />
    </button>
  )
}
