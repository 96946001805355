import { getFilterCategories } from "features/filter/api";
import { FilterItem } from "components/filter";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as iconMap from "react-icons/md";
import { MdCircle } from "react-icons/md";
import { setActiveFilterCategory } from "features/filter/filterSlice";
import { useParams } from "react-router-dom";
import { updateSegment } from "features/segment/api";
import Agent from "api/Agent";

const FilterCategoriesList = ({ setOpen, setIsActiveId }) => {
  //Get segment id from the URL
  const { id } = useParams();
  const [segmentTypeId, setSegmentTypeId] = useState(null);
  //Fetch segment data using id
  const { data: segment } = Agent.Segments.GET.ID(id);

  useEffect(() => {
    if (segment) {
      setSegmentTypeId(segment.type);
    }
  }, [segment]);

  //Update segment filtercategories using mutate() function
  const queryClient = new useQueryClient();
  const { mutate } = useMutation(
    (newSegment) => updateSegment({ id: segment?.id }, newSegment),
    {
      onSuccess: () => {
        // Re-fetch the segment data after the filter categories are updated
        queryClient.invalidateQueries(["segment", id]);
      },
    }
  );

  //OnClickEventHandler
  const dispatch = useDispatch();
  const onClickHandler = (filterCategory) => {
    //set subfilters to display in right Col
    setIsActiveId(filterCategory.id);
    dispatch(setActiveFilterCategory(filterCategory));
    // setOpen(false);

    //Get the filter categories array from the segment data
    const filterCategoriesArr = JSON.parse(segment.filterCategories);

    //Check if the clicked filter category is already included in the array
    if (filterCategoriesArr.includes(filterCategory.id)) {
      //If the filter category is already included, remove it from the array
      const updatedFilterCategories = filterCategoriesArr.filter(
        (item) => item !== filterCategory.id
      );
      //Update the segment data with the new filter categories array
      const updatedSegment = {
        ...segment,
        filterCategories: JSON.stringify(updatedFilterCategories),
      };
      //Update the segment data using the mutate() function
      mutate(updatedSegment);
    } else {
      //If the filter category is not included, add it to the array
      filterCategoriesArr.push(filterCategory.id);
      //Update the segment data with the new filter categories array
      const updatedSegment = {
        ...segment,
        filterCategories: JSON.stringify(filterCategoriesArr),
      };
      //Update the segment data using the mutate() function
      mutate(updatedSegment);
    }
  };

  //Fetch FilterCategories

  const { data, isLoading, isError, error } = useQuery(
    ["filter", segmentTypeId],
    getFilterCategories,
    {
      enabled: !!segmentTypeId,
    }
  );

  if (isLoading) return <Spinner />;

  if (isError) return <p>{error.message}</p>;

  //Convert the filter categories stringified array to a regular array
  const filterCategoriesArr = segment && JSON.parse(segment?.filterCategories);

  return (
    <>
      {data?.map((item) => {
        const Icon = iconMap[item.icon];

        if (!filterCategoriesArr?.includes(item.id)) {
          return (
            <FilterItem
              key={item.id}
              title={item.title}
              subTitle={item.subTitle}
              icon={item.icon ? <Icon size={33} /> : <MdCircle size={33} />}
              iconBg={item.iconBg ? item.iconBg : "#dddddd"}
              onClick={() => onClickHandler(item)}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default FilterCategoriesList;
