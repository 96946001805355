import "../style.scss";
import { useState, useEffect } from "react";
import { Form, Spinner, Col, Row } from "react-bootstrap";
import { ButtonMain } from "components/buttons";
import { TextInput } from "components/forms";
import { forgotPassword } from "features/authentication/api";
import { useMutation } from "react-query";
import { BsSendCheck } from "react-icons/bs";
import { useBeforeUnload } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { removeSession } from "context/sessionSlice";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = ({ errorMessage, setErrorMessage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  //Credential input States
  const [email, setEmail] = useState("");
  //Set errorMessage depending on inputs
  useEffect(() => {
    setErrorMessage("");
  }, [email, setErrorMessage]);

  const forgotPasswordMutation = useMutation((email) => forgotPassword(email), {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);
      setEmailSent(true);
      setTimeout(() => {
        dispatch(removeSession());
        navigate("/login");
      }, 5000);
    },
    onError: () => {
      setLoading(false);
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailObj = {
      email: email,
    };

    forgotPasswordMutation.mutate(emailObj);
  };

  useBeforeUnload(() => {
    dispatch(removeSession());
  });

  return (
    <Row className="p-3 flex-column align-items-center">
      {emailSent ? (
        <>
          <Col className="col-12 d-flex text-center justify-content-center mb-5">
            <BsSendCheck size={80} color="green" />
          </Col>
          <Col className="col-12 d-flex text-center justify-content-center">
            <h3 className="display-md">
              Ett mail med vidare instruktioner har skickats till{" "}
              <strong>{email}</strong>
            </h3>
          </Col>
        </>
      ) : (
        <>
          {" "}
          <Col className="col-sm-8 mb-3">
            <h3 className="display-lg semi-bold">Återställ lösenord</h3>
          </Col>
          <Col className="col-sm-8">
            <Form onSubmit={handleSubmit}>
              <TextInput
                label="E-post"
                type="email"
                placeholder="Din E-postadress"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

              {loading ? (
                <Row className="justify-content-center">
                  <Spinner />
                </Row>
              ) : (
                <Row className="gx-2">
                  <Col className="col-12 mb-3">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <ButtonMain
                        width
                        variant="info"
                        type="submit"
                        text="Återställ lösenord"
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </>
      )}
    </Row>
  );
};

export default ForgotPasswordForm;
