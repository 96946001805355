import { Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { GoogleMapMain } from "components/maps";
import ContactForm from "../components/ContactForm";
import AccountTimeLine from "../../components/AccountTimeLine";
// import accountTimeLine from "../components/accountTimeLine";

const PersonOverview = ({
  accountId,
  setBgBlur,
  account,
  accountActivities,
}) => {
  const year = account?.jObject?.basic.data.pnr.substring(0, 4);
  const month = account?.jObject?.basic.data.pnr.substring(4, 6);
  const day = account?.jObject?.basic.data.pnr.substring(6, 8);
  const today = new Date();
  var age = today.getFullYear() - year;
  if (
    today.getMonth() < month ||
    (today.getMonth() === month && today.getDate() < day)
  ) {
    age--;
  }

  return (
    <Row className="gy-5 w-100" style={{ overflow: "hidden" }}>
      {/* DESCRIPTION and MAP------------------- */}
      <Col className="col-6 p-0" style={{ height: "100%", overflowY: "auto" }}>
        <Row className="g-4">
          <Col className="col-12">
            <CardContainer
              padding
              h100
              children={
                <Row className="h-100">
                  <Col className="col-12">
                    <Row className="g-2">
                      <Col className="col-12">
                        <h3 className="display-sm semi-bold">Beskrivning</h3>
                        {account.jObject && (
                          <p className="text-xl regular">
                            <strong>{`${account.jObject.basic.data.name.first_name} ${account.jObject.basic.data.name.last_name}`}</strong>
                            , {`${age} år`}, bor i en{" "}
                            {`${account.jObject.residence.data.living_area}kvm stor`}{" "}
                            <strong>
                              {account.jObject.residence.data.type?.string &&
                                account.jObject.residence.data.type?.string}
                            </strong>{" "}
                            {`på addressen ${account.jObject.basic.data.address.name}, ${account.jObject.basic.data.address.zip_code} i `}
                            <strong>
                              {account.jObject.basic.data.address.city}
                            </strong>
                            .
                            <strong>{` ${account.jObject.basic.data.name.first_name}`}</strong>{" "}
                            {account.jObject.phoneNumbers.data.length > 0
                              ? `har följande telefonnummer eller mobilnummer registrerat i sitt namn: ${account.jObject.phoneNumbers.data.map(
                                  (num) => "+" + num.attributes.phonenumber
                                )}`
                              : "har inga telefonnummer registrerade i sitt namn."}
                          </p>
                        )}
                        {!account.jObject && (
                          <p className="text-xl regular">
                            <strong>{`${
                              account.firstName ? account.firstName : "Personen"
                            } ${
                              account.lastName ? account.lastName : ""
                            }`}</strong>
                            {account.age ? `, ${account.age} år ,` : ""}
                            {account.city ? `bor i ${account.city}.` : ""}
                            <strong>{` ${
                              account.firstName ? account.firstName : ""
                            }`}</strong>{" "}
                            {account.phone
                              ? `har följande telefonnummer eller mobilnummer registrerat i sitt namn: ${account.phone}`
                              : "har inga telefonnummer registrerade i sitt namn."}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col className="col-12 mt-4">
                    <GoogleMapMain
                      address={
                        account.jObject?.basic.data.address.name
                          ? account.jObject.basic.data.address.name
                          : !account.jObject && account.city
                          ? account.city
                          : "Odengatan 1"
                      }
                      height="100%"
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col className="col-12">
            <ContactForm accountId={accountId} account={account} />
          </Col>
        </Row>
      </Col>
      {/* CONTACTFORM------------------- */}

      {/* TIMELINE------------------- */}
      <Col className="col-6" style={{ height: "100%", overflowY: "auto" }}>
        <AccountTimeLine
          accountId={accountId}
          accountActivities={accountActivities}
          setBgBlur={setBgBlur}
          account={account}
        />
      </Col>
    </Row>
  );
};

export default PersonOverview;
