import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";
import axios from "api/axios";

export const SignUps = {
  GET: {
    ALL: (id) => {
      // const queryParams = [
      //   page && `page=${page}`,
      //   search && `search=${search}`,
      //   limit && `limit=${limit}`,
      //   `showExpired=${showExpired}`,
      // ]?.filter((x) => x);
      // const queryClient = useQueryClient();

      // const queryString =
      //   queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () => axios.get(`signups/v1?campaignId=${id}`).then(({ data }) => data),
        queryKey: ["signups", id],
        cacheTime: 1000 * 60 * 10, // 10 minutes
        retry: false,
        onError: (error) => {
          console.warn(`Failed to GET offers with query: \n Error: `, error);
        },
      });
    },
  },
};
