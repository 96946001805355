import { useMutation, useQueryClient } from "react-query";
import { updateCampaignActivity } from "features/campaign/api";
import moment from "moment";
import MetaStep0 from "./wizard/MetaStep0";
import MetaStep1 from "./wizard/MetaStep1";
import MetaStep2 from "./wizard/MetaStep2";
import MetaStep3 from "./wizard/MetaStep3";
import MetaStepper from "./wizard/MetaStepper";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useRef } from "react";
import { putPreview } from "../../api";
import Agent from "api/Agent";

const Meta = ({ campaign, activity, setBgBlur }) => {
  const { data: segment, isLoading: segmentIsLoading } = Agent.Segments.GET.ID(
    campaign.segmentID
  );

  const [selectedMedia, setSelectedMedia] = useState("Facebook");

  const [noAdCreation, setNoAdCreation] = useState(false);

  const formRef0 = useRef(null);
  const formRef = useRef(null);
  const formRef2 = useRef(null);

  //Dailybudget calculcation
  const startDate = campaign.startDate ? new Date(campaign.startDate) : null;
  const endDate = campaign.endDate ? new Date(campaign.endDate) : null;
  const timeDifference =
    endDate && startDate ? endDate.getTime() - startDate.getTime() : null;
  const daysDifference =
    timeDifference && Math.floor(timeDifference / (1000 * 3600 * 24));
  const calculatedDailyBudget =
    daysDifference &&
    campaign.totalBudget &&
    Math.round(campaign.totalBudget / daysDifference);
  //Start- and endDate defaults if null
  const currentDateTime = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const nextMonthDateTime = moment(currentDateTime)
    .add(1, "months")
    .format("YYYY-MM-DDTHH:mm:ss");

  //Form states--------------------------------------
  const [metaAdObject, setMetaAdObject] = useState({
    adAccountId: activity?.ad?.adAccountId ? activity.ad.adAccountId : null,
    metaPageId: activity?.ad?.metaPageId ? activity.ad.metaPageId : null,
    metaCampaignId: activity?.ad?.metaCampaignId
      ? activity.ad.metaCampaignId
      : null,
    metaAdSetId: activity?.ad?.metaAdSetId ? activity.ad.metaAdSetId : null,
    skipAdCreative: activity?.skipAdCreative ? activity.skipAdCreative : false,
    includeExistingCustomers: activity?.includeExistingCustomers
      ? activity.includeExistingCustomers
      : campaign?.includeExistingCustomers
      ? campaign.includeExistingCustomers
      : false,
    imageId: null,
    imageId2: null,
    adTitle: activity?.ad ? activity.ad.title : "",
    adDescription: activity?.ad ? activity.ad.description : "",
    adTargetUrl: activity?.ad?.targetUrl ? activity.ad.targetUrl : "",
    adButtonText: activity?.ad ? activity.ad.buttonText : "",
    primaryText: activity?.ad ? activity.ad.primaryText : "",
    landingPageID: activity?.ad ? activity.ad.landingPageID : "",
    adType: "0",
    startDate: activity?.ad?.endDate
      ? activity.ad.startDate
      : campaign?.startDate
      ? campaign.startDate
      : currentDateTime,
    endDate: activity?.ad?.endDate
      ? activity.ad.endDate
      : campaign?.endDate
      ? campaign.endDate
      : nextMonthDateTime,
    enableTargetGroupMaxSize: activity?.enableTargetGroupMaxSize
      ? activity.enableTargetGroupMaxSize
      : false,
    // targetGroupSize: activity?.targetGroupSize
    //   ? activity.targetGroupSize
    //   : segment
    //   ? segment.size
    //   : 150,
    targetGroupMaxSize: activity?.targetGroupMaxSize
      ? activity.targetGroupMaxSize
      : segment
      ? segment.size
      : 0,
    directMarketingTarget: activity?.directMarketingTarget
      ? activity.directMarketingTarget
      : segment?.type === 0
      ? 1
      : segment?.type === 1
      ? 3
      : null,
    budget: activity?.ad?.budget
      ? activity.ad.budget
      : campaign.totalBudget
      ? calculatedDailyBudget
      : 0,
    optimizationGoal: activity?.optimizationGoal
      ? activity.optimizationGoal
      : 5,
    objective: activity?.objective ? activity.objective : 0,
    bidStrategy: activity?.bidStrategy ? activity.bidStrategy : 0,
    billingEvent: activity?.billingEvent ? activity.billingEvent : 2,
  });

  const [activeStep, setActiveStep] = useState(
    !metaAdObject.skipAdCreative && activity?.externalId
      ? 3
      : metaAdObject.skipAdCreative && activity?.externalId
      ? 1
      : 0
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
        setActiveStep((prev) => prev + 1);
      },
    }
  );

  //Trigger preview---------------------------------------

  const [adBannerPreview, setAdBannerPreview] = useState(null);

  const previewMutation = useMutation(
    (adObject) => putPreview(activity?.id, adObject),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          "MobilePreview/" + activity?.id + "/" + selectedMedia
        );
        console.log({ response });
        setAdBannerPreview(response);
      },
    }
  );

  useEffect(() => {
    const updatedCampaign = {
      ...activity,
      ad: {
        ...activity.ad,
        title: metaAdObject.adTitle,
        description: metaAdObject.adDescription,
        metaPageId: metaAdObject.metaPageId,
        targetUrl: metaAdObject.adTargetUrl,
        landingPageID: metaAdObject.landingPageID,
        adMedias: [
          {
            imageId: metaAdObject.imageId,
          },
        ],
      },
    };
    if (metaAdObject.imageId) {
      previewMutation.mutate(updatedCampaign);
    }
  }, [metaAdObject.imageId]);

  const steps = [
    {
      step: 0,
      children: (
        <Container
          className="meta-container"
          key={0}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <MetaStep0
            campaign={campaign}
            activity={activity}
            metaAdObject={metaAdObject}
            setMetaAdObject={setMetaAdObject}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            ref={formRef0}
            setBgBlur={setBgBlur}
            previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
    {
      step: 1,
      children: (
        <Container
          className="meta-container"
          key={0}
          style={{ padding: "15px 70px 100px 70px" }}
        >
          <MetaStep1
            campaign={campaign}
            activity={activity}
            metaAdObject={metaAdObject}
            setMetaAdObject={setMetaAdObject}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            ref={formRef}
            setBgBlur={setBgBlur}
            previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
    {
      step: 2,
      children: (
        <Container
          className="meta-container"
          key={1}
          style={{ padding: "40px 70px 100px 70px" }}
        >
          <MetaStep2
            campaign={campaign}
            activity={activity}
            metaAdObject={metaAdObject}
            setMetaAdObject={setMetaAdObject}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            mutate={mutate}
            ref={formRef2}
            previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
            segment={segment}
            segmentIsLoading={segmentIsLoading}
          />
        </Container>
      ),
    },
    {
      step: 3,
      children: (
        <Container
          className="meta-container"
          key={2}
          style={{ padding: "40px 70px 100px 70px" }}
        >
          <MetaStep3
            segment={segment}
            segmentIsLoading={segmentIsLoading}
            campaign={campaign}
            mutate={mutate}
            activity={activity}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
  ];

  const stepsNoAd = [
    {
      step: 0,
      children: (
        <Container
          className="meta-container"
          key={0}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <MetaStep0
            segment={segment}
            segmentIsLoading={segmentIsLoading}
            noAdCreation={noAdCreation}
            setNoAdCreation={setNoAdCreation}
            campaign={campaign}
            activity={activity}
            metaAdObject={metaAdObject}
            setMetaAdObject={setMetaAdObject}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            ref={formRef0}
            setBgBlur={setBgBlur}
            previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
    {
      step: 1,
      children: (
        <Container
          className="meta-container"
          key={1}
          style={{ padding: "40px 70px 100px 70px" }}
        >
          <MetaStep3
            campaign={campaign}
            segmentIsLoading={segmentIsLoading}
            segment={segment}
            mutate={mutate}
            activity={activity}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
  ];

  return (
    <>
      {!metaAdObject.skipAdCreative &&
        steps.map((step) => {
          if (step.step === activeStep) {
            return step.children;
          }
        })}
      {metaAdObject.skipAdCreative &&
        stepsNoAd.map((step) => {
          if (step.step === activeStep) {
            return step.children;
          }
        })}
      {activeStep === 3 && !metaAdObject.skipAdCreative ? (
        ""
      ) : activeStep === 1 && metaAdObject.skipAdCreative ? (
        ""
      ) : (
        <MetaStepper
          mutate={mutate}
          isError={isError}
          error={error}
          isLoading={isLoading}
          activity={activity}
          campaign={campaign}
          steps={metaAdObject.skipAdCreative ? stepsNoAd : steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          metaAdObject={metaAdObject}
          formRef0={formRef0}
          formRef={formRef}
          formRef2={formRef2}
        />
      )}
    </>
  );
};

export default Meta;
