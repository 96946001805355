import { DndContext } from "@dnd-kit/core";
import DroppableTextBox from "./DroppableTextBox";
import { Form } from "react-bootstrap";
import VariableContainer from "./VariableContainer";

export default function SMSEditor({ text, setText, activity }) {
  return (
    <>
      <DndContext onDragEnd={handleDragEnd}>
        <Form.Group
          className="mb-3 mt-2"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label className="p-0 text-muted semi-bold">
            Meddelande
          </Form.Label>{" "}
          <br />
          <DroppableTextBox id="textBox" text={text} setText={setText} />
          <Form.Control.Feedback type="invalid">
            Ange ett meddelande.
          </Form.Control.Feedback>
          <Form.Label>
            <strong>{`${text.length}/765`}</strong> tecken i{" "}
            <strong>{Math.ceil(text.length / 160)}</strong> SMS
          </Form.Label>
          <br />
          <Form.Label style={{ fontSize: "12px", padding: "0 10px" }}>
            Dra variabler in i meddelandet och använd dess innehåll av{" "}
            <strong>[namn]</strong> eller <strong>[id]</strong> för att skapa
            kundanpassade värden i din text
          </Form.Label>
        </Form.Group>
        <VariableContainer activity={activity} />
      </DndContext>
    </>
  );

  function handleDragEnd({ over, active }) {
    if (over && over.id === "textBox") {
      setText(text + active.data.current);
    }
  }
}
