import React, { useState, useEffect } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import RangeSlider from "react-range-slider-input";
import Agent from "api/Agent";

const EditSMSSchedule = React.forwardRef(
  ({ onChange, activity, campaign }, ref) => {
    //Get segment by id-----
    const { data: segment, isLoading: segmentLoading } = Agent.Segments.GET.ID(campaign.segmentID)

    const [sliderValue, setSliderValue] = useState(
      activity?.targetGroupMaxSize && activity?.targetGroupMaxSize !== 0
        ? activity.targetGroupMaxSize
        : segment?.size
        ? Number(segment.size)
        : 1000
    );

    const [startDate, setStartDate] = useState(
      activity?.ad?.startDate
        ? activity.ad.startDate.substring(0, 10)
        : campaign?.startDate
        ? campaign.startDate.substring(0, 10)
        : "2023-08-08T06:40:50.4919621".substring(0, 10)
    );

    const [startTime, setStartTime] = useState(
      activity?.ad?.startDate
        ? activity.ad.startDate.substring(11, 16)
        : campaign?.startDate
        ? campaign.startDate.substring(11, 16)
        : "2023-08-08T06:40:50.4919621".substring(11, 16)
    );

    useEffect(() => {
      if (activity?.targetGroupMaxSize || activity?.targetGroupMaxSize !== 0) {
        setSliderValue(activity.targetGroupMaxSize);
      } else if (
        segment &&
        (!activity?.targetGroupMaxSize || activity?.targetGroupMaxSize === 0)
      ) {
        setSliderValue(Number(segment?.size));
      }
    }, []);

    useEffect(() => {
      if (activity) {
        setSliderValue(activity.targetGroupMaxSize);
        // dispatch(
        //   updateDrObject({
        //     ...activity,
        //     targetGroupMaxSize: activity.targetGroupMaxSize,
        //     ad: {
        //       ...activity.ad,
        //       startDate: activity?.ad?.startDate,
        //     },
        //   })
        // );
      }
    }, [activity]);

    //Handle SLider change
    const handleSliderChange = (value) => {
      setSliderValue(value[1]);
    };

    return (
      <Form ref={ref} onChange={onChange}>
        <Row className="mt-1 row-cols-1 g-3">
          <Col>
            <Form.Label
              htmlFor="date"
              className="p-0 text-md"
              style={{ color: "#344054" }}
            >
              Datum för utskick
            </Form.Label>
            <Form.Control
              type="date"
              name="scheduleDate"
              id="date"
              defaultValue={startDate}
              required
            />
            <Form.Control.Feedback type="invalid">
              Ange ett datum för utskick.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label
              htmlFor="time"
              className="p-0 text-md"
              style={{ color: "#344054" }}
            >
              Tid för utskick
            </Form.Label>
            <Form.Control
              type="time"
              name="scheduleTime"
              id="time"
              defaultValue={startTime}
              required
            />

            <Form.Control.Feedback type="invalid">
              Ange ett tid för utskick.
            </Form.Control.Feedback>
          </Col>
          {/* Segment max value */}
          <Col>
            <Form.Label
              htmlFor="segmentvalue"
              className="p-0 text-md"
              style={{ color: "#344054" }}
            >
              Maximal storlek på målgrupp
            </Form.Label>
            {segmentLoading ? (
              <Spinner />
            ) : (
              <div style={{ position: "relative", marginTop: "20px" }}>
                <RangeSlider
                  thumbsDisabled={[true, false]}
                  min={0}
                  max={segment?.size ? Number(segment.size) : 10000}
                  value={[0, sliderValue]}
                  onInput={(value) => handleSliderChange(value)}
                  className="single-thumb"
                  rangeSlideDisabled={true}
                  step={10}
                />
                <span
                  style={{
                    position: "absolute",
                    color: "#2a2c2a",
                    fontSize: "16px",
                    fontWeight: 500,
                    top: "20px",
                  }}
                >
                  Valt antal: <strong>{sliderValue} st</strong>
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
);

export default EditSMSSchedule;
