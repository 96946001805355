import { useState } from "react";
import { Table, Badge, Pagination, Form, Col, Spinner } from "react-bootstrap";
import Numeral from "react-numeral";
import { getCustomerSettings } from "features/settings/api";
import { useQuery } from "react-query";
import { MdOutlineHistory } from "react-icons/md";
import PaginationBar from "components/pagination/PaginationBar";
import Agent from "api/Agent";
import { segmentStatuses, segmentTypes_sv } from "features/segment/segment-details/assets/config";

const CampaignTableSelect = (props) => {
  const { data: settings } = useQuery("customer", getCustomerSettings);

  //Pagination
  const rowsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: segments,
    isLoading,
    isError,
    error,
  } = Agent.Segments.GET.ALL(
    currentPage,
    rowsPerPage,
    props.search,
    "createdOn",
    true,
    segmentStatuses.Ready
  );

  return (
    <>
      {props.tableName === "offers" && (
        <Col className="d-flex align-items-center mb-2">
          <Form.Check
            style={{ width: "20px", height: "20px" }}
            checked={props.showExpired}
            onChange={() => props.setShowExpired((prev) => !prev)}
          />
          <Form.Label className="text-sm text-nowrap mb-0">
            Visa historiska erbjudanden
          </Form.Label>
        </Col>
      )}
      <div className="table-responsive" style={{zIndex:"0"}}>
      <Table className="campaign-table" hover>
        <thead>
          <tr>
            <th></th>
            {props.columns.map((column) => (
              <th className="campaign-column-title" key={column.id}>
                {props.tableName === "offers" &&
                column.id === 1 &&
                settings?.offerPluralName
                  ? `${settings.offerPluralName}`
                  : column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* IF CONVERSIONGOALS----------------------------------------- */}
          {props.tableName === "conversionGoals" &&
            props.data.rows.map((row) => (
              <tr
                className="campaign-table-row"
                key={row.id}
                onClick={() => props.onClick(row.value)}
              >
                <td>
                  <input
                    type="radio"
                    readOnly
                    name={props.data.name}
                    checked={props.value === row.value}
                  />
                </td>
                <td>{row.label}</td>
                <td
                  style={{
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    maxWidth: "200px",
                    textOverflow: " ellipsis",
                    fontSize: "12px",
                    color: "grey",
                  }}
                >
                  {row.desc}
                </td>
                {props.value === row.value ? (
                  <td>
                    <Form.Control
                      type="number"
                      value={props.goalAmount}
                      onChange={(e) => props.setGoalAmount(e.target.value)}
                    />
                  </td>
                ) : <td></td>}
              </tr>
            ))}
          {/* IF OFFERS----------------------------------------- */}
          {props.tableName === "offers" && (
            <>
              {props.data.Results.length > 0 ? (
                props.data.Results.map((row) => (
                  <tr
                    className="campaign-table-row"
                    key={row.id}
                    onClick={() => props.onClick(row.id)}
                  >
                    <td>
                      <input
                        type="radio"
                        readOnly
                        name={props.data.Results[0].name}
                        checked={props.value === row.id}
                      />
                    </td>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "250px",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}
                    >
                      {row.title}
                    </td>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "250px",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}
                    >
                      {row.location}
                    </td>

                    <td
                      style={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {row.startTime ? (
                        <>{`${row.startTime.substring(
                          0,
                          10
                        )} - ${row.startTime.substring(11, 16)}`}</>
                      ) : (
                        "Ej satt"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td></td>
                  <td>Inga kommande erbjudanden</td>
                </tr>
              )}
            </>
          )}
          {/* IF SEGMENTS----------------------------------------- */}
          {props.tableName === "segments" ? (
            isLoading ? (
              <tr>
                <td colSpan={99}>
                  <div className="d-flex justify-content-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            ) : !segments?.data?.length ? (
              <tr className="text-center">
                <td></td>
                <td>Inga tillgängliga målgrupper</td>
              </tr>
            ) : (
              segments?.data?.map((row) => (
                <tr
                  className="campaign-table-row"
                  key={row.id}
                  onClick={() => props.onClick(row.id)}
                >
                  <td>
                    <input
                      type="radio"
                      readOnly
                      name={row?.name}
                      checked={props.value === row.id}
                    />
                  </td>
                  <td>{row?.name}</td>
                  <td>
                    {row.status === 0 ? (
                      <Badge pill bg="warning">
                        Kalkylerar...
                      </Badge>
                    ) : row.status === 1 ? (
                      <Badge pill bg="success">
                        Redo
                      </Badge>
                    ) : (
                      <Badge pill bg="danger">
                        Ogiltig
                      </Badge>
                    )}
                  </td>
                  <td>{segmentTypes_sv[row?.type]?.text}</td>
                  <td>
                    <Numeral value={row.size} format={"0,0"} />
                  </td>
                  <td style={{ color: "#2D6450" }}>
                    {row.campaignsCount}{" "}
                    {row.campaignsCount === 1 ? "kampanj" : "kampanjer"}
                  </td>
                      {/*
                        <td style={{ color: "#2D6450" }}>{row.emailCount} st</td>
                      */}
                </tr>
              ))
            )
          ) : (
            <></>
          )}
        </tbody>
      </Table>
      </div>
      {props.tableName === "segments" &&
        segments?.paging?.total > rowsPerPage && (
          <PaginationBar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={segments?.paging?.total}
            countPerPage={rowsPerPage}
          />
        )}
      {props.data &&
        props.tableName === "offers" &&
        props.data.Paging.total > 4 && (
          <PaginationBar
            currentPage={props.currentPageOffers}
            setCurrentPage={props.handleOffersPageChange}
            totalCount={props.data?.Paging?.total}
            countPerPage={props.data?.Paging.per_page}
          />
          // <Pagination size="sm" className=" justify-content-center">
          //   <Pagination.Prev
          //     disabled={currentPage === 1}
          //     onClick={() => handlePageChange(currentPage - 1)}
          //   />
          //   {Array(Math.ceil(props.data.length / rowsPerPage))
          //     .fill()
          //     .map((_, index) => (
          //       <Pagination.Item
          //         key={index + 1}
          //         active={index + 1 === currentPage}
          //         onClick={() => handlePageChange(index + 1)}
          //       >
          //         {index + 1}
          //       </Pagination.Item>
          //     ))}

          //   <Pagination.Next
          //     disabled={
          //       currentPage === Math.ceil(props.data.length / rowsPerPage)
          //     }
          //     onClick={() => handlePageChange(currentPage + 1)}
          //   />
          // </Pagination>
        )}
    </>
  );
};

export default CampaignTableSelect;
