import { Row, Col, Modal, Spinner, Button } from "react-bootstrap";
import { IoMdTrash } from "react-icons/io";
import { HiArrowUturnLeft } from "react-icons/hi2";

const ModalDeleteCampaign = ({
  loadingDelete,
  setShow,
  show,
  data,
  id,
  onDeleteClick,
}) => {
  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Body className="p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h5 className="display-xs semi-bold">
              Är du säker på att du vill ta bort kampanjen
              {data.map((d) => {
                if (d.id === id) {
                  return d.name;
                }
              })}
              ?
            </h5>
          </Col>
          <Col>
            <Button
              className="button-large w-100"
              onClick={() => setShow(false)}
            >
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <HiArrowUturnLeft size={60} color="#ddd" />
                </Col>

                <Col className="d-flex align-items-center">
                  <h3 className="display-md bold" style={{ color: "#ddd" }}>
                    Nej, avbryt
                  </h3>
                </Col>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button
              className="button-large w-100"
              onClick={() => onDeleteClick(id)}
            >
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <IoMdTrash size={60} color="#B73324" />
                </Col>

                <Col className="d-flex align-items-center">
                  {loadingDelete ? (
                    <Spinner />
                  ) : (
                    <h3
                      className="display-lg bold"
                      style={{ color: "#B73324" }}
                    >
                      Ja
                    </h3>
                  )}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDeleteCampaign;
