import { useQuery, useQueryClient, useMutation } from "react-query";
import { getSegments } from "features/segment/api";
import { Spinner, Badge, Button } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableFilterSearch from "./TableFilterSearch";
import { Table, Row, Col, Image } from "react-bootstrap";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { CardContainer } from "components/cards";
import { deleteSegment, copySegment } from "features/segment/api";
import Numeral from "react-numeral";
import PaginationBar from "components/pagination/PaginationBar";
import {
  MdCancel,
  MdCheckCircleOutline,
  MdOutlineContentCopy,
  MdOutlineOpenInNew,
  MdSearch,
} from "react-icons/md";
import Agent from "api/Agent";
import moment from "moment";
import { segmentStatuses, segmentStatuses_sv } from "features/segment/segment-details/assets/config";
import FilterDropdown from "./FilterDropdown";
import { TextInput } from "components/forms";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import SegmentDetailsOffCanvas from "features/segment/segment-details/SegmentDetailsOffCanvas";
import { RxOpenInNewWindow } from "react-icons/rx";
import ExportSegmentOffCanvas from "./ExportSegmentOffCanvas";

const MerinfoSegmentTable = (props) => {
  const navigate = useNavigate();

  //Table order, search and pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = props.rows;
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ prop: "createdOn", desc: true });

  //Table filters
  const [statusFilter, setStatusFilter] = useState([]);
  const [segmentType, setSegmentType] = useState(undefined);
  const [isAiGenerated, setIsAiGenerated] = useState(false);

  const {
    data: segmentsData,
    isError,
    isLoading,
    error,
  } = Agent.Segments.GET.ALL(
    currentPage,
    rowsPerPage,
    search,
    sort?.prop,
    sort?.desc,
    statusFilter,
    segmentType,
    isAiGenerated,
    props?.segmentIDs
  );
  const data = props?.data ? props?.data : segmentsData?.data ?? null;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Segment Details offCanvas
  const [showDetails, setShowDetails] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(false);

  //Export Details OffCanvas
  const [exportSegment, setExportSegment] = useState(null);

  const handleShowSegmentDetails = (segment) => {
    setShowDetails(!showDetails);
    setSelectedSegment(segment);
  };

  //Duplicate segment----------------
  //const { mutate: copyTargetGroup } = Agent.Segments.POST.COPY();
  const { mutate: copyTargetGroup } = useMutation((id) => copySegment({ id }), {
    onSuccess: () => {
      queryClient.invalidateQueries("segments");
    },
  });
  const onCopyClick = (e, id) => {
    e.stopPropagation();

    copyTargetGroup(id);
  };

  //Edit segment
  const onEditClick = (id) => {
    navigate(`/segments/create-segment/${id}`);
  };

  //OnDelete---------
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({ id: null });
  const [confirmDelete, setConfirmDelete] = useState([]);

  const queryClient = new useQueryClient();
  const { mutate } = useMutation((id) => deleteSegment({ id }), {
    onMutate: (id) => {
      setLoadingDelete(true);
      setSelectedObject({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("segments");
      setSelectedObject({ id: null });
    },
    onError: () => {
      setLoadingDelete(false);
      setSelectedObject({ id: null });
    },
    onSettled: () => {
      setLoadingDelete(false);
    },
  });

  const onDeleteClick = (id) => {
    setConfirmDelete(confirmDelete.filter((x) => x !== id));
    mutate(id);
  };

  const options = (item, id) => {
    return !confirmDelete?.find((_delete) => _delete === id) ? (
      <>
        <button
          className="edit-button"
          onClick={(e) => {
            onCopyClick(e, id);
          }}
        >
          <MdOutlineContentCopy size={18} />
        </button>
        {!item?.isAiGenerated && (
          <button
            className="edit-button"
            onClick={(e) => {
              onEditClick(id);
              e.stopPropagation();
            }}
          >
            <FiEdit3 size={18} />
          </button>
        )}

        <button
          className="delete-button"
          onClick={(e) => {
            setConfirmDelete([...confirmDelete, id]);
            e.stopPropagation();
          }}
        >
          <FiTrash2 size={18} />
        </button>
      </>
    ) : loadingDelete && id === selectedObject.id ? (
      <Spinner size="sm" />
    ) : (
      <>
        <MdCancel
          size={20}
          className="edit-button me-2"
          onClick={(e) => {
            setConfirmDelete(confirmDelete.filter((x) => x !== id));
            e.stopPropagation();
          }}
        />
        <MdCheckCircleOutline
          size={20}
          className="delete-button"
          onClick={(e) => {
            onDeleteClick(id);
            e.stopPropagation();
          }}
        />
      </>
    );
  };

  //*-----------------------------------------------

  const columns = [
    { label: "", property: "isAiGenerated" },
    { label: "Målgrupp", property: "name", sortable: true },
    { label: "Status", property: "status", sortable: true },
    { label: "Skapad", property: "createdOn", sortable: true },
    { label: "Typ", property: "type", sortable: true },
    { label: "Storlek", property: "size", sortable: true },
    { label: "", property: "", },
  ];

  const showOptions = !props?.data && !props.inCampaignDetails;
  if (showOptions) columns.push({ label: "", property: "" });

  const handleTableHeaderClick = (property) => {
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };

  const handleSearch = (input) => {
    setSearch(input);
    currentPage > 1 && setCurrentPage(1);
  };

  return (
    <>
      <Row className="row-cols-1 gap-2 mx-0 px-0">
        {props.filter && (
          <Row className="d-flex flex-row align-items-baseline justify-content-sm-between justify-content-center gap-1 px-0 mx-0">
            <div className="col-auto">
              <FilterDropdown
                setStatusFilter={setStatusFilter}
                setIsAiGenerated={setIsAiGenerated}
                setSegmentType={setSegmentType}
              />
            </div>
            <div style={{ width: "300px" }} className="px-0">
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
          </Row>
        )}

        <CardContainer
          style={{ padding: "0" }}
          children={
            <div className="table-responsive">
              <Table
                id="segmentTable"
                className="table-container"
                responsive
                hover
                size={props.tableSize}
              >
                <thead className="text-sm semi-bold">
                  <tr>
                    {columns.map((col, index) => (
                      <th
                        style={{ width: "150px", userSelect: "none" }}
                        key={index}
                        onClick={() =>
                          col.sortable && handleTableHeaderClick(col.property)
                        }
                      >
                        {sort?.prop === col.property && col.property ? (
                          <p>
                            {col.label}
                            {sort?.desc ? (
                              <FaSortDown className="ms-1" size={12} />
                            ) : (
                              <FaSortUp className="ms-1" size={12} />
                            )}
                          </p>
                        ) : (
                          <p>
                            {col.label}
                            {!!col?.sortable && (
                              <FaSort className="ms-1" size={12} />
                            )}
                          </p>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="text-md regular">
                  {data?.map((item) => (
                    <tr
                      key={item?.id}
                      onClick={(e) => handleShowSegmentDetails(item)}
                    >
                      <td style={{ width: "1%" }}>
                        {item?.isAiGenerated && (
                          <Image
                            src="https://everydayaitools.com/wp-content/uploads/2023/03/ai-1.png"
                            width={25}
                          />
                        )}
                      </td>
                      <td
                        style={{
                          maxWidth: "400px",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        {item?.name}
                        {/* <div className="tooltip">
                            <span className="tooltiptext">{item?.name}</span>
                          </div> */}
                      </td>
                      <td>
                        {
                          <Badge
                            pill
                            bg={segmentStatuses_sv[item?.status]?.variant}
                          >
                            {segmentStatuses_sv[item?.status]?.text}
                          </Badge>
                        }
                      </td>
                      <td>
                        {moment(item?.createdOn).format("yyyy-MM-DD")}
                        <p
                          className="text-sm"
                          style={{ color: "#7B8FA1", margin: "-10px 0" }}
                        >
                          {moment(item?.createdOn).format("HH:mm")}
                        </p>
                      </td>
                      <td>
                        {item?.type === 0
                          ? "Företag"
                          : item?.type === 1
                            ? "Personer"
                            : item?.type === 2 && item?.subType === 0
                              ? "Fordonsägare (företag)"
                              : item?.type === 2 && item?.subType === 1
                                ? "Fordonsägare (personer)"
                                : ""}
                      </td>
                      <td>
                        {(item?.status === segmentStatuses.Scoring) ? (
                          <Spinner className="slow-spinner" size="sm" />
                        ) : (
                          item?.size ? <Numeral value={item?.size} format={"0,0"} /> : <span className="text-muted">{"-"}</span>
                        )}
                      </td>

                      <td>

                        {item.status == segmentStatuses.Ready &&
                          <Button variant="success" className="text-md " disabled={item.status != segmentStatuses.Ready} onClick={() => setExportSegment(item)}><span>Exportera urval</span><MdOutlineOpenInNew className="ms-2" /></Button >
                        }
                      </td>
                      {showOptions && (
                        <td>
                          <div> {options(item, item?.id)}</div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {segmentsData?.paging &&
                !props?.data &&
                !props?.inCampaignDetails && (
                  <PaginationBar
                    setCurrentPage={handlePageChange}
                    paging={segmentsData?.paging}
                  />
                )}
              {data && data.length === 0 && (
                <Col className="d-flex align-items-center justify-content-center p-3">
                  <h5 style={{ color: "#ddd" }}>Inga målgrupper hittade.</h5>
                </Col>
              )}
            </div>
          }
        />
      </Row>
      <ExportSegmentOffCanvas show={!!exportSegment} segment={exportSegment} onClose={() => setExportSegment(null)} />
    </>
  );
};

export default MerinfoSegmentTable;
