import Agent from "api/Agent";
import { CardContainer } from "components/cards";
import React, { useEffect } from "react";
import { useState } from "react";
import { Badge, Button, Spinner, Table, Col } from "react-bootstrap";
import {
  MdCancel,
  MdCheck,
  MdOutlineCalculate,
  MdShoppingCartCheckout,
} from "react-icons/md";

const DecisionMakers = ({ segment }) => {
  const ORDER_DECISIONMAKERS_STATES = {
    Draft: 0,
    Accepted: 1,
    Started: 2,
    Completed: 3,
    Canceled: 4,
    NotCalculated: 6,
  };

  const columns = [
    "Skapad",
    "Status",
    "Antal Företag",
    "Pris per företag",
    "Totalpris",
    "Funna beslutsfattare",
    "",
  ];

  const {
    data: decisionMakers,
    isLoading,
    refetch,
    isRefetching,
  } = Agent.Segments.GET.MAPPINGORDERS(segment?.id);

  const [progress, setProgress] = useState(
    decisionMakers && decisionMakers.length > 0 ? decisionMakers[0].state : 6
  );

  useEffect(() => {
    if (decisionMakers && decisionMakers.length > 0) {
      setProgress(decisionMakers[0].state);
    }
  }, [decisionMakers]);

  const { mutate: postMappingOrder, isLoading: calculationIsLoading } =
    Agent.Segments.POST.MAPPINGORDERS(segment?.id);

  const onClickCalculatePrice = () => {
    postMappingOrder();
    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const { mutate: putMappingOrder, isLoading: orderIsLoading } =
    Agent.Segments.PUT.MAPPINGORDERS();

  const onClickPlaceOrder = (item) => {
    const today = new Date().toISOString();
    const newOrder = {
      ...item,
      id: item.id,
      acceptedByCustomer: true,
      acceptedByCustomerOn: today,
    };

    putMappingOrder({ id: segment.id, item: newOrder });
  };

  const onClickCancelOrder = (item) => {
    const newOrder = {
      ...item,
      id: item.id,
      acceptedByCustomer: false,
      state: 4,
    };

    putMappingOrder({ id: segment.id, item: newOrder });
  };

  return (
    <>
      <h3 className="display-md semi-bold mb-4">
        Beställ genomsökning av beslutsfattare
      </h3>
      <CardContainer
        style={{ padding: 0 }}
        children={
          <>
            {isLoading ? (
              <Spinner />
            ) : (
              <Table>
                <thead className="text-sm semi-bold">
                  <tr>
                    {columns.map((col, index) => (
                      <th key={index}>{col}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-md regular">
                  {isRefetching ? (
                    <Spinner />
                  ) : ORDER_DECISIONMAKERS_STATES.NotCalculated === progress ? (
                    <Col className="d-flex align-items-center justify-content-center p-3">
                      <h5 style={{ color: "#ddd" }}>
                        Ingen kalkylering eller beställning har gjorts
                      </h5>
                    </Col>
                  ) : (
                    decisionMakers.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.createdOn.substring(0, 10)} -{" "}
                          {item.createdOn.substring(11, 16)}
                        </td>
                        <td>
                          {item.state === 0 ? (
                            <>
                              {item.acceptedByCustomer ? (
                                <Badge bg="warning">
                                  Beställning gjord, inväntar behandling
                                </Badge>
                              ) : (
                                <Badge bg="secondary">Ej beställd</Badge>
                              )}
                            </>
                          ) : item.state === 1 || item.state === 2 ? (
                            <Badge
                              bg="warning"
                              className="d-flex text-center align-items-center"
                              style={{ width: "max-content" }}
                            >
                              <Spinner size="sm" className="mx-1" />
                              Beställning påbörjad
                            </Badge>
                          ) : item.state === 3 ? (
                            <Badge
                              bg="success"
                              className="d-flex text-center align-items-center"
                              style={{ width: "max-content" }}
                            >
                              <MdCheck size={14} className="mx-1" /> Beställning
                              genomförd!
                            </Badge>
                          ) : item.state === 4 ? (
                            <Badge
                              bg="danger"
                              className="d-flex text-center align-items-center"
                              style={{ width: "max-content" }}
                            >
                              <MdCancel size={14} className="mx-1" />{" "}
                              Beställning avbruten
                            </Badge>
                          ) : (
                            item.state
                          )}
                        </td>
                        <td>{item.companiesCount}</td>
                        <td>{item.pricePerCompany}</td>
                        <td>
                          {item.companiesCount * item.pricePerCompany} SEK
                        </td>
                        <td>{item.decisionMakersCount}</td>
                        <td>
                          {item.state === 0 ? (
                            <>
                              {item.acceptedByCustomer ? (
                                <Button
                                  variant="danger"
                                  className="d-flex text-center align-items-center semi-bold"
                                  style={{ width: "max-content" }}
                                  onClick={() => onClickCancelOrder(item)}
                                >
                                  Avbryt Beställning
                                  <MdCancel size={16} className="mx-1" />
                                </Button>
                              ) : (
                                <Button
                                  variant="success"
                                  className="d-flex text-center align-items-center semi-bold"
                                  style={{ width: "max-content" }}
                                  onClick={() => onClickPlaceOrder(item)}
                                >
                                  Gör Beställning
                                  <MdShoppingCartCheckout
                                    size={16}
                                    className="mx-1"
                                  />
                                </Button>
                              )}
                            </>
                          ) : item.state === 1 || item.state === 2 ? (
                            <Button
                              variant="danger"
                              disabled={orderIsLoading}
                              className="d-flex text-center align-items-center semi-bold"
                              style={{ width: "max-content" }}
                              onClick={() => onClickCancelOrder(item)}
                            >
                              {orderIsLoading ? (
                                <Spinner />
                              ) : (
                                <>
                                  Avbryt Beställning
                                  <MdCancel size={16} className="mx-1" />
                                </>
                              )}
                            </Button>
                          ) : item.state === 3 ? (
                            <Button
                              variant="success"
                              disabled
                              className="d-flex text-center align-items-center semi-bold"
                              style={{ width: "max-content" }}
                            >
                              Beställning klar!
                              <MdCheck size={16} className="mx-1" />
                            </Button>
                          ) : item.state === 4 ? (
                            <Button
                              variant="secondary"
                              disabled
                              className="d-flex text-center align-items-center semi-bold"
                              style={{ width: "max-content" }}
                            >
                              Beställning avbruten
                              <MdCancel size={16} className="mx-1" />
                            </Button>
                          ) : (
                            item.state
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            )}
          </>
        }
      />
      <Button
        className="p-3 text-xl semi-bold my-3 d-flex"
        variant="success"
        onClick={onClickCalculatePrice}
      >
        {calculationIsLoading ? <Spinner /> : <MdOutlineCalculate size={26} />}
        Räkna ut pris för att ta fram Beslutsfattare
      </Button>
    </>
  );
};

export default DecisionMakers;
