
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalConfirmation({handleAddValue }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirm = () => {
    handleAddValue();
    handleClose();
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
      Skapa användare
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Vill du skapa användaren?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Avbryt
          </Button>
          <Button variant="success" onClick={handleConfirm}>
            Begå ändringar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalConfirmation;