import { Alert, Button, Col, Form, Row, Stack } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDrObject } from "../drSlice";
import "react-range-slider-input/dist/style.css";
import { useEffect } from "react";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import Agent from "api/Agent";
import TargetTypeSelect from "./form/TargetTypeSelect";
import { useContext } from "react";
import { DRContext } from "../wizard/DRWizard";
import PrintPreview from "./PrintPreview";
import moment from "moment";
import MinMaxSlider from "components/sliders/minMaxSlider/MinMaxSlider";

const EditDRSettings = React.forwardRef(({ campaign, activity }, ref) => {
  const { render } = useContext(DRContext);
  const drObject = useSelector((state) => state.directAd.drObject);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  //Get segment by id-----
  const { data: segment, isLoading: segmentLoading } = Agent.Segments.GET.ID(campaign.segmentID)
  const [dataExport, setDataExport] = useState({});

  const parsedTemplate =
    activity?.ad?.template && JSON.parse(activity.ad.template)
    ;

  useEffect(() => {
    if (activity) {
      dispatch(
        updateDrObject({
          ...activity,
          targetGroupMaxSize: activity.targetGroupMaxSize,
          directMarketingTarget: activity.directMarketingTarget
            ? activity.directMarketingTarget
            : drObject.directMarketingTarget,
          ad: {
            ...activity.ad,
            startDate: activity?.ad?.startDate,
            template: {
              ...parsedTemplate,
            },
          },
        })
      );
    }
  }, [activity]);

  //Handle Slider change
  const handleSliderChange = (values) => {
    const max = Math.max(...values);
    const min = Math.min(...values);
    dispatch(
      updateDrObject({
        ...drObject,
        targetGroupMaxSize: max,
        dataExports: [{
          ...dataExport,
          maxSize: max, minSize: min, campaignActivityID: activity?.id
        }]
      })
    );
  };

  const handleStartDate = (date) => {
    setDataExport(prev => ({ ...prev, scheduledDate: date }));

    dispatch(
      updateDrObject({
        ...drObject,
        ad: {
          ...drObject.ad,
          startDate: date,
        },
      })
    )
  }

  const onClickSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(false);

    if (!form.checkValidity()) {
      e.stopPropagation();
      return setValidated(true);
    }
  }


  return (
    <Stack className="gap-4">
      {/* Heading -------------------------------- */}
      <Row>
        <Col className="col-12">
          <Row className="justify-content-between">
            <Col>
              <h4 className="display-sm semi-bold">
                Skapa ett första utskick
              </h4>
            </Col>
            <Col className="col-2 d-flex align-items-center justify-content-end">
              {statusBadgeSetter(activity)}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="row-cols-1 row-cols-md-auto gap-2 justify-content-around">
        <Form ref={ref} noValidate validated={validated} onSubmit={onClickSubmit}>
          <Stack className="gap-3" style={{ minWidth: "300px" }}>
            <Col >
              <MinMaxSlider max={segment?.size} onChange={(values) => handleSliderChange(values)} onMount={(values) => handleSliderChange(values)}/>
            </Col>

            {/* Choose Type of Address -------------------------------- */}
            <Col>
              <TargetTypeSelect segment={segment} onChange={(val) => dispatch(
                updateDrObject({
                  ...drObject,
                  directMarketingTarget: val,
                })
              )}
                defaultValue={activity?.directMarketingTarget}
              />
            </Col>
            <Col >
              <Form.Label
                htmlFor="targetadress"
                className="p-0 text-md semi-bold"
              >
                Datum för detta utskick
              </Form.Label>
              <Form.Control
                type="date"
                className="p-3"
                required
                min={moment().format('yyyy-MM-DD')}
                onChange={(e) => handleStartDate(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Ange ett datum för utskick
              </Form.Control.Feedback>
            </Col>
            <Alert variant="info" className="text-sm semi-bold">
              En unik kundlista genereras inom angiven storlek, baserat utifrån kampanjens målgrupp. <br />
            </Alert>
          </Stack>
          <Button className="invisible" type='submit'> </Button>

        </Form>
        <div className="col-auto col-md-5 mx-auto">
          <PrintPreview sources={render.filter(x => x)?.map((img) => Object.values(img))} />
        </div>
      </Row>
    </Stack>
  );
});

EditDRSettings.displayName = 'EditDRSettings'

export default EditDRSettings;

{/* segment download
      <Row className="justify-content-between">
        {campaign?.segmentDownloadUrl !== null ? (
          <Col className="mt-5">
            <Button variant="success" onClick={onClickDownloadList}>
              {isLoading ? (
                <Spinner />
              ) : (
                  <p>
                    Ladda ned Lista <IoMdDownload />
                  </p>
                )}
            </Button>
          </Col>
        ) : activity?.state === 5 ? (
            "Listan gereras och är klar för nedladdning inom några minuter!"
          ) : campaign?.segmentDownloadStatus === 1 &&
              campaign?.segmentDownloadUrl === null &&
              activity?.state !== 5 ? (
                <Col className="mt-5">
                  <Button variant="outline-success" onClick={onClickGenerateList}>
                    {isLoading ? (
                      <Spinner />
                    ) : (
                        <p>
                          Generera Lista <IoMdListBox />
                        </p>
                      )}
                  </Button>
                </Col>
              ) : (
                <Col className="mt-5">
                  <Button disabled variant="secondary">
                    Generera Lista <IoMdListBox />
                  </Button>
                </Col>
              )}
      </Row> */}


{/* Segment max value */ }
{/*
            <Col>
              <Form.Label
                htmlFor="segmentvalue"
                className="p-0 text-muted semi-bold"
              >
                Maximal storlek på målgrupp
              </Form.Label>
              {segmentLoading ? (
                <Spinner />
              ) : (
                  <div style={{ position: "relative", marginTop: "20px" }}>
                    <RangeSlider
                      thumbsDisabled={[true, false]}
                      min={0}
                      max={segment?.size ? Number(segment.size) : 10000}
                      value={[0, sliderValue]}
                      onInput={(value) => handleSliderChange(value)}
                      className="single-thumb"
                      rangeSlideDisabled={true}
                      step={10}
                    />
                    <span
                      style={{
                        position: "absolute",
                        color: "#2a2c2a",
                        fontSize: "16px",
                        fontWeight: 500,
                        top: "20px",
                      }}
                    >
                      Valt antal: <strong>{sliderValue} st</strong>
                    </span>
                  </div>
                )}
            </Col>
          */}

