import { TextInput } from 'components/forms';
import CampaignTable from 'components/table/campaign/CampaignTable';
import React, { useState } from 'react';
import { Stack } from 'react-bootstrap';
import { MdSearch } from 'react-icons/md';

export default function Campaigns({ customerID }) {
  const [activeCampaignsSearch, setActiveCampaignSearch] = useState(undefined)
  const [scheduledCampaignsSearch, setScheduledCampaignSearch] = useState(undefined)
  return (
    <Stack className='gap-4 mx-auto' style={{ maxWidth: "1400px" }}>

      <div>
        <div className='d-flex flex-row row-cols-auto justify-content-between align-items-baseline'>
          <span className='p-2'>Aktiva</span>
          <TextInput
            type="search"
            placeholder="Sök"
            icon={<MdSearch />}
            onChange={(e) => {
              setActiveCampaignSearch(e.target.value);
            }}
          />
        </div>

        <CampaignTable typeSmall={true} customerID={customerID} statusFilter="1" rows={5} search={activeCampaignsSearch} />
      </div>

      <div>
        <div className='d-flex flex-row row-cols-auto justify-content-between align-items-baseline'>
          <span className='p-2'>Schemalagda</span>
          <TextInput
            type="search"
            placeholder="Sök"
            icon={<MdSearch />}
            onChange={(e) => {
              setScheduledCampaignSearch(e.target.value);
            }}
          />
        </div>

        <CampaignTable typeSmall={true} customerID={customerID} statusFilter="0" rows={5} search={scheduledCampaignsSearch} />
      </div>

    </Stack>
  )
}
