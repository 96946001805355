import axios from "api/axios";
import { endPoints } from "api/endPoints";

const FORGOT_PASSWORD_URL = endPoints.FORGOT_PASSWORD_URL;
const RESET_PASSWORD_URL = endPoints.RESET_PASSWORD_URL;

export const forgotPassword = async (Email) => {
  return await axios.post(FORGOT_PASSWORD_URL, Email);
};

export const resetPassword = async (Obj) => {
  return await axios.post(RESET_PASSWORD_URL, Obj);
};
