import Agent from "api/Agent";
import { useContext } from "react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap"
import { BsArrowRight } from "react-icons/bs";
import { EmailContext } from "../../../Email";
import { DraftContext } from "../Draft";


const SaveEmailButton = ({ onSubmit, ifXsScreen }) => {
  const { activity, campaign } = useContext(EmailContext);
  const { saveActivity, editorReady, emailEditorRef } = useContext(DraftContext);
  const [error, setError] = useState(false)
  const uploadEmailMutation = Agent.EmailTemplate.POST.TEMPLATE();
  const updateEmailMutation = Agent.EmailTemplate.PUT.TEMPLATE();
  const { data: emailTemplate } = Agent.EmailTemplate.GET.ID(
    activity?.ad?.emailTemplateID
  );
  const putTemplate = (design, html) => {
    return onSubmit(
      async () =>
        await updateEmailMutation.mutateAsync({
          ...emailTemplate,
          design: JSON.stringify(design),
          html: html,
        })
    ).catch((err) => { setError(true); throw err })
  }

  const postTemplate = (design, html) => onSubmit(async () =>
    uploadEmailMutation.mutateAsync(
      {
        design: JSON.stringify(design),
        name: campaign?.name || "email template",
        html: html,
      },
      {
        onSuccess: async ({ data }) => {
          return await saveActivity({
            ...activity,
            ad: { ...activity?.ad, emailTemplateID: data?.id },
          });
        },
      }
    )
  ).catch((err) => { setError(true); throw err });

  const handleSaveEmail = async () => {
    setError(false)
    try {

      emailEditorRef.current.editor.exportHtml(async ({ design, html }) => {

        // if (isGeneric || isEdit) return handleSaveActivity(async () =>
        //   uploadEmailMutation.mutateAsync(
        //     {
        //       design: JSON.stringify(design),
        //       name: name ?? campaign?.name ?? "email template",
        //       html: html,
        //       isGeneric: isGeneric
        //     }
        //   ))

        if (emailTemplate?.id) {
          return putTemplate(design, html)
        } else {
          return postTemplate(design, html);
        }
      });
    } catch (error) {
      console.warn("error saving email, error", error)
      setError(true)
    }
  };


  const isLoading = uploadEmailMutation.isLoading || updateEmailMutation.isLoading || !editorReady;
  const isError = uploadEmailMutation.isError || updateEmailMutation.isError || !!error;
  return (

    <Button
      variant={
        isError ? "danger" : "success"
      }
      style={{ fontWeight: 600, padding: "8px 14px" }}
      className="d-flex align-items-center "
      onClick={() =>
        handleSaveEmail()}
      disabled={!emailEditorRef}
    >
      {
        isLoading ?
          <Spinner style={{ width: "20px", height: "20px" }} />
          :
          isError ?
            <span className={ifXsScreen ? "text-white text-sm" : "text-white"}> Ett fel uppstod </span>
            :
            <span className={ifXsScreen ? "text-sm" : ""}>
              <p className="d-none d-sm-inline">Spara & fortsätt</p>
               <BsArrowRight size={ifXsScreen ? 10 : 20} className="ms-1"  />
            </span>
      }
    </Button>
  )
}

export default SaveEmailButton
