import { TableComponent } from "components/table";
import { useQuery, useMutation } from "react-query";

import { getUsers, addUser } from "features/users/api";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";

import { ButtonMain } from "components/buttons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "components/forms";
import { Table, Pagination, Row, Col } from "react-bootstrap";
import { MdDelete, MdEdit, MdFilterList, MdSearch } from "react-icons/md";
import { BsArrowDownShort } from "react-icons/bs";
import { FiEdit, FiEdit3, FiTrash2 } from "react-icons/fi";
import { CardContainer } from "components/cards";
import { deleteSegment } from "features/segment/api";
import PaginationBar from "components/pagination/PaginationBar";


const UsersTable = () => {
  const { data, isError, isLoading, error } = useQuery("users", getUsers);

  const [search, setSearch] = useState("");

    const rowsPerPage = 7;

    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

  const columns = [
    { label: "Namn", id: 1 },
    { label: "Email", id: 2 },
    { label: "Skapad", id: 3 },
  ];

    if (isLoading) return <Spinner />;

    if (isError) return <p>{error.message}</p>;


  return (
    <Row>
      <Col className="col-12">
        <Row className="justify-content-end">
          <Col className="col-5">
            <TextInput
              type="search"
              placeholder="Sök"
              icon={<MdSearch />}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col className="col-12">
        <CardContainer
          style={{ padding: "0" }}
          children={
            <>
              <Table className="table-container" responsive hover size="lg">
                <thead className="text-sm semi-bold">
                  <tr>
                    {columns.map((col) => (
                      <th key={col.id}>{col.label}</th>
                    ))}
                  </tr>
                </thead>

                <tbody className="text-md regular">
                  {data?.filter((user) => {
                        if (search === " ") {
                          return user;
                        } else if (
                          user.firstName.toLowerCase().includes(search.toLowerCase())
                        ) {
                          return user;
                        }
                      })
                  .map((item) => (
                    <tr key={item.firstName}>
                      <td>{`${item.firstName} ${item.lastName}`}</td>
                      <td>{item.email}</td>
                      <td>{item.createdOn.substring(0,10)}</td>
                    </tr>
                  ))
                      .slice(
                       (currentPage - 1) * rowsPerPage,
                      currentPage * rowsPerPage
                     )
                  }
                  </tbody>
                </Table>
                {/* <Pagination size="sm" className=" justify-content-center">
                  <Pagination.Prev
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  />
                  {Array(Math.ceil(data?.length / rowsPerPage))
                    .fill()
                    .map((_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}

                  <Pagination.Next
                    disabled={
                      currentPage === Math.ceil(data?.length / rowsPerPage)
                    }
                    onClick={() => handlePageChange(currentPage + 1)}
                  />
                </Pagination> */}
                {data && data?.length > 0 && (
                <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    countPerPage={rowsPerPage}
                    totalCount={data?.length}
                />
              )}
            </>
          }
        />
      </Col>
    </Row>
  );
};

export default UsersTable;
