import { useEffect, useMemo } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Row } from "react-bootstrap";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useDispatch, useSelector } from "react-redux";
import {
  GetVisualsForStatisticsPage,
  SetSortingOrder,
} from "features/stats/assets/statisticsSlice";
import VisualCard from "features/stats/VisualCard";
import AddVisualCard from "features/stats/AddVisualCard";

export default function VisualCardContainer() {
  const visuals = useSelector((state) => state.statistics.visual.layout);
  const dispatch = useDispatch();
  const sensors = useSensors(useSensor(PointerSensor));

  const items = useMemo(() => [...visuals], [visuals]);

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newItems = arrayMove([...items], oldIndex, newIndex);
      dispatch(SetSortingOrder(newItems));
    }
  }

  useEffect(() => {
    dispatch(GetVisualsForStatisticsPage());
  }, []);

  return (
    // <DndContext
    //   sensors={sensors}
    //   collisionDetection={closestCenter}
    //   onDragEnd={handleDragEnd}
    //   sortingStrategy={rectSortingStrategy}
    // >
    //   <SortableContext items={items}>
    //     <Row className="g-4 px-4 pt-2 ">
    //       {items.map((item, index) => (
    //         <VisualCard visual={item} key={"visual-" + index} />
    //       ))}
    //     </Row>
    //     <Row className="g-4 px-4 mt-1">
    //       <AddVisualCard />
    //     </Row>
    //   </SortableContext>
    // </DndContext>
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sortingStrategy={rectSortingStrategy}
    >
      <SortableContext items={items}>
        <Row className="g-5">
          {items.map((item, index) => (
            <VisualCard visual={item} key={"visual-" + index} />
          ))}
        </Row>
        <Row className="g-4 px-4 mt-1">
          <AddVisualCard />
        </Row>
      </SortableContext>
    </DndContext>
  );
}
