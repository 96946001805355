import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ title, data_values, data_labels, y_line_unit }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: title ? true : false,
        text: title && title,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      // y: {
      //     ticks: {
      //         callback: function(value) {
      //             return y_line_unit ? value + y_line_unit : value;
      //         }
      //     },
      //     suggestedMin: 0,
      //     suggestedMax: 150
      // }
      x: {
        ticks: {
          display: true,
        },

        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        ticks: {
          display: true,
          beginAtZero: false,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };

  const data = {
    labels: data_labels,
    datasets: [
      {
        data: data_values,
        borderColor: "#2D6450",
        backgroundColor: "#2D6450",
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart;
