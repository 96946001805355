import { useState } from "react";
import styles from "../../../style.module.css";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import Stepper from "components/stepper/stepper";
import { useMediaQuery } from "react-responsive";

const formStates = {
  normal: "normal",
  error: "error",
};

const MetaStepper = ({
  steps,
  activity,
  campaign,
  metaAdObject,
  activeStep,
  setActiveStep,
  mutate,
  isError,
  error,
  isLoading,
  formRef0,
  formRef,
  formRef2,
}) => {
  const [formState, setFormState] = useState(formStates.normal);

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const onClickSaveStep0 = () => {
    validateForm0()
      .then(() => {
        const updatedCampaignNoAd = {
          ...activity,
          skipAdCreative: metaAdObject.skipAdCreative,
          createAd: true,
          enableTargetGroupMaxSize: metaAdObject.enableTargetGroupMaxSize,
          includeExistingCustomers: metaAdObject.includeExistingCustomers,
          targetGroupMaxSize: metaAdObject.targetGroupMaxSize,
          directMarketingTarget: metaAdObject.directMarketingTarget,
          ad: {
            ...activity.ad,
            adAccountId: metaAdObject.adAccountId,
            metaCampaignId: metaAdObject.metaCampaignId,
            metaAdSetId: metaAdObject.metaAdSetId,
          },
        };

        const updatedCampaign = {
          ...activity,
          skipAdCreative: metaAdObject.skipAdCreative,
          ad: {
            ...activity.ad,
            adAccountId: metaAdObject.adAccountId,
            metaPageId: metaAdObject.metaPageId,
          },
        };
        mutate(
          metaAdObject.skipAdCreative ? updatedCampaignNoAd : updatedCampaign
        );
      })
      .catch(BadFormResponse);
  };

  const onClickSaveStep1 = () => {
    validateForm1()
      .then(() => {
        const updatedCampaign = {
          ...activity,
          ad: {
            ...activity.ad,
            title: metaAdObject.adTitle,
            description: metaAdObject.adDescription,
            targetUrl: metaAdObject.adTargetUrl,
            buttonText: metaAdObject.adButtonText,
            primaryText: metaAdObject.primaryText,
            landingPageID: metaAdObject.landingPageID,
            startDate: activity?.ad?.startDate
              ? activity.ad.startDate
              : campaign?.startDate
              ? campaign?.startDate
              : null,
            endDate: activity?.ad?.endDate
              ? activity?.ad?.endDate
              : campaign?.endDate,
            budget: metaAdObject.budget,
            adMedias: [
              {
                imageId: metaAdObject.imageId,
              },
            ],
          },
        };
        mutate(updatedCampaign);
      })
      .catch(BadFormResponse);
  };

  const BadFormResponse = () => {
    setFormState(formStates.error);
    // trigger onSubmit event for the form. in order to highlight form validation in the form.
    if (formRef0.current) {
      const submitButton = formRef.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
    if (formRef.current) {
      const submitButton = formRef.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
    if (formRef2.current) {
      const submitButton = formRef2.current.querySelector('[type="submit"]');
      if (submitButton) {
        submitButton.click();
      }
    }
  };

  const validateForm0 = async () => {
    setFormState(formStates.normal);
    const form = formRef0?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const validateForm1 = async () => {
    setFormState(formStates.normal);
    const form = formRef?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const validateForm2 = async () => {
    setFormState(formStates.normal);
    const form = formRef2?.current;
    if (!form) return Promise.reject();
    if (!form.checkValidity()) return Promise.reject();
  };

  const onClickSaveStep2 = () => {
    validateForm2()
      .then(() => {
        const updatedCampaign = {
          ...activity,
          createAd: true,
          targetGroupMaxSize: metaAdObject.targetGroupMaxSize,
          directMarketingTarget: metaAdObject.directMarketingTarget,
          optimizationGoal: metaAdObject.optimizationGoal,
          billingEvent: metaAdObject.billingEvent,
          bidStrategy: metaAdObject.bidStrategy,
          objective: metaAdObject.objective,
          ad: {
            ...activity.ad,
            startDate: metaAdObject.startDate,
            endDate: metaAdObject.endDate,
            budget: metaAdObject.budget,
            adMedias: [
              {
                imageId: metaAdObject.imageId,
              },
            ],
          },
        };
        mutate(updatedCampaign);
      })
      .catch(BadFormResponse);
  };

  return (
    <Col className={styles.campaignChannelFooter}>
      <Row className="row-cols-3 px-2">
        <Col className="d-flex align-items-center justify-content-start">
          {activeStep !== 0 && (
            <Button
              variant="outline-dark"
              style={{ fontWeight: 600, padding: "8px 14px" }}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              <BsArrowLeft size={20} /> Gå tillbaka
            </Button>
          )}
        </Col>

        <Col
          className="d-flex align-items-center justify-content-center p-0"
          style={{ flexGrow: 1 }}
        >
          <Stepper
            steps={steps?.length}
            disableContinue
            activeStep={activeStep}
            onClick={(e) => e !== undefined && setActiveStep(e)}
            smallSteps={isSmallScreen}
          />
        </Col>

        <Col className="d-flex align-items-center justify-content-end">
          {steps.length - 1 !== activeStep && (
            <>
              {/* {isError && <p>{error.response.data}</p>} */}
              <Button
                variant={
                  formState === formStates.error || isError
                    ? "danger"
                    : "success"
                }
                style={{ fontWeight: 600, padding: "8px 14px" }}
                className="text-md"
                onClick={
                  activeStep === 0
                    ? onClickSaveStep0
                    : activeStep === 1
                    ? onClickSaveStep1
                    : activeStep === 2
                    ? onClickSaveStep2
                    : () => setActiveStep((prev) => prev + 1)
                }
              >
                {isLoading ? (
                  <Spinner />
                ) : isError ? (
                  "Ett fel uppstod"
                ) : formState === formStates.error ? (
                  <span>Inkomplett formulär</span>
                ) : (
                  <span>
                    Spara & fortsätt <BsArrowRight size={20} />
                  </span>
                )}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default MetaStepper;
