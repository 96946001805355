import axios from "api/axios";
import { endPoints } from "api/endPoints";

const CUSTOMER_SETTINGS_URL = endPoints.customers.CUSTOMER_SETTINGS_URL;
const CUSTOMER_URL = endPoints.customers.CUSTOMER_URL;

export const getCustomerSettings = async () => {
  const response = await axios.get(CUSTOMER_SETTINGS_URL);
  return response.data;
};

export const getCustomerSettingsById = async (id) => {
  return await axios.get(`${CUSTOMER_URL}/${id}/settings`);
};

//create post api request

export const addCustomerSetting = async (id, updatedSettings) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.put(`${CUSTOMER_URL}/${id}/settings`, updatedSettings);
};

