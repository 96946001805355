import { LoginContainer } from "features/authentication";
import "./style.scss";
import img from "assets/img/login-bg.jpg";
import imgMerInfo from "assets/img/imgMerInfo.jpg";
import { Container, Col, Row, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const LoginPage = ({ siteUrl }) => {
  const ifBigScreen = useMediaQuery({ query: "(min-width: 769px)" });
  return (
    <Container fluid className="login-container">
      <Row className={siteUrl === "merinfo" ? "flex-row-reverse" : ""}>
        <Col className="col-12 col-md-6">
          <LoginContainer siteUrl={siteUrl} ifBigScreen={ifBigScreen} />
        </Col>
        {ifBigScreen && (
          <Col md={6} className="col">
            <Image
              className="login-img"
              fluid
              src={siteUrl === "merinfo" ? imgMerInfo : img}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default LoginPage;
