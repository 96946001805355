import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Jobs = {
  GET: {
    ALL: (page, type, search,) => {
      const queryParams = [
        page && `page=${page}`,
        search && `search=${search}`,
        type !== undefined && `type=${type}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios.get(`jobs/v1/${queryString}`).then(({ data }) => data),
        queryKey: ["jobs", queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET jobs with query: ${queryString} \n Error: `,
            error
          );
          toast.error("[Fel] Det gick inte att hämta jobb");
        },
      });
    },
    CUSTOMER_ID: (id) => {
      return useQuery({
        queryFn: () => axios.get(`jobs/v1/customer/${id}`).then(({ data }) => data),
        queryKey: ["job customer", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET job customer with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta job id");
        },
      });
    },
    RUNS: (id) => {
      return useQuery({
        queryFn: () => axios.get(`jobs/v1/${id}/runs`).then(({ data }) => data),
        queryKey: ["job runs", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET job runs with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta job runs id");
        },
      });
    },
    TASKRUNS: (id) => {
      return useQuery({
        queryFn: () => axios.get(`jobs/v1/runs/${id}/taskruns`).then(({ data }) => data),
        queryKey: ["job taskruns", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET job taskruns runs with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta job taskruns id");
        },
      });
    },

  }
}