import { Row, Col, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import RangeSlider from "react-range-slider-input";
import { FaSave } from "react-icons/fa";

const EditTargetGroupMaxSize = ({
  campaign,
  activity,
  updateActivity,
  updateActivityLoading,
  segment,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [rangeSettings, setRangeSettings] = useState({
    min: 0,
    max:
      segment?.size && segment?.size > 50000
        ? 50000
        : Math.round(segment?.size / 1000) * 1000,

    step: 1,
  });

  const [rangeSliderValue, setRangeSliderValue] = useState(
    activity
      ? activity["targetGroupMaxSize"]
      : rangeSettings?.max < 50000
      ? segment.size
      : 50000
  );

  const handleRangeInput = (value) => {
    if (value > rangeSettings.max) return;
    if (value < rangeSettings.min) return;
    setRangeSliderValue(value);
  };

  const updateTargetGroupMaxSize = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/targetGroupMaxSize",
        value: rangeSliderValue,
      },
    ];
    updateActivity(patchObj);
    setShowInput(false);
  };

  return (
    <Row className="row-cols-auto justify-content-around gy-2 flex-nowrap">
      <Col className="regular text-center" style={{ minWidth: "170px" }}>
        <p>Max antal mottagare</p>
      </Col>

      {showInput ? (
        <Col className="d-flex align-items-center col-8">
          <div className="d-flex justify-content-center w-100">
            <Form.Control
              type="text"
              name="activity_targetGroupMaxSize"
              value={rangeSliderValue}
              className="text-md semi-bold"
              required
              onChange={(e) =>
                handleRangeInput(!e.target.value ? 0 : +e.target.value)
              }
              style={{ flex: "1", minWidth: "100px" }}
            />
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{ flex: 8, minWidth: "200px" }}
            >
              <div style={{ flex: "6" }} className="px-2  my-auto">
                <RangeSlider
                  thumbsDisabled={[true, false]}
                  value={[0, rangeSliderValue]}
                  onInput={(e) => setRangeSliderValue(e[1])}
                  min={rangeSettings.min}
                  max={rangeSettings.max}
                  className="single-thumb"
                  step={1000}
                />
              </div>
              <Form.Control
                style={{ flex: "2", minWidth: "70px" }}
                disabled
                className="text-center"
                type="text"
                value={rangeSettings.max}
              />
            </div>
          </div>
        </Col>
      ) : (
        <Col className="flex-grow-1">
          <p style={{ color: "#545F61" }} className="text-center">
            {campaign?.autoSegment ? "-" : `${activity.targetGroupMaxSize} st`}
          </p>
        </Col>
      )}
      {showInput ? (
        <Col className="edit-col-icon" onClick={updateTargetGroupMaxSize}>
          <FaSave color="green" />
        </Col>
      ) : (
        <Col
          className="flex-shrink-1 edit-col-icon"
          onClick={() => setShowInput(true)}
        >
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditTargetGroupMaxSize;
