import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "api/axios";
import { endPoints } from "api/endPoints";
import Cookies from "js-cookie";
import moment from "moment";

const URL = endPoints.visuals.URL;

const selectedPeriodCookie = Cookies.get("statistics-selectedPeriod");
const parsedSelectedPeriodCookie =
  selectedPeriodCookie && JSON.parse(selectedPeriodCookie);

const filtersCookie = Cookies.get("statistics-filters");
const parsedFiltersCookie = filtersCookie && JSON.parse(filtersCookie);

const initialState = {
  id: "5B5D11F3-0FB8-4741-ADC8-1ABA14470FF2",
  selectedPeriod: parsedSelectedPeriodCookie
    ? parsedSelectedPeriodCookie
    : [
      {
        startDate: moment().subtract(4, "months").toISOString(),
        endDate: moment().toISOString(),
        key: "selection",
      },
    ],
  visual: {
    viewAsTable: [],
    layout: [],
    types: [
      {
        name: "SumTotal",
        id: 0,
      },
      {
        name: "BarChart",
        id: 1,
      },
      {
        name: "PieChart",
        id: 2,
      },
      {
        name: "DoughnutChart",
        id: 3,
      },
      {
        name: "LineChart",
        id: 4,
      },
      {
        name: "Map",
        id: 5,
      },
    ],
    entities: [
      {
        name: "Customers",
        id: 0,
      },
      {
        name: "Campaigns",
        id: 1,
      },
      {
        name: "Leads",
        id: 2,
      },
      {
        name: "Purchases",
        id: 3,
      },
      {
        name: "Interactions",
        id: 4,
      },
      {
        name: "Persons",
        id: 5,
      },
      {
        name: "Companies",
        id: 6,
      },
      {
        name: "CampaignStatistics",
        id: 7,
      },
    ],
  },
  filters: parsedFiltersCookie ? parsedFiltersCookie : [],
};

export const GetVisualsForStatisticsPage = createAsyncThunk(
  "statistics/layout",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${endPoints.visuals.PAGE.STATISTICS}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const GetVisualTypes = createAsyncThunk(
  "statistics/types",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${endPoints.visuals.TYPES}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const GetVisualEntities = createAsyncThunk(
  "statistics/entities",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(`${endPoints.visuals.ENTITIES}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const SetSortingOrder = createAsyncThunk(
  "statistics/layout/sort",
  async (data, thunkAPI) => {
    try {
      await axios.put(`${endPoints.visuals.SORT}`, data);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const CreateNewVisual = createAsyncThunk(
  "statistics/visual/create",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${URL}`, data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const UpdateVisual = createAsyncThunk(
  "statistics/visual/update",
  async (data, thunkAPI) => {
    try {
      return await axios.put(`${URL}${data?.id}`, data).then(() => data);
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const RemoveVisual = createAsyncThunk(
  "statistics/visual/remove",
  async (visualId, thunkAPI) => {
    try {
      await axios.delete(`${URL}${visualId}`);
      return visualId;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err });
    }
  }
);

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    initSelectedPeriod: (state, action) => {
      state.selectedPeriod = Cookies.get("statistics-selectedPeriod");
    },
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload;
      Cookies.set("statistics-selectedPeriod", JSON.stringify(action.payload));
    },
    addFilters: (state, { payload, type }) => {
      const { id, value = [], name = null } = payload;
      const activeFilter = state.filters.find((x) => x.id === id);
      if (activeFilter) {
        activeFilter.values = [...activeFilter.values, value];
        const index = state.filters.indexOf((x) => x.id === id);
        if (!index) return;
        state.filters[index] = activeFilter;
      } else {
        const filter = { id: id, values: [value], name: name };
        state.filters.push(filter);
      }

      Cookies.set("statistics-filters", JSON.stringify(state.filters));
    },
    addFilterTotal: (state, { payload, type }) => {
      const { id, value = [], name = null } = payload;
      const activeFilter = state.filters.find((x) => x.id === id);

      if (activeFilter) {
        activeFilter.values = [value];
        const index = state.filters.indexOf((x) => x.id === id);
        if (!index) return;
        state.filters[index] = activeFilter;
      } else {
        const filter = { id: id, values: [value], name: name };
        state.filters.push(filter);
      }

      Cookies.set("statistics-filters", JSON.stringify(state.filters));
    },
    removeFilter: (state, { payload, type }) => {
      const { id, value, name = null } = payload;

      const activeFilter = state.filters.find((x) => x.id === id);
      state.filters?.splice(state.filters.indexOf(activeFilter), 1);

      Cookies.set("statistics-filters", JSON.stringify(state.filters));
    },
    removeFilterProp: (state, { payload: prop, type }) => {
      const { id, value } = prop;
      const filters = [...state.filters];
      const activeFilter = filters.find((x) => x.id === id);

      const indexOfPropToRemove = activeFilter?.values?.indexOf(value);
      activeFilter?.values?.splice(indexOfPropToRemove, 1);

      filters[filters.indexOf(filters.find((x) => x.id === id))].values =
        activeFilter.values;
      state.filters = filters;

      Cookies.set("statistics-filters", JSON.stringify(state.filters));
    },
    deleteAllFilters: (state, action) => {
      state.filters = [];
      Cookies.remove("statistics-filters");
    },
    ToggleVisualTable: (state, { payload, type }) => {
      const id = payload
      const viewAsTable = [...state.visual.viewAsTable]
      const toggle = viewAsTable.includes(id)
      if (!toggle) state.visual.viewAsTable = [...viewAsTable, id]
      else state.visual.viewAsTable = [...viewAsTable.filter(x => x !== id)]

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetVisualsForStatisticsPage.fulfilled, (state, action) => {
        state.visual.layout = action.payload;
      })
      .addCase(SetSortingOrder.fulfilled, (state, action) => {
        state.visual.layout = action.payload;
      })
      .addCase(CreateNewVisual.fulfilled, (state, action) => {
        state.visual.layout = [...state.visual.layout, action.payload];
      })
      .addCase(UpdateVisual.fulfilled, (state, action) => {
        const index = state.visual.layout.indexOf(
          state.visual.layout.find((x) => x?.id === action.payload?.id)
        );
        if (index === -1) return;
        state.visual.layout[index] = action.payload;
      })
      .addCase(RemoveVisual.fulfilled, (state, action) => {
        const newVisual = state.visual.layout.filter(
          (item) => item.id !== action.payload
        );
        state.visual.layout = newVisual;
      })
      .addCase(GetVisualTypes.fulfilled, (state, action) => {
        state.visual.types = action.payload;
      })
      .addCase(GetVisualEntities.fulfilled, (state, action) => {
        state.visual.entities = action.payload;
      })
      .addMatcher(
        (action) =>
          [
            GetVisualsForStatisticsPage.rejected,
            SetSortingOrder.rejected,
            CreateNewVisual.rejected,
            UpdateVisual.rejected,
            RemoveVisual.rejected,
            GetVisualTypes.rejected,
            GetVisualEntities.rejected,
          ].includes(action.type),
        (state, action) => {
          throw action.payload;
        }
      );
  },
});

export const {
  setSelectedPeriod,
  addFilters,
  addFilterTotal,
  deleteAllFilters,
  removeFilter,
  removeFilterProp,
  ToggleVisualTable,
} = statisticsSlice.actions;
