import { Row, Col, Container, Table, ToggleButton, ButtonGroup, Button } from "react-bootstrap";
import { ButtonMain } from "components/buttons";
import { AdminFiltersTable } from "features/adminFilters";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { setFilterType } from "features/adminFilters/filterAdminSlice";
import NewFilterCategory from "features/adminFilters/my-adminFilters/newFilterCategory/NewFilterCategory";
import { useState } from "react";
import EditFilterTypeModal from "features/adminFilters/my-adminFilters/adminFiltersDetails/components/EditFilterTypeModal/EditFilterTypeModal";
import EditFilterCategoryModal from "features/adminFilters/my-adminFilters/adminFiltersDetails/components/EditFilterCategoryModal/EditFilterCategoryModal";



const MyFilters = () => {
  const [show, setShow] = useState(false)

  const dispatch = useDispatch();
  const filterType = useSelector((state)=> state.filterAdmin.filterType);

  const radios = [
    { name: "0", value: 0 },
    { name: "1", value: 1 },
  ];

  const onClickSwitch = (e) => {
    dispatch(setFilterType(Number(e.currentTarget.value)));
    console.log({ filterType });
  };


  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className="justify-content-between">
            <Col>
              <h2>Mina Filter</h2>
            </Col>
            <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
              <Button variant="success" onClick={() => setShow(true)}>Ny Kategori</Button>
                       </Col>
          </Row>
        </Col>
        <Row>
          <AdminFiltersTable/>
          </Row>
      </Row>

      <EditFilterCategoryModal show={show} setShow={setShow}/>
    </Container>
  );
};

export default MyFilters;
