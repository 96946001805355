import DR from "features/activities/DR/DR";
import { Col, Container, Row } from "react-bootstrap"
import { useMediaQuery } from "react-responsive";

const Activities = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className={`justify-content-between ${isSmallScreen ? 'flex-column' : ''}`}>
            <Col style={{marginTop: isSmallScreen ? 15 : ''}}>
              <h2>Alla DR Aktiviteter</h2>
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <DR/>
        </Col>
      </Row>
    </Container>
  )
}

export default Activities
