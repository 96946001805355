
import { Button, Form, Row, Stack } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";
export default function FilterTypeNumber({ filterType, disabled }) {
  return <Row className="row-cols-2 gy-1">
    <Form.Group >
      <Form.Label>RangeMin</Form.Label>
      <Form.Control type="number" className="text-md" disabled={disabled} name="rangeMin" defaultValue={filterType?.rangeMin} />
    </Form.Group>

    <Form.Group >
      <Form.Label>RangeMax</Form.Label>
      <Form.Control type="number" className="text-md" disabled={disabled} name="rangeMax" defaultValue={filterType?.rangeMax} />
    </Form.Group>

    <Form.Group >
      <Form.Label>Default RangeMin</Form.Label>
      <Form.Control type="number" className="text-md" disabled={disabled} name="rangeDefaultMin" defaultValue={filterType?.rangeDefaultMin} />
    </Form.Group>

    <Form.Group >
      <Form.Label>Default RangeMax</Form.Label>
      <Form.Control type="number" className="text-md" disabled={disabled} name="rangeDefaultMax" defaultValue={filterType?.rangeDefaultMax} />
    </Form.Group>

    <Form.Group >
      <Form.Label>Range Step</Form.Label>
      <Form.Control type="number" className="text-md" disabled={disabled} name="rangeSteps" defaultValue={filterType?.rangeSteps} />
    </Form.Group>

    <Form.Group >
      <Form.Label>Suffix</Form.Label>
      <Form.Control type="text" className="text-md" disabled={disabled} name="suffix" defaultValue={filterType?.suffix} />
    </Form.Group>

  </Row>
}
