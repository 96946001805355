import { useState, useRef, useContext } from "react";
import { Container } from "react-bootstrap";
import axios from "api/axios";
import { useMutation, useQueryClient } from "react-query";
import { endPoints } from "api/endPoints";
import { useEffect } from "react";
import { createContext } from "react";
import EmailStep1 from "./EmailStep1";
import EmailStep2 from "./EmailStep2";
import EmailStep3 from "./EmailStep3";
import { EmailContext } from "../../Email";
import EmailStepper from "./EmailStepper";


export const DraftContext = createContext(null);

const Draft = () => {
  const { activity, segment } = useContext(EmailContext);
  const [activeStep, setActiveStep] = useState(!activity?.ad?.emailTemplateID ? 0 : !activity?.ad?.title ? 1 : !activity?.ad?.startDate ? 2 : 2);
  const emailEditorRef = useRef(null);
  const formRef = useRef(null);
  const [formValidation, setFormValidation] = useState(false);
  const [editorReady, SetEditorReady] = useState(false);

  const [template, setTempalte] = useState();
  useEffect(() => {
    SetEditorReady(false)
  }, []);


  const queryClient = useQueryClient();

  const { mutateAsync: saveActivity } = useMutation({
    mutationFn: async (modifiedActivity) => {
      setFormValidation(true);

      const cachedCampaign = JSON.stringify(activity);

      if (JSON.stringify(modifiedActivity) === cachedCampaign)
        return Promise.resolve(
          console.warn("No change found in email settings, nothing updated")
        );

      return axios.put(
        `${endPoints.campaigns.CAMPAIGNS_URL}/${activity.campaignID}/activity/${activity?.id}`,
        modifiedActivity
      );
    },
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries(["campaign", activity?.campaignID]);
      setFormValidation(false);
    },
    onError: (error, variables, context) => {
      console.error(error);
    },
  });


  const steps = [
    {
      step: 0,
      children: (

        <EmailStep1
          emailEditorRef={emailEditorRef}
          SetEditorReady={SetEditorReady}
          templateID={template?.id}
          currentActivity={activity}
        />

      ),
    },
    {
      step: 1,
      children: (
        <EmailStep2
          validated={formValidation}
          currentActivity={activity}
          ref={formRef}
        />
      ),
    },
    {
      step: 2,
      children: (
        <EmailStep3
          ref={formRef}
          validated={formValidation}
          segment={segment}
          currentActivity={activity}
        />
      ),
    },

  ];

  const step = steps.find(step => step?.step === activeStep)?.children;

  return (
    <DraftContext.Provider
      value={{
        template: template,
        setTempalte: setTempalte,
        emailEditorRef: emailEditorRef,
        editorReady: editorReady,
        saveActivity: saveActivity
      }}
    >
      <div className="position-relative overflow-hidden h-100" style={{ display: "grid", gridTemplateRows: "auto-track-list", }}>
        <Container style={{ overflow: "auto" }} className="p-3 ps-lg-5 pt-4">
          {step && step}
        </Container>
        <EmailStepper
          template={template}
          setTemplate={setTempalte}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          emailEditorRef={emailEditorRef}
          formRef={formRef}
          editorReady={editorReady}
          saveActivity={saveActivity}
          currentActivity={activity}
          setFormValidation={setFormValidation}
        />
      </div>
    </DraftContext.Provider>


  );
};

export default Draft
