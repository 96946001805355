import { InputGroup, Form, Row, Col } from "react-bootstrap";

const CampaignInput = (props) => {
  return (
    <Row className="g-3">
      <Col className="col-12">
        <Row>
          {props.heading && (
            <Col className="col-12">
              <h4 className="display-xs semi-bold">{props.heading}</h4>
            </Col>
          )}
          {props.subHeading && (
            <Col className="col-12">
              <p className="text-lg regular">{props.subHeading}</p>
            </Col>
          )}
        </Row>
      </Col>

      <Col className="col-12">
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label className="campaign-form-label">{props.label}</Form.Label>
          <InputGroup hasValidation={!!props.invalid}>
            <Form.Control
              name={props.name}
              required={props.required}
              onChange={props.onChange}
              defaultValue={props.defaultValue && props.defaultValue}
              value={props.value && props.value}
              className="campaign-input py-3 text-xl semi-bold"
              max={props.max}
              as={props.as}
              type={props.type}
              placeholder={props.placeholder}
            />
            {props.suffix && <InputGroup.Text>{props.suffix}</InputGroup.Text>}
            {props.invalid && (
              <Form.Control.Feedback type="invalid">
                {props.invalid}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default CampaignInput;
