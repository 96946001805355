import "../style.scss";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Modal, Spinner, Alert, InputGroup } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import {
  setActiveChannel,
  setCampaignId,
  setSelectedChannel,
  setShowCanvas,
} from "features/campaign/campaignSlice";
import {
  CampaignOptions,
  CampaignActivityTypes,
} from "features/campaign/campaign-details/options";
import { MdInfoOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import StepTopRow from "../components/StepTopRow";
import StepBottomRow from "../components/StepBottomRow";
import CampaignInput from "../components/CampaignInput";
import Agent from "api/Agent";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const BudgetAndSchedule = () => {
  const isXsScreen = useMediaQuery({ maxWidth: 900 });
  const paddingBottomStyle = isXsScreen ? { paddingBottom: '300px' } : {};
  const formRef = useRef();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  //Get Campaign-----------------------------------------------
  //Get campaign id from URL
  const { id } = useParams();

  //Get campaign data using id
  const { data: campaign } = Agent.Campaigns.GET.ID(id);

  const [budget, setBudget] = useState(8000);
  const [startDate, setStartDate] = useState(
    !!campaign?.startDate ? campaign?.startDate : moment().format('yyyy-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    !!campaign?.endDate ? campaign?.endDate : moment().add("month", 1).format('yyyy-MM-DD')
  );
  const [switchState, setSwitchState] = useState(
    campaign ? campaign?.inactivateByConversionGoal : false
  );
  const [numOfViews, setNumOfViews] = useState(5);

  const handleChange = (e) => {
    setSwitchState(!switchState);
  };

  useEffect(() => {
    if (campaign) {
      setBudget(campaign.totalBudget);
      // setStartDate(campaign.startDate);
      // setEndDate(campaign.endDate);
      setSwitchState(campaign.inactivateByConversionGoal);
      setNumOfViews(campaign.dailyExposureLimit);
    }
  }, [campaign]);
  //Submit-------------------------------------------------
  //Update campaign function
  const [showModal, setShowModal] = useState(false);
  const showModalTimeOut = () => {
    setShowModal(true);
    setTimeout(() => {
      navigate("/campaigns", { replace: true });
      dispatch(setCampaignId(campaign.id));
      dispatch(setShowCanvas(true));
      dispatch(
        setSelectedChannel(
          campaign?.campaignActivities.length > 0
            ? CampaignActivityTypes[0]
            : CampaignOptions.Overview
        )
      );
      dispatch(
        setActiveChannel(
          campaign?.campaignActivities.length > 0
            ? CampaignActivityTypes[0]
            : CampaignOptions.Overview
        )
      );
      setShowModal(true);
    }, 2000);
  };


  const { mutate: updateCampaign, isLoading } = Agent.Campaigns.PUT.CAMPAIGN();
  const mutate = (campaign) =>
    updateCampaign(campaign, {
      onSuccess: () => {
        showModalTimeOut();
      },
    });

  //onClick
  const onClickSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    const form = formRef.current;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return false;
    }

    const updatedCampaign = {
      ...campaign,
      totalBudget: budget,
      startDate: startDate,
      endDate: endDate,
      inactivateByConversionGoal: switchState,
      dailyExposureLimit: numOfViews,
      completedSteps: !campaign.completedSteps.some(
        (step) => step === "budget-schedule"
      )
        ? [...campaign.completedSteps, "budget-schedule"]
        : [...campaign.completedSteps],
    };



    mutate(updatedCampaign);
  };

  return (
    <Row className="m-0 " style={paddingBottomStyle}>
      <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Row className="gy-3">
            <Col className="col-12 display-sm semi-bold text-center">
              <Alert variant="warning">
                <Row className="justify-content-center">
                  <Col className="col-3 d-flex justify-content-end">
                    <MdInfoOutline size={30} color="rgb(174 165 138)" />
                  </Col>
                  <Col
                    className="text-lg semi-bold d-flex align-items-center"
                    style={{ color: "rgb(174 165 138)" }}
                  >
                    Din kampanj skapas och du dirigeras snart till
                    annonsinställningarna...
                  </Col>
                </Row>
              </Alert>
            </Col>
            <Col className="col-12 d-flex justify-content-center">
              <Spinner animation="grow" variant="info" />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Form ref={formRef} noValidate validated={validated} onSubmit={(e) => onClickSubmit(e)}>
        {/* TOP----------------------- */}
        <StepTopRow heading="Budget & Schema" />
        {/* MIDDLE----------------------- */}
        <Col className="col-12 p-5">
          <CampaignInput
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
            heading="Annonsbudget"
            subHeading="Den föreslagna budgeten är satt efter storleken på din målgrupp."
            label="Föreslagen Budget"
            type="number"
            placeholder={budget}
            suffix="kr"
          />
        </Col>
        <Col className="col-12 p-5">
          <Row>
            <Col className="col-12">
              <h4 className="display-xs semi-bold">Annonsschema</h4>
            </Col>
            <Col className="col-6">
              <CampaignInput
                defaultValue={startDate && startDate.substring(0, 10)}
                onChange={(e) => setStartDate(e.target.value.toString())}
                label="Startdatum"
                type="date"
                required
                invalid="Ange ett startdatum"
              />
            </Col>
            <Col className="col-6">
              <CampaignInput
                defaultValue={endDate && endDate.substring(0, 10)}
                onChange={(e) => setEndDate(e.target.value.toString())}
                label="Slutdatum"
                type="date"
                required
                invalid="Ange ett slutdatum"
              />
            </Col>
            <Col className="col-12 mt-5">
              <Form.Check
                className="text-md semi-bold"
                style={{ color: "#344054" }}
                type="switch"
                id="custom-switch"
                label="Pausa kampanj vid uppnått mål"
                defaultChecked={switchState}
                onChange={handleChange}
              />
            </Col>
            <Col className="col-12  mt-4">
              <CampaignInput
                value={numOfViews}
                onChange={(e) => setNumOfViews(e.target.value)}
                label="Max antal visningar per person (per dag)"
                type="number"
                placeholder={numOfViews}
                suffix="st"
              />
            </Col>
          </Row>
        </Col>
        {/* BOTTOM----------------------- */}
        <StepBottomRow loading={isLoading} />
      </Form>
    </Row>
  );
};

export default BudgetAndSchedule;
