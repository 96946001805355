import AsyncSelect from "react-select/async";
import { getVisualSelectOptions } from "./api";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const VisualTotalSelect = ({ visual, handleSumTotalClick, filter }) => {
  function GetSelectedPeriod() {
    const selectedPeriod = useSelector(
      (state) => state.statistics.selectedPeriod
    );
    const startDate = moment(selectedPeriod[0].startDate).format("YYYY-MM-DD");
    const endDate = moment(selectedPeriod[0].endDate).format("YYYY-MM-DD");
    return { startDate, endDate };
  }

  const { startDate, endDate } = GetSelectedPeriod();
  const {
    data: visualOptions,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["visualOptions", visual.id],
    queryFn: () => getVisualSelectOptions(visual.id, startDate, endDate),
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>{error.message}</p>;
  }

  //map visualoptions to match required propertynames for AsyncSelect
  const mappedOptions = visualOptions.map((option) => ({
    value: option.id !== null ? option.id : 0,
    label:
      typeof option.text === "number"
        ? option.text.toString()
        : option.text === null
        ? ""
        : option.text,
  }));

  const filterOptions = (inputValue) => {
    return mappedOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterOptions(inputValue));
    }, 1000);
  };

  const getDefaultSelectValues = (visualOptions, filter) => {
    const value = visualOptions?.find((f) => f.id === filter[0]?.values[0]);
    if (value) {
      return {
        value: value.id,
        label: value.text,
      };
    }
    return [];
  };

  const defaultValues = getDefaultSelectValues(visualOptions, filter);

  return (
    <AsyncSelect
      className="visual-select mt-2"
      isClearable
      cacheOptions
      defaultValue={defaultValues}
      onChange={(selectedValues, triggeredAction) =>
        handleSumTotalClick(selectedValues, triggeredAction)
      }
      loadOptions={loadOptions}
      defaultOptions
      placeholder={`Välj ${visual.name}...`}
    />
  );
};

export default VisualTotalSelect;
