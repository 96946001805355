
const LANDSCAPE_A4_ASPECT_RATIO = 210 / 297;
const PORTRAIT_A4_ASPECT_RATIO = 297 / 210;

/**
 * Scales the content to fit within the container while maintaining the aspect ratio.
 * @param {React.RefObject} containerRef - Reference to the container element and target to fit.
 * @param {React.RefObject} contentRef - Reference to the content element and the content to scale.
 * @param {boolean} isLandscape - Indicates whether the content should be scaled for landscape or portrait mode.
 * @throws {Error} Throws an error if containerRef or contentRef is missing.
 */
export const scaleContentToFit = (containerRef, contentRef, isLandscape) => {
  const container = containerRef?.current;
  const content = contentRef?.current;

  if (!containerRef) throw new Error("the containerRef is missing")
  if (!contentRef) throw new Error("the contentRef is missing")


  const aspectRatio = isLandscape
    ? LANDSCAPE_A4_ASPECT_RATIO
    : PORTRAIT_A4_ASPECT_RATIO;

  const containerWidth = container?.clientWidth;

  if (containerWidth) {
    const newContentWidth = containerWidth;
    const newContentHeight = newContentWidth * aspectRatio;

    content.style.width = newContentWidth + "px";
    content.style.height = newContentHeight + "px";
  }
};

export const loadDraggableFromTemplate= (contentRef, template, propertyName) => {
  if (!contentRef) throw new Error("the contentRef is missing")

  const contentWidth = contentRef?.current?.clientWidth;
  const contentHeight = contentRef?.current?.clientHeight;

      console.log(contentHeight, contentWidth)
  const draggables  = template[propertyName]?.map(draggable => {
    const position = draggable?.position;
    if (!position) return draggable;

    const isNewPosition = Object.values(position)?.every(x => x == 0)
    if (isNewPosition) return draggable;
    
    const cashedWidth = draggable.parent_size?.at(0)
    const cashedHeight = draggable.parent_size?.at(1)
      console.log(cashedWidth, contentWidth)
      console.log(cashedWidth/contentWidth)

      const x = position.x * (cashedWidth/contentWidth)
      if (x <= contentWidth) position.x = x;
      else position.x = contentWidth;
      if (x < 0) position.x = 0;


    if (cashedHeight !== contentHeight) {

    }

    console.log(position, contentWidth)
    draggable.position = position;
    return draggable;
  })
  return draggables


  // const asd = defaultItems?.map((item) => {
  //   if (item?.id?.includes("QR")) {
  //     try {
  //       const relativePositionX =
  //         +qrTemplate?.position?.x / +qrTemplate?.parent_size[0];
  //       const relativePositionY =
  //         +qrTemplate?.position?.y / +qrTemplate?.parent_size[1];
  //
  //       item.position.x = relativePositionX * newContentWidth;
  //       item.position.y = relativePositionY * newContentHeight;
  //     } catch (error) {
  //       throw error;
  //     }
  //   } else if (item?.id?.includes("Address")) {
  //     try {
  //       const relativePositionX2 =
  //         +adressTemplate?.position?.x / +adressTemplate?.parent_size[0];
  //       const relativePositionY2 =
  //         +adressTemplate?.position?.y / +adressTemplate?.parent_size[1];
  //
  //       item.position.x = relativePositionX2 * newContentWidth;
  //       item.position.y = relativePositionY2 * newContentHeight;
  //     } catch (error) {
  //       throw error;
  //     }
  //   }
  //
  //   return item;
  // });
  // setItems(asd)
};


 export const loadCashedWizzard = (wizardCashe, propertyName) => {
      if (!wizardCashe) return null
      try {
        const jsoncashe = JSON.parse(wizardCashe)
        return jsoncashe[propertyName].draggables

      } catch (err) {
        console.warn(`failed to parse cashed settings for ${propertyName} in wizard`, wizardCashe)
      }
      return null

    }

