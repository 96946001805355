import { useContext } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { TemplateModalContext } from "./TemplateModal"

const TemplateFilters = () => {
  const { setSearch, generic, setGeneric } = useContext(TemplateModalContext)
  return (
    <Row className='row-cols-auto justify-content-between align-items-center'>
      <Col className='col-md-6'>
        <Form.Select className="semi-bold p-2" value={generic ? 1 : 2} onChange={(e) => setGeneric(!generic)}>
          <option value={1}>Mallar</option>
          <option value={2}>Tidigare utskick</option>

        </Form.Select>
      </Col>

      <div className="p-2 d-flex flex-row justify-content-end ">
        <Form.Control
          style={{ width: "250px" }}
          placeholder="Sök..."
          onChange={(e) => setSearch(e?.target?.value)}

        />
      </div>
    </Row>
  )
}

export default TemplateFilters
