import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useState, useEffect } from "react";

const FilterBooleanButton = ({
  filter,
  segment,
  parsedFilters,
  mutate,
  initialValue,
}) => {
  const [radioValue, setRadioValue] = useState(null);

  const radios = [
    { name: "Ja", value: 0 },
    { name: "Nej", value: 1 },
    { name: "Båda", value: 2 },
  ].map((radio, idx) => ({
    ...radio,
    id: `${filter.id}-${idx}`,
  }));

  useEffect(() => {
    const filterIndex =
      segment && JSON.parse(segment?.filters).findIndex((f) => f[filter.id]);

    if (radioValue) {
      if (filterIndex === -1) {
        if (Number(radioValue) === 2) {
          const updatedSegment = {
            ...segment,
            filters:
              parsedFilters &&
              JSON.stringify([...parsedFilters, { [filter.id]: [] }]),
          };
          mutate(updatedSegment);
        } else {
          const updatedSegment = {
            ...segment,
            filters:
              parsedFilters &&
              JSON.stringify([
                ...parsedFilters,
                { [filter.id]: [Number(radioValue)] },
              ]),
          };
          mutate(updatedSegment);
        }
        // create a new filter with the selected value
      } else {
        // update the existing filter with the selected value
        // if (Number(radioValue) === 2) {
        //   const newParsedFilters = parsedFilters?.filter(
        //     (obj) => !Object.keys(obj).includes(filter.id)
        //   );
         
        //   const newFilters = [...newParsedFilters];
          

        //   mutate({ ...segment, filters: JSON.stringify(newFilters) });
        // } else {
          const updatedFilter = { [filter.id]: [Number(radioValue)] };
          const newFilters = [...parsedFilters];
          newFilters[filterIndex] = updatedFilter;

          mutate({ ...segment, filters: JSON.stringify(newFilters) });
        // }
      }
    }
  }, [radioValue]);

  const getDefaultValues = (filters, filterId) => {
    const foundfilter = filters?.find((f) => f[filterId]);

    if (foundfilter) {
      const values = foundfilter[filterId];
      return values;
    }
    return;
  };

  const defaultValues = getDefaultValues(
    segment && JSON.parse(segment?.filters),
    filter.id
  );

  return (
    <ButtonGroup>
      {radios.map((radio, idx) => (
        <ToggleButton
          key={radio.id}
          id={radio.id}
          onClick={() => console.log("Filternamn:", filter.name)}
          type="radio"
          variant={"outline-secondary"}
          value={radio.value?.toString()}
          checked={
            defaultValues && defaultValues.length > 0
              ? defaultValues.toString() === radio.value?.toString()
              : "2" === radio.value?.toString()
          }
          onChange={(e) => setRadioValue(e.currentTarget.value)}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
};

export default FilterBooleanButton;
