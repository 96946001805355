import { Row, Col } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";

const EditTargetGroup = ({ campaign, segment }) => {
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{minWidth:"170px"}}>
        <p>Målgrupp</p>
      </Col>
      <Col className="flex-grow-1">
        <p style={{ color: "#545F61" }} className="text-center">
          {campaign?.autoSegment
            ? "Automatisk"
            : !campaign?.autoSegment && segment
            ? segment.name
            : "-"}
        </p>
      </Col>
      <Col className="flex-shrink-1 edit-col-icon">
        <AiFillLock color="rgb(212 212 212)" />
      </Col>
    </Row>
  );
};

export default EditTargetGroup;
