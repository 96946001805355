import { MdWork } from "react-icons/md";
import { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { HiPlusSm } from "react-icons/hi";
import {
  FiPhone,
  FiUsers,
  FiMessageSquare,
  FiBell,
  FiTrash,
} from "react-icons/fi";
import { updateLead } from "features/leads/api";
import { useMutation, useQueryClient } from "react-query";
import { MdMailOutline } from "react-icons/md";
import { ButtonMain } from "components/buttons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import AddActivityOffCanvas from "./AddActivityOffCanvas";

const LeadTimeLine = ({ lead, leadId, setBgBlur, leadActivities }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setBgBlur(false);
  };

  const handleShow = () => {
    setShow(true);
    setBgBlur(true);
  };

  //Remove activity
  const queryClient = new useQueryClient();
  const { mutate } = useMutation(
    (newActivity) => updateLead({ id: leadId }, newActivity),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("leads");
        queryClient.invalidateQueries("lead");
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    }
  );

  const onClickRemove = (id) => {
    const updatedLead = {
      ...lead,
      jObject: null,
      activities: lead.activities.filter((item) => item.id !== id),
    };

    mutate(updatedLead);
    console.log({ updatedLead });
  };

  function sortByDateAsc(a, b) {
    return new Date(b.date) - new Date(a.date);
  }

  return (
    <>
      {show && (
        <AddActivityOffCanvas
          show={show}
          handleClose={handleClose}
          leadId={leadId}
          leadActivities={leadActivities}
          lead={lead}
        />
      )}
      <Row className="p-4 mb-5">
        <Col className="col-12">
          <Row className="justify-content-between">
            <Col>
              <h3 className="display-sm semi-bold">Aktiviteter</h3>
            </Col>
            <Col>
              <ButtonMain
                width
                onClick={handleShow}
                text={"Lägg till aktivitet"}
                variant="success"
                reverse
                icon={<HiPlusSm />}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          {leadActivities?.length > 0 ? (
            <VerticalTimeline style={{ margin: 0 }} layout="1-column-left">
              {leadActivities.sort(sortByDateAsc).map((activity) => (
                <VerticalTimelineElement
                  key={activity.id}
                  iconStyle={{ background: "#b3ccc3", color: "#fff" }}
                  icon={
                    activity.type === 0 ? (
                      <FiPhone />
                    ) : activity.type === 1 ? (
                      <MdMailOutline />
                    ) : activity.type === 2 ? (
                      <FiUsers />
                    ) : activity.type === 3 ? (
                      <FiMessageSquare />
                    ) : activity.type === 4 ? (
                      <FiBell />
                    ) : (
                      <MdWork />
                    )
                  }
                >
                  <Row className="p-0 m-0">
                    <Col className="col-12 mb-1">
                      <Row className="justify-content-between">
                        <Col className="p-0 text-md semi-bold">Du</Col>
                        <Col
                          className="text-end text-sm regular"
                          style={{ color: "#667085" }}
                        >
                          {activity.date.substring(0, 10)}
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-12 lead-timeline-content">
                      <Row>
                        <Col>
                          <p className="text-md semi-bold m-0">
                            {activity.notes}
                          </p>
                        </Col>
                        <Col
                          className="col-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => onClickRemove(activity.id)}
                        >
                          {loading ? <Spinner /> : <FiTrash />}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          ) : (
            "Inga aktiviteter"
          )}
        </Col>
      </Row>
    </>
  );
};

export default LeadTimeLine;
