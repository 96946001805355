import { useMutation, useQueryClient } from "react-query";
import { updateCampaignActivity } from "features/campaign/api";
import LinkedinStep1 from "./wizard/LinkedinStep1";
import LinkedinStep2 from "./wizard/LinkedinStep2";
import LinkedinStep3 from "./wizard/LinkedinStep3";
import LinkedinStepper from "./wizard/LinkedinStepper";
import { useState, useEffect } from "react";
import moment from "moment";
import { Container } from "react-bootstrap";
import { useRef } from "react";
import { putPreview } from "../../api";
import Agent from "api/Agent";
import LinkedinStep0 from "./wizard/LinkedinStep0";

const LinkedIn = ({ campaign, activity, setBgBlur }) => {
  const queryClient = useQueryClient();
  const { data: segment, isLoading: segmentIsLoading } = Agent.Segments.GET.ID(campaign.segmentID);

  const formRef = useRef(null);
  const formRef0 = useRef(null);
  const formRef2 = useRef(null);
  //Form states--------------------------------------

    //Dailybudget calculcation
    const startDate = campaign.startDate ? new Date(campaign.startDate) : null;
    const endDate = campaign.endDate ? new Date(campaign.endDate) : null;
    const timeDifference =
      endDate && startDate ? endDate.getTime() - startDate.getTime() : null;
    const daysDifference =
      timeDifference && Math.floor(timeDifference / (1000 * 3600 * 24));
    const calculatedDailyBudget =
      daysDifference &&
      campaign.totalBudget &&
      Math.round(campaign.totalBudget / daysDifference);
    //Start- and endDate defaults if null
    const currentDateTime = moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    const nextMonthDateTime = moment(currentDateTime)
      .add(1, "months")
      .format("YYYY-MM-DDTHH:mm:ss");

  // useEffect(() => {
  //   console.log(campaign.totalBudget / daysDifference);
  //   const dailyBudget = campaign.totalBudget / daysDifference;
  //   setCalculatedDailyBudget(dailyBudget);
  // }, [daysDifference]);

  const [linkedinAdObject, setLinkedinAdObject] = useState({
    adAccountId: activity?.ad?.adAccountId ? activity.ad.adAccountId : null,
    metaPageId: activity?.ad?.metaPageId ? activity.ad.metaPageId : null,
    metaCampaignId: activity?.ad?.metaCampaignId
      ? activity.ad.metaCampaignId
      : null,
    metaAdSetId: activity?.ad?.metaAdSetId ? activity.ad.metaAdSetId : null,
    skipAdCreative: activity?.skipAdCreative ? activity.skipAdCreative : false,
    imageId: null,
    adTitle: activity?.ad ? activity.ad.title : "",
    adDescription: activity?.ad ? activity.ad.description : "",
    adTargetUrl: activity?.ad ? activity.ad.targetUrl : "",
    adButtonText: activity?.ad ? activity.ad.buttonText : "",
    primaryText: activity?.ad ? activity.ad.primaryText : "",
    landingPageID: activity?.ad ? activity.ad.landingPageID : "",
    targeting: null,
    startDate: activity?.ad?.endDate
      ? activity.ad.startDate
      : campaign?.startDate
      ? campaign.startDate
      : currentDateTime,
    endDate: activity?.ad?.endDate
      ? activity.ad.endDate
      : campaign?.endDate
      ? campaign.endDate
      : nextMonthDateTime,
    enableTargetGroupMaxSize: activity?.enableTargetGroupMaxSize
      ? activity.enableTargetGroupMaxSize
      : false,
    targetGroupMaxSize: activity?.targetGroupMaxSize
      ? activity.targetGroupMaxSize
      : segment
      ? segment.size
      : 0,
    directMarketingTarget: activity?.directMarketingTarget
      ? activity.directMarketingTarget
      : null,
    budget: activity?.ad?.budget ? activity.ad.budget : 0,
  });

  const [activeStep, setActiveStep] = useState(
    !linkedinAdObject.skipAdCreative && activity?.externalId
      ? 3
      : linkedinAdObject.skipAdCreative && activity?.externalId
      ? 1
      : 0
  );

  const { mutate, isLoading, isError } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
        setActiveStep((prev) => prev + 1);
      },
    }
  );

  //Trigger preview---------------------------------------

  const [adBannerPreview, setAdBannerPreview] = useState(null);

  // const previewMutation = useMutation(
  //   (adObject) => putPreview(activity?.id, adObject),
  //   {
  //     onSuccess: (response) => {
  //       queryClient.invalidateQueries("MobilePreview/" + activity?.id);
  //       console.log({ response });
  //       setAdBannerPreview(response);
  //     },
  //   }
  // );

  // useEffect(() => {
  //   const updatedCampaign = {
  //     ...activity,
  //     ad: activity?.ad && {
  //       ...activity?.ad,
  //       title: linkedinAdObject.adTitle,
  //       description: linkedinAdObject.adDescription,
  //       metaPageId: linkedinAdObject.metaPageId,
  //       targetUrl: linkedinAdObject.adTargetUrl,
  //       landingPageID: linkedinAdObject.landingPageID,
  //       adMedias: [
  //         {
  //           externalId: linkedinAdObject.imageId,
  //         },
  //       ],
  //     },
  //   };
  //   if (linkedinAdObject.imageId) {
  //     previewMutation.mutate(updatedCampaign);
  //   }
  // }, [linkedinAdObject.imageId]);

  const steps = [
    {
      step: 0,
      children: (
        <Container
          className="linkedin-container"
          key={0}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <LinkedinStep0
            campaign={campaign}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            ref={formRef0}
            setBgBlur={setBgBlur}
            // previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
           
          />
        </Container>
      ),
    },
    {
      step: 1,
      children: (
        <Container
          className="linkedin-container"
          key={0}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <LinkedinStep1
            campaign={campaign}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            ref={formRef}
            setBgBlur={setBgBlur}
            // previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
    {
      step: 2,
      children: (
        <Container
          className="linkedin-container"
          key={1}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <LinkedinStep2
            campaign={campaign}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            mutate={mutate}
            ref={formRef2}
            // previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
            segment={segment}
          />
        </Container>
      ),
    },
    {
      step: 3,
      children: (
        <Container
          className="linkedin-container"
          key={2}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <LinkedinStep3
            campaign={campaign}
            mutate={mutate}
            activity={activity}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
  ];

  const stepsNoAd = [
    {
      step: 0,
      children: (
        <Container
          className="meta-container"
          key={0}
          style={{ padding: "45px 70px 100px 70px" }}
        >
          <LinkedinStep0
            campaign={campaign}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            ref={formRef0}
            setBgBlur={setBgBlur}
            // previewMutation={previewMutation}
            adBannerPreview={adBannerPreview}
            segment={segment}
            segmentIsLoading={segmentIsLoading}
          />
        </Container>
      ),
    },
    {
      step: 1,
      children: (
        <Container
          className="meta-container"
          key={1}
          style={{ padding: "40px 70px 100px 70px" }}
        >
          <LinkedinStep3
            campaign={campaign}
            mutate={mutate}
            activity={activity}
            adBannerPreview={adBannerPreview}
          />
        </Container>
      ),
    },
  ];

  return (
    <>
      {!linkedinAdObject.skipAdCreative &&
        steps.map((step) => {
          if (step.step === activeStep) {
            return step.children;
          }
        })}
      {linkedinAdObject.skipAdCreative &&
        stepsNoAd.map((step) => {
          if (step.step === activeStep) {
            return step.children;
          }
        })}
      {activeStep !== 3 && (
        <LinkedinStepper
          mutate={mutate}
          isError={isError}
          isLoading={isLoading}
          activity={activity}
          campaign={campaign}
          steps={linkedinAdObject.skipAdCreative ? stepsNoAd : steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          linkedinAdObject={linkedinAdObject}
          formRef={formRef}
          formRef2={formRef2}
          formRef0={formRef0}
        />
      )}
    </>
  );
};

export default LinkedIn;