import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
} from "react-bootstrap";
import VisualCardSettingsModal from "../../components/cards/visual-card/VisualCardSettingsModal";
import { MdAddChart } from "react-icons/md";

export default function AddVisualCard() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 


  return (
    <Col className="col-6 mx-auto mb-2">
        <Button
          variant="success"
          style={{ height: "fit-content" }}
          onClick={handleShow}
          className="d-flex display-xs semi-bold mx-auto align-items-center"
        >
          <MdAddChart className="me-1"/>Lägg till övervakning
        </Button>

      <VisualCardSettingsModal onClose={handleClose} visible={show}/>
    </Col>
  );
}
