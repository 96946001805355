import { Row, Col, Accordion, Spinner, Container } from "react-bootstrap";
import { MdAddCircleOutline } from "react-icons/md";
import { IoMdCloseCircleOutline, IoMdTrash } from "react-icons/io";
import { PiUserListFill, PiListNumbersFill } from "react-icons/pi";
import {
  FilterCategoriesList,
  SelectedFilterCategories,
} from "features/filter/add-filters";
import { TbPlaylistAdd } from "react-icons/tb";
import { useState } from "react";
import Agent from "api/Agent";
import { useParams } from "react-router-dom";

const FiltersListContainer = () => {
  const [open, setOpen] = useState(false);
  const [isActiveId, setIsActiveId] = useState("");

  const { id } = useParams();
  const { data: segment, isLoading } = Agent.Segments.GET.ID(id);

  if (isLoading)
    return (
      <Row className="gy-3 p-0">
        <Accordion className="row ">
          <Accordion.Item
            eventKey="0"
            className="d-flex justify-content-center"
          >
            <Accordion.Header>
              <Spinner />
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  return (
    <Row className="gy-3 p-0 ">
      <SelectedFilterCategories
        segment={segment}
        isActiveId={isActiveId}
        setIsActiveId={setIsActiveId}
      />
      <Accordion className="m-0">
        <Accordion.Item eventKey="0">
          <Accordion.Header
            onClick={() => setOpen(!open)}
            className="add-filter-button"
          >
            <Row className="">
              <Col
                className=" d-flex justify-items-start align-items-center m-0"
                style={{ color: "rgba(2, 124, 193, 1)", maxWidth: "60px" }}
              >
                {open ? (
                  <IoMdCloseCircleOutline
                    style={{ width: "100%", height: "auto" }}
                  />
                ) : (
                  <MdAddCircleOutline
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </Col>
              <Col
                className="text-start"
                style={{ color: "rgba(2, 124, 193, 1)" }}
              >
                <Row>
                  <Col className="col-12">
                    {open ? (
                      <h5 className="display-xs semi-bold">Välj Filter</h5>
                    ) : (
                      <h5 className="display-xs semi-bold">
                        Lägg till en filterkategori
                      </h5>
                    )}
                  </Col>
                  <Col className="col-12">
                    <p className="text-sm">Geografi, Företagstyp, Ekonomi...</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Accordion.Header>

          <Accordion.Body>
            <FilterCategoriesList
              isActiveId={isActiveId}
              setIsActiveId={setIsActiveId}
              setOpen={setOpen}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* //? PREPARATIONS FOR COMBINING WITH LISTBLOCK */}
      {/* <hr className="mb-3" />
      <Accordion className="m-0">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="add-filter-button">
            <Row style={{ color: "rgba(2, 124, 193, 1)" }}>
              <Col
                className=" d-flex justify-items-start align-items-center m-0"
                style={{ maxWidth: "60px" }}
              >
                <TbPlaylistAdd style={{ width: "100%", height: "auto" }} />
              </Col>
              <Col className="text-start">
                <Row>
                  <Col className="col-12">
                    <h5 className="display-xs semi-bold">
                      Kombinera med lista
                    </h5>
                  </Col>
                  <Col className="col-12">
                    <p className="text-sm">Företag, Personer...</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            <Container className={"filter-item-container mb-3"}>
              <Row className="justify-content-center align-items-center px-3">
                <Col
                  className="col-12 filter-item-icon-col "
                  style={{ backgroundColor: "rgb(201, 183, 126)" }}
                >
                  <PiUserListFill />
                </Col>
                <Col>
                  <Row className="gy-0">
                    <Col className="col-12 ">
                      <Row className="justify-content-between">
                        <Col className="col-9">
                          <Row>
                            <Col style={{ position: "relative" }}>
                              <h4 className="display-xs semi-bold m-0">
                                Mina kunder
                              </h4>
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <Row className="justify-content-end">
                            <Col className="col-1 filter-item-container-remove">
                              <IoMdTrash size={20} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <p className="text-xs regular m-0">
                        Antal: 354st | Skapad: 2024-02-16
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container className={"filter-item-container mb-3"}>
              <Row className="justify-content-center align-items-center px-3">
                <Col
                  className="col-12 filter-item-icon-col "
                  style={{ backgroundColor: "rgb(0,0,0)" }}
                >
                  <PiListNumbersFill />
                </Col>
                <Col>
                  <Row className="gy-0">
                    <Col className="col-12 ">
                      <Row className="justify-content-between">
                        <Col className="col-9">
                          <Row>
                            <Col style={{ position: "relative" }}>
                              <h4 className="display-xs semi-bold m-0">
                                Företagslista
                              </h4>
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <Row className="justify-content-end">
                            <Col className="col-1 filter-item-container-remove">
                              <IoMdTrash size={20} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <p className="text-xs regular m-0">
                        Antal: 354st | Skapad: 2024-02-16
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container
              className={"filter-item-container mb-3"}
              style={{
                backgroundColor: "#b0e6fc ",
                color: "#3fbde2",
                border: "1px solid #3fbde2",
              }}
            >
              <Row className="justify-content-center align-items-center px-3">
                <Col className="col-12 filter-item-icon-col ">
                  <TbPlaylistAdd color="#3fbde2" size={40} />
                </Col>
                <Col>
                  <Row className="gy-0">
                    <Col className="col-12 ">
                      <Row className="justify-content-between">
                        <Col className="col-9">
                          <Row>
                            <Col style={{ position: "relative" }}>
                              <h4 className="display-xs semi-bold m-0">
                                Ladda upp ny lista
                              </h4>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row className="justify-content-end">
                            <Col className="col-1 filter-item-container-remove"></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <p className="text-xs regular m-0">
                        Antal: 354st | Skapad: 2024-02-16
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </Row>
  );
};

export default FiltersListContainer;

//Add Enable Settings
