import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { getAttributesFromEntity } from "../../../features/stats/assets/api";
import { CreateNewVisual } from "../../../features/stats/assets/statisticsSlice";
import { string } from "prop-types";

export default function VisualCardSettingsModal({ visible, onClose }) {
  const dispatch = useDispatch();
  const { types } = useSelector((state) => state.statistics.visual);
  const pageId = useSelector((state) => state.statistics.id);
  const { entities } = useSelector((state) => state.statistics.visual);


  const [attributes, setAttributes] = useState();
  const [visualName, setVisualName] = useState("");
  const [visualType, setVisualType] = useState("");
  const [visualEntity, setVisualEntity] = useState("");

  const [visualAttribute, setVisualAttribute] = useState("");
  const [attributeDisabled, setAttributeDisabled] = useState(true);
  const [entityDisabled, setEntityDisabled] = useState(false);

  const handleAdd = () => {
    const newVisual = {
      name: visualName,
      groupBy: !!visualAttribute ? visualAttribute : undefined,
      pageId: pageId,
      entity: !!visualEntity ? visualEntity : undefined,
      type: visualType,
      sortOrder: -1,
    };
    dispatch(CreateNewVisual(newVisual));
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(false);
      event.stopPropagation();
    } else {
      setValidated(true);

      handleAdd();
      handleClose();
    }
    event.preventDefault();
  };

  const resetFormData = () => {
    setAttributes()
    setVisualName("")
    setVisualType("")
    setVisualEntity("")
    setVisualAttribute("")
    setAttributeDisabled(true)
    setValidated(false)
  }

  const handleClose = () => {
    resetFormData();
    onClose();
  }

  const getAttributes = useMutation((entityID) => {
    setAttributes();
    return getAttributesFromEntity(entityID);
  }

  );

  useEffect(() => {
    setAttributeDisabled(false);
    if (visualType === "0" || visualType === "" || visualType === "6")
    {
      setEntityDisabled(visualType === "6")
      return setAttributeDisabled(true);
    }
  }, [visualType]);


  useEffect(() => {
    if (!visualEntity) return;

    const HasEntityInVisualName =
      entities.filter((entity) => visualName.includes(entity?.name))?.length >
      0;
    if (!visualName || HasEntityInVisualName)
      setVisualName(entities[visualEntity]?.name);


    if (entities[visualEntity]?.id)
      getAttributes.mutate(entities[visualEntity]?.id, {
        onSuccess: (data, variables, context) => {
          setAttributes(data);

          const currentAttribute = data.filter(
            (attribute) => attribute?.id === visualAttribute
          )[0];

          if (currentAttribute?.name)
            setVisualName(
              `${entities[visualEntity]?.name} by ${currentAttribute?.name}`
            );
          if (!currentAttribute) {
            setVisualAttribute("");
            setVisualName(entities[visualEntity]?.name);
          }
        },
      });
  }, [visualEntity, visualAttribute]);





  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lägg till övervakning</Modal.Title>
      </Modal.Header>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Modal.Body className="d-flex align-items-baseline flex-column gap-3">
          <InputGroup hasValidation>
            <FloatingLabel
              controlId="floatingInput"
              label="Namn på övervakning"
            >
              <Form.Control
                required
                type="text"
                placeholder="Namn på övervakning"
                onChange={(e) => setVisualName(e.target.value)}
                value={visualName}
              />
              <Form.Control.Feedback type="invalid">
                Namnge elementet.
              </Form.Control.Feedback>
            </FloatingLabel>
          </InputGroup>

          <InputGroup hasValidation>
            <Form.Select
              aria-label="select chart"
              className=" mx-1"
              required
              value={visualType}
              onChange={(e) => setVisualType(e.target.value)}
            >
              <option value={""}>Välj graf</option>
              {types !== undefined &&
                types.map((option, index) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}


            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Välj en graf
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup hasValidation={entityDisabled}>
            <Form.Select
              aria-label="select entity"
              className="mx-1"
              disabled={entityDisabled}
              required={entityDisabled}
              value={visualEntity}
              style={{
                transition: "all .2s",
                opacity: entityDisabled ? "0" : "1",
              }}

              onChange={(e) => {
                setVisualEntity(e.target.value);
              }}
            >
              <option value={""}>Välj kategori</option>
              {entities !== undefined &&
                entities.map((option, index) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Välj en kategori
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup hasValidation={attributeDisabled}>
            <Form.Select
              disabled={attributeDisabled}
              aria-label="select attribute"
              className="mx-1"
              required={attributeDisabled}
              style={{
                transition: "all .2s",
                opacity: attributeDisabled ? "0" : "1",
              }}
              value={visualAttribute}
              onChange={(e) => {
                setVisualAttribute(e.target.value);
              }}
            >
              <option value={""}>Gruppera på attribut</option>
              {attributes !== undefined &&
                attributes.map((option, index) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Välj ett attribut
            </Form.Control.Feedback>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            Stäng
          </Button>
          <Button type="submit" variant="success" onSubmit={() => { }}>
            Spara
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
