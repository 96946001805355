
import {
  Badge,
  Col,
  Form,
  Row,
  Spinner,
  Stack,
  Table,
} from "react-bootstrap";
import { useState } from "react";
import { CardContainer } from "components/cards";

import Agent from "api/Agent";
import SegmentDetailsOffCanvas from "features/segment/segment-details/SegmentDetailsOffCanvas";
import { segmentStatuses_sv, segmentTypes_sv } from "features/segment/segment-details/assets/config";
import moment from "moment";
import Numeral from "react-numeral";
import AddSegmentToCampaign from "./AddSegmentToCampaign";
import RemoveCampaignSegment from "./RemoveCampaignSegment";


const CampaignSegmentsTable = ({ campaign, enableRemoval, onRemove }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (segment) => {
    setShowModal(true);
    setSelectedSegment(segment);
  };
  const handleModalClose = () => {
    setSelectedSegment(null);
    setShowModal(false)
  }

  const { data: campaignSegments, isLoading, isError, isSuccess } = Agent.Campaigns.GET.SEGMENTS(campaign?.id);
  const [selectedSegment, setSelectedSegment] = useState(null);


  const columns = [
    { header: "Målgrupp", data: (campaignSegment) => campaignSegment?.segment?.name, },
    {
      header: "Status", data: (campaignSegment) => segmentStatuses_sv[campaignSegment?.segment?.status] &&
        <Badge
          pill
          bg={segmentStatuses_sv[campaignSegment?.segment?.status]?.variant}
        >
          {segmentStatuses_sv[campaignSegment?.segment?.status]?.text}
        </Badge>
    },
    {
      header: "Skapad", data: (campaignSegment) => campaignSegment?.segment?.createdOn &&
        <Stack className="gap-2">
          {moment(campaignSegment?.segment?.createdOn).format("yyyy-MM-DD")}
          <p
            className="text-sm"
            style={{ color: "#7B8FA1", margin: "-10px 0" }}
          >
            {moment(campaignSegment?.segment?.createdOn).format("HH:mm")}
          </p>

        </Stack>,
    },
    { header: "Typ", data: (campaignSegment) => segmentTypes_sv[campaignSegment?.segment?.type]?.text, },
    { header: "Egen Lista", data: (campaignSegment) => <div className="d-flex justify-content-center align-items-center"><Form.Check checked={campaignSegment?.segment?.customListID} disabled /></div> },
    { header: "Storlek", data: (campaignSegment) => <Numeral value={campaignSegment?.segment?.size} format={"0,0"} /> },
  ];

  if (enableRemoval) columns.push({ header: "Ta bort", data: (campaignSegment) => <RemoveCampaignSegment campaignSegment={campaignSegment} onRemove={(e) => onRemove && onRemove(e)} /> })


  return (<>

    <CardContainer
      style={{ padding: "0" }}
      children={
        <>
          <Table>
            <thead className="text-sm semi-bold">
              <tr>
                {columns.map((col) => (
                  <th
                    key={col.id}
                    align="center"
                    className="text-center">
                    {col?.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-md regular">

              {isLoading &&
                <tr>
                  <td colSpan={99} className="text-center" >
                    <Spinner className="mt-1" />
                  </td>
                </tr>
              }

              {isError &&
                <tr>
                  <td colSpan={99} className="text-center" >
                    <span>Ett fel uppstod, försök igen...</span>
                  </td>
                </tr>
              }

              {isSuccess && !campaignSegments?.length ?
                <tr>
                  <td colSpan={99} className="text-center" >
                    <span>Inga resultat</span>
                  </td>
                </tr>
                : isSuccess &&
                campaignSegments?.map((campaignSegment) =>
                  <tr key={campaignSegment.id} onClick={() => handleShowModal(campaignSegment?.segment)} >
                    {columns?.filter(col => col?.data !== undefined).map(col => <td key={col?.property} align="center" className="text-sm text-center">{(typeof col?.data === "function" && col?.data(campaignSegment)) ?? "-"}</td>)}
                  </tr>
                )}
            </tbody>
            <tfoot>

              <tr>
                <td colSpan={99} style={{ border: "none" }}>
                  <Row className="row-cols-auto justify-content-end px-3 align-items-center">

                    <AddSegmentToCampaign campaign={campaign} />
                  </Row>
                </td>

              </tr>
            </tfoot>
          </Table>
        </>
      }
    />
    <SegmentDetailsOffCanvas show={showModal} setShow={handleModalClose} segment={selectedSegment} />
  </>
  );
};

export default CampaignSegmentsTable;

