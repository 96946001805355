import moment from "moment";
export const EMAIL_CHART_COLORS = [
  "#2D6450",
  "#B2CCC3",
  "#B26609",
  "#F6DDBD",
  "#EC5F52",
  "#FFDEDB",
];

export const EMAIL_CHART_COLOR_OPACITY = {
  '100': 'FF',
  '60': '99',
  '40': '66',
  '20': '33',
};

export const EMAIL_CHART_RANDOM_COLOR = EMAIL_CHART_COLORS[Math.floor(Math.random() * EMAIL_CHART_COLORS.length)];

export function parseChartData(charts, campaignStatistic) {
  const getChartData = (items, chartIndex) => {

    const colorOfChartIndexOrRandom =
      chartIndex < EMAIL_CHART_COLORS.length
        ? EMAIL_CHART_COLORS[chartIndex]
        : EMAIL_CHART_COLORS[Math.floor(Math.random() * EMAIL_CHART_COLORS.length)];

    let chartData = {
      labels: [],
      datasets: [
        {
          label: [],
          data: [],
        },
      ],
    };
    const stats = campaignStatistic?.filter(statistics => items?.filter(item => statistics[item?.property] > 0)?.length > 0);

    try {
      chartData = {

        labels: stats?.map(stat => moment(stat.date).format("YYYY-MM-DD")),
        datasets: items?.flatMap(item => {
          const propertyStatistics = stats?.filter(stat => stat[item.property]);
          const data = propertyStatistics.map(x => x[item.property])
          return {
            type: "bar",
            label: item.label,
            data: data,
            backgroundColor: colorOfChartIndexOrRandom + EMAIL_CHART_COLOR_OPACITY[items?.length > 1 ? "40" : "100"],
            borderColor: "black",
            borderWidth: 1,
            borderRadius: 10,
            barPercentage: 0.6,
            categoryPercentage: 1,
            pointStyle: "circle",
            //For smooth linechart
            lineTension: 0.3,
            pointRadius: 0,
            stack: moment(propertyStatistics.date).format("YYYY-MM-DD")
          }
        })
      }


    } catch (error) {
      console.log(error);
    }
    return chartData;
  };

  const result = charts?.map((chart, index) => ({
    label: "",
    chartData: getChartData(chart, index)
  }));
  return result;
}
