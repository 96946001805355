import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import styles from "../../style.module.scss";

const PersonMenu = ({ selectedMenuItem, options }) => {
    const [active, setActive] = useState(options[0].name);
    const handleSelection = (selection) => {
      setActive(selection);
      selectedMenuItem(selection);
    };
  
    return (
      <Row className="d-flex justify-content-start px-4 g-1">
        {options.map((option) => (
          <Col
            key={option.id}
            role="button"
            onClick={() => handleSelection(option.name)}
            className={`${active === option.name ? styles.navItem : null}
                     text-center text-md semi-bold py-2 col-1`}
          >
            {option.label}
          </Col>
        ))}
      </Row>
    );
}

export default PersonMenu