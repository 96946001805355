import { Row, Col } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";

const EditTargetGroupMaxSize = ({ campaign, activity }) => {
  return (
    <Row>
      <Col className="col-md-4 regular">
        <p>Antal mottagare</p>
      </Col>
      <Col>
        <p style={{ color: "#545F61" }}>
          {campaign?.autoSegment ? "-" : `${activity.targetGroupMaxSize} st`}
        </p>
      </Col>
      <Col className="col-2 edit-col-icon">
          <AiFillLock color="rgb(212 212 212)" />
        </Col>
        {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {/* <Col className="col-2 edit-col-icon">
        <AiFillEdit />
      </Col> */}
    </Row>
  );
};

export default EditTargetGroupMaxSize;
