import { useQueryClient } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { setCampaignId, setShowCanvas } from "features/campaign/campaignSlice";
import "../style.scss";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import ModalDeleteCampaign from "./ModalDeleteCampaign";
import { useNavigate } from "react-router-dom";
import { TextInput } from "components/forms";
import {
  Table,
  Row,
  Col,
  Spinner,
  Badge,
  ProgressBar,
  Button,
} from "react-bootstrap";
import {
  MdSearch,
  MdArrowRightAlt,
  MdCheckCircleOutline,
  MdCancel,
  MdMailOutline,
  MdOutlineSms,
} from "react-icons/md";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { CardContainer } from "components/cards";
import CampaignDetailsOffCanvas from "features/campaign/campaign-details/CampaignDetailsOffCanvas";
import PaginationBar from "components/pagination/PaginationBar";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";
import Agent from "api/Agent";
import { FaSort, FaSortDown, FaSortUp, FaTiktok } from "react-icons/fa";
import FilterDropdown from "./FilterDropdown";
import CampaignOverallStatistics from "./CampaignOverallStatistics";

const CampaignTable = (props) => {
  const queryClient = useQueryClient();
  const ifBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const ifMediumScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  //OffCanvas Campaign details
  const [show, setShow] = useState(false);
  const campaignId = useSelector((state) => state.campaign.campaignId);
  const showCanvas = useSelector((state) => state.campaign.showCanvas);
  const dispatch = useDispatch();
  // const [campaignId, setCampaignId] = useState(null);
  const [sort, setSort] = useState({ prop: "createdOn", desc: true });

  const handleClose = () => {
    dispatch(setShowCanvas(false));
    dispatch(setCampaignId(null));

    queryClient.removeQueries("segment");

    // handled with Agent instead
    // queryClient.removeQueries("campaign");
  };

  const handleShow = (id) => {
    dispatch(setCampaignId(id));
    dispatch(setShowCanvas(true));
  };

  //Statistics
  const [showStatistics, setShowStatistics] = useState(false);
  const handleCloseStatistics = () => {
    setShowStatistics(false);
  };

  //Table search and pagination
  const [search, setSearch] = useState(null);
  const rowsPerPage = props.rows ? props.rows : 8;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Table filters
  const [activityFilter, setActivityFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  const {
    data: campaignsResponse,
    isError,
    isLoading,
    error,
  } = Agent.Campaigns.GET.ALL(
    currentPage,
    rowsPerPage,
    search,
    sort?.prop,
    sort?.desc,
    activityFilter,
    statusFilter,
    props.withSegmentID
  );
  const data = campaignsResponse?.data;

  const navigate = useNavigate();

  const onEditClick = (event, id) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    console.log({ id });
    navigate(`/campaigns/create-campaign/${id}/type-of-campaign`);
  };

  //OnDelete---------
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedObject, setSelectedObject] = useState({ id: null });
  const [confirmDelete, setConfirmDelete] = useState([]);

  const handleSearch = (input) => {
    if (currentPage > 1) setCurrentPage(1);
    setSearch(input);
  };
  const openModal = (event, id) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    setShowDeleteModal(true);
    dispatch(setCampaignId(id));
  };
  const { mutate } = Agent.Campaigns.DEL.ID();
  const deleteCampaign = (id) =>
    mutate(id, {
      onMutate: (id) => {
        setLoadingDelete(true);
        setSelectedObject({ id });
      },
      onSuccess: () => {
        setSelectedObject({ id: null });
        setShowDeleteModal(false);
      },
      onError: () => {
        setLoadingDelete(false);
        setSelectedObject({ id: null });
      },
      onSettled: () => {
        setLoadingDelete(false);
      },
    });

  const onDeleteClick = (id) => {
    setConfirmDelete(confirmDelete.filter((x) => x !== id));
    deleteCampaign(id);
  };

  const columns = [
    { label: "Kampanj", property: "name", sortable: "true" },
    { label: "Status", property: "state", sortable: "true" },
    { label: "Skapad", property: "createdOn", sortable: "true" },
    { label: "Visningar", property: "statistics.exposures" },
    { label: "Klick", property: "statistics.clicks" },
    { label: "CTR", property: "statistics.ctr" },
    { label: "Konv.", property: "statistics.conversions" },
    { label: "Måluppfyllnad", property: "item.statistics.fulfillment" },

    { label: "Kanaler" },
  ];

  const columnsSmall = [
    { label: "Kampanj", property: "name", sortable: "true" },
    { label: "Status", property: "state", sortable: "true" },
    { label: "Skapad", property: "createdOn", sortable: "true" },

    { label: "Kanaler" },
    { label: "" },
  ];

  if (!ifSmallScreen) {
    columns.push({ label: "" });
  }

  if (!ifBigScreen) {
    columns.splice(3, 5);
  }

  if (ifSmallScreen) {
    columns.splice(1, 2);
  }

  const handleTableHeaderClick = (property) => {
    if (!property || property?.includes(".")) return;
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };

  return (
    <>
      {campaignId && (
        <CampaignDetailsOffCanvas
          show={showCanvas}
          handleClose={handleClose}
          campaignId={campaignId}
        />
      )}

      {showStatistics && (
        <CampaignOverallStatistics
          show={showStatistics}
          handleClose={handleCloseStatistics}
        />
      )}

      {showDeleteModal && (
        <ModalDeleteCampaign
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          loadingDelete={loadingDelete}
          onDeleteClick={onDeleteClick}
          id={campaignId}
          data={data}
        />
      )}
      <Row>
        {(!props.typeSmall && !props?.withSegmentID) && (
          <Row className="d-flex flex-row align-items-baseline justify-content-between px-0 mx-0">
            <Col md={6} sm={12} className="pb-3 d-flex">
              <Button
                className="semi-bold text-md filter-button py-1"
                style={{ marginRight: "5px" }}
                variant="light"
                onClick={() => setShowStatistics(true)}
              >
                <AiOutlineLineChart /> Statistik
              </Button>

              <FilterDropdown
                setActivityFilter={setActivityFilter}
                setStatusFilter={setStatusFilter}
              />
            </Col>
            <Col
              md={6}
              sm={12}
              style={{ width: ifMediumScreen ? "100%" : "300px" }}
            >
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </Col>
          </Row>
        )}
        <Col className="col-12">
          <CardContainer
            style={{ padding: "0" }}
            children={
              <>
                <div className="table-responsive">
                  <Table
                    id="segmentTable"
                    className="table-container"
                    responsive
                    hover
                    size="lg"
                  >
                    <thead className="text-sm semi-bold">
                      <tr>
                        {!props.typeSmall
                          ? columns.map((col, index) => (
                            <th
                              style={{ width: "150px", userSelect: "none" }}
                              key={index}
                              onClick={() =>
                                col.property &&
                                handleTableHeaderClick(col.property)
                              }
                            >
                              {sort?.prop === col.property && col.property ? (
                                <p>
                                  {col.label}
                                  {sort?.desc ? (
                                    <FaSortDown className="ms-1" size={12} />
                                  ) : (
                                    <FaSortUp className="ms-1" size={12} />
                                  )}
                                </p>
                              ) : (
                                <p>
                                  {col.label}
                                  {!!col?.sortable && (
                                    <FaSort className="ms-1" size={12} />
                                  )}
                                </p>
                              )}
                            </th>
                          ))
                          : columnsSmall.map((col, index) => (
                            <th
                              style={{ width: "150px", userSelect: "none" }}
                              role="button"
                              key={index}
                              onClick={() =>
                                handleTableHeaderClick(col.property)
                              }
                            >
                              {sort?.prop === col.property && col.property ? (
                                <p>
                                  {col.label}
                                  {sort?.desc ? (
                                    <FaSortDown className="ms-1" size={12} />
                                  ) : (
                                    <FaSortUp className="ms-1" size={12} />
                                  )}
                                </p>
                              ) : (
                                <p>
                                  {col.label}
                                  {!!col?.sortable && (
                                    <FaSort className="ms-1" size={12} />
                                  )}
                                </p>
                              )}
                            </th>
                          ))}
                      </tr>
                    </thead>

                    <tbody className="text-md regular">
                      {!isLoading &&
                        !isError &&
                        data?.map((item) => (
                          <tr key={item.id} onClick={() => handleShow(item.id)}>
                            <td style={{ whiteSpace: "nowrap" }}>
                              <Row className="flex-column g-0">
                                <Col>{item.name}</Col>
                                {item.startDate && !ifSmallScreen && (
                                  <Col className="table-dates">
                                    {item.startDate?.substring(0, 10)}
                                    <MdArrowRightAlt
                                      className="mx-1"
                                      size={12}
                                    />
                                    {item.endDate?.substring(0, 10)}
                                  </Col>
                                )}
                              </Row>
                            </td>
                            {!ifSmallScreen && (
                              <>
                                <td>
                                  {item.state === 0 ? (
                                    <Badge pill bg="warning">
                                      Schemalagd
                                    </Badge>
                                  ) : item.state === 1 ? (
                                    <Badge pill bg="success">
                                      Aktiv
                                    </Badge>
                                  ) : item.state === 2 ? (
                                    <Badge pill bg="info">
                                      Paus
                                    </Badge>
                                  ) : (
                                    <Badge pill bg="danger">
                                      Avslutad
                                    </Badge>
                                  )}
                                </td>

                                <td>
                                  {item?.createdOn.substring(0, 10)}
                                  <p
                                    className="text-sm"
                                    style={{
                                      color: "#7B8FA1",
                                      margin: "-10px 0",
                                    }}
                                  >
                                    {item?.createdOn.substring(11, 16)}
                                  </p>
                                </td>
                              </>
                            )}

                            {ifBigScreen && !props.typeSmall && (
                              <>
                                {item.statistics?.exposures ? (
                                  <td>{item.statistics.exposures}</td>
                                ) : (
                                  <td>-</td>
                                )}
                                {item.statistics?.clicks ? (
                                  <td>{item.statistics.clicks}</td>
                                ) : (
                                  <td>-</td>
                                )}
                                {item.statistics?.ctr ? (
                                  <td>{item?.statistics.ctr.toFixed(2)}%</td>
                                ) : (
                                  <td>-</td>
                                )}
                                {item.statistics?.conversions ? (
                                  <td>{item.statistics.conversions}</td>
                                ) : (
                                  <td>-</td>
                                )}

                                <td>
                                  <div style={{ padding: "14px 14px 0px 0px" }}>
                                    <ProgressBar
                                      variant="success"
                                      now={
                                        item.statistics &&
                                        item.statistics.fulfillment &&
                                        item.statistics.fulfillment
                                      }
                                      visuallyHidden
                                    />
                                  </div>
                                </td>
                              </>
                            )}

                            <td>
                              <Row className="gx-2">
                                {item.enableFacebook && (
                                  <Col className="text-center col-4">
                                    <TbBrandMeta size={18} color="#216FE4" />
                                  </Col>
                                )}
                                {item.enableLinkedIn && (
                                  <Col className="text-center col-4">
                                    <SiLinkedin size={18} color="#0A66C2" />
                                  </Col>
                                )}
                                {item.enableGoogleAds && (
                                  <Col className="text-center col-4">
                                    <SiGoogleads size={18} color="#216FE4" />
                                  </Col>
                                )}
                                {item.enableTikTok && (
                                  <Col className="text-center col-4">
                                    <FaTiktok size={18} color="#000" />
                                  </Col>
                                )}
                                {item.enableLeadGeneration && (
                                  <Col className="text-center col-4">
                                    <BiTargetLock
                                      size={18}
                                      color="rgb(167 118 113)"
                                    />
                                  </Col>
                                )}
                                {item.enableEmail && (
                                  <Col className="text-center col-4">
                                    <MdMailOutline size={18} color="#B3CCC3" />
                                  </Col>
                                )}
                                {item.enableSms && (
                                  <Col className="text-center col-4">
                                    <MdOutlineSms size={18} color="#017397" />
                                  </Col>
                                )}
                              </Row>
                            </td>

                            <td onClick={(e) => e.stopPropagation()}>
                              <div align="center ">
                                {!confirmDelete?.find(
                                  (_delete) => _delete === item?.id
                                ) ? (
                                  <>
                                    <button
                                      className="edit-button"
                                      onClick={(event) =>
                                        onEditClick(event, item.id)
                                      }
                                    >
                                      <FiEdit3 size={18} />
                                    </button>
                                    <button
                                      className="delete-button"
                                      onClick={() =>
                                        setConfirmDelete([
                                          ...confirmDelete,
                                          item.id,
                                        ])
                                      }
                                    >
                                      <FiTrash2 size={18} />
                                    </button>
                                  </>
                                ) : loadingDelete &&
                                  item.id === selectedObject.id ? (
                                  <Spinner size="sm" />
                                ) : (
                                  <>
                                    <MdCancel
                                      size={20}
                                      className="edit-button me-2"
                                      onClick={(e) => {
                                        setConfirmDelete(
                                          confirmDelete.filter(
                                            (x) => x !== item.id
                                          )
                                        );
                                      }}
                                    />
                                    <MdCheckCircleOutline
                                      size={20}
                                      className="delete-button"
                                      onClick={() => onDeleteClick(item.id)}
                                    />
                                  </>
                                )}
                              </div>
                            </td>

                          </tr>
                        ))}
                    </tbody>
                    {isError && (
                      <caption
                        style={{ captionSide: "bottom", columnSpan: "100%" }}
                        className="p-3 bg-danger"
                      >
                        <h5 className=" text-white semi-bold text-center w-100">
                          Ett fel uppstod vid hämtning av data
                        </h5>
                      </caption>
                    )}

                    {isLoading && (
                      <caption
                        style={{ captionSide: "bottom", columnSpan: "100%" }}
                        className="p-3"
                      >
                        <h5 className=" text-black text-center w-100">
                          <Spinner />
                        </h5>
                      </caption>
                    )}

                    {!isLoading && !isError && !data?.length && (
                      <caption
                        style={{ captionSide: "bottom", columnSpan: "100%" }}
                        className="p-3 text-center"
                      >
                        <span className="text-xl text-black text-muted ">
                          Inga kampanjer hittade.
                        </span>
                      </caption>
                    )}
                  </Table>

                  {campaignsResponse && !props.typeSmall && (
                    <PaginationBar
                      currentPage={currentPage}
                      setCurrentPage={handlePageChange}
                      countPerPage={rowsPerPage}
                      totalCount={campaignsResponse?.paging?.total}
                    />
                  )}
                </div>
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default CampaignTable;
