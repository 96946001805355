import React, { useState } from "react";
import { Offcanvas, Row, Spinner, Form, InputGroup, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import Agent from "api/Agent";
import moment from "moment";
import { segmentTypes_sv } from "features/segment/segment-details/assets/config";
import FilterTypesTable from "./components/FilterTypesTable";
import * as icons from "react-icons/md";
import CreateFilterTypeModal from "./components/CreateFilterTypeModal/CreateFilterTypeModal";
import EditFilterTypeModal from "./components/EditFilterTypeModal/EditFilterTypeModal";
import { editFilterCategory } from "features/filter/filterSlice";
import { FilterItem } from "components/filter";
import DisplayCategory from "features/adminFilters/components/DisplayCategory";

const AdminFiltersDetails = ({ show, handleClose, id }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { data, isLoading, error } = Agent.FilterCategories.GET.ID(id)


  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      className={styles.offcanvas}
      placement="end"
    >
      <Offcanvas.Header
        className="flex-row-reverse justify-content-end align-items-baseline py-0 px-3"
        closeButton
      >

        <h2 className="display-sm semibold p-3">Filter Kategori</h2>
      </Offcanvas.Header>
      {
        isLoading ? <div className="h-100 d-flex flex-row justify-content-center align-items-center"><Spinner /></div> :

          <Offcanvas.Body className="px-4 gap-1 d-flex flex-column">
            <Row>
              <span className="semi-bold display-sm">Kategori</span>
              <DisplayCategory category={data} />

            </Row>
            <Row className="justify-content-center " >

              <Form className="d-flex flex-column gap-2 text-sm">
                <span className="semi-bold display-sm">Inställningar</span>
                <Row className="row-cols-1 row-cols-xl-2 gy-2">

                  <Form.Group >
                    <Form.Label>Rubrik</Form.Label>
                    <Form.Control type="text" className="text-md" disabled defaultValue={data?.name ?? ""} />
                  </Form.Group>

                  <Form.Group >
                    <Form.Label>Kategori</Form.Label>
                    <Form.Control type="text" className="text-md" disabled defaultValue={data?.title ?? ""} />
                  </Form.Group>
                </Row>

                <Row>

                  <Form.Group >
                    <Form.Label>Beskrivning</Form.Label>
                    <Form.Control type="text" className="text-md" disabled defaultValue={data?.subTitle ?? ""} />
                  </Form.Group>
                </Row>

                <Row className="row-cols-1 row-cols-xl-2 gy-2">

                  <Form.Group >
                    <Form.Label>Typ av målgrupp</Form.Label>
                    <Form.Control type="text" className="text-md" disabled defaultValue={segmentTypes_sv[data?.segmentType]?.text ?? "Alla"} />
                  </Form.Group>

                  <Form.Group >
                    <Form.Label>Skapad</Form.Label>
                    <Form.Control type="text" className="text-md" disabled defaultValue={moment(data?.createdOn).format("yyyy-MM-DD")} />
                  </Form.Group>



                  <Form.Group >
                    <Form.Label>Ikon</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control type="text" className="text-md" disabled defaultValue={data?.icon ?? "Ingen"} />
                      <InputGroup.Text id="basic-addon1">

                        {icons[data?.icon] && React.createElement(icons[data?.icon], {
                          className: "my-auto",
                          style: {
                            color: data?.iconBg,
                            width: "25px",
                            height: "25px",
                          },
                        }
                        )}

                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group >
                    <Form.Label>Ikon bakgrundsfärg</Form.Label>
                    <Form.Control type="color" disabled defaultValue={data?.iconBg} />
                  </Form.Group>

                </Row>


              </Form>

            </Row>

            <Row className="row-cols-1 gap-3">

              <Row className="row-cols-auto justify-content-between">
                <span className="semi-bold display-sm">Filter typer</span>

                <Button variant="success" onClick={() => setShowEditModal(true)}>
                  Ny Filter typ
                </Button>
              </Row>

              <FilterTypesTable filterCategory={data} />
            </Row>
          </Offcanvas.Body>
      }
      <EditFilterTypeModal show={showEditModal} setShow={setShowEditModal} categoryId={id} />
    </Offcanvas>
  );
};

export default AdminFiltersDetails;
