import { Row, Col, Container } from "react-bootstrap";
import { NewCustomerForm } from "features/customers";
import CustomerTable from "components/table/Customer/CustomerTableCopy";
import { useState } from "react";
import { MdSearch } from "react-icons/md";
import { TextInput } from "components/forms";

const AdminAccounts = () => {
  const [search, setSearch] = useState();
  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className="justify-content-between">
            <Col>
              <h2>Kontoinställningar</h2>
            </Col>
            <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
              <NewCustomerForm />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <Row className="justify-content-end">
            <Col className="col-5">
              <TextInput
                type="search"
                placeholder="Sök"
                icon={<MdSearch />}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
          </Row>

          <CustomerTable search={search} />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminAccounts;
