import Agent from "api/Agent";
import moment from "moment";
import { Button, Spinner } from "react-bootstrap"
import { toast } from "react-toastify";

const SaveTemplateButton = ({ emailEditorRef, isEditorReady, size}) => {
  const postMutation = Agent.EmailTemplate.POST.TEMPLATE();

  const handleTemplateCreation = async () => {
    try {
      const name = "Mall " + moment().format("YYYY-MM-DD HH:mm")
      await emailEditorRef.current.editor.exportHtml(async ({ design, html }) => {

        return postMutation.mutateAsync(
          { html: html, design: JSON.stringify(design), name: name, isGeneric: true },
          { onSuccess: () => { toast.success(`[${name}] skapad`) } })

      });

    } catch (error) {
      console.warn(error)
      return false
    }
  }

  const ButtonState = () => {
    return postMutation.isError ?
      <Button variant='danger' size={size} onClick={() => handleTemplateCreation()} >Försök igen</Button>
      :
      <Button variant='outline-info' size={size} onClick={() => handleTemplateCreation()} >{postMutation.isLoading || !isEditorReady ? <Spinner size="sm" /> : <p>Spara som mall</p>}</Button>
  }

  return (
    <ButtonState />)
}

export default SaveTemplateButton
