import React from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const TableHeader = ({ columns }) => {
  const renderHeaderColumns = (cols) => {
    return cols.map((col, index) => (
      <th
        key={index}
        style={{ width: "150px", userSelect: "none" }}
        role="button"
      >
        <p>
          {col?.header}
        </p>
      </th>
    ));
  };


  return (
    <thead className="text-sm semi-bold">
      <tr>
        {renderHeaderColumns(columns)}
      </tr>
    </thead>
  );
};

export default TableHeader;

