import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateVisual,
} from "../../../features/stats/assets/statisticsSlice";
import {
  getVisual,
} from "features/stats/assets/api";

export default function VisualCardModifySettingsModal({
  visible,
  onClose,
  visual,
}) {
  const dispatch = useDispatch();

  const typeOfChart = useSelector((state) =>
    state.statistics.visual.types.find((_type) => _type?.id === visual?.type)
  );

  const showLimitSetting = false;

  const [visualName, setVisualName] = useState(visual?.name);
  const [validated, setValidated] = useState(false);

  const isDisabled = false;

  const [limit, setLimit] = useState({ table: 100, graph: 10 });

  const handleLimit = (key, value = "") => {
    const newLimit = { ...limit, [key]: value?.replace(/[^0-9.]/g, '') }
    setLimit(newLimit)
  }

  useEffect(() => {
    if (!visible) return;

    setVisualName(visual?.name);
  }, [visual, visible]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(false);
      event.stopPropagation();
    } else {
      setValidated(true);

      getVisual(visual?.id)
        .then((data) => {
          dispatch(
            UpdateVisual({
              ...data,
              name: visualName,
            })
          ).then(() => handleClose());
        })
        .catch((err) =>
          console.error("There was a issue submitting the updated visual", err)
        );
    }
    event.preventDefault();
  };

  const resetFormData = () => {
    setVisualName(visual?.name);
    setValidated(false);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => resetFormData(), 1000);
  };

  return (
    <Modal show={visible} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Ändra övervakning</Modal.Title>
      </Modal.Header>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Modal.Body className="d-flex align-items-baseline flex-column gap-3">
          <InputGroup hasValidation>
            <FloatingLabel
              controlId="floatingInput"
              label="Namn på övervakning"
            >
              <Form.Control
                required
                type="text"
                placeholder="Namn på övervakning"
                onChange={(e) => setVisualName(e.target.value)}
                value={visualName}
              />
              <Form.Control.Feedback type="invalid">
                Namnge elementet.
              </Form.Control.Feedback>
            </FloatingLabel>
          </InputGroup>

          {typeOfChart && (
            <InputGroup>
              <FloatingLabel controlId="floatingInput" label="Typ">
                <Form.Control
                  disabled
                  required
                  type="text"
                  placeholder="Typ"
                  onChange={(e) => setVisualName(e.target.value)}
                  value={typeOfChart?.name}
                />
                <Form.Control.Feedback type="invalid">
                  Namnge elementet.
                </Form.Control.Feedback>
              </FloatingLabel>
            </InputGroup>
          )}
          {showLimitSetting && (
            <InputGroup hasValidation>
              <FloatingLabel
                controlId="floatingInput"
                label="Resultat i graf"
              >
                <Form.Control
                  type="text"
                  onChange={(e) => handleLimit("graph", e.target.value)}
                  value={limit?.graph}
                />
              </FloatingLabel>
            </InputGroup>
          )}

          {showLimitSetting && (
            <InputGroup hasValidation>
              <FloatingLabel
                controlId="floatingInput"
                label="Resultat i tabell"
              >
                <Form.Control
                  type="text"
                  onChange={(e) => handleLimit("table", e.target.value)}
                  value={limit?.table}
                />
              </FloatingLabel>
            </InputGroup>
          )}

        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
            }}
          >
            Avbryt
          </Button>
          <Button
            type="submit"
            variant="success"
            className={isDisabled && "bg-dark bg-opacity-75"}
            disabled={isDisabled}
            onSubmit={() => {
              handleSubmit();
            }}
          >
            Spara
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
