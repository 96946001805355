import { Row, Col, Form, Spinner } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useState } from "react";

const EditTargetURL = ({ activity, updateAd, updateAdLoading }) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(
    activity?.ad?.targetUrl ? activity.ad.targetUrl : ""
  );

  const updateTargetUrl = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/targetUrl",
        value: newValue,
      },
    ];
    updateAd(patchObj);
    setShowInput(false);
  };
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{minWidth:"170px"}}>
        <p>Måladress</p>
      </Col>

      {showInput ? (
        <Col className="flex-grow-1">
          <Form>
            <Form.Control
              type="text"
              defaultValue={newValue}
              onChange={(e) => setNewValue(e.target.value)}
            />
          </Form>
        </Col>
      ) : (
        <Col className="flex-grow-1"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            whiteSpace: "nowrap",
          }}
        >
          {updateAdLoading ? (
            <Spinner />
          ) : (
            <p className="text-center" style={{ color: "#545F61", textDecoration: "underline" }}>
              <a href={activity?.ad?.targetUrl} target="_blank">
                {activity?.ad?.targetUrl}
              </a>
            </p>
          )}
        </Col>
      )}

      {/* <Col className="col-2 edit-col-icon">
        <AiFillLock color="rgb(212 212 212)" />
      </Col> */}
      {/*//TODO: EDIT BELOW WHEN BACKEND ALLOWS META_EDITING*/}
      {showInput ? (
        <Col className="flex-shrink-1 edit-col-icon" onClick={updateTargetUrl}>
          <FaSave color="green" />
        </Col>
      ) : (
        <Col className="flex-shrink-1 edit-col-icon" onClick={() => setShowInput(true)}>
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditTargetURL;
