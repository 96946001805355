import {
  Row,
  Col,
  Container,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useState } from "react";
import ClientsTable from "features/accounts/my-accounts/components/ClientsTable";
import { useMediaQuery } from 'react-responsive';

const MyAccounts = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [accountType, setAccountType] = useState(6);
  const radios = [
    { name: "Företag", value: 6 },
    { name: "Personer", value: 5 },
  ];
  const onClickSwitch = (e) => {
    setAccountType(Number(e.currentTarget.value));
    console.log({ accountType });
  };

  return (
    <Container className="py-5 h-100">
      <Row className="gy-3">
        <Col className="col-12 mb-5">
          <Row className={`justify-content-between ${isSmallScreen ? 'flex-column' : ''}`}>
            <Col style={{marginTop: isSmallScreen ? 15 : ''}}>
              <h2>Mina Kunder</h2>
            </Col>
            <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
              <ButtonGroup>
                {radios.map((radio, i) => (
                  <ToggleButton
                    className="dark-toggle-button"
                    key={i}
                    id={i}
                    type="radio"
                    variant={"outline-secondary"}
                    value={radio.value?.toString()}
                    checked={
                      accountType.toString() === radio.value?.toString()
                    }
                    onChange={(e) => onClickSwitch(e)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
            {/* <Col className="col-lg-2 col-md-3 d-flex justify-content-end">
              <CreateCampaignButton />
            </Col> */}
          </Row>
        </Col>
        <Col className="col-12">
          <ClientsTable accountType={accountType} />
        </Col>
      </Row>
    </Container>
  );
};

export default MyAccounts;
