import Agent from 'api/Agent'
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { Col, Form, Spinner, Stack, Table } from 'react-bootstrap';
import Select from 'react-select';
import styles from './style.module.css'


export default function OffersSelectBox({ offerID, onSelect }) {
  const [search, setSearch] = useState();
  const [showExpired, setShowExpired] = useState(false);
  const [value, setValue] = useState();
  const offer = Agent.Offers.GET.ID(offerID);
  const Offers = Agent.Offers.GET.ALL(1, 30, search, showExpired);

  let options = [];
  if (typeof Offers.data?.Results !== "undefined") {
    options = [...Offers.data?.Results].filter(x => x?.id !== offer.data?.id);
  }
  const mappedOptions = options?.map(_offer => ({
    value: [_offer?.id, _offer, ...Object.values(_offer)],
    label: <div className='d-flex flex-column row-cols-auto text-sm'>
      <span>
        <strong>{_offer?.title}</strong>
      </span>
      <span>{_offer?.location}</span>
      {
        _offer?.startTime && _offer?.endTime &&
        <span>{moment(_offer?.startTime).format('yyyy-MM-DD')} - {moment(_offer?.endTime).format('yyyy-MM-DD')}</span>
      }
    </div>
  }))

  const newOfferSelected = !!value && value.value?.at(0) !== offerID;
  const noOfferSelected = !value && !offerID;

  const handleSelect = (val) => {
    setValue(val);
    if (typeof onSelect !== "function") return null;
    if (!val) return onSelect(offerID ?? null) 
    if (val?.value?.at(0) !== offerID) return onSelect(val?.value?.at(0))
  }
  return (
    <Stack className='gap-3 ' >
      <Stack>
      <Col className="d-flex align-items-baseline">
        <Form.Check
          style={{ width: "20px", height: "20px" }}
          checked={showExpired}
          onChange={() => setShowExpired((prev) => !prev)}
        />
        <Form.Label className="text-sm text-nowrap " >
          Visa historiska erbjudanden
        </Form.Label>
      </Col>

      <Select
        styles={{ control: (styles) => ({ ...styles, backgroundColor: "white" }) }}
        className="basic-single "
        classNamePrefix="select "
        placeholder="Sök & välj"
        isDisabled={Offers.isError}
        isLoading={Offers.isLoading || offer.isLoading}
        isClearable={true}
        isSearchable={true}
        onChange={(val) => handleSelect(val)}
          noOptionsMessage={() => "Inga erbjudanden" }
        value={value}

        inputValue={search}
        onInputChange={(e) => { setSearch(e) }}

        options={mappedOptions}
      />
      </Stack>

      <span>Valt erbjudande</span>

      <div className="table-responsive" style={{ zIndex: "0" }}>
        <Table className="campaign-table shadow-sm" hover>
          <thead>
            <tr className='text-sm'>
              <th>Erbjudande</th>
              <th>Plats</th>
              <th>Start</th>
              <th>Slut</th>
            </tr>
          </thead>
          <tbody>
            {
              offerID && (
                offer.isLoading ? <tr ><td colSpan={99} align="center" > <Spinner size='sm' /> </td></tr>
                  :
                  offer.isError ? <tr ><td colSpan={99} align="center" > <span className='text-center mx-auto'>Ett fel uppstod vid inladdning av förvalt erbjudande</span>  </td></tr>
                    :
                    offer.data &&
                    <tr className={`text-sm ${newOfferSelected ? styles.strikeout : ""}`}>
                      <td>{offer?.data?.title}</td>
                      <td>{offer?.data?.location}</td>
                      <td>
                        {offer?.data?.startTime ? moment(offer?.data?.startTime).format('yyyy-MM-DD') : ""}
                      </td>
                      <td>{offer?.data?.endTime ? moment(offer?.data?.endTime).format('yyyy-MM-DD') : ""}</td>
                    </tr>)
            }

            {
              noOfferSelected && <tr ><td colSpan={99} align="center" > <span className='text-center mx-auto'>Inget erbjudande valt</span> </td></tr>

            }

            {
              newOfferSelected && <tr >
                <td>{value.value?.at(1)?.title}</td>
                <td>{value.value?.at(1)?.location}</td>
                <td>{value.value?.at(1)?.startTime ? moment(value.value?.at(1)?.startTime).format('yyyy-MM-DD') : ""}</td>
                <td>{value.value?.at(1)?.endTime ? moment(value.value?.at(1)?.endTime).format('yyyy-MM-DD') : ""}</td>
              </tr>

            }
          </tbody>
        </Table>
      </div>

    </Stack>)
}

