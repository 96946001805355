import Agent from "api/Agent"
import { CampaignOptionsTypes } from "features/campaign/campaign-details/options"
import { useState } from "react"
import { Col, Offcanvas, OffcanvasBody } from "react-bootstrap"
import { useSelector } from "react-redux"
import ChannelNavbar from "./components/ChannelNavbar/ChannelNavbar"
import Overview from "./features/Overview"
import TikTokIntegrationSetting from "./features/TikTokIntegrationSetting"
import styles from "./style.module.css"

const SettingDetailsOffCanvas = ({ show, onClose }) => {
  const [channel, setChannel] = useState(null);
  const handleClose = () => { onClose() }
  return (
    <Offcanvas
      show={show}
      onHide={() => handleClose()}
      placement="end"
    >
      <div className={styles.campaignDetailsOffCanvas_Header}>
        <Offcanvas.Header
          className="flex-row-reverse justify-content-end align-items-baseline pb-0"
          closeButton
        >
          <Col className="d-flex justify-content-start ms-3 align-items-baseline">
            <h2 className="display-sm semibold">Konto</h2>
          </Col>
        </Offcanvas.Header>
        <ChannelNavbar
          onClick={(_channel) =>  setChannel(_channel) }
        />
      </div>

      <Offcanvas.Body>
        {
          {
            [CampaignOptionsTypes.TikTok]: <TikTokIntegrationSetting/>

          }[channel] ??
            <Overview/>
        }
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default SettingDetailsOffCanvas
