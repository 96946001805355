import "../style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { MdInfoOutline, MdOutlineCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { updateSegment } from "features/segment/api";
import { CardContainer } from "components/cards";
import { BsPlusCircle, BsPlusCircleFill, BsTrashFill } from "react-icons/bs";
import { useState } from "react";
import Agent from "api/Agent";
import FilterInputs from "components/filter/inputs/FilterInputs";

const FilterTypesList = () => {
  const activeFilterCategory = useSelector(
    (state) => state.filter.activeFilterCategory
  );

  //Get segment by ID------------------------------------------------------
  const { id } = useParams();
  const { data: segment, refetch } = Agent.Segments.GET.ID(id);
  const { data: filterGroups } = Agent.FilterGroups.GET.ALL();

  const parsedFilters =
    segment &&
    segment?.filters &&
    JSON.parse(segment?.filters && segment.filters);

  //Update Segment filters on input change--------------------------------------------------
  const queryClient = new useQueryClient();
  const { mutate, isLoading } = useMutation(
    (newSegment) => updateSegment({ id: segment?.id }, newSegment),
    {
      onSuccess: () => {
        // Re-fetch the segment data after the filter categories are updated
        queryClient.invalidateQueries(["segment", id]);
      },
    }
  );

  const [selectedFilterType, setSelectedFilterType] = useState("");

  //Add Filtertype-------
  const onClickAddFilterType = (filter) => {
    setSelectedFilterType(filter);
    try {
      const updatedSegment = {
        ...segment,
        filters:
          parsedFilters &&
          JSON.stringify([...parsedFilters, { [filter.id]: [] }]),
      };
      mutate(updatedSegment);
    } catch (error) {
      console.warn("issue in onClickAddFilterType. error: \n", error);
    }
  };

  const [loading, setLoading] = useState(false);
  const onClickAddFilterGroup = (filterGroup) => {
    const objKeyMatches = parsedFilters.some((obj) =>
      Object.keys(obj).includes(filterGroup.filterTypeID)
    );

    if (objKeyMatches) {
      // Replace the matching object with the new values
      const updatedFilters = parsedFilters.map((obj) => {
        const key = Object.keys(obj)[0];
        if (key === filterGroup.filterTypeID) {
          return { [filterGroup.filterTypeID]: [...filterGroup.filters] };
        }
        return obj;
      });

      const updatedSegment = {
        ...segment,
        filters: JSON.stringify(updatedFilters),
      };
      mutate(updatedSegment);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      try {
        const updatedSegment = {
          ...segment,
          filters:
            parsedFilters &&
            JSON.stringify([
              ...parsedFilters,
              {
                [filterGroup.filterTypeID]: [...filterGroup.filters],
              },
            ]),
        };
        mutate(updatedSegment);
      } catch (error) {
        console.warn("issue in onClickAddFilterType. error: \n", error);
      }
    }
  };

  const { mutate: deleteFiltergroup, isLoading: isLoadingDeleteFiltergroup } =
    Agent.FilterGroups.DEL.ID();

  const onClickRemoveGroup = (e, id) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    deleteFiltergroup(id);
  };

  //RemoveFilterType-----------------
  const onClickRemoveFilterType = (filter) => {
    try {
      const updatedFilters = parsedFilters.filter((obj) => {
        const objKey = Object.keys(obj)[0];
        const match = filter.id === objKey;

        return !match;
      });

      //Update the segment data with the new filter categories array
      const updatedSegment = {
        ...segment,
        filters: JSON.stringify(updatedFilters),
      };
      //Update the segment data using the mutate() function
      mutate(updatedSegment);
    } catch (error) {
      console.warn("issue in onClickRemoveFilterType. error : \n", error);
    }
  };

  //Show/Don't show dynamic date filters
  const [dynamicFilter, setDynamicFilter] = useState(false);

  const onClickDynamicFilter = (filter, filterId) => {
    const foundFilter = parsedFilters?.filter((f) => f[filter?.id]);
    if (filter.id === filterId) {
      setDynamicFilter(!dynamicFilter);
    }
  };
  //Operator for multiselect
  const [opValue, setOpValue] = useState(2);

  const radios = [
    { name: "Finns i", value: 2 },
    { name: "Finns inte i", value: 3 },
  ];

  const FilterGroup = () => {
    const NoFilterGroups = (
      <Dropdown.Item key={999} disabled>
        <Row className="justify-content-between">
          <Col className="col-8 text-start">
            Finns inga filtergrupper för {activeFilterCategory.title}
          </Col>
        </Row>
      </Dropdown.Item>
    );
    try {
      const FilterGroups = filterGroups
        ?.filter((item) => item.filterCategoryID === activeFilterCategory.id)
        .map((filter) => (
          <Dropdown.Item
            eventKey="1"
            key={filter.id}
            onClick={() => onClickAddFilterGroup(filter)}
          >
            <Row className="justify-content-between flex-nowrap">
              <Col className="col-8 text-start">{filter.name}</Col>
              <Col className="text-end">
                <BsPlusCircleFill size={25} color="rgb(61 213 243)" />
              </Col>
              {/* //?PREPARATIONS FOR DELETING FILTERGROUP, ERROR ON BACKEND ENDPOINT */}
              {/* <Col onClick={(e) => onClickRemoveGroup(e, filter.id)}>
                <BsTrashFill
                  className="filter-item-container-remove"
                  size={20}
                />
              </Col> */}
            </Row>
          </Dropdown.Item>
        ));
      if (!FilterGroups?.length) return NoFilterGroups;

      return FilterGroups;
    } catch (error) {
      console.warn("Issue with FilterCategory. error: \n", error);
      return NoFilterGroups;
    }
  };
  const FilterCategory = () => {
    const NoFilterCategories = (
      <Dropdown.Item key={999} disabled>
        <Row className="justify-content-between">
          <Col className="col-8 text-start">Finns inga filter</Col>
        </Row>
      </Dropdown.Item>
    );

    try {
      const parsedFilterKeys = parsedFilters.map(Object.keys).flat();

      const FilterCategories = activeFilterCategory?.filterTypes
        ?.filter((filter) => !parsedFilterKeys.includes(filter?.id))
        ?.map((filter) => {
          return (
            <CardContainer
              key={filter.id}
              bg="secondary"
              padding
              children={
                <Row>
                  <Col className="col-10">
                    <Row>
                      <Col
                        className="col-8 text-xl semi-bold"
                        style={{ color: "#64748b" }}
                      >
                        {filter.name}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="text-end add-filter-type"
                    onClick={() => onClickAddFilterType(filter)}
                  >
                    {isLoading && filter.id === selectedFilterType.id ? (
                      <Spinner />
                    ) : (
                      <BsPlusCircle />
                    )}
                  </Col>
                </Row>
              }
            />
          );
        });

      if (!FilterCategories?.length) return NoFilterCategories;

      return FilterCategories;
    } catch (error) {
      console.warn("Issue with FilterCategory. error: \n", error);
      return NoFilterCategories;
    }
  };

  return (
    <Row className="p-0">
      <Col className="col-12 sub-filter-heading-col">
        <h4 className="display-xs semi-bold" style={{ color: "#2A2C2A" }}>
          {activeFilterCategory?.title}
        </h4>
        <DropdownButton
          className="text-lg semi-bold"
          drop="down-start"
          variant="success"
          title="Mina filtergrupper"
        >
          <FilterGroup />
        </DropdownButton>
      </Col>
      <Col
        className="col-12 d-flex flex-column p-5"
        style={{ gap: "20px", overflowY: "auto" }}
      >
        {activeFilterCategory?.filterTypes
          ?.filter((filter) => parsedFilters?.find((f) => f[filter?.id]))
          ?.map((filter) => (
            <CardContainer
              key={filter.id}
              children={
                <Row key={filter.id}>
                  <Col className="col-10">
                    <Row>
                      {filter.type !== 3 ? (
                        <Col
                          className="col-12 mb-2 text-xl semi-bold"
                          style={{ color: "#2A2C2A" }}
                        >
                          {filter.name}
                        </Col>
                      ) : (
                        <>
                          <Col
                            className="col-6 mb-2 text-xl semi-bold"
                            style={{ color: "#2A2C2A" }}
                          >
                            {filter.name}
                          </Col>

                          <Col
                            className="col-6 mb-2 text-md semi-bold d-flex justify-content-end"
                            style={{ color: "#2A2C2A" }}
                          >
                            <Row
                              className="text-center align-items-center"
                              style={{ color: "lightgrey" }}
                            >
                              <Col>Dynamiskt</Col>
                              <Col>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  className="status-switch"
                                  checked={dynamicFilter}
                                  onClick={() =>
                                    onClickDynamicFilter(filter, filter.id)
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </>
                      )}

                      {loading ? (
                        <Spinner />
                      ) : (
                        <FilterInputs
                          filter={filter}
                          segment={segment}
                          values={parsedFilters}
                          onChange={mutate}
                          setDynamicFilter={setDynamicFilter}
                          dynamicFilter={dynamicFilter}
                        />
                      )}
                    </Row>
                  </Col>
                  <Col
                    className="col-1 d-flex align-items-center justify-content-center remove-filtertype"
                    onClick={() => onClickRemoveFilterType(filter)}
                  >
                    <MdOutlineCancel
                      style={{ cursor: "pointer" }}
                      size={30}
                      className="trash-can"
                    />
                  </Col>
                </Row>
              }
              padding
            />
          ))}
        <FilterCategory />
      </Col>
    </Row>
  );
};

export default FilterTypesList;
