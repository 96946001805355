import { Row, Col, Form, Spinner } from "react-bootstrap";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useState } from "react";

const EditObjective = ({ activity, updateActivity, updateActivityLoading }) => {
  const [showInput, setShowInput] = useState(false);
  const [newValue, setNewValue] = useState(
    activity?.objective ? activity.objective : 0
  );

  const updateObjective = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/objective",
        value: newValue,
      },
    ];
    updateActivity(patchObj);
    setShowInput(false);
  };
  return (
    <Row className="row-cols-auto justify-content-around gy-2">
      <Col className="regular text-center " style={{ minWidth: "170px" }}>
        <p>Annonsmål</p>
      </Col>

      {showInput ? (
        <Col className="flex-grow-1">
          <Form.Select
            id="objective"
            aria-label="Default select example"
            defaultValue={newValue}
            onChange={(e) => setNewValue(Number(e.target.value))}
          >
            <option value={0}>Trafik</option>
            <option value={1}>Medvetenhet</option>
            <option value={2}>App-Promotion</option>
            <option value={3}>Engagemang</option>
            <option value={4}>Leads</option>
            <option value={5}>Försäljning</option>
          </Form.Select>
        </Col>
      ) : (
        <Col className="flex-grow-1">
          {updateActivityLoading ? (
            <Spinner />
          ) : (
            <p className="text-center" style={{ color: "#545F61" }}>
              {activity?.objective && activity.objective === 0
                ? "Trafik"
                : activity?.objective && activity.objective === 1
                ? "Medvetenhet"
                : activity?.objective && activity.objective === 2
                ? "App-Promotion"
                : activity?.objective && activity.objective === 3
                ? "Engagemang"
                : activity?.objective && activity.objective === 4
                ? "Leads"
                : activity?.objective && activity.objective === 5
                ? "Försäljning"
                : "Trafik"}
            </p>
          )}
        </Col>
      )}
      {showInput ? (
        <Col className="flex-shrink-1 edit-col-icon" onClick={updateObjective}>
          <FaSave color="green" />
        </Col>
      ) : (
        <Col
          className="flex-shrink-1 edit-col-icon"
          onClick={() => setShowInput(true)}
        >
          <AiFillEdit />
        </Col>
      )}
    </Row>
  );
};

export default EditObjective;
