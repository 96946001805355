import "./style.scss";
import { Button, Row, Col } from "react-bootstrap";
import {MdUploadFile, MdArrowForwardIos } from "react-icons/md";
import { useMediaQuery } from 'react-responsive';

const ButtonLarge = (props) => {

  const ifXsScreen = useMediaQuery({ query: "(max-width: 769px)" });
  const ifMediumScreen = useMediaQuery({ query: "(min-width: 770px) and (max-width: 1150px)" });

  
  return (
    <Button className="button-large w-100" onClick={props.onClick}>
      <Row>
        {props.icon && (
          <Col className={`${ifMediumScreen? "col-12 pb-2" : ifXsScreen ? "col-5 pb-2" : "col-3" } d-flex align-items-center justify-content-center`}>
            {props.icon}
          </Col>
        )}
        <Col>
          <Row>
            <Col className="col-12">
              <h3 className="text-lg bold">{props.title} {props.suffix && <span className="text-xs">{props.suffix}</span>} </h3>
             
            </Col>
            <Col className="col-12">
              <p className="btn-text">
                {props.subTitle
                  ? props.subTitle
                  : "Bygg upp en egen målgrupp med Autotargets filter"}
              </p>
            </Col>
          </Row>
        </Col>
        {props.arrow && (
          <Col className="col-1 d-flex align-items-center">
            <MdArrowForwardIos size={32} color="#808080" />
          </Col>
        )}
        {props.upload && (
          <Col className="col-3 d-flex align-items-center">
            <MdUploadFile size={32} color="#808080" />
          </Col>
        )}
      </Row>
    </Button>
  );
};

ButtonLarge.propTypes = {};

export default ButtonLarge;
