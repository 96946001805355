import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Packages = {
  GET: {
    ALL: (activityID,compatibleWithSegmentID) => {
      const queryClient = useQueryClient();
      const queryParams = [
        activityID && `activityID=${activityID}`,
        compatibleWithSegmentID && `compatibleWithSegmentID=${compatibleWithSegmentID}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: async () => axios.get(`packages/v1${queryString}`).then(({ data }) => data),
        queryKey: ["packages", queryString],
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onError: (error) => {
          console.warn(`Failed to GET packages \n Error: `, error);
        },
      });
    },
  }
}
