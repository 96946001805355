import { Row, Col, Table } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { BarChart } from "components/charts";

const LeadCompanyEconomy = ({ lead }) => {
  const reversedLead = [...lead].reverse();
  console.log({reversedLead})
  const options = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
      },
      xAxes: [{ stacked: true }],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const turnoverData = {
    labels: reversedLead.map((item) => item.date_from.substring(0, 4)), // use date_from property as chart labels
    datasets: [
      {
        label: "Årets resultat (MSEK)",
        data: reversedLead?.map((item) => item.result_after_financial_items), // use turnover property as chart values
        backgroundColor: "#2D6450",
        borderColor: "rgba(54, 162, 235, 0)",
        borderRadius: 10,
        borderWidth: 1,
        barPercentage: 0.4,
        categoryPercentage: 1.0,
        // barThickness: "flex",
        pointStyle: "circle", // set the shape of the markers to circles
        pointRadius: 5, // set the size of the circles to 5 pixels
       
      },
      {
        label: "Omsättning (MSEK)",
        data: reversedLead?.map((item) => item.turnover), // use turnover property as chart values
        backgroundColor: "#B3CCC3",
        borderColor: "rgba(54, 162, 235, 0)",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        // barThickness: "flex",
        pointStyle: "circle", // set the shape of the markers to circles
        pointRadius: 5, // set the size of the circles to 5 pixels
      },
    ],
    
  };

  const marginData = {
    labels: reversedLead.map((item) => item.date_from.substring(0, 4)), // use date_from property as chart labels
    datasets: [
      {
        label: "Vinstmarginal (%)",
        data: reversedLead?.map((item) => item.profit_margin), // use turnover property as chart values
        backgroundColor: "#E7AD67",
        borderColor: "rgba(54, 162, 235, 0)",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.5,
        categoryPercentage: 1.0,
        stack: "stacked",
      },
    ],
  };

  return (
    <Row className="w-100 g-4">
      <Col className="col-6">
        <CardContainer
          padding
          children={
            <Row className="gy-2">
              <Col className="col-12">
                <h2 className="display-sm bold">Omsättning och resultat</h2>
              </Col>
              <Col className="col-12">
                <p className="text-md regular" style={{ color: "#6A6D6A" }}>
                  Alla siffror anges i hela tusental.
                </p>
              </Col>
              <Col className="col-12" style={{ height: "26vh" }}>
                <BarChart data={turnoverData} />
              </Col>
            </Row>
          }
        />
      </Col>
      <Col className="col-6">
        <CardContainer
          padding
          children={
            <Row className="gy-2">
              <Col className="col-12">
                <h2 className="display-sm bold">Vinstmarginal</h2>
              </Col>
              <Col className="col-12">
                <p className="text-md regular" style={{ color: "#6A6D6A" }}>
                  Alla siffror anges i hela tusental.
                </p>
              </Col>
              <Col className="col-12" style={{ height: "26vh" }}>
                <BarChart data={marginData} />
              </Col>
            </Row>
          }
        />
      </Col>
      {/* TABLE */}
      <Col className="col-12">
        <CardContainer
          marginBottom
          children={
            <Row>
              <Col className="col-12 p-5">
                <h2 className="display-sm bold">
                  Fler siffror för Informationsgruppen i Sverige AB
                </h2>
              </Col>
              <Col className="col-12">
                <Table
                  id="segmentTable"
                  className="table-container"
                  responsive
                  hover
                  size="lg"
                >
                  <tr>
                    <th></th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Omsättning
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Resultat efter finansnetto
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Antal anställda
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Aktiekapital
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Kassalikviditet
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Riskbuffert
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Vinstmarginal
                    </th>
                  </tr>

                  <tbody className="text-md regular">
                    {lead.map((item, index) => (
                      <tr key={index}>
                        <td className="bold">{item.date_to}</td>
                        <td>{item.turnover}</td>
                        <td>{item.result_after_financial_items}</td>
                        <td>{item.number_of_employees}</td>
                        <td>{item.share_assets}</td>
                        <td>{item.quick_ratio.toFixed(2)}%</td>
                        <td>{item.risk_margin.toFixed(2)}%</td>
                        <td>{item.profit_margin.toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          }
        />
      </Col>
    </Row>
  );
};

export default LeadCompanyEconomy;