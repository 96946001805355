import { CardContainer } from "components/cards";
import { Container, Row, Col, Image, Spinner, Alert } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import { BiTargetLock } from "react-icons/bi";
import { useQuery } from "react-query";
import { getLeads } from "features/leads/api";
import { useState } from "react";
import { useSelector } from "react-redux";
import { columnsSegments } from "features/campaign/create-campaign/components/steps/data/stepsData";
import CampaignTableSelect from "features/campaign/create-campaign/components/steps/components/CampaignTableSelect";
import "../style.scss";
import { useNavigate } from "react-router-dom";
import LeadsStats from "./LeadsStats";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";

const LeadsOverviewContainer = ({ifXsScreen, ifMdAddCircleOutlineIsSmall,xsValue }) => {

  // const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  // const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  // const ifMdAddCircleOutlineIsSmall = useMediaQuery({query:'(max-width: 350px)'});
  // const xsValue = ifMdAddCircleOutlineIsSmall ? 12 : 9;


  const leadType = useSelector((state) => state.lead.leadType);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoading,
    isError,
    error,
    data: leads,
  } = Agent.Leads.GET.ALL(currentPage, leadType, undefined, undefined);



  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/campaigns");
  };


  return (
    <CardContainer
      h100
      padding
      children={
        
        <Container className="m-0 g-0" fluid>
        <>
          <Row className="mb-4 justify-content-between">
            <Col>
              <h4 className="text-lg semi-bold">Mina Leads</h4>
            </Col>
            <Col className="col-2 d-flex justify-content-end align-items-center">
              <BiTargetLock size={30} color="#ddd" />
            </Col>
          </Row>
          <Row 
          // style={{ height: "25vh" }}         
          >
            {isLoading ? (
              <Col className="justify-content-center text-center">
              <Spinner />
              </Col>
            ) : !isLoading && leads?.Results && leads?.Results?.length > 0 ? (
              <Col className="col-12 h-100">
                <LeadsStats />
              </Col>
            ) : (
              <Container fluid >
              <Row className="h-100">
              <Col className="col-12">
                    <Row className={`justify-content-evenly  mx-auto ${ifXsScreen? 'p-0' : 'h-100 p-3'}`}>
                      <Col md={6} className="overviewInfoBox h-100 ">
                        <Alert variant="warning" className={ifXsScreen ? 'd-flex' : 'h-100 d-flex'}>
                          <Row className="justify-content-center text-center">
                            <Col
                              className={` semi-bold d-flex align-items-center ${ifXsScreen? 'text-sm ' : 'text-lg'}`}
                              style={{ color: "rgb(174 165 138)" }}
                            >
                              Just nu är inga leads genererade <br /> För att leads ska börja genereras behöver en kampanj ha skapats, skapa din första kampanj!
                            </Col>
                          </Row>
                        </Alert>
                      </Col>
                      <Col xs={12} md={6} className="overviewCreateButton" onClick={handleButtonClick}>
                        <Row className="justify-content-evenly mx-auto">
                        {!ifMdAddCircleOutlineIsSmall &&
                          <Col xs={3} md={3} lg={3} className="d-flex align-items-center">
                            <MdAddCircleOutline size={65} />
                          </Col>
                        }
                          <Col xs={xsValue} md={9}>
                            <Row className="g-0 flex-column">
                              <Col>
                                <h4 className={`semi-bold m-0 ${ifXsScreen? 'text-sm ' : ''}`}>
                                  Skapa Leads Kampanj
                                </h4>
                              </Col>
                              <Col>
                                <p className="text-sm ">
                                  Företag, Personer eller egen lista
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
              </Col>
              </Row>
              </Container>
            )}
          </Row>
          </>
         </Container>
      }
    />
  );
};

export default LeadsOverviewContainer;
