import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Accounts = {
  GET: {
    ALL: (page = 1, type, limit = 10, search) => {
      const queryParams = [
        page && `page=${page}`,
        search && `search=${search}`,
        limit && `limit=${limit}`,
        type !== undefined && `type=${type}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios.get(`accounts/v1${queryString}`).then(({ data }) => data),
        queryKey: ["accounts", queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET accounts with query: ${queryString} \n Error: `,
            error
          );
        },
      });
    },
    ID: (id, accountType) => {
      return useQuery({
        queryFn: () =>
          axios
            .get(`accounts/v1/${id}?type=${accountType}`)
            .then(({ data }) => data),
        queryKey: ["account", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET account with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta kunddatan");
        },
      });
    },
  },
  PATCH: {
    ACTIVITY: () => {
      const queryClient = useQueryClient();
      return useMutation(
        (newActivity) =>
          Promise.resolve(
            axios
              .put(`/campaigns/v1/${campaign?.id}`, campaign)
              .then(() => campaign?.id)
          ).catch((e) => Promise.reject({ error: e, id: campaign?.id })),
        {
          onSuccess: (id) => {
            queryClient.invalidateQueries("campaigns");
            queryClient.invalidateQueries(["campaign", id]);
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to PUT campaign ${id} \n Error: `, error);
          },
        }
      );
    },
  },
};
