
import { CampaignOptionsTypes } from "features/campaign/campaign-details/options";
import { useState } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import styles from './style.module.css'

export default function ChannelNavbar({ onClick, defaultValue, options }) {
  const [activeChannel, setActiveChannel] = useState(defaultValue ?? null); // Initialize active channel state

  // Array of channel names
  const channels = options ||
    { Overview: 'Översikt' }
    ; // Add more channels as needed

  // Function to handle channel click
  const handleChannelClick = (channelName) => {
    setActiveChannel(channelName); // Set the clicked channel as active
    if (typeof onClick === "function") onClick(channelName);
  };

  return (
    <Row className="d-flex flex-row justify-content-center p-0 m-0">
      {Object.keys(channels).map((key) => {
        const value = channels[key]

        return (
          <div
            role={"button"}
            key={key}
            onClick={() => handleChannelClick(value)} // Handle click event
            className={`col text-center text-sm semi-bold py-2 navItem ${activeChannel === value ? styles.activeNavItem : ''}`}
            style={{
              borderRight: '1px solid rgb(243 243 243)',
            }}
          >
            <span>{value}</span>
          </div>
        )
      })}
    </Row>
  );
}

