import Agent from "api/Agent";
import { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";
import { DRContext } from "../../DR";

const EditTargetGroup = () => {
      const drContext = useContext(DRContext);
  const campaign = drContext?.campaign ?? undefined;

  const { data: segment } = Agent.Segments.GET.ID(campaign.segmentID);

  return (
    <Row>
      <Col className="col-md-4 regular">
        <p>Målgrupp</p>
      </Col>
      <Col>
        <p style={{ color: "#545F61" }}>
          {campaign?.autoSegment
            ? "Automatisk"
            : !campaign?.autoSegment && segment
            ? segment.name
            : "-"}
        </p>
      </Col>
      <Col className="col-2 edit-col-icon">
        <AiFillLock color="rgb(212 212 212)" />
      </Col>
    </Row>
  );
};

export default EditTargetGroup;
