import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

// Constants for claim names
const ROLE_CLAIM = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const NAME_CLAIM = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
const CUSTOMER_ID_CLAIM = "CustomerID";

const getUserData = (token) => {
  if (!token) return undefined;

  try {
    const decoded = jwt_decode(token);

    if (!decoded ) {
      return console.error("Invalid session")
    }
    
    const role = decoded[ROLE_CLAIM];
    const name = decoded[NAME_CLAIM];
    const customerID = decoded[CUSTOMER_ID_CLAIM];

    return { role, name, customerID };
  } catch (error) {
    console.error("Error decoding token:", error);
    return undefined;
  }
};
const userData = getUserData(Cookies.get("session"));
const initialState = {
  token: Cookies.get("session"),
  role: userData?.role,
  user: userData?.name,
  userID: userData?.customerID,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action) => {
      const { token, expiration } = action.payload;
      const data = getUserData(token);
      if (data) {
        state.token = token;
        state.role = data?.role;
        state.user = data?.name;
        state.userID = data?.customerID;
        Cookies.set("session", token, { secure: true, sameSite: "strict", expires: new Date(expiration) });
      }
    },
    removeSession: (state) => {
      Object.keys(state).forEach((prop) => (state[prop] = undefined));
      Cookies.remove("session");
    },
  },
});
export const { setSession, removeSession } = sessionSlice.actions;
