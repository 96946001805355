
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import axios from "api/axios";



export const FILTERSOURCE = { Merinfo : 0, CosmosDB : 1, Biluppgifter : 2 }
export const FILTERSOURCE_SV = { [ FILTERSOURCE.Merinfo ] : "Merinfo", [ FILTERSOURCE.CosmosDB ] : "CosmosDB", [ FILTERSOURCE.Biluppgifter ] : "Biluppgifter" }

const PATH = "/filterTypes/v1/"
export const FilterTypes = {
  GET: {
    ALL: (page, limit, search, sortbyproperty, desc, filterCategoryId, filter) => {
      const queryparams = [
        page && `page=${page}`,
        limit !== undefined && `limit=${limit}`,
        search && search?.trim().length > 0 && `searchterm=${search}`,
        sortbyproperty && `orderByProperty=${sortbyproperty}`,
        desc && `desc=${true}`,
        filterCategoryId && `filterCategoryId=${filterCategoryId}`,
        filter?.type !== undefined && `type=${filter?.type}`,
        filter?.isLocal !== undefined && `isLocal=${filter?.isLocal}`,
        filter?.isExclude !== undefined && `isExclude=${filter?.isExclude}`,
        filter?.segmentType !== undefined && `segmentType=${filter?.segmentType}`,
      ]?.filter((x) => x);

      const querystring =
        queryparams.length > 0 ? `?${queryparams.join("&")}` : "";

      return useQuery({
        queryFn: async () => {
          const result = await axios.get(`${PATH}${querystring}`);
          return result?.data;
        },
        queryKey: ["filterTypes", querystring, filterCategoryId],
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`failed to get all filterTypes \n error: `, err),
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: async () => {
          const result = await axios.get(`${PATH}${id}`)
          return result?.data;
        },
        enabled : !!id,
        queryKey: ["filterType", id],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(`Failed to GET filter type with ID: ${id} \n Error: `, error);
        },
      });
    },
  },
  POST: {
    FILTER_TYPE:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (filterType) => {
            const result = await axios.post(`${PATH}`, filterType)
            return result?.data;
          },
          {
            onSuccess: () => {
               queryClient.invalidateQueries({queryKey:["filterTypes"]});
              queryClient.invalidateQueries({queryKey:["filterType"]});
            },
            onError: (error) => {
              console.warn(`Failed to POST filterType \n Error: `, error);
              toast(`Det gick inte att skapa filter typ `, {
                type: "error",
              });
            },
          }
        );
      },
  },
  PUT: {
    FILTER_TYPE:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (filterType) => {
            const result = await axios.put(`${PATH}`, filterType)
            return { id: filterType?.id, data: result?.data };
          },
          {
            onSuccess: ({ id }) => {
              queryClient.invalidateQueries({queryKey:[id]});
              queryClient.invalidateQueries({queryKey:["filterTypes"]});
              queryClient.invalidateQueries({queryKey:["filterType"]});
            },
            onError: (error) => {
              console.warn(`Failed to PUT filterType \n Error: `, error);
              toast(`Det gick inte att skapa filter typ `, {
                type: "error",
              });
            },
          }
        );
      },
  },
  DEL: {
    FILTER_TYPE:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (id) => {
            const result = await axios.delete(`${PATH}${id}`)
            return { id: id, data: result?.data };
          },
          {
            onSuccess: ({ id }) => {
              queryClient.invalidateQueries("filterTypes", id);
            },
            onError: (error) => {
              console.warn(`Failed to DEL filterType \n Error: `, error);
              toast(`Det gick inte att skapa filter typ `, {
                type: "error",
              });
            },
          }
        );
      },
  }


};
