import React, { useState } from "react";
import {
  Button,
  Offcanvas,
  Col,
  Row,
  Spinner,
  InputGroup,
  Form,
  Container,
} from "react-bootstrap";
import { CardContainer } from "components/cards";
import styles from "./style.module.scss";
import Agent from "api/Agent";
import { TbCategory } from "react-icons/tb";
import { useMutation, useQueryClient } from "react-query";
import { segmentTypes_sv } from "features/segment/segment-details/assets/config";
import * as icons from "react-icons/md";
import Select from 'react-select';
import { toast } from "react-toastify";

const iconOptions = Object.keys(icons).map(key => ({
  label: <div>{React.createElement(icons[key], {
    style: {
      width: "30px",
      height: "30px",
      marginRight: "10px"
    },
  }
  )}{key}</div>, value: key
}));




const NewFilterCategory = ({ show, setShow }) => {
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false)

  const [iconBg, setIconBg] = useState(undefined)

  const postMutation = Agent.FilterCategories.POST.FILTER_CATEGORY();

  const resetForm = () => {
    const form = document.getElementById('form')
    form?.reset();
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById('form')
    const formData = new FormData(form);
    const formDataArray = Array.from(formData?.entries());

    if (!formDataArray) {
      console.warn("Failed to get formData")
      return false
    }

    const request = {};
    formDataArray.forEach(([key, value]) => {
      if (form.elements[key]?.type?.includes("select")) request[key] = +value;
      else request[key] = value;
    })

    console.log(request)
    return false;
    toast.promise(
      postMutation.mutate(request, {
        onSuccess: () => {
          resetForm();
        }
      }), { success: "Kategori skapad" })

  }


  return (
    <>
      <Button variant="success" onClick={() => handleShow()} >
        <TbCategory size={25} /> Ny Filterkategori
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className={styles.offcanvas}
        placement="end"
      >
        <Offcanvas.Header
          className="flex-row-reverse justify-content-end align-items-baseline py-0 px-3"
          closeButton
        >
          <h2 className="display-sm semibold p-3">Ny Filterkategori</h2>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="px-2">
            <Form onSubmit={(e) => handleSubmit(e)} id="form" className="d-flex flex-column gap-2">
              <Form.Group >
                <Form.Label>Namn *</Form.Label>
                <Form.Control
                  name="name"
                  placeholder="..."
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group >
                <Form.Label>Rubrik (i applikation) *</Form.Label>
                <Form.Control
                  name="title"
                  placeholder="..."
                  type="text"
                  required
                />
              </Form.Group>
              <Form.Group >
                <Form.Label>Beskrivning </Form.Label>
                <Form.Control
                  name="subtitle"
                  placeholder="..."
                  type="text"
                />
              </Form.Group>
              <Form.Group >
                <Form.Label>Typ av målgrupp *</Form.Label>
                <Form.Select className="text-md" name="segmentType" >
                  <option value="">Alla</option>
                  {Object.keys(segmentTypes_sv)?.map(key => <option key={segmentTypes_sv[key]?.text} value={key}>{segmentTypes_sv[key]?.text}</option>)}

                </Form.Select>

              </Form.Group>

              <Form.Group >
                <Form.Label>Icon *</Form.Label>
                <Select
                  required
                  isSearchable
                  isClearable
                  name="icon"
                  placeholder="Välj en icon"
                  options={iconOptions}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      color: iconBg,
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: iconBg,
                    })
                  }}
                />
              </Form.Group>


              <Form.Group >
                <Form.Label>Ikon färg *</Form.Label>
                <Form.Control
                  name="iconBg"
                  type="color"
                  value={iconBg}
                  onChange={e => setIconBg(e.target.value)}
                />
              </Form.Group>

              <Row className="row-cols-auto  p-2">
                <span className="text-muted ">* nödvändiga fält</span>
              </Row>

              <Row className="row-cols-auto justify-content-end p-2">
                <Button variant="success" type="submit"   >
                  {postMutation.isLoading ? <Spinner /> : "Skapa kategori"}
                </Button>
              </Row>
            </Form>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NewFilterCategory;
