
import { useMutation, useQuery } from "react-query";
import axios from "api/axios";
import { useDispatch } from "react-redux";
import { setSession } from "context/sessionSlice";

const PATH = "/authenticate/v1"
export const Authenticate = {
  POST: {
    SignInAsUser: () => {
      const dispatch = useDispatch();
      return useMutation(
        async (userId) => {
          const result = await axios.post(`${PATH}/signInAsUser`, userId)
          return result?.data;
        },
        {
          onSuccess: (data) => {
            try {
              dispatch(setSession(data))

            } catch (error) {
              console.warn("Unable update the session with user token, error:", error)
            }
          },
          onError: (error) => {
            console.warn(`Failed to fetch user token, unable to sign in\n Error: `, error);
          },
        }
      );
    },
     ValidateToken : () => useMutation(
      {
        mutationFn: ({token, email}) => axios.post(`${PATH}/validatePasswordResetToken`, {
        token: token,
        email: email,
      }),
      })

  },


};
