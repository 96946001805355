import { useState, useEffect } from "react";
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import { MdFilterList } from "react-icons/md";

const FilterLeadsButton = ({ filters, setFilters, mainTable }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);

  function countPropertiesWithValues(obj) {
    let count = 0;
    for (let key in obj) {
      if (obj[key]) {
        count++;
      }
    }
    return count;
  }

  useEffect(() => {
    setFiltersCount(countPropertiesWithValues(filters));
  }, [filters]);

  return (
    <>
      <Button
        onClick={() => setShowFilters(!showFilters)}
        variant="light"
        className="filter-button text-md semi-bold position-relative"
      >
        <Row>
          <Col className="pr-1 col-4">
            <MdFilterList size={20} />
          </Col>
          <Col className="text-start">
            Filter
            {filtersCount > 0 && (
              <span className="filter-indicator">{filtersCount}</span>
            )}
          </Col>
        </Row>
      </Button>
      {showFilters && (
        <Container
          className="filter-dropdown"
          style={{ marginTop: "50px", width: mainTable ? "100%" : "250%" }}
        >
          <Row className="g-3 flex">
            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Synkad till CRM</p>
              <Form.Select
                aria-label="Default select example"
                value={filters.syncedToCrm}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    syncedToCrm: e.target.value,
                  })
                }
              >
                <option value={""}>Alla</option>
                <option value={1}>Synkad</option>
                <option value={0}>Ej Synkad</option>
              </Form.Select>
            </Col>
            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Har aktivitet-/er</p>
              <Form.Select
                aria-label="Default select example"
                value={filters.hasActivities}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    hasActivities:
                      e.target.value === "null" ? null : e.target.value,
                  })
                }
              >
                <option value={""}>Alla</option>
                <option value={1}>Har aktiviteter</option>
                <option value={0}>Har ej aktiviteter</option>
              </Form.Select>
            </Col>
            <Col className="col-12">
              <p className="text-md semi-bold mb-2">Score</p>
              <Form.Select
                aria-label="Default select example"
                value={filters.score}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    score: e.target.value === "null" ? null : e.target.value,
                  })
                }
              >
                <option value={null}>Alla</option>
                <option value={0}>Lågt rankade</option>
                <option value={1}>Medel rankade</option>
                <option value={2}>Högt rankade</option>
              </Form.Select>
            </Col>

            <Col className="col-12 d-flex justify-content-end">
              <Button
                variant="link"
                onClick={() =>
                  setFilters({
                    syncedToCrm: "",
                    hasActivities: "",
                    score: "",
                  })
                }
              >
                Rensa
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default FilterLeadsButton;
