import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import styles from "../../../style.module.css";
import { Bar } from "react-chartjs-2";
import StatisticsTotalCardRow from "./StatisticsTotalCardRow";
import { parseChartData } from "../helper";
import CampaignConversions from "./CampaignConversions";
import { MdArrowBack, MdArrowForward } from "react-icons/md";

export default function StatisticsContainer({
  campaign,
  campaignStatistic,
  activity,
}) {
  if (!campaign.statistics) return <></>;

  const statisticsList = [
    [
      {
        label: "budget",
        property: "budget",
      },
      {
        label: "Spenderad budget",
        property: "budgetSpent",
      },
    ],
    [
      {
        label: "Visningar per person",
        property: "frequenzy",
      },
    ],
    [{ label: "Klick", property: "clicks" }],
    [
      { label: "Visningar", property: "exposures" },
      // {
      //   label: "Unika Visningar (per dag)",
      //   property: "reach",
      // },
    ],
  ];

  const charts = parseChartData(statisticsList, campaignStatistic);

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  const stats = campaign.statistics;

  const total_stats_items = [
    {
      label: "Exponeringar",
      value: stats.exposures,
    },
    {
      label: "Unika Visningar",
      value: stats.reach,
    },
    {
      label: "Klick",
      value: stats.clicks,
    },
    {
      label: "CTR",
      value: stats.ctr,
      unit: "%",
    },
    {
      label: "Spenderad Budget",
      value: stats.budgetSpent,
      unit: "kr",
    },
    {
      label: "Värde",
      value: stats.conversionValue,
      unit: "kr",
    },
    {
      label: "Konverteringar",
      value: stats.conversions,
    },
  ];
  const [sliceIndex1, setSliceIndex1] = useState(0);
  const [sliceIndex2, setSliceIndex2] = useState(10);
  const [disablePrevBtn, setDisablePrevBtn] = useState(false);
  const handlePrevClick = () => {
    setSliceIndex1(sliceIndex1 + 10);
    setSliceIndex2(sliceIndex2 + 10);
  };
  const handleNextClick = () => {
    setSliceIndex1(sliceIndex1 - 10);
    setSliceIndex2(sliceIndex2 - 10);
  };
  return (
    <>
      <StatisticsTotalCardRow items={total_stats_items} />
      <Row className=" g-4 py-4">
        <Col className="col-12">
          <Row className="justify-content-between">
            <Col>
              <Button
                onClick={handlePrevClick}
                disabled={disablePrevBtn}
                variant="success"
              >
                <MdArrowBack />
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                onClick={handleNextClick}
                variant="success"
                disabled={sliceIndex1 === 0}
              >
                <MdArrowForward />
              </Button>
            </Col>
          </Row>
        </Col>
        {charts.map((chart, index) => {
          const { label, chartData } = chart;
          const arrayLength = chartData.labels.length;

          const getModifiedChartData = () => {
            const labels = chartData.labels.slice(
              arrayLength - sliceIndex2,
              arrayLength - sliceIndex1
            );
            const datasets = chartData.datasets.map((dataset) => ({
              ...dataset,
              data: dataset.data.slice(
                arrayLength - sliceIndex2,
                arrayLength - sliceIndex1
              ),
            }));

            return { ...chartData, labels, datasets };
          };

          const modifiedChartData = getModifiedChartData();

          return (
            chartData !== undefined && (
              <Col className="col-6" key={index}>
                <Card
                  className="card-container p-2 h-100"
                  style={{ minHeight: "200px" }}
                  bg="light"
                >
                  <Container className="m-0 g-0" fluid>
                    <div className={styles.cardRow}>
                      <Col>
                        <h4 className="text-lg semi-bold text-capitalize">
                          {label}
                        </h4>
                      </Col>
                    </div>
                    <div className={styles.cardRow}>
                      {modifiedChartData.labels.length <= 0 ? (
                        <Col>
                          <p style={{color: "#ffab40ff"}} className="text-sm">
                            Ingen data hittad för dessa datum. Klicka på pilen till höger för att gå framåt!
                          </p>
                        </Col>
                      ) : (
                        <Bar data={modifiedChartData} options={options} />
                      )}
                    </div>
                  </Container>
                </Card>
              </Col>
            )
          );
        })}
      </Row>
      {activity && (
        <Row>
          <CampaignConversions activity={activity} />
        </Row>
      )}
    </>
  );
}
