import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import EditSMSSchedule from "../components/EditSMSSchedule";
import MobileSMSPreview from "../components/MobileSMSPreview";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";

const SMSStep2 = React.forwardRef(
  ({ text, currentActivity, campaign }, ref) => {
    const [formData, setFormData] = useState(null);

    const handleChange = () => {
      if (ref?.current) {
        const form = ref.current;

        const data = new FormData(form);
        const responseBody = {};
        for (let [key, value] of data.entries()) {
          responseBody[key] = value;
        }
        setFormData(responseBody);
      }
    };
    return (
      <Row className="justify-content-between">
        <Col className="col-5">
          <Row>
            <Col className="col-10">
              <h4 className="display-sm semi-bold">Schemalägg smskampanj</h4>
            </Col>
            <Col className="col-2 d-flex align-items-center justify-content-end">
              {statusBadgeSetter(currentActivity)}
            </Col>
            <Col>
              <EditSMSSchedule
                ref={ref}
                onChange={handleChange}
                activity={currentActivity}
                campaign={campaign}
              />
            </Col>
          </Row>
        </Col>
        <Col className="offset-1 col-6">
          <MobileSMSPreview text={text} formData={formData} />
        </Col>
      </Row>
    );
  }
);

export default SMSStep2;
