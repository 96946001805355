import Agent from "api/Agent";
import { SegmentTable } from "features/segment";
import { Col, Row, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import CampaignSegments from "./features/CampaignSegments";

const SegmentInfo = ({ campaign }) => {
  const ifSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const paddingValue = ifSmallScreen ? "40px 10px" : "40px 50px";

  const segmentQuery = Agent.Segments.GET.ID(campaign?.segmentID);

  if (!campaign.segmentID) {
    return (
      <div style={{ padding: paddingValue }}>
        <Row className="g-3">
          <Col className="col-12">
            <p className="p-0 text-muted semi-bold form-label">
              Det finns ingen målgrupp till denna kampanj
            </p>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Stack gap={1} style={{ padding: paddingValue }}>
      <Row className="gy-2 gap-2 m-0 row-cols-1">
        <div className="d-flex flex-row row-cols-auto justify-content-between align-items-baseline">
          <span className="semi-bold">Målgrupp</span>
        </div>
        <SegmentTable
          data={[segmentQuery.data]}
          tableSize={5}
          rows={5}
          inCampaignDetails
        />
      </Row>
      {/* Disable until critical update to campaignSegment is made */}
      {/* <CampaignSegments campaign={campaign} /> */}

    </Stack>
  );
};

export default SegmentInfo;
