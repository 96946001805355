import axios from "api/axios";
import { endPoints } from "api/endPoints";

const CUSTOMER_URL = endPoints.customers.CUSTOMER_URL;

export const getCustomers = async () => {
  const response = await axios.get(CUSTOMER_URL);
  return response.data;
};

export const getCustomerById = async (id) => {
  return await axios.get(`${CUSTOMER_URL}/${id}`);
};

export const addCustomer = async (Customer) => {
  return await axios.post(CUSTOMER_URL, Customer);
};

export const deleteCustomer = async ({ id }) => {
  return await axios.delete(`${CUSTOMER_URL}/${id}`);
};

export const customerObject = (name) => {
  return {
    name: name,
  };
};
