import {
  MdCampaign,
  MdGroups,
  MdOutlineAdUnits,
  MdHistory,
  MdEditNote,
  MdOutlinePinDrop,
  MdOutlineSettings,
  MdOutlineContactPhone,
  MdMailOutline,
  MdOutlineSms,
} from "react-icons/md";
import { FaBalanceScale } from "react-icons/fa";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin, SiTiktok } from "react-icons/si";
import { BiTargetLock } from "react-icons/bi";
import { v4 as uuidv4 } from "uuid";
export const campaignSteps = [
  {
    id: uuidv4(),
    name: "Typ av kampanj & målsättning",
    subText: "Vilken kampanj vill du skapa och vilka mål har du?",
    url: `/campaigns/create-campaign/2dbb7f1f-1a7e-48c9-1d8a-08db268a0760/type-of-campaign`,
    icon: <MdCampaign size={30} />,
    link: "type-of-campaign",
    iconBg: "#96C97E",
    status: 1,
  },
  {
    id: uuidv4(),
    name: "Målgrupp",
    subText: "Vem ska kampanjen rikta sig mot?",
    url: `/campaigns/create-campaign/2dbb7f1f-1a7e-48c9-1d8a-08db268a0760/target-group`,
    icon: <MdGroups size={30} />,
    link: "target-group",
    iconBg: "#C9B47E",
    status: 0,
  },
  {
    id: uuidv4(),
    name: "Prioritering",
    subText: "Vilka delar av målgruppen är högst prioriterade?",
    icon: <FaBalanceScale size={30} />,
    url: `/campaigns/create-campaign/2dbb7f1f-1a7e-48c9-1d8a-08db268a0760/prioritize`,
    link: "prioritize",
    iconBg: "#C9877E",
    status: 0,
  },
  {
    id: uuidv4(),
    name: "Annonskanal",
    subText: "Digitala kampanjer, telemarketing eller DR",
    icon: <MdOutlineAdUnits size={30} />,
    url: `/campaigns/create-campaign/2dbb7f1f-1a7e-48c9-1d8a-08db268a0760/ad-channel`,
    link: "ad-channel",
    iconBg: "#C97EB8",
    status: 0,
  },
  {
    id: uuidv4(),
    name: "Budget & Schema",
    subText: "Ställ in budget och ",
    icon: <MdHistory size={30} />,
    url: `/campaigns/create-campaign/2dbb7f1f-1a7e-48c9-1d8a-08db268a0760/budget-schedule`,
    link: "budget-schedule",
    iconBg: "#9B7EC9",
    status: 0,
  },
  // {
  //   id: uuidv4(),
  //   name: "Annonsinnehåll",
  //   subText: "Ange text- och bildmaterial till din kampanj",
  //   icon: <MdEditNote size={30} />,
  //   url: `/campaigns/create-campaign/2dbb7f1f-1a7e-48c9-1d8a-08db268a0760/ad-content`,
  //   link: "ad-content",
  //   iconBg: "#7E9CC9",
  //   status: 0,
  // },
];

//?Step 1: Type of Campaign-----------------------------------------------------------------------------
//?----------------------------------------------------------------------------------------

//Choose Type of Campaign (Offer/Branding)
export const options = [
  {
    id: 1,
    name: "Offer",
    value: 0,
    title: "Kampanj för generell branding",
    // icon: <MdSportsHockey size={48} />,
    subText: "Skapa en generell kampanj",
  },
  {
    id: 2,
    name: "Branding",
    value: 1,
    title: "Skapa kampanj för ett specifikt erbjudande",
    // icon: <IoDiamondOutline size={48} />,
    subText: "Nå en bred målgrupp och öka medvetenheten",
  },
];

//Table "Convert Goals"
export const columnsGoals = [
  { id: 1, name: "Offer", label: "Händelse", value: 0 },
  { id: 2, name: "Description", label: "Målet med kampanjen är:", value: 0 },
  { id: 3, name: "GoalCount", label: "Mål antal", value: 0 },
  // { id: 2, name: "Branding", label: "Källa", value: 1 },
];

export const dataGoals = {
  name: "dataGoals",
  rows: [
    {
      id: uuidv4(),
      value: 0,
      label: "Nya kunder",
      name: "NewCustomers",
      desc: "...att hitta ett visst antal nya kunder.",
    },
    {
      id: uuidv4(),
      value: 1,
      label: "Antal köp",
      name: "Purchases",
      desc: "...att nå ett visst antal köp.",
    },
    {
      id: uuidv4(),
      value: 2,
      label: "Interaktioner",
      name: "Interactions",
      desc: "...att synas och få så många interaktioner som möjligt.",
    },
  ],
};
//Table "Upcoming games"
export const columnsGames = [
  { id: 1, label: "Erbjudande" },
  { id: 2, label: "Plats" },
  { id: 3, label: "Start" },
];

export const dataGames = {
  name: "dataGames",
  rows: [
    {
      id: "E21ED8A5-AE3B-4E42-A151-3434EEFCE938",
      event: "Frölunda - Rögle",
      ctr: "2,5%",
      ticketsSold: 500,
    },
    {
      id: uuidv4(),
      event: "Frölunda - Luleå",
      ctr: "2,1%",
      ticketsSold: 200,
    },
    {
      id: uuidv4(),
      event: "Frölunda - Brynäs",
      ctr: "1,6%",
      ticketsSold: 50,
    },
  ],
};

//?Step 2: Target Group-----------------------------------------------------------------------------
//?----------------------------------------------------------------------------------------

export const targetOptions = [
  {
    id: 1,
    title: "Automatisk målgrupp",
    name: "",
    value: true,
    icon: <MdOutlinePinDrop size={48} />,
    subText:
      "Autotarget prioriterar din målgrupp med hjälp av maskininlärning utifrån resultat på tidigare kampanjer",
  },
  {
    id: 2,
    title: "Välj ett av dina definierade målgruppssegment",
    value: false,
    name: "",
    icon: <MdGroups size={48} />,
    subText: "Ställ in din egen prioritering",
  },
];

//Table "Upcoming games"
export const columnsSegments = [
  { id: 1, label: "Målgrupp" },
  { id: 2, label: "Status" },
  { id: 3, label: "Typ" },
  { id: 4, label: "Räckvidd" },
  { id: 5, label: "Används i" },
  // { id: 6, label: "Email" },
];

export const columnsSegmentsSm = [
  { id: 1, label: "Målgrupp" },
  { id: 2, label: "Status" },
];

export const dataSegments = {
  name: "dataSegments",
  rows: [
    {
      id: 1,
      segment: "Hockeyintresserade",
      reach: "2,5%",
      clicks: "2,5%",
      convertions: "1,5%",
      inCampaign: "",
    },
    {
      id: 2,
      segment: "Tidigare besökare",
      reach: "2,5%",
      clicks: "2,5%",
      convertions: "1,5%",
      inCampaign: "",
    },
    {
      id: 3,
      segment: "Har säsongskort",
      reach: "2,5%",
      clicks: "2,5%",
      convertions: "1,5%",
      inCampaign: "",
    },
    {
      id: 4,
      segment: "Tidigare besökare med företagsengagemang",
      reach: "2,5%",
      clicks: "2,5%",
      convertions: "1,5%",
      inCampaign: "",
    },
    {
      id: 5,
      segment: "Göteborg med omnejd",
      reach: "2,5%",
      clicks: "2,5%",
      convertions: "1,5%",
      inCampaign: "",
    },
  ],
};

//?Step 3: Prioritize-----------------------------------------------------------------------------
//?----------------------------------------------------------------------------------------

export const optionsPrioritize = [
  {
    id: 1,
    title: "Automatisk prioritering",
    value: true,
    name: "",
    icon: <MdOutlinePinDrop size={48} />,
    subText:
      "Autotarget prioriterar din målgrupp med hjälp av maskininlärning utifrån resultat på tidigare kampanjer",
  },
  {
    id: 2,
    title: "Definiera egen prioritering",
    inactive: false,
    value: false,
    name: "",
    icon: <MdOutlineSettings size={48} />,
    subText: "Ställ in din egen prioritering",
  },
];

export const scoringCategories = [
  {
    id: 1,
    label: "Geografi",
    subText: "närhet till arenan",
  },
  {
    id: 2,
    label: "Ekonomi",
    subText: "hushållets inkomst",
  },
  {
    id: 1,
    label: "Matchbesök",
    subText: "antal tidigare besök",
  },
];

//?Step 4: Ad Channels-----------------------------------------------------------------------------
//?----------------------------------------------------------------------------------------

export const optionsSocialAd = [
  {
    id: 1,
    title: "Google Ads-kampanj",
    name: "",
    icon: <SiGoogleads size={48} color="#216FE4" />,
    subText: "Nå din målgrupp via annonser i Googles annonsnätverk",
  },
  {
    id: 2,
    title: "Meta-kampanj",
    name: "",
    icon: <TbBrandMeta size={48} color="#216FE4" />,
    subText: "Nå din målgrupp via annonser på Facebook, Instagram, Messenger",
  },
  {
    id: 3,
    title: "LinkedIn-kampanj",
    name: "",
    icon: <SiLinkedin size={48} color="#0A66C2" />,
    subText: "Nå din målgrupp via annonser på LinkedIn",
  },
  {
    id: 4,
    title: "TikTok-kampanj",
    name: "",
    icon: <SiTiktok size={48} color="#000" />,
    subText: "Nå din målgrupp via annonser på Tiktok",
  },
];

export const optionsDirectAd = [
  {
    id: 1,
    title: "SMS-kampanj",
    name: "",
    icon: <MdOutlineSms size={48} color="#EC5F52" />,
    subText: "Skapa en kampanj där du når din målgrupp via sms",
  },
  {
    id: 2,
    title: "DR-kampanj",
    name: "",
    icon: <MdMailOutline size={48} color="#E7AD67" />,
    subText: "Skapa en postal kampanj där du når din målgrupp med DR",
  },
  {
    id: 3,
    title: "E-postkampanj",
    name: "",
    icon: <MdMailOutline size={48} color="#B3CCC3" />,
    subText: "Skapa en kampanj där du når din målgrupp via e-post",
  },
  {
    id: 4,
    title: "Generera Leads",
    name: "",
    icon: <BiTargetLock size={48} color="#7A1B12" />,
    subText: "Leads skapas utifrån kampanjens inställningar och målgrupp",
  },
];
