import { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { MdCheckCircle, MdImage } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAvailableImages,
  uploadNewAdImage,
} from "features/campaign/campaign-details/api";
import DragAndDropFile from "features/campaign/campaign-details/components/DragAndDropFile/DragAndDropFile";
import PaginationBar from "components/pagination/PaginationBar";

const PAGING_LENGTH = 6;

const UPLOAD_STATES = {
  NEUTRAL: "Ladda upp",
  SUCCESS: "Uppladdad",
  ERROR: "Error",
};

const QUERYKEY = "linkedin/availableImages";

export default function ImagePickerModal({
  activityId,
  setSelectedImage,
  selectedImage,
  linkedinAdObject,
  setLinkedinAdObject,
  imageHash,
}) {
  const [show, setShow] = useState(false);
  const [userUpload, setUserUpload] = useState([]);
  const [image, setImage] = useState([]);
  const [imgFormData, setImgFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [showInfoText, setShowInfoText] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!linkedinAdObject.metaPageId) {
      setShowInfoText(true);
      setShow(false);
    } else {
      setImage(selectedImage);
      setShow(true);
      setShowInfoText(false);
    }
  };

  const [page, setPage] = useState(1);
  const [uploadState, setUploadState] = useState(UPLOAD_STATES.NEUTRAL);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: QUERYKEY,
    queryFn: () => getAvailableImages(activityId, linkedinAdObject.metaPageId),
    staleTime: 60 * 1000 * 5,
    retry: false,
    onError: (err) =>
      console.warn(`Failed to get all campaigns \n Error: `, err),
  });

  useEffect(() => {
    refetch();
  }, [linkedinAdObject.metaPageId]);

  const uploadNewImage = useMutation(
    (file) => uploadNewAdImage(activityId, file, linkedinAdObject.metaPageId),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(QUERYKEY);
        setLoading(false);
        console.log({ res });
      },
      onMutate: () => {
        setLoading(true);
      },
    }
  );

  useEffect(() => {
    const templateImage = data?.find((img) => img?.hash?.includes(imageHash));
    if (templateImage !== -1) setSelectedImage(templateImage);
  }, [imageHash]);

  const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    uploadNewImage.mutate(file);
    console.log("HERE!!!")
  };

  const handleImageSelect = (img) => {
    const isAlreadySelected = image?.id === img?.id;
    if (isAlreadySelected) return setImage(null);
    setImage(img);
    setLinkedinAdObject({ ...linkedinAdObject, imageId: img.id });
  };

  const handleSubmit = () => {
    setSelectedImage(image);
    handleClose();
  };

  console.log({ selectedImage });

  return (
    <Col className="mx-auto mb-2">
      <div
        className="d-flex justify-content-center h-100 position-relative"
        onClick={handleShow}
      >
        <Image
          rounded
          src={
            selectedImage
              ? selectedImage?.url
              : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          }
          style={{
            width: selectedImage ? "300px" : "100%",
            height: selectedImage ? "auto" : "150px",
            backgroundColor: !selectedImage && "#eeecec",
            border: !selectedImage && "4px dotted lightgray",
          }}
        />
        {!selectedImage && (
          <span className="semi-bold image-picker-container">
            <MdImage size={50} /> <br /> <p className="text-lg">Välj en bild</p>
          </span>
        )}
      </div>

      {linkedinAdObject.metaPageId && linkedinAdObject.metaPageId !== 0 ? (
        <Modal show={show} onHide={handleClose} centered size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Bild galleri</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-baseline flex-column gap-3">
            <Row className="w-100">
              <Col className="col-12">
                <h3 className=" text-lg semi-bold me-auto">Lägg till bilder</h3>
              </Col>
              <Col className="col-12">
                <DragAndDropFile
                  loading={loading}
                  handleImageUpload={handleImageUpload}
                  setFiles={setUserUpload}
                  files={userUpload}
                />
              </Col>
            </Row>

            <Row className="m-0 p-0">
              <h3 className=" text-lg semi-bold me-auto">Uppladdade bilder</h3>
            </Row>

            {isLoading && <span>Hämtar och laddar in bilder...</span>}
            {error && <span>Ett fel uppstod vid hämtning av bilder.</span>}
            <Row className="w-100 row-cols-3">
              {data &&
                data
                  .sort((a, b) => {
                    if (a.id === selectedImage?.id) {
                      return -1;
                    } else if (b.id === selectedImage?.id) {
                      return 1;
                    } else {
                      return 0;
                    }
                  })
                  .slice(
                    page * PAGING_LENGTH - PAGING_LENGTH,
                    page * PAGING_LENGTH
                  )
                  .map((img, index) => (
                    <div
                      key={index}
                      className="mw-100 p-2 overflow-hidden position-relative"
                      onClick={() => handleImageSelect(img)}
                    >
                      <Image
                        rounded
                        style={{
                          display: "block",
                          maxWidth: "100%",
                          width: "auto",
                          height: "auto",
                          maxHeight: "300px",
                          opacity: image?.id === img.id ? "0.5" : "1",
                          userSelect: "none",
                        }}
                        className="mx-auto"
                        src={img.url}
                        alt=""
                      />
                      {image?.id === img?.id && (
                        <MdCheckCircle
                          style={{
                            position: "absolute",
                            fontSize: "6rem",
                            bottom: "calc(40%)",
                            zIndex: "10",
                            width: "100%",
                            userSelect: "none",
                          }}
                        />
                      )}
                    </div>
                  ))}
            </Row>
            <PaginationBar
              currentPage={page}
              setCurrentPage={setPage}
              countPerPage={PAGING_LENGTH}
              totalCount={data?.length}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Stäng
            </Button>
            <Button
              type="submit"
              variant="success"
              onClick={() => {
                handleSubmit();
              }}
            >
              Välj
            </Button>
          </Modal.Footer>{" "}
        </Modal>
      ) : !linkedinAdObject.metaPageId && showInfoText ? (
        <p style={{ color: "red" }}>
          Välj en LinkedIn-sida innan du väljer bild.
        </p>
      ) : (
        ""
      )}
    </Col>
  );
}
