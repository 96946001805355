import React from 'react'
import { Badge } from 'react-bootstrap'

export default function BadgeState({state}) {
  var states = {
    0: (
      <Badge pill bg="warning">
        Schemalagd
      </Badge>
    ), 1: (
      <Badge pill bg="success">
        Aktiv
      </Badge>
    ), 2: (
      <Badge pill bg="info">
        Paus
      </Badge>
    ), default: (
      <Badge pill bg="danger">
        Avslutad
      </Badge>
    )
  }

  return (states[state] ?? states.default)
}
