import React from "react";
import { useState } from "react";

import { CardContainer } from "components/cards";
import { MdSearch } from "react-icons/md";
import { Table, Row, Col, Spinner, Button } from "react-bootstrap";
import { TextInput } from "components/forms";
import PaginationBar from "components/pagination/PaginationBar";

import Agent from "api/Agent";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function Accounts({ id }) {
  const navigate = useNavigate();

  const columns = [
    { label: "Namn", id: 1 },
    { label: "Email", id: 2 },
    { label: "Skapad", id: 3 },
    { label: "Senaste inloggning", id: 4 },
  ];

  const { isLoading, isError, error, data } = Agent.Users.GET.ALL(id);

  const signInAsUserMutation = Agent.Authenticate.POST.SignInAsUser();

  const queryClient = useQueryClient();
  const handleSignInClick = async (user) => {
    await toast.promise(signInAsUserMutation.mutateAsync(user?.id),
      {
        success: `Loggat in användare [${user?.email}]`,
        pending: "...",
        error: `Kunde inte logga in användare [${user?.email}]`
      }
    )
    queryClient.resetQueries();
    queryClient.clear();
    navigate("/");

  }
  //Table search and pagination
  const [search, setSearch] = useState("");
  const rowsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredData = data?.filter((item) => {
    if (
      search &&
      !item.firstName.toLowerCase().includes(search.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <p>{error.message}</p>;
  }

  return (
    <Col className="col-12">
      <Col className="col-12, mt-4">
        <Row className="justify-content-end">
          <Col className="col-md-5">
            <TextInput
              type="search"
              placeholder="Sök"
              icon={<MdSearch />}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col className="col-12">
        <CardContainer
          style={{ padding: "0" }}
          children={
            <>
              <Row className="flex-column">
                <Table className="table-container" responsive hover size="lg">
                  <thead className="text-sm semi-bold">
                    <tr style={{ textAlign: "center" }}>
                      {columns.map((col) => (
                        <th key={col.id}>{col.label}</th>
                      ))}
                    </tr>
                  </thead>
                  {filteredData && filteredData.length !== 0 && (
                    <tbody className="text-md regular">
                      {filteredData.map(user => (
                        <tr key={user.id}>
                          <td>{user?.firstName}</td>
                          <td style={{ textAlign: "center" }}>{user?.email}</td>
                          <td style={{ textAlign: "center" }}>
                            {user.createdOn.substring(0, 10)}
                          </td>
                          <td style={{ textAlign: "center" }}>{user?.lastLoginDate ? moment(user?.lastLoginDate).format('yyyy-MM-DD HH:mm') : "-"}</td>
                          <td><Button variant="success" onClick={() => handleSignInClick(user)}>Sign in as user</Button></td>
                        </tr>
                      ))}{" "}
                    </tbody>
                  )}
                </Table>
                {filteredData && filteredData.length === 0 && (
                  <Col className="d-flex justify-content-center align-items-center p-3">
                    <h5 style={{ color: "#ddd" }}>Inga kunder hittade. Ladda upp en kundlista!</h5>
                  </Col>
                )}
                {filteredData && filteredData?.length > 0 && (
                  <PaginationBar
                    currentPage={currentPage}
                    setCurrentPage={handlePageChange}
                    countPerPage={rowsPerPage}
                    totalCount={filteredData?.length}
                  />
                )}
              </Row>
            </>
          }
        />
      </Col>
    </Col>
  );
}
