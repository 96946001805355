import React, { useState } from "react";

import { Container, Col, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useMutation, useQueryClient } from "react-query";
import { addCustomer, customerObject } from "../../api";
import {
  addCustomerSetting,
  customerSettingObject,
} from "features/settings/api";

import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineUserAdd, AiOutlineFileImage } from "react-icons/ai";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import { getCustomerById } from "../../api";
import { id } from "react-date-range/dist/locale";
import styles from "./style.module.scss";

const NewCustomerForm = (props) => {
  const queryClient = new useQueryClient();

  //Offcanvas states-------------------
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [customerId, setCustomerId] = useState(null);

  const [name, setName] = useState("");

  const [loadingCreate, setLoadingCreate] = useState(false);

  //Create customer
  const addCustomerMutation = useMutation(addCustomer, {
    onMutate: () => {
      setLoadingCreate(true);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries("customers");
      console.log({ response });
      // setStep((prevStep) => prevStep + 1);

      setCustomerId(response.data.id); //get id, store with useState

      setLoadingCreate(false);
      setShow(false);
    },
    onError: () => {
      setLoadingCreate(false);
    },
    onSettled: () => {
      setLoadingCreate(false);
    },
  });

  // add new customer button
  const onClickSubmit = (e) => {
    e.preventDefault();
    addCustomerMutation.mutate(customerObject(name));
    setName("");
  };

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <AiOutlineUserAdd size={25} /> Skapa konto
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={styles.offcanvas}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Lägg till konto</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <form>
              <Col>
                <label className={styles.label} htmlFor="name">Kundnamn</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    id="name"
                    placeholder="Namn på kund"
                    aria-label="name"
                    type="text"
                    aria-describedby="basic-addon1"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Button type="button" variant="success" onClick={onClickSubmit}>
                {loadingCreate ? <Spinner /> : "Skapa konto"}
              </Button>
            </form>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NewCustomerForm;
