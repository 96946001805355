import { useState, useEffect } from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { TbArrowNarrowRight } from "react-icons/tb";

const FilterDatePickerDynamic = ({
  filter,
  segment,
  parsedFilters,
  mutate,
  setDynamicFilter,
}) => {
  //Set default values for AsyncSelect based on segment.filters--------------
  const getDefaultValues = (filters, filterId) => {
    const foundfilter = filters && filters?.find((f) => f[filterId]);

    if (foundfilter) {
      const values = foundfilter[filterId];
      console.log({ values });
      if (values[0] && values[0].includes("latest")) {
        setDynamicFilter(true);
      } else if(values[0] && !values[0].includes("latest")) {
        setDynamicFilter(false);
      }
      return values;
    }
    return filter;
  };

  const defaultValues = getDefaultValues(
    segment && JSON.parse(segment?.filters),
    filter.id
  );

  const [dynamicSpan, setDynamicSpan] = useState(
    defaultValues ? defaultValues[0] : ""
  );
  const [dynamicSpanValue, setDynamicSpanValue] = useState(
    defaultValues[1] ? defaultValues[1] : "7"
  );

  const handleChangeDynamicSpan = (value) => {
    updateFilter({ newDynamicSpan: value.toString() });
    setDynamicSpan(value.toString());
  };
  const handleChangeDynamicSpanValue = (value) => {
    updateFilter({ newDynamicSpanValue: value.toString() });
    setDynamicSpanValue(value.toString());
  };

  const updateFilter = ({ newDynamicSpan, newDynamicSpanValue }) => {
    const _dynamicSpan = newDynamicSpan ?? dynamicSpan;
    const _dynamicSpanValue = newDynamicSpanValue ?? dynamicSpanValue;
    const filterIndex =
      segment && JSON.parse(segment?.filters).findIndex((f) => f[filter.id]);

    if (_dynamicSpan || _dynamicSpanValue) {
      if (filterIndex === -1) {
        // create a new filter with the selected value
        const updatedSegment = {
          ...segment,
          filters:
            parsedFilters &&
            JSON.stringify([
              ...parsedFilters,
              { [filter.id]: [_dynamicSpan, _dynamicSpanValue] },
            ]),
        };
        mutate(updatedSegment);
      } else {
        // update the existing filter with the selected value
        const updatedFilter = {
          [filter.id]: [_dynamicSpan, _dynamicSpanValue],
        };
        const newFilters = parsedFilters && [...parsedFilters];
        newFilters[filterIndex] = updatedFilter;
        mutate({ ...segment, filters: JSON.stringify(newFilters) });
      }
    } else {
      return;
    }
  };

  return (
    <Form.Group controlId="exampleForm.ControlInput1">
      <Row className="mt-2">
        <Col className="col-8">
          <InputGroup>
            <Form.Select
              onChange={(e) => handleChangeDynamicSpan(e.target.value)}
              className="campaign-input"
              defaultValue={dynamicSpan}
              style={{ wordSpacing: "0px" }}
            >
              <option value="">Välj span</option>
              <option value="latest_days">X antal dagar</option>
              <option value="latest_weeks">X antal veckor</option>
              <option value="latest_months">X antal månader</option>
            </Form.Select>
          </InputGroup>
        </Col>
        <Col className="col-1 d-flex align-items-end justify-content-end p-0">
          <p className="semi-bold" style={{ color: "rgb(174 174 174)" }}>
            Antal:
          </p>
        </Col>
        <Col className="col-3">
          <InputGroup>
            <Form.Control
              onChange={(e) => handleChangeDynamicSpanValue(e.target.value)}
              className="campaign-input"
              defaultValue={dynamicSpanValue}
              type="text"
            />
          </InputGroup>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default FilterDatePickerDynamic;
