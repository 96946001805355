import SMSStepper from "./wizard/SMSStepper";
import SMSStep1 from "./wizard/SMSStep1";
import SMSStep2 from "./wizard/SMSStep2";
import SMSStep3 from "./wizard/SMSStep3";
import { Container } from "react-bootstrap";
import { useState, useRef } from "react";
import axios from "api/axios";
import { useMutation, useQueryClient } from "react-query";
import { endPoints } from "api/endPoints";

const SMS = ({ campaign }) => {
  const currentActivity = campaign.campaignActivities
    ?.filter((activity) => activity.type === 3)
    .find((x) => x);
  const [activeStep, setActiveStep] = useState(0);
  const [text, setText] = useState(
    currentActivity?.ad?.primaryText ? currentActivity.ad.primaryText : ""
  );
  const [senderName, setSenderName] = useState("");
  const [formValidation, setFormValidation] = useState(false);
  const formRef = useRef(null);

  //SAVE ACTIVITY PUT REQUEST
  const queryClient = useQueryClient();

  const { mutate: saveActivity } = useMutation({
    mutationFn: async (modifiedActivity) => {
      // setFormValidation(true);

      const cachedCampaign = JSON.stringify(currentActivity);

      if (JSON.stringify(modifiedActivity) === cachedCampaign)
        return Promise.resolve(
          console.warn("No change found in sms settings, nothing updated")
        );

      return axios.put(
        `${endPoints.campaigns.CAMPAIGNS_URL}/${campaign.id}/activity/${currentActivity.id}`,
        modifiedActivity
      );
    },
    onSuccess: () => {
      queryClient.refetchQueries(["campaign", campaign?.id]);
      setActiveStep((prev) => prev + 1);
      // setFormValidation(false);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  //WIZARD STEPS
  const steps = [
    {
      step: 0,
      children: (
          <SMSStep1
            text={text}
            setText={setText}
            senderName={senderName}
            setSenderName={setSenderName}
            activity={currentActivity}
            campaign={campaign}
          />
      ),
    },

    {
      step: 1,
      children: (
          <SMSStep2
            text={text}
            ref={formRef}
            currentActivity={currentActivity}
            campaign={campaign}
          />
      ),
    },
    {
      step: 2,
      children: (
          <SMSStep3 text={text} currentActivity={currentActivity} />
      ),
    },
  ];

  const step = steps.find(step => step?.step === activeStep)?.children;

  return (
    <div className="position-relative overflow-hidden h-100" style={{ display:"grid", gridTemplateRows:"auto-track-list",  }}>
    <Container style={{ padding: "40px 70px 100px 70px", overflow: "auto" }}>
      {step && step}
    </Container>
    <SMSStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        saveActivity={saveActivity}
        currentActivity={currentActivity}
        text={text}
      />
  </div>
  );
};


export default SMS;
