import "./style.scss";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { IoMdTrash } from "react-icons/io";

const FilterItem = (props) => {
  const [filterTypesLength, setFilterTypesLength] = useState(0);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  const segmentFilters = props.filters && JSON.parse(props.filters);
  const filterTypes = props.filterTypes;

  useEffect(() => {
    const matchingFilterTypes = filterTypes?.filter((filterType) =>
      segmentFilters?.some(
        (segmentFilter) => Object.keys(segmentFilter)[0] === filterType.id
      )
    );
    if (matchingFilterTypes) {
      // const matchingFilterType = matchingFilterTypes[0];
      // const matchingFilterTypeLength = segmentFilters.filter(
      //   (segmentFilter) =>
      //     Object.keys(segmentFilter)[0] === matchingFilterType.id
      // )[0][matchingFilterType.id].length;

      setFilterTypesLength(matchingFilterTypes.length);
      setSelectedFilterTypes([matchingFilterTypes.map((item) => item.name)]);
    }
  }, [props.filters, filterTypes]);

  return (
    <Container
      onClick={props.onClick}
      category={props.category}
      className={
        props.selected && !props.active
          ? "filter-item-container selected mb-3"
          : props.selected && props.active
          ? "filter-item-container selected active-filter-box mb-3"
          : "filter-item-container mb-3"
      }
    >
      <Row className="justify-content-center align-items-center px-3">
        <Col
          className="col-12 filter-item-icon-col"
          style={{
            backgroundColor:
              props.active && !props.selected
                ? props.iconBg
                : !props.active && !props.selected
                ? props.iconBg
                : props.active && props.selected
                ? props.iconBg
                : "#dddddd",
          }}
        >
          {props.icon}
        </Col>
        <Col>
          <Row className="gy-0">
            <Col className="col-12 ">
              <Row className="justify-content-between">
                <Col className="col-9">
                  <Row>
                    <Col style={{ position: "relative" }}>
                      <h4 className="display-xs semi-bold m-0">
                        {props.title}
                      </h4>
                      {filterTypesLength > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            backgroundColor: "rgba(62, 190, 227, 1)",
                            borderRadius: "50%",
                            width: "17px",
                            display: "flex",
                            fontSize: "12px",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                            color: "#ffffff",
                            padding: "0px 3px",
                          }}
                        >
                          <p className="m-0">{filterTypesLength}</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row className="justify-content-end">
                    {props.canBeRemoved && (
                      <Col
                        className="col-1 filter-item-container-remove"
                        onClick={props.onClickRemove}
                      >
                        <IoMdTrash size={20} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              {!props.selected ? (
                <p className="text-xs regular m-0">{props.subTitle}</p>
              ) : (
                <p style={{ color: "#6C9384" }} className="text-xs regular m-0">
                  {selectedFilterTypes.map((item) => item + " ")}
                </p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FilterItem;
