import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.css";
import { Bar } from "react-chartjs-2";
import StatisticsTotalCardRow from "features/campaign/campaign-details/features/statistics/components/StatisticsTotalCardRow";
import { parseChartData } from "features/campaign/campaign-details/features/statistics/helper";
import StatisticsCounts from "./StatisticsCounts";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { FaTiktok } from "react-icons/fa";
import { BsSend } from "react-icons/bs";
import { MdOutlineSms, MdMailOutline } from "react-icons/md";
import moment from "moment";
import StatisticsFilter from "./StatisticsFilter";
import { CampaignActivityTypes } from "features/campaign/campaign-details/options";

export default function StatisticsContainer({ campaignStatistic, campaign, setActivityFilter, activityFilter }) {
  if (!campaignStatistic) return <></>;

  const statisticsList = [
    [
      {
        label: "budget",
        property: "budget",
      },
      {
        label: "Spenderad budget",
        property: "budgetSpent",
      },
    ],
    [
      {
        label: "Visningar per person",
        property: "frequenzy",
      },
    ],
    [{ label: "Klick", property: "clicks" }],
    [
      { label: "Visningar", property: "exposures" },
      // {
      //   label: "Unika Visningar (per dag)",
      //   property: "reach",
      // },
    ],
  ];

  const charts = parseChartData(statisticsList, campaignStatistic);

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  const stats = campaignStatistic[0];

  const total_stats_items = [
    {
      label: "Exponeringar",
      value: stats.exposures,
    },
    {
      label: "Unika Visningar",
      value: stats.reach,
    },
    {
      label: "Klick",
      value: stats.clicks,
    },
    {
      label: "CTR",
      value: stats.ctr,
      unit: "%",
    },
    {
      label: "Spenderad Budget",
      value: stats.budgetSpent,
      unit: "kr",
    },
    {
      label: "Värde",
      value: stats.conversionValue,
      unit: "kr",
    },
    {
      label: "Konverteringar",
      value: stats.conversions,
    },
  ];

  const countStats = stats.countOfCampaigns;
  const countChannels = stats.countOfActivityTypes;
  const total_counts_items = [
    {
      label: "Totalt",
      value: countStats.total,
      unit: "kampanjer",
      totals: [
        {
          label: "Aktiva",
          value: countStats.active,
          color: "#027a48",
          bgColor: "#ecfdf3",
        },
        {
          label: "Schemalagda",
          value: countStats.scheduled,
          color: "#b54708",
          bgColor: "#fffaeb",
        },
        {
          label: "Avslutade",
          value: countStats.ended,
          color: "#b42318",
          bgColor: "#fef3f2",
        },
        {
          label: "Pausade",
          value: countStats.paused,
          color: "#3ebee3",
          bgColor: "#ccecf6",
        },
      ],
      channels: [
        {
          label: "Meta",
          value: countChannels.meta,
          icon: <TbBrandMeta color="#216FE4" className="mx-1" />,
        },
        {
          label: "TikTok",
          value: countChannels.tikTok,
          icon: <FaTiktok color="#000" className="mx-1" />,
        },
        {
          label: "LinkedIn",
          value: countChannels.linkedIn,
          icon: <SiLinkedin color="rgb(10, 102, 194)" className="mx-1" />,
        },
        {
          label: "Google Ads",
          value: countChannels.google,
          icon: <SiGoogleads className="mx-1" />,
        },
        {
          label: "Email",
          value: countChannels.email,
          icon: <BsSend color="#017397" className="mx-1" />,
        },
        {
          label: "Sms",
          value: countChannels.sms,
          icon: <MdOutlineSms color="#017397" className="mx-1" />,
        },

        {
          label: "Direkt marknadsföring",
          value: countChannels.directMarketing,
          icon: <MdMailOutline color="#017397" className="mx-1" />,
        },
      ],
    },
  ];

  const fromDate = moment(stats.fromDate)
  const toDate = moment(stats.toDate)
  const daysDiff = toDate.diff(fromDate, "days")
  return (
    <>
      <Row><span>Mellan <strong>{fromDate.format('yyyy-MM-DD')}</strong> - <strong>{toDate.format('yyyy-MM-DD')}</strong> och över ett spann av <strong>{daysDiff}</strong> dagar</span></Row>
      <hr  />
      <StatisticsCounts items={total_counts_items} />
      <hr style={{ marginTop: "45px" }} />
      <Row className="row-cols-auto">
        <Col>
          <StatisticsFilter 
            setSelected={setActivityFilter} 
            selected={activityFilter} 
            activeChannels={Object.keys(countChannels).filter(key => countChannels[key])}/>
        </Col>
      </Row>
      <StatisticsTotalCardRow items={total_stats_items} />
      <Row className="row-cols-1 row-cols-lg-2 g-4 py-4">
        {charts.map((chart, index) => {
          const { label, chartData } = chart;
          return (
            chartData !== undefined && (
              <Col key={index}>
                <Card className="card-container p-2 h-100" bg="light">
                  <Container className="m-0 g-0" fluid>
                    <div className={styles.cardRow}>
                      <Col>
                        <h4 className="text-lg semi-bold text-capitalize">
                          {label}
                        </h4>
                      </Col>
                    </div>
                    <div className={styles.cardRow}>
                      <Bar data={chartData} options={options} />
                    </div>
                  </Container>
                </Card>
              </Col>
            )
          );
        })}
      </Row>
    </>
  );
}
