import { Row, Col, Table } from "react-bootstrap";
import { CardContainer } from "components/cards";

const AccountPersonPhoneNumbers = ({ account }) => {
  return (
    <Col className="col-12">
      <CardContainer
        children={
          <Row>
            <Col className="col-12 p-5">
              <h2 className="display-sm bold">Telefonnummer</h2>
            </Col>
            <Col className="col-12">
              {account?.length === 0 ? (
                <p className="m-4">Inga nummer hittades hos företaget.</p>
              ) : (
                <Table
                  id="segmentTable"
                  className="table-container"
                  responsive
                  hover
                  size="lg"
                >
                  <tr>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Namn
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Nummer
                    </th>
                    <th className="text-md bold" style={{ color: "#000" }}>
                      Typ av nummer
                    </th>
                  </tr>

                  <tbody className="text-md regular">
                    {account.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.basic.data.names.map((name) => name.name)}
                        </td>
                        <td>+{item.attributes.phonenumber}</td>
                        <td>
                          {item.basic.data.type === "MB" || item.basic.data.type === "M"
                            ? "Mobil"
                            : item.basic.data.type === "STD"
                            ? "Fast"
                            : item.basic.data.type}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        }
      />
    </Col>
  );
};

export default AccountPersonPhoneNumbers;
