import React, { useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import { useState } from "react";
import {
  CampaignOptions,
  CampaignOptionsTypes,
  CampaignActivityTypes,
} from "../../options";
import StatisticsFilter from "./components/StatisticsFilter";
import StatisticsContainer from "./components/StatisticsContainer";
import EmailStats from "./containers/EmailStats";

const Statistics = ({ campaign }) => {
  const firstActivityType = campaign?.campaignStatistics?.at(0)?.activityType;
  const isOnlyOneActicityType = campaign?.campaignStatistics.every(
    (item) => item.activityType === firstActivityType
  );
  const DEFAULT_SELECTION = isOnlyOneActicityType
    ? CampaignActivityTypes[firstActivityType]
    : CampaignOptions.Overview;

  const [selectedStatisticFilter, setSelectedStatisticFilter] =
    useState(DEFAULT_SELECTION);
  useEffect(() => setSelectedStatisticFilter(DEFAULT_SELECTION), []);

  if (campaign === undefined) return <></>;

  const campaignActivityTypes =  campaign.campaignStatistics.flatMap(
    (x) => x.activityType
  );

  const campaignStarted = campaign?.campaignStatistics?.some((obj) =>
    Object.values(obj).some((value) => value !== 0)
  );

  const activityType = Object.keys(CampaignActivityTypes).find(key => CampaignActivityTypes[key] === selectedStatisticFilter)

  return (
    <div className="px-4 pt-2">
      {campaignStarted ? (
        <>
          <Row className="d-flex align-items-center">
            <div className="d-flex col">
              <StatisticsFilter
                selected={selectedStatisticFilter}
                setSelected={setSelectedStatisticFilter}
                activeChannels={campaignActivityTypes}
              />
            </div>
          </Row>

          {selectedStatisticFilter === CampaignOptions.Overview ?
            <StatisticsContainer
                campaign={campaign}
              campaignStatistic={campaign.campaignStatistics}
            />
            :
          selectedStatisticFilter === CampaignOptions.Email ? (
            <EmailStats
              activity={campaign.campaignActivities?.find(
                (x) => x.type === CampaignOptionsTypes.Email
              )}
              campaignStatistic={campaign.campaignStatistics.filter(
                (x) => x.activityType === CampaignOptionsTypes.Email
              )}
            />
          ) :

            <StatisticsContainer
                campaign={campaign}
              campaignStatistic={campaign.campaignStatistics.filter(
                (x) => +x.activityType === +activityType 
              )}
              activity={campaign.campaignActivities?.find(
                (x) => +x?.type === +activityType
              )}

            />
          }

        </>
      ) : (
        <Alert variant="warning">
          <Row className="justify-content-center">
            <Col className="col-2 d-flex justify-content-end">
              <MdInfoOutline size={30} color="rgb(174 165 138)" />
            </Col>
            <Col
              className="text-lg semi-bold d-flex align-items-center"
              style={{ color: "rgb(174 165 138)" }}
            >
              Kampanjen har ännu inte startats och har i nuläget ingen statistik
              att visa. Starta din kampanj för att se statistik här!
            </Col>
          </Row>
        </Alert>
      )}
    </div>
  );
};

export default Statistics;
