import { PolygonF } from "@react-google-maps/api";
import React from "react";
import { Col, Row } from "react-bootstrap";

export function infoBoxTemplate({ title, code, opacity, totalcount }) {
  return (
    <Row className="row-cols-1 w-100">
      <Col>
        <span style={{ fontWeight: 400 }}>
          <strong className="semi-bold">Kommun: </strong>
          {title}
        </span>
      </Col>
      <Col>
        <span style={{ fontWeight: 400 }}>
          <strong className="semi-bold">Antal: </strong>
          {totalcount}
        </span>
      </Col>
    </Row>
  )
}

const usePolygonClick = (
  event,
  polygon,
  setInfoBoxContent,
  setInfoBoxOpen,
  setInfoBoxPosition
) => {
  setInfoBoxContent(
    infoBoxTemplate(polygon)
  );

  setInfoBoxOpen(true);
  setInfoBoxPosition(event.latLng);
};

const renderPolygon = (
  postalCode,
  setInfoBoxContent,
  setInfoBoxOpen,
  setInfoBoxPosition
) => {
  return (
    <PolygonF
      key={postalCode.code}
      options={{
        paths: postalCode?.coordinates[0],
        strokeColor: "#000",
        fillColor: "#2d6450",
        strokeOpacity: 0.4,
        strokeWeight: 1,
        zIndex: 3,
        fillOpacity: postalCode?.opacity,
        postalcode: postalCode?.code,
      }}
      onClick={(event) =>
        usePolygonClick(
          event,
          postalCode,
          setInfoBoxContent,
          setInfoBoxOpen,
          setInfoBoxPosition
        )
      }
    />
  );
};

export function polygonRenderer(
  postalCodes,
  setInfoBoxContent,
  setInfoBoxOpen,
  setInfoBoxPosition
) {
  if (!postalCodes) return;
  const polygons = [];
  postalCodes.forEach((postalCode) => {
    const pcPolygons = postalCode.coordinates.map((postalCoords) => {
      return renderPolygon(
        postalCode,
        setInfoBoxContent,
        setInfoBoxOpen,
        setInfoBoxPosition
      );
    });

    polygons.push(pcPolygons);
  });
  return [...polygons];
}
