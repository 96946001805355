export const endPoints = {
  LOGIN_URL: "/authenticate/v1/login",
  FORGOT_PASSWORD_URL: "/authenticate/v1/forgotpassword",
  RESET_PASSWORD_URL: "/authenticate/v1/resetpassword",
  accounts: {
    ACCOUNTS_URL: "/accounts/v1",
  },
  customers: {
    CUSTOMER_SETTINGS_URL: "/customers/v1/settings",
    CUSTOMER_URL: "/customers/v1",
  },
  customLists: {
    CUSTOM_LISTS_URL: "/customlists/v1",
  },
  filtertypes: {
    FILTERTYPES_URL: "/filters/v1/types",
    FILTERCATEGORIES_URL: "/filters/v1/categories",
    FILTERS_URL: "/filters/v1",
  },
  segments: {
    SEGMENTS_URL: "/segments/v1",
    SEGMENTS_COUNT_URL: "/segments/v1/preview",
  },
  leads: {
    LEADS_URL: "/leads/v1",
    TOT_LEADS_URL: "/leads/v1/count",
    LEADS_STATS_URL: "/leads/v1/stats",
  },
  campaigns: {
    CAMPAIGNS_URL: "/campaigns/v1",
    TOT_CAMPAIGNS_URL: "/campaigns/v1/count",
  },
  visuals: {
    URL: "/visuals/v1/",
    PAGE: {
      STATISTICS: "/visuals/v1/?pageId=5B5D11F3-0FB8-4741-ADC8-1ABA14470FF2",
      ID: "/visuals/v1/?pageId=",
    },
    SORT: "/visuals/v1/sort",
    TYPES: "/visuals/v1/types",
    ENTITIES: "/visuals/v1/entities",
    ATTRIBUTES: "/visuals/v1/atts?entity=",
  },
  offers: {
    OFFERS_URL: "/offers/v1",
  },
  overview: {
    OVERVIEW_URL: "/statistics/v1/overview",
  },
  users: {
    USERS_URL: "/users/v1",
    ADD_USERS_URL: "/users/v1/adduser",
  },
};
