import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import styles from "../../../style.module.css";
import Numeral from "react-numeral";
import { isNaN } from "lodash";

export default function StatisticsTotalCardRow({ items }) {
  return (
    <>
      <Row className=" g-4 mt-2 justify-content-around">
        {items?.map((item) => {
          const value = isNaN(item?.value) ? 0 : item?.value;
          return (
            <Col key={item?.label}>
              <Card
                className="card-container p-3 h-100 justify-content-between "
                bg="light"
              >
                <div className={styles.cardRow}>
                  <Col>
                    <h4
                      className="text-lg semi-bold text-capitalize"
                      style={{ color: "grey" }}
                    >
                      {item?.label}
                    </h4>
                  </Col>
                </div>
                <div className={styles.cardRow} style={{ fontSize: "36px" }}>
                  {item?.unit?.includes("kr") ? (
                    <Numeral value={value?.toFixed(2)} format={"0,0"} />
                  ) : item?.unit?.includes("%") ? (
                    <Numeral value={value?.toFixed(2)} format={"0,0"} />
                  ) : (
                    <Numeral value={value} format={"0,0"} />
                  )}
                  {item?.unit}
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
