import Agent from "api/Agent";
import { CardContainer } from "components/cards";
import { TextInput } from "components/forms";
import PaginationBar from "components/pagination/PaginationBar";
import moment from "moment";
import { useState } from "react";
import { Button, Row, Table } from "react-bootstrap"
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { MdLogin, MdSearch } from "react-icons/md";
import CustomerDetailsOffCanvas from "../components/customer-details/CustomerDetailsOffCanvas";
import FilterDropdown from "./components/FilterDropdown";

const CustomerSuccessTable = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState({ prop: "lastLoginDate", desc: true })
  const [search, setSearch] = useState(undefined)
  const [filter, setFilter] = useState(undefined)


  const { data: tableResult, isLoading, error } = Agent.Customers.GET.ACTIVITY(currentPage, undefined, search, sort?.prop, sort?.desc)

  const [show, setShow] = useState(false)
  const [selectedId, setSelectedId] = useState(undefined)
  const handleSearch = (text) => {
    setSearch(text);
    if (currentPage !== 1) setCurrentPage(1)
  }

  // Table header columns 
  const handleTableHeaderClick = (property) => {
    if (!property || property?.includes(".")) return;
    if (sort?.prop === property)
      return setSort({ prop: property, desc: !sort.desc });
    return setSort({ prop: property, desc: false });
  };
  const isColumnSortable = (col) => !!col?.sortable;
  const isSorting = (col) => sort?.prop === col.property && col.property;

  const handleTableRowClick = (id) => {
    setSelectedId(id)
    setShow(true)
  }

  /*
 * label : table header label
 * property : reflect table column data from Agent data
 * format : a function to format the data to a readable format 
 * sortable : boolean prop is sorting is allowed
 */
  const columns = [
    { label: "Konto", property: "name", sortable: true },
    {
      label: "Senast Inloggad", property: "lastLoginDate",
      format: (date) => {
        const differenceOfDaysFromToday = moment().diff(moment(date), 'days')
        const formatedDate = moment(date).format('yyyy-MM-DD HH:mm')

        if (!date) return "-"
        if (differenceOfDaysFromToday === 0) return `Idag, ${formatedDate}`
        if (differenceOfDaysFromToday === 1) return `Igår, ${formatedDate}`
        return `${differenceOfDaysFromToday} dagar sedan, ${formatedDate}`
      }, sortable: true
    },
    { label: "Aktiva Kampanjer", property: "countOfActiveCampaigns", sortable: false },
    { label: "Klick totalt", property: "clickTotal", sortable: false },
    { label: "CTR (Ø)", property: "ctr", format: (number) => (Math.round(number * 100) / 100) + "%", sortable: false, },
    { label: "Konv.", property: "convertions", },
    {
      label: "Senaste kampanj avslut", property: "latestCampaignEndDate", sortable:false,
      format: (date) => {
        const differenceOfDaysFromToday = moment(date).diff(moment(), 'days')
        const formatedDate = moment(date).format('yyyy-MM-DD')

        if (!date) return "-"
        if (differenceOfDaysFromToday < 0) return `${Math.abs(differenceOfDaysFromToday)} dagar sedan, ${formatedDate}`
        if (differenceOfDaysFromToday === 0) return `Idag, ${formatedDate}`
        if (differenceOfDaysFromToday === 1) return `Imorgon, ${formatedDate}`
        return `om ${differenceOfDaysFromToday} dagar, ${formatedDate}`
      } 
    },
  ];


  return (
    <Row>
      <Row className="d-flex flex-row align-items-baseline justify-content-between px-0 mx-0">
        <div className="col-auto">
          <FilterDropdown setFilter={setFilter} filter={filter} />
        </div>
        <div style={{ width: "300px" }}>
          <TextInput
            type="search"
            placeholder="Sök"
            icon={<MdSearch />}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </div>
      </Row>

      <Row className="p-0 ">
        <CardContainer
          style={{ padding: "0" }}>
          <Table>
            <thead className="text-sm semi-bold">
              <tr>
                {columns.map((col) => (
                  <th key={col.id} style={{ width: "150px", userSelect: "none" } + isColumnSortable(col) && { cursor: "pointer" }}
                    onClick={() =>
                      isColumnSortable(col) &&
                      handleTableHeaderClick(col.property)
                    }
                  > {isSorting(col) ? (
                    <p>
                      {col?.label}
                      {sort?.desc ? (
                        <FaSortDown className="ms-1" size={12} />
                      ) : (
                        <FaSortUp className="ms-1" size={12} />
                      )}
                    </p>
                  ) : (
                    <p>{col.label}
                      {isColumnSortable(col) && <FaSort className="ms-1" size={12} />}
                    </p>
                  )}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableResult?.data?.map((r) =>
                <tr key={r.id} onClick={() => handleTableRowClick(r?.id)}>
                  {columns?.filter(x => x?.property !== undefined).map(x => <td key={x?.property} className="text-sm">{x?.format ? x?.format(r[x?.property]) : r[x?.property]}</td>)}

                                 </tr>
              )}
            </tbody>

            <tfoot>
            </tfoot>

          </Table>
          {tableResult?.paging &&
            <PaginationBar
              setCurrentPage={setCurrentPage}
              paging={tableResult?.paging}
            />
          }

        </CardContainer>
      </Row>
      <CustomerDetailsOffCanvas show={show} setShow={setShow} id={selectedId}/>
    </Row>
  )
}

export default CustomerSuccessTable
