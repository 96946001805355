
import { confirmable } from 'react-confirm';
import { Button, Modal } from 'react-bootstrap';

// https://www.npmjs.com/package/react-confirm

const Confirm = ({ show, proceed, confirmation, options }) => (
        <Modal centered show={show} onHide={() => proceed(false)}>
                <Modal.Header >
                        <Modal.Title>Bekräfta</Modal.Title>
                </Modal.Header>

                <Modal.Body className='text-center semi-bold'>
                        {confirmation}
                </Modal.Body>

                <Modal.Footer className='d-flex justify-content-center'>
                        <Button onClick={() => proceed(false)} variant="secondary">Avbryt</Button>
                        <Button onClick={() => proceed(true)} variant="primary">Ok</Button>
                </Modal.Footer>
        </Modal >
)

export default confirmable(Confirm);
