import { useState } from "react";
import { Button, Offcanvas, Table, Col, Row, Spinner, } from "react-bootstrap";
import { CardContainer } from "components/cards";
import styles from "./style.module.scss";
import Agent from "api/Agent";
import JobDetailsOffcanvas from "./components/JobDetailsOffcanvas";

const JobDetails = ({ handleClose, show, id, data }) => {

  const [showDetailsOffcanvas, setShowDetailsOffcanvas] = useState(false);
  const [bgBlur, setBgBlur] = useState(false);
  const [customerId, setCustomerId] = useState("");
  

  const {
    // isLoading,
    // isError,
    data: jobsRuns,
  } = Agent.Jobs.GET.RUNS(id);

  


  const columns = [
    { label: "Typ av jobb", id: 1 },
    { label: "Starttid", id: 2 },
    { label: "Sluttid", id: 3 },
  ];


  const handleShowDetailsOffcanvas = (id) => {
    setShowDetailsOffcanvas(true);
    setBgBlur(true);
    setCustomerId(id);
  };
  const handleCloseDetailsOffcanvas = () => {
    setShowDetailsOffcanvas(false);
    setBgBlur(false);
    setCustomerId(null);

  };



  return (
    <>
    <JobDetailsOffcanvas
    show={showDetailsOffcanvas}
    handleClose={handleCloseDetailsOffcanvas}
    id={customerId}
    
    />
      <Offcanvas
        show={show}
        onHide={handleClose}
        className={styles.offcanvas}
        placement="end"
        style={bgBlur ? { filter: "brightness(50%)" } : {}}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Name Job </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Row className="p-4">
         <Col className="col-12">
          <CardContainer
          style={{ padding: "0" }}
            children={
              <>
                <Table>
                  <thead className="text-sm semi-bold">
                    <tr style={{ textAlign: "center" }}>
                      {columns.map((col) => (
                        <th key={col.id}>{col.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-md regular">
                    {jobsRuns?.Results.map((r) => (
                      <tr 
                      key={r.id}
                      onClick={() => handleShowDetailsOffcanvas(r.id)}
                      
                      >
                        <td>{r.jobID}</td>
                        <td style={{ textAlign: "center" }}>
                        {r.startedOn && `${r.startedOn.substring(0, 10)} ${r.startedOn.substring(11, 16)} `}
                        </td>
                        <td>{r.completedOn && `${r.completedOn.substring(0, 10)} ${r.completedOn.substring(11, 16)} `}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            }
          />
          </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default JobDetails;
