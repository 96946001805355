import React from "react";
import { Col } from "react-bootstrap";
import MultiRangeSlider from "multi-range-slider-react";
import { isArray } from "lodash";

export default function FilterPropMultiRange({ filter, filterType }) {
  // const maxValue = Math.max(
  //   ...filter.find((filterProperties) => isArray(filterProperties))
  // );
  // const minValue = Math.min(
  //   ...filter.find((filterProperties) => isArray(filterProperties))
  // );

  return (
    <Col className="bold" style={{ color: "#00789e" }}>
      {`${filter[1][0]}${filterType.suffix ?? ""} - ${filter[1][1]}${filterType.suffix ?? ""}`}
    </Col>
    // <div
    //   key={filter.find((x) => x) + "-multiRange"}
    //   className="mx-auto"
    //   style={{ width: "80%", userSelect: "none", pointerEvents: "none" }}
    // >
    //   <MultiRangeSlider
    //     ruler={false}
    //     step={filter && filter.rangeSteps}
    //     stepOnly={true}
    //     style={{ opacity: 0.8 }}
    //     baseClassName="multi-range-slider-black"
    //     label={false}
    //     minCaption={minValue + filterType?.suffix}
    //     maxCaption={
    //       filter?.path === "[address][zip_code]"
    //         ? maxValue?.toString().substring(0, 3) +
    //           " " +
    //           maxValue?.toString().substring(3, 5)
    //         : filterType?.suffix
    //         ? maxValue?.toLocaleString("en-US") + filterType?.suffix
    //         : maxValue?.toLocaleString("en-US")
    //     }
    //     min={minValue}
    //     minValue={minValue}
    //     max={maxValue}
    //     maxValue={maxValue}
    //     values={filter.find((filterProperties) => isArray(filterProperties))}
    //   />
    // </div>
  );
}
