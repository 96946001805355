
import { segmentStatuses_sv, segmentTypes_sv } from 'features/segment/segment-details/assets/config';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Dropdown, Form, AccordionButton, } from 'react-bootstrap';
import { MdFilterList } from 'react-icons/md';

const FilterDropdown = ({ setSegmentType }) => {
  const ref = useRef();
  const [activeCount, setActiveCount] = useState(0)
  const resetForm = () => {
    ref?.current?.reset();
    handleChange();
  }

  const getFilterState = () => {
  
    if (!ref?.current) return null;
    const formData = new FormData(ref?.current);
    const formDataArray = Array.from(formData?.entries());


    const enabledOptions = formDataArray?.filter(x => x?.length > 1 && !!x?.at(1))
    setActiveCount(enabledOptions?.length ?? 0)

    return formDataArray;
  }

  const handleChange = () => {
    const formDataArray = getFilterState();


    const type = formDataArray?.find(x => x?.at(0) === "type")?.at(1);
    if (typeof setSegmentType == "function") setSegmentType(type ? type : undefined)

  }

  useEffect(() => {getFilterState();}, [ref]);


  

  return (
    <Dropdown >
      <Dropdown.Toggle variant='light' className='d-flex align-items-center px-4 py-2 gap-1 filter-button semi-bold text-md' >
        <MdFilterList />
        <span>Filter</span>
        {activeCount > 0 && <span className="filter-indicator">{activeCount}</span>}
      </Dropdown.Toggle>


      <Dropdown.Menu className='mt-2 rounded shadow '>
        <Form ref={ref} style={{ minWidth: "280px", maxWidth: "320px" }}  className="d-flex flex-column justify-content-center align-content-center p-2" onInput={handleChange}>

          <TypeFilter />


        </Form>
        <AccordionButton type='button' style={{ borderTop: "2px solid gray" }} onClick={() => resetForm()} className="semi-bold ">Återställ Filter</AccordionButton>
      </Dropdown.Menu>
    </Dropdown >)
}



function TypeFilter() {

  const options = Object.keys(segmentTypes_sv)?.map(key => {
    const { text } = segmentTypes_sv[key] ?? { text: undefined };
    return (
      <option key={text} value={key}>{text}</option>
    )
  });

  return <Form.Group className=' d-flex flex-column justify-content-start align-items-start gap-1 p-1'>
    <Form.Text>Typ av målgrupp</Form.Text>
    <Form.Select name='type'>
      <option value="">Alla</option>
      {options}
    </Form.Select>
  </Form.Group>
}



export default FilterDropdown
