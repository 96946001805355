import "../style.scss";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { optionsSocialAd, optionsDirectAd } from "../data/stepsData";
import AdCheckBox from "../components/AdCheckBox";
import StepTopRow from "../components/StepTopRow";
import StepBottomRow from "../components/StepBottomRow";
import { getCustomerSettings } from "features/settings/api";
import Agent from "api/Agent";
import { useMediaQuery } from "react-responsive";

const AdChannel = () => {
  const isXsScreen = useMediaQuery({ maxWidth: 900 });
  const paddingBottomStyle = isXsScreen ? { paddingBottom: '400px' } : {};
  const navigate = useNavigate();
  //Get Campaign-----------------------------------------------W
  //Get campaign id from URL
  const { id } = useParams();

  //Get campaign data using id
  const { data: campaign } = Agent.Campaigns.GET.ID(id);
  const { data: settings } = useQuery("customer", getCustomerSettings);

  //States ------------------------------------------------
  const [googleAds, setGoogleAds] = useState(false);
  const [meta, setMeta] = useState(false);
  const [linkedIn, setLinkedIn] = useState(false);
  const [tikTok, setTikTok] = useState(false);
  //Direct
  const [email, setEmail] = useState(false);
  const [sms, setSms] = useState(false);
  const [direct, setDirect] = useState(false);
  //! const [generateLeads, setGenerateLeads] = useState(false);

  useEffect(() => {
    if (campaign) {
      setGoogleAds(campaign.enableGoogleAds);
      setMeta(campaign.enableFacebook);
      setLinkedIn(campaign.enableLinkedIn);
      setDirect(campaign.enableDirectMarketing);
      setEmail(campaign.enableEmail);
      setSms(campaign.enableSms);
      setTikTok(campaign.enableTikTok)
      //! setGenerateLeads(campaign.enableLeadGeneration);
    }
  }, [campaign]);

  //Submit-------------------------------------------------
  //Update campaign function
  const [loading, setLoading] = useState(false);
  const { mutate: updateCampaign } = Agent.Campaigns.PUT.CAMPAIGN();
  const mutate = (newCampaign) =>
    updateCampaign(newCampaign, {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        navigate(`/campaigns/create-campaign/${id}/budget-schedule`);
      },
    });
  //onClick
  const onClickSubmit = () => {
    const updatedCampaign = {
      ...campaign,
      enableGoogleAds: googleAds,
      enableFacebook: meta,
      enableInstagram: meta,
      enableLinkedIn: linkedIn,
      enableEmail: email,
      enableSms: sms,
      enableDirectMarketing: direct,
      enableLeadGeneration: true,
      enableTikTok: tikTok,
      completedSteps: !campaign.completedSteps.some(
        (step) => step === "ad-channel"
      )
        ? [...campaign.completedSteps, "ad-channel"]
        : [...campaign.completedSteps],
    };
    mutate(updatedCampaign);
  };

  return (
    <Row className="m-0 " style={paddingBottomStyle}>
      {/* TOP----------------------- */}
      <StepTopRow heading="Annonskanaler" />
      {/* MIDDLE----------------------- */}
      <Col className="col-12 p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h4 className="display-xs bold">Annonsera i sociala medier</h4>
          </Col>
          <Col className="col-12">
            <Row className="justify-content-between gx-4">
              {settings?.isGoogleAdsAvailable ? (
                <Col
                  onClick={() => {
                    setGoogleAds(!googleAds);
                  }}
                >
                  <AdCheckBox
                    {...optionsSocialAd[0]}
                    current={optionsSocialAd[0]}
                    value={googleAds}
                  />
                </Col>
              ) : (
                <OverlayTrigger
                  overlay={<Tooltip>Google Ads är inte aktiverat!</Tooltip>}
                >
                  <Col>
                    <AdCheckBox
                      {...optionsSocialAd[0]}
                      current={optionsSocialAd[0]}
                      value={googleAds}
                      disabled
                    />
                  </Col>
                </OverlayTrigger>
              )}
              {settings?.isMetaAdsAvailable ? (
                <Col
                  onClick={() => {
                    setMeta(!meta);
                  }}
                >
                  <AdCheckBox
                    {...optionsSocialAd[1]}
                    current={optionsSocialAd[1]}
                    value={meta}
                  />
                </Col>
              ) : (
                <OverlayTrigger
                  overlay={
                    <Tooltip variant="info">
                      <div>Meta annonsering är inte aktiverat!</div>
                    </Tooltip>
                  }
                >
                  <Col>
                    <AdCheckBox
                      {...optionsSocialAd[1]}
                      current={optionsSocialAd[1]}
                      value={meta}
                      disabled
                    />
                  </Col>
                </OverlayTrigger>
              )}

              {settings?.isLinkedInAdsAvailable ? (
                <Col
                  onClick={() => {
                    setLinkedIn(!linkedIn);
                  }}
                >
                  <AdCheckBox
                    {...optionsSocialAd[2]}
                    current={optionsSocialAd[2]}
                    value={linkedIn}
                  />
                </Col>
              ) : (
                <OverlayTrigger
                  overlay={
                    <Tooltip variant="info">
                      <div>LinkedIn är inte aktiverat!</div>
                    </Tooltip>
                  }
                >
                  <Col>
                    <AdCheckBox
                      {...optionsSocialAd[2]}
                      current={optionsSocialAd[2]}
                      value={linkedIn}
                      disabled
                    />
                  </Col>
                </OverlayTrigger>
              )}
              {settings?.isTikTokAdsAvailable ? (
                <Col
                  onClick={() => {
                    setTikTok(!tikTok);
                  }}
                >
                  <AdCheckBox
                    {...optionsSocialAd[3]}
                    current={optionsSocialAd[3]}
                    value={tikTok}
                  />
                </Col>
              ) : (
                <OverlayTrigger
                  overlay={
                    <Tooltip variant="info">
                      <div>TikTok är inte aktiverat!</div>
                    </Tooltip>
                  }
                >
                  <Col>
                    <AdCheckBox
                      {...optionsSocialAd[3]}
                      current={optionsSocialAd[3]}
                      value={tikTok}
                      disabled
                    />
                  </Col>
                </OverlayTrigger>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="col-12 p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h4 className="display-xs bold">
              Nå din målgrupp via telefon eller post
            </h4>
          </Col>
          <Col className="col-12">
            <Row className="justify-content-between gy-4">
              <Col
               md={12} lg={6}
                
                onClick={() => {
                  setSms(!sms);
                }}
              >
                <AdCheckBox
                  slim
                  {...optionsDirectAd[0]}
                  current={optionsDirectAd[0]}
                  value={sms}
                />
              </Col>
              <Col
               md={12} lg={6}
                // className="col-6"
                onClick={() => {
                  setDirect(!direct);
                }}
              >
                <AdCheckBox
                  slim
                  {...optionsDirectAd[1]}
                  current={optionsDirectAd[1]}
                  value={direct}
                />
              </Col>
              <Col
                 md={12} lg={6}
                // className="col-6"
                onClick={() => {
                  setEmail(!email);
                }}
              >
                <AdCheckBox
                  slim
                  {...optionsDirectAd[2]}
                  current={optionsDirectAd[2]}
                  value={email}
                />
              </Col>
              {/*//! <Col
                className="col-6"
                onClick={() => {
                  setGenerateLeads(!generateLeads);
                }}
              >
                <AdCheckBox
                  slim
                  {...optionsDirectAd[3]}
                  current={optionsDirectAd[3]}
                  value={generateLeads}
                />
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Col>
      {/* BOTTOM----------------------- */}
      <StepBottomRow onClickSubmit={() => onClickSubmit()} loading={loading} />
    </Row>
  );
};

export default AdChannel;
