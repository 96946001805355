import { ButtonMain } from 'components/buttons'
import React from 'react'
import { useState } from 'react';
import { Card, Col, Offcanvas, Row, Spinner, Table } from 'react-bootstrap'
import { FaMapMarkedAlt } from 'react-icons/fa'
import styles from "./assets/style.module.css"
import { useMutation, useQuery } from 'react-query';
import { getSegmentById, getSegmentPreview, segmentPreviewObject } from 'features/segment/api';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import PaginationBar from 'components/pagination/PaginationBar';
import GoogleMapTargetGroup from 'components/maps/google-map-main/GoogleMapTargetGroup';
import { useRef } from 'react';
import { getNestedPropertyValue } from 'helpers/shared';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import Agent from 'api/Agent';


export default function ViewSegmentOffCanvas({segment}) {
  const [show, setShow] = useState(false);
  const mapRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const { mutate: fetchPreview } = useMutation(
    (_segment) => 
    getSegmentPreview(segmentPreviewObject(_segment), page, limit, data?.Paging?.pit).then(
        (response) => response
      ),
    {
      onMutate: () => {
        setError(null);
        setData({...data, Results : null});
      },
      onSuccess: (response) => {setData(response); setError(null);},
      onError: (error) => {
        setError(error);
        console.error("There was an error fetching preview", error);
      },
    }
  );
  const results = data?.Results?.map((result => result?.basic?.data))
  const isPerson = data?.Results?.find(x => x.type === 'person')
  const isCompany = data?.Results?.find(x => x.type === 'company')
  const paging = data?.Paging


  useEffect(() => {
    segment && fetchPreview(segment);
  },[segment])
  
  useEffect(() => {
    fetchPreview(segment);
  },[page])
  
  const locations = 
  
   isPerson ? results?.map(result => `${result?.address?.name}, ${result?.address?.zip_code} ${result?.address?.city}, Sweden `)
   :
  results?.map(result => `${result?.zipcode} ${result?.county} `)


  const columns = isCompany ?
   [
    { label: "företag", property: "name" },
    { label: "stad", property: "municipality" },
    { label: "län", property: "county" },
   ]
   : isPerson &&
   [
    { label: "född år", property: "birth_year" },
    { label: "kön", property: "sex" },
    { label: "stad", property: "address?.city" },
    { label: "län", property: "address?.county" },
  ];

  
  
  return (
    <>
      <ButtonMain
        onClick={() => handleShow()}
        text={
          <>
            <span>Visa Målgrupp</span>
            <FaMapMarkedAlt className="ms-2" />
          </>
        }
        variant="secondary "
      />

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className={`${styles.OffCanvas} offcanvas`}
      >
        <Offcanvas.Header closeButton >
          <Offcanvas.Title >Visualisering av segment </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="">
          {error && (
            <span>
              Det uppstod ett problem vid hämtning av segmentet. Försök igen
              senare
            </span>
          )}

          <Row className="h-100 pb-3 px-2 gy-3">
            <Col className="col-lg-7 mx-auto col-12 h-100 ">
              <Card
                border="light"
                tabIndex={0}
                className="card-container p-3 h-100"
                bg={"light"}
              >
                <div className="h-100">
                  <GoogleMapTargetGroup mapRef={mapRef} locations={locations} />
                </div>
              </Card>
            </Col>

            <Col className="col-lg-5 col-12 h-100">
              <Card
                border="light"
                tabIndex={0}
                className="card-container p-3 h-100"
                bg={"light"}
              >
                <Table className={`h-100 ${styles.tableSegment}`}>
                  <thead className=" text-capitalize">
                    <tr>
                      {columns?.map((col, index) => (
                        <th
                          style={{ width: "150px", userSelect: "none" }}
                          key={index}
                        >
                          
                            <p>{col.label}</p>
                          
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {results && results
                      ?.map((result, index) => (
                        <tr key={index}>
                          {columns.map((col) => (
                            <td>
                              {getNestedPropertyValue(result, col?.property)}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                  <tfoot className="pt-3">
                    <tr>
                      <td>
                        <PaginationBar
                          currentPage={page}
                          setCurrentPage={setPage}
                          countPerPage={paging?.per_page}
                          totalCount={paging?.total}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Card>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
