import { useDroppable } from "@dnd-kit/core";

  export function Droppable({ children }) {
  const { isOver, setNodeRef } = useDroppable({
    id: "droppable"
  });
 

  return (
    <div ref={setNodeRef} style={{ position:"absolute", width:"100%", height:"100%", top:0, left:0, display:"inline-block" }}>
      {children}
    </div>
  );
}
