import { isArray } from "lodash";
import { Col } from "react-bootstrap";
import React from "react";

export default function FilterPropText({ filter, filterCategory }) {
  const id = filter.find((filterCategory) => filterCategory);
  return filter
    .find((filterProperties) => isArray(filterProperties))
    ?.map((filterProp) => (
      <Col
        className="bold mx-1"
        style={{
          color: "#00789e",
          textWrap: "nowrap",
          maxWidth: "fit-content",
        }}
      >
        {`${filterProp?.name || filterProp} ${
          filterCategory?.filterTypes?.filter((x) => x.id === id)[0]?.suffix ||
          ""
        }`}
      </Col>
    ));
}
