import { segmentTypes } from "features/segment/segment-details/assets/config"
import moment from "moment"
import { useRef } from "react"
import { Col, Row } from "react-bootstrap"
import { Bar, getElementAtEvent, Pie } from "react-chartjs-2"
import { BsPersonExclamation } from "react-icons/bs"
import { SEGMENT_PREVIEW_AGGREGATIONS } from "../../SegmentPreview"
import styles from "./style.module.css"

const SegmentPreviewChart = ({ aggr, col = 6, segment, onClick, value }) => {

  const chartRef = useRef();
  const personAggr = SEGMENT_PREVIEW_AGGREGATIONS.person
  const title = {
    [personAggr.gender]: "Kön",
    [personAggr.address.city]: "Stad",
    [personAggr.has_phone]: "Telefon",
    [personAggr.birth_year]: "Ålder",
    [personAggr.residence.living_area]: "Boyta",
    [personAggr.residence.type]: "Typ av bostad",
  }[aggr.field]

  const unit = {
    [segmentTypes.Person]: "Personer",
    [segmentTypes.Company]: "Företag",
    [segmentTypes.VehicleOwner]: "Fordonsägare",
  }[segment?.type]

  const labels = aggr?.buckets?.map(bucket => {
    const label = bucket?.key;
    if ([personAggr.has_phone].includes(aggr?.field)) return label ? "Tillgängliga" : "Saknas"
    if (aggr.field === personAggr.gender) return "male".includes(label) ? "Män" : "Kvinnor"
    if (aggr.field === personAggr.address.city) {
      // capitalize city
      return label?.charAt(0)?.toUpperCase() + label?.substr(1);
    }
    if (aggr.field === personAggr.birth_year) return `${moment().year() - +label} år`
    if (aggr.field === personAggr.residence.type) switch (label) {
      case 1:
        return "Hyresrätt";
      case 2:
        return "Bostadsrätt";
      case 3:
        return "Småhus";
      case 4:
        return "Radhus";
      case 5:
        return "Kedjehus";
      default:
        return "Annat";
    }
    if (aggr.field === personAggr.residence.living_area) return `${label} m2`
    return label;

  })
  const data = aggr?.buckets?.map(bucket => bucket?.doc_count)

  const handleClick = (event) => {
    const elementClicked = getElementAtEvent(chartRef?.current, event);
    const elem = elementClicked?.at(0)
    if (!elem) return null

    const key = aggr.buckets?.map(x => x.key)?.at(elem?.index)
    const value = chartRef?.current?.data.datasets[elem?.datasetIndex]?.data[elem?.index]
    const label = chartRef?.current?.data?.labels[elem.index]

    const payload = { field: aggr.field, field_label: title, click: { key, label, value, event: elem }, chart: chartRef?.current?.data }


    if (typeof onClick == "function") {
      onClick(payload)
    }
  }

  const customChartData = value?.field === aggr.field ? value?.data : null
  const dataCount = customChartData?.labels?.length ?? labels?.length

  return (
    <Col className={`d-flex flex-column justify-content-between col-12 col-lg-${col}`}>
      < div style={{ backgroundColor: "#FFF" }
      } className="p-2 shadow rounded" >
        <div className="d-flex flex-column gap-1 px-1 h-100" >
          <Row className="row-cols-auto align-items-center px-2 pt-1 justify-content-start" >
            <span className="text-lg bold text-capitalize p-0 pe-3 text-start " style={{ color: '#017397', borderBottom: "2px solid #017397" }}>{title ?? aggr?.field}</span>
            {customChartData &&
              <BsPersonExclamation className="ms-auto " size={22} style={{ color: '#ff6b6b' }} />
            }
          </Row>
          <div className={`${styles["canvas-container"]} d-flex align-items-center p-0`}>
            {dataCount <= 2 ?
              <Pie
                ref={chartRef}
                data={customChartData ?? {
                  labels: labels,
                  datasets: [{
                    label: unit ?? aggr?.field,
                    data: data,
                    backgroundColor: [
                      // '#8bd8ee',
                      // '#c5ebf7',
                      // '#f7fcfe',
                      // '#00365d',
                      // '#017397',
                      // '#3ebee3',
                      '#3fbde2',
                      '#1d3557',
                      '#ff6b6b',
                      "#c8a2c8",
                      "#008080",
                      '#1d3557',



                    ],
                    borderColor: [
                      // '#8bd8ee',
                      // '#c5ebf7',
                      // '#f7fcfe',
                      // '#00365d',
                      // '#017397',
                      // '#3ebee3',

                      '#000',


                    ],
                    borderWidth: 1
                  }]
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                    padding: 15
                  },
                  plugins: {
                    legend: {
                      labels: {
                        boxHeight: 5
                      }

                    }
                  },
                }}
                onClick={handleClick}
                plugins={[{
                  id: "pieLabelsLine",
                  afterDraw(chart) {
                    // adds outline label for pie chart

                    const {
                      ctx,
                      chartArea: { width, height },
                    } = chart;
                    // reference canvas/ctx https://www.w3schools.com/tags/ref_canvas.asp

                    const cx = chart._metasets[0].data[0].x;
                    const cy = chart._metasets[0].data[0].y;

                    // const sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);

                    chart.data.datasets.forEach((dataset, i) => {
                      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                        const { x: a, y: b } = datapoint.tooltipPosition();

                        const x = 2 * a - cx;
                        const y = 2 * b - cy;

                        // draw line
                        const halfwidth = width / 2;
                        const halfheight = height / 2;
                        const xLine = x >= halfwidth ? x + 7 : x - 7;
                        const yLine = y >= halfheight ? y + 7 : y - 7;

                        const extraLine = x >= halfwidth ? 12 : -12;

                        ctx.beginPath();
                        ctx.moveTo(x, y);
                        ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
                        ctx.fill();
                        ctx.moveTo(x, y);
                        ctx.lineTo(xLine, yLine);
                        ctx.lineTo(xLine + extraLine, yLine);
                        // ctx.strokeStyle = dataset.backgroundColor[index];
                        ctx.strokeStyle = "black";
                        ctx.stroke();

                        // text
                        const textWidth = ctx.measureText(chart.data.labels[index]).width;
                        ctx.font = "600 normal 12px Arial ";
                        // control the position
                        const textXPosition = x >= halfwidth ? "left" : "right";
                        const plusFivePx = x >= halfwidth ? 5 : -5;
                        ctx.textAlign = textXPosition;
                        ctx.textBaseline = "middle";
                        // ctx.fillStyle = dataset.backgroundColor[index];
                        ctx.fillStyle = "black";

                        ctx.fillText(
                          (chart.data.datasets[0].data[index])?.toLocaleString() + " " + chart.data.labels[index]?.toLowerCase(),
                          xLine + extraLine + plusFivePx,
                          yLine
                        );
                      });
                    });
                  },
                }]}
              />
              :


              <Bar
                ref={chartRef}
                data={customChartData ?? {
                  labels: labels,
                  datasets: [{
                    label: unit ?? aggr?.field,
                    data: data,
                    backgroundColor: [
                      // '#8bd8ee',
                      // '#c5ebf7',
                      // '#f7fcfe',
                      // '#00365d',
                      // '#017397',
                      // '#3ebee3',
                      '#3fbde2',
                      '#1d3557',
                      '#ff6b6b',
                      "#c8a2c8",
                      "#008080",



                    ],
                    borderColor: [
                      '#3fbde2',
                      '#1d3557',
                      '#ff6b6b',
                      "#c8a2c8",
                      "#008080",
                    ],
                    borderWidth: 2,
                    borderRadius: 12,
                  }]
                }}
                options={{
                  responsive: true, maintainAspectRatio: false,

                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      beginAtZero: true,
                      stacked: false,
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  },
                }}
                onClick={handleClick}
              />
            }
          </div>
        </div>

      </div >
    </Col>
  )
}


export default SegmentPreviewChart
