import { useState } from "react";
import { Offcanvas, Spinner, Row, Col, Button } from "react-bootstrap";
import CompanyMenu from "./companies/components/CompanyMenu";
import CompanyOverview from "./companies/pages/CompanyOverview";
// import LeadCompanyEconomy from "./pages/LeadCompanyEconomy";
import styles from "./style.module.scss";
// import LeadCompanyBoard from "./pages/LeadCompanyBoard";
// import LeadCompanyPhoneNumbers from "./pages/LeadCompanyPhoneNumbers";
// import LeadCompanyVehicles from "./pages/LeadCompanyVehicles";
// import { useMutation, useQueryClient } from "react-query";
import Agent from "api/Agent";
import CompanyEconomy from "./companies/pages/CompanyEconomy";
import CompanyBoard from "./companies/pages/CompanyBoard";
import CompanyVehicles from "./companies/pages/CompanyVehicles";
import CompanyPhoneNumbers from "./companies/pages/CompanyPhoneNumbers";

const options = [
  { id: 0, name: "Overview", label: "Översikt" },
  { id: 1, name: "Economy", label: "Ekonomi" },
  { id: 3, name: "Board", label: "Board" },
  { id: 4, name: "Vehicle", label: "Vehicle" },
  { id: 5, name: "Phone", label: "Phone" },
  { id: 6, name: "SimilarCompanies", label: "Liknande Företag" },
];

const ClientCompanyDetailsOffCanvas = (props) => {
  const [selectedNav, setSelectedNav] = useState(options[0].name);
  const [bgBlur, setBgBlur] = useState(false);

  const {
    data: account,
    isLoading,
    isError,
  } = Agent.Accounts.GET.ID(props.companyId, props.accountType);

  const handleNavSelection = (selection) => {
    setSelectedNav(selection);
  };

  return (
    <>
      {account && account.jObject && (
        <Offcanvas
          className={styles.leadDetailsOffCanvas}
          show={props.show}
          onHide={props.handleClose}
          placement="end"
          // style={bgBlur ? { filter: "brightness(50%)" } : {}}
        >
          {isLoading && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner style={{ width: "5rem", height: "5rem" }} />
            </div>
          )}
          {isError && (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <span className="semi-bold text-danger">
                Det gick inte att läsa in detaljerad information
              </span>
            </div>
          )}
          {account && account.jObject && !isLoading && !isError && (
            <>
              <div className={styles.leadDetailsOffCanvas_Header}>
                <Offcanvas.Header
                  className="flex-row-reverse justify-content-end align-items-baseline"
                  closeButton
                >
                  <Row className="justify-content-between w-100">
                    <Col className="d-flex text-center align-items-center col-10">
                      <h5 className="text-lg regular m-2">
                        {account.jObject.basic.data.orgnumber}
                      </h5>
                      <h2 className="display-sm semibold">
                        {account?.jObject.basic.data.name
                          ? account.jObject.basic.data.name
                          : "Företaget Test AB"}
                      </h2>
                    </Col>
                  </Row>
                </Offcanvas.Header>

                <CompanyMenu
                  selectedMenuItem={handleNavSelection}
                  options={options}
                />
              </div>

              <Offcanvas.Body className={styles.leadOffCanvasBody}>
                {selectedNav === "Overview" && (
                  <CompanyOverview
                    accountId={props.companyId}
                    setBgBlur={setBgBlur}
                    account={account}
                    accountActivities={account.activities}
                  />
                )}
                {selectedNav === "Economy" && (
                  <CompanyEconomy
                    account={account.jObject.financialSummary.data}
                  />
                )}
                {selectedNav === "Board" && (
                  <CompanyBoard account={account.jObject.boards.data} />
                )}
                {selectedNav === "Vehicle" && (
                  <CompanyVehicles
                    account={account.jObject}
                    accountId={account.id}
                  />
                )}
                {selectedNav === "Phone" && (
                  <CompanyPhoneNumbers
                    account={account.jObject.phoneNumbers.data}
                  />
                )}
                {selectedNav === "SimilarCompanies" && "Liknande Företag"}
              </Offcanvas.Body>
            </>
          )}
        </Offcanvas>
      )}
    </>
  );
};

export default ClientCompanyDetailsOffCanvas;
