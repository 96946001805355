import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styles from "./style.module.css";
import VisualCardOptions from "./VisualCardOptions";
import { arrayMove, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDispatch, useSelector } from "react-redux";
import { SetSortingOrder } from "../../../features/stats/assets/statisticsSlice";
import { IoMdOpen } from "react-icons/io";
import { CARD_TYPE } from "features/stats/assets/options";

const CARD_WIDTH_CLASSES = {
  0: "col-6 col-lg-3",
  1: "col-12 col-lg-6",
  2: "col-12 col-lg-6",
  3: "col-12 col-lg-6",
  4: "col-12 col-lg-6",
  5: "col-12",
  default: "col-12"
};

export default function VisualCardDesign({ visual, title, bg, children }) {
  const [showModal, setShowModal] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: visual.id });
  const visuals = useSelector((state) => state.statistics.visual.layout);
  const dispatch = useDispatch();

  useEffect(() => { setShowModal(false) }, [])

  const moveCard = (direction) => {
    const oldIndex = visuals.findIndex((item) => item.id === visual.id);
    let newIndex = oldIndex;
    if (direction === "left") {
      newIndex = Math.max(oldIndex - 1, 0);
    } else if (direction === "right") {
      newIndex = Math.min(oldIndex + 1, visuals.length - 1);
    }
    dispatch(SetSortingOrder(arrayMove(visuals, oldIndex, newIndex)));
  };

  const cardWidth =
    CARD_WIDTH_CLASSES[visual.type] || CARD_WIDTH_CLASSES.default;

  return (
    <Col className={cardWidth}>
      <Card
        border="light"
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        {...attributes}
        tabIndex={0}
        className="card-container p-3"
        bg={bg === undefined ? "light" : bg}
      >
        <Container className="m-0 g-0" fluid>
          <div className={styles.row}>
            <h4
              style={{ cursor: "grab", userSelect: "none" }}
              {...listeners}
              className="col text-md semi-bold drag-handle text-capitalize"
            >
              {
                title ?? visual?.name
              }
            </h4>
            <MdKeyboardArrowLeft onClick={() => moveCard("left")} />
            <MdKeyboardArrowRight onClick={() => moveCard("right")} />

            {!["add", CARD_TYPE.SumTotal].includes(visual?.type) &&
              <IoMdOpen onClick={() => { setShowModal(!showModal) }} />}

            {visual.type !== "add" ? (
              <VisualCardOptions visual={visual} />
            ) : null}
          </div>
          <Col className={` px-1`} style={{ minHeight: "80px", cursor: "default" }}>
            <>
              {children}
              <VisualCardModal visual={visual} show={showModal} onHide={() => setShowModal(false)}>
                {children}
              </VisualCardModal>
            </>
          </Col>
        </Container>
      </Card>
    </Col>
  );
}
function VisualCardModal({ visual, show, onHide, children }) {
  return <Modal
    show={show}
    onHide={(e) => onHide && onHide(e)}
    dialogClassName={`${styles.modal} `}
    contentClassName={`${styles.modal} `} aria-labelledby="example-custom-modal-styling-title"
    centered
  >

    <Modal.Header closeButton className="flex-row justify-content-end">
      <Col>

        {
          visual?.name
        }
      </Col>
      <div className="px-2">

        {visual.type !== "add" ? (
          <VisualCardOptions visual={visual} />
        ) : null}
      </div>
    </Modal.Header>

    <Modal.Body className="max-width-none max-height-none d-flex flex-column justify-content-center ">

      {children}       </Modal.Body>
  </Modal>
}
