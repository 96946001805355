import React, { useState, useCallback } from "react";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";

function ImageInput({ images, setImages }) {
  const handleImageChange = useCallback(async (event) => {
    const newImages = [];
    for (const file of event.target.files) {
      const url = await convertToDataURL(file);
      newImages.push(url);
    }
    setImages((prevImages) => [...prevImages, ...newImages]);
  }, []);

  const handleImageRemove = useCallback((url) => {
    setImages((prevImages) => prevImages.filter((image) => image !== url));
  }, []);

  const convertToDataURL = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Form.Group controlId="formFileMultiple">
            <Form.Label>Välj bild(er)</Form.Label>
            <Form.Control type="file" multiple onChange={handleImageChange} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        {images.map((url) => (
          <Col key={url} md={3} sm={6}>
            <div style={{ position: "relative" }}>
              <Image src={url} thumbnail style={{height: "120px", objectFit: "contain"}}  />
              <Button
                variant="danger"
                size="sm"
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => handleImageRemove(url)}
              >
                <MdDeleteForever color="#fafafa" />
              </Button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ImageInput;
