import { CardContainer } from "components/cards";
import {
  Table,
  Col,
  Row,
  Form,
  Badge,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { TbBrandMeta } from "react-icons/tb";
import { SiGoogleads, SiLinkedin } from "react-icons/si";
import { useDispatch } from "react-redux";
import { setActiveChannel } from "features/campaign/campaignSlice";
import { BsSend, BsEyeFill, BsFillPlayCircleFill } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import {
  MdOutlineSms,
  MdMailOutline,
  MdQueryStats,
  MdError,
} from "react-icons/md";
import { BiTargetLock } from "react-icons/bi";
import { useMutation, useQueryClient } from "react-query";
import {
  updateCampaignActivityIsActive,
  updateCampaignActivity,
} from "features/campaign/api";
import { CampaignOptions } from "features/campaign/campaign-details/options";
import "../../../style.scss";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import Numeral from "react-numeral";

const OverviewChannelTable = ({ campaign, selectedChannel }) => {
  const ifBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleSelection = (selection) => {
    dispatch(setActiveChannel(selection));
    selectedChannel(selection);
  };

  const { mutate } = useMutation(
    ({ id, activityId, obj }) =>
      updateCampaignActivityIsActive(id, activityId, obj),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );

  const onActiveChange = (e, item) => {
    e.stopPropagation();
    const updatedCampaign = {
      ...item,
      publishAd: item.state === 4 ? true : false,
      isActive: item.isActive ? false : true,
    };

    mutate({
      id: item.campaignID,
      activityId: item.id,
      obj: updatedCampaign,
    });
  };

  const onClickStatistics = (e) => {
    e.stopPropagation();
    handleSelection(CampaignOptions.Statistics);
  };

  //Set leadgeneration to active onPageLoad
  useEffect(() => {
    if (campaign) {
      const lead = campaign.campaignActivities.find(
        (activity) => activity.type === 4
      );

      if (lead) {
        const updatedLeadActivity = {
          ...lead,
          isActive: true,
          enableLeadGeneration: true,
        };

        mutate({
          id: lead.campaignID,
          activityId: lead.id,
          obj: updatedLeadActivity,
        });
      } else {
        const updatedLeadActivity = {
          ...lead,
          isActive: true,
          enableLeadGeneration: true,
        };

        mutate({
          obj: updatedLeadActivity,
        });
      }
    }
  }, []);

  //Publish Meta Ad
  const metaActivity = campaign?.campaignActivities.find(
    (activity) => activity.type === 0
  );

  const {
    mutate: publishMetaAd,
    isLoading: publishLoading,
    isError: publishIsError,
  } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: metaActivity.campaignID },
        { activityId: metaActivity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
      },
    }
  );

  const publishAd = (e) => {
    e.stopPropagation();

    const publishedAd = {
      ...metaActivity,
      publishAd: true,
      isActive: true,
    };

    publishMetaAd(publishedAd);
  };

  const columns = [
    { label: "Kanal", id: 0 },
    { label: "Aktiv", id: 1 },
    { label: "Status", id: 2 },
    { label: "Budget", id: 3 },
    { label: "Visningar", id: 4 },
    { label: "Klick", id: 5 },
    { label: "Konverteringar", id: 6 },
    { label: "Konv.Värde", id: 7 },
    { label: "Spenderat", id: 8 },
    { label: "CTR", id: 9 },
    { label: "", id: 10 },
  ];
  if (!ifBigScreen) {
    columns.splice(3, 7);
    
  }

  return (
    <CardContainer
      style={{ padding: "0px" }}
      children={
        <Table
          id="campaignChannelsTable"
          className="table-container"
          responsive
          hover
          size="lg"
        >
          <thead className="text-sm semi-bold">
            <tr>
              {columns.map((col) => (
                <th key={col.id}>{col.label}</th>
              ))}
            </tr>
          </thead>

          <tbody className="text-md regular">
            {campaign.campaignActivities.map((item) => {
              return (
                <>
                  {item.type !== 4 && (
                    <tr
                      key={item.id}
                      className="campaign-channels-table"
                      onClick={() =>
                        handleSelection(
                          item.type === 0
                            ? CampaignOptions.Meta
                            : item.type === 1
                            ? CampaignOptions.GoogleAds
                            : item.type === 2
                            ? CampaignOptions.Linkedin
                            : item.type === 3
                            ? CampaignOptions.SMS
                            : item.type === 4
                            ? CampaignOptions.LeadGeneration
                            : item.type === 5
                            ? CampaignOptions.Email
                            : item.type === 6
                            ? CampaignOptions.DirectMarketing
                            : item.type === 7
                            ? CampaignOptions.TikTok
                            : CampaignOptions.Statistics
                        )
                      }
                    >
                      <td>
                        <Row
                          className={`d-flex justify-content-evenly text-md align-items-center semi-bold my-2`}
                          style={{ color: "#101828" }}
                        >
                          {item.type === 0 ? (
                            <>
                              <Row className="align-items-center g-1"> 
                                <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0"> 
                                  <TbBrandMeta color="#216FE4" size={20} />
                                </Col>
                                <Col sm xs={12}  className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0"> 
                                  Meta
                                </Col>
                              </Row>
                            </>
                          ) : item.type === 1 ? (
                            <>
                            <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12} className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                                <SiGoogleads size={20} />
                              </Col>
                              <Col sm xs={12}  className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">Google Ads</Col>
                              </Row>
                            </>
                          ) : item.type === 2 ? (
                            <>
                              <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                                <SiLinkedin
                                  size={20}
                                  color="rgb(10, 102, 194)"
                                />
                              </Col>
                              <Col sm xs={12}  className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">LinkedIn</Col>
                              </Row>
                            </>
                          ) : item.type === 3 ? (
                            <>
                            <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                                <MdOutlineSms color="#017397" size={20} />
                              </Col>
                              <Col sm xs={12}  className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">SMS</Col>
                              </Row>
                            </>
                          ) : item.type === 4 ? (
                            <>
                            <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                                <BiTargetLock color="#F7DDBD" size={20} />
                              </Col>
                              <Col sm xs={12}  className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">Leads</Col>
                              </Row>
                            </>
                          ) : item.type === 5 ? (
                            <>
                            <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                                <BsSend color="#017397" size={20} />
                              </Col>
                              <Col sm xs={12} className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">Email</Col>
                              </Row>
                            </>
                          ) : item.type === 6 ? (
                            <>
                            <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0">
                                <MdMailOutline color="#017397" size={20} />
                              </Col>
                              <Col sm xs={12} className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">DR</Col>
                              </Row>
                            </>
                          ) : item.type === 7 ? (
                            <>
                            <Row className="align-items-center g-1">
                              <Col md sm={12} xs={12}  className="d-flex justify-content-center justify-content-sm-start">
                                <FaTiktok color="#000" size={20} />
                              </Col>
                              <Col sm xs={12}  className="d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">TikTok</Col>
                              </Row>                         
                            </>
                          ) : (
                            ""
                          )}
                        </Row>
                      </td>
                      {/* STATUS-------------------------------- */}
                      <td>
                        {item.type === 0 ||
                        item.type === 1 ||
                        item.type === 2 ||
                        item.type === 4 ||
                        item.type === 7 ? (
                          <Row
                            className="d-flex text-md semi-bold align-items-center my-2"
                            style={{ color: "#101828" }}
                          >
                            {item.type === 0 || item.type === 2 ? (
                              <>
                                {item.state === 2 || item.state === 4 ? (
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    className="status-switch"
                                    defaultChecked={item.isActive}
                                    onClick={(e) => onActiveChange(e, item)}
                                  />
                                ) : item.state === 5 ? (
                                  <BsEyeFill
                                    className="eye-logo"
                                    style={{ paddingLeft: 0 }}
                                    size={18}
                                    color="rgb(232 224 203)"
                                  />
                                ) : item.state === 1 ? (
                                  <Button
                                    onClick={(e) => publishAd(e)}
                                    variant={
                                      publishIsError ? "warning" : "success"
                                    }
                                    size="sm"
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: 500,
                                      width: "fit-content",
                                      marginLeft: 0,
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    <div className="d-flex align-items-center g-2 flex-nowrap justify-content-around">
                                      <div className="col col-10">
                                        <p>Publicera</p>
                                      </div>
                                      <div className="col col-1">
                                        {publishLoading ? (
                                          <Spinner size="sm" />
                                        ) : publishIsError ? (
                                          <MdError />
                                        ) : (
                                          <BsFillPlayCircleFill />
                                        )}
                                      </div>
                                    </div>
                                  </Button>
                                ) : item.state === 0 ? (
                                  <p
                                    className="m-0 p-0"
                                    style={{
                                      color: "lightgrey",
                                      fontSize: "10px",
                                    }}
                                  >
                                    Ej redo att aktivera
                                  </p>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : item.state === 0 && item.type !== 4 ? (
                              <p
                                className="m-0 p-0"
                                style={{ color: "lightgrey", fontSize: "10px" }}
                              >
                                Ej redo att aktivera
                              </p>
                            ) : (
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                className="status-switch"
                                defaultChecked={item.isActive}
                                // onClick={(e) => onActiveChange(e, item)}
                              />
                            )}
                          </Row>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              className={
                                item.state === 0
                                  ? "danger"
                                  : item.state === 1
                                  ? "warning"
                                  : item.state === 2
                                  ? "success"
                                  : "secondary"
                              }
                            >
                              {item.state === 0
                                ? "Inga ändringar har gjorts för kanalen. Klicka här för att börja!"
                                : item.state === 1
                                ? "Kanalen har börjat skapas men är ännu inte helt redo för att sättas igång. Klicka här för att fortsätta göra inställningar!"
                                : item.state === 2
                                ? "Kanalen är igång & körs i detta nu. Pausa kanalen genom knappen till vänster."
                                : "Kanalen är redo att köras men är just nu pausad. Aktivera kanalen genom knappen till vänster."}
                            </Tooltip>
                          }
                        >
                          {item.type === 0 || item.type === 2 ? (
                            <>
                              {statusBadgeSetter(
                                campaign.campaignActivities.find((activity) => {
                                  if (
                                    activity.type === 0 &&
                                    activity.id === item.id
                                  ) {
                                    return activity;
                                  }
                                  if (
                                    activity.type === 2 &&
                                    activity.id === item.id
                                  ) {
                                    return activity;
                                  }
                                })
                              )}
                            </>
                          ) : (
                            <>
                              {item.state === 0 ? (
                                <Badge pill bg="danger">
                                  Ej påbörjad
                                </Badge>
                              ) : item.state === 1 ? (
                                <Badge pill bg="warning">
                                  Påbörjad
                                </Badge>
                              ) : item.state === 2 ? (
                                <Badge pill bg="success">
                                  Aktiv
                                </Badge>
                              ) : (
                                <Badge pill bg="secondary">
                                  Pausad
                                </Badge>
                              )}
                            </>
                          )}
                        </OverlayTrigger>
                      </td>
                      {ifBigScreen && (
                        <>
                          {item.statistics ? (
                            <>
                              <td>{item.statistics.budget}</td>
                              <td>
                                <Numeral
                                  className="numeral"
                                  value={item.statistics.exposures}
                                  format={"0,0"}
                                />
                              </td>
                              <td>{item.statistics.clicks}</td>
                              <td>{item.statistics.conversions}</td>
                              <td>
                                <Numeral
                                  className="numeral"
                                  value={item.statistics.conversionValue}
                                  format={"0,0"}
                                />
                              </td>
                              <td>{item.statistics.budgetSpent}</td>
                              <td>
                                {item.statistics.ctr.toString().substring(0, 4)}{" "}
                                %
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )}
                      <td>
                        <Button
                          variant="link"
                          className="campaign-statistics-btn"
                          onClick={onClickStatistics}
                        >
                          <MdQueryStats color="#017397" size={18} /> Visa
                          statistik
                        </Button>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      }
    />
  );
};

export default OverviewChannelTable;
