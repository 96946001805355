import "../style.scss";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { Form, Spinner, Col, Row, InputGroup, Button } from "react-bootstrap";
import { ButtonMain } from "components/buttons";
import { resetPassword } from "features/authentication/api";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { BsSendCheck } from "react-icons/bs";
import { useBeforeUnload } from "react-router-dom";
import Cookies from "js-cookie";
import { useParams, useLocation } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { FiCheckCircle } from "react-icons/fi";
import { removeSession } from "context/sessionSlice";

const ResetPasswordForm = ({ errorMessage, setErrorMessage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [passwordIsReset, setPasswordIsReset] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordApproved, setPasswordApproved] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  //Credential input States
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  // In your component
  const query = useQuery();
  const email = query.get("email");
  const token = query.get("token");
  // State variables to hold email and token
  const [resetEmail, setResetEmail] = useState(query.get("email") || "");
  const [resetToken, setResetToken] = useState(query.get("token") || "");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  // useEffect(() => {
  //   if (isValid) {
  //     setPasswordError("");
  //   }
  //   // else {
  //   //   setPasswordError("Lösenordet är inte korrekt");
  //   // }
  // }, [passwordConfirm]);

  const validatePassword = (password, passwordConfirm) => {
    // length <= 8
    if (password.length < 8) {
      setPasswordError("Lösenord måste innehålla minst 8 tecken");
      setPasswordApproved(false);
      return false;
    }
    // at least a letter
    else if (!/[a-z]/.test(password)) {
      setPasswordError("Lösenord måste innehålla minst 1 alfabetiskt tecken");
      setPasswordApproved(false);
      return false;
    }
    // at least a letter
    else if (!/[A-Z]/.test(password)) {
      setPasswordError("Lösenord måste innehålla minst en stor bokstav");
      setPasswordApproved(false);
      return false;
    }
    // digit
    else if (!/[0-9]/.test(password)) {
      setPasswordError("Lösenord måste innehålla minst en siffra");
      setPasswordApproved(false);
      return false;
    }
    // special character
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPasswordError("Lösenordet måste innehålla minst ett specialtecken");
      setPasswordApproved(false);
      return false;
    } else if (password !== passwordConfirm) {
      setPasswordError("Lösenorden måste matcha");
      setPasswordApproved(false);
      return false;
    } else {
      setPasswordError("");
      setPasswordApproved(true);
      return true;
    }
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsValid(validatePassword(newPassword));
  };

  const handlePasswordConfirmChange = (event) => {
    const newPassword = event.target.value;
    setPasswordConfirm(newPassword);
    setIsValid(validatePassword(password, newPassword));
  };

  useEffect(() => {
    setResetEmail(email || "");
    setResetToken(token || "");
  }, [query]);

  const resetPasswordMutation = useMutation((email) => resetPassword(email), {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);
      setPasswordIsReset(true);
      setTimeout(() => {
        dispatch(removeSession());
        navigate("/login");
      }, 5000);
    },
    onError: (error) => {
      setLoading(false);
      setIsError(true);
      console.log({ error }, error.response.data.errors.passwordConfirm);
    },
    onSettled: () => {
      setLoading(false);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = {
      email: resetEmail,
      token: resetToken,
      password: password,
      confirmPassword: passwordConfirm,
    };

    resetPasswordMutation.mutate(obj);
  };

  useBeforeUnload(() => {
    dispatch(removeSession());
  });

  return (
    <Row className="p-3 flex-column align-items-center">
      {passwordIsReset ? (
        <>
          <Col className="col-12 d-flex text-center justify-content-center mb-5">
            <BsSendCheck size={80} color="green" />
          </Col>
          <Col className="col-12 d-flex text-center justify-content-center">
            <h3 className="display-md">
              Ditt lösenord har nu ändrats och du diregeras vidare till inloggningssidan!
            </h3>
          </Col>
        </>
      ) : (
        <>
          {" "}
          <Col className="col-sm-8 mb-3">
            <h3 className="display-lg semi-bold">Sätt nytt lösenord</h3>
            {/* <p className="text-md regular">Fyll i din mail</p> */}
          </Col>
          <Col className="col-sm-8">
            <Form onSubmit={handleSubmit}>
              <Col>
                <label>
                  Lösenord {passwordApproved && <FiCheckCircle color="green" />}
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder=""
                    aria-label="password"
                    aria-describedby="basic-addon1"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={toggleShowPassword}
                  >
                    {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </Button>
                </InputGroup>
              </Col>
              <Col>
                <label>
                  Bekräfta lösenord{" "}
                  {passwordApproved && <FiCheckCircle color="green" />}
                </label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder=""
                    aria-label="Namn"
                    aria-describedby="basic-addon1"
                    type={showPassword ? "text" : "password"}
                    value={passwordConfirm}
                    onChange={handlePasswordConfirmChange}
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={toggleShowPassword}
                  >
                    {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </Button>
                </InputGroup>
              </Col>

              <Col style={{ paddingBottom: "10px", color: "red" }}>
                {passwordError && <div>{passwordError}</div>}
              </Col>
              <Col
                className={styles.passwordRequirements}
                style={{ paddingTop: "20px" }}
              >
                <div className="">
                  <p>- Lösenordet ska innehålla minst 8 tecken</p>
                  <p>- Lösenordet ska innehålla minst en stor bokstav</p>
                  <p>- Lösenordet ska innehålla minst ett nummer</p>
                  <p>- Lösenordet ska innehålla minst ett specialtecken</p>
                </div>
              </Col>

              {loading ? (
                <Row className="justify-content-center">
                  <Spinner />
                </Row>
              ) : (
                <Row className="gx-2">
                  <Col className="col-12 mb-3">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Button
                        variant="info"
                        className="w-100"
                        type="submit"
                        disabled={!isValid}
                      >
                        Sätt nytt lösenord
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </>
      )}
    </Row>
  );
};

export default ResetPasswordForm;
