export const segmentType = (type) => (type ? "personer" : "företag");

export const segmentStatuses = {
  Draft: -1,
  Calculating: 0,
  Ready: 1,
  Invalid: 2,
  Scoring: 3,
};
export const segmentStatuses_sv = {
  [segmentStatuses.Draft]: { variant: "dark", text: "Utkast" },
  [segmentStatuses.Calculating]: { variant: "warning", text: "Kalkylerar..." },
  [segmentStatuses.Scoring]: { variant: "warning", text: "Kalkylerar..." },
  [segmentStatuses.Ready]: { variant: "success", text: "Redo" },
  [segmentStatuses.Invalid]: { variant: "danger", text: "Ogiltig" },
};

export const segmentTypes = { Company: 0, Person: 1, VehicleOwner: 2 };
export const segmentTypes_sv = {
  [segmentTypes.Person]: { text: "Person" },
  [segmentTypes.Company]: { text: "Företag" },
  [segmentTypes.VehicleOwner]: { text: "Fordonsägare" },
};
