import { CardContainer } from "components/cards";
import React, { useState } from "react";
import { Form, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import {
  CampaignActivityState,
  CampaignActivityTypes,
  CampaignOptions,
} from "../../../options";
import Agent from "api/Agent";

export default function ModifyCampaignChannelTable({ campaign }) {
  const { mutate: updateCampaign, isLoading } = Agent.Campaigns.PUT.CAMPAIGN();
  const [updatedChannel, setUpdatedChannel] = useState(null);

  const handleChecked = (checked, prop) => {
    const updatedCampaign = { ...campaign };
    updatedCampaign[prop] = checked;
    if (prop.toLowerCase().includes("facebook"))
      updatedCampaign["enableInstagram"] = checked;

    setUpdatedChannel(prop)
    updateCampaign(updatedCampaign, { onSettled: () => { setUpdatedChannel(null) } });
  };

  const channels = Object.entries(campaign).filter((prop) =>
    prop[0].toLowerCase().includes("enable")
  );

  const availableChannels = channels
    .filter((channel) => !channel[0].includes("Instagram"))
    .filter((channel) =>
      Object.keys(CampaignOptions).filter((option) =>
        channel[0].toLowerCase().includes(option.toLowerCase())
      )
    );

  const getActivityState = (option) =>
    campaign?.campaignActivities?.find((_activity) =>
      CampaignActivityTypes[_activity?.type]?.includes(option)
    )?.state;

  const isDisabled = (option) => {
    if (getActivityState(option)) return true;
    return false;
  };

  const getOptionLabel = (property) => {
    const label = Object.entries(CampaignOptions).find((option) =>
      property.toLowerCase().includes(option[0].toLowerCase())
    ) &&
      Object.entries(CampaignOptions).find((option) =>
        property.toLowerCase().includes(option[0].toLowerCase())
      )[1];

    return <div className="d-flex flex-row justify-content-between align-items-center gap-1">

      {

        property === updatedChannel && isLoading &&
        <Spinner size="sm" />
      }
      <span>{label}</span>
    </div>
  }

  return (
    <CardContainer
      style={{ padding: "0" }}
      className="h-100"
      children={
        <Table
          id="campaignChannelsTable"
          className="table-container"
          responsive
          hover
          size="lg"
        >
          <thead></thead>

          <tbody>
            {availableChannels?.map((channel, index) => (
              <>
                {channel[0] !== "enableLeadGeneration" && (
                  <tr key={index}>
                    <td>{getOptionLabel(channel[0])}</td>

                    <td>
                      {isDisabled(getOptionLabel(channel[0])) ? (
                        <OverlayTrigger
                          key={channel[0]}
                          placement={"left"}
                          overlay={
                            <Tooltip id={`tooltip-disabled`}>
                              Kanalen är{" "}
                              {
                                CampaignActivityState[
                                getActivityState(getOptionLabel(channel[0]))
                                ]
                              }
                            </Tooltip>
                          }
                        >
                          <div>
                            <Form.Check
                              type="switch"
                              defaultChecked={channel[1]}
                              disabled
                              style={{ pointerEvents: "none" }}
                              className="status-switch form-switch"
                            />
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <Form.Check
                          type="switch"
                          checked={channel[1]}
                          className="status-switch form-switch"
                          disabled={isLoading}
                          onChange={(e) =>
                            handleChecked(e.target.checked, channel[0])
                          }
                        />
                      )}
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
      }
    />
  );
}
