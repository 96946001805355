import { Row, Col } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";

const EditTargetGroup = ({ campaign, segment }) => {
  return (
    <Row>
      <Col className="col-md-4 regular">
        <p>Målgrupp</p>
      </Col>
      <Col>
        <p style={{ color: "#545F61" }}>
          {campaign?.autoSegment
            ? "Automatisk"
            : !campaign?.autoSegment && segment
            ? segment.name
            : "-"}
        </p>
      </Col>
      <Col className="col-2 edit-col-icon">
        <AiFillLock color="rgb(212 212 212)" />
      </Col>
    </Row>
  );
};

export default EditTargetGroup;
