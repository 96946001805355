import { Popover, OverlayTrigger } from "react-bootstrap";
import { BsQuestionCircleFill } from "react-icons/bs";

const CampaignTooltip = ({ selectedChannel }) => {
  const channelTooltips = [
    {
      name: "Översikt",
      label: "Översiktstabell",
      body: "Här ser du alla kanaler du valt att använda till din kampanj. Här kan du välja att pausa/starta din färdigbyggda kampanj, se vilken status en specifik kanal har för tillfället och lägga till nya kanaler.",
    },
    {
      name: "Statistik",
      label: "Statistik",
      body: "Här ser du all statistik kopplat till din kampanj. Välj att se generell statistik för hela kampanjen, eller per kanal. Statistik börjar först visas när någon kanal har startats igång och det finns statistik att visa!",
    },
    {
      name: "Meta",
      label: "Meta",
      body: "",
    },
    {
      name: "Google",
      label: "Google",
      body: "",
    },
    {
      name: "SMS",
      label: "SMS",
      body: "",
    },
    {
      name: "Email",
      label: "Email",
      body: "",
    },
    {
      name: "Leads",
      label: "Leads",
      body: "",
    },
    {
      name: "Direktreklam",
      label: "Direktreklam",
      body: "",
    },
  ];

  const channel = channelTooltips.find(
    (channel) => channel.name === selectedChannel
  );

  const popoverContent = channel ? (
    <Popover style={{ inset: "0px auto 50px 0px !important", width: "1000px" }}>
      <Popover.Header as="h3">{channel.label}</Popover.Header>
      <Popover.Body>{channel.body}</Popover.Body>
    </Popover>
  ) : (
    <div></div>
  );

  return (
    <OverlayTrigger placement="bottom-end" overlay={popoverContent}>
      <div>
        <BsQuestionCircleFill className="question-mark" size={25} />
      </div>
    </OverlayTrigger>
  );
};

export default CampaignTooltip;
