import { Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { GoogleMapMain } from "components/maps";
import ContactForm from "../components/ContactForm";
import LeadTimeLine from "../components/LeadTimeLine";
import { useMediaQuery } from "react-responsive";

const LeadPersonOverview = ({ leadId, setBgBlur, lead, leadActivities }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' })
  const year = lead?.jObject.basic.data.pnr.substring(0, 4);
  const month = lead?.jObject.basic.data.pnr.substring(4, 6);
  const day = lead?.jObject.basic.data.pnr.substring(6, 8);
  const today = new Date();
  var age = today.getFullYear() - year;
  if (
    today.getMonth() < month ||
    (today.getMonth() === month && today.getDate() < day)
  ) {
    age--;
  }

  return (
    <Row className="gy-5 " >
      {/* DESCRIPTION and MAP------------------- */}
      <Col className={`${isSmallScreen ? "col-12": "col-6"} p-0`} >
        <Row className="g-4">
          <Col className="col-12">
            <CardContainer
              padding
              h100
              children={
                <Row className="h-100">
                  <Col className="col-12">
                    <Row className="g-2">
                      <Col className="col-12">
                        <h3 className="display-sm semi-bold">Beskrivning</h3>
                        <p className="text-xl regular">
                          <strong>{`${lead.jObject.basic.data.name.first_name} ${lead.jObject.basic.data.name.last_name}`}</strong>
                          , {`${age} år`}, bor i en{" "}
                          {`${lead.jObject.residence.data.living_area}kvm stor`}{" "}
                          <strong>
                            {lead.jObject.residence.data.type?.string &&
                              lead.jObject.residence.data.type?.string}
                          </strong>{" "}
                          {`på addressen ${lead.jObject.basic.data.address.name}, ${lead.jObject.basic.data.address.zip_code} i `}
                          <strong>
                            {lead.jObject.basic.data.address.city}
                          </strong>
                          .
                          <strong>{` ${lead.jObject.basic.data.name.first_name}`}</strong>{" "}
                          {lead.jObject.phoneNumbers.data.length > 0
                            ? `har följande telefonnummer eller mobilnummer registrerat i sitt namn: ${lead.jObject.phoneNumbers.data.map(
                                (num) => "+" + num.attributes.phonenumber
                              )}`
                            : "har inga telefonnummer registrerade i sitt namn."}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="col-12 mt-4">
                    <GoogleMapMain
                      address={lead.jObject.basic.data.address.name}
                      height="100%"
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col className="col-12">
            <ContactForm person leadId={leadId} lead={lead} />
          </Col>
        </Row>
      </Col>
      {/* CONTACTFORM------------------- */}

      {/* TIMELINE------------------- */}
      <Col className={`${isSmallScreen ? "col-12": "col-6"}`} style={{ overflowY: "auto" }}>
        <LeadTimeLine
          leadId={leadId}
          leadActivities={leadActivities}
          setBgBlur={setBgBlur}
          lead={lead}
        />
      </Col>
    </Row>
  );
};

export default LeadPersonOverview;
