import { CardContainer } from "components/cards";
import { CampaignTable } from "features/campaign";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { segmentType } from "../assets/config";

export default function Overview({ segment }) {
  return (
    <Row className="gy-2 m-0 row-cols-1 ">
      <Col className="h-100 col-7">
        <Row className="flex-column g-5">
          <Col>
            <h3 className="display-sm semi-bold mb-3">
              Kampanjer skapade på målgrupp
            </h3>

            <div className="d-flex flex-column row-cols-1">
              {segment?.campaignsID?.length ? (
                <CampaignTable
                  withSegmentID={segment?.id}
                  rows={5}
                />
              ) : (
                <span className="text-md regular">
                  Ingen kampanj använder denna målgrupp. Skapa en kampanj riktad
                  mot denna målgrupp
                </span>
              )}
            </div>
          </Col>
        </Row>
      </Col>

      <Col className="h-100 col-5">
        <Row className="h-100">
          <Col className="h-100">
            <h3 className="display-sm semi-bold mb-3">Allmänt</h3>

            <Row className="flex-column row-cols-2 g-2">
              <Col>
                <span className=" bold">Storlek:</span>
                <span className="px-1">
                  {segment?.size} {segmentType(segment?.type)}
                </span>
              </Col>
              <Col>
                <span className=" bold">Egen lista:</span>
                <span className="px-1">
                  {segment?.customListID ? "Ja" : "Nej"}{" "}
                </span>
              </Col>
              <Col>
                <span className=" bold">Typ:</span>
                <span className="px-1">
                  {segment?.type === 0 ? "Företag" : "Personer"}{" "}
                </span>
              </Col>
              <Col>
                <span className=" bold">AI-genererad:</span>
                <span className="px-1">
                  {segment?.isAiGenerated ? "Ja" : "Nej"}{" "}
                </span>
              </Col>

            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
