import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";

export const DataExports = {
  GET: {
    ALL: (page, limit, segmentID) => {
      const queryClient = useQueryClient();
      const queryParams = [
        page && `page=${page}`,
        limit !== undefined && `limit=${limit}`,
        segmentID && `segmentID=${segmentID}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: async () => axios.get(`DataExports/v1${queryString}`).then(({ data }) => data),
        queryKey: ["dataExports", queryString],
        retry: false,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: false,
        refetchInterval: (query) => {

          const IS_NEW_EXPORT = query?.data?.some(e => e?.state == 0)
          return IS_NEW_EXPORT ? 1000 * 5 : false
        },
        onError: (error) => {
          console.warn(`Failed to GET dataExports \n Error: `, error);
        },
      });
    },
    ID: (id) => {
      const queryClient = useQueryClient();

      return useQuery({
        queryFn: async () => axios.get(`DataExports/v1/${id}`).then(({ data }) => data),
        queryKey: ["dataExports", id],
        enabled: !!id,
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onError: (error) => {
          console.warn(`Failed to GET dataExport \n Error: `, error);
        },
      });
    },

    ID_NO_QUERY: async (id) => {
      return await axios.get(`DataExports/v1/${id}`).then(({ data }) => data);
    },

  },
  POST: {
    DATAEXPORT: () => {
      const queryClient = useQueryClient();
      return useMutation((dataExport) => axios.post(`DataExports/v1/`, dataExport), {
        onSuccess: () => {
          queryClient.invalidateQueries("dataExports");
        },
      });
    },
  },
}
