import { Row, Col, Form } from "react-bootstrap";
import React, { useState } from "react";
import { FiCheck } from "react-icons/fi";

const ActivityItem = (props) => {

  const style = {
    col: {
      border: "1px solid #EAECF0",
      borderRadius: "8px",
      cursor: "pointer",
    },
    colChecked: {
      background: "rgba(230, 236, 233, 0.49)",
      borderRadius: "8px",
      border: "1px solid #6C9384",
    },
    iconCol: {
      background: "#E6ECE9",
      borderInline: "6px solid rgba(230, 236, 233, 0.49)",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      color: "#2D6450",
    },
    roundedCircle: {
      borderRadius: "50%",
      border: "1px solid #D0D5DD",
      width: "20px",
      height: "20px",
    },
    roundedCircleChecked: {
      borderRadius: "50%",
      background: "#2D6450",
      width: "20px",
      height: "20px",
    },
  };

  const handleColClick = (id) => {
    props.setCheckedId(id);
  };

  return (
    <Col
      className="col-12"
      style={props.checkedId === props.id ? style.colChecked : style.col}
      key={props.id}
      onClick={() => handleColClick(props.id)}
    >
      <Row className="p-2 justify-content-between">
        <Col>
          <Row>
            <div
              style={style.iconCol}
              className="d-flex justify-content-center align-items-center p-0"
            >
              {props.icon}
            </div>
            <Col>
              <p className="text-lg semi-bold">{props.name}</p>
            </Col>
          </Row>
        </Col>

        <Col>
          <Form.Check
            type="checkbox"
            checked={props.checked === props.id ? true : false}
            className="d-flex justify-content-end align-items-center p-0"
          >
            <div
              style={
                props.checkedId === props.id
                  ? style.roundedCircleChecked
                  : style.roundedCircle
              }
              className="d-flex justify-content-center align-items-center"
            >
              {props.checkedId === props.id && (
                <span>
                  <FiCheck color="#fff" />
                </span>
              )}
            </div>
          </Form.Check>
        </Col>
      </Row>
    </Col>
  );
};

export default ActivityItem;
