import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import { AiFillEdit, AiFillLock } from "react-icons/ai";
import { FaSave } from "react-icons/fa";

const EditDateTime = ({ activity, updateAd, updateAdLoading }) => {
  //Startdate--------------
  const [showStartDateInput, setShowStartDateInput] = useState(false);
  const [newStartDate, setNewStartDate] = useState(
    activity?.ad?.startDate ? activity.ad.startDate : ""
  );

  const updateStartDate = () => {
    const patchObj = [
      {
        op: "replace",
        path: "/startDate",
        value: newStartDate,
      },
    ];
    updateAd(patchObj);
    setShowStartDateInput(false);
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const today = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return (
    <>
      <Row
        className={`${showStartDateInput ? "edit-row-active mb-3" : "mb-3"}`}
      >
        <Col className="col-md-4 regular">
          <p>Tid för utskick</p>
        </Col>
        {showStartDateInput ? (
          <Col>
            <Form>
              <Form.Control
                type="datetime-local"
                defaultValue={newStartDate}
                onChange={(e) => setNewStartDate(e.target.value)}
              />
            </Form>
          </Col>
        ) : (
          <Col>
            {updateAdLoading ? (
              <Spinner />
            ) : (
              <p style={{ color: activity.ad.startDate < today ? "#cc6249" : "#545F61" }}>
                {activity?.ad?.startDate?.substring(0, 10)}
                <span style={{ fontWeight: 600, marginLeft: "15px" }}>
                  {activity?.ad?.startDate?.substring(11, 16)}
                </span>
              </p>
            )}
          </Col>
        )}

        {showStartDateInput ? (
          <Col className="col-2 edit-col-icon" onClick={updateStartDate}>
            <FaSave color="green" />
          </Col>
        ) : activity.state === 0 ||
          activity.state === 1 ||
          activity.state === 4 ? (
          <Col
            className="col-2 edit-col-icon"
            onClick={() => setShowStartDateInput(true)}
          >
            <AiFillEdit />
          </Col>
        ) : (
          <Col className="col-2 edit-col-icon">
            <AiFillLock color="rgb(212 212 212)" />
          </Col>
        )}
      </Row>
    </>
  );
};

export default EditDateTime;
