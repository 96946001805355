
import { Container } from "react-bootstrap";
import { useState, useEffect, createContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateCampaignActivity } from "features/campaign/api";
import { useSelector, useDispatch } from "react-redux";
import { updateDrObject } from "../drSlice";
import DRStep1 from "./DRStep1";
import DRStepper from "./DRStepper";
import DRStep2 from "./DRStep2";
import DRStep3 from "./DRStep3";
import { useRef } from "react";

export const DRContext = createContext();

const DRWizard = ({ campaign, activity, campaignIsLoading, }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [render, setRender] = useState([]);
  const FormRef = useRef(undefined);
  const dispatch = useDispatch();
  const drObject = useSelector((state) => state.directAd.drObject);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    (newCampaign) =>
      updateCampaignActivity(
        { id: activity.campaignID },
        { activityId: activity.id },
        newCampaign
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns");
        queryClient.invalidateQueries("campaign");
        dispatch(
          updateDrObject({
            ...drObject,
            generateQrCode: false,
          })
        );
      },
    }
  );

  const steps = [
    {
      step: 0,
      children: (

        <DRStep1
          campaign={campaign}
          activity={activity}
          campaignIsLoading={campaignIsLoading}
        />

      ),
    },
    {
      step: 1,
      children: (

        <DRStep2 campaign={campaign} activity={activity} ref={FormRef} />

      ),
    },
  ];

  const step = steps.find((step) => step.step === activeStep)?.children;

  return (
    <DRContext.Provider value={{ render: render, setRender: setRender }}>
      <div
        className="position-relative overflow-hidden h-100"
        style={{ display: "grid", gridTemplateRows: "auto-track-list" }}
      >
        <Container
          style={{ padding: "20px 40px 80px 40px", overflow: "auto" }}
        >
          {step && step}
        </Container>
        <DRStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          activity={activity}
          steps={steps}
          mutate={mutate}
          FormRef={FormRef}
        />
      </div>
    </DRContext.Provider>
  );
};

export default DRWizard;
