import axios from "api/axios";
import { endPoints } from "api/endPoints";

const OVERVIEW_URL = endPoints.overview.OVERVIEW_URL;
const VISUALS_URL = endPoints.visuals.URL

// export const getLeads = async () => {
//   const response = await axios.get(CUSTOMERS_URL);
//   return response.data;
// };

export const getOverviewStats = async (fromDate, endDate) => {
  return await axios.get(`${OVERVIEW_URL}?from=${fromDate}&to=${endDate}`);
};

export const getLeadStats = async (fromDate, endDate) => {
  return await axios.get(`${VISUALS_URL}overview/leads/data`);
};
export const getCampaignStats = async (fromDate, endDate) => {
  return await axios.get(`${VISUALS_URL}overview/campaigns/data`);
};



