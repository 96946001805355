import Agent from "api/Agent";
import { useState } from "react";
import { Button, Form, Row, Stack } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";

function FilterTypeSelect({ filterType }) {
  const putFilterMutation = Agent.Filters.PUT.FILTER();
  const postFilterMutation = Agent.Filters.POST.FILTER();
  const deleteFilterMutation = Agent.Filters.DEL.FILTER();
  const isLoading = putFilterMutation.isLoading || postFilterMutation.isLoading;

  const [filter, setFilter] = useState(undefined);
  const [select, setSelect] = useState(undefined);

  const handleSelect = (select) => {
    setSelect(select)

    const selectedFilter = select?.value?.at(2)
    setFilter(selectedFilter)
  }

  const options = [
    {
      value: [],
      label: <Row >
        <span className="semi-bold"><strong>Skapa: </strong>Nytt filter</span>
      </Row>,
    },
    filterType?.filters && {
      label: `Valbara filter, ${filterType?.filters?.length}st`, options: filterType?.filters?.map(x => ({
        label:
          <Row >
            <span className="semi-bold"><strong>Label: </strong>{x?.name}</span>
            <span className="semi-bold"><strong>Query: </strong>{x?.query}</span>
          </Row>,
        value: [x?.name, x?.query, x]
      }))
    }]
  const filtersId = filterType?.filters?.filter(x => x?.id).map(x => x?.id)

  const handleSubmit = () => {
    const request = { ...filter, filterTypeID: filterType?.id }

    if (filter?.id) toast.promise(putFilterMutation.mutateAsync(request, { onSuccess: () => setSelect(options[0]) }), { success: "Uppdaterat filter", loading: "...", error: "Ett fel uppstod, kunde inte uppdatera filter" })
    else toast.promise(postFilterMutation.mutateAsync(request, { onSuccess: () => setSelect(options[0]) }), { success: "skapat filter", loading: "...", error: "Ett fel uppstod, kunde inte uppdatera filter" })
  }
  const handleDelete = (id) => {
    toast.promise(deleteFilterMutation.mutateAsync(id, { onSuccess: () => { setSelect(options[0]); setFilter(undefined) } }), { success: "filter borttaget", loading: "...", error: "Ett fel uppstod, kunde inte ta bort filter" })
  }

  return <Row className="row-cols-1 gy-1 ">
    <span className="semi-bold text-lg">Filter</span>

    <Select
      className="basic-single "
      classNamePrefix="select"
      isSearchable={true}
      name="color"
      options={options}

      placeholder={`Valbara filter, ${filterType?.filters?.length}st`}
      value={select}
      onChange={(e) => handleSelect(e)}
    />

    <span className="semi-bold text-lg py-2">Ändra/skapa</span>
    <Stack>
      <Row className="row-cols-2 gy-1">

        <Form.Group >
          <Form.Label>ID</Form.Label>
          <Form.Select disabled className="text-md" value={filter?.id ?? ""}  >
            <option value="">Nytt filter</option>
            {filtersId?.map(id => <option key={id} value={id}>{id}</option>)}

          </Form.Select>
        </Form.Group>

        <Form.Group >
          <Form.Label>Default</Form.Label>
          <Form.Control disabled type="text" className="text-md" value={!!filter?.default ? filter?.default : "Nej"} />
        </Form.Group>

        <Form.Group >
          <Form.Label>Namn</Form.Label>
          <Form.Control type="text" className="text-md" value={filter?.name ?? ""} onChange={e => setFilter(prev => ({ ...prev, name: e.target.value }))} />
        </Form.Group>

        <Form.Group >
          <Form.Label>Query</Form.Label>
          <Form.Control type="text" className="text-md" value={filter?.query ?? ""} onChange={e => setFilter(prev => ({ ...prev, query: e.target.value }))} />
        </Form.Group>

      </Row>
      <Row className="justify-content-between row-cols-auto mx-1 mt-2">
        <Button disabled={deleteFilterMutation.isLoading || !filter?.id} variant="danger" type="button" onClick={() => handleDelete(filter?.id)}>Ta bort</Button>
        <Button disabled={isLoading} variant="success" type="button" onClick={() => handleSubmit()}>{filter?.id ? "Uppdatera filter" : "Skapa nytt filter"}</Button>
      </Row>
    </Stack>




  </Row>
}

export default FilterTypeSelect
