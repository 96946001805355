import { Col, Row } from "react-bootstrap";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import EditTikTokSettings from "../components/EditTikTokSettings";
import MobilePreview from "features/campaign/campaign-details/components/MobilePreview";
import { forwardRef } from "react";

const TikTokStep2 = forwardRef(
  (
    {
      activity,
      campaign,
      tikTokObject,
      setTikTokObject,
      selectedMedia,
      setSelectedMedia,
      mutate,
      segment,
      adBannerPreview,
    },
    ref
  ) => {
    return (
      <Row className="justify-content-between">
        <Col className="col-12">
          <Row>
            <Col className="col-12">
              <Row className="justify-content-between">
                <Col>
                  <h4 className="display-sm semi-bold">
                    Inställningar för TikTok-kampanj
                  </h4>
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  {statusBadgeSetter(activity)}
                </Col>
              </Row>
            </Col>
          </Row>
          <EditTikTokSettings
            campaign={campaign}
            mutate={mutate}
            activity={activity}
            tikTokObject={tikTokObject}
            setTikTokObject={setTikTokObject}
            segment={segment}
            ref={ref}
          />
        </Col>

        {/* <Col className="d-flex align-items-center justify-content-center">
          <MobilePreview
            activity={activity}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
            adBannerPreview={adBannerPreview ? adBannerPreview : null}
          />
        </Col> */}
      </Row>
    );
  }
);

export default TikTokStep2;
