import styles from "../../../../style.module.css";
import { Button, Col, Row, Container, Spinner } from "react-bootstrap";
import { BsArrowRight, BsArrowLeft, BsEnvelopePlus } from "react-icons/bs";
import Stepper from "components/stepper/stepper";
import Confirm from "components/dialog/Confirm";
import { createConfirmation } from "react-confirm";
import { EmailContext } from "../../Email";
import { useContext } from "react";
import { DraftContext } from "./Draft";
import TemplateModal from "./components/templates/TemplateModal";
import SaveTemplateButton from "./components/templates/SaveTemplateButton";
import SaveEmailButton from "./components/SaveEmailButton";
import SaveFormButton from "./components/SaveFormButton";
import { useMediaQuery } from "react-responsive";

const EmailStepper = ({
  setTemplate,
  activeStep,
  setActiveStep,
  steps,
  formRef,
  setFormValidation,
}) => {
  
  const ifXsScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const { emailEditorRef, editorReady } = useContext(DraftContext);
  const { activity } = useContext(EmailContext);


  const isLastPage = +activeStep >= 2;
  const isFirstPage = +activeStep === 0;
  const isActivityActive =
    activity?.isActive || !!activity?.state;


  const confirm = createConfirmation(Confirm);
  const handleSubmit = async (promise) => {

    if (isLastPage) {
      try {
        const confirmation = await confirm({
          confirmation:
            "Din kampanj skickas till emailleverantören för kontroll av målgrupp. När målgruppen är redo och godkänd kommer du kunna göra ett testutskick till vald emailadress och/eller publicera ditt utskick. OK?",
        });

        if (!confirmation) {
          return;
        }
      } catch (error) {
        console.error("Confirmation error:", error);
        return;
      }
    }
    try {
      await promise();
      setTimeout(() => {
        if (!isLastPage) setActiveStep(activeStep + 1);
      }, 500);
    } catch (error) {
      console.warn("Issue with promise in btn, error:\n", error);
    }
  };
  return (
    <Col className={styles.campaignChannelFooter}>
    <Row  className="align-items-center justify-content-center p-2">
      <Col xs={2} md={3}  className={`d-flex justify-content-start mb-2 mb-sm-0 ${ifXsScreen ? "order-1 ":""}`}>
        {activeStep > 0 ? (
          <Button variant="outline-dark" className="fw-bold" size={ifXsScreen ? "sm" : ""} onClick={() => setActiveStep((prev) => prev - 1)}>
            <BsArrowLeft size={20} />
            <span className="d-none d-sm-inline">Gå tillbaka</span>
          </Button>
        ) : (
          <>
                  <Row className="d-flex flex-wrap">
                    <Col xs={12} md={ifXsScreen ? 12 : 6} lg={ifXsScreen ? 12 : 6}>
                      <Col className="pb-2">
                        <TemplateModal setTemplate={(e) => setTemplate(e)} size={ifXsScreen ? "sm" : ""} />
                      </Col>
                    </Col>
                    <Col xs={12} md={ifXsScreen? 12 : 6} lg={ifXsScreen ? 12 : 6}>
                      <SaveTemplateButton emailEditorRef={emailEditorRef} isEditorReady={editorReady} size={ifXsScreen ? "sm" : ""}/>
                    </Col>
                  </Row>
          </>
        )}
      </Col>

      <Col xs={8} md={6}   className={`d-flex justify-content-center mb-2 mb-sm-0 ${ifXsScreen ? "order-2":""}`}>
        <Stepper
          steps={steps?.length}
          disablePrev={isLastPage && isActivityActive}
          disableContinue
          activeStep={activeStep}
          onClick={(e) => e !== undefined && setActiveStep(e)}
          className="stepper-responsive"
        />
      </Col>

      <Col xs={2} md={3}  className={`d-flex justify-content-end mb-2 mb-sm-0 ${ifXsScreen ? "order-3 ":""}`}>
        {isFirstPage ? (
          <SaveEmailButton ifXsScreen={ifXsScreen} onSubmit={handleSubmit} />
        ) : (
          <SaveFormButton ifXsScreen={ifXsScreen}  formRef={formRef} setFormValidation={setFormValidation} onSubmit={handleSubmit} isLastStep={isLastPage} />
        )}
      </Col>
    </Row>
  </Col>
  );
};

export default EmailStepper;
