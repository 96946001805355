import { Col, Row, Form, Stack } from "react-bootstrap";
import {
  patchUpdateCampaignActivity,
  patchUpdateCampaignActivityAd,
} from "features/campaign/campaign-details/api";
import EditDateTime from "./editsettingscomponents/EditDateTime";
import { useMutation, useQueryClient } from "react-query";
import Agent from "api/Agent";
import EditTargetGroup from "./editsettingscomponents/EditTargetGroup";
import EditTargetGroupMaxSize from "./editsettingscomponents/EditTargetGroupMaxSize";
import EditBudget from "./editsettingscomponents/EditBudget";
import EditTargetURL from "./editsettingscomponents/EditTargetURL";
import EditBillingEvents from "./editsettingscomponents/EditBillingEvents";
import EditDynamicUpdating from "./editsettingscomponents/EditDynamicUpdating";
import EditBidStrategy from "./editsettingscomponents/EditBidStrategy";
import EditTargetType from "./editsettingscomponents/EditTargetType";
import EditOptimizationGoal from "./editsettingscomponents/EditOptimizationGoal";
import AppendPersonToSegment from "./editsettingscomponents/AppendPersonToSegment";
import EditObjective from "./editsettingscomponents/EditObjective";

const EditSettingsContainer = ({ activity, campaign, segment }) => {
  const queryClient = useQueryClient();
  const { mutate: updateActivity, isLoading: updateActivityLoading } =
    useMutation((obj) => patchUpdateCampaignActivity(activity.id, obj), {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id]);
      },
    });
  const { mutate: updateAd, isLoading: updateAdLoading } = useMutation(
    (obj) => patchUpdateCampaignActivityAd(activity.ad.id, obj),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id]);
      },
    }
  );

  // const { data: segment } = Agent.Segments.GET.ID(campaign.segmentID);

  return (
    <>
      {activity?.skipAdCreative ? (
        <Stack className="mt-4 pt-3 gap-3 text-lg semi-bold edit-settings-container">
          <EditTargetGroup campaign={campaign} segment={segment} />

          <EditTargetType activity={activity} />

          <EditTargetGroupMaxSize
            campaign={campaign}
            activity={activity}
            segment={segment}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <EditDynamicUpdating
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />
          <hr />
          <AppendPersonToSegment activity={activity} />
        </Stack>
      ) : (
        <Stack className="mt-4 pt-3 gap-4 gap-md-3 text-lg semi-bold edit-settings-container">
          <EditDateTime
            activity={activity}
            updateAd={updateAd}
            updateAdLoading={updateAdLoading}
          />

          <EditTargetGroup campaign={campaign} segment={segment} />

          <EditTargetType activity={activity} />

          <EditTargetGroupMaxSize
            segment={segment}
            campaign={campaign}
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <EditBudget
            activity={activity}
            updateAd={updateAd}
            updateAdLoading={updateAdLoading}
          />

          <EditTargetURL
            activity={activity}
            updateAd={updateAd}
            updateAdLoading={updateAdLoading}
          />

          <EditDynamicUpdating
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <EditOptimizationGoal
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <EditBillingEvents
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <EditBidStrategy
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <EditObjective
            activity={activity}
            updateActivity={updateActivity}
            updateActivityLoading={updateActivityLoading}
          />

          <hr />

          <AppendPersonToSegment activity={activity} />
        </Stack>
      )}
    </>
  );
};

export default EditSettingsContainer;
