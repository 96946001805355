import { Button, Col, Form, Spinner, Stack } from "react-bootstrap";
import React, { useState } from "react";
import "react-range-slider-input/dist/style.css";
import Agent from "api/Agent";
import TargetTypeSelect from "./TargetTypeSelect";
import { DRContext } from "../../DR";
import MinMaxSlider from "components/sliders/minMaxSlider/MinMaxSlider";
import { useContext } from "react";
import moment from "moment";

const NewDataExportForm = React.forwardRef(({ onSubmit }, ref) => {
  const drContext = useContext(DRContext);
  const campaign = drContext?.campaign ?? undefined;
  const activity = drContext?.activity ?? undefined;
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  //Get segment by id-----
  const { data: segment, isLoading: segmentLoading } = Agent.Segments.GET.ID(campaign?.segmentID)

  //Handle Slider change
  const handleSliderChange = (values) => {
    const max = Math.max(...values);
    const min = Math.min(...values);
    setFormData(prev => ({
      ...prev,
      maxSize: max, minSize: min, campaignActivityID: activity?.id
    }))
      ;
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    const form = ref.current;
    setValidated(false);

    if (!form.checkValidity()) {
      e.stopPropagation();
      return setValidated(true);
    }

    if (typeof onSubmit == "function") onSubmit(formData)
  }
  return (
    <Form ref={ref} noValidate validated={validated} onSubmit={(e) => onClickSubmit(e)}>
      <Stack className="gap-3" >
        <Col>
          <Col className="col-12">
            <Form.Label
              htmlFor="activity_targetGroupMaxSize"
              className="p-0 semi-bold text-md"
              style={{ color: "#344054" }}
            >
              Ange antal mottagare för detta utskick
            </Form.Label>
          </Col>
          <MinMaxSlider max={segment?.size} onChange={(values) => handleSliderChange(values)} onMount={(values) => handleSliderChange(values)} />
        </Col>
        <Col>
          <Form.Label
            htmlFor="targetadress"
            className="p-0 semi-bold text-md"
          >
            Datum för detta utskick
          </Form.Label>
          <Form.Control
            type="date"
            className="p-3"
            required
                min={moment().format('yyyy-MM-DD')}
            onChange={(e) =>
              setFormData(prev => ({
                ...prev,
                scheduledDate: e.target.value,
              }))

            }
          />
          <Form.Control.Feedback type="invalid">
            Ange ett datum för utskick
          </Form.Control.Feedback>
        </Col>

      </Stack>
      <Button className="invisible" type='submit'> </Button>

    </Form>
  )
});

NewDataExportForm.displayName = "NewDataExportForm";

export default NewDataExportForm
