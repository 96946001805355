import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const FilterGroups = {
  GET: {
    ALL: () => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/FilterGroups/v1`).then(({ data }) => data)
          ).catch((e) => Promise.reject(e)),
        queryKey: ["filtergroups"],
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all filtergroups \n Error: `, err),
      });
    },
    ID: (id) => {
      // return useQuery({
      //   queryFn: () =>
      //     Promise.resolve(
      //       criteria(id).then(() =>
      //         axios.get(`/campaigns/v1/${id}`).then(({ data }) => data)
      //       )
      //     ).catch((e) => Promise.reject(e)),
      //   queryKey: ["campaign", id],
      //   staleTime: 1000 * 60 * 1,
      //   refetchInterval: 1000 * 60 * 5,
      //   retry: false,
      //   onError: (err) =>
      //     console.warn(`Failed to get campaign '${id}' \n Error: `, err),
      // });
    },
  },
  PUT: {
    // CAMPAIGN: () => {
    //   const queryClient = useQueryClient();
    //   return useMutation(
    //     (campaign) =>
    //       Promise.resolve(
    //         axios
    //           .put(`/campaigns/v1/${campaign?.id}`, campaign)
    //           .then(() => campaign?.id)
    //       ).catch((e) => Promise.reject({ error: e, id: campaign?.id })),
    //     {
    //       onSuccess: (id) => {
    //         queryClient.invalidateQueries("campaigns");
    //         queryClient.invalidateQueries(["campaign", id]);
    //       },
    //       onError: ({ error, id }) => {
    //         console.warn(`Failed to PUT campaign ${id} \n Error: `, error);
    //       },
    //     }
    //   );
    // },
  },
  POST: {
    FILTERGROUP: () => {
      const queryClient = useQueryClient();
      return useMutation((filters) => axios.post(`/FilterGroups/v1`, filters), {
        onSuccess: () => {
          queryClient.invalidateQueries("filtergroups");
        },
        onError: ({ error }) => {
          console.warn(`Failed to POST new campaign \n Error: `, error);
          toast("[Fel] Det gick inte att skapa en ny filtergrupp", {
            type: "error",
          });
        },
      });
    },
  },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/FilterGroups/v1/${id}`), {
        onSuccess: (x) => {
          queryClient.invalidateQueries("filtergroups");
          queryClient.removeQueries([id]);
          toast("Filtergrupp borttagen ", { type: "info" });
        },
        onError: (error) => {
          console.warn(
            `Failed to delete filtergroup '${id}' \n Error: `,
            error
          );
          toast("[Fel] Filtergruppen gick inte att ta bort ", {
            type: "error",
          });
        },
      });
    },
  },
};
