import { Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { GoogleMapMain } from "components/maps";
import ContactForm from "../components/ContactForm";
import LeadTimeLine from "../components/LeadTimeLine";
import { useMediaQuery } from "react-responsive";

const LeadCompanyOverview = ({ leadId, setBgBlur, lead, leadActivities }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' })


  return (
    <Row className="gy-5">
      {/* DESCRIPTION and MAP------------------- */}
      <Col className={`${isSmallScreen ? "col-12": "col-6"} p-0`} >
        <Row className="g-4">
          <Col className="col-12">
            <CardContainer
              padding
              children={
                <Row>
                  <Col className="col-12">
                    <Row className="g-2">
                      <Col className="col-12">
                        <h3 className="display-sm semi-bold">Beskrivning</h3>
                        <p className="text-lg regular">
                          {lead?.jObject?.basic?.data?.description?.replace(
                            /\//g,
                            ""
                          )}
                        </p>
                      </Col>
                      <Col className="col-6">
                        <h6 className="text-lg bold">Organisationsnummer</h6>
                        <p className="text-lg regular">
                          {lead.jObject.basic.data.orgnumber}
                        </p>
                      </Col>
                      <Col className="col-6">
                        <h6 className="text-lg bold">Adress</h6>
                        <p className="text-lg regular">
                          {lead.jObject.basic.data.address},
                          {lead.jObject.basic.data.zipcode
                            .toString()
                            .substring(0, 3)}
                          {lead.jObject.basic.data.zipcode
                            .toString()
                            .substring(3, 5)}
                          , {lead.jObject.basic.data.town}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 mt-4">
                    <GoogleMapMain
                      address={lead.jObject.basic.data.address}
                      height="100%"
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
          <Col className="col-12">
            <ContactForm leadId={leadId} lead={lead} />
          </Col>
        </Row>
      </Col>

      {/* TIMELINE------------------- */}
      <Col className={`${isSmallScreen ? "col-12": "col-6"}`} style={{ overflowY: "auto" }}>
        <LeadTimeLine
          leadId={leadId}
          leadActivities={leadActivities}
          setBgBlur={setBgBlur}
          lead={lead}
        />
      </Col>
    </Row>
  );
};

export default LeadCompanyOverview;
