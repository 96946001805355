import { Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { CardContainer } from "components/cards";
import { BarChart, DoughnutChart } from "components/charts";

const VehiclesOverview = ({ vehicleAggregations }) => {
  return (
    <>
      <Col className="col-12">
        <Row>
          {vehicleAggregations?.map((item) => {
            if (
              item.field === "technical.fuel_1" ||
              item.field === "technical.chassi" ||
              item.field === "technical.transmission" ||
              item.field === "technical.four_wheel_drive"
            ) {
              return (
                <Col className="col-3">
                  <CardContainer
                    padding
                    children={
                      <Row>
                        <Col className="col-12">
                          <Row>
                            <Col>
                              <h5 className="text-xl semi-bold">
                                {item.label}
                              </h5>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-12" style={{ height: "25vh" }}>
                          <DoughnutChart
                            values={[
                              ...item?.buckets.map((value) => value.doc_count),
                            ]}
                            labels={item?.buckets.map((label) => label.label)}
                            colors={
                              item.field === "technical.fuel_1"
                                ? ["#2D6450", "#6C9384", "#B3CCC3"]
                                : item.field === "technical.chassi"
                                ? ["#FFF6EB", "#E7AD67", "#B36609", "#F7DDBD"]
                                : item.field === "technical.four_wheel_drive"
                                ? ["#EC5F52", "#FFDEDB"]
                                : item.field === "technical.transmission"
                                ? ["#D6D1CE", " #979A97"]
                                : null
                            }
                          />
                        </Col>
                      </Row>
                    }
                  />
                </Col>
              );
            }
          })}
        </Row>
      </Col>
      <Col className="col-12">
        <Row>
          {vehicleAggregations?.map((item) => {
            if (
              item.field === "basic.make" ||
              item.field === "basic.vehicle_year"
            ) {
              const data = {
                labels: item?.buckets.map((label) => label.label),
                datasets: [
                  {
                    label: item.label,
                    data: item?.buckets.map((count) => count.doc_count), // use turnover property as chart values
                    backgroundColor: "#6C9384",
                    borderColor: "rgba(54, 162, 235, 0)",
                    borderWidth: 1,
                    borderRadius: 10,
                    barPercentage: 0.5,
                    categoryPercentage: 1.0,
                    stack: "stacked",
                    scales: {
                      xAxes: [{ stacked: true }],
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                          },
                          stacked: false,
                        },
                      ],
                    },
                  },
                ],
              };
              return (
                <Col className="col-6">
                  <CardContainer
                    marginBottom
                    padding
                    children={
                      <Row>
                        <Col className="col-12">
                          <Row>
                            <Col>
                              <h5 className="text-xl semi-bold">
                                {item.label}
                              </h5>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-12" style={{ height: "25vh" }}>
                          <BarChart data={data} noLabels />
                        </Col>
                      </Row>
                    }
                  />
                </Col>
              );
            }
          })}
        </Row>
      </Col>
    </>
  );
};

export default VehiclesOverview;
