import React, { useState, useRef } from "react";
import {
  Container,
  Col,
  Row,
  Spinner,
  Popover,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useQueryClient } from "react-query";
import { addCustomersList } from "features/accounts/api";
import Offcanvas from "react-bootstrap/Offcanvas";

import { MdFileUpload, MdArrowRightAlt } from "react-icons/md";
import Agent from "api/Agent";

const UploadList = ({ show, setShow }) => {
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(3);

  const { mutate } = Agent.CustomLists.POST();

  //UPLOAD FILE FUNCTIONALITY
  const fileInputRef = useRef(null);
  const handleClickUpload = () => {
    fileInputRef.current.click();
  };
  const [showOverlay, setShowOverlay] = useState(false);
  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    mutate({ file: formData, type });
  };

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: "unset" }}>
      <Popover.Header as="h3">
        Är du säker på att du vill ladda upp denna lista?
      </Popover.Header>
      <Popover.Body>
        <Button
          variant="success"
          className="mx-2 text-md btn-lg"
          onClick={handleFileUpload}
        >
          {loading ? <Spinner /> : "Ja, ladda upp!"}
        </Button>
        <Button
          variant="secondary"
          className="mx-2 text-md btn-lg"
          onClick={() => setShowOverlay(false)}
        >
          Avbryt
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={{ width: "30%" }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Ladda upp lista</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          <Form>
            <Alert variant="info">Vänligen ladda upp en .txt eller .csv-fil med ett värde per rad.</Alert>
            <Col className="col-12 my-3">
              <Form.Label>Typ</Form.Label>
              <Form.Select
                className="py-3 semi-bold text-lg"
                aria-label="Default select example"
                onChange={(e) => setType(e.target.value)}
                defaultValue={type}
              >
                <option value={3}>Organisationsnummer</option>
                <option value={4}>Personnummer</option>
                <option value={5}>Telefonnummer</option>
                <option value={6}>Addresser</option>
              </Form.Select>
            </Col>
            <Col className="mt-5">
              <input
                type="file"
                onChange={handleFileUpload}
                ref={fileInputRef}
                style={{ display: "none" }}
              />

              <Button
                variant="success"
                className="mx-2 text-md btn-lg"
                onClick={handleClickUpload}
              >
                Ladda upp lista <MdFileUpload style={{ marginLeft: "6px" }} />
              </Button>
            </Col>
          </Form>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UploadList;
