import "../style.scss";
import { Row, Col, InputGroup, Form, Spinner, Button } from "react-bootstrap";
import { MdEdit, MdCheck } from "react-icons/md";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Agent from "api/Agent";
import { useEffect } from "react";

const SetCampaignName = ({ifXsScreen}) => {
  //Toggle between input and display name
  const [showInput, setShowInput] = useState(false);

  //Get campaign id from URL
  const { id } = useParams();

  //Get campaign data using id
  const { data: campaign } = Agent.Campaigns.GET.ID(id);
  useEffect(() => {setCampaignName(campaign?.name)},[campaign])

  //Set campaign name to display
  const [campaignName, setCampaignName] = useState(campaign?.name);
  const {mutate : updateCampaign} = Agent.Campaigns.PUT.CAMPAIGN();
  
  //Set the name onChange
  const handleNameChange = (e) => {
    setCampaignName(e.target.value);
  };

  //Submit the PUT-request on click
  const onClickSubmit = (eventType) => {
    const updatedCampaign = {
      ...campaign,
      name: campaignName,
    };
    updateCampaign(updatedCampaign);
    if (eventType === "mouse-leave") {
      setTimeout(() => {
        setShowInput(!showInput);
      }, "3000");
    } else {
      setShowInput(!showInput);
    }
  };
  return (
    <Row className="justify-content-start w-100">
      {showInput === true ? (
        <>
          <Col
            className="w-100"
            onMouseLeave={() => onClickSubmit("mouse-leave")}
          >
            <InputGroup>
              <Form.Control
                placeholder={campaignName ? campaignName : "Namn på målgrupp"}
                onChange={handleNameChange}
                defaultValue={
                  campaignName && !campaignName.includes("Ny kampanj -") 
                    ? campaignName
                    : null
                }
              />
            </InputGroup>
          </Col>
          <Col className="col-2 name-col">
            <Button onClick={() => onClickSubmit("click")} variant="link">
              <MdCheck />
            </Button>
          </Col>
        </>
      ) : (
        <>
            <Row className="d-flex align-items-center">
              <Col className="d-flex align-items-center"
              >
                <h2 className={`display-xs semi-bold mb-0 text-nowrap ${ifXsScreen  ? "text-truncate" : ""}`}>
                  {campaignName ? campaignName : <Spinner />}
                </h2>
                <Button
                  onClick={() => setShowInput(!showInput)}
                  className=" name-col edit-name-col ms-2  "
                  style={{ background: "none", color: "#b4b4b4", padding: '0.5rem', minWidth: '2rem', height: 'auto' }}
                >
                  <MdEdit />
                </Button>
              </Col>
            </Row>
        </>
      )}
    </Row>
  );
};

export default SetCampaignName;
