import {
  Row,
  Col,
  Form,
  InputGroup,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
// import { updateaccount } from "features/accounts/api";
import { useMutation, useQueryClient } from "react-query";
import { CardContainer } from "components/cards";
import { TextInput } from "components/forms";
import { ButtonMain } from "components/buttons";
import { useState } from "react";
import { MdFace } from "react-icons/md";

const ContactForm = ({ account }) => {
  return (
    <CardContainer
      padding
      marginBottom
      children={
        <Row className="justify-content-between">
          <Col className="col-12">
            <h3 className="display-sm semi-bold">Kontaktperson</h3>
          </Col>
          <Col className="my-4 col-9">
            <Row>
              <Col className="col-2 d-flex justify-content-center">
                <MdFace size={60} color="#F7DDBD" />
              </Col>

              <Col style={{ margin: "auto" }}>
                <p className="text-md regular">
                  Namn:{" "}
                  <span className="semi-bold">
                    {" "}
                    {account?.jObject.basic.data.name.given_name
                      ? account?.jObject.basic.data.name.given_name
                      : account?.jObject.basic.data.name.first_name}{" "}
                    {` ${account?.jObject.basic.data.name.last_name}`}
                  </span>
                </p>

                {/* {account.email && (
                    <p className="text-md regular">
                      E-post: <span className="semi-bold">{account.email}</span>
                    </p>
                  )} */}
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  );
};

export default ContactForm;
