import { useState } from "react";
import { Alert, Form, Row } from "react-bootstrap";
import ImagePickerModal from "../../ImagePickerModal";

const EditAdImage = ({ activity, disabled, onChange, defaultValue, value }) => {
  const [selectedImg, setSelectedImg] = useState();

  const handleSelect = (img) => {
    setSelectedImg(img);
    if (typeof onChange == "function") onChange(img)
  }

  return (
    <ImagePickerModal
      disabled={disabled}
      metaAdObject={activity.ad}
      setMetaAdObject={() => { }}
      activityId={activity?.id}
      setSelectedImage={handleSelect}
      selectedImage={value}
    />
  )
}

export default EditAdImage
