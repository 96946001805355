import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "Danderyd",
  "Haninge",
  "Järfälla",
  "Nacka",
  "Salem",
  "Solna",
  "Sollentuna",
  "Tyresö",
  "Täby",
  "Österåker",
];

export const dummyData = {
  labels,
  datasets: [
    {
      // label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "#905AD6",
    },
  ],
};

const BarChart = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        display: props.noLabels ? false : true,
        labels: {
          usePointStyle: true,
          generateLabels: (chart) => {
            const labels =
              ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
            labels.forEach((label) => {
              label.pointStyle = "circle"; // set the shape of the markers to circles
              label.lineWidth = 1; // set the line width of the markers to 1 pixel
              label.boxWidth = 5; // set the box width of the markers to 10 pixels
              label.boxHeight = 5; // set the box height of the markers to 10 pixels
              label.fontSize = 10; // set the font size of the labels to 12 pixels
            });
            return labels;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: false,
        ticks: {
          beginAtZero: true,
        },
      },
    },
    maintainAspectRatio: false,
  };
  return <Bar options={options} data={props.data ? props.data : dummyData} />;
};

export default BarChart;
