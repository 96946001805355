import { ForgotPasswordContainer } from "features/authentication";
import "./style.scss";
import img from "assets/img/login-bg.jpg";
import { Container, Col, Row, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const ForgotPassword = () => {

  const ifBigScreen = useMediaQuery({ query: "(min-width: 769px)" });

  return (
    <Container fluid className="login-container">
      <Row>
        <Col className="col-12 col-md-6">
          <ForgotPasswordContainer />
        </Col>
        {ifBigScreen && (
        <Col md={6} className="col">
          <Image className="login-img" fluid src={img} />
        </Col>)}
      </Row>
    </Container>
  );
};

export default ForgotPassword;
