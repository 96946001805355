import { useEffect, useState, forwardRef } from "react";
import RangeSlider from "react-range-slider-input";
import { Col, Form, Row } from "react-bootstrap";
import CampaignInput from "features/campaign/create-campaign/components/steps/components/CampaignInput";

const EditGoogleAdsSettings = forwardRef(
  ({ activity, googleAdsObject, setGoogleAdsObject, segment }, ref) => {
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const [startDate, setStartDate] = useState(
      activity?.ad?.startDate ? activity.ad.startDate : ""
    );
    const [endDate, setEndDate] = useState(
      activity?.ad?.endDate ? activity.ad.endDate : ""
    );

    useEffect(() => {
      if (activity) {
        setStartDate(activity?.ad?.startDate ? activity.ad.startDate : "");
        setEndDate(activity?.ad?.endDate ? activity.ad.endDate : "");
      }
    }, [activity]);

    //Targetgroupsize
    const rangeSettings = { min: 0, max: segment?.size, step: 1 };
    const [rangeSliderValue, setRangeSliderValue] = useState(
      activity && activity["targetGroupMaxSize"]
        ? activity["targetGroupMaxSize"]
        : rangeSettings?.max
    );

    const handleRangeInput = (value) => {
      if (value > rangeSettings.max) return;
      if (value < rangeSettings.min) return;
      setRangeSliderValue(value);
    };

    useEffect(() => {
      setGoogleAdsObject({
        ...googleAdsObject,
        targetGroupMaxSize: rangeSliderValue,
      });
    }, [rangeSliderValue]);

    const [validated, setValidated] = useState(false);
    const onClickSubmit = (e) => {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
      setValidated(true);
    };

    return (
      <Row className="mt-1 row-cols-1 g-3">
        <Form
          ref={ref}
          validated={validated}
          noValidate
          onSubmit={onClickSubmit}
        >
          <Row className="gy-4">
            <Col>
              <CampaignInput
                required
                defaultValue={startDate}
                label="Startdatum"
                type="datetime-local"
                onChange={(e) =>
                  setGoogleAdsObject({
                    ...googleAdsObject,
                    startDate: e.target.value,
                  })
                }
              />
            </Col>
            <Col>
              <CampaignInput
                required
                defaultValue={endDate}
                label="Slutdatum"
                type="datetime-local"
                onChange={(e) =>
                  setGoogleAdsObject({
                    ...googleAdsObject,
                    endDate: e.target.value,
                  })
                }
              />
            </Col>
            <Col className="col-12">
              <Form.Label
                htmlFor="activity_targetGroupMaxSize"
                className="p-0 text-md"
                style={{ color: "#344054" }}
              >
                Antal utskick i målgrupp
              </Form.Label>
              <div className="d-flex justify-content-center flex-wrap">
                <Form.Control
                  type="text"
                  name="activity_targetGroupMaxSize"
                  value={rangeSliderValue}
                  required
                  onChange={(e) =>
                    handleRangeInput(!e.target.value ? 0 : +e.target.value)
                  }
                  style={{ flex: "1", minWidth: "100px" }}
                />
                <div
                  className="d-flex flex-wrap justify-content-center"
                  style={{ flex: 8, minWidth: "200px" }}
                >
                  <div style={{ flex: "6" }} className="px-2  my-auto">
                    <RangeSlider
                      thumbsDisabled={[true, false]}
                      value={[0, rangeSliderValue]}
                      onInput={(e) => setRangeSliderValue(e[1])}
                      min={rangeSettings.min}
                      max={rangeSettings.max}
                      className="single-thumb"
                      step={rangeSettings.step}
                    />
                  </div>
                  <Form.Control
                    style={{ flex: "4", minWidth: "70px" }}
                    disabled
                    className=" text-center"
                    type="text"
                    value={rangeSettings.max}
                  />
                </div>
              </div>
            </Col>
            {segment?.type === 0 && (
              <Col className="col-12">
                <Form.Label
                  htmlFor="targetadress"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Typ av mottagare
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) =>
                    setGoogleAdsObject({
                      ...googleAdsObject,
                      directMarketingTarget: e.target.value,
                    })
                  }
                  defaultValue={googleAdsObject.directMarketingTarget}
                >
                  <option value={0}>Företagsadress</option>
                  <option value={1}>Alla personer i styrelsen</option>
                  <option value={2}>Beslutsfattare</option>
                </Form.Select>
              </Col>
            )}
          </Row>
        </Form>
        <Form>
          <Col>
            <Row>
              <Col className="col-1">
                <Form.Check
                  type="switch"
                  id="show-settings-switch"
                  className="status-switch"
                  value={showAdvancedSettings}
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                />
              </Col>
              <Col className="d-flex align-items-center">
                <p className="p-0 text-md" style={{ color: "#344054" }}>
                  Visa avancerad inställningar
                </p>
              </Col>
            </Row>
          </Col>
          {showAdvancedSettings && (
            <>
              <Col>
                <CampaignInput
                  value={googleAdsObject.budget}
                  onChange={(e) =>
                    setGoogleAdsObject({
                      ...googleAdsObject,
                      budget: e.target.value,
                    })
                  }
                  label="Budget"
                  type="number"
                  placeholder={googleAdsObject.budget}
                  suffix="kr"
                />
              </Col>
              <Col>
                <Form.Label
                  htmlFor="choose-campaign"
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Annonsmål
                </Form.Label>

                <Form.Select
                  id="meta-pages"
                  aria-label="Default select example"
                  required
                >
                  <option value={0}>Antal unika visningar</option>
                  <option value={1}>Antal Klick</option>
                  <option value={2}>Antal visade annonser</option>
                </Form.Select>
              </Col>
            </>
          )}
        </Form>
      </Row>
    );
  }
);

export default EditGoogleAdsSettings;