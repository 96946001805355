import { Button, Form, Spinner } from "react-bootstrap";
import { Table, Row, Col } from "react-bootstrap";
import { CardContainer } from "components/cards";
import moment from "moment";
import "moment/locale/sv";
import Agent from "api/Agent";
import { useQuery } from "react-query";
import { getCustomerSettings } from "features/settings/api";



export default function OfferTable({ offerID }) {
  const { data: settings } = useQuery("customer", getCustomerSettings);
  const {
    data: offer,
    isError,
    isLoading
  } = Agent.Offers.GET.ID(offerID);


  const columns = [
    {
      label: settings?.offerName
        ? `${settings.offerName}`
        : "Erbjudande",
      property: "title",
    },
    { label: "Konverteringar", property: "purchaseCount" },
    { label: "Börjar", property: "startTime", formatDate: true },
    { label: "Slutar", property: "endTime", formatDate: true },
    { label: "Används i antal kampanjer", property: "campaignsCount" },
  ];

  return (
    <CardContainer
      style={{ padding: "0" }}
      children={
        <>
          <Table
            id="segmentTable"
            className="table-container"
            responsive
            hover
            size="lg"
          >
            <thead className="text-sm semi-bold">
              <tr>
                {columns.map((col, index) => (
                  <th
                    style={{ width: "150px", userSelect: "none" }}
                    key={index}

                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className="text-md regular">

              <tr key={offerID}>

                {offer && columns.map((col, index) => (
                  <th
                    style={{ width: "150px", userSelect: "none" }}

                  >
                    {
                      col?.formatDate
                        ? moment(offer[col.property]).format("YYYY-MM-DD ")
                        : offer[col.property]
                    }                  </th>
                ))
                }
              </tr>
            </tbody>

            {isError && (
              <caption
                style={{ captionSide: "bottom", columnSpan: "100%" }}
                className="p-3 bg-danger"
              >
                <h5 className=" text-white semi-bold text-center w-100">
                  Ett fel uppstod med tabell data
                </h5>
              </caption>
            )}

            {isLoading && (
              <caption
                style={{ captionSide: "bottom", columnSpan: "100%" }}
                className="p-3"
              >
                <h5 className=" text-black text-center w-100">
                  <Spinner />
                </h5>
              </caption>
            )}
          </Table>

        </>
      }
    />

  )
}
