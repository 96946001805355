import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Customers = {
  GET: {
    ALL: () => {
      return useQuery({
        queryFn: () => axios.get(`/customers/v1`).then(({ data }) => data),
        queryKey: ["customers"],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
      });
    },
    PAGINATED: (page, limit, search, sortByProperty, desc) => {
      const queryParams = [
        page && `page=${page}`,
        limit !== undefined && `limit=${limit}`,
        search && search?.trim().length > 0 && `searchTerm=${search}`,
        sortByProperty && `orderByProperty=${sortByProperty}`,
        desc && `desc=${true}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios
            .get(`/customers/v1/paginated${queryString}`)
            .then(({ data }) => data),
        queryKey: ["customers", queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes
      });
    },

    ID: (id) => {
      return useQuery({
        queryFn: () => axios.get(`customers/v1/${id}`).then(({ data }) => data),
        queryKey: ["customer", id],
        enabled: !!id,
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET customers with query: ${id} \n Error: `,
            error
          );
        },
      });
    },

    ACTIVITY: (page, limit, search, sortByProperty, desc) => {
      const queryParams = [
        page && `page=${page}`,
        limit != undefined && `limit=${limit}`,
        search && search?.trim().length > 0 && `searchTerm=${search}`,
        sortByProperty && `orderByProperty=${sortByProperty}`,
        desc && `desc=${true}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios
            .get(`/customers/v1/activity${queryString}`)
            .then(({ data }) => data),
        queryKey: ["customers", "customers_activity", queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        staleTime: 1000 * 60 * 10, // 10 minutes

        onError: (error) => {
          console.warn(
            `Failed to get all customers: ${queryString} \n Error: `,
            error
          );
          toast.error("[Fel] Det gick inte att hämta leads");
        },
      });
    },

    CUSTOMERSETTINGS: () => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/customers/v1/settings`).then(({ data }) => data)
          ).catch((x) => Promise.reject(x)),
        queryKey: ["customers_settings"],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all customers settings \n Error: `, err),
      });
    },
    CUSTOMERSETTINGSID: (id) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/customers/v1/${id}/settings`).then(({ data }) => data)
          ).catch((x) => Promise.reject(x)),
        queryKey: ["customers_settings", id],
        // staleTime: 1000 * 60 * 10,
        // cacheTime: 1000 * 60 * 60, // 60 minutes
        // retry: false,
        onError: (err) =>
          console.warn(`Failed to get all customers settings \n Error: `, err),
      });
    },
    STATUS: (id) => {
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/customers/v1/${id}/status`).then(({ data }) => data)
          ).catch((x) => Promise.reject(x)),
        queryKey: ["customers_status", id],
        // staleTime: 1000 * 60 * 10,
        // cacheTime: 1000 * 60 * 60, // 60 minutes
        // retry: false,
        onError: (err) => console.warn(`Failed to get status \n Error: `, err),
      });
    },
  },
  PUT: {
    CUSTOMERSSETTINGS: (updatedSettings, id) => {
      const queryClient = useQueryClient();
      return useMutation(
        async () => axios.put(`/customers/v1/${id}/settings`, updatedSettings),
        {
          onSuccess: () => {
            // queryClient.invalidateQueries("customers settings");
            queryClient.invalidateQueries(["customer_settings", id]);
            toast("Inställningar uppdaterade", { type: "success" });
          },
          onError: (error) => {
            console.warn(
              `Failed to PUT customer setting ${id} \n Error: `,
              error
            );
          },
        }
      );
    },
  },
  POST: {
    SETTINGS: (updatedSettings, id) => {
      const queryClient = useQueryClient();
      return useMutation(
        async () => axios.post(`/customers/v1/${id}/settings`, updatedSettings),
        {
          onSuccess: () => {
            queryClient.invalidateQueries("customers settings");
            queryClient.invalidateQueries(["customer setting", id]);
            toast("Uppsättning uppdaterad", { type: "success" });
          },
          onError: (error) => {
            console.warn(
              `Failed to POST customer setup ${id} \n Error: `,
              error
            );
            toast("Uppsättning misslyckades med error: ", error, {
              type: "error",
            });
          },
        }
      );
    },
    SETUP: (id) => {
      const queryClient = useQueryClient();
      return useMutation(async () => axios.post(`/customers/v1/${id}/setup`), {
        onSuccess: (res) => {
          queryClient.invalidateQueries("customers setup");
          queryClient.invalidateQueries(["customer setup", id]);
        },
        onError: (error) => {
          console.warn(
            `Failed to PUT customer setting ${id} \n Error: `,
            error
          );
        },
      });
    },
  },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/customers/v1/${id}`), {
        onSuccess: () => {
          queryClient.invalidateQueries("customers");
          queryClient.removeQueries(["customers", id]);
          toast("Konton borttagen", { type: "success" });
        },
        onError: (error) => {
          console.warn(`Failed to delete customer ${id} \n Error:`, error);
          toast("Konton gick inte att ta bort", { type: "error" });
        },
      });
    },
  },
};
