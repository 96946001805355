import { CardContainer } from "components/cards";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { MdAddCircleOutline, MdDownload } from "react-icons/md";
import SegmentTable from "./SegmentTable";
import {
  columnsSegments,
  columnsSegmentsSm,
} from "features/campaign/create-campaign/components/steps/data/stepsData";
import "../style.scss";
import { useNavigate } from "react-router-dom";
import Agent from "api/Agent";
import ExportsTable from "features/exports/my-exports/ExportsTable";

const ExportsOverviewContainer = ({
  ifXsScreen,
  ifSmallScreen,
  ifMdAddCircleOutlineIsSmall,
  xsValue,
}) => {
  const {
    data: segments,
    isError,
    isLoading,
    error,
  } = Agent.Segments.GET.ALL();

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/segments");
  };

  return (
    <CardContainer
      h100
      padding
      children={
        <>
          <Container className="m-0 g-0" fluid>
            <Row className="mb-4 justify-content-between">
              <Col>
                <h4 className="text-lg semi-bold">Mina Exporter</h4>
              </Col>
              <Col className="col-2 d-flex justify-content-end align-items-center">
                <MdDownload size={30} color="#ddd" />
              </Col>
            </Row>
            <Row>
              <ExportsTable
                columns={ifSmallScreen ? columnsSegmentsSm : columnsSegments}
                ifSmallScreen={ifSmallScreen}
              />
            </Row>
          </Container>
        </>
      }
    />
  );
};

export default ExportsOverviewContainer;
