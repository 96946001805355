import "../style.scss";
import { useState } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import logo from "assets/img/autotarget-logo-blue.png";

import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordContainer = () => {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Container fluid className="login-form-container">
      <Row className="flex-column justify-content-between h-100 p-4">
        <Col className="col-sm-12">
          <Image className="logo-img" fluid src={logo} />
        </Col>
        <Col className="col-sm-12">
          <ResetPasswordForm
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </Col>
        <Col className="col-sm-12">
          <p className="text-sm regular">@ Autotarget 2023</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordContainer;
