import "./style.scss";
import { Card } from "react-bootstrap";

const CardContainer = (props) => {
  return (
    <Card
      style={props.h100 ? { height: "100%" } : {}}
      className={props.marginBottom ? "card-container mb-5" : "card-container"}
      bg={props.bg === "secondary" ? "secondary" : "light"}
    >
      {props.header && <Card.Header>{props.header}</Card.Header>}

      <Card.Body className={props.padding ? "p-4" : "p-0"}>
        {props.children}
      </Card.Body>
    </Card>
  );
};

export default CardContainer;
