import React from 'react'
import { useState } from 'react';
import DeleteCampaignButton from './DeleteCampaignButton';
import EditCampaignButton from './EditCampaignButton';

export default function TableActions({ item }) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <div align="center " onClick={(e) => e.stopPropagation}>
      {!confirmDelete && <EditCampaignButton item={item} />}
      <DeleteCampaignButton item={item}
        onConfirm={(isConfirming) => setConfirmDelete(isConfirming)}
      />
    </div>
  )
}
