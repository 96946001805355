import { Nav, Col, Accordion, Row } from "react-bootstrap";
import MenuItem from "./MenuItem";
import { BiTargetLock } from "react-icons/bi";
import {
  MdOutlineSpaceDashboard,
  MdSportsHockey,
  MdGroups,
  MdCampaign,
  MdAnalytics,
  MdManageAccounts,
  MdPersonSearch,
  MdBusinessCenter,
  MdFilterList,
  MdAdminPanelSettings,
  MdOutlineAdminPanelSettings,
  MdAreaChart,
  MdDownload,
  MdUpload,
} from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import {
  FaChevronDown,
  FaChevronUp,
  FaRegHandshake,
  FaUser,
} from "react-icons/fa";
import useCheckUser from "hooks/useCheckUser";

import { iconMap } from "features/filter/add-filters/services";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { TbProgress } from "react-icons/tb";

const SideMenu = ({ settings, ifMediumScreen, sidebarOpen, siteUrl }) => {
  const userStatus = useCheckUser();
  const location = useLocation();

  const isMerinfoTheme = siteUrl === "merinfo";
  const [activeKey, setActiveKey] = useState([
    null, // currentMenuItem from url
    null, // selected index from nav
  ]);

  const handleMenuItemClick = () => {
    if (sidebarOpen) {
      sidebarOpen(false);
    }
  };

  const handleAccordionToggle = (key) => {
    if (activeKey.includes(key)) return setActiveKey([null, null]);
    return setActiveKey([null, key]);
  };

  useEffect(() => {
    const currentMenuItem = Object.values(menu)?.find((category) =>
      category.items?.map((x) => x.link).includes(location.pathname)
    )?.userRoleID;

    if (currentMenuItem !== undefined) {
      const key = [...activeKey];
      key[0] = currentMenuItem;
      setActiveKey(key);
    }
  }, [location?.pathname]);

  // console.log({ settings });

  const Icon = settings && iconMap[settings.offerIcon];
  const iconSize = !ifMediumScreen ? 22 : 30;

  const userRoles = {
    user: 2,
    admin: 1,
    superadmin: 3,
  };

  const menuItemsMerInfo = [
    {
      title: "Översikt",
      icon: <MdOutlineSpaceDashboard size={iconSize} />,
      link: "/",
    },
    {
      title: "Kontaktlistor",
      icon: <MdGroups size={iconSize} />,
      link: "/segments",
    },
    {
      title: "Spärrlistor",
      icon: <MdUpload size={iconSize} />,
      link: "/lists",
    },
    {
      title: "Mina exporter",
      icon: <MdDownload size={iconSize} />,
      link: "/exports",
    },
  ];

  const menuItems = [
    {
      title: "Översikt",
      icon: <MdOutlineSpaceDashboard size={iconSize} />,
      link: "/",
    },
    {
      title: settings?.offerPluralName
        ? settings.offerPluralName
        : "Erbjudande",
      icon: settings ? (
        <Icon size={iconSize} />
      ) : (
        <MdSportsHockey size={iconSize} />
      ),
      link: "/offers",
    },
    {
      title: "Målgrupper",
      icon: <MdGroups size={iconSize} />,
      link: "/segments",
    },
    {
      title: "Leads",
      icon: <BiTargetLock size={iconSize} />,
      link: "/leads",
    },
    {
      title: "Kampanjer",
      icon: <MdCampaign size={iconSize} />,
      link: "/campaigns",
    },
    {
      title: "Kunder",
      icon: <FaRegHandshake size={iconSize} />,
      link: "/accounts",
    },
    {
      title: "Statistik",
      icon: <MdAnalytics size={iconSize} />,
      link: "/statistics",
    },
  ];

  const menuItemsAdmin = [
    {
      title: "Användare",
      icon: <MdPersonSearch size={iconSize} />,
      link: "/users",
    },
  ];
  const menuItemsSuperAdmin = [
    {
      title: "Jobb",
      icon: <MdBusinessCenter size={iconSize} />,
      link: "/jobs",
    },
    {
      title: "Konton",
      icon: <MdManageAccounts size={iconSize} />,
      link: "/customers",
    },
    {
      title: "Kunduppföljning",
      icon: <MdAreaChart size={iconSize} />,
      link: "/customersuccess",
    },
    {
      title: "Filter",
      icon: <MdFilterList size={iconSize} />,
      link: "/filters",
    },
    {
      title: "Aktiviteter",
      icon: <TbProgress size={iconSize} />,
      link: "/activities",
    },
  ];

  const menu = {
    user: {
      userRoleID: userRoles.user,
      title: "Användare",
      icon: <FaUser />,
      items: menuItems,
    },
    admin: {
      userRoleID: userRoles.admin,
      title: "Admin",
      icon: <MdAdminPanelSettings />,
      items: menuItemsAdmin,
    },
    superadmin: {
      userRoleID: userRoles.superadmin,
      icon: <MdOutlineAdminPanelSettings />,
      title: "SuperAdmin",
      items: menuItemsSuperAdmin,
    },
  };

  return (
    <Nav className="auto">
      {siteUrl === "merinfo" ? (
        menuItemsMerInfo.map((item, i) => (
          <Col className="col-12" key={i}>
            <MenuItem link={item.link} icon={item.icon} title={item.title} />
          </Col>
        ))
      ) : userStatus === userRoles.user ? (
        menuItems.map((item, i) => (
          <Col className="col-12" key={i}>
            <MenuItem link={item.link} icon={item.icon} title={item.title} />
          </Col>
        ))
      ) : (
        <Accordion
          className="p-0 m-0 w-100"
          flush
          onSelect={(e) => handleAccordionToggle(e)}
          activeKey={activeKey}
        >
          {Object.values(menu)
            .filter((x) =>
              userStatus !== userRoles.superadmin
                ? x.userRoleID !== userRoles.superadmin
                : x
            )
            ?.map((category) => {
              return (
                <Accordion.Item
                  key={category.title}
                  eventKey={category.userRoleID}
                >
                  <Accordion.Header className="d-flex flex-row row-cols-3 justify-content-between align-items-baseline">
                    <Col className="col-2 menu-item-icon">{category?.icon}</Col>
                    <Col className="d-flex align-items-center menu-item-text text-lg regular text-start">
                      <span>{category?.title}</span>
                    </Col>

                    <Col className="d-flex justify-content-end">
                      {activeKey.includes(category.userRoleID) ? (
                        <FaChevronUp />
                      ) : (
                        <FaChevronDown />
                      )}
                    </Col>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 m-0">
                    {category?.items?.map((item, i) => (
                      <MenuItem
                        key={i}
                        link={item.link}
                        icon={item.icon}
                        title={item.title}
                        onClick={handleMenuItemClick}
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      )}
    </Nav>
  );
};

export default SideMenu;
