import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const LandingPages = {
  GET: {
    ALL: () => {
      const queryClient = useQueryClient();
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            axios.get(`/landingpages/v1/`).then(({ data }) => data)
          ).catch((x) => Promise.reject(x)),
        queryKey: ["landingpages"],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 60, // 60 minutes
        retry: false,
        onError: (err) =>
          console.warn(`Failed to get all landingpages \n Error: `, err),
        // onSuccess: () => queryClient.invalidateQueries("landingpages"),
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: () =>
          criteria(id)
            .then(() =>
              axios
                .get(`/landingpages/v1/${id}`)
                .then(({ data }) => Promise.resolve(data))
                .catch((err) => Promise.reject(err))
            )
            .catch((badInput) => Promise.resolve(badInput)),
        queryKey: ["landingpage", id],
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET template with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta email utskick");
        },
      });
    },
  },

  POST: () => {
    const queryClient = useQueryClient();
    return useMutation(
      (object) =>
        Promise.resolve(
          axios.post(`/landingpages/v1`, object).then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("landingpages");
        },
        onError: (error) => {
          console.warn(`Failed to POST landingpage \n Error: `, error);
          toast(`[Fel] Det gick inte att skapa en landningssida`, {
            type: "error",
          });
        },
      }
    );
  },
};
