import "./style.scss";
import { Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setActiveFilterCategory } from "features/filter/filterSlice";
import { FiltersListContainer, ActiveFilterContainer } from "features/filter";
import {
  SetSegmentName,
  SegmentReachDisplay,
  SaveSegment,
} from "features/segment";
import { MdArrowBack } from "react-icons/md";
import ViewSegmentOffCanvas from "features/segment/create-segment/components/ViewSegmentOffCanvas/ViewSegmentOffCanvas";
import Agent from "api/Agent";

const config ={
  left : 
    {minWidth : "350px"}
  
}

const CreateTargetGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const { data: segment } = Agent.Segments.GET.ID(id)



  const onClickGoBack = () => {
    navigate("/segments");
  };

  useEffect(() => {
    dispatch(setActiveFilterCategory([]));
  }, [navigate]);

  return (
    <div className="d-flex flex-column h-100">
      <Row className="top-container col-12 align-items-center m-0 ps-1 py-3 " style={{flex: "0 1 auto"}}>
          <Col className="col-lg-4 " style={{minWidth: config.left.minWidth}}>
            <Row className="d-flex justify-content-start align-items-center">
              <Col style={{maxWidth:"50px"}} className="mt-5 mt-md-0">
                <Button onClick={onClickGoBack} className="btn-go-back">
                  <MdArrowBack />
                </Button>
                </Col>
              <Col className="">
                <SetSegmentName />
              </Col>
            </Row>
          </Col>
          {/* <Col className="text-end">
            <p>Hjälp</p>
          </Col> */}
      </Row>
      <Col className="segment-content-container m-0 p-0" style={{flex: "1 1 auto" , overflowX:"hidden", overflowY:"auto"}}>
        <Row className="justify-content-start h-100">
          <Col className="col-lg-4 col-12 menu" style={{minWidth: config.left.minWidth}}>
            <FiltersListContainer />
          </Col>
          <Col>
            <ActiveFilterContainer />
          </Col>
        </Row>
      </Col>
      <Col className="bottom-container  bg-light col-12 m-0 p-0" style={{flex:"0 1 80px"}}>
        <Row className="justify-content-between align-items-center h-100 m-0 p-0">
          <Col className="col-12 col-md-6 text-center text-md-start">
            <SegmentReachDisplay />
          </Col>
          <Col className="col-12 col-md-6 d-flex justify-content-md-end justify-content-center">
            <div className="p-2">
              {
                segment &&
                <ViewSegmentOffCanvas segment={segment}/>
              }
            </div>

            <div className="p-2">
              <SaveSegment />
            </div>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default CreateTargetGroup;
