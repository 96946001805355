import { useState, useEffect, useRef } from "react";
import Numeral from "react-numeral";
import MultiRangeSlider from "multi-range-slider-react";
import { Row, Col, Form } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import _ from 'lodash';

function FilterRangeSlider({ filter, segment, parsedFilters, mutate }) {
  const [rangeValues, setRangeValues] = useState(null);
  const prevRangeValuesRef = useRef(null);

  const filterIndex =
    segment && JSON.parse(segment?.filters).findIndex((f) => f[filter.id])
  const values = parsedFilters && parsedFilters[filterIndex];

  const [rangeMinValue, setRangeMinValue] = useState(
    // values[filter.id][0] ? values[filter.id][0] : filter.rangeDefaultMin 
    // will result to rangeDefaultMin if [0] = 0. Also the component throws an error if max < min so we take both min and max value with Math.min/max().
    values[filter?.id]?.length > 1 ? Math.min(...values[filter?.id]) : filter?.rangeDefaultMin
  );
  const [rangeMaxValue, setRangeMaxValue] = useState(
    values[filter.id]?.length > 1 ? Math.max(...values[filter.id]) : filter.rangeDefaultMax
  );

  const rangeMin = filter && filter.rangeMin;
  const rangeMax = filter && filter.rangeMax;

  const handleSliderChange = (values) => {
    const newRangeValues = { [filter.id]: [values.minValue, values.maxValue] };
    setRangeMinValue(values.minValue);
    setRangeMaxValue(values.maxValue);
    if (JSON.stringify(newRangeValues) !== JSON.stringify(rangeValues)) {
      setRangeValues({ [filter.id]: [values.minValue, values.maxValue] });
    }
  };

  const debouncedMutation = useRef(_.debounce(mutate, 1000))

  useEffect(() => {
    if (
      rangeValues &&
      JSON.stringify(rangeValues) !== JSON.stringify(prevRangeValuesRef.current)
    ) {
      if (filterIndex === -1) {
        setRangeMinValue(filter.rangeDefaultMin);
        setRangeMaxValue(filter.rangeDefaultMax);
        const updatedSegment = {
          ...segment,
          filters: JSON.stringify([
            ...parsedFilters,
            { [filter.id]: [filter.rangeDefaultMin, filter.rangeDefaultMax] },
          ]),
        };
        debouncedMutation.current(updatedSegment);
      } else {
        const values = rangeValues[filter.id].map((value) => value);
        const updatedFilter = { [filter.id]: values };
        const newFilters = [...parsedFilters];
        newFilters[filterIndex] = updatedFilter;
        debouncedMutation.current({ ...segment, filters: JSON.stringify(newFilters) });
      }
    }


  }, [rangeValues]);

  const handleMinInputChange = (e) => {
    const inputValue = Number(e.target.value);
    if (!isNaN(inputValue) && inputValue <= rangeMaxValue) {
      setRangeMinValue(inputValue);
      setRangeValues({ [filter.id]: [inputValue, rangeMaxValue] });
    }
  };

  const handleMaxInputChange = (e) => {
    const inputValue = Number(e.target.value);
    if (!isNaN(inputValue) && inputValue >= rangeMinValue) {
      setRangeMaxValue(inputValue);
      setRangeValues({ [filter.id]: [rangeMinValue, inputValue] });
    }
  };

  return (
    <>
      {filter && (
        <>
          <MultiRangeSlider
            ruler={false}
            step={filter && filter.rangeSteps}
            stepOnly={true}
            baseClassName="multi-range-slider-black"
            label={false}
            minCaption={
              filter?.path === "[address][zip_code]"
                ? rangeMinValue?.toString().substring(0, 3) +
                " " +
                rangeMinValue?.toString().substring(3, 5)
                : filter.suffix
                  ? rangeMinValue?.toLocaleString("en-US") + filter.suffix
                  : rangeMinValue?.toLocaleString("en-US")
            }
            maxCaption={
              filter?.path === "[address][zip_code]"
                ? rangeMaxValue?.toString().substring(0, 3) +
                " " +
                rangeMaxValue?.toString().substring(3, 5)
                : filter.suffix
                  ? rangeMaxValue?.toLocaleString("en-US") + filter.suffix
                  : rangeMaxValue?.toLocaleString("en-US")
            }
            min={rangeMin}
            max={rangeMax}
            minValue={rangeMinValue}
            maxValue={rangeMaxValue}
            onInput={handleSliderChange}
          />

          <Row className="mt-3 justify-content-between">
            <Col className="col-2">
              <Form.Control
                style={{ width: "100px", fontSize: "13px", height: "32px" }}
                type="number"
                id="rangeMinInput"
                value={rangeMinValue}
                onChange={handleMinInputChange}
              />
            </Col>

            <Col className="col-2">
              <Form.Control
                style={{ width: "100px", fontSize: "13px", height: "32px" }}
                type="number"
                id="rangeMaxInput"
                value={rangeMaxValue}
                onChange={handleMaxInputChange}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default FilterRangeSlider;
