import { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import { Col, Form } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";

const TargetGroupSize = ({
  activity,
  linkedinAdObject,
  setLinkedinAdObject,
  segment,
}) => {

  //Targetgroupsize
  const rangeSettings = {
    min: 0,
    max: segment?.size && segment?.size > 50000 ? 50000 : segment?.size,
    step: 1,
  };
  const [rangeSliderValue, setRangeSliderValue] = useState(
    activity && activity["targetGroupMaxSize"]
      ? activity["targetGroupMaxSize"]
      : rangeSettings.max < 50000
      ? rangeSettings.max
      : 50000
  );

  const handleRangeInput = (value) => {
    if (value > rangeSettings.max) return;
    if (value < rangeSettings.min) return;
    setRangeSliderValue(value);
  };

  useEffect(() => {
    setLinkedinAdObject({
      ...linkedinAdObject,
      targetGroupMaxSize: rangeSliderValue,
    });
  }, [rangeSliderValue]);

  return (
    <>
      <Col className="col-12">
        <Form.Label
          htmlFor="campaignTemplate"
          className="p-0 text-md"
          style={{ color: "#344054" }}
        >
          Begränsa målgruppsstorlek
        </Form.Label>
        <Form.Check
          className="py-1 big-switch"
          type="switch"
          defaultChecked={linkedinAdObject.enableTargetGroupMaxSize}
          onChange={() =>
            setLinkedinAdObject({
              ...linkedinAdObject,
              enableTargetGroupMaxSize: !linkedinAdObject.enableTargetGroupMaxSize,
            })
          }
        />
      </Col>
      {linkedinAdObject.enableTargetGroupMaxSize && (
        <Col className="col-12">
          <Form.Label
            htmlFor="activity_targetGroupMaxSize"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Välj max antal i målgrupp
          </Form.Label>
          <div className="d-flex justify-content-center flex-wrap">
            <Form.Control
              type="text"
              name="activity_targetGroupMaxSize"
              value={rangeSliderValue}
              className="py-3 text-xl semi-bold"
              required
              onChange={(e) =>
                handleRangeInput(!e.target.value ? 0 : +e.target.value)
              }
              style={{ flex: "1", minWidth: "100px" }}
            />
            <div
              className="d-flex flex-wrap justify-content-center"
              style={{ flex: 8, minWidth: "200px" }}
            >
              <div style={{ flex: "6" }} className="px-2  my-auto">
                <RangeSlider
                  thumbsDisabled={[true, false]}
                  value={[0, rangeSliderValue]}
                  onInput={(e) => setRangeSliderValue(e[1])}
                  min={rangeSettings.min}
                  max={50000}
                  className="single-thumb"
                  step={100}
                />
              </div>
              <Form.Control
                style={{ flex: "2", minWidth: "70px" }}
                disabled
                className="text-center"
                type="text"
                value={50000}
              />
            </div>
          </div>
          <span style={{ color: "grey", fontSize: "10px" }}>
            <MdInfoOutline />{" "}
            {rangeSettings.max >= 50000
              ? "Målgruppsstorleken är begränsad till max 50.000. Autotarget prioriterar den valda delen av målgruppen baserat på tidigare kampanjutgångar."
              : "Autotarget prioriterar den valda delen av målgruppen baserat på tidigare kampanjutgångar."}
          </span>
        </Col>
      )}
    </>
  );
};

export default TargetGroupSize;