import CustomerSuccessTable from "features/customers/success/CustomerSuccessTable"
import { Container, Row } from "react-bootstrap"

const CustomersSuccess = () => {
  return (
    <Container className="d-flex flex-column p-5 mx-auto gap-3" style={{ maxWidth: "1600px" }}>
      <Row>
        <span className="display-md">Kontouppföljning</span>
      </Row>
        <CustomerSuccessTable/>
    </Container>
  )

}

export default CustomersSuccess
