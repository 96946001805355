import Agent from "api/Agent"
import { useEffect } from "react";
import { Button, Card, Container, Row, Table } from "react-bootstrap";

const Overview = () => {
  const customerSettings = Agent.Customers.GET.CUSTOMERSETTINGS();
  useEffect(() => {
  if (customerSettings.isFetched) customerSettings.refetch();
  }, [])
  const data = customerSettings.data;

  const columns = [
    { channel: "TikTok", type: "Social Media", active: data?.isTikTokAdsAvailable, button : <Button className="p-2 text-sm bold " variant="link" onClick={() => {
          window.open(`https://business-api.tiktok.com/portal/auth?app_id=7313871730680791041&state=${data?.customerID}&redirect_uri=https%3A%2F%2Fapi.autotarget.se%2Fauth%2Ftiktok`, '_blank').focus()
        }}>
          Logga in med TikTok
        </Button>
  },
    { channel: "Meta", type: "Social Media", active: data?.isMetaAdsAvailable  },
    { channel: "Google", type: "Social Media", active: data?.isGoogleAdsAvailable  },
    { channel: "LinkedIn", type: "Social Media", active: data?.isLinkedInAdsAvailable  },
    { channel: "Instagram", type: "Social Media", active: data?.isInstagramAvailable  },
  ]
  return (
    <Container className="pt-2">
      <Row>
        <Card>
          <Table>
            <thead>
              <tr>
                <th>
                  Konto
                </th>
                <th>
                  Typ
                </th>
                <th>
                  Aktiverad
                </th>
                <th>
                  Anslut 
                </th>
              </tr>
            </thead>
            {columns?.map(( col, index ) =>
              <tr key={index}>
                <td>
                  {col?.channel} 
                </td>
                <td>
                  {col?.type} 
                </td>
                <td>
                  {
                    col?.active ? "Ja" : "Nej"
                  }
                </td>
                <td>
                  {col?.button} 
                </td>
              </tr>
            )}

            <tbody>
            </tbody>

          </Table>
        </Card>
      </Row>
    </Container>
  )
}

export default Overview
