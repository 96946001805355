import { Form } from "react-bootstrap";
import "./style.scss";

const TextInput = (props) => {
  return (
    <Form.Group className="input-group-lg">
      {props.label && <Form.Label>{props.label}</Form.Label>}

      <Form.Control
        as={props.as}
        rows={props.rows}
        style={{ color: "#667085" }}
        onChange={props.onChange}
        value={props.value}
        type={props.type}
        placeholder={props.placeholder}
      />
    </Form.Group>
  );
};

export default TextInput;
