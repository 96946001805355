import React, { useState, useEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  DrawingManagerF,
  CircleF,
  PolygonF,
} from "@react-google-maps/api";
import { Button, Spinner } from "react-bootstrap";
import { mapOptions, libraries } from "./assets/options";
import { useCallback } from "react";
import { useRef } from "react";
import { MdDelete } from "react-icons/md";

const defaults = {
  height: "100%",
};

/*
https://github.com/JustFly1984/react-google-maps-api/issues/2978
Compatibility Issue with React 18+. ReactStrictMode will disable rendering  Add prefix "F" to elements
*/
const GoogleMapTargetGroup = ({
  mapRef,
  height = defaults.height,
  locations,
}) => {
  const drawableRefs = useRef([]);
  const activeDrawableIndex = useRef();
  const drawingManagerRef = useRef();

  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: API_KEY,
    libraries,
  });
  const [center, setCenter] = useState(null);
  const [locationBounds, setLocationBounds] = useState([]);

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, []);

  const [drawable, setDrawable] = useState([]);

  useEffect(() => {
    if (!locations || !isLoaded) return;

    const geocodePromises = locations.map((_location) => {
      return new Promise((resolve, reject) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: _location }, (results, status) => {
          if (status === "OK") {
            const location = results[0].geometry.location;
            const bounds = new window.google.maps.LatLngBounds({
              lat: location.lat(),
              lng: location.lng(),
            });
            resolve(bounds);
          } else {
            console.error(
              `Geocode was not successful for the following reason: ${status}`
            );
            reject(status);
          }
        });
      });
    });

    Promise.all(geocodePromises).then((resolvedBounds) => {
      setLocationBounds(resolvedBounds);
    });
  }, [locations, isLoaded]);

  useEffect(() => {
    if (!locationBounds.length) return;

    const bounds = new window.google.maps.LatLngBounds();
    locationBounds.forEach((coord) => bounds.union(coord));
    setCenter(bounds.getCenter());

    const map = mapRef.current;
    bounds && map.fitBounds(bounds);
  }, [locationBounds]);

  if (!isLoaded) {
    return (
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Laddar in område...
      </Button>
    );
  }

  if (loadError) {
    return (
      <Button variant="danger" disabled>
        Ett fel uppstod vid inladdning av kartan... försök igen senare.
      </Button>
    );
  }

  const drawableOptions = {
    fillOpacity: 0.3,
    strokeOpacity: 1,
    fillColor: "#3ebee3",
    strokeColor: "#3ebee3",
    strokeWeight: 3,
    draggable: true,
    editable: true,
  };

  const drawingManagerOptions = {
    polygonOptions: drawableOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON],
    },
  };

  const onLoadDrawable = (drawable, index) => {
    drawableRefs.current[index] = drawable;
  };

  const onMouseDownDrawable = (index) => {
    activeDrawableIndex.current = index;
  };

  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = ($overlayEvent) => {
    drawingManagerRef.current.setDrawingMode(null);
    if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newDrawable = $overlayEvent.overlay
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      // start and end point should be same for valid geojson
      const startPoint = newDrawable[0];
      newDrawable.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      setDrawable([
        ...drawable,
        { coords: newDrawable, type: $overlayEvent.type },
      ]);
      activeDrawableIndex.current = drawable?.length
    }
  };

  const onDeleteDrawing = () => {
    if (drawable[activeDrawableIndex.current])
      return setDrawable(
        drawable.filter(
          (drawable, index) => index !== activeDrawableIndex.current
        )
      );

    if (drawable.length > 0)
      return setDrawable(drawable.slice(0, drawable?.length - 1));
  };

  const onEditDrawable = (index) => {
    const drawableRef = drawableRefs.current[index];
    if (drawableRef) {
      const coordinates = drawableRef
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const allDrawable = [...drawable];
      allDrawable[index].coords = coordinates;
      setDrawable(allDrawable);
    }
  };

  return (
    <div style={{ height: height, position: "relative" }}>
      {drawingManagerRef.current && drawable[activeDrawableIndex.current] && (
        <div
          className="d-flex justify-content-center align-items-center "
          style={{
            position: "absolute",
            top: "10px",
            right: "55px",
            zIndex: 10,
            width: "40px",
            height: "40px",
            backgroundColor: "#FFF",
            borderRadius: 0,
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
            color: "#CB4C4E",
            cursor: "pointer",
          }}
          onClick={onDeleteDrawing}
        >
          <MdDelete size={30} />
        </div>
      )}
      <GoogleMap
        options={mapOptions}
        mapContainerStyle={{
          minHeight: "27vh",
          width: "100%",
          height: "100%",
          borderRadius: "5px",
        }}
        center={center && center}
        onLoad={onLoad}
      >
        <DrawingManagerF
          onLoad={onLoadDrawingManager}
          onOverlayComplete={onOverlayComplete}
          // Drawing disabled until complete interaction
          options={{...drawingManagerOptions, drawingControl: false}}
        />
        {drawable
          .filter(
            (_drawable) =>
              _drawable?.type === window.google.maps.drawing.OverlayType.POLYGON
          )
          .map((iterator, index) => (
            <PolygonF
              key={index}
              onLoad={(event) => onLoadDrawable(event, index)}
              onMouseDown={() => onMouseDownDrawable(index)}
              onMouseUp={() => {
                onEditDrawable(index);
              }}
              onDragEnd={() => onEditDrawable(index)}
              options={{
                ...drawableOptions,
                strokeOpacity: activeDrawableIndex.current === index ? 1 : 0.1,
                editable: activeDrawableIndex.current === index,
              }}
              paths={iterator.coords}
              draggable
              editable
            />
          ))}

        {locationBounds &&
          locationBounds.map((coordinates, index) => (
            <MarkerF
              key={index}
              position={coordinates.getCenter()}
              options={{
                strokeColor: "#000",
                fillColor: "#2d6450",
                strokeOpacity: 0.4,
                strokeWeight: 1,
                zIndex: 3,
              }}
            />
          ))}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapTargetGroup;
