import AsyncSelect from "react-select/async";
import { getFilterSelectValues } from "features/filter/api";
import { useEffect } from "react";

//Rangeslider

const FilterRegularSelect = ({ filter, segment, parsedFilters, mutate }) => {
  const loadOptions = async (inputValue, filter) => {
    const filterOptions = await getFilterSelectValues(filter, segment).then(
      (response) => {
        return response.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      }
    );
    return filterOptions;
  };

  useEffect(() => {
    getFilterSelectValues(filter, segment);
  }, [segment?.filters]);

  const handleSelectChange = (selectedValues, filter) => {
    const value = {
      id: selectedValues.value,
      name: selectedValues.label,
      query: selectedValues.query,
    };
    const filterIndex = JSON.parse(segment?.filters).findIndex(
      (f) => f[filter.id]
    );

    // create a new filter with the selected value
    if (filterIndex === -1) {
      console.log({ value });
      if (value?.length < 1) {
        console.log("1", { parsedFilters });
        const updatedSegment = {
          ...segment,
          filters: JSON.stringify([...parsedFilters, { [filter.id]: value }]),
        };
        mutate(updatedSegment);
      } else {
        const updatedSegment = {
          ...segment,
          filters: JSON.stringify([...parsedFilters, { [filter.id]: value }]),
        };
        mutate(updatedSegment);
      }
    } else {
      const updatedFilter = { [filter.id]: value };
      const newFilters = [...parsedFilters];
      newFilters[filterIndex] = updatedFilter;
      mutate({ ...segment, filters: JSON.stringify(newFilters) });
    }
  };

  //Set default values for AsyncSelect based on segment.filters--------------
  const getDefaultSelectValues = (filters, filterId) => {
    const filter = filters?.find((f) => f[filterId]);
    if (filter) {
      const values = filter[filterId];
      return {
        value: values.id,
        label: values.name,
      };
    }
    return [];
  };

  const defaultValues = getDefaultSelectValues(
    segment && JSON.parse(segment?.filters),
    filter.id
  );

  return (
    <AsyncSelect
      key={filter.id}
      cacheOptions
      defaultOptions
      defaultValue={defaultValues}
      onChange={(selectedValues) => handleSelectChange(selectedValues, filter)}
      placeholder={filter.name === "Remarks" ? "Inga" : "Alla"}
      name={filter.name}
      loadOptions={(inputValue) => loadOptions(inputValue, filter)}
    />
  );
};

export default FilterRegularSelect;
