import { Col, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { getCustomerSettings } from "features/settings/api";
import { segmentTypes } from "features/segment/segment-details/assets/config";

const TargetTypeSelect = ({ linkedinAdObject, setLinkedinAdObject, segment }) => {
  const { data: settings } = useQuery("customer", getCustomerSettings);
  return (
    <>
      {segment?.type === segmentTypes.Company && (
        <Col className="col-12">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="py-3 text-xl semi-bold"
            onChange={(e) =>
              setLinkedinAdObject({
                ...linkedinAdObject,
                directMarketingTarget: e.target.value,
              })
            }
            defaultValue={1}
          >
            <option value={1}>Alla personer i styrelsen</option>
            <option value={2}>Ordförande, VD & styrelseledamöter</option>

            {settings?.isContactPersonsTargetingAvailable && (
              <option value={5}>Kontaktpersoner</option>
            )}
          </Form.Select>
        </Col>
      )}
      {segment?.type === segmentTypes.Person && (
        <Col className="col-12">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            className="py-3 text-xl semi-bold"
            aria-label="Default select example"
            onChange={(e) =>
              setLinkedinAdObject({
                ...linkedinAdObject,
                directMarketingTarget: e.target.value,
              })
            }
            defaultValue={3}
          >
            <option value={3}>Personer i målgruppen</option>
            <option value={4}>Övriga personer i hushållet</option>
            <option value={6}>Alla i hushållet</option>
          </Form.Select>
        </Col>
      )}
    </>
  );
};

export default TargetTypeSelect;
