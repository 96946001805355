import { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { TbFilterHeart } from "react-icons/tb";
import { HiArrowRightCircle } from "react-icons/hi2";
import Agent from "api/Agent";

const SaveFilterGroupModal = ({ showModal, setShowModal, segment, filter }) => {
  const [filterGroup, setFilterGroup] = useState([]);
  const [name, setName] = useState("");

  const getDefaultSelectValues = (filters, filterId) => {
    const filter = filters?.find((f) => f[filterId]);
    if (filter) {
      const values = filter[filterId];
      // Check if values is an array before calling map
      if (Array.isArray(values)) {
        return values.map((v) => ({
          query: v.query,
          value: v.id,
          label: v.name,
        }));
      }
    }
    return [];
  };

  useEffect(() => {
    if (segment && filter) {
      setFilterGroup(
        getDefaultSelectValues(
          segment && JSON.parse(segment?.filters),
          filter.id
        )
      );
    }
  }, [segment, filter]);

  const { mutate: postFiltergroup, isLoading } =
    Agent.FilterGroups.POST.FILTERGROUP();

  const filtergroupObject = {
    name,
    filterTypeID: filter.id,
    filterCategoryID: filter.filterCategoryID,
    filters: [
      ...filterGroup.map((filter) => {
        return {
          name: filter.label,
          query: filter.query,
          id: filter.value,
        };
      }),
    ],
  };

  const onClickHandler = async (e) => {
    e.preventDefault();
    postFiltergroup(filtergroupObject, {
      onSuccess: () => {
        setShowModal(false);
      },
    });
  };

  return (
    <Modal
      size="md"
      show={showModal}
      onHide={() => setShowModal(false)}
      style={{ border: "none" }}
    >
      <Modal.Body className="p-5">
        <Row className="gy-3">
          <Col className="col-12">
            <Row>
              <Col className="col-10">
                <h4 className="display-md semi-bold">Skapa filtergrupp</h4>
              </Col>
              <Col>
                <TbFilterHeart color="#b0e6fc" size={40} />
              </Col>
            </Row>
          </Col>
          <Col className="col-12">
            <Form>
              <Form.Group className="col-12 mb-4">
                <Form.Label
                  className="p-0 text-md"
                  style={{ color: "#344054" }}
                >
                  Namn på grupp
                </Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Min filtergrupp..."
                  className="py-3 semi-bold text-lg"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Ange ett namn
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Label className="p-0 text-md" style={{ color: "#344054" }}>
                Valda filter ({filter.name}):
              </Form.Label>
              <Col className="col-12 d-flex mb-4">
                {filterGroup && filterGroup.length > 0 ? (
                  <>
                    {filterGroup.map((filter) => (
                      <Col
                        className="selected-filters"
                        style={{
                          maxWidth: "fit-content",
                          marginRight: "8px",
                          fontSize: "14px",
                        }}
                      >
                        <p>{filter.label}</p>
                      </Col>
                    ))}
                  </>
                ) : (
                  "Inga valda filter"
                )}
              </Col>
              <Col className="col-12">
                <Button
                  className="mt-4 semi-bold p-3 w-100"
                  onClick={(e) => onClickHandler(e)}
                  type="submit"
                  variant="success"
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      Skapa Filtergrupp <HiArrowRightCircle size={20} />
                    </>
                  )}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default SaveFilterGroupModal;
