import { Row } from "react-bootstrap";
import EditDRTemplate from "../components/EditDRTemplate";

const DRStep1 = ({ campaign, activity, campaignIsLoading }) => {

  return (
    <Row className="justify-content-between mx-auto" style={{maxWidth:"1400px"}}>
      <Row >
        <EditDRTemplate campaign={campaign} activity={activity} campaignIsLoading={campaignIsLoading} />
      </Row>
    </Row>
  );
};

export default DRStep1;
