import { Card, Button, Row, Col, Image } from "react-bootstrap";
import holder from "assets/img/image-holder.png";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";

const AdContentDisplay = ({
  adTargetUrl,
  adButtonText,
  adTitle,
  adDescription,
  images,
}) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Card>
      <Carousel>
        {images.length > 1 ? (
          images.map((imageUrl, index) => (
            <Carousel.Item>
              <div className="d-flex align-items-center justify-content-center">
                <Image
                  src={imageUrl}
                  alt={`Image ${index}`}
                  fluid
                  style={{ height: "200px", objectFit: "cover", width: "100%" }}
                />
              </div>
            </Carousel.Item>
          ))
        ) : images.length === 0 ? (
          <Carousel.Item>
            <div className="d-flex align-items-center justify-content-center">
              <Image
                src={holder}
                alt={"Holder"}
                fluid
                style={{ height: "200px", objectFit: "cover", width: "100%" }}
              />
            </div>
          </Carousel.Item>
        ) : (
          <Carousel.Item>
            <div className="d-flex align-items-center justify-content-center">
              <Image
                src={images[0]}
                style={{ height: "200px", objectFit: "cover", width: "100%" }}
              />
            </div>
          </Carousel.Item>
        )}
      </Carousel>
      <Card.Body>
        <Card.Title
          className="mb-3 semi-bold display-xs"
          style={{ color: "#027CC1" }}
        >
          {adTitle ? adTitle : "Annonsrubrik"}
        </Card.Title>
        <Card.Text className="mb-3" style={{ color: "#101828" }}>
          {adDescription ? adDescription : "Här är en text som beskriver eventet/produkten som kampanjen marknadsför..."}
        </Card.Text>
        <Button variant="primary">{adButtonText ? adButtonText : "Läs mer här!"}</Button>
      </Card.Body>
    </Card>
  );
};

export default AdContentDisplay;
