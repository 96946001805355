import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const EmailTemplate = {
  GET: {
    ALL: ({ isGeneric, search }) => {
      const queryParams = [
        isGeneric !== undefined && `isGeneric=${isGeneric ? true : false}`,
        search && `search=${search}`,
      ]?.filter((x) => x);


      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";


      return useQuery({
        queryFn: async () => axios
          .get(`emailtemplates/v1${queryString}`)
          .then(({ data }) => (data))
          .catch((err) => err),
        queryKey: ["templates", "templates" + queryString],
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET templates \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta mallar till email kampanj");
        },
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: () =>
          criteria(id)
            .then(() =>
              axios
                .get(`emailtemplates/v1/${id}`)
                .then(({ data }) => Promise.resolve(data))
                .catch((err) => Promise.reject(err))
            )
            .catch((badInput) => Promise.resolve(badInput)),
        queryKey: ["template", id],
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET template with query: ${id} \n Error: `,
            error
          );
          toast.error("Det gick inte att hämta email utskick");
        },
      });
    },
    PREVIEW: (MailChimpID) => {
      return useQuery({
        queryFn: () =>
          criteria(MailChimpID)
            .then(() =>
              axios
                .get(`emailtemplates/v1/preview/${MailChimpID}`)
                .then(({ data }) => Promise.resolve(data))
                .catch((err) => Promise.reject(err))
            )
            .catch((badInput) => Promise.resolve(badInput)),
        queryKey: [MailChimpID],
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET template preview with query: ${MailChimpID} \n Error: `,
            error
          );
        },
      });
    },
  },
  POST: {
    TEMPLATE: () => {
      const queryClient = useQueryClient();
      return useMutation(
        async ({ html, design, name, isGeneric }) =>
          axios
            .post(`/emailtemplates/v1/`, {
              html: html,
              json: design,
              name: name,
              mailChimpID: "",
              isGeneric: isGeneric ? true : false
            }),


        {
          onSuccess: (res) => {
            queryClient.invalidateQueries(["templates"]);
            return res?.data
          },
          onError: (error) => {
            console.warn(`Failed to POST new email template\n Error: `, error);
            toast("Ett fel uppstod vid uppladdning av email utskick", {
              type: "error",
            });
          },
        }
      );
    },
  },
  PUT: {
    TEMPLATE: () => {
      const queryClient = useQueryClient();
      return useMutation(
        async (template) =>
          axios
            .put(`/emailtemplates/v1`, { ...template, json: template?.design ?? template?.json }, { headers: { "Content-Type": 'application/json-patch+json', "accept": "text/plain" } })
            .then(() => template.id)
            .catch((e) => ({ error: e, id: template.id })),
        {
          onSuccess: (id) => {
            queryClient.invalidateQueries(["templates"]);
            queryClient.invalidateQueries(["template", id]);
          },
          onError: ({ error, id }) => {
            console.warn(`Failed to PUT template ${id} \n Error: `, error);
          },
        }
      );
    },
  },
  DEL: {
    ID: (id) => {
      const queryClient = useQueryClient();
      return useMutation((id) => axios.delete(`/emailtemplates/v1/${id}`), {
        onSuccess: () => {
          queryClient.invalidateQueries("templates");
          queryClient.removeQueries(["template", id]);
        },
        onError: (error) => {
          console.warn(
            `Failed to delete email template ${id} \n Error:`,
            error
          );
          toast("Utskicket gick inte att ta bort", { type: "error" });
        },
      });
    },
  },
};
