import { Col, Form } from "react-bootstrap";
import { useQuery } from "react-query";
import { getCustomerSettings } from "features/settings/api";
import { segmentTypes } from "features/segment/segment-details/assets/config";
import { useEffect, useState } from "react";

const TargetTypeSelect = ({ segment, onChange, onMount, defaultValue }) => {
  const { data: settings } = useQuery("customer", getCustomerSettings);
  const [value, setValue] = useState(defaultValue ?? (segment?.type === segmentTypes.Company ? 1 : 3));

  const handleChange = (val) => {
    setValue(val)
    if (typeof onChange == "function") onChange(val)
  }
  useEffect(()=> {
    if (typeof onMount == "function") onMount(value)
  },[])
  return (
        <Col className="col-12">
          <Form.Label
            htmlFor="targetadress"
            className="p-0 text-md semi-bold"
          >
            Typ av mottagare
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            className="py-3 text-md semi-bold"
            onChange={(e) =>
                handleChange(e.target.value)
            }
            value={value}
          >
          {segment?.type === segmentTypes.Company && <>
            <option value={1}>Alla personer i styrelsen</option>
            <option value={2}>Beslutsfattare</option>

            {settings?.isContactPersonsTargetingAvailable && (
              <option value={5}>Kontaktpersoner</option>
            )}
          </>}
          {segment?.type === segmentTypes.Person && <>

            <option value={3}>Personer i målgruppen</option>
            <option value={4}>Övriga personer i hushållet</option>
            <option value={6}>Alla i hushållet</option>
          </>}
          </Form.Select>
        </Col>
  );
};

export default TargetTypeSelect;
