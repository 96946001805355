import { useState, useEffect } from "react";
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { TbArrowNarrowRight } from "react-icons/tb";

const FilterDatePicker = ({ filter, segment, parsedFilters, mutate, setDynamicFilter }) => {
  //Set default values for AsyncSelect based on segment.filters--------------
  const getDefaultValues = (filters, filterId) => {
    const foundfilter = filters && filters?.find((f) => f[filterId]);

    if (foundfilter) {
      const values = foundfilter[filterId];
      if (values[0] && values[0].includes("latest")) {
        setDynamicFilter(true);
      } else {
        setDynamicFilter(false);
      }
      return values;
    }
    return filter;
  };

  const defaultValues = getDefaultValues(
    segment && JSON.parse(segment?.filters),
    filter.id
  );

  const [startDate, setStartDate] = useState(
    defaultValues ? defaultValues[0] : null
  );
  const [endDate, setEndDate] = useState(
    defaultValues[1] ? defaultValues[1] : startDate ? startDate : null
  );

  const handleChangeStartDate = (value, filter) => {
    updateFilter({ newStartDate: value.toString() });
    setStartDate(value.toString());
  };
  const handleChangeEndDate = (value, filter) => {
    updateFilter({ newEndDate: value.toString() });
    setEndDate(value.toString());
  };

  const updateFilter = ({ newStartDate, newEndDate }) => {
    const _startDate = newStartDate ?? startDate;
    const _endDate = newEndDate ?? endDate;
    const filterIndex =
      segment && JSON.parse(segment?.filters).findIndex((f) => f[filter.id]);

    if (_endDate === null && _startDate) {
      setEndDate(_startDate);
    }

    if (_startDate || _endDate) {
      if (filterIndex === -1) {
        // create a new filter with the selected value
        const updatedSegment = {
          ...segment,
          filters:
            parsedFilters &&
            JSON.stringify([
              ...parsedFilters,
              { [filter.id]: [_startDate, _endDate] },
            ]),
        };
        mutate(updatedSegment);
      } else {
        // update the existing filter with the selected value
        const updatedFilter = { [filter.id]: [_startDate, _endDate] };
        const newFilters = parsedFilters && [...parsedFilters];
        // const updatedFilter = newFilters && newFilters[filterIndex];
        newFilters[filterIndex] = updatedFilter;
        mutate({ ...segment, filters: JSON.stringify(newFilters) });
      }
    } else {
      return;
    }
  };

  return (
    <Form.Group controlId="exampleForm.ControlInput1">
      <Row className="mt-2">
        <Col className="col-5">
          <p className="semi-bold" style={{ color: "rgb(174 174 174)" }}>
            Från:
          </p>
          <InputGroup>
            <Form.Control
              onChange={(e) => handleChangeStartDate(e.target.value, filter)}
              className="campaign-input"
              defaultValue={startDate}
              placeholder={startDate}
              type="date"
              style={{ backGround: "#f9f9f9" }}
            />
          </InputGroup>
        </Col>
        <Col className="col-2 d-flex justify-content-center align-items-end">
          <TbArrowNarrowRight size={40} color="#ddd" />
        </Col>
        <Col className="col-5">
          <p className="semi-bold" style={{ color: "rgb(174 174 174)" }}>
            Till:
          </p>
          <InputGroup>
            <Form.Control
              onChange={(e) => handleChangeEndDate(e.target.value, filter)}
              className="campaign-input"
              defaultValue={endDate}
              placeholder={endDate}
              type="date"
            />
          </InputGroup>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default FilterDatePicker;
