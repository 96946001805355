import moment from "moment";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const EditEmailSchedule = ({ ad }) => {

  return (
    <Row className="mt-1 row-cols-1 g-3">
      <Col>
        <Col>
          <Form.Label
            htmlFor="date"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Datum för utskick
          </Form.Label>
          <Form.Control
            type="date"
            name="startDate"
            id="date"
            defaultValue={moment(ad?.startDate).isValid() ? moment(ad?.startDate).format("YYYY-MM-DD") : undefined}
            required
          />

          <Form.Control.Feedback type="invalid">
            Ange ett datum för utskick.
          </Form.Control.Feedback>
        </Col>

        <Col>
          <Form.Label
            htmlFor="time"
            className="p-0 text-md"
            style={{ color: "#344054" }}
          >
            Tid för utskick
          </Form.Label>
          <Form.Control
            type="time"
            name="startTime"
            id="time"
            defaultValue={moment(ad?.startDate).isValid() ? moment(ad?.startDate).format("HH:mm") : undefined}
            required
          />

          <Form.Control.Feedback type="invalid">
            Ange en tid för utskick
          </Form.Control.Feedback>
        </Col>
      </Col>
    </Row>
  );
};

export default EditEmailSchedule;
