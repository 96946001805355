import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";
import axios from "api/axios";

export const Tags = {
  GET: {
    ALL: () => {
      return useQuery({
        queryFn: () => axios.get(`tags/v1`).then(({ data }) => data),
        queryKey: ["tags"],
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (error) => {
          console.warn(`Failed to GET tags \n Error: `, error);
        },
      });
    },
    ID: (id) => {
      return useQuery({
        queryFn: async () => {
          const result = await criteria(id).catch((badInput) => badInput);
          if (result !== id) return;
          return axios.get(`/tags/v1/${id}`).then(({ data }) => data);
        },
        queryKey: ["tagValues", id],
        //!Creates updating bug #3295
        // staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) => {
          console.warn(`Failed to get tags of '${id}'\n Error: `, err);
        },
      });
    },
  },
};
