import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { criteria } from "api/Agent";

export const Files = {
  GET: {
    FILES: () => {
      return useQuery({
        queryFn: async () => axios.get(`files/v1`).then(({ data }) => data),
        queryKey: ["files"],
        retry: false,
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(`Failed to GET files \n Error: `, error);
        },
      });
    },
    ID: (id) => {
      const queryClient = useQueryClient();
      return useQuery({
        queryFn: () =>
          Promise.resolve(
            criteria(id).then(() =>
              axios.get(`/files/v1/${id}`).then(({ data }) => data)
            )
          ).catch((x) => Promise.reject(x)),
        queryKey: ["file", id],
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30, // 30 minutes
        retry: false,
        onError: (err) => {
          console.warn(`Failed to get file of '${id}'\n Error: `, err);
        },
        
      });
    },
  },
  POST: () => {
    const queryClient = useQueryClient();
    return useMutation(
      ({ file }) =>
        Promise.resolve(
          axios
            .post(`/files/v1`, file, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => data)
        ).catch((err) => Promise.reject({ error: err })),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("files");
          queryClient.invalidateQueries("file");
        },
        onError: (error) => {
          console.warn(`Failed to POST file \n Error: `, error);
          toast(`[Fel] Det gick inte att skapa en ny fil`, {
            type: "error",
          });
        },
      }
    );
  },
};
