import { useEffect, useState } from "react";
import { Col, Row, Spinner, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  options,
  columnsGoals,
  dataGoals,
  columnsGames,
} from "../data/stepsData";
import CampaignRadioBox from "../components/CampaignRadioBox";
import "../style.scss";
import StepTopRow from "../components/StepTopRow";
import StepBottomRow from "../components/StepBottomRow";
import CampaignTableSelect from "../components/CampaignTableSelect";
import Agent from "api/Agent";
import { useQuery } from "react-query";
import { AiOutlinePlus } from "react-icons/ai";
import { getCustomerSettings } from "features/settings/api";
import CreateOfferOffCanvas from "features/offers/create-offer/CreateOfferOffCanvas";
import { useMediaQuery } from "react-responsive";
import OffersSelectBox from "../components/OfferSelectBox/OffersSelectBox";

const TypeOfCampaign = () => {
  const isXsScreen = useMediaQuery({ maxWidth: 900 });
  const paddingBottomStyle = isXsScreen ? { paddingBottom: "300px" } : {};
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: campaign } = Agent.Campaigns.GET.ID(id);
  const offerID = campaign?.offerID;

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showExpired, setShowExpired] = useState(false);
  const rowsPerPage = 4;
  const { data: offers, isLoading } = Agent.Offers.GET.ALL(
    currentPage,
    rowsPerPage,
    search,
    showExpired
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Set campaign type-----------------------------------------
  const [typeOfCampaign, setTypeOfCampaign] = useState(offerID ? 1 : 0);
  const [conversionGoal, setConversionGoal] = useState(0);
  const [offer, setOffer] = useState(null);
  const [goalAmount, setGoalAmount] = useState(100);

  const onClickHandler = (option) => {
    setTypeOfCampaign(option?.value);
  };

  //Set campaign type-----------------------------------------
  useEffect(() => {
    if (offerID) {
      setOffer(offerID);
    }
  }, []);

  useEffect(() => {
    if (campaign) {
      setTypeOfCampaign(offerID ? 1 : 0);
      if (offerID) {
        setOffer(campaign.offerID);
      }
      setConversionGoal(campaign.conversionType ? campaign.conversionType : 0);
    }
  }, [campaign]);

  //Set offer-----------------------------------------------

  const onClickHandlerOffer = (id) => {
    setOffer(id);
  };

  const onClickHandlerConversion = (id) => {
    setConversionGoal(id);
  };

  //Create offer
  const { data: settings } = useQuery("customer", getCustomerSettings);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Submit-------------------------------------------------
  //Update campaign function
  const [loading, setLoading] = useState(false);
  const { mutate: updateCampaign } = Agent.Campaigns.PUT.CAMPAIGN();
  const mutate = (campaign) =>
    updateCampaign(campaign, {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        setLoading(false);
        navigate(`/campaigns/create-campaign/${id}/target-group`);
      },
    });

  //onClick
  const onClickSubmit = () => {
    const updatedCampaign = {
      ...campaign,
      type: typeOfCampaign,
      offerID: offer,
      conversionType: conversionGoal,
      conversionGoal: goalAmount,
      completedSteps: !campaign.completedSteps.some(
        (step) => step === "type-of-campaign"
      )
        ? [...campaign.completedSteps, "type-of-campaign"]
        : [...campaign.completedSteps],
    };
    mutate(updatedCampaign);
  };

  return (
    <Row className="m-0 px-0 pt-0" style={paddingBottomStyle}>
      {/* TOP----------------------- */}
      <StepTopRow heading="Typ av kampanj" />

      {/* MIDDLE----------------------- */}

      {/* Choose Type Of Campaign */}
      <Col className="col-12 p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h4 className="display-xs bold">Välj ett Mål</h4>
          </Col>
          <Col className="col-12">
            <Row className="justify-content-between gx-4">
              {options.map((option) => (
                <Col key={option.value} onClick={() => onClickHandler(option)}>
                  <CampaignRadioBox
                    {...option}
                    value={typeOfCampaign}
                    current={option}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
      {/* If current type 1 */}

      <Col className="p-5">
        <Row className="gy-4">
          <Col className="col-12">
            <h4 className="display-xs bold">Välj ett konverteringsmål</h4>
          </Col>
          <Col className="col-12">
            <div className="table-responsive">
              <CampaignTableSelect
                tableName={"conversionGoals"}
                columns={columnsGoals}
                data={dataGoals}
                value={conversionGoal}
                onClick={onClickHandlerConversion}
                setGoalAmount={setGoalAmount}
                goalAmount={goalAmount}
              />
            </div>
          </Col>
        </Row>
      </Col>
      {typeOfCampaign === 1 && (
        <Col className="p-5">
          <Row className="gy-4">
            <Col className="col-12">
              <Row className="justify-content-between">
                <Col className="col-10">
                  <h4 className="display-xs bold">
                    Välj ett specifikt erbjudande
                  </h4>
                </Col>
                <Col className="d-flex justify-content-end ">
                  <Button variant="success" onClick={handleShow}>
                    <AiOutlinePlus />
                  </Button>
                  <CreateOfferOffCanvas
                    settings={settings}
                    show={show}
                    handleClose={handleClose}
                  />
                </Col>
              </Row>
            </Col>
            {isLoading ? (
              <Spinner />
            ) : (
              <Col className="col-12">
                <OffersSelectBox
                  offerID={offerID}
                  onSelect={onClickHandlerOffer}
                />
                {/*
                    <div className="table-responsive">
                      <CampaignTableSelect
                        setShowExpired={setShowExpired}
                        showExpired={showExpired}
                        tableName={"offers"}
                        onClick={onClickHandlerOffer}
                        value={offer}
                        columns={columnsGames}
                        data={offers}
                        handleOffersPageChange={handlePageChange}
                        currentPageOffers={currentPage}
                      />
                    </div>
                  */}
              </Col>
            )}
          </Row>
        </Col>
      )}

      {/* BOTTOM----------------------- */}
      <StepBottomRow
        onClickSubmit={() => onClickSubmit()}
        noGoBack
        loading={loading}
      />
    </Row>
  );
};

export default TypeOfCampaign;
