import axios from "api/axios";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";

const PATH = "/filters/v1/"

export const Filters = {
  GET: {
    CATEGORIES: (type) => {
      const queryParams = [
        type !== undefined && `type=${type}`,
      ]?.filter((x) => x);

      const queryString =
        queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

      return useQuery({
        queryFn: () =>
          axios.get(`filters/v1/categories/${queryString}`).then(({ data }) => data),
        queryKey: ["filters categories", queryString],
        retry: false,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        onError: (error) => {
          console.warn(
            `Failed to GET filters categories with query: ${queryString} \n Error: `,
            error
          );
          toast.error("[Fel] Det gick inte att hämta filterkategori");
        },
      });



    }
  },
  POST: {
    FILTER:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (filter) => {
            const result = await axios.post(`${PATH}`, filter)
            return result?.data;
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: ["filterTypes"] });
              queryClient.invalidateQueries({ queryKey: ["filterType"] });
              queryClient.invalidateQueries({ queryKey: ["filters"] });
            },
            onError: (error) => {
              console.warn(`Failed to POST filter \n Error: `, error);
              toast(`Det gick inte att skapa filter `, {
                type: "error",
              });
            },
          }
        );
      },
  },
  PUT: {
    FILTER:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (filter) => {
            const result = await axios.put(`${PATH}`, filter)
            return { id: filter?.id, data: result?.data };
          },
          {
            onSuccess: ({ id }) => {
              queryClient.invalidateQueries({ queryKey: [id] });
              queryClient.invalidateQueries({ queryKey: ["filterTypes"] });
              queryClient.invalidateQueries({ queryKey: ["filterType"] });
              queryClient.invalidateQueries({ queryKey: ["filters"] });
            },
            onError: (error) => {
              console.warn(`Failed to PUT filter \n Error: `, error);
              toast(`Det gick inte att uppdatera filter `, {
                type: "error",
              });
            },
          }
        );
      },
  },
  DEL: {
    FILTER:
      () => {
        const queryClient = useQueryClient();
        return useMutation(
          async (id) => {
            const result = await axios.delete(`${PATH}${id}`)
            return { id: id, data: result?.data };
          },
          {
            onSuccess: ({ id }) => {
              queryClient.invalidateQueries({ queryKey: [id] });
              queryClient.invalidateQueries({ queryKey: ["filterTypes"] });
              queryClient.invalidateQueries({ queryKey: ["filterType"] });
              queryClient.invalidateQueries({ queryKey: ["filters"] });
            },
            onError: (error) => {
              console.warn(`Failed to DEL filter \n Error: `, error);
              toast(`Det gick inte att ta bort filter `, {
                type: "error",
              });
            },
          }
        );
      },
  }
};
