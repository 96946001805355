import React, { useEffect } from "react";
import { useState } from "react";
import { MdCancel, MdCheckBox, MdSearch } from "react-icons/md";
import { Table, Row, Col, Spinner, Button, Form } from "react-bootstrap";
import Agent from "api/Agent";

const Settings = ({ id }) => {
  const {
    data: status,
    isLoading: statusLoading,
    refetch: refetchStatus,
    isRefetching,
  } = Agent.Customers.GET.STATUS(id);

  const { data } = Agent.Customers.GET.CUSTOMERSETTINGSID(id);

  const [enableCompanyPurchase, setEnableCompanyPurchase] = useState(
    data && data.enableCompanyPurchaseProcessing
      ? data.enableCompanyPurchaseProcessing
      : false
  );
  const [enablePersonPurchase, setEnablePersonPurchase] = useState(
    data && data.enablePersonPurchaseProcessing
      ? data.enablePersonPurchaseProcessing
      : false
  );
  const [metaToken, setMetaToken] = useState(
    data && data.metaAccessToken ? data.metaAccessToken : ""
  );
  const [linkedinToken, setLinkedinToken] = useState(
    data && data.linkedInAccessToken ? data.linkedInAccessToken : ""
  );
  const [tiktokToken, setTiktokToken] = useState(
    data && data.tikTokAccessToken ? data.tikTokAccessToken : ""
  );
  const [googleToken, setGoogleToken] = useState(
    data && data.googleAccessToken ? data.googleAccessToken : ""
  );

  useEffect(() => {
    if (data) {
      setEnableCompanyPurchase(data.enableCompanyPurchaseProcessing);
      setEnablePersonPurchase(data.enablePersonPurchaseProcessing);
      setMetaToken(data.metaAccessToken);
      setLinkedinToken(data.linkedInAccessToken);
      setTiktokToken(data.tikTokAccessToken);
      setGoogleToken(data.googleAccessToken);
    }
  }, [data]);

  const { mutate: setupPost, isLoading: postSetupLoading } =
    Agent.Customers.POST.SETUP(id);

  const { mutate: settingsPut, isLoading: putSettingsLoading } =
    Agent.Customers.PUT.CUSTOMERSSETTINGS(
      {
        ...data,
        metaAccessToken: metaToken,
        googleAccessToken: googleToken,
        linkedInAccessToken: linkedinToken,
        tikTokAccessToken: tiktokToken,
        enableCompanyPurchaseProcessing: enableCompanyPurchase,
        enablePersonPurchaseProcessing: enablePersonPurchase,
      },
      id
    );

  const saveChanges = () => {
    // setupPost();
    settingsPut();
  };

  const runSetup = () => {
    setupPost();
  };

  return (
    <Col className="col-12">
      <Row className="mb-5 g-2">
        <Col className="col-3">
          <h5 className="bold">Konfigurationsstatus</h5>
        </Col>

        <Col className="col-3">
          <Button onClick={() => refetchStatus()} variant="success">
            Kontrollera
          </Button>
        </Col>
        <Col className="col-12">
          {statusLoading || isRefetching ? <Spinner /> : ""}

          {status &&
            !statusLoading &&
            !isRefetching &&
            status.map((s) => (
              <h6>
                {s.ok ? (
                  <MdCheckBox style={{ color: "green" }} />
                ) : (
                  <MdCancel style={{ color: "red" }} />
                )}{" "}
                {s.message}{" "}
                {!s.ok && <span style={{ color: "red" }}>({s.error})</span>}
              </h6>
            ))}
        </Col>
      </Row>

      <Row className="mb-5 g-3">
        <Col className="col-12">
          <h5 className="bold">Maskininlärning</h5>
        </Col>
        <Col className="col-12">
          <Form.Check
            type="switch"
            label="Företagsköp"
            checked={enableCompanyPurchase}
            onClick={() => setEnableCompanyPurchase(!enableCompanyPurchase)}
          />
        </Col>
        <Col className="col-12">
          <Form.Check
            type="switch"
            label="Personköp"
            checked={enablePersonPurchase}
            onClick={() => setEnablePersonPurchase(!enablePersonPurchase)}
          />
        </Col>
      </Row>
      <Row className="mb-3 g-4">
        <Col className="col-12">
          <h5 className="bold">Sociala Mediekonton</h5>
        </Col>
        <Col className="col-12">
          <Row>
            <Col className="col-1">Meta token</Col>
            <Col className="col-4">
              <Form.Control
                type="text"
                label="Företagsköp"
                value={metaToken}
                onChange={(e) => setMetaToken(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <Row>
            <Col className="col-1">Tiktok token</Col>
            <Col className="col-4">
              <Form.Control
                type="text"
                label="Företagsköp"
                value={tiktokToken}
                onChange={(e) => setTiktokToken(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <Row>
            <Col className="col-1">Linkedin token</Col>
            <Col className="col-4">
              <Form.Control
                type="text"
                label="Företagsköp"
                value={linkedinToken}
                onChange={(e) => setLinkedinToken(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12">
          <Row>
            <Col className="col-1">Google token</Col>
            <Col className="col-4">
              <Form.Control
                type="text"
                label="Företagsköp"
                value={googleToken}
                onChange={(e) => setGoogleToken(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col className="col-4 my-5">
        <Row>
          <Col>
            <Button variant="success" className="w-100" onClick={saveChanges}>
              {putSettingsLoading ? <Spinner /> : "Spara ändringar"}
            </Button>
          </Col>
          <Col>
            <Button
              variant="warning"
              className="w-100"
              onClick={() => setupPost()}
            >
              {postSetupLoading ? <Spinner /> : "Kör Setup"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default Settings;
