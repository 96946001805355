import React from 'react';
import { Card, Button, Row, Col, Form, InputGroup, Spinner, Stack, Alert } from 'react-bootstrap';
import Agent from 'api/Agent';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { MdCancel, MdCheck, MdDelete, MdOutlineEdit } from 'react-icons/md';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import { useContext } from 'react';
import { TemplateModalContext } from './TemplateModal';

function TemplateCard({ template, onClick, style = { width: '240px' } }) {
  const { setRemovable, removable: isRemovable, setRename, rename: isNameEditable } = useContext(TemplateModalContext)
  const [editName, setEditName] = useState(undefined)

  const { data: preview, refetch } = Agent.EmailTemplate.GET.PREVIEW(template?.mailChimpID);
  const deleteMutation = Agent.EmailTemplate.DEL.ID();
  const putMutation = Agent.EmailTemplate.PUT.TEMPLATE();

  const date = template?.modifiedOn && moment(template?.modifiedOn)

  useEffect(() => {
    setEditName(undefined)

  }, []);

  const handleClick = () => {
    try {
      onClick({ id: template?.id })
    } catch (error) {
      console.warn("Unable to setTemplate", error)
    }
  }
  const resetSettingToggles = () => {
    setRemovable(false);
    setRename(false);
  }
  const onRequest = (type) => {
    try {
      resetSettingToggles();
    } catch (error) {
      console.warn("unable to notify request", error)

    }
  }


  const handleRemoval = () => {
    toast.promise(
      deleteMutation.mutateAsync(template?.id,
        { onSuccess: () => onRequest() }
      ),
      {
        success: `[${template.name}] borttagen`,
        error: "Ett fel uppstod. Försök igen.. ",
        pending: "Tar bort mall.. laddar.."
      });
  }
  const handleEditName = (newName) => {
    toast.promise(
      putMutation.mutateAsync({ ...template, name: newName },
        { onSuccess: () => onRequest() }),
      {
        success: `[${newName}] uppdaterad`,
        error: "Ett fel uppstod. Försök igen.. ",
        pending: "Uppdaterar mall.. laddar.."
      });
  }
  const debouncedPreview = debounce(() => {
    if (preview && !preview?.thumbnail) {
      refetch();
      debouncedPreview();
    }
  }, 60000);

  const refetchMissingPreview = () => {
    if (preview && !preview?.thumbnail) {
      debouncedPreview();
    }
  }

  refetchMissingPreview();

  return (
    <div className="d-flex justify-content-center px-2 mx-auto" style={style}>

      <Card className='shadow my-3'>

        {/*
          !!isRemovable &&
          <div className='d-flex justify-content-end ' style={{ position: "absolute", width: "100%", top: 0 }}>
            <Button onClick={() => handleRemoval(template?.id)} className='rounded-circle p-0 ' variant='danger' style={{ height: "25px", width: "25px", border: "none", position: "relative", right: "-12px", top: "-12px" }}>
              <div className='p-0 d-flex align-items-center justify-content-center' >
                <MdCancel className='w-100 h-100' />
              </div>
            </Button>
          </div>
        */}
        <Card.Header className='d-flex flex-column align-items-center'>

          {
            !!isNameEditable ?
              <InputGroup className="my-2">
                <Form.Control
                  placeholder={template?.name}
                  value={editName}
                  onChange={(e) => { setEditName(e?.target?.value) }}
                  style={{ userSelect: "text" }}
                />
                <Button variant='success' onClick={() => handleEditName(editName)} disabled={!editName || editName === template?.name} >
                  <div className='p-0 d-flex align-items-center justify-content-center' >
                    <MdCheck className='w-100 h-100' />
                  </div>
                </Button>
              </InputGroup>
              :
              <Card.Title style={{ width: '200px' }} className="text-center text-lg">
                {template?.name}
              </Card.Title>
          }
          {
            date?.isValid() &&
            <small className='text-center text-sm'>{date.format("YYYY-MM-DD")} </small>
          }
        </Card.Header>
        <Card.Body className='d-flex flex-column align-items-center justify-content-between row-cols-1'>
          <div style={{ height: '250px' }} className="p-1 d-flex justify-content-center align-items-center">
            {
              !!preview?.thumbnail && !!template?.mailChimpID ?
                <Card.Img
                  variant="top"
                  src={preview?.thumbnail ?? 'https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png'}
                  placeholder={'https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png'}
                  style={{ objectFit: 'contain', height: '100%' }}
                />
                :
                <Stack className='align-items-center '>
                  <Alert variant="info" className='text-sm text-center' s>
                    Förgranskningen skapas...
                  </Alert>

                  <Spinner className='my-auto' />
                </Stack>
            }
          </div>
          <div className='d-flex justify-content-center flex-column gap-2'>

            {isRemovable ?

              <Button className='px-2' variant="danger" onClick={() => handleRemoval()} disabled={deleteMutation.isLoading}>
                Radera
              </Button>
              :
              <Button className='px-2' variant="success" onClick={() => handleClick()} disabled={putMutation.isLoading}>
                Använd
              </Button>}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TemplateCard;

