import axios from "api/axios";
import { endPoints } from "api/endPoints";

const URL = endPoints.visuals.URL;

export const getVisualData = async (GuidID, startDate, endDate, filters, limit) => {
  let path = `${URL}${GuidID}/data?from=${startDate}&to=${endDate}`;
  if (filters) path += `&filters=${JSON.stringify(filters)}`;
  if (limit) path += `&limit=${limit}`;
  const response = await axios.get(path);
  return response.data;
};
export const getAttributesFromEntity = async (entityID) => {
  const response = await axios.get(
    `${endPoints.visuals.ATTRIBUTES}${entityID}`
  );
  return response.data;
};

export const updateVisual = async (visual, visualId) => {
  const response = await axios.put(`${URL}${visualId}`, visual);
  return response.data;
};

export const getVisual = async (visualId) => {
  const response = await axios.get(`${URL}${visualId}`);
  return response.data;
};

export const getVisualSelectOptions = async (visualId, startDate, endDate) => {
  const response = await axios.get(
    `${URL}${visualId}/options?from=${startDate}&to=${endDate}`
  );
  return response.data;
};
