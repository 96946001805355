import { Col, Row } from "react-bootstrap";
import MobilePreview from "../../../components/MobilePreview";
import "../../../style.scss";
import { forwardRef } from "react";
import { statusBadgeSetter } from "features/campaign/campaign-details/options";
import EditLinkedinAccount from "../components/EditLinkedinAccount";

const LinkedinStep0 = forwardRef(
  (
    { activity, linkedinAdObject, setLinkedinAdObject, segment, segmentIsLoading },
    ref
  ) => {
    return (
      <Row className=" row-cols-1 row-cols-xl-2">
        {/* CONTENT LEFT---------- */}
        <Col>
          <Row className="d-flex align-items-center row-cols-auto flex-wrap-reverse">
            <Col className="float-start">
              <span className="display-sm semi-bold">
                Välj Meta konto och typ
              </span>
            </Col>
            <Col className=" float-start ms-auto">
              {statusBadgeSetter(activity)}
            </Col>
          </Row>
          <EditLinkedinAccount
            segmentIsLoading={segmentIsLoading}
            segment={segment}
            activity={activity}
            linkedinAdObject={linkedinAdObject}
            setLinkedinAdObject={setLinkedinAdObject}
            ref={ref}
          />
        </Col>
        {/* CONTENT RIGHT---------- */}
        <Col className="col-6 h-100 d-flex justify-content-center align-items-center">
          <MobilePreview onAccountPage adType={"linkedin"}  />
        </Col>
      </Row>
    );
  }
);

export default LinkedinStep0;
