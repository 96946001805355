import "./style.scss";
import { Button, Row, Col } from "react-bootstrap";

const ButtonMain = (props) => {
  return (
    <Button
      className={`button-main btn-lg ${props.className}`}
      variant={props.variant}
      type={props.type}
      onClick={props.onClick}
      disabled={props.loading || props.disabled}
      style={
        props.width
          ? { width: "100%" }
          : props.setWidth
              ? { width: props.setWidth }
            : { width: "unset" }
            }
            >
              <Row className="row-cols-auto justify-content-center align-items-center g-1 ">
                      <Col          
                className={props.reverse ? "order-2 text-start mb-0" : "order-1 mb-0"}
              >      
                <p className="text-lg semi-bold" style={{ whiteSpace: "nowrap" }}>{props.text}</p>
              </Col>
                { props.icon && (
                < Col
                className={
                    props.reverse
              ? "order-1 text-end d-flex align-items-center"
                          : "order-2 "
                  }
                >
                  {props.icon}
                </Col>
                                                      )}
            </Row>
    </Button >
  );
};

ButtonMain.propTypes = {};

export default ButtonMain;
