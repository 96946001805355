
import React from 'react'
import { Button, Col, Container, Row, Alert, Stack, Image } from 'react-bootstrap'
import { MdErrorOutline } from "react-icons/md";
import styles from "./style.module.css"

export default function ErrorUI({icon, header, description, Buttons}) {
  return (
    <Stack className={`d-flex justify-content-start align-content-center align-items-center flex-column gap-2 ${styles.desktopPadding}`} style={{ height: "100vh", backgroundColor: "#f9f9f9", }}>
      <Image src="/static/media/autotarget-logo-blue.48702643c1e20d631ce2.png" className="logo-img img-fluid" style={{ maxWidth: "300px", width: "100%" }} />

      <Row className='d-flex flex-row  justify-content-center bg-secondary py-3 px-1 mx-1 rounded border bg-white gap-2' style={{ maxWidth: "800px" }} >
        <Row className=" row-cols-1 row-cols-md-2">
          <Stack className="align-items-center align-content-center">
            {
              icon ??
              <MdErrorOutline size={72} />
            }
            <span className='text-center bold my-2'>{header}</span>
          </Stack >
          <Stack className=" p-2 text-center justify-content-center">
            <p>{description}</p>
          </Stack >

        </Row>
        <Row className="row-cols-1 row-cols-md-2 ">
          <Stack className="gap-3">
            <Button variant="primary" onClick={() => { document.location.href = "/"; }}>Gå tillbaka till startsidan</Button>
            {Buttons}
          </Stack>

        </Row>
      </Row >
    </Stack >
  )
}
