import React from 'react'
import { Button, Col, Container, Row, Alert, Stack, Image } from 'react-bootstrap'
import { useErrorBoundary } from 'react-error-boundary';
import { MdErrorOutline } from "react-icons/md";
import style from "./style.module.css"

export default function ErrorFallback() {
  const { resetBoundary } = useErrorBoundary();
  return (
    <Stack className={`d-flex justify-content-start align-content-center align-items-center flex-column gap-2 ${style.error_boundary}`} style={{ height: "100vh", backgroundColor: "#f9f9f9", }}>
      <Image src="/static/media/autotarget-logo-blue.48702643c1e20d631ce2.png" className="logo-img img-fluid" style={{ maxWidth: "300px", width: "100%" }} />

      <Row className='d-flex flex-row  justify-content-center bg-secondary py-3 px-1 mx-1 rounded border bg-white gap-2' style={{ maxWidth: "800px" }} >
        <Row className=" row-cols-1 row-cols-md-2">
          <Stack className="align-items-center align-content-center">
            <MdErrorOutline size={72} />
            <span className='text-center bold my-2'>Ett oväntat fel uppstod</span>
          </Stack >
          <Stack className=" p-2 text-center justify-content-center">
            <p>Tyvärr inträffade ett oväntat fel som inte kunde hanteras korrekt.  Vi ber om ursäkt för eventuella besvär detta kan ha orsakat. </p>
          </Stack >

        </Row>
        <Row className="row-cols-1 row-cols-md-2 ">
          <Stack className="gap-3">
            <Button variant="light" onClick={() => { resetBoundary(); }}>Försök igen</Button>
            <Button variant="primary" onClick={() => { document.location.href = "/"; }}>Gå tillbaka till startsidan</Button>
          </Stack>

        </Row>
      </Row >
    </Stack >
  )
}
